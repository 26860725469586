import { Dispatch, SetStateAction, useState } from "react";
import { Button, Descriptions } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { ClientDesItemLabel, LineDiv } from "../../utils";
import { ClinetDetailType } from "../interface";
import { ClientBaseInfoEdit } from "./edit";
import { useWindowSize } from "utils/hooks/useWindowSize";

const ClientBaseInfo = ({
  detail,
  reload,
  setDisChange,
}: {
  detail: ClinetDetailType | undefined;
  reload: () => void;
  setDisChange: Dispatch<SetStateAction<boolean>>;
}) => {
  // 基本信息state
  const [baseInfoEditable, setbaseInfoEditableable] = useState(false);
  const { width } = useWindowSize();

  return baseInfoEditable ? (
    <ClientBaseInfoEdit
      editingItem={detail}
      setEditable={setbaseInfoEditableable}
      setDisChange={setDisChange}
      reload={reload}
    />
  ) : (
    <Descriptions
      title="小插件基本信息"
      column={width <= 1440 ? 2 : 4}
      extra={
        <Button
          onClick={() => {
            setbaseInfoEditableable(true);
            setDisChange(true);
          }}
          icon={<EditOutlined />}
        >
          编辑
        </Button>
      }
    >
      <Descriptions.Item label={<ClientDesItemLabel label={"小插件版本"} />}>
        <LineDiv>
          {detail?.client_version_list.map((i) => i?.name).join("、")}
        </LineDiv>
      </Descriptions.Item>
      <Descriptions.Item label={<ClientDesItemLabel label={"主识别方式"} />}>
        <LineDiv>{detail?.identify_way_name}</LineDiv>
      </Descriptions.Item>
      <Descriptions.Item label={<ClientDesItemLabel label={"配置下载"} />}>
        <LineDiv>
          {detail?.is_config_download === 1
            ? "是"
            : detail?.is_config_download === 0
            ? "否"
            : null}
        </LineDiv>
      </Descriptions.Item>
      <Descriptions.Item label={<ClientDesItemLabel label={"安装数量"} />}>
        <LineDiv>{detail?.install_num}</LineDiv>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default ClientBaseInfo;
