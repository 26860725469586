import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useBreaklistAdd = () => {
  const [{ openBreaklistAdd }, setopenBreaklistAdd] = useUrlQueryParam([
    "openBreaklistAdd",
  ]);

  const open = () => setopenBreaklistAdd({ openBreaklistAdd: true });
  const close = () => setopenBreaklistAdd({ openBreaklistAdd: undefined });

  return [openBreaklistAdd === "true", open, close] as const;
};
