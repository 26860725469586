import { Dispatch, SetStateAction } from "react";
import { Collapse, List, Popup, Space } from "antd-mobile";

import { HardwaresMatterInfos } from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";
import { AttachmentDiv } from "authenticated-app/mobile/components/AttachmentDiv";
import { isImageFormat } from "authenticated-app/mobile/util";

export const MobileMatterInfoDetail = ({
  detailInfo,
  visible,
  setVisible,
}: {
  detailInfo: HardwaresMatterInfos | undefined | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Popup
      visible={visible}
      destroyOnClose
      bodyStyle={{ height: "61.8vh", overflowY: "scroll" }}
      onMaskClick={() => setVisible(false)}
      showCloseButton
      onClose={() => setVisible(false)}
    >
      <List
        mode="card"
        header={<p style={{ fontWeight: "bold" }}>其他物料详情</p>}
      >
        <List.Item extra={detailInfo?.eqpt_type_name || "未知"}>
          设备类型
        </List.Item>
        <List.Item extra={detailInfo?.eqpt_oem_name || "未知"}>
          设备品牌
        </List.Item>
        <List.Item extra={detailInfo?.eqpt_model_name || "未知"}>
          设备型号
        </List.Item>
        <List.Item extra={detailInfo?.ip || "未知"}>物料IP</List.Item>
        <List.Item extra={detailInfo?.maintenance_code || "未知"}>
          维保代码
        </List.Item>
        <List.Item extra={detailInfo?.assets_code || "未知"}>
          资产编码
        </List.Item>
        <Collapse
          defaultActiveKey={
            detailInfo?.description ? detailInfo?.id?.toString() : undefined
          }
          accordion
        >
          <Collapse.Panel
            key={detailInfo?.id?.toString() || "detailInfo-description"}
            title="应用说明"
          >
            {detailInfo?.description || "无"}
          </Collapse.Panel>
        </Collapse>
        <Collapse
          defaultActiveKey={
            detailInfo?.asset_photos.length
              ? detailInfo?.id?.toString()
              : undefined
          }
          accordion
        >
          <Collapse.Panel
            key={detailInfo?.id?.toString() || "detailInfo-asset_photos"}
            title="资产照片"
          >
            {detailInfo?.asset_photos.length ? (
              <Space direction="vertical">
                {detailInfo?.asset_photos?.map((item, index) => (
                  <AttachmentDiv
                    key={index}
                    isImage={isImageFormat(item.attachment_name)}
                    attachment={item}
                  />
                ))}
              </Space>
            ) : (
              "无"
            )}
          </Collapse.Panel>
        </Collapse>
      </List>
    </Popup>
  );
};
