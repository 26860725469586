import { Fragment, useEffect, useRef, useState } from "react";
import type { ProColumns } from "@ant-design/pro-components";
import { ActionType, ProTable, FormInstance } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { DateTimeFormate, FormatDate, trimStr } from "utils";
import { useHttp } from "utils/http";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { BREAK_TYPE } from "lib/interface/breaklist";
import { GET_BREAKLIST } from "lib/api/issues";
import { useBreaklistDetail } from "authenticated-app/hooks/breaklist/useBreaklistDetail";
import { BreakDetailModal } from "authenticated-app/pc/ticket/breakList/detail";

const getSeachParams = (params: number[] | string[]) => {
  return params?.map((i) => i).join("|");
};

export const BreaklistTable = ({ hospID }: { hospID: number | null }) => {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<FormInstance>();
  const { height } = useWindowSize();
  const client = useHttp();
  const [, openBreaklistDetail] = useBreaklistDetail();
  const [selectedTag, setSelectedTag] = useState<string[]>(["全部工单"]);
  const [area, setArea] = useState<string[]>();
  const [searchParams, setSearchParams] = useState<any>();
  const [page, setPage] = useState<number>(1);

  const getParams = (params: any) => {
    const { create_time } = params;
    let start_time = create_time?.[0]
      ? FormatDate(create_time?.[0])
      : undefined;
    let end_time = create_time?.[1] ? FormatDate(create_time?.[1]) : undefined;
    let queryParams = {
      ...params,
      hospital_id: hospID,
      order_id: params?.order_id ? trimStr(params?.order_id) : undefined,
      customer_name: params?.customer_name
        ? trimStr(params?.customer_name)
        : undefined,
      node_user_name: params?.node_user_name
        ? trimStr(params?.node_user_name)
        : undefined,
      issue_user_name: params?.issue_user_name
        ? trimStr(params?.issue_user_name)
        : undefined,
      create_user: params?.create_user
        ? trimStr(params?.create_user)
        : undefined,
      order_type_id_list: getSeachParams(params?.order_type_name),
      order_type_name: undefined,
      process_id_list: getSeachParams(params?.process_name),
      process_name: undefined,
      area_id_list: getSeachParams(params?.area_id_list),
      province_id_list: getSeachParams(params?.province_id_list),
      label_id_list: getSeachParams(params?.label_id_list),
      start_time,
      end_time,
      create_time: undefined,
    };
    return {
      ...params,
      ...queryParams,
    };
  };

  const search = (params: any) => {
    return client(GET_BREAKLIST, {
      data: { ...params, ...searchParams },
    });
  };

  const BreaklistColumns: ProColumns<BREAK_TYPE>[] = [
    {
      title: "工单ID",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "工单类别",
      dataIndex: "order_type_name",
      key: "order_type_name",
    },
    {
      title: "工单状态",
      dataIndex: "process_name",
      key: "process_name",
    },
    {
      title: "节点负责人",
      dataIndex: "node_user_name",
      key: "node_user_name",
    },
    {
      title: "工单创建人",
      dataIndex: "create_user",
      key: "create_user",
    },
    {
      title: "标题",
      search: false,
      dataIndex: "title",
      key: "title",
    },

    {
      title: "创建时间",
      dataIndex: "create_time",
      key: "create_time",
      search: false,
      render: (_, record, __) => {
        return dayjs(record.create_time).format(DateTimeFormate);
      },
    },
    {
      title: "解决时长",
      dataIndex: "processing_time",
      key: "processing_time",
      search: false,
    },
  ];

  useEffect(() => {
    actionRef.current?.reload();
  }, [hospID]);

  return (
    <Fragment>
      <ProTable<BREAK_TYPE>
        style={{
          minWidth: 988,
        }}
        rowKey="id"
        actionRef={actionRef}
        formRef={formRef}
        cardBordered
        columns={BreaklistColumns}
        request={async (params) => {
          try {
            let queryParams = getParams(params);
            const { current, pageSize, ...downloadParamsD } = queryParams;
            const res = await search(queryParams);
            return {
              data: res.results,
              total: res.count,
              success: true,
            };
          } catch (e) {
            return { success: false };
          }
        }}
        onReset={() => {
          setSearchParams(undefined);
          setSelectedTag(["全部工单"]);
        }}
        search={false}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: false,
          pageSize:
            Math.trunc((height - 382) / 47) > 5
              ? Math.trunc((height - 382) / 47)
              : 5,
          current: page,
          onChange: (v) => {
            setPage(v);
          },
        }}
        onRow={(record) => ({
          onClick: () => openBreaklistDetail(record.id),
        })}
      />
      <BreakDetailModal actionRef={actionRef} />
    </Fragment>
  );
};
