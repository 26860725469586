import { useState, useRef, useEffect } from "react";
import type { BaseSelectRef } from "rc-select";
import { Form, Select, Spin, Tag, Tooltip } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { BREAK_DETAIL_TYPE } from "lib/interface/breaklist";
import { selectFilter, HospSelectFilter } from "utils";
//
import "./index.css";

const options = [
  { value: "gold" },
  { value: "lime" },
  { value: "blue" },
  { value: "cyan" },
];

export const UpdateMultiSelect = ({
  onUpdate,
  defaultValue,
  defaultIds,
  label,
  name,
  options,
  required,
  api,
  isCanChange,
  preDependency,
  preData,
  enableDependency,
  maxTag,
}: {
  onUpdate: (value: any) => void;
  defaultValue?: { id: number; name?: string; userName?: string }[];
  defaultIds?: number[];
  label: string;
  name: string;
  options: { label: string; value: string };
  required?: boolean;
  api: string;
  isCanChange?: boolean;
  preDependency?: {
    type: string;
    label: string;
    value: number | string | undefined;
  };
  preData?: BREAK_DETAIL_TYPE | null;
  enableDependency?: boolean;
  maxTag?: number;
}) => {
  const [form] = Form.useForm();
  const client = useHttp();

  const [isEditing, setIsEditing] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const ref = useRef<BaseSelectRef>(null);

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"blue"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const maxTagPlaceholder = (value: any) => {
    return (
      <div
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <Tooltip
          title={value.map((item: any) => item.label).join(", ")}
          color={"blue"}
          key={"blue_Tooltip_1"}
          open={tooltipVisible}
          onOpenChange={(visible) => setTooltipVisible(visible)}
        >
          <div style={{ color: "blue" }}>+{value.length}...</div>
        </Tooltip>
      </div>
    );
  };

  const onFinish = (value: any) => {
    value[name] = value[name] || [];

    if (value.issue_type_id) {
      value = {
        ...value,
        issue_reason_id: null,
        issue_reason_name: null,
      };
      form.setFieldValue("issues_reason_id", undefined);
    }
    onUpdate(value);
    ref.current?.blur();
  };

  const { run, data, isLoading, isIdle } = useAsync<any>();
  const runHandle = () => {
    if (preDependency?.value) {
      api = `${api}?${preDependency.type}=${preDependency.value}`;
    }
    run(client(api)).then(() => {
      if (defaultIds) {
        form.setFieldsValue({
          [name]: defaultIds,
        });
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      [name]: defaultValue ? defaultValue.map((item) => item.id) : [],
    });
  }, [defaultValue]);

  useEffect(() => {
    if (enableDependency) {
      if (preDependency?.value) {
        runHandle();
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [enableDependency, preDependency?.value]);

  const loading = isLoading || isIdle;

  return (
    <Form
      form={form}
      className="update-select-form"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label={label}
        name={name}
        rules={[{ required: required, message: `必须选择${label}` }]}
        style={{ width: "100%" }}
      >
        {isCanChange || maxTag ? (
          <Select
            ref={ref}
            showSearch
            filterOption={HospSelectFilter}
            maxTagCount={maxTag ? "responsive" : undefined}
            tagRender={maxTag ? tagRender : undefined}
            maxTagPlaceholder={maxTagPlaceholder}
            mode="multiple"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            placeholder={
              disabled ? `请先选择${preDependency?.label}!` : `选择${label}`
            }
            disabled={!isCanChange ? true : disabled}
            bordered={isEditing}
            suffixIcon={isEditing ? undefined : null}
            onChange={form.submit}
            onFocus={() => setIsFocus(true)}
            onBlur={() => {
              setIsFocus(false);
              setIsEditing(false);
            }}
            onMouseEnter={() => {
              setIsEditing(true);
            }}
            onMouseLeave={() => {
              if (isFocus) return;
              setIsEditing(false);
            }}
            options={data?.map((i: any) => ({
              label: i[options.label],
              value: i[options.value],
            }))}
            onClick={() => {
              if (!data) {
                runHandle();
              }
            }}
            dropdownRender={(originNode) => (
              <Spin spinning={loading}>{originNode}</Spin>
            )}
          >
            {defaultValue?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name || item.userName}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <>
            <>
              {defaultValue
                ?.map((item) => item.name || item.userName)
                .join(", ")}
            </>
          </>
        )}
      </Form.Item>
    </Form>
  );
};
