import { List } from "antd-mobile";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { CheckValue } from "lib/interface/base";
import { CHECKLIST_VALUE_TYPE } from "lib/interface/checklist";

const getValue = (value: CheckValue) => {
  switch (value) {
    case 0:
      return <>异常</>;
    case 1:
      return <>正常</>;
    case 2:
      return <>不适用</>;
    default:
      return <>不适用</>;
  }
};

export const SharedSurveyList = ({
  detail,
  index,
  loading,
}: {
  detail: CHECKLIST_VALUE_TYPE;
  index: number;
  loading: boolean;
}) => {
  const { content, description, value } = detail || {};

  return (
    <>
      <List
        mode="card"
        header={<ListHeader text={`第${index}项`} loading={loading} />}
      >
        <List.Item extra={content || "无"}>调研内容</List.Item>
        <List.Item extra={description || "无"}>参考指标</List.Item>
        <List.Item extra={getValue(value) || "无"}>调研结果</List.Item>
      </List>
    </>
  );
};
