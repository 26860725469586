import { StatisticsHospType } from "../../interface";
import { MyBarChartType } from "../../chart/bar-chart";

const GetType = (data: StatisticsHospType[]) => {
  let tmpType: Map<string, { data: number }> = new Map();
  for (let r of data) {
    if (r.is_public === 1) {
      tmpType.set(r.type, { data: 0 });
    }
  }
  return tmpType;
};

export const GetHospTypeBar = (data: StatisticsHospType[]) => {
  let tmpType = GetType(data);

  let tmpData: MyBarChartType = {
    xData: [],
    series: [],
  };

  data.forEach((item) => {
    if (item.is_public === 1) {
      tmpType.get(item.type)!.data += item.count;
    }
  });

  let tmpSeriesData: number[] = [];
  tmpType.forEach((v, k) => {
    tmpData.xData?.push(k);
    tmpSeriesData.push(v.data);
  });
  tmpData.series?.push({ data: tmpSeriesData, type: "bar", barWidth: 50 });

  return tmpData;
};
