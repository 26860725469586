import { useEffect } from "react";
import { Tabs, Result, Button, Skeleton } from "antd";
import type { TabsProps } from "antd";
import { usePermission } from "context/permission";
import { ProductNumberStatistical } from "./number-statistical";
import { ProductRate } from "./product-rate";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

const defaultTab = "number";

export const ProductStat = () => {
  const [{ tab }, setTab] = useUrlQueryParam(["tab"]);
  const { permission } = usePermission();

  useEffect(() => {
    if (!!!tab) {
      setTab({ tab: defaultTab });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const items: TabsProps["items"] = [
    {
      key: "number",
      label: `产品数量统计`,
      children: <ProductNumberStatistical />,
    },
    {
      key: "rate",
      label: `产品使用统计`,
      children: <ProductRate />,
    },
  ];

  return permission?.productProfile === 1 ? (
    <Tabs
      activeKey={tab}
      size="small"
      items={items}
      onChange={(v) => setTab({ tab: v })}
    />
  ) : permission?.productProfile === 0 ? (
    <Result
      status="403"
      title="403"
      subTitle="抱歉，你无权限访问此页面！"
      extra={
        <Button
          type="primary"
          onClick={() => window.location.replace(window.location.origin)}
        >
          返回主页
        </Button>
      }
    />
  ) : (
    <Skeleton active />
  );
};
