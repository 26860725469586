import { Fragment } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { HospListProvider } from "context/hosp";
import { MobileCustomerList } from "./list";
import { getLinkTo } from "utils";

import "./index.css";

export const searchNameStorageKey = "infer-mobile-search-name";
export const ticketNameStorageKey = "infer-mobile-ticket-name";
export const breakNameStorageKey = "infer-mobile-break-name";
export const launchChangeNameStoreageKey = "infer-mobile-launchChange-name";

export const mobileListRouter = "/mobile/list";

export const MobileHome = () => {
  return (
    <Fragment>
      <Routes>
        <Route path={mobileListRouter} element={<MobileCustomerList />} />
        <Route
          path="*"
          element={<Navigate to={getLinkTo(mobileListRouter)} replace />}
        />
      </Routes>
      {/* <HospListProvider></HospListProvider> */}
    </Fragment>
  );
};
