import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, Result, Spin, UploadProps } from "antd";
import { message, Upload, Typography } from "antd";
import { RcFile } from "antd/lib/upload/interface";

import { usePostFile } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";

const { Dragger } = Upload;
const { Link, Paragraph } = Typography;

const fileSize = 1024 * 1024 * 10; // 10M

export const BaseExcelImport = ({
  API,
  title = "Excel导入",
  tip = "文件上传支持Excel表格 (xls、xlsx) 格式",
  modelAPI,
  visible,
  setVisible,
}: {
  API: string;
  title?: string;
  tip?: string;
  modelAPI: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  const client = usePostFile();
  const [isSubSuccess, setIsSubSuccess] = useState<boolean>(false);
  const [isHasError, setIsHasError] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<string[]>([]);

  const { run: postFile, isLoading: postingFile } = useAsync();

  const props: UploadProps = {
    fileList: [],
    maxCount: 1,
    accept: "image/*,.xlsx,.XLSX,.xls,.XLS",
    customRequest(options) {
      const file = options.file as RcFile;
      const isLt2M = file.size <= fileSize;
      let url: string;
      const fmData = new FormData();

      if (isLt2M) {
        if (
          file.name.toLocaleLowerCase().endsWith("xlsx") ||
          file.name.toLocaleLowerCase().endsWith("xls")
        ) {
          url = API;
          fmData.append("file", file);
        } else {
          message.error(file.name + "不是Excel类型！", 2);
          return;
        }
      } else {
        message.error(file.name + "文件大小超出10M，无法上传！", 2);
        return;
      }

      postFile(
        client(url, {
          body: fmData,
        }),
      ).then(async (res) => {
        let msg = await res.json();
        if (msg?.error_list?.length > 0) {
          setIsHasError(true);
          setErrorList(msg?.error_list);
        } else {
          setIsSubSuccess(true);
        }
      });
    },
  };

  const initStatus = () => {
    setErrorList([]);
    setIsHasError(false);
    setIsSubSuccess(false);
  };

  useEffect(() => {
    if (visible) {
      initStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={title}
      open={visible}
      destroyOnClose
      maskClosable={false}
      onCancel={() => setVisible(false)}
      footer={null}
      width={"640px"}
      closable={!isSubSuccess && !isHasError}
    >
      {isSubSuccess || isHasError ? (
        <Result
          status={isHasError ? "error" : "success"}
          title={isHasError ? "提交出错" : "提交成功"}
          subTitle={isHasError ? "表格校验出错，详情如下" : "完成导入"}
          extra={[
            <Button
              key="subAgain"
              type="primary"
              onClick={() => {
                initStatus();
              }}
            >
              再次上传
            </Button>,
            <Button
              key="backCharts"
              onClick={() => {
                setVisible(false);
              }}
            >
              关闭窗口
            </Button>,
          ]}
        >
          {isHasError ? (
            <Paragraph
              style={{
                maxHeight: 256,
                overflowY: "scroll",
              }}
            >
              {errorList.map((v, i) => (
                <p key={`import-${i}`}>{v}</p>
              ))}
            </Paragraph>
          ) : null}
        </Result>
      ) : (
        <Spin spinning={postingFile}>
          <Alert
            type="info"
            showIcon
            message={
              <Fragment>
                <span>表格需要使用对应的模板，</span>
                <Link href={modelAPI} target="_blank">
                  点击查看
                </Link>
              </Fragment>
            }
          />
          <br />
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">点击或拖动文件到此区域进行上传</p>
            <p className="ant-upload-hint">{tip}</p>
          </Dragger>
        </Spin>
      )}
    </Modal>
  );
};
