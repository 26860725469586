import { SafeArea, TabBar } from "antd-mobile";
import useUrlState from "@ahooksjs/use-url-state";

import { ListBodyComponent, tabs, userListKey } from "./tabs";
import { useMount } from "utils";

export const MobileCustomerList = () => {
  const [{ tab }, setTab] = useUrlState({ tab: undefined });

  useMount(() => {
    if (!!!tab) {
      setTab(() => ({ tab: userListKey }));
    }
  });

  return (
    <div className={"mobile-app"}>
      <div className={"mobile-body"}>
        <div style={{ width: "100%", marginBottom: "48px" }}>
          {tab && ListBodyComponent({ tabKey: tab })}
        </div>
      </div>
      <div className={"mobile-bottom"}>
        <TabBar
          onChange={(key) => setTab({ tab: key })}
          activeKey={tab}
          safeArea={true}
        >
          {tabs.map((item) => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
      <SafeArea position="bottom" />
    </div>
  );
};
