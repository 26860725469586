import { SetStateAction, Dispatch, useEffect } from "react";
import { Button, Modal, Space, Form, Input, message } from "antd";

import { useHttp } from "utils/http";
import { BaseSingleInfo, HosEqpt } from "../interface";
import { initFormValue } from "utils";

export const BaseSingleSettingEdit = ({
  editSettingInfo,
  info,
  UPDATE_SETTING,
  visible,
  setVisible,
  reload,
}: {
  editSettingInfo: HosEqpt | undefined;
  info: BaseSingleInfo;
  UPDATE_SETTING: (hospID: number) => string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const submit = (value: any) => {
    value = initFormValue(value);
    let id = editSettingInfo?.id;
    if (id)
      client(UPDATE_SETTING(id), {
        data: {
          ...value,
        },
        method: "PUT",
      })
        .then(() => {
          message.success("修改成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    else message.error("无法请求修改，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...editSettingInfo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={`修改${info.title}`}
      destroyOnClose
      open={visible}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="name"
          label={info.label}
          rules={[{ required: true, message: `必须输入${info.label}` }]}
          // normalize={(v) => DisposeForm(v)}
        >
          <Input placeholder={`例：${info.placeholder}`} maxLength={50} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
