import { IdType } from "lib/api";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useBreaklistDetail = () => {
  const [{ openBreaklistDetail }, setopenBreaklistDetail] = useUrlQueryParam([
    "openBreaklistDetail",
    "detailId",
  ]);

  const open = (id: IdType) =>
    setopenBreaklistDetail({ openBreaklistDetail: true, detailId: id });
  const close = () =>
    setopenBreaklistDetail({
      openBreaklistDetail: undefined,
      detailId: undefined,
    });

  return [openBreaklistDetail === "true", open, close] as const;
};
