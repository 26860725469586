import { useEffect } from "react";
import { useDebounceFn } from "ahooks";

import { useMount } from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { classReducer } from ".";
import { GET_RESOURCE_CLASS } from "../api";
import { ResourceClass } from "../interface";

const ShowClass = ({ id, state }: { id: number; state: classReducer }) => {
  const client = useHttp();
  const { run: getResourcesClass, data: resourcesClass } =
    useAsync<ResourceClass[]>();
  const getResourcesClassHandle = () => {
    if (id) {
      getResourcesClass(client(GET_RESOURCE_CLASS(id)));
    }
  };
  const { run: getResourcesClassDebounce } = useDebounceFn(
    getResourcesClassHandle,
    { wait: 200 },
  );

  useEffect(() => {
    if (state.id === id) {
      getResourcesClassHandle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useMount(getResourcesClassDebounce);

  return (
    <>{resourcesClass?.map((i) => i.classFicationName).join("、") || ""}</>
  );
};

export default ShowClass;
