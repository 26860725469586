import { TicketPrefix } from "lib/api/prefix";

// 权限列表
export const GET_FRONT_RESOURCES = "/csmAPI/api/resources/autoRelation/list/";
export const UPDATE_FRONT_RESOURCES = "/csmAPI/api/resources/autoRelation/";
export const GET_PACKAGE_LIST = "/csmAPI/api/package/";
export const GET_PACKAGE = (org_id: number) => `/csmAPI/api/package/${org_id}/`;
export const ADD_PACKAGE = "/csmAPI/api/package/";
export const UPDATE_PACKAGE = () => `/csmAPI/api/package/`;
export const DEL_PACKAGE = (org_id: number) => `/csmAPI/api/package/${org_id}/`;

// 资源列表
export const GET_RESOURCE_LIST = "/csmAPI/api/resources/list/";
export const ADD_RESOURCE = "/csmAPI/api/resources/addResource";
export const GET_RESOURCE_CLASS = (org_id: number) =>
  `/csmAPI/api/resources/classfication/list/?resourceId=${org_id}`;
export const ADD_RESOURCE_CLASS =
  "/csmAPI/api/resources/addResourceClassfication";

// 权限关联
export const GET_PERMISSION_ASSOSIATION = "/csmAPI/api/permissionDetail/";
export const GET_PERMISSION_ASSOSIATION_BY_ID = (org_id: number) =>
  `/csmAPI/api/permissionDetail/${org_id}/`;
export const ADD_PERMISSION_ASSOSIATION = "/csmAPI/api/permissionDetail/";
export const UPDATE_PERMISSION_ASSOSIATION = () =>
  "/csmAPI/api/permissionDetail/";
export const DEL_PERMISSION_ASSOSIATION = (org_id: number) =>
  `/csmAPI/api/permissionDetail/${org_id}/`;

export const GET_USER_LIST = "/csmAPI/api/departments/user/list/";
export const GET_GROUP_LIST = "/rommanAPI/hospitalGroups/groups/?groupId=";

export const UPDATE_FRONT_AUTH = "/rommanAPI/maintainers/update_user_relation/";

export const GET_ISSUES_DEPARTMENT_LIST = `${TicketPrefix}/issues/order_department_type`;
export const UPDATE_ISSUES_DEPARTMENT_USERS = `${TicketPrefix}/issues/update_department_users`;
