import { useState, useRef, useEffect } from "react";
import { Form, Input, InputRef, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { UrlRegex } from "utils/regexp";

import "./index.css";
import { getClickableLink } from "utils";

const { Link } = Typography;

export const UpdateInput = ({
  onUpdate,
  value,
  label,
  name,
  required,
  needUrlRegex,
  isCanChange,
  isLink,
  hiddenLabel,
  formStyle,
  inputStyle,
}: {
  onUpdate: (value: any) => void;
  value?: string;
  label?: string;
  name: string;
  required?: boolean;
  needUrlRegex?: boolean;
  isCanChange?: boolean;
  isLink?: boolean;
  hiddenLabel?: boolean;
  formStyle?: object;
  inputStyle?: object;
}) => {
  const [form] = Form.useForm();

  const [isEditting, setIsEditting] = useState(false);
  const [isFoucs, setIsFoucs] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const inputRef = useRef<InputRef>(null);

  const onFinish = (value: any) => {
    if (isChanged) {
      onUpdate(value);
      setIsChanged(false);
    }
    inputRef.current?.blur();
  };

  useEffect(() => {
    form.setFieldsValue({
      [name]: value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Form
      form={form}
      className="update-input-form"
      onFinish={onFinish}
      autoComplete="off"
      style={formStyle ? formStyle : {}}
    >
      <Form.Item
        label={hiddenLabel ? null : label}
        name={name}
        rules={[
          { required: required, message: `必须输入${label}` },
          needUrlRegex
            ? {
                pattern: UrlRegex(),
                message: "必须输入合法的URL格式",
              }
            : {},
        ]}
        style={{ width: "100%" }}
      >
        {isCanChange ? (
          <Input
            ref={inputRef}
            style={
              inputStyle
                ? inputStyle
                : {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }
            }
            placeholder={`输入${label}`}
            suffix={isEditting ? <EditOutlined /> : undefined}
            bordered={isEditting}
            onChange={() => setIsChanged(true)}
            onFocus={() => setIsFoucs(true)}
            onBlur={() => {
              setIsFoucs(false);
              setIsEditting(false);
              form.submit();
            }}
            onMouseEnter={() => setIsEditting(true)}
            onMouseLeave={() => {
              if (!isFoucs) {
                setIsEditting(false);
              }
            }}
          />
        ) : isLink ? (
          <Link
            href={value ? getClickableLink(value) : undefined}
            target="_blank"
          >
            {value || ""}
          </Link>
        ) : (
          value
        )}
      </Form.Item>
    </Form>
  );
};
