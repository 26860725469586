import { Dispatch, SetStateAction } from "react";
import { Collapse, List, Popup } from "antd-mobile";

import { HosUserType } from "authenticated-app/pc/customer/customer-detail/tab-pane/infoTabPane/interface";

export const MobileHospUserInfoDetail = ({
  hosUserInfo,
  visible,
  setVisible,
}: {
  hosUserInfo: HosUserType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Popup
      visible={visible}
      destroyOnClose
      bodyStyle={{ height: "61.8vh", overflowY: "scroll" }}
      onMaskClick={() => setVisible(false)}
      showCloseButton
      onClose={() => setVisible(false)}
    >
      <List
        mode="card"
        header={<p style={{ fontWeight: "bold" }}>外部人员详情</p>}
      >
        <List.Item extra={hosUserInfo?.hos_user_name || "未知"}>姓名</List.Item>
        <List.Item extra={hosUserInfo?.hos_user_type_name || "未知"}>
          人员类型
        </List.Item>
        <List.Item extra={hosUserInfo?.contact_name || "未知"}>
          联系方式
        </List.Item>
        <List.Item extra={hosUserInfo?.mobile || "未知"}>联系号码</List.Item>
        <Collapse
          defaultActiveKey={
            hosUserInfo?.remark ? hosUserInfo?.id.toString() : undefined
          }
          accordion
        >
          <Collapse.Panel
            key={hosUserInfo?.id.toString() || "hosUserDetail"}
            title="备注"
          >
            {hosUserInfo?.remark || "无"}
          </Collapse.Panel>
        </Collapse>
      </List>
    </Popup>
  );
};
