import { useEffect, useRef, useState } from "react";
import { message, Button, Space, Row, Col } from "antd";
import { ProForm, ProFormDateRangePicker } from "@ant-design/pro-components";
import {
  ProTable,
  ProColumns,
  FormInstance,
  ActionType,
} from "@ant-design/pro-components";
import { useHttp } from "utils/http";
import { DataReceiveInfoType } from "../interface";
import { GET_DATA_RECEIVE_FILTER_INFO } from "lib/api";
import { FormatDate, GetDate, RangeValue } from "utils";

const columns: ProColumns<DataReceiveInfoType>[] = [
  {
    title: "日期",
    dataIndex: "statics_date",
    valueType: "date",
    width: 120,
    search: false,
  },
  {
    title: "处理总检查量",
    dataIndex: "total",
    search: false,
  },
  {
    title: "不满足处理条件检查(过滤) ",
    dataIndex: "filter_num",
    search: false,
  },
];

const DataReceiveInfo = ({ hospID }: { hospID: number | null }) => {
  const client = useHttp();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const [searchParams, setSearchParams] = useState<any>();

  const getParams = (params: any) => {
    const dateRange = formRef.current?.getFieldValue("dateRange");
    if (dateRange) {
      let start_date = dateRange[0] ? FormatDate(dateRange[0]) : undefined;
      let end_date = dateRange[1] ? FormatDate(dateRange[1]) : undefined;
      let queryParams = {
        start_date,
        end_date,
      };
      return {
        ...params,
        ...queryParams,
      };
    } else {
      message.error("请选择日期");
      return null;
    }
  };

  const search = (params: any) => {
    if (hospID) {
      return client(GET_DATA_RECEIVE_FILTER_INFO(hospID), {
        data: { ...params, ...searchParams },
      });
    }
  };

  return (
    <ProTable<DataReceiveInfoType>
      rowKey="id"
      className="ant-table-padding-inline"
      actionRef={actionRef}
      search={false}
      tableExtraRender={(props) => [
        <Space>
          <h3 style={{ margin: "0px 20px 0px 0px" }}> 数据接收统计</h3>
          <ProForm<DataReceiveInfoType[]>
            formRef={formRef}
            layout={"inline"}
            submitter={{
              render: (props, doms) => {
                return (
                  <Space>
                    <Button type="primary" htmlType="submit">
                      查询
                    </Button>
                  </Space>
                );
              },
            }}
            onFinish={async () => {
              actionRef.current?.reload();
            }}
          >
            <ProFormDateRangePicker
              label="日期"
              name="dateRange"
              initialValue={GetDate("last_3_days")}
            />
          </ProForm>
        </Space>,
      ]}
      columns={columns}
      pagination={{
        pageSize: 3,
      }}
      dateFormatter="string"
      options={false}
      request={async (params) => {
        try {
          let queryParams = getParams(params);
          const res = await search(queryParams);
          return {
            data: res.results,
            total: res.count,
            success: true,
          };
        } catch (e) {
          return { success: false };
        }
      }}
    />
  );
};

export default DataReceiveInfo;
