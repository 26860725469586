import { Fragment, useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import type { ProColumns } from "@ant-design/pro-components";
import { ActionType, ProTable, FormInstance } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { DateFormate, trimStr } from "utils";
import { useHttp } from "utils/http";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { HosNodesType } from "authenticated-app/pc/customer/customer-list/interface";
import { useAsync } from "utils/hooks/useAsync";
import {
  GET_HOSP_NODES,
  GET_PRODUCT_LIST,
  GET_PRODUCT_DEPARTMENT,
} from "lib/api";
import {
  GET_EVENT_TYPE,
  GET_VISIT_TYPE,
  GET_VISIT_LIST,
  GET_BUSINESS_TYPE,
} from "lib/api/issues";
import { ProductType } from "authenticated-app/pc/setting/customer-setting/interface";
import { useDownload } from "utils/download";

import { ProductUsingDepartmentType } from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import {
  VisitType,
  EventType,
  VisitInfoList,
  BusinessType,
} from "authenticated-app/pc/operate/visit/interface";
import { useVisitListDetail } from "authenticated-app/hooks/visitlist/useVisitListDetail";
import { VisitDetailModal } from "authenticated-app/pc/operate/visit/detail";

const getSeachParams = (params: number[] | string[]) => {
  return params?.map((i) => i).join("|");
};

export const VisitListTable = ({ hospID }: { hospID: number | null }) => {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<FormInstance>();
  const { height } = useWindowSize();

  const client = useHttp();
  const downloadClient = useDownload();

  const [, openVisitListDetail] = useVisitListDetail();

  const [selectedTag, setSelectedTag] = useState<string[]>(["全部工单"]);

  const [area, setArea] = useState<string[]>();
  const [areaListEnum, setAreaListEnum] = useState<{
    [key in number]: { text: string };
  }>({});
  const [provinceListEnum, setProvinceListEnum] = useState<{
    [key in number]: { text: string };
  }>({});

  const [searchParams, setSearchParams] = useState<any>();

  const {
    run: getHosNodes,
    isLoading: gettingHosNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };

  // 科室类型
  const {
    run: getDepartmentList,
    data: departTypeList,
    isLoading: gettingDepartTypeList,
  } = useAsync<ProductUsingDepartmentType[]>();
  const getDepartmentListHandle = () => {
    getDepartmentList(client(GET_PRODUCT_DEPARTMENT));
  };

  // 拜访类型
  const {
    run: getVisitTypeList,
    data: visitTypeList,
    isLoading: gettingVisitTypeList,
  } = useAsync<VisitType[]>();
  const getVisitTypeListHandle = () => {
    getVisitTypeList(client(GET_VISIT_TYPE));
  };

  //   重大事件类别
  const {
    run: getEventTypeList,
    data: eventTypeList,
    isLoading: gettingEventTypeList,
  } = useAsync<EventType[]>();
  const getEventTypeListHandle = () => {
    getEventTypeList(client(GET_EVENT_TYPE));
  };

  // 商机类别
  const {
    run: getBusinessTypeList,
    data: businessTypeList,
    isLoading: gettingBusinessTypeList,
  } = useAsync<BusinessType[]>();
  const getBusinessTypeListHandle = () => {
    getBusinessTypeList(client(GET_BUSINESS_TYPE));
  };

  const {
    run: getProductList,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductType[]>();
  const getProductListHandle = () => {
    getProductList(client(GET_PRODUCT_LIST));
  };

  const getParams = (params: any) => {
    let start_date = params?.visit_date?.[0]
      ? params?.visit_date?.[0]
      : undefined;
    let end_date = params?.visit_date?.[1]
      ? params?.visit_date?.[1]
      : undefined;
    let queryParams = {
      ...params,
      hospital_id: hospID,
      hospital_name: params?.hospital_name
        ? trimStr(params?.hospital_name)
        : undefined,
      customer_name: params?.customer_name
        ? trimStr(params?.customer_name)
        : undefined,
      create_user: params?.create_user
        ? trimStr(params?.create_user)
        : undefined,
      area_id_list: getSeachParams(params?.area_id_list),
      province_id_list: getSeachParams(params?.province_id_list),
      department_type_id_list: getSeachParams(params?.department_type_list),
      visit_type_id_list: getSeachParams(params?.visit_type_list),
      business_opportunity_type_id_list: getSeachParams(
        params?.business_opportunity_type_list,
      ),
      product_id_list: getSeachParams(params?.product_list),
      event_type_id: params?.event_type_name
        ? trimStr(params?.event_type_name)
        : undefined,
      start_date,
      end_date,
    };
    return {
      ...params,
      ...queryParams,
    };
  };

  const search = (params: any) => {
    return client(GET_VISIT_LIST, {
      data: { ...params, ...searchParams },
    });
  };

  const VisitlistColumns: ProColumns<VisitInfoList>[] = [
    {
      title: "涉及产品线",
      // hideInTable: true,
      dataIndex: "product_list",
      valueEnum: () => {
        let enums: { [key in string]: { text: string } } = {};
        productList?.forEach((i) => {
          enums[i.title] = {
            text: i.chinese_name || `${i.title}(${i.chinese_name})`,
          };
        });
        return enums;
      },
      fieldProps: {
        loading: gettingProductList,
        showSearch: true,
        onClick: () => {
          if (!!!productList) {
            getProductListHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin spinning={gettingProductList}>{originNode}</Spin>
        ),
        mode: "multiple",
        maxTagCount: 3,
      },
    },
    {
      title: "拜访科室",
      // hideInTable: true,
      dataIndex: "department_type_list",
      valueEnum: () => {
        let enums: { [key in string]: { text: string } } = {};
        departTypeList?.forEach((i) => {
          enums[i.id] = { text: i.name };
        });
        return enums;
      },
      fieldProps: {
        loading: gettingDepartTypeList,
        showSearch: true,
        onClick: () => {
          if (!!!departTypeList) {
            getDepartmentListHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin spinning={gettingDepartTypeList}>{originNode}</Spin>
        ),
        mode: "multiple",
        maxTagCount: 3,
      },
    },
    {
      title: "拜访类型",
      dataIndex: "visit_type_list",

      valueEnum: () => {
        let enums: { [key in string]: { text: string } } = {};
        visitTypeList?.forEach((i) => {
          enums[i.id] = { text: i.name };
        });
        return enums;
      },
      fieldProps: {
        loading: gettingVisitTypeList,
        showSearch: true,
        onClick: () => {
          if (!!!visitTypeList) {
            getVisitTypeListHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin spinning={gettingVisitTypeList}>{originNode}</Spin>
        ),
        mode: "multiple",
        maxTagCount: 3,
      },
    },
    {
      title: "特别关注",
      dataIndex: "is_special_focus", // number|number
      valueType: "select",
      valueEnum: {
        1: {
          text: "是",
          color: "red",
        },
        0: {
          text: "否",
        },
      },
    },

    {
      title: "创建人",
      dataIndex: "create_user",
    },
    {
      title: "重大事件类别",
      dataIndex: "event_type_name", // number|number
      valueEnum: () => {
        let enums: { [key in number]: { text: string } } = {};
        eventTypeList?.forEach((i) => {
          enums[i.id] = { text: i.name };
        });
        return enums;
      },
      fieldProps: {
        loading: gettingEventTypeList,
        showSearch: true,
        onClick: () => {
          if (!!!eventTypeList) {
            getEventTypeListHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin spinning={gettingEventTypeList}>{originNode}</Spin>
        ),
      },
    },
    {
      title: "商机类别",
      dataIndex: "business_opportunity_type_list",

      valueEnum: () => {
        let enums: { [key in string]: { text: string } } = {};
        businessTypeList?.forEach((i) => {
          enums[i.id] = { text: i.name };
        });
        return enums;
      },
      fieldProps: {
        loading: gettingBusinessTypeList,
        showSearch: true,
        onClick: () => {
          if (!!!businessTypeList) {
            getBusinessTypeListHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin spinning={gettingBusinessTypeList}>{originNode}</Spin>
        ),
        mode: "multiple",
        maxTagCount: 3,
      },
    },

    {
      title: "拜访时间",
      dataIndex: "visit_date",
      search: false,
      render: (_, record, __) => {
        return dayjs(record.visit_date).format(DateFormate);
      },
    },
  ];

  useEffect(() => {
    let enums: { [key in number]: { text: string } } = {};
    hosNodes?.forEach((i) => {
      if (i.nodeLevel === 1 && i.nodeId !== 5) {
        // 去除海外 nodeId 5
        enums[i.nodeId] = { text: i.nodeName || "未知" };
      }
    });
    setAreaListEnum(enums);
  }, [hosNodes]);

  useEffect(() => {
    let enums: { [key in number]: { text: string } } = {};
    hosNodes?.forEach((i) => {
      if (
        (area || []).includes(i.parentNodeId?.toString() || "-1") &&
        i.parentNodeId !== null
      ) {
        enums[i.nodeId] = { text: i.nodeName || "未知" };
      }
    });

    setProvinceListEnum(enums);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  useEffect(() => {
    actionRef.current?.reload();
  }, [hospID]);

  return (
    <Fragment>
      <ProTable<VisitInfoList>
        style={{
          minWidth: 988,
        }}
        rowKey="id"
        actionRef={actionRef}
        formRef={formRef}
        cardBordered
        columns={VisitlistColumns}
        request={async (params) => {
          try {
            let queryParams = getParams(params);
            const { current, pageSize, ...downloadParamsD } = queryParams;
            const res = await search(queryParams);
            return {
              data: res.results,
              total: res.count,
              success: true,
            };
          } catch (e) {
            return { success: false };
          }
        }}
        onReset={() => {
          setSearchParams(undefined);
          setSelectedTag(["全部工单"]);
        }}
        // search={{
        //   labelWidth: "auto",
        //   searchText: "搜索",
        //   span: 6,
        // }}
        search={false}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: false,
          pageSize:
            Math.trunc((height - 382) / 47) > 5
              ? Math.trunc((height - 382) / 47)
              : 5,
        }}
        onRow={(record) => ({
          onClick: () => openVisitListDetail(record.id),
        })}
      />
      <VisitDetailModal actionRef={actionRef} />
    </Fragment>
  );
};
