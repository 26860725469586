import { ReactNode } from "react";
import { Button } from "antd-mobile";

export const ListHeader = ({
  text,
  rightIcon,
  loading,
  disabled,
  onClick,
}: {
  text: ReactNode;
  rightIcon?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown;
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          lineHeight: "100%",
          height: "100%",
          margin: 0,
          fontWeight: "bold",
          fontSize: 17,
        }}
      >
        {text}
      </div>
      {rightIcon ? (
        <Button
          size={"mini"}
          color="primary"
          fill="none"
          loading={loading}
          disabled={disabled}
          onClick={onClick}
          style={{ paddingRight: 0 }}
        >
          {rightIcon}
        </Button>
      ) : null}
    </div>
  );
};
