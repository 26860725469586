import { MutableRefObject, useEffect } from "react";
import { ActionType } from "@ant-design/pro-components";
import {
  Button,
  Card,
  Form,
  message,
  Modal,
  Space,
  Table,
  Tooltip,
} from "antd";

import {
  CHECKLIST_COLUMNS_MAP,
  CHECKLIST_DETAIL_TYPE,
  CHECKLIST_ENUM,
  CHECKLIST_ENUM_MAP,
} from "lib/interface/checklist";
import { useChecklistDetail } from "authenticated-app/hooks/checklist/useChecklistDetail";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { GET_CHECKLIST_DETAIL, UPDATE_CHECKLIST } from "lib/api/checklist";
import { FormatTime } from "utils";

import "./index.css";

export const CheckDetailModal = ({
  actionRef,
}: {
  actionRef: MutableRefObject<ActionType | undefined>;
}) => {
  const [isOpen, , close] = useChecklistDetail();
  const [{ detailId }] = useUrlQueryParam(["detailId"]);

  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getDetailInfo,
    data: detailInfo,
    isIdle,
    isLoading,
  } = useAsync<CHECKLIST_DETAIL_TYPE>();
  const { run: submit, isLoading: submitting } = useAsync();

  const { hospital_name, check_type, create_time, checklist_values } =
    detailInfo || {};

  const isCanNotModify =
    detailInfo?.is_submit === 1 &&
    (detailInfo?.check_type === CHECKLIST_ENUM.online_list ||
      detailInfo?.check_type === CHECKLIST_ENUM.exit_list ||
      detailInfo?.check_type === CHECKLIST_ENUM.except_list);

  const onFinish = (value: any) => {
    if (detailInfo?.id) {
      if (isCanNotModify) {
        message.warning("已提交过, 不允许修改提交");
        return;
      }
      submit(
        client(UPDATE_CHECKLIST(detailInfo?.id), {
          method: "PUT",
          data: {
            hospital_id: detailInfo?.hospital_id,
            assignee_id: detailInfo?.assignee_id,
            source_order: detailInfo?.source_order,
            check_type: detailInfo?.check_type,
            checklist_value_map: { ...value },
          },
        }),
      ).then(() => {
        close();
        actionRef.current?.reload();
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      getDetailInfo(client(GET_CHECKLIST_DETAIL(detailId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const Loading = isIdle || isLoading;

  return (
    <Modal
      centered
      maskClosable={false}
      destroyOnClose
      open={isOpen}
      className="checklist-detail-modal"
      title={hospital_name || "未知"}
      width={"61.8%"}
      onCancel={close}
      footer={
        <Space>
          <Button onClick={close}>取消</Button>
          <Tooltip title={isCanNotModify ? "不允许修改提交" : undefined}>
            <Button
              type="primary"
              onClick={form.submit}
              loading={submitting}
              disabled={Loading || isCanNotModify}
            >
              提交
            </Button>
          </Tooltip>
        </Space>
      }
      bodyStyle={{
        minHeight: "12rem",
        maxHeight: "80vh",
        overflow: "auto",
      }}
    >
      <Card
        loading={Loading}
        title={CHECKLIST_ENUM_MAP.get(check_type as CHECKLIST_ENUM) || "未知"}
        extra={`创建时间: ${FormatTime(create_time)}`}
      >
        <Form form={form} onFinish={onFinish}>
          <Table
            bordered
            columns={CHECKLIST_COLUMNS_MAP.get(
              (check_type as CHECKLIST_ENUM) || CHECKLIST_ENUM.online_list,
            )}
            dataSource={checklist_values}
            pagination={false}
          />
        </Form>
      </Card>
    </Modal>
  );
};
