import { MutableRefObject, useEffect, useState, useRef } from "react";
import { ActionType } from "@ant-design/pro-components";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Spin,
  Upload,
  UploadFile,
  Cascader,
  message,
} from "antd";
import { PlusOutlined, PaperClipOutlined } from "@ant-design/icons";
import {
  DateTimeFormate,
  DisposeQuill,
  HospSelectFilter,
  handleUpload,
  limitUpload,
} from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { useBreaklistAdd } from "authenticated-app/hooks/breaklist/useBreaklistAdd";
import { BREAKLIST_OPTION_TYPE } from "lib/interface/breaklist";
import {
  ADD_BREAKLIST,
  GET_ISSUES_FEEDBACK_CHANNEL,
  // GET_ISSUES_MODULE,
  GET_ISSUES_REASON,
  GET_ISSUES_REASON_NEW,
  GET_ISSUES_TYPE,
  GET_ORDER_TYPE,
} from "lib/api/issues";

import { useDebounceFn } from "ahooks";
import {
  ADD_FILE,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_LIST,
  HOSP_LIST_API,
  GET_PRODUCT_VERSION_BY_ID,
  GET_PRODUCT_DETAIL_HAS_NOTONLINE,
} from "lib/api";

import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { editQuillToolbarOption } from "authenticated-app/pc/components/EditQuill/option";
import {
  ProductInfoType,
  ProductType,
  ProductVersionType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import { HospListType } from "interface";

import { handlePreview } from "utils/handlePreview";

import "./index.css";
import "authenticated-app/pc/components/EditQuill/quill.snow.css";
import { selectOptions } from "@testing-library/user-event/dist/types/setup/directApi";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-zzone";
Quill.register("modules/imageResize", ImageResize);

const { Item } = Form;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

interface Options {
  value: string;
  label: string;
  children?: Options[];
  isLeaf?: boolean;
}

export const AddModal = ({
  actionRef,
}: {
  actionRef: MutableRefObject<ActionType | undefined>;
}) => {
  const [isOpen, , close] = useBreaklistAdd();
  const [form] = Form.useForm();
  const [isCustomer, setIsCustomer] = useState(true);
  const [isAdditional, setIsAdditional] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [serviceConfirmationfile, setServiceConfirmationfile] = useState<
    UploadFile[]
  >([]);
  const [departureConfirmationfile, setDepartureConfirmationfile] = useState<
    UploadFile[]
  >([]);

  const [selectedHospId, setSelectedHospId] = useState<number>();
  const [selectedOrderTypeId, setSelectedOrderTypeId] = useState<number>();
  const [orderTypeHelpInfo, setOrderTypeHelpInfo] = useState<string>("");

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const client = useHttp();

  const {
    run: getHosp,
    data: hospList,
    isIdle: getHospIsIdle,
    isLoading: gettingHospList,
  } = useAsync<HospListType[]>();
  const getHospFunc = (params?: { [key in any]: any }) => {
    getHosp(
      client(HOSP_LIST_API, {
        data: {
          ...params,
        },
      }),
    );
  };
  const { run: getHospDebounce } = useDebounceFn(getHospFunc, { wait: 200 });
  const { run: AddBreaklist, isLoading: AddingBreaklist } = useAsync();
  const {
    run: getOrderTypeList,
    data: orderTypeList,
    isIdle: getOrderTypeListIsIdle,
    isLoading: gettingOrderTypeList,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getOrderTypeListHandle = () => {
    getOrderTypeList(client(GET_ORDER_TYPE));
  };
  const { run: getOrderTypeListDebounce } = useDebounceFn(
    getOrderTypeListHandle,
    {
      wait: 200,
    },
  );

  // const {
  //   run: getIssuesModuleList,
  //   data: issuesModuleList,
  //   isIdle: getIssuesModuleListIsIdle,
  //   isLoading: gettingIssuesModuleList,
  // } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  // const getIssuesModuleListHandle = () => {
  //   getIssuesModuleList(client(GET_ISSUES_MODULE));
  // };
  // const { run: getIssuesModuleListDebounce } = useDebounceFn(
  //   getIssuesModuleListHandle,
  //   {
  //     wait: 200,
  //   },
  // );

  const {
    run: getIssuesTypeList,
    data: issuesTypeList,
    isIdle: getIssuesTypeListIsIdle,
    isLoading: gettingIssuesTypeList,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesTypeListHandle = () => {
    getIssuesTypeList(client(GET_ISSUES_TYPE));
  };

  const { run: getIssuesTypeListDebounce } = useDebounceFn(
    getIssuesTypeListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getIssuesReasonList,
    data: issuesReasonList,
    isIdle: getIssuesReasonListIsIdle,
    isLoading: gettingIssuesReasonList,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesReasonListHandle = () => {
    if (selectedOrderTypeId) {
      getIssuesReasonList(client(GET_ISSUES_REASON_NEW(selectedOrderTypeId)));
    }
  };

  const { run: getIssuesReasonListDebounce } = useDebounceFn(
    getIssuesReasonListHandle,
    {
      wait: 200,
    },
  );

  //  获取指定医院已有产品线
  const {
    run: getProductList,
    isIdle: getProductListIsIdle,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductInfoType[]>();
  const getProductListHandle = () => {
    if (selectedHospId) {
      getProductList(client(GET_PRODUCT_DETAIL(selectedHospId)));
    }
  };

  // 获取产品线信息
  const {
    run: getAllProductList,
    isIdle: getAllProductListIsIdle,
    isLoading: gettingAllProductList,
    data: allProductList,
  } = useAsync<ProductType[]>();
  const getAllProductListHandle = () => {
    getAllProductList(client(GET_PRODUCT_LIST));
  };

  const {
    run: getFeedbackChannel,
    data: feedbackChannel,
    isIdle: getFeedbackChannelIsIdle,
    isLoading: gettingFeedbackChannel,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getFeedbackChannelHandle = () => {
    getFeedbackChannel(client(GET_ISSUES_FEEDBACK_CHANNEL));
  };
  const { run: getFeedbackChannelDebounce } = useDebounceFn(
    getFeedbackChannelHandle,
    {
      wait: 200,
    },
  );

  const onSubmit = (values: any) => {
    AddBreaklist(
      client(ADD_BREAKLIST, {
        method: "POST",
        data: {
          ...values,
          feedback_time: values.feedback_time.format(DateTimeFormate),
          attachment_id_list: fileList.map((i) => i.uid),
          checklist_id: serviceConfirmationfile.length
            ? serviceConfirmationfile.map((i) => i.uid)[0]
            : undefined,
          service_order_id: departureConfirmationfile.length
            ? departureConfirmationfile.map((i) => i.uid)[0]
            : undefined,
        },
      }),
    )
      .then((v) => {
        close();
        const content = `工单[${v.order_id || ""}]提交成功! `;
        message.success(content, 5, undefined);
        actionRef.current?.reload();
      })
      .catch((error) => {
        close();
        message.error("工单创建失败!", 3, undefined);
      });
  };

  const loading = getHospIsIdle || gettingHospList;
  const [options, setOptions] = useState<Options[]>([]);
  const [productLoading, setProductLoading] = useState(true);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [effectSwitch, setEffectSwitch] = useState<number>(1);
  const [defautlProduct, setDefaultProduct] = useState<any>();

  // 请求产品接口
  const fetchFirstLevelData = async (hospID: number | null) => {
    try {
      setProductLoading(true);
      let firstLevelData: any[] = [];
      let formattedData: any[] = [];
      if (hospID) {
        firstLevelData = await client(GET_PRODUCT_DETAIL_HAS_NOTONLINE(hospID));
      } else {
        firstLevelData = await client(GET_PRODUCT_LIST);
      }
      // 构建默认选中项
      const defaultSelectedValues = firstLevelData
        .filter((item: ProductInfoType) => item.product_version_id)
        .map((item: ProductInfoType) => ({
          product: item.product_id,
          product_version_id: item.product_version_id.toString(),
        }));

      formattedData = await fetchSecondLevelData(firstLevelData);
      setOptions(formattedData);

      setDefaultProduct(defaultSelectedValues);
      setProductLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // 请求产品版本接口
  const fetchSecondLevelData = async (firstLevelData: any[]) => {
    const formattedData: Options[] = [];
    for (const item of firstLevelData) {
      const current_product_version_id = item.product_version_id;
      const product: Options = {
        value: item.product_id || item.title,
        label:
          item.product_chinese_name || item.chinese_name || item.title || "",
        children: [],
      };
      const secondLevelData: any[] = await client(
        GET_PRODUCT_VERSION_BY_ID(item.product_id || item.title),
      );
      let fetch_ids: string[] = [];
      if (secondLevelData.length) {
        for (const version of secondLevelData) {
          if (product.children) {
            version.id.toString() === `${current_product_version_id}`
              ? product.children.unshift({
                  value: version.id.toString(),
                  label: `${
                    item.product_chinese_name ||
                    item.chinese_name ||
                    item.title ||
                    ""
                  } ${version.name}`,
                })
              : product.children.push({
                  value: version.id.toString(),
                  label: `${
                    item.product_chinese_name ||
                    item.chinese_name ||
                    item.title ||
                    ""
                  } ${version.name}`,
                });
          }
          fetch_ids.push(version.id.toString());
        }
      } else {
        if (product.children) {
          product.children.push({
            value: "",
            label: "无版本",
          });
        }
        fetch_ids.push("");
      }
      // 处理医院有产品线和产品版本, 但是对应产品线列表内没有对应版本的情况(例如,当前是历史版本,系统中已经删除了这个版本, 要允许选择.)
      if (item.product_version_id) {
        const include_current_version = fetch_ids.filter(
          (val: string) => val === `${item.product_version_id}`,
        );
        if (include_current_version.length === 0) {
          product.children?.unshift({
            value: `${item.product_version_id}`,
            label: `${item.product_chinese_name} ${item.product_version_name}`,
          });
        }
      }
      formattedData.push(product);
    }
    return formattedData;
  };

  // 清理产品线数据
  const resetProductSetting = () => {
    setOptions([]);
    form.setFieldsValue({ product_id_list: [] });
    setSelectedValues([]);
    setProductLoading(true);
  };

  // 重置所有数据
  const resetFormData = () => {
    const order_type_id = form.getFieldValue("order_type_id");
    form.resetFields();
    form.setFieldValue("order_type_id", order_type_id);
    resetProductSetting();
  };

  useEffect(() => {
    if (isAdditional) {
      // if (!!!issuesModuleList) {
      //   getIssuesModuleListDebounce();
      // }
      if (!!!issuesReasonList) {
        getIssuesReasonListDebounce();
      }
      if (!!!issuesTypeList) {
        getIssuesTypeListDebounce();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdditional]);

  useEffect(() => {
    getIssuesReasonListDebounce();
    form.setFieldValue("issue_reason_id", "");
  }, [selectedOrderTypeId]);

  useEffect(() => {
    if (isOpen) {
      resetFormData();
      form.setFieldsValue({ order_type_id: 1 });
      setEffectSwitch(1);
      setIsCustomer(true);
      setIsAdditional(false);
      setSelectedHospId(undefined);
      setFileList([]);
      setServiceConfirmationfile([]);
      setDepartureConfirmationfile([]);
      if (!!!hospList) {
        getHospDebounce();
      }
      if (!!!orderTypeList) {
        getOrderTypeListDebounce();
      }
      if (!!!feedbackChannel) {
        getFeedbackChannelDebounce();
      }
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!!!isCustomer) {
      // getAllProductListHandle();
      fetchFirstLevelData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomer]);

  useEffect(() => {
    if (selectedValues) {
      form.setFieldsValue({ product_id_list: selectedValues });
    }
  }, [selectedValues]);

  useEffect(() => {
    resetProductSetting();
    if (selectedHospId) {
      fetchFirstLevelData(selectedHospId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHospId]);

  useEffect(() => {
    if (effectSwitch === 1) {
      setOrderTypeHelpInfo(
        "日常客户反馈的故障，例如产品功能异常，数据预测失败，数据缺失等",
      );
    }
    if (effectSwitch === 2) {
      setOrderTypeHelpInfo(
        "日常客户提出的需求，例如使用咨询，修改报告模板，模型反馈，新增电脑安装客户端，数据/PACS/胶片对接等",
      );
    }
    if (effectSwitch === 3) {
      setOrderTypeHelpInfo("资产盘点、使用巡检、信息调研、回访等");
    }
  }, [effectSwitch]);

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="新建工单"
      centered
      className="breaklist-add-modal"
      onCancel={close}
      onOk={form.submit}
      confirmLoading={AddingBreaklist}
      width={"912px"}
      bodyStyle={{
        minHeight: "12rem",
        maxHeight: "88vh",
        overflow: "auto",
      }}
    >
      <Form form={form} {...formItemLayout} onFinish={onSubmit}>
        <Item
          name="order_type_id"
          label="工单类型"
          rules={[{ required: true, message: "必须选择工单类型" }]}
          help={orderTypeHelpInfo}
        >
          <Select
            loading={gettingOrderTypeList || getOrderTypeListIsIdle}
            placeholder="选择工单类型"
            dropdownRender={(originNode) => (
              <Spin spinning={gettingOrderTypeList || getOrderTypeListIsIdle}>
                {originNode}
              </Spin>
            )}
            onChange={(v) => {
              setEffectSwitch(v);
            }}
          >
            {orderTypeList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="title"
          label="标题"
          rules={[{ required: true, message: "必须填写标题" }]}
        >
          <Input placeholder="填写问题简述" />
        </Item>
        <Item
          name="customer_owner"
          label="客户所属"
          rules={[{ required: true, message: "必须选择客户所属" }]}
          initialValue={"商业化事业部"}
        >
          <Select
            placeholder="选择客户所属"
            onChange={(v) => {
              setSelectedHospId(undefined);
              form.setFieldValue("hospital_id", "");
              resetProductSetting();
              if (v === "商业化事业部") {
                setIsCustomer(true);
              } else {
                setIsCustomer(false);
              }
            }}
          >
            <Option value="商业化事业部">商业化事业部</Option>
            <Option value="其他">其他</Option>
          </Select>
        </Item>
        {isCustomer ? (
          <Item
            name="hospital_id"
            label="客户名称"
            rules={[{ required: true, message: "必须填写客户名称" }]}
          >
            <Select
              loading={loading}
              placeholder="选择客户名称"
              showSearch
              filterOption={HospSelectFilter}
              onChange={(id) => {
                setSelectedHospId(id);
              }}
              dropdownRender={(originNode) => (
                <Spin spinning={loading}>{originNode}</Spin>
              )}
              options={hospList?.map((i) => ({ label: i.name, value: i.id }))}
            />
          </Item>
        ) : (
          <Item
            name="customer_name"
            label="客户名称"
            rules={[{ required: true, message: "必须填写客户名称" }]}
          >
            <Input placeholder="填写客户名称" />
          </Item>
        )}
        {!!!isCustomer ? ( // 其他, 非必须
          <Spin spinning={productLoading}>
            <Item name="product_id_list" label="影响产品">
              <Cascader
                value={selectedValues}
                placeholder={"选择产品线"}
                // disabled={!!!selectedHospId}
                style={{ width: "100%" }}
                options={options}
                onChange={(v, item) => {
                  const resultMap = new Map();
                  item.forEach((i: any[]) => {
                    let k, v;
                    if (i.length === 1) {
                      const all_info = i[0];

                      const children_info =
                        all_info.children[all_info.children.length - 1];
                      k = all_info.value;
                      v = children_info.value;
                    } else {
                      const all_info = i[0];
                      const children_info = i[1];
                      k = all_info.value;
                      v = children_info.value;
                    }
                    resultMap.set(k, [k, v]);
                  });
                  const uniqueData = Array.from(resultMap.values());
                  form.setFieldValue("product_id_list", uniqueData);
                  setSelectedValues(uniqueData);
                }}
                dropdownRender={(originNode) => (
                  <Spin spinning={productLoading}>{originNode}</Spin>
                )}
                multiple
              />
            </Item>
          </Spin>
        ) : (
          <>
            {selectedHospId ? (
              <Spin spinning={productLoading}>
                <Item
                  name="product_id_list"
                  label="影响产品"
                  rules={[{ required: true, message: "必须选择影响产品" }]}
                >
                  <Cascader
                    value={selectedValues}
                    placeholder={
                      !!!selectedHospId ? "请先选择客户" : "选择产品线"
                    }
                    disabled={!!!selectedHospId}
                    style={{ width: "100%" }}
                    options={options}
                    onChange={(v, item) => {
                      const resultMap = new Map();
                      item.forEach((i: any[]) => {
                        let k, v;
                        if (i.length === 1) {
                          const all_info = i[0];
                          // 判断当前医院是否有该产品线, 有的话选择对应版本, 没有的话选择列表返回的最后一个.
                          if (defautlProduct) {
                            const has_product = defautlProduct.filter(
                              (item: any) => item.product == all_info.value,
                            );
                            if (has_product.length) {
                              k = all_info.value;
                              v = has_product[0].product_version_id;
                            }
                          }
                          const children_info =
                            all_info.children[all_info.children.length - 1];
                          k = k ? k : all_info.value;
                          v = v ? v : children_info.value;
                        } else {
                          const all_info = i[0];
                          const children_info = i[1];
                          k = all_info.value;
                          v = children_info.value;
                        }
                        resultMap.set(k, [k, v]);
                      });
                      const uniqueData = Array.from(resultMap.values());
                      form.setFieldValue("product_id_list", uniqueData);
                      setSelectedValues(uniqueData);
                    }}
                    dropdownRender={(originNode) => (
                      <Spin spinning={productLoading}>{originNode}</Spin>
                    )}
                    multiple
                  />
                </Item>
              </Spin>
            ) : (
              <Item
                name="product_id_list"
                label="影响产品"
                rules={[{ required: true, message: "必须选择影响产品" }]}
              >
                <Cascader
                  value={selectedValues}
                  placeholder={
                    !!!selectedHospId ? "请先选择客户" : "选择产品线"
                  }
                  disabled
                />
              </Item>
            )}
          </>
        )}
        <Item
          name="description"
          label="反馈详情"
          rules={[{ required: true, message: "必须填写反馈详情" }]}
          normalize={(v) => DisposeQuill(v)}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
              imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
              },
            }}
          />
        </Item>
        <Item
          name="feedback_channel_id"
          label="反馈渠道"
          rules={[{ required: true, message: "必须选择反馈渠道" }]}
        >
          <Select
            loading={gettingFeedbackChannel || getFeedbackChannelIsIdle}
            placeholder="选择反馈渠道"
            dropdownRender={(originNode) => (
              <Spin
                spinning={gettingFeedbackChannel || getFeedbackChannelIsIdle}
              >
                {originNode}
              </Spin>
            )}
          >
            {feedbackChannel?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="feedback_user"
          label="反馈人姓名"
          // rules={[{ required: true, message: "必须填写反馈人姓名" }]}
        >
          <Input placeholder="填写反馈人姓名" />
        </Item>
        <Item
          name="contact"
          label="反馈人联系方式"
          // rules={[{ required: true, message: "必须填写反馈人联系方式" }]}
        >
          <Input placeholder="填写反馈人联系方式" />
        </Item>
        <Item
          name="feedback_time"
          label="反馈时间"
          rules={[{ required: true, message: "必须选择反馈时间" }]}
        >
          <DatePicker
            showTime
            placeholder="请选择反馈时间"
            style={{ width: "100%" }}
          />
        </Item>
        {effectSwitch === 1 ? (
          <>
            <Item
              name="influence_function"
              label="影响功能"
              rules={[{ required: true, message: "必须选择影响功能" }]}
            >
              <Select placeholder="选择影响功能">
                <Option value="核心功能">核心功能</Option>
                <Option value="次要功能">次要功能</Option>
                <Option value="附加功能">附加功能</Option>
              </Select>
            </Item>
            <Item
              name="influence_sphere"
              label="影响范围"
              rules={[{ required: true, message: "必须选择影响范围" }]}
            >
              <Select placeholder="选择影响范围">
                <Option value="完全不可用">完全不可用</Option>
                <Option value="部分不可用">部分不可用</Option>
                <Option value="不影响使用">不影响使用</Option>
              </Select>
            </Item>
          </>
        ) : (
          <></>
        )}
        <Item
          name="is_additional_recording"
          label="是否为补录"
          rules={[{ required: true, message: "必须选择是否为补录" }]}
          initialValue={0}
        >
          <Radio.Group
            onChange={(v) => {
              const value = v.target.value;
              if (value) {
                setIsAdditional(true);
              } else {
                setIsAdditional(false);
              }
            }}
          >
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Item>
        {isAdditional ? (
          <>
            <Item
              name="debug_info"
              label="定位及补充记录"
              rules={[{ required: true, message: "必须填写定位及补充记录" }]}
            >
              <ReactQuill
                theme={"snow"}
                modules={{
                  toolbar: editQuillToolbarOption,
                  imageResize: {
                    parchment: Quill.import("parchment"),
                    modules: ["Resize", "DisplaySize"],
                  },
                }}
              />
            </Item>
            <Item
              name="solution_info"
              label="操作及解决"
              rules={[{ required: true, message: "必须填写操作及解决" }]}
            >
              <ReactQuill
                theme={"snow"}
                modules={{
                  toolbar: editQuillToolbarOption,
                  imageResize: {
                    parchment: Quill.import("parchment"),
                    modules: ["Resize", "DisplaySize"],
                  },
                }}
              />
            </Item>
            <Item
              name="is_deal_with_on_site"
              label="是否现场处理"
              rules={[{ required: true, message: "必须选择是否现场处理" }]}
            >
              <Radio.Group>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>
            </Item>
            <Item
              name="response_time"
              label="响应时长"
              // rules={[{ required: true, message: "必须填写响应时长" }]}
            >
              <InputNumber
                precision={0}
                addonAfter={"min"}
                controls
                min={0}
                placeholder="填写响应时长"
              />
            </Item>
            <Item
              name="issue_type_id"
              label="反馈类型"
              rules={[{ required: true, message: "必须选择反馈类型" }]}
            >
              <Select
                loading={gettingIssuesTypeList || getIssuesTypeListIsIdle}
                dropdownRender={(originNode) => (
                  <Spin
                    spinning={gettingIssuesTypeList || getIssuesTypeListIsIdle}
                  >
                    {originNode}
                  </Spin>
                )}
                onChange={(id) => {
                  setSelectedOrderTypeId(id);
                }}
                placeholder="请选择反馈类型"
              >
                {issuesTypeList?.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              name="issue_reason_id"
              label="反馈归因"
              rules={[{ required: true, message: "必须选择反馈归因" }]}
            >
              <Select
                placeholder={
                  !!!selectedOrderTypeId ? "请先选择反馈类型" : "选择反馈归因"
                }
                disabled={!!!selectedOrderTypeId}
                loading={gettingIssuesReasonList || getIssuesReasonListIsIdle}
                dropdownRender={(originNode) => (
                  <Spin
                    spinning={
                      gettingIssuesReasonList || getIssuesReasonListIsIdle
                    }
                  >
                    {originNode}
                  </Spin>
                )}
              >
                {issuesReasonList?.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Item>
            {/* <Form.Item
              name="checklist_id"
              label="离场确认单"
              rules={[{ required: true, message: "必须上传离场确认单" }]}
            >
              <Upload
                headers={UploadHeaders}
                action={ADD_FILE}
                fileList={serviceConfirmationfile}
                beforeUpload={limitUpload}
                onChange={(info) =>
                  handleUpload(info, setServiceConfirmationfile)
                }
              >
                {serviceConfirmationfile.length ? null : (
                  <Button icon={<PaperClipOutlined />}>点击上传</Button>
                )}
              </Upload>
            </Form.Item> */}
            <Form.Item
              name="service_order_id"
              label="服务确认单"
              // rules={[{ required: true, message: "必须上传服务确认单" }]}
              getValueFromEvent={(e) => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
            >
              <Upload
                headers={UploadHeaders}
                action={ADD_FILE}
                maxCount={1}
                fileList={departureConfirmationfile}
                beforeUpload={limitUpload}
                onChange={(info) =>
                  handleUpload(info, setDepartureConfirmationfile)
                }
              >
                {departureConfirmationfile.length ? null : (
                  <Button icon={<PaperClipOutlined />}>点击上传</Button>
                )}
              </Upload>
            </Form.Item>
          </>
        ) : null}
        <Form.Item name="attachment_id_list" label="附件上传">
          <Upload
            headers={UploadHeaders}
            maxCount={10}
            multiple={true}
            action={ADD_FILE}
            listType="picture-card"
            fileList={fileList}
            beforeUpload={limitUpload}
            onChange={(info) => handleUpload(info, setFileList)}
            onPreview={(f) =>
              handlePreview(f, {
                setPreviewImage,
                setPreviewOpen,
                setPreviewTitle,
              })
            }
          >
            {fileList.length >= 10 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传(限10个)</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </Form>
      <Modal
        centered
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        width={"61.8%"}
      >
        <div style={{ width: "100%" }}>
          <img alt="previwe" style={{ width: "100%" }} src={previewImage} />
        </div>
      </Modal>
    </Modal>
  );
};
