import { useState } from "react";
import { Button, Descriptions } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { DesItemLabel, LineDiv } from "../../utils";
import { DetailCtrlProps } from "../interface";
import BaseInfoEdit from "./edit";

const BaseInfo = ({ detail, reload, setDisChange }: DetailCtrlProps) => {
  const [baseInfoEditable, setBaseInfoEditableable] = useState(false);

  return baseInfoEditable ? (
    <BaseInfoEdit
      editingItem={detail}
      setEditable={setBaseInfoEditableable}
      reload={reload}
      setDisChange={setDisChange}
    />
  ) : (
    <Descriptions
      title="客户基本信息"
      column={2}
      extra={
        baseInfoEditable ? null : (
          <Button
            onClick={() => {
              setBaseInfoEditableable(true);
              setDisChange(true);
            }}
            icon={<EditOutlined />}
          >
            编辑
          </Button>
        )
      }
    >
      <Descriptions.Item label={<DesItemLabel label={"客户名称"} />}>
        <LineDiv>{detail?.name}</LineDiv>
      </Descriptions.Item>
      <Descriptions.Item label={<DesItemLabel label={"区域"} />}>
        <LineDiv>{detail?.area_name}</LineDiv>
      </Descriptions.Item>
      <Descriptions.Item label={<DesItemLabel label={"客户编码"} />}>
        <LineDiv>{detail?.code}</LineDiv>
      </Descriptions.Item>
      <Descriptions.Item label={<DesItemLabel label={"省份"} />}>
        <LineDiv>{detail?.province_name}</LineDiv>
      </Descriptions.Item>
      <Descriptions.Item label={<DesItemLabel label={"客户类型"} />}>
        <LineDiv>
          {detail?.type
            ? `${detail?.type[0]} ${
                detail?.type[1] ? `(${detail?.type[1]})` : ""
              }`
            : ""}
        </LineDiv>
      </Descriptions.Item>
      <Descriptions.Item label={<DesItemLabel label={"城市"} />}>
        <LineDiv>{detail?.city_name}</LineDiv>
      </Descriptions.Item>
      <Descriptions.Item label={<DesItemLabel label={"客户地址"} />}>
        <LineDiv>{detail?.address}</LineDiv>
      </Descriptions.Item>
      <Descriptions.Item label={<DesItemLabel label={"市/区/县"} />}>
        <LineDiv>{detail?.county_name}</LineDiv>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default BaseInfo;
