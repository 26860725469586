import { Dispatch, SetStateAction, useEffect } from "react";
import { Modal, Form, DatePicker, Select, Segmented, Alert } from "antd";
import dayjs from "dayjs";

import { DisposeForm, formatDate, RID } from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { ProductType } from "../../../setting/customer-setting/interface";
import { GET_PRODUCT_TYPE } from "../../../setting/customer-setting/api";
import { DateStep } from "../../rate-detail/interface";
import { useDownload } from "utils/download";
import { GET_RATE } from "lib/api";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const RateOutput = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  const [form] = Form.useForm();

  const client = useHttp();
  const DownloadClient = useDownload();

  const { run: getFile, isLoading: gettingFile } = useAsync();

  const {
    run: getProductList,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductType[]>();
  const getProductListFunc = () => {
    getProductList(client(GET_PRODUCT_TYPE));
  };

  const submit = (value: any) => {
    getFile(
      DownloadClient(GET_RATE, {
        data: {
          status: 1,
          unit: value?.unit || "day",
          product_line: value?.product_line?.map((i: string) => i).join("|"),
          start: formatDate(value?.date[0] || dayjs().subtract(30, "days")),
          end: formatDate(value?.date[1] || dayjs().subtract(1, "days")),
        },
      }),
    );
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      getProductListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="点击率导出选项"
      maskClosable={false}
      destroyOnClose
      open={visible}
      onCancel={() => setVisible(false)}
      cancelButtonProps={{ disabled: gettingFile }}
      okButtonProps={{
        htmlType: "submit",
        loading: gettingFile,
      }}
      onOk={() => form.submit()}
      okText={"导出"}
      width={720}
    >
      <Alert
        type="info"
        showIcon
        message={`点击率仅导出医院状态为“使用中”的数据`}
      />
      <br />
      <Form
        id="rateOutputForm"
        form={form}
        onFinish={submit}
        initialValues={{
          product_line: [RID],
          date: [dayjs().subtract(30, "days"), dayjs().subtract(1, "days")],
          unit: DateStep.day,
        }}
      >
        <Form.Item
          name="unit"
          label="导出周期"
          normalize={(v) => DisposeForm(v)}
        >
          <Segmented
            options={[
              { label: "日", value: DateStep.day },
              { label: "周", value: DateStep.week },
              { label: "月", value: DateStep.month },
              { label: "年", value: DateStep.year },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="date"
          label="起止日期"
          normalize={(v) => DisposeForm(v)}
        >
          <RangePicker allowClear />
        </Form.Item>
        <Form.Item
          name="product_line"
          label="产品线"
          normalize={(v) => DisposeForm(v)}
          rules={[{ required: true, message: "必须选择一个产品线" }]}
        >
          <Select
            loading={gettingProductList}
            mode="multiple"
            placeholder="选择产品线"
          >
            {productList?.map((i, index) => (
              <Option key={index} value={i.title}>
                {i.chinese_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
