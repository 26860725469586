import { useState, Fragment } from "react";
import { Button, Descriptions, Space, message, Popconfirm, Spin } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { useDebounceFn } from "ahooks";

import { useHttp } from "utils/http";
import { PermissionAssociationSettingAdd } from "./add";
import { PermissionAssociationSettingEdit } from "./edit";
import { useAsync } from "utils/hooks/useAsync";
import { useMount } from "utils";
import {
  SettingAPI,
  PermissionAssosiationType,
} from "authenticated-app/pc/setting/customer-setting/interface";

const PermissionAssociationSetting = ({ ctrl }: { ctrl: SettingAPI }) => {
  // 必要信息state
  const [addVisible, setAddVisible] = useState(false);

  const [editVisible, setEditVisible] = useState(false);
  const [edittingInfo, setEditingInfo] = useState<PermissionAssosiationType>();

  const client = useHttp();

  const {
    run: getSettingList,
    isIdle,
    isLoading: gettingSettingList,
    data: permissionAssociationList,
  } = useAsync<PermissionAssosiationType[]>();
  const getSettingListHandle = () => {
    getSettingList(client(ctrl.GET_SETTING));
  };
  const { run: getSettingListDebounce } = useDebounceFn(getSettingListHandle, {
    wait: 200,
  });

  const reload = getSettingListHandle;
  const loading = gettingSettingList || isIdle;

  useMount(getSettingListDebounce);

  const delSetting = (id: number) => {
    if (id)
      client(ctrl.DEL_SETTING(id), { method: "DELETE" })
        .then(() => {
          message.success("删除成功！");
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("删除失败，请联系管理员！");
        });
    else message.error("无法进行删除，请稍后重试或联系管理员！");
  };

  const SettingDescItem = (item: PermissionAssosiationType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`permissionList${key}`}>
        <Descriptions.Item label={title("编号")} className={className()}>
          {key + 1}
        </Descriptions.Item>
        <Descriptions.Item label={title("名称")} className={className()}>
          {item?.title || ""}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("操作")}
          className={className()}
        >
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              loading={editVisible && item.id === edittingInfo?.id}
              onClick={() => {
                setEditingInfo(item);
                setEditVisible(true);
              }}
            />
            <Popconfirm
              title={`确认删除【${item.title}】？`}
              onConfirm={() => delSetting(item.id)}
              okText="确认"
              cancelText="取消"
              icon={<ExclamationCircleOutlined />}
              style={{ color: "red" }}
            >
              <Button
                danger
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Spin spinning={loading}>
      <Descriptions
        column={3}
        bordered={permissionAssociationList?.length ? true : false}
        title={"权限关联规则"}
        layout="vertical"
        extra={
          <Space>
            <Button
              loading={addVisible}
              icon={<PlusOutlined />}
              onClick={() => setAddVisible(true)}
            >
              新增
            </Button>
            <Button
              loading={loading}
              icon={<RedoOutlined />}
              type={"primary"}
              onClick={reload}
            >
              刷新
            </Button>
          </Space>
        }
      >
        {permissionAssociationList
          ?.sort((a, b) => a.id - b.id)
          ?.map((item, index) => SettingDescItem(item, index))}
      </Descriptions>
      <PermissionAssociationSettingAdd
        CTRL_ADD={ctrl.ADD_SETTING}
        visible={addVisible}
        setVisible={setAddVisible}
        reload={reload}
      />
      <PermissionAssociationSettingEdit
        CTRL_UPDATE={ctrl.UPDATE_SETTING}
        edittinItem={edittingInfo}
        visible={editVisible}
        setVisible={setEditVisible}
        reload={reload}
      />
    </Spin>
  );
};

export default PermissionAssociationSetting;
