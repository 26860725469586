import { SetStateAction, Dispatch, useEffect } from "react";
import { Modal, Form, Input, Select, message } from "antd";

import { useHttp } from "utils/http";
import { GET_HOS_EQPT, UPDATE_EQPT_RECORD } from "lib/api";
import { DockingEqptType } from "../interface";
import { EqptType } from "../../hardwareTabPane/interface";
import { DisposeForm } from "utils";
import { useAsync } from "utils/hooks/useAsync";

const { TextArea } = Input;
const { Option } = Select;

export const EqptRecordInfoEdit = ({
  hospID,
  editingItem,
  visible,
  setVisible,
  reload,
}: {
  hospID: number | null;
  editingItem: DockingEqptType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getEqptTypeList,
    isLoading: gettingEqptTypeList,
    data: eqptTypeList,
  } = useAsync<EqptType[]>();
  const getEqptTypeListFunc = () => {
    getEqptTypeList(client(GET_HOS_EQPT));
  };

  const submit = (value: any) => {
    let id = editingItem?.id;
    if (id && hospID)
      client(UPDATE_EQPT_RECORD(id), {
        data: {
          ...value,
          hospital_id: parseInt(hospID.toString()),
        },
        method: "PUT",
      })
        .then(() => {
          message.success("修改成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    else message.error("修改失败，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    if (visible) {
      getEqptTypeListFunc();
      form.setFieldsValue({
        ...editingItem,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="编辑设备信息"
      destroyOnClose
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={form.submit}
      okText="提交"
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={submit}
      >
        <Form.Item
          name="hos_eqpt_type_id"
          label="设备类型"
          rules={[{ required: true, message: "必须选择设备类型" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select loading={gettingEqptTypeList} placeholder="选择设备类型">
            {eqptTypeList?.map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="hos_eqpt_oem_name"
          label="设备厂商"
          rules={[{ required: true, message: "必须输入设备厂商" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="node_info"
          label="AE信息"
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="permission"
          label="权限说明"
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="location"
          label="设备位置"
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item name="remark" label="备注" normalize={(v) => DisposeForm(v)}>
          <TextArea allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};
