import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Select, message } from "antd";
import { useDebounceFn } from "ahooks";

import {
  DisposeForm,
  defaultUserStatus,
  selectFilter,
  statusOptions,
  useMount,
} from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import {
  CityType,
  HosNodesType,
} from "authenticated-app/pc/customer/customer-list/interface";
import { GET_HOSP_CITY, GET_HOSP_NODES } from "lib/api";
import { GET_USER_LIST, UPDATE_FRONT_AUTH } from "../api";
import {
  BaseSingleSettingType,
  IUser,
} from "authenticated-app/pc/setting/customer-setting/interface";
import { GET_EMPLOYEE_TYPE } from "authenticated-app/pc/setting/customer-setting/api";

const { Option } = Select;

export const FrontAuthSetting = () => {
  const [form] = Form.useForm();
  const client = useHttp();

  const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [provinceList, setProvinceList] = useState<HosNodesType[]>();

  const [searchArea, setSearchArea] = useState<number | null>(null);
  const [searchProvince, setSearchProvince] = useState<number | null>(null);

  const {
    run: getEmployeeTypeList,
    isLoading: gettingEmployeeTypeList,
    data: employeeList,
  } = useAsync<BaseSingleSettingType[]>();
  const getEmployeeTypeListHandle = () => {
    getEmployeeTypeList(client(GET_EMPLOYEE_TYPE));
  };
  const { run: getEmployeeTypeListDebounce } = useDebounceFn(
    getEmployeeTypeListHandle,
    { wait: 200 },
  );

  const {
    run: getHosNodes,
    isLoading: gettingNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };
  const { run: getHosNodesDebounce } = useDebounceFn(getHosNodesHandle, {
    wait: 200,
  });

  const {
    run: getUserList,
    isLoading: gettingUserList,
    data: userList,
  } = useAsync<IUser[]>();
  const getUserListHandle = () => {
    getUserList(client(GET_USER_LIST));
  };
  const { run: getUserListDebounce } = useDebounceFn(getUserListHandle, {
    wait: 200,
  });

  const {
    run: getCityList,
    isLoading: gettingCity,
    data: cityList,
    setData: setCityList,
  } = useAsync<CityType[]>();

  const { run: updateAuth, isLoading: updattingAuth } = useAsync();

  const submit = (value: any) => {
    updateAuth(
      client(UPDATE_FRONT_AUTH, {
        data: {
          ...value,
          hospital_status: value?.hospital_status
            .map((i: string) => i)
            .join("|"),
        },
        method: "POST",
      }),
    )
      .then((res) => {
        message.success(res?.message || "更新成功！");
      })
      .catch((e) => {
        console.error(e);
        message.error("更新失败！请检查或者联系管理员");
      });
  };

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.nodeLevel === 1) {
        areaListTMP?.push(item);
      }
    });
    setAreaList(areaListTMP);
  }, [hosNodes]);

  useEffect(() => {
    let provinceListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.parentNodeId === searchArea && item.parentNodeId !== null) {
        provinceListTMP?.push(item);
      }
    });
    setProvinceList(provinceListTMP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchArea]);

  useEffect(() => {
    if (searchProvince) {
      getCityList(client(GET_HOSP_CITY(searchProvince)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProvince]);

  useMount(() => {
    getEmployeeTypeListDebounce();
    getHosNodesDebounce();
    getUserListDebounce();
  });

  return (
    <Card title={"人员授权"}>
      <Form form={form} onFinish={submit}>
        <Row gutter={[24, 8]}>
          <Col>
            <Form.Item
              name="user_type_id"
              label="人员类型"
              rules={[{ required: true, message: "必须选择人员类型" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                disabled={gettingEmployeeTypeList}
                loading={gettingEmployeeTypeList}
                placeholder="人员类型"
                style={{ width: 108 }}
              >
                {employeeList?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Row gutter={[4, 4]}>
              <Col>
                <Form.Item
                  name="area_id"
                  label="负责区域"
                  rules={[{ required: true, message: "至少选择区域" }]}
                  normalize={(v) => DisposeForm(v)}
                >
                  <Select
                    disabled={gettingNodes}
                    loading={gettingNodes}
                    placeholder="区域"
                    style={{ width: 96 }}
                    onChange={(nodeId) => {
                      setSearchProvince(null);
                      form.setFieldValue("province_id", null);
                      form.setFieldValue("city_id", null);
                      setCityList([]);
                      setSearchArea(nodeId);
                    }}
                  >
                    {areaList?.map((item, index) => (
                      <Option key={index} value={item.nodeId}>
                        {item.nodeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="province_id" normalize={(v) => DisposeForm(v)}>
                  <Select
                    disabled={gettingNodes}
                    loading={gettingNodes}
                    allowClear
                    placeholder="省份"
                    style={{ width: 108 }}
                    showSearch
                    filterOption={selectFilter}
                    onChange={(nodeId) => {
                      form.setFieldValue("city_id", null);
                      setSearchProvince(nodeId);
                    }}
                  >
                    {provinceList?.map((item, index) => (
                      <Option key={index} value={item.nodeId}>
                        {item.nodeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="city_id" normalize={(v) => DisposeForm(v)}>
                  <Select
                    disabled={gettingCity}
                    loading={gettingCity}
                    allowClear
                    placeholder="城市"
                    style={{ width: 108 }}
                    showSearch
                    filterOption={selectFilter}
                  >
                    {cityList?.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.cityName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col>
            <Form.Item
              label="客户状态"
              name="hospital_status"
              rules={[{ required: true, message: "必须选择客户状态" }]}
              normalize={(v) => DisposeForm(v)}
              initialValue={defaultUserStatus}
            >
              <Select
                mode="multiple"
                maxTagCount={1}
                allowClear
                placeholder="客户状态"
                style={{ width: 170 }}
              >
                {statusOptions.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="负责人"
              name="user_id"
              rules={[{ required: true, message: "必须选择负责人" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                disabled={gettingUserList}
                loading={gettingUserList}
                placeholder="负责人"
                style={{ width: 180 }}
                showSearch
                filterOption={selectFilter}
              >
                {userList?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.userName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={updattingAuth}
                loading={updattingAuth}
              >
                提交
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
