import { useEffect, useState, useRef } from "react";
import { Row, Col, Divider, Modal, Button } from "antd";
import {
  ProTable,
  ActionType,
  FormInstance,
  ProColumns,
} from "@ant-design/pro-components";
import { HOSPITAL_ISSUES_STATS } from "../../interface";
import { useHttp } from "utils/http";
import { GET_HOSPITAL_ISSUES_STATS } from "lib/api";
import { MyLineChart, MyLineChartType } from "../../chart/line-chart";
import { useDownload } from "utils/download";
import { useAsync } from "utils/hooks/useAsync";

export const IssuesStatsTable = () => {
  const client = useHttp();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const [columns, setColumns] = useState<ProColumns<HOSPITAL_ISSUES_STATS>[]>(
    [],
  );
  const [page, setPage] = useState<number>(1);
  const downloadClient = useDownload();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [chartData, setChartData] = useState<
    { month: string; count: number }[]
  >([]);
  const [hospitalName, setHospitalName] = useState<string>("");

  const search = async (params: any): Promise<HOSPITAL_ISSUES_STATS[]> => {
    const response = await client(GET_HOSPITAL_ISSUES_STATS, {
      method: "GET",
      data: params,
    });
    return response;
  };

  const [downloadParams, setDownloadParams] = useState<any>();
  const { run: getFile, isLoading: gettingFile } = useAsync();
  const getFileHandle = () => {
    getFile(
      downloadClient(GET_HOSPITAL_ISSUES_STATS, {
        data: { is_download: 1, ...downloadParams },
      }),
    );
  };

  const generateColumns = (data: HOSPITAL_ISSUES_STATS[]) => {
    const months = new Set<string>();
    data.forEach((item) => {
      item.result.forEach((res) => months.add(res.month));
    });

    const baseColumns: ProColumns<HOSPITAL_ISSUES_STATS>[] = [
      {
        title: "医院名称",
        dataIndex: "hospital_name",
        key: "hospital_name",
        valueType: "text",
      },
      {
        title: "医院编码",
        dataIndex: "hospital_code",
        key: "hospital_code",
        valueType: "text",
      },
      {
        title: "区域",
        dataIndex: "area_name",
        key: "province_name",
        valueType: "text",
        search: false,
      },
      {
        title: "省份",
        dataIndex: "province_name",
        key: "province_name",
        valueType: "text",
        search: false,
      },
      {
        title: "城市",
        dataIndex: "city_name",
        key: "city_name",
        valueType: "text",
        search: false,
      },
    ];

    const dynamicColumns: ProColumns<HOSPITAL_ISSUES_STATS>[] = Array.from(
      months,
    )
      .sort()
      .map((month) => ({
        title: month,
        dataIndex: month,
        key: month,
        valueType: "digit",
        render: (_, record) => {
          const result = record.result.find((res) => res.month === month);
          return result ? result.count : 0;
        },
        search: false,
        sorter: (a, b) => {
          const countA =
            a.result.find((res) => res.month === month)?.count || 0;
          const countB =
            b.result.find((res) => res.month === month)?.count || 0;
          return countA - countB;
        },
      }));

    setColumns([...baseColumns, ...dynamicColumns]);
  };

  const fetchDataAndSetColumns = async (params: any) => {
    const data = await search(params);
    generateColumns(data);
    return data;
  };

  const handleRowClick = (record: HOSPITAL_ISSUES_STATS) => {
    setHospitalName(record.hospital_name);
    setChartData(record.result);
    setModalVisible(true);
  };

  useEffect(() => {
    setPage(1);
    actionRef.current?.reload();
  }, []);

  const chartConfig: MyLineChartType = {
    xData: chartData.map((item) => item.month),
    series: [
      {
        data: chartData.map((item) => item.count),
        type: "line",
        smooth: true,
      },
    ],
  };

  return (
    <>
      <Divider style={{ marginTop: "4px", marginBottom: "5px" }} />
      <Row>
        <Col span={24}>
          <ProTable<HOSPITAL_ISSUES_STATS>
            style={{
              minWidth: 988,
            }}
            rowKey="hospital_id"
            actionRef={actionRef}
            formRef={formRef}
            cardBordered
            columns={columns}
            request={async (params) => {
              try {
                const data = await fetchDataAndSetColumns(params);
                return {
                  data: data,
                  success: true,
                };
              } catch (e) {
                return { success: false };
              }
            }}
            pagination={{
              showQuickJumper: true,
              showSizeChanger: true,
              pageSize: 15,
              current: page,
              onChange: (v) => {
                setPage(v);
              },
            }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            headerTitle="各医院月度故障工单数量（近一年）"
            toolBarRender={() => [
              <Button
                key="export"
                type="primary"
                onClick={getFileHandle}
                loading={gettingFile}
                disabled={gettingFile}
              >
                导出
              </Button>,
            ]}
          />
        </Col>
      </Row>
      <Modal
        title={`${hospitalName}`}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
        bodyStyle={{ padding: 0 }}
      >
        <div style={{ height: 400, width: "100%" }}>
          <MyLineChart Data={chartConfig} />
        </div>
      </Modal>
      <Divider
        style={{
          marginTop: "4px",
          marginBottom: "4px",
          borderTop: "1px solid grey",
        }}
      />
    </>
  );
};
