import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from "react";
import { useAsync } from "utils/hooks/useAsync";
import { useDebounceFn } from "ahooks";
import {
  ProDescriptions,
  ProCard,
  ModalForm,
  ProForm,
  ProFormText,
  ProFormSelect,
  ProTable,
  ActionType,
} from "@ant-design/pro-components";
import {
  Descriptions,
  message,
  Tabs,
  Modal,
  Divider,
  Space,
  Popover,
  Tag,
  Button,
  Form,
  Select,
  Skeleton,
} from "antd";
import {
  EditOutlined,
  CloseCircleFilled,
  ExclamationCircleFilled,
  InfoCircleOutlined,
  InfoCircleFilled,
  PlusOutlined,
  PlusSquareTwoTone,
} from "@ant-design/icons";

import { useHttp } from "utils/http";
import {
  DEL_PRODUCT_DETAIL,
  ADD_PRODUCT_RECORD,
  UPDATE_PRODUCT_RECORD,
  DEL_PRODUCT_RECORD,
  ADD_PRODUCT_RETAIN,
  UPDATE_PRODUCT_RETAIN,
  DEL_PRODUCT_RETAIN,
  UPDATE_PRODUCT_DETAIL,
  GET_PRODUCT_LIST,
  GET_DEPLOY_TYPE,
  GET_PRODUCT_DEPARTMENT,
  GET_SERVICE_TYPE,
  GET_USING_STATUS,
  GET_SERVICE_LEVEL,
  UPDATE_CUSTOMIZATION,
  GET_PRODUCT_VERSION_BY_ID,
  GET_PRODUCT_CLIENT,
  UPDATE_GLOBAL_PRODUCT_VERSION,
  ADD_GLOBAL_PRODUCT_VERSION,
  GET_HARDWARES_LIST,
  GET_LICENSE_INFO,
} from "lib/api";
import { ProductDesItemLabel, LineDiv } from "../../utils";

import {
  ProductInfoType,
  ProductBaseInfoType,
  ProductVersionType,
} from "../interface";
import { HardwaresType } from "../../hardwareTabPane/interface";

import UpdateRecord from "../update-record";
import UsingRate from "../using-rate";
import { ProductBaseInfoAdd } from "./add";
import { ProductBaseInfoEdit } from "./edit";
import BaseRetainInfo from "../../infoTabPane/base-retain-info";
import { RID, THID, valueIsUseful } from "utils";
import ThUsingRate from "../using-rate/thUsingRate";
import ProductManitenancInfo from "../maintenance-info";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

const ProductBaseInfo = ({
  hospID,
  detail,
  reload,
  setDisChange,
  changeCount,
}: {
  hospID: number | null;
  detail: [ProductInfoType[], ProductBaseInfoType[]] | undefined;
  reload: () => void;
  setDisChange: Dispatch<SetStateAction<boolean>>;
  changeCount: any;
}) => {
  const [{ tab, product }, setProduct] = useUrlQueryParam(["tab", "product"]);

  const [isEditable, setIsEditable] = useState(false);
  const [edittingID, setEdittingID] = useState<string>();
  const [addInfoVisible, setAddInfoVisible] = useState(false);

  const client = useHttp();
  const actionRef = useRef<ActionType>();
  const [form] = Form.useForm<ProductBaseInfoType>();
  const [globalInfoEditVisible, setGlobalInfoEditVisible] = useState(false);
  const [globalInfoAddVisible, setGlobalInfoAddVisible] = useState(false);

  const onEdit = (
    targetKey:
      | string
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>,
    action: "add" | "remove",
  ) => {
    if (action === "add") {
      setAddInfoVisible(true);
    } else {
      setDisChange(true);
      setEdittingID(targetKey as string);
      if (!isEditable) {
        setIsEditable(true);
      }
    }
  };

  useEffect(() => {
    if (actionRef.current) {
      actionRef.current.reload();
    }
  }, [hospID]);

  const getLicenseInfo = async (...params: any) => {
    if (hospID) {
      return await client(GET_LICENSE_INFO(hospID), {
        data: { ...params },
      });
    }
  };

  const delInfo = (id: number, product_id: string) => {
    Modal.confirm({
      title: "删除确认",
      content: `确认删除【${product_id}】的信息？`,
      okText: "删除",
      cancelText: "取消",
      okButtonProps: { type: "primary", danger: true },
      closable: true,
      icon: <ExclamationCircleFilled style={{ color: "#f5222d" }} />,
      onOk: () =>
        client(DEL_PRODUCT_DETAIL(id), { method: "DELETE" })
          .then(() => {
            message.success("删除成功！");
            setIsEditable(false);
            setDisChange(false);
            Modal.destroyAll();
            reload();
          })
          .catch((e) => {
            console.error(e);
            message.error("删除失败，请联系管理员！");
          }),
    });
  };

  const getProductStatus = (status: number, icon?: boolean) => {
    if (status === 1) {
      return icon ? "#009b51" : "已授权";
    } else if (status === 2) {
      return icon ? "#ffae11" : "未授权";
    } else if (status === 3) {
      return icon ? "#009b51" : "无需授权";
    } else {
      return "未知状态";
    }
  };

  const [detailInfo, setDetailInfo] = useState<any[]>();

  useEffect(() => {
    if (tab === "product" && edittingID === "0") {
      setEdittingID(edittingID);
      setProduct({ product: edittingID });
    } else if (tab === "product" && edittingID) {
      let index = detailInfo?.findIndex((d) => d.id.toString() === edittingID);
      if (index !== undefined && index >= 0 && detail) {
        setProduct({ product: edittingID });
      } else {
        if (detailInfo && detailInfo[1]) {
          let settingID = detailInfo[1].id.toString();
          setEdittingID(settingID);
          setProduct({ product: settingID });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCount, edittingID]);

  useEffect(() => {
    if (product) {
      if (product === "0") {
        setEdittingID("0");
      } else {
        let index = detailInfo?.findIndex((d) => d.id.toString() === product);
        if (index !== undefined && index >= 0 && detail) {
          setEdittingID(product);
        } else {
          setEdittingID("0");
        }
      }
    } else if (detailInfo && detailInfo[0]) {
      setEdittingID("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailInfo]);

  const [hardwareIdList, setHardwareIdList] = useState<number[]>([]);

  useEffect(() => {
    if (Array.isArray(detail) && detail.length > 0) {
      if (Array.isArray(detail[0])) {
        const newHardwareIds = detail[0]
          .map((item: any) => item.hardware_id)
          .filter((id) => id);
        setHardwareIdList(newHardwareIds);
      }
      const remainingElements = detail.slice(1); // 除去第一个元素
      setDetailInfo(remainingElements);
    }
  }, [detail]);

  const {
    run: getUbVersionList,
    isLoading: gettingUbVersionList,
    data: ubVersionList,
  } = useAsync<ProductVersionType[]>();

  const {
    run: updateGlobalProductVersion,
    isLoading: updateGlobalProductVersionLoading,
  } = useAsync<ProductBaseInfoType>();
  const updateGlobalProductVersionFunc = (
    hospId: number,
    value: ProductBaseInfoType,
  ) => {
    updateGlobalProductVersion(
      client(UPDATE_GLOBAL_PRODUCT_VERSION(hospId), {
        data: {
          ...value,
        },
        method: "PUT",
      }),
    )
      .then((res) => {
        message.success("更新成功！");
        setGlobalInfoEditVisible(false);
        reload();
      })
      .catch((error: any) => {
        message.error("更新失败，请联系管理员！", 5);
      });
  };

  const getHardwareInfo = (params: any) => {
    if (hospID) {
      return client(GET_HARDWARES_LIST(hospID), {
        data: { ...params },
      });
    }
  };

  const AddGlobalInfo = (value: any) => {
    if (hospID) {
      client(ADD_GLOBAL_PRODUCT_VERSION, {
        data: {
          ...value,
          hospital_id: hospID,
        },
        method: "POST",
      })
        .then(() => {
          message.success("添加成功！");
          setGlobalInfoAddVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("添加失败，请检查或联系管理员！");
        });
    } else {
      message.error("暂时无法添加，请稍后重试或联系管理员！");
    }
  };

  return (
    <Fragment>
      <Descriptions column={2}>
        <Descriptions.Item>
          <Space>
            <h3 style={{ margin: "0px" }}>产品基本信息</h3>
            <Tag color="blue">已部署[{detail ? detail.length : null}]</Tag>
            {detailInfo
              ? detailInfo?.filter((i) => i.product_status === 1).length >
                  0 && (
                  <Popover
                    title={"已授权产品线"}
                    content={
                      <Space direction="vertical">
                        {detailInfo
                          ?.filter((i) => i.product_status === 1)
                          .map((i) => (
                            <Button
                              key={`_btn_${i.id}`}
                              type="link"
                              onClick={() => {
                                setEdittingID(i.id.toString());
                                setProduct({ product: i.id });
                              }}
                            >
                              {i.product_id}
                            </Button>
                          ))}
                      </Space>
                    }
                  >
                    <Tag color={getProductStatus(1, true)}>
                      <InfoCircleOutlined /> 已授权[
                      {detailInfo
                        ? detailInfo.filter((i) => i.product_status === 1)
                            .length
                        : 0}
                      ]
                    </Tag>
                  </Popover>
                )
              : null}
            {detailInfo
              ? detailInfo?.filter((i) => i.product_status === 3).length >
                  0 && (
                  <Popover
                    title={"无需授权产品线"}
                    content={
                      <Space direction="vertical">
                        {detailInfo
                          ?.filter((i) => i.product_status === 3)
                          .map((i) => (
                            <Button
                              key={`_btn_${i.id}`}
                              type="link"
                              onClick={() => {
                                setEdittingID(i.id.toString());
                                setProduct({ product: i.id });
                              }}
                            >
                              {i.product_id}
                            </Button>
                          ))}
                      </Space>
                    }
                  >
                    <Tag color={getProductStatus(1, true)}>
                      <InfoCircleOutlined /> 无需授权[
                      {detailInfo
                        ? detailInfo.filter((i) => i.product_status === 3)
                            .length
                        : 0}
                      ]
                    </Tag>
                  </Popover>
                )
              : null}
            {detailInfo
              ? detailInfo?.filter((i) => i.product_status === 2).length >
                  0 && (
                  <Popover
                    title={"未授权产品线"}
                    content={
                      <Space direction="vertical">
                        {detailInfo
                          ?.filter((i) => i.product_status === 2)
                          .map((i) => (
                            <Button
                              type="link"
                              onClick={() => {
                                setEdittingID(i.id.toString());
                                setProduct({ product: i.id });
                              }}
                            >
                              {i.product_id}
                            </Button>
                          ))}
                      </Space>
                    }
                  >
                    <Tag color={getProductStatus(2, true)}>
                      <InfoCircleOutlined /> 未授权[
                      {detailInfo
                        ? detailInfo.filter((i) => i.product_status === 2)
                            .length
                        : 0}
                      ]
                    </Tag>
                  </Popover>
                )
              : null}
          </Space>
        </Descriptions.Item>
      </Descriptions>
      <Tabs
        type="editable-card"
        onEdit={onEdit}
        activeKey={edittingID || undefined}
        hideAdd={isEditable}
        onTabClick={(v) => {
          setEdittingID(v);
          setProduct({ product: v });
        }}
        size="small"
        items={detail?.map((i: any, index) => {
          const id = String(index);
          if (index == 0) {
            return {
              key: `${id}`,
              disabled: edittingID !== id && isEditable,
              label: (
                <Space>
                  <span style={{ fontSize: "14px" }}>公共组件</span>
                  <Popover title={"点击添加公共组件信息"} trigger="hover">
                    <Button
                      disabled={edittingID !== id && isEditable}
                      onClick={() => {
                        setGlobalInfoAddVisible(true);
                      }}
                      type="link"
                      size="small"
                    >
                      <PlusOutlined />
                    </Button>
                  </Popover>
                </Space>
              ),
              closable: false,
              children: (
                <>
                  {i.map((item: ProductBaseInfoType, idx: number) => (
                    <div key={`div_${idx}`}>
                      <ProCard key={item.id}>
                        <ProDescriptions
                          title={<>{item.server_ip}</>}
                          extra={
                            <Button
                              type="link"
                              onClick={() => {
                                form.setFieldsValue({
                                  id: item.id,
                                  hospital_id: item.hospital_id,
                                  server_ip: item.server_ip,
                                  hardware_id: item.hardware_id,
                                  ub_version_id: item.ub_version_id,
                                  aic_version_id: item.aic_version_id,
                                  aim_version_id: item.aim_version_id,
                                  ga_version_id: item.ga_version_id,
                                  w_version_id: item.w_version_id,
                                  lang_version_id: item.lang_version_id,
                                  ub_version_name: item.ub_version_name,
                                  aic_version_name: item.aic_version_name,
                                  aim_version_name: item.aim_version_name,
                                  ga_version_name: item.ga_version_name,
                                  w_version_name: item.w_version_name,
                                  lang_version_name: item.lang_version_name,
                                });
                                setGlobalInfoEditVisible(true);
                              }}
                            >
                              编辑
                            </Button>
                          }
                          column={2}
                        >
                          <ProDescriptions.Item
                            labelStyle={{
                              fontWeight: "bold",
                              color: "#4F4F4F",
                            }}
                            label="UB版本"
                            valueType="text"
                          >
                            {item.ub_version_name}
                          </ProDescriptions.Item>
                          <ProDescriptions.Item
                            labelStyle={{
                              fontWeight: "bold",
                              color: "#4F4F4F",
                            }}
                            label="AGI平台"
                            valueType="text"
                          >
                            {item.ga_version_name}
                          </ProDescriptions.Item>
                          <ProDescriptions.Item
                            labelStyle={{
                              fontWeight: "bold",
                              color: "#4F4F4F",
                            }}
                            label="AI CT预测服务"
                            valueType="text"
                          >
                            {item.aic_version_name}
                          </ProDescriptions.Item>
                          <ProDescriptions.Item
                            labelStyle={{
                              fontWeight: "bold",
                              color: "#4F4F4F",
                            }}
                            label="AI MR预测服务"
                            valueType="text"
                          >
                            {item.aim_version_name}
                          </ProDescriptions.Item>
                          <ProDescriptions.Item
                            labelStyle={{
                              fontWeight: "bold",
                              color: "#4F4F4F",
                            }}
                            label="OP工作站"
                            valueType="text"
                          >
                            {item.w_version_name}
                          </ProDescriptions.Item>
                          <ProDescriptions.Item
                            labelStyle={{
                              fontWeight: "bold",
                              color: "#4F4F4F",
                            }}
                            label="语言包"
                            valueType="text"
                          >
                            {item.lang_version_name}
                          </ProDescriptions.Item>
                          <ProDescriptions.Item
                            labelStyle={{
                              fontWeight: "bold",
                              color: "#4F4F4F",
                            }}
                            label="更新人"
                            valueType="text"
                          >
                            {item.update_user}
                          </ProDescriptions.Item>
                          <ProDescriptions.Item
                            labelStyle={{
                              fontWeight: "bold",
                              color: "#4F4F4F",
                            }}
                            label="更新时间"
                            fieldProps={{
                              format: "YYYY-MM-DD HH:mm:ss",
                            }}
                            valueType="dateTime"
                          >
                            {item.update_time}
                          </ProDescriptions.Item>
                        </ProDescriptions>
                      </ProCard>
                      <Divider style={{ margin: "8px" }} />
                    </div>
                  ))}
                  <ProTable
                    headerTitle="授权信息"
                    rowKey="id"
                    actionRef={actionRef}
                    request={async (params) => {
                      try {
                        const response = await getLicenseInfo(params);
                        if (response && response.length > 0) {
                          return { data: response, success: true };
                        } else {
                          return { data: [], success: true };
                        }
                      } catch (e) {
                        return { success: false, data: [] };
                      }
                    }}
                    columns={[
                      {
                        title: "解决方案",
                        dataIndex: "solution_name",
                        key: "solution_name",
                      },
                      {
                        title: "开始日期",
                        dataIndex: "valid_start",
                        key: "valid_start",
                        valueType: "date",
                      },
                      {
                        title: "截至日期",
                        dataIndex: "valid_end",
                        key: "valid_end",
                        valueType: "date",
                      },
                      {
                        title: "授权时长(天)",
                        dataIndex: "duration",
                        key: "duration",
                      },
                      {
                        title: "授权状态",
                        key: "authorization_status",
                        render: (
                          _: any,
                          record: { valid_end: string | number | Date },
                        ) => {
                          const currentDate = new Date();
                          const validEndDate = new Date(record.valid_end);
                          return currentDate <= validEndDate ? (
                            <span style={{ color: "green" }}>有效</span>
                          ) : (
                            <span style={{ color: "red" }}>失效</span>
                          );
                        },
                      },
                    ]}
                    pagination={{
                      pageSize: 5,
                      showQuickJumper: true,
                      showSizeChanger: true,
                    }}
                    search={false}
                  />
                </>
              ),
            };
          } else {
            return {
              disabled: edittingID !== i?.id.toString() && isEditable,
              key: i?.id.toString(),
              label: (
                <Space>
                  <span style={{ fontSize: "14px" }}>{i?.product_id}</span>
                  <Popover
                    title={"更新记录"}
                    content={
                      <>
                        <p> 更新人: {i.update_user || "未知"}</p>
                        <p> 更新时间: {i.update_time || "未知"}</p>
                        <p>
                          授权状态:{" "}
                          {getProductStatus(i.product_status) || "未知"}
                        </p>
                      </>
                    }
                    trigger="hover"
                  >
                    <InfoCircleOutlined
                      style={{
                        color: `${getProductStatus(i.product_status, true)}`,
                      }}
                    />
                  </Popover>
                  {/* </Tag> */}
                </Space>
              ),
              closable: edittingID === i?.id.toString(),
              closeIcon:
                isEditable && i?.id.toString() === edittingID ? (
                  <CloseCircleFilled
                    style={{ color: "#f5222d" }}
                    onClick={() => delInfo(i?.id, i?.product_id.toString())}
                  />
                ) : (
                  <EditOutlined />
                ),
              children:
                isEditable && i?.id.toString() === edittingID ? (
                  <ProductBaseInfoEdit
                    editingItem={i}
                    editable={isEditable}
                    setEditable={setIsEditable}
                    setDisChange={setDisChange}
                    reload={reload}
                  />
                ) : (
                  <Descriptions column={2}>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"产品线"} />}
                    >
                      <LineDiv>{i.product_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"授权状态"} />}
                    >
                      <LineDiv>
                        <Popover
                          title="授权说明"
                          content={
                            <>
                              <p>
                                已授权：产品为License版本，当前已授权产品使用。
                              </p>
                              <p>
                                未授权：产品为License版本，当前未授权产品使用。
                              </p>
                              <p>
                                无需授权：当前产品非License版本，无需授权即可使用。
                              </p>
                            </>
                          }
                        >
                          <Tag color={getProductStatus(i.product_status, true)}>
                            {getProductStatus(i.product_status)}
                          </Tag>
                        </Popover>
                      </LineDiv>
                    </Descriptions.Item>
                    {i?.product_id === RID ? (
                      <Descriptions.Item
                        label={<ProductDesItemLabel label={"版本类型"} />}
                      >
                        <LineDiv>{i.version_type}</LineDiv>
                      </Descriptions.Item>
                    ) : null}
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"模型阈值"} />}
                    >
                      <LineDiv>{i.threshold}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"产品定制化"} />}
                    >
                      <LineDiv>
                        {i.is_customization === 1
                          ? "是"
                          : i.is_customization === 0
                          ? "否"
                          : null}
                      </LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"安装分支"} />}
                    >
                      <LineDiv>{i?.product_version_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"UB版本"} />}
                    >
                      <LineDiv>{i?.ub_version_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"客户端版本"} />}
                    >
                      <LineDiv>{i?.client_version_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"部署类型"} />}
                    >
                      <LineDiv>{i?.deploy_type_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"上线时间"} />}
                    >
                      <LineDiv>{i?.online_date || ""}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"服务类型"} />}
                    >
                      <LineDiv>{i?.service_type_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"单日体量"} />}
                    >
                      <LineDiv>{i?.number}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"使用状态"} />}
                    >
                      <LineDiv>{i?.using_status_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"使用科室"} />}
                    >
                      <LineDiv>
                        {i?.departments?.map((i: any) => i?.name).join("、")}
                      </LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"服务等级"} />}
                    >
                      <LineDiv>{i?.service_level_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<ProductDesItemLabel label={"维保时间"} />}
                    >
                      <LineDiv>
                        {`${i?.start_date ? i?.start_date + " -- " : ""}${
                          i?.end_date || ""
                        }`}
                      </LineDiv>
                    </Descriptions.Item>
                  </Descriptions>
                ),
            };
          }
        })}
      />
      {detailInfo?.find((i) => i.id.toString() === edittingID)?.product_id ? (
        <>
          {detailInfo?.find((i) => i.id.toString() === edittingID)
            ?.maintenance_infos?.length ? (
            <>
              <Divider style={{ marginTop: "14px" }} />
              <ProductManitenancInfo
                detail={
                  detailInfo?.find((i) => i.id.toString() === edittingID)
                    ?.maintenance_infos
                }
              />
            </>
          ) : null}
          <Divider style={{ marginTop: "14px" }} />
          <BaseRetainInfo
            orgID={hospID}
            productID={
              detailInfo?.find((i) => i.id.toString() === edittingID)
                ?.product_id
            }
            retain={
              detailInfo?.find((i) => i.id.toString() === edittingID)
                ?.retain_infos
            }
            RetainAPI={{
              ADD_RETAIN: ADD_PRODUCT_RETAIN,
              UPDATE_RETAIN: UPDATE_PRODUCT_RETAIN,
              DEL_RETAIN: DEL_PRODUCT_RETAIN,
            }}
            reload={reload}
          />
          <Divider style={{ marginTop: "14px" }} />
          <UpdateRecord
            retain={
              detailInfo?.find((i) => i.id.toString() === edittingID)
                ?.update_info
            }
            orgID={edittingID}
            RetainAPI={{
              ADD_RETAIN: () => ADD_PRODUCT_RECORD,
              UPDATE_RETAIN: UPDATE_PRODUCT_RECORD,
              DEL_RETAIN: DEL_PRODUCT_RECORD,
            }}
            reload={reload}
          />
          <Divider style={{ marginTop: "14px" }} />
          {detailInfo?.find((i) => i.id.toString() === edittingID)
            ?.product_id === THID ? (
            <ThUsingRate
              usingInfo={
                detailInfo?.find((i) => i.id.toString() === edittingID)
                  ?.using_info
              }
              reload={reload}
            />
          ) : (
            <UsingRate
              usingInfo={
                detailInfo?.find((i) => i.id.toString() === edittingID)
                  ?.using_info
              }
              reload={reload}
            />
          )}
        </>
      ) : null}
      <ProductBaseInfoAdd
        id={hospID}
        visible={addInfoVisible}
        setVisible={setAddInfoVisible}
        reload={reload}
      />
      <ModalForm<ProductBaseInfoType>
        form={form}
        autoFocusFirstInput
        modalProps={{
          destroyOnClose: true,
        }}
        submitTimeout={2000}
        onFinish={async (value) => {
          if (globalInfoAddVisible) {
            AddGlobalInfo(value);
          } else {
            updateGlobalProductVersionFunc(form.getFieldValue("id"), value);
          }
        }}
        open={globalInfoEditVisible || globalInfoAddVisible}
        onOpenChange={(v) => {
          if (!v) {
            setGlobalInfoAddVisible(false);
            setGlobalInfoEditVisible(false);
          }
        }}
        title={
          <>
            {globalInfoAddVisible
              ? "添加"
              : `编辑${form.getFieldValue("server_ip")}`}
            公共组件信息
          </>
        }
      >
        {globalInfoAddVisible ? (
          <ProForm.Group>
            <ProFormSelect
              request={async () => {
                const res = await getHardwareInfo({});
                if (res.hardware_list) {
                  return res.hardware_list.map((i: any) => {
                    return {
                      label: i.server_ip,
                      value: i.id,
                      disabled: hardwareIdList.includes(i.id), // 判断是否在禁用列表中
                    };
                  });
                }
              }}
              width="lg"
              allowClear={false}
              name="hardware_id"
              label="服务器"
              placeholder={"请选择服务器"}
              rules={[{ required: true, message: "必须选择服务器" }]}
            />
          </ProForm.Group>
        ) : (
          <ProForm.Group>
            <ProFormSelect
              hidden={true}
              fieldProps={{
                fieldNames: { label: "server_ip", value: "id" },
              }}
              disabled={true}
              width="lg"
              allowClear={false}
              name="hardware_id"
              label="服务器"
              placeholder={"请选择服务器"}
              rules={[{ required: true, message: "必须选择服务器" }]}
            />
          </ProForm.Group>
        )}
        <ProForm.Group>
          <ProFormText
            width="md"
            name="hospital_id"
            label="hospital_id"
            hidden={true}
          />
          <ProFormSelect
            request={async () => {
              return getUbVersionList(client(GET_PRODUCT_VERSION_BY_ID("UB")));
            }}
            fieldProps={{
              fieldNames: { label: "name", value: "id" },
            }}
            width="md"
            name="ub_version_id"
            label="UB版本"
          />
          <ProFormSelect
            request={async () => {
              return client(GET_PRODUCT_VERSION_BY_ID("GA"));
            }}
            fieldProps={{
              fieldNames: { label: "name", value: "id" },
            }}
            width="md"
            name="ga_version_id"
            label="AGI平台"
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormSelect
            request={async () => {
              return client(GET_PRODUCT_VERSION_BY_ID("AIC"));
            }}
            fieldProps={{
              fieldNames: { label: "name", value: "id" },
            }}
            width="md"
            name="aic_version_id"
            label="AI CT预测服务"
          />
          <ProFormSelect
            request={async () => {
              return client(GET_PRODUCT_VERSION_BY_ID("AIM"));
            }}
            fieldProps={{
              fieldNames: { label: "name", value: "id" },
            }}
            width="md"
            name="aim_version_id"
            label="AI MR预测服务"
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormSelect
            request={async () => {
              return client(GET_PRODUCT_VERSION_BY_ID("W"));
            }}
            fieldProps={{
              fieldNames: { label: "name", value: "id" },
            }}
            width="md"
            name="w_version_id"
            label="OP工作站"
          />
          <ProFormSelect
            request={async () => {
              return client(GET_PRODUCT_VERSION_BY_ID("LANG"));
            }}
            fieldProps={{
              fieldNames: { label: "name", value: "id" },
            }}
            width="md"
            name="lang_version_id"
            label="语言包"
          />
        </ProForm.Group>
      </ModalForm>
    </Fragment>
  );
};

export default ProductBaseInfo;
