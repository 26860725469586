import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-components";
import { Button, Row, Select, Space, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDebounceFn, useMount } from "ahooks";

import { useHttp } from "utils/http";
import { HosNodesType } from "authenticated-app/pc/customer/customer-list/interface";
import { useAsync } from "utils/hooks/useAsync";
import { GET_HOSP_NODES } from "lib/api";
import { GetTotalNum } from "./calculate-value/total-num";
import { GetLevelNum } from "./calculate-value/level-num";
import { GetStatusNum } from "./calculate-value/status-num";
import { MyBarChart, MyBarChartType } from "../chart/bar-chart";
import { MaintenanceQuantityType } from "../interface";
import { GET_MAINTENANCE_STATISTIC } from "../api";
import { useWindowSize } from "utils/hooks/useWindowSize";

const { Option } = Select;

export const MaintenanceQuantity = () => {
  const client = useHttp();
  const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [searchArea, setSearchArea] = useState<number>();

  const [totalNum, setTotalNum] = useState<MyBarChartType>();
  const [levelNum, setLevelNum] = useState<MyBarChartType>();
  const [statusNum, setStatusNum] = useState<MyBarChartType>();

  const { height } = useWindowSize();

  const {
    run: getHosNodes,
    isLoading: gettingNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };
  const { run: getHosNodesDebounce } = useDebounceFn(getHosNodesHandle, {
    wait: 200,
  });

  const {
    run: getMaintenanceQuantity,
    isLoading: gettingMaintenanceQuantity,
    data: maintenanceQuantity,
  } = useAsync<MaintenanceQuantityType[]>();
  const getMaintenanceQuantityHandle = () => {
    getMaintenanceQuantity(
      client(GET_MAINTENANCE_STATISTIC, {
        data: {
          area_id: searchArea,
        },
      }),
    );
  };
  const { run: getMaintenanceQuantityDebounce } = useDebounceFn(
    getMaintenanceQuantityHandle,
    { wait: 200 },
  );

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.nodeLevel === 1 && item.nodeName !== "海外") {
        areaListTMP?.push(item);
      }
    });
    setAreaList(areaListTMP);
  }, [hosNodes]);

  useEffect(() => {
    if (maintenanceQuantity) {
      setTotalNum(GetTotalNum(maintenanceQuantity));
      setLevelNum(GetLevelNum(maintenanceQuantity));
      setStatusNum(GetStatusNum(maintenanceQuantity));
    }
  }, [maintenanceQuantity]);

  useMount(() => {
    getHosNodesDebounce();
    getMaintenanceQuantityDebounce();
  });

  const Loading = gettingNodes || gettingMaintenanceQuantity;

  return (
    <div style={{ height: "100%", minWidth: 988 }}>
      <Spin spinning={Loading}>
        <PageHeader
          style={{ padding: 0 }}
          extra={
            <Space>
              <Select
                allowClear
                style={{ width: 120 }}
                placeholder="区域"
                disabled={Loading}
                loading={Loading}
                value={searchArea}
                onChange={(nodeId) => {
                  setSearchArea(nodeId);
                }}
              >
                {areaList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                loading={Loading}
                disabled={Loading}
                onClick={getMaintenanceQuantityHandle}
              >
                查询
              </Button>
            </Space>
          }
        />
        <Row
          style={{
            height: (height - 256) / 3 > 248 ? (height - 256) / 3 : 248,
            width: "100%",
            minHeight: 248,
          }}
        >
          <MyBarChart Data={totalNum} />
        </Row>
        <Row
          style={{
            height: (height - 256) / 3 > 248 ? (height - 256) / 3 : 248,
            width: "100%",
            minHeight: 248,
          }}
        >
          <MyBarChart Data={levelNum} />
        </Row>
        <Row
          style={{
            height: (height - 256) / 3 > 248 ? (height - 256) / 3 : 248,
            width: "100%",
            minHeight: 248,
          }}
        >
          <MyBarChart Data={statusNum} />
        </Row>
      </Spin>
    </div>
  );
};
