import { Radio, Form } from "antd";
import type { ColumnsType } from "antd/es/table";

import { CHECKLIST_VALUE_TYPE } from "lib/interface/checklist";
import { valueIsUseful } from "utils";

const { Item } = Form;

export const WorkColumns: ColumnsType<CHECKLIST_VALUE_TYPE> = [
  {
    title: "编号",
    dataIndex: "id",
    width: 64,
    align: "center",
    render: (_, __, index) => {
      return index + 1;
    },
  },
  {
    title: "检查项",
    dataIndex: "check_item",
    width: 96,
  },
  {
    title: "参考指标",
    dataIndex: "description",
  },
  {
    title: "结果",
    dataIndex: "value",
    width: 256,
    render: (_, record, __) => {
      return (
        <Item
          key={record.id}
          name={record.id}
          rules={[{ required: record.is_need === 1, message: "必须填写此项" }]}
          initialValue={record.value}
        >
          <Radio.Group disabled={valueIsUseful(record.value)}>
            <Radio value={0}>异常</Radio>
            <Radio value={1}>正常</Radio>
            <Radio value={2}>不适用</Radio>
          </Radio.Group>
        </Item>
      );
    },
  },
];
