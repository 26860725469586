import { SetStateAction, Dispatch, useEffect } from "react";
import { Button, Modal, Space, Form, Input, Select, message } from "antd";

import { useHttp } from "utils/http";
import {
  GET_PRODUCT_VERSION_BY_ID,
  UPDATE_DOCKING_VERSION_INFO,
} from "lib/api";
import { DockingVersionType } from "../interface";
import { DisposeForm } from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { IpRegex } from "utils/regexp";
import { ProductVersionType } from "../../productTabPane/interface";

const { TextArea } = Input;
const { Option } = Select;

export const DockingVersionInfoEdit = ({
  title,
  hospID,
  editingItem,
  visible,
  setVisible,
  reload,
}: {
  title: string;
  hospID: number | null;
  editingItem: DockingVersionType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getDsVersionList,
    isLoading: gettingDsVersionList,
    data: ubVersionList,
  } = useAsync<ProductVersionType[]>();
  const getDsVersionListFunc = () => {
    getDsVersionList(client(GET_PRODUCT_VERSION_BY_ID("DS")));
  };

  const submit = (value: any) => {
    let id = editingItem?.id;
    if (id && hospID)
      client(UPDATE_DOCKING_VERSION_INFO(id), {
        data: {
          ...value,
          hospital_id: parseInt(hospID.toString()),
        },
        method: "PUT",
      })
        .then(() => {
          message.success("修改成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    else message.error("修改失败，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    if (visible) {
      getDsVersionListFunc();
      form.setFieldsValue({
        ...editingItem,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={`编辑${title}`}
      destroyOnClose
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="ip"
          label="IP"
          rules={[
            { required: true, message: "必须输入IP" },
            {
              pattern: IpRegex(),
              message: "必须输入合法的IP格式",
            },
          ]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="version_id"
          label="DS版本"
          rules={[{ required: true, message: "必须选择DS版本" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select loading={gettingDsVersionList} placeholder="选择DS版本">
            {ubVersionList?.map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
          normalize={(v) => DisposeForm(v)}
          rules={[{ required: true, message: "必须输入备注" }]}
        >
          <TextArea allowClear />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
