import { useEffect, useState } from "react";

import {
  Ellipsis,
  List,
  NavBar,
  Popup,
  PullToRefresh,
  SafeArea,
  Space,
  Collapse,
  Tag,
  Grid,
  Divider,
  Button,
  Toast,
  Modal,
  Result,
  AutoCenter,
  Badge,
} from "antd-mobile";

import { EditSOutline, ExclamationCircleFill } from "antd-mobile-icons";
import { Timeline, Typography } from "antd";
import { FormatDate, FormatTime } from "utils";
import { priorityColor, priorityStatus } from "utils/ticket";
import { useDebounceFn } from "ahooks";
import { useAuth } from "context/auth";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { useBreaklistDetail } from "authenticated-app/hooks/breaklist/useBreaklistDetail";
import { AttachmentDiv } from "authenticated-app/mobile/components/AttachmentDiv";
import { isImageFormat } from "authenticated-app/mobile/util";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { BREAK_DETAIL_TYPE } from "lib/interface/breaklist";
import { PamInfoType } from "authenticated-app/pc/customer/customer-list/interface";
import { HospListType } from "interface";
import { GET_HOSP_PWD } from "lib/api";
import {
  LockOutlined,
  QuestionOutlined,
  ToolOutlined,
  LaptopOutlined,
  RocketOutlined,
  BugOutlined,
  VerticalAlignTopOutlined,
  UnlockOutlined,
  CheckOutlined,
  SwapOutlined,
  RollbackOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";

import {
  DELETE_BREAK_DETAIL,
  GET_BREAK_DETAIL,
  TRANS_BREAK,
  UPDATE_BREAK_DETAIL,
} from "lib/api/issues";
import { useBreakDetailTransfer } from "authenticated-app/hooks/breaklist/trans/useBreakDetailTransfer";
import { useBreakDetailUpgrade } from "authenticated-app/hooks/breaklist/trans/useBreakDetailUpgrade";
import { useBreakDetailSuspend } from "authenticated-app/hooks/breaklist/trans/useBreakDetailSuspend";
import { useBreakDetailSolve } from "authenticated-app/hooks/breaklist/trans/useBreakDetailSolve";
import { useBreakDetailReverse } from "authenticated-app/hooks/breaklist/trans/useBreakDetailReverse";
import { BreakDetailTransferModal } from "authenticated-app/mobile/list/breaklist/detail/trans/transfer";
import { BreakDetailUpgradeModal } from "authenticated-app/mobile/list/breaklist/detail/trans/upgrade";
import { BreakDetailSuspendModal } from "authenticated-app/mobile/list/breaklist/detail/trans/suspend";
import { BreakDetailReverseModal } from "authenticated-app/mobile/list/breaklist/detail/trans/reverse";
import { BreakDetailSolveModal } from "authenticated-app/mobile/list/breaklist/detail/trans/solve";
import { MobileEditBreakListPopup } from "authenticated-app/mobile/list/breaklist/edit/index";
import { MobileComments } from "authenticated-app/mobile/components/Comments";
import { MobileReactQuill } from "authenticated-app/mobile/components/MobileQuill";

import "./index.css";

const titleHeight = "45px";
const { Link } = Typography;

const processMap = new Map([
  ["未分配", <QuestionOutlined />],
  ["现场处理中", <ToolOutlined />],
  ["客服处理中", <LaptopOutlined />],
  ["SRD处理中", <RocketOutlined />],
  ["研发处理中", <BugOutlined />],
  ["已解决", <CheckOutlined />],
  ["挂起中", <LockOutlined />],
]);

export const MobileBreaklistDetailPopup = ({
  reload,
}: {
  reload: () => void;
}) => {
  const [{ detailId }] = useUrlQueryParam(["detailId"]);

  const [isOpen, , close] = useBreaklistDetail();
  const [isNotFound, setIsNotFound] = useState(false);
  const client = useHttp();

  const {
    run: getDetailInfo,
    data: detailInfo,
    isIdle,
    isLoading,
    setData,
  } = useAsync<BREAK_DETAIL_TYPE>();
  const getDetailInfoHandle = async () => {
    try {
      await getDetailInfo(client(GET_BREAK_DETAIL(detailId)));
      setIsNotFound(false);
    } catch (error: any) {
      setData(null);
      if (error.status === 404) {
        setIsNotFound(true);
      }
    }
  };
  const { run: getDetailInfoDebounce } = useDebounceFn(getDetailInfoHandle, {
    wait: 200,
  });

  const reloadDetailInfoHandle = () => {
    getDetailInfo(client(GET_BREAK_DETAIL(detailId)));
    // window.location.reload();
  };

  const { hospital_name, order_id } = detailInfo || {};

  const { user } = useAuth();

  useEffect(() => {
    if (isOpen && detailId) {
      setIsNotFound(false);
      getDetailInfoDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailId]);

  const Loading = isIdle || isLoading;
  const isFinished = !!!isIdle && detailInfo?.process_id === 6;
  const isHasPermission =
    user?.id === detailInfo?.node_user_id ||
    user?.id === detailInfo?.issue_user_id;
  const isCanChange = !!!isFinished && isHasPermission;

  const [, setId] = useUrlQueryParam(["detailId"]);

  const [isChanged, setIsChanged] = useState(false);
  const [nextProcess, setNextProcess] = useState<number[]>([]);
  const [tarnsDepartment, setTransDepartment] = useState<number[]>([]);
  const [breakDetailHandleOpening, setBreakDetailHandleOpening] =
    useState(false);

  const [breakDetailUnlockOpening, setBreakDetailUnlockOpening] =
    useState(false);
  const [breakDetailTransferOpenig, openBreakDetailTransfer] =
    useBreakDetailTransfer();
  const [breakDetailUpgradeOpenig, openBreakDetailUpgrade] =
    useBreakDetailUpgrade();
  const [breakDetailReverseOpenig, openBreakDetailReverse] =
    useBreakDetailReverse();
  const [breakDetailSuspendOpenig, openBreakDetailSuspend] =
    useBreakDetailSuspend();
  const [breakDetailSolveOpenig, openBreakDetailSolve] = useBreakDetailSolve();
  const [breakDeleteOpening, setBreakDeleteOpening] = useState(false);
  const { run: trans } = useAsync();
  const onTrans = (value: any, onTransType?: string) => {
    if (detailInfo?.id) {
      // 仅将必须和发生变化的数据提交.
      trans(
        client(TRANS_BREAK(detailInfo?.id), {
          method: "PUT",
          data: {
            customer_owner: detailInfo.customer_owner,
            feedback_time: detailInfo.feedback_time,
            hospital_id: detailInfo.hospital_id,
            reasons_for_transfer: detailInfo.reasons_for_transfer,
            knowledge_link: detailInfo.knowledge_link,
            suspension_reason: detailInfo.suspension_reason,
            is_additional_recording: detailInfo.is_additional_recording,
            is_temporary: detailInfo.is_temporary,
            is_on_site: detailInfo.is_on_site,
            is_known: detailInfo.is_known,
            delivery_time: detailInfo.delivery_time,
            next_time: detailInfo.next_time,
            checklist_id: detailInfo.checklist_id,
            is_deal_with_on_site: detailInfo.is_deal_with_on_site,
            response_time: detailInfo.response_time,
            issue_module_id: detailInfo.issue_module_id,
            issue_reason_id: detailInfo.issue_reason_id,
            issue_type_id: detailInfo.issue_type_id,
            service_order_id: detailInfo.service_order_id,
            order_type_id: detailInfo.order_type_id,
            title: detailInfo.title,
            customer_name: detailInfo.customer_name || detailInfo.hospital_name,
            description: detailInfo.description,
            feedback_channel_id: detailInfo.feedback_channel_id,
            feedback_user: detailInfo.feedback_user,
            contact: detailInfo.contact,
            influence_function: detailInfo.influence_function,
            influence_sphere: detailInfo.influence_sphere,
            issue_user_id: detailInfo.issue_user_id,
            node_user_id: detailInfo.node_user_id,
            remote_info: detailInfo.remote_info,
            // product_id_list: detailInfo.product_list?.map((i) => ([ i.title, i.product_version_id])),
            product_id_list: detailInfo.product_list?.map((i) => [
              i.title,
              i.product_version_id || "",
            ]),
            attachment_id_list: detailInfo.attachment_list?.map(
              (i) => i.attachment_id,
            ),
            debug_info:
              !!!detailInfo.debug_info_dict.update_user_id &&
              !!!detailInfo.debug_info_dict.debug_info
                ? undefined
                : detailInfo.debug_info_dict.debug_info,
            solution_info:
              !!!detailInfo.solution_info_dict.update_user_id &&
              !!!detailInfo.solution_info_dict.solution_info
                ? undefined
                : detailInfo.solution_info_dict.solution_info,
            ...value,
          },
        }),
      )
        .then((res) => {
          setIsChanged(true);
          setData(res);
          const content = `${res.order_id}转换为${res.process_name}!`;
          Toast.show({
            icon: "success",
            content: <ToastContent content={content} />,
            duration: 3000,
          });
        })
        .catch(() => getDetailInfoDebounce())
        .finally(() => {
          if (onTransType === "handle") {
            setBreakDetailHandleOpening(false);
          } else if (onTransType === "unlock") {
            setBreakDetailUnlockOpening(false);
          }
        });
    }
  };
  const [pamIsLoading, setPamIsLoading] = useState(false);

  const getPam = () => {
    if (detailInfo?.hospital_id) {
      if (process.env.REACT_APP_VERSION === "dev") {
        setPamInfo({
          id: detailInfo?.hospital_id,
          name: detailInfo?.hospital_name,
          code: detailInfo?.hospital_id.toString(),
          hospitalPwd: "test_pwd",
        });
        setIsPamVisible(true);
      } else {
        setPamIsLoading(true);
        client(GET_HOSP_PWD(detailInfo?.hospital_id))
          .then((res) => {
            setPamInfo({
              name: detailInfo?.hospital_name,
              code: res[0].id,
              ...res[0],
            });
            setIsPamVisible(true);
          })
          .catch((err) => {
            console.error(err);
            Toast.show({
              icon: "fail",
              content: <ToastContent content="获取出错" />,
            });
          })
          .finally(() => {
            setPamIsLoading(false);
          });
      }
    }
  };

  const { run: getPwd, isLoading: gettingPwd } = useAsync<HospListType[]>();
  const getPwdHandle = (record: HospListType) => {
    if (process.env.REACT_APP_VERSION === "dev") {
      setPamInfo({
        id: record?.id,
        name: record?.name,
        code: record?.code,
        hospitalPwd: "test_pwd",
      });
      setIsPamVisible(true);
    } else {
      getPwd(client(GET_HOSP_PWD(record?.id)))
        .then((response) => {
          setPamInfo({
            id: record?.id,
            name: record?.name,
            code: record?.code,
            ...response[0],
          });
          setIsPamVisible(true);
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="获取出错" />,
          });
        });
    }
  };

  const { run: breakDelete } = useAsync();
  const onDelete = async (id: number | string) => {
    try {
      await breakDelete(
        client(DELETE_BREAK_DETAIL(id), {
          method: "DELETE",
        }),
      ).then(() => {
        Toast.show({
          icon: "success",
          content: (
            <ToastContent content={`${detailInfo?.order_id} 删除成功!`} />
          ),
        });
        close();
        reload();
      });
    } catch (error) {
      Toast.show({
        icon: "fail",
        content: <ToastContent content={`${detailInfo?.order_id} 删除失败!`} />,
      });
      close();
      reload();
    }
  };

  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [isPamVisible, setIsPamVisible] = useState(false);
  const [pamInfo, setPamInfo] = useState<PamInfoType>();

  const currentReload = reloadDetailInfoHandle;

  return (
    <Popup
      visible={isOpen}
      destroyOnClose
      bodyStyle={{
        height: "100vh",
        overflowY: "scroll",
      }}
    >
      <div className={"breaklist-detail-top"}>
        <NavBar
          style={{ lineHeight: titleHeight, "--height": titleHeight }}
          onBack={() => {
            close();
            setData(null);
            reload();
          }}
        >
          <Ellipsis
            direction="end"
            content={
              (order_id || "") +
                " " +
                (hospital_name || detailInfo?.customer_name) || "未知"
            }
            style={{
              fontSize: "14px",
              lineHeight: "50px",
              textAlign: "center",
            }}
          />
        </NavBar>
      </div>
      {isNotFound ? (
        <Result
          status="info"
          title={
            <span color="grey" style={{ fontWeight: "bold" }}>
              404 NotFound
            </span>
          }
          description={<span>你访问的工单不存在, 该工单可能已经被删除.</span>}
        />
      ) : (
        <>
          <LoadingMask visible={Loading} />
          <PullToRefresh
            onRefresh={async () => getDetailInfoHandle()}
            refreshingText={null}
          >
            <ContentCard style={{ margin: "0px" }}>
              <List>
                <List.Item
                  extra={
                    <Space wrap>
                      {detailInfo?.process_name !== "已解决" ? (
                        <>
                          <Button
                            key={"delete_break"}
                            onClick={() => {
                              setBreakDeleteOpening(true);
                            }}
                            loading={breakDeleteOpening}
                            color="danger"
                            size="mini"
                            fill="outline"
                          >
                            删除
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      {detailInfo?.process_trans.length && isCanChange ? (
                        <Button
                          onClick={() => {
                            setEditVisible(true);
                          }}
                          color="primary"
                          fill="none"
                          size="mini"
                        >
                          <EditSOutline />
                          编辑
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Space>
                  }
                >
                  <ListHeader
                    text={
                      <Space wrap>
                        <span>工单详情</span>
                        <Tag
                          style={{ fontSize: "11px", marginRight: "0px" }}
                          color="primary"
                          fill="outline"
                        >
                          未解决{detailInfo?.relation_order_list.length}个
                        </Tag>
                        <Tag
                          style={{ fontSize: "11px" }}
                          color="primary"
                          fill="outline"
                        >
                          相似{detailInfo?.similar_order_list.length}个
                        </Tag>
                      </Space>
                    }
                    loading={Loading}
                  />
                </List.Item>
                <List.Item
                  description={
                    <div>
                      <Grid columns={1} gap={8}>
                        <Grid.Item>
                          <span>{"创建时间"}</span>
                          <span style={{ marginLeft: "8px" }}>
                            {FormatDate(detailInfo?.create_time)}
                          </span>
                        </Grid.Item>
                      </Grid>
                    </div>
                  }
                  arrow={
                    <div
                      style={{
                        fontSize: "var(--adm-font-size-main)",
                        textAlign: "end",
                        color: "var(--adm-color-weak)",
                      }}
                    >
                      <div>
                        <Tag
                          round
                          color={priorityColor.get(
                            (detailInfo?.priority as priorityStatus) ||
                              priorityStatus.medium,
                          )}
                          style={{
                            margin: 0,
                            padding: "2px",
                            transform: "translateY(-2px)",
                            marginRight: "4px",
                          }}
                        />
                        {detailInfo?.priority}
                      </div>
                      <Tag color="primary" fill="outline">
                        {detailInfo?.process_name}
                      </Tag>
                    </div>
                  }
                >
                  <span style={{ wordBreak: "break-all" }}>
                    {detailInfo?.title}
                  </span>
                </List.Item>
                {detailInfo?.process_trans.length ? (
                  <List.Item>
                    <Grid columns={1} gap={8}>
                      <Grid.Item>
                        <span style={{ fontSize: "14px", color: "gray" }}>
                          工单流转
                        </span>
                      </Grid.Item>
                      <Grid.Item>
                        <Space style={{ marginRight: 24 }} wrap>
                          {detailInfo?.process_trans.map((i, index) => {
                            switch (i.title) {
                              case "处理":
                                return (
                                  <Button
                                    key={index}
                                    fill="outline"
                                    size="small"
                                    onClick={() => {
                                      setBreakDetailHandleOpening(true);
                                      onTrans(
                                        { next_process: i.next_process },
                                        "handle",
                                      );
                                    }}
                                    loading={breakDetailHandleOpening}
                                  >
                                    <PlayCircleOutlined />
                                    {i.title}
                                  </Button>
                                );
                              case "转派":
                                return (
                                  <Button
                                    key={index}
                                    fill="outline"
                                    size="mini"
                                    loading={breakDetailTransferOpenig}
                                    onClick={() => {
                                      setNextProcess(i.next_process);
                                      setTransDepartment(i.department_ids);
                                      openBreakDetailTransfer();
                                    }}
                                  >
                                    <SwapOutlined />
                                    {i.title}
                                  </Button>
                                );
                              case "转回":
                                return (
                                  <Button
                                    key={index}
                                    fill="outline"
                                    size="mini"
                                    loading={breakDetailReverseOpenig}
                                    onClick={() => {
                                      setNextProcess(i.next_process);
                                      setTransDepartment(i.department_ids);
                                      openBreakDetailReverse();
                                    }}
                                  >
                                    <RollbackOutlined />
                                    {i.title}
                                  </Button>
                                );
                              case "升级":
                                return (
                                  <Button
                                    key={index}
                                    fill="outline"
                                    size="mini"
                                    loading={breakDetailUpgradeOpenig}
                                    onClick={() => {
                                      setNextProcess(i.next_process);
                                      setTransDepartment(i.department_ids);
                                      openBreakDetailUpgrade();
                                    }}
                                  >
                                    <VerticalAlignTopOutlined />
                                    {i.title}
                                  </Button>
                                );
                              case "解决":
                                return (
                                  <Button
                                    key={index}
                                    fill="outline"
                                    size="mini"
                                    loading={breakDetailSolveOpenig}
                                    onClick={() => {
                                      setNextProcess(i.next_process);
                                      setTransDepartment(i.department_ids);
                                      openBreakDetailSolve();
                                    }}
                                  >
                                    <CheckOutlined />
                                    {i.title}
                                  </Button>
                                );
                              case "挂起":
                                return (
                                  <Button
                                    key={index}
                                    fill="outline"
                                    size="mini"
                                    loading={breakDetailSuspendOpenig}
                                    onClick={() => {
                                      setNextProcess(i.next_process);
                                      setTransDepartment(i.department_ids);
                                      openBreakDetailSuspend();
                                    }}
                                  >
                                    <LockOutlined />
                                    {i.title}
                                  </Button>
                                );
                              case "解挂":
                                return (
                                  <Button
                                    key={index}
                                    fill="outline"
                                    size="mini"
                                    onClick={() => {
                                      setBreakDetailUnlockOpening(true);
                                      onTrans(
                                        { next_process: i.next_process },
                                        "unlock",
                                      );
                                    }}
                                    loading={breakDetailUnlockOpening}
                                  >
                                    <UnlockOutlined />
                                    {i.title}
                                  </Button>
                                );
                              default:
                                return null;
                            }
                          })}
                        </Space>
                      </Grid.Item>
                    </Grid>
                  </List.Item>
                ) : (
                  <></>
                )}
                <List.Item
                  description={
                    <>
                      <Grid columns={2} gap={8}>
                        <Grid.Item>
                          <div>
                            <span>{"工单类别:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.order_type_name}
                            </span>
                          </div>
                        </Grid.Item>
                        {detailInfo?.order_type_name === "使用故障" ? (
                          <>
                            <Grid.Item>
                              <div>
                                <span>{"影响功能:"}</span>
                                <span
                                  style={{ marginLeft: "8px", color: "black" }}
                                >
                                  {detailInfo?.influence_function}
                                </span>
                              </div>
                            </Grid.Item>
                            <Grid.Item>
                              <div>
                                <span>{"影响范围:"}</span>
                                <span
                                  style={{ marginLeft: "8px", color: "black" }}
                                >
                                  {detailInfo?.influence_sphere}
                                </span>
                              </div>
                            </Grid.Item>
                          </>
                        ) : (
                          <></>
                        )}
                        <Grid.Item>
                          <div>
                            <span>{"客户所属:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.customer_owner}
                            </span>
                          </div>
                        </Grid.Item>
                      </Grid>
                      <Grid columns={2} gap={8} style={{ marginTop: "8px" }}>
                        <Grid.Item>
                          <div>
                            <span>{"接单时长:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.receiving_time}
                            </span>
                          </div>
                        </Grid.Item>
                        <Grid.Item>
                          <div>
                            <span>{"解决时长:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.processing_time}
                            </span>
                          </div>
                        </Grid.Item>
                      </Grid>
                      <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                        <Grid.Item>
                          <div>
                            <span>动态密码:</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              <Space wrap>
                                <Button
                                  size="mini"
                                  fill="outline"
                                  // loading={
                                  //   isPamVisible &&
                                  //   pamInfo?.code.toString() ===
                                  //     i.code.toString()
                                  // }
                                  loading={pamIsLoading}
                                  disabled={isPamVisible}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    getPam();
                                  }}
                                >
                                  点击获取
                                </Button>
                              </Space>
                            </span>
                          </div>
                        </Grid.Item>
                      </Grid>
                      <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                        <Grid.Item>
                          <div>
                            <span>工单标签:</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              <Space wrap>
                                {detailInfo?.label_list.map((item, index) => (
                                  <Tag
                                    key={`${item.id}_order_tag`}
                                    color="success"
                                  >
                                    {item.name}
                                  </Tag>
                                ))}
                              </Space>
                            </span>
                          </div>
                        </Grid.Item>
                      </Grid>
                    </>
                  }
                >
                  <h4 style={{ margin: "0px" }}>基本信息</h4>
                </List.Item>
                <List.Item
                  description={
                    <>
                      <Grid columns={2} gap={8}>
                        <Grid.Item>
                          <div>
                            <span>{"是否在现场:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.is_on_site ? "是" : "否"}
                            </span>
                          </div>
                        </Grid.Item>
                        <Grid.Item>
                          <div>
                            <span>{"是否现场处理:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.is_deal_with_on_site ? "是" : "否"}
                            </span>
                          </div>
                        </Grid.Item>
                        <Grid.Item>
                          <div>
                            <span>{"是否临时解决:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.is_temporary ? "是" : "否"}
                            </span>
                          </div>
                        </Grid.Item>
                        <Grid.Item>
                          <div>
                            <span>{"是否已知问题:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.is_known ? "是" : "否"}
                            </span>
                          </div>
                        </Grid.Item>
                        <Grid.Item>
                          <div>
                            <span>{"反馈类型:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.issue_type_name}
                            </span>
                          </div>
                        </Grid.Item>
                        <Grid.Item>
                          <div>
                            <span>{"反馈归因:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.issue_reason_name}
                            </span>
                          </div>
                        </Grid.Item>
                      </Grid>

                      <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                        <Grid.Item>
                          <div>
                            <span>{"预计交付日期:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {FormatDate(detailInfo?.delivery_time)}
                            </span>
                          </div>
                        </Grid.Item>
                        <Grid.Item>
                          <div>
                            <span>{"影响产品:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              <Space wrap>
                                {detailInfo?.product_list.map((item, index) => (
                                  <Tag
                                    key={`procdut_tag_${item.id}`}
                                    color="#108ee9"
                                  >
                                    {item.chinese_name}
                                    {item.product_version_name}
                                  </Tag>
                                ))}
                              </Space>
                            </span>
                          </div>
                        </Grid.Item>
                        <Grid.Item>
                          <div>
                            <span>{"知识库链接:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.knowledge_link}
                            </span>
                          </div>
                        </Grid.Item>
                      </Grid>
                    </>
                  }
                >
                  <h4 style={{ margin: "0px" }}>详细信息</h4>
                </List.Item>
                <List.Item
                  description={
                    <>
                      <Grid columns={2} gap={8}>
                        <Grid.Item>
                          <div>
                            <span>{"响应时长:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.response_time} min
                            </span>
                          </div>
                        </Grid.Item>
                        <Grid.Item>
                          <div>
                            <span>{"节点负责人:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.node_user_name}
                            </span>
                          </div>
                        </Grid.Item>
                        <Grid.Item>
                          <div>
                            <span>{"工单负责人:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.issue_user_name}
                            </span>
                          </div>
                        </Grid.Item>
                        <Grid.Item>
                          <div>
                            <span>{"工单创建人:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.create_user}
                            </span>
                          </div>
                        </Grid.Item>
                      </Grid>
                      <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                        <Grid.Item>
                          <div>
                            <span>关注人:</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.follow_user_list
                                .map((i) => i.userName)
                                .join("、") || ""}
                            </span>
                          </div>
                        </Grid.Item>
                      </Grid>
                    </>
                  }
                >
                  <h4 style={{ margin: "0px" }}>工单状态</h4>
                </List.Item>
                <List.Item description={<></>}>
                  <Grid columns={2} gap={10} style={{ marginBottom: "4px" }}>
                    <Grid.Item>
                      <h4 style={{ margin: "0px" }}>反馈详情</h4>
                    </Grid.Item>
                    <Grid.Item>
                      <div
                        style={{
                          fontSize: "var(--adm-font-size-main)",
                          textAlign: "end",
                          color: "var(--adm-color-weak)",
                        }}
                      ></div>
                    </Grid.Item>
                  </Grid>
                  <MobileReactQuill
                    info={detailInfo?.description || ""}
                    name={"description"}
                    title={"反馈详情"}
                    isCanChange={false}
                  />
                </List.Item>
                <List.Item>
                  <h4 style={{ margin: "2px" }}>附件</h4>
                  {detailInfo?.attachment_list.length ? (
                    <Space direction="vertical">
                      {detailInfo?.attachment_list?.map((item, index) => (
                        <AttachmentDiv
                          key={index}
                          isImage={isImageFormat(item.attachment_name)}
                          attachment={item}
                        />
                      ))}
                    </Space>
                  ) : (
                    <span style={{ marginLeft: "12px", fontSize: "12px" }}>
                      无
                    </span>
                  )}
                </List.Item>
                <List.Item>
                  <Grid columns={2} gap={10} style={{ marginBottom: "4px" }}>
                    <Grid.Item>
                      <h4 style={{ margin: "0px" }}>定位及补充记录</h4>
                    </Grid.Item>
                    <Grid.Item>
                      <div
                        style={{
                          fontSize: "var(--adm-font-size-main)",
                          textAlign: "end",
                          color: "var(--adm-color-weak)",
                        }}
                      ></div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1}>
                    <Grid.Item>
                      <MobileReactQuill
                        name={"debug_info"}
                        title={"定位及补充记录"}
                        user={
                          detailInfo?.debug_info_dict.update_user_name || ""
                        }
                        avatar={detailInfo?.debug_info_dict.avatar || ""}
                        info={detailInfo?.debug_info_dict.debug_info || "无"}
                        time={detailInfo?.debug_info_dict.update_time || ""}
                        isCanChange={false}
                      />
                    </Grid.Item>
                  </Grid>
                </List.Item>
                <List.Item>
                  <Grid columns={2} gap={10} style={{ marginBottom: "4px" }}>
                    <Grid.Item>
                      <h4 style={{ margin: "0px" }}>操作及解决记录</h4>
                    </Grid.Item>
                    <Grid.Item>
                      <div
                        style={{
                          fontSize: "var(--adm-font-size-main)",
                          textAlign: "end",
                          color: "var(--adm-color-weak)",
                        }}
                      ></div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1}>
                    <Grid.Item>
                      <MobileReactQuill
                        name={"solution_info"}
                        title={"操作及解决记录"}
                        user={
                          detailInfo?.solution_info_dict.update_user_name || ""
                        }
                        avatar={detailInfo?.solution_info_dict.avatar || ""}
                        info={
                          detailInfo?.solution_info_dict.solution_info || "无"
                        }
                        time={detailInfo?.solution_info_dict.update_time || ""}
                        isCanChange={false}
                      />
                    </Grid.Item>
                  </Grid>
                </List.Item>
                <Collapse defaultActiveKey={[]}>
                  <Collapse.Panel
                    key={"comments-info"}
                    title={
                      <>
                        <Grid
                          columns={2}
                          gap={10}
                          style={{ marginBottom: "4px" }}
                        >
                          <Grid.Item>
                            <h4 style={{ margin: "0px" }}>工单评论</h4>
                          </Grid.Item>
                          <Grid.Item>
                            <Space
                              wrap
                              style={{
                                justifyContent: "flex-end",
                                marginTop: "6px",
                                width: "100%",
                              }}
                            >
                              {/* <div
                                style={{
                                  fontSize: "var(--adm-font-size-main)",
                                  // textAlign: "end",
                                  color: "var(--adm-color-weak)",
                                }}
                              >
                                点击展开
                              </div> */}
                              <div
                                style={{
                                  fontSize: "var(--adm-font-size-main)",
                                  // textAlign: "end",
                                  color: "var(--adm-color-weak)",
                                }}
                              >
                                <Tag color="primary" fill="outline">
                                  {detailInfo?.comment_length}条
                                </Tag>{" "}
                              </div>
                            </Space>
                          </Grid.Item>
                        </Grid>
                      </>
                    }
                  >
                    <Grid columns={1}>
                      <Grid.Item>
                        <MobileComments
                          name={"comments"}
                          title={"评论"}
                          user={
                            detailInfo?.solution_info_dict.update_user_name ||
                            ""
                          }
                          avatar={detailInfo?.solution_info_dict.avatar || ""}
                          detailInfo={detailInfo}
                        />
                      </Grid.Item>
                    </Grid>
                  </Collapse.Panel>
                </Collapse>

                <Collapse defaultActiveKey={["customer-info", "feedback-info"]}>
                  <Collapse.Panel
                    style={{ fontSize: "16px" }}
                    key={"customer-info"}
                    title="客户信息"
                  >
                    <Grid columns={2} gap={8}>
                      <Grid.Item>
                        <div>
                          <span>{"区域:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {detailInfo?.hospital_info.area_name}
                          </span>
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <div>
                          <span>{"省份:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {detailInfo?.hospital_info.province_name}
                          </span>
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <div>
                          <span>{"销售:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {detailInfo?.hospital_info.sales_user_name}
                          </span>
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <div>
                          <span>{"售前:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {detailInfo?.hospital_info.pre_sales_user_name}
                          </span>
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <div>
                          <span>{"售后:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {detailInfo?.hospital_info.execute_user_name}
                          </span>
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <div>
                          <span>{"运营:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {detailInfo?.hospital_info.operate_user_name}
                          </span>
                        </div>
                      </Grid.Item>
                    </Grid>

                    <Grid columns={1} gap={10} style={{ marginTop: "4px" }}>
                      <Grid.Item>
                        <div>
                          <span>{"远程信息:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {detailInfo?.remote_info}
                          </span>
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <span style={{ fontWeight: "bold" }}>
                          {"已上线产品:"}
                          <Space direction="vertical">
                            {detailInfo?.hospital_info.product_info ? (
                              detailInfo?.hospital_info?.product_info.map(
                                (item, index) => (
                                  <Tag
                                    key={`online_product_tag_${index}`}
                                    color="#108ee9"
                                  >
                                    {item.product_chinese_name}{" "}
                                    {item.product_version_name}{" "}
                                  </Tag>
                                ),
                              )
                            ) : (
                              <></>
                            )}
                            {/* {detailInfo?.hospital_info?.product_info.map(
                          (item, index) => (
                            <Tag color="#108ee9">
                              {item.product_chinese_name}{" "}
                              {item.product_version_name}{" "}
                            </Tag>
                          ),
                        )} */}
                          </Space>
                        </span>
                      </Grid.Item>
                    </Grid>
                  </Collapse.Panel>
                  <Collapse.Panel
                    style={{ fontSize: "16px" }}
                    key={"feedback-info"}
                    title="反馈信息"
                  >
                    <Grid columns={2} gap={8}>
                      <Grid.Item>
                        <div>
                          <span>{"渠道:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {detailInfo?.feedback_channel_name}
                          </span>
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <div>
                          <span>{"反馈人:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {detailInfo?.feedback_user}
                          </span>
                        </div>
                      </Grid.Item>
                    </Grid>
                    <Grid columns={2} gap={8}>
                      <Grid.Item>
                        <div>
                          <span>{"联系方式:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {detailInfo?.contact}
                          </span>
                        </div>
                      </Grid.Item>
                    </Grid>
                    <Grid columns={1} gap={10} style={{ marginTop: "4px" }}>
                      <Grid.Item>
                        <span>{"反馈时间:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.feedback_time}
                        </span>
                      </Grid.Item>
                    </Grid>
                  </Collapse.Panel>
                  <Collapse.Panel
                    style={{ fontSize: "16px" }}
                    key={"service-order-info"}
                    title="服务确认单"
                  >
                    <Grid columns={1} gap={8}>
                      <Grid.Item>
                        {detailInfo?.service_order_name ? (
                          <Space direction="vertical">
                            <AttachmentDiv
                              isImage={true}
                              attachment={{
                                attachment_name: detailInfo?.service_order_name,
                                attachment_addr: detailInfo?.service_order_addr,
                                id: detailInfo.id,
                                attachment_id: detailInfo.id,
                                update_user: "zsan",
                              }}
                            />
                          </Space>
                        ) : (
                          "无"
                        )}
                      </Grid.Item>
                    </Grid>
                  </Collapse.Panel>
                  <Collapse.Panel
                    style={{ fontSize: "16px" }}
                    key={"change-order-info"}
                    title="变更记录"
                  >
                    {detailInfo?.operation_record_list?.length ? (
                      <Timeline
                        style={{
                          paddingTop: "12px",
                          maxHeight: "400px",
                          overflow: "auto",
                        }}
                        items={detailInfo?.operation_record_list?.map((i) => ({
                          children: (
                            <Space direction="vertical">
                              <span>{FormatTime(i.create_time)}</span>
                              <Space>
                                <span>{i.update_user}</span>
                                <span>{i.process_trans_name}</span>
                                <span>{i.to_user}</span>
                              </Space>
                            </Space>
                          ),
                        }))}
                      />
                    ) : (
                      "无"
                    )}
                  </Collapse.Panel>
                  <Collapse.Panel
                    style={{ fontSize: "16px" }}
                    key={"transfer-info"}
                    title="转派挂起记录"
                  >
                    <Grid columns={1} gap={8}>
                      <Grid.Item>
                        <div>
                          <span>{"转派原因:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {detailInfo?.reasons_for_transfer}
                          </span>
                        </div>
                      </Grid.Item>
                    </Grid>
                    <Grid columns={1} gap={8}>
                      <Grid.Item>
                        <div>
                          <span>{"挂起原因:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {detailInfo?.suspension_reason}
                          </span>
                        </div>
                      </Grid.Item>
                    </Grid>

                    <Grid columns={1} gap={8}>
                      <Grid.Item>
                        <div>
                          <span>{"下次跟进日期:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {FormatDate(detailInfo?.next_time)}
                          </span>
                        </div>
                      </Grid.Item>
                    </Grid>
                  </Collapse.Panel>
                  <Collapse.Panel
                    style={{ fontSize: "16px" }}
                    key={"relation-order-list"}
                    title={
                      <>
                        <Grid
                          columns={2}
                          gap={10}
                          style={{ marginBottom: "4px" }}
                        >
                          <Grid.Item>
                            <span style={{ margin: "0px" }}>未解决工单</span>
                          </Grid.Item>
                          <Grid.Item>
                            <Space
                              wrap
                              style={{
                                justifyContent: "flex-end",
                                marginTop: "6px",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "var(--adm-font-size-main)",

                                  color: "var(--adm-color-weak)",
                                }}
                              >
                                <Tag color="primary" fill="outline">
                                  {detailInfo?.relation_order_list.length}个
                                </Tag>
                              </div>
                            </Space>
                          </Grid.Item>
                        </Grid>
                      </>
                    }
                  >
                    <List
                      style={{
                        maxHeight: "400px",
                        overflow: "auto",
                      }}
                    >
                      <>
                        {detailInfo?.relation_order_list?.map((item, index) => (
                          <List.Item key={`relation_order_list_${index}`}>
                            <Grid columns={1}>
                              <Grid.Item>
                                <Space wrap>
                                  <span>
                                    {processMap.get(item.process_name)}
                                  </span>
                                  <Link
                                    style={{ fontWeight: "normal" }}
                                    onClick={() => {
                                      setId({ detailId: item.id });
                                      currentReload();
                                    }}
                                  >
                                    {item.order_id}
                                  </Link>
                                  <span
                                    style={{
                                      color: " rgba(0, 0, 0, 0.45)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <Space>
                                      {item.order_type_name}
                                      {FormatTime(item.create_time)}
                                    </Space>
                                  </span>
                                </Space>
                              </Grid.Item>
                            </Grid>
                            <Grid columns={1}>
                              <Grid.Item>
                                <div>
                                  <span
                                    style={{
                                      color: " rgba(0, 0, 0, 0.45)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {"标题:"}
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: "8px",
                                      color: "black",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item.title}
                                  </span>
                                </div>
                              </Grid.Item>
                            </Grid>
                          </List.Item>
                        ))}
                      </>
                    </List>
                  </Collapse.Panel>
                  <Collapse.Panel
                    style={{ fontSize: "16px" }}
                    key={"similar_id_order_list"}
                    title={
                      <>
                        <Grid
                          columns={2}
                          gap={10}
                          style={{ marginBottom: "4px" }}
                        >
                          <Grid.Item>
                            <span style={{ margin: "0px" }}>相似工单</span>
                          </Grid.Item>
                          <Grid.Item>
                            <Space
                              wrap
                              style={{
                                justifyContent: "flex-end",
                                marginTop: "6px",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "var(--adm-font-size-main)",

                                  color: "var(--adm-color-weak)",
                                }}
                              >
                                <Tag color="primary" fill="outline">
                                  {detailInfo?.similar_order_list.length}个
                                </Tag>
                              </div>
                            </Space>
                          </Grid.Item>
                        </Grid>
                      </>
                    }
                  >
                    <List
                      style={{
                        maxHeight: "400px",
                        overflow: "auto",
                      }}
                    >
                      <>
                        {detailInfo?.similar_order_list?.map((item, index) => (
                          <List.Item key={`similar_order_list${index}`}>
                            <Grid columns={1}>
                              <Grid.Item>
                                <Space wrap>
                                  <span>
                                    {processMap.get(item.process_name)}
                                  </span>
                                  <Link
                                    style={{ fontWeight: "normal" }}
                                    onClick={() => {
                                      setId({ detailId: item.id });
                                      currentReload();
                                    }}
                                  >
                                    {item.order_id}
                                  </Link>
                                  <span
                                    style={{
                                      color: " rgba(0, 0, 0, 0.45)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <Space>
                                      {item.order_type_name}
                                      {FormatTime(item.create_time)}
                                    </Space>
                                  </span>
                                </Space>
                              </Grid.Item>
                            </Grid>
                            <Grid columns={1}>
                              <Grid.Item>
                                <div>
                                  <span
                                    style={{
                                      color: " rgba(0, 0, 0, 0.45)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {"标题:"}
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: "8px",
                                      color: "black",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item.title}
                                  </span>
                                </div>
                              </Grid.Item>
                            </Grid>
                          </List.Item>
                        ))}
                      </>
                    </List>
                  </Collapse.Panel>
                </Collapse>
              </List>
              <BreakDetailTransferModal
                detailInfo={detailInfo}
                onTrans={onTrans}
                next={nextProcess}
                department={tarnsDepartment}
              />
              <BreakDetailUpgradeModal
                detailInfo={detailInfo}
                onTrans={onTrans}
                next={nextProcess}
                department={tarnsDepartment}
              />
              <BreakDetailReverseModal
                detailInfo={detailInfo}
                onTrans={onTrans}
                next={nextProcess}
                department={tarnsDepartment}
              />
              <BreakDetailSuspendModal
                detailInfo={detailInfo}
                onTrans={onTrans}
                next={nextProcess}
              />
              <BreakDetailSolveModal
                detailInfo={detailInfo}
                onTrans={onTrans}
                next={nextProcess}
              />
              <Modal
                visible={breakDeleteOpening}
                destroyOnClose
                showCloseButton={true}
                header={
                  <ExclamationCircleFill
                    style={{
                      fontSize: 32,
                      color: "var(--adm-color-warning)",
                    }}
                  />
                }
                onClose={() => {
                  setBreakDeleteOpening(false);
                }}
                title={"确定要删除这个工单吗?"}
                actions={[
                  {
                    key: "delete",
                    text: (
                      <Button
                        fill="outline"
                        block
                        key="cancel"
                        color="danger"
                        onClick={() => {
                          onDelete(detailId);
                          setBreakDeleteOpening(false);
                          close();
                          setData(null);
                          reload();
                        }}
                      >
                        确认删除
                      </Button>
                    ),
                    onClick: () => {},
                  },
                  {
                    key: "cancel",
                    text: (
                      <Button
                        block
                        key="cancel"
                        color="primary"
                        onClick={() => {
                          setBreakDeleteOpening(false);
                        }}
                      >
                        取消
                      </Button>
                    ),
                  },
                ]}
              ></Modal>
              <MobileEditBreakListPopup
                editingItem={detailInfo}
                visible={editVisible}
                setVisible={setEditVisible}
                reload={currentReload}
              ></MobileEditBreakListPopup>
              <Modal
                visible={isPamVisible}
                title={pamInfo?.name || "未知"}
                closeOnAction
                onClose={() => setIsPamVisible(false)}
                actions={[
                  {
                    key: "confirm",
                    text: "确定",
                  },
                ]}
                content={
                  <Grid columns={3} gap={8}>
                    <Grid.Item span={1}>医院编号：</Grid.Item>
                    <Grid.Item span={2}>
                      <span style={{ fontWeight: "bold" }}>
                        {pamInfo?.code || "未知"}
                      </span>
                    </Grid.Item>
                    <Grid.Item span={1}>动态密码：</Grid.Item>
                    <Grid.Item span={2}>
                      <span style={{ fontWeight: "bold" }}>
                        {pamInfo?.hospitalPwd || "未知"}
                      </span>
                    </Grid.Item>
                    <Grid.Item span={3}>
                      <Divider />
                      <AutoCenter>
                        <Space>
                          <ExclamationCircleFill
                            style={{
                              color: "var(--adm-color-warning)",
                            }}
                          />
                          <span>密码当天有效，切勿泄露！</span>
                        </Space>
                      </AutoCenter>
                      <Divider />
                    </Grid.Item>
                  </Grid>
                }
              />
            </ContentCard>
          </PullToRefresh>
          <SafeArea position="bottom" />
        </>
      )}
    </Popup>
  );
};
