import { useEffect, useState } from "react";

import {
  Ellipsis,
  List,
  NavBar,
  Popup,
  PullToRefresh,
  SafeArea,
  Space,
  Collapse,
  Tag,
  Grid,
  Button,
  Toast,
} from "antd-mobile";

import { EditSOutline } from "antd-mobile-icons";
import { Timeline, Typography } from "antd";
import { FormatDate, FormatTime } from "utils";
import { useDebounceFn } from "ahooks";
import { useAuth } from "context/auth";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { MobileReactQuill } from "authenticated-app/mobile/components/MobileQuill";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { useDeliverylistDetail } from "authenticated-app/hooks/deliverylist/useDeliverylistDetail";
import { AttachmentDiv } from "authenticated-app/mobile/components/AttachmentDiv";
import { isImageFormat } from "authenticated-app/mobile/util";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { getBoolValue } from "utils";
import { NEXT_PROCESS_TYPE } from "lib/interface/launchChangeList";
import { TRANS_DELIVERY } from "lib/api/deliverylist";
import {
  SwapOutlined,
  RollbackOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";

import { PlayOutline, StopOutline, RedoOutline } from "antd-mobile-icons";
import { DeliveryDetailScheduledModal } from "authenticated-app/mobile/list/launchChangeList/detail/trans/schedule";
import { useDeliveryDetailScheduled } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailScheduled";
import { DeliveryDetailTransferModal } from "authenticated-app/mobile/list/launchChangeList/detail/trans/transfer";
import { useDeliveryDetailTransfer } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailTransfer";
import { useDeliveryDetailStop } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailStop";
import { DeliveryDetailStopModal } from "authenticated-app/mobile/list/launchChangeList/detail/trans/stop";
import { useDeliveryDetailReScheduled } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailReScheduled";
import { useDeliveryDetailSendback } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailSendback";
import { DeliveryDetailSendbackModal } from "authenticated-app/mobile/list/launchChangeList/detail/trans/sendback";
import { useDeliveryDetailPause } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailPause";
import { DeliveryDetailSendModal } from "authenticated-app/mobile/list/launchChangeList/detail/trans/send";
import { DeliveryDetailReScheduledModal } from "authenticated-app/mobile/list/launchChangeList/detail/trans/reschedule";
import { MobileEditDeliveryPopup } from "../edit";
import "./index.css";

import { DELIVERY_DETAIL_TYPE } from "lib/interface/launchChangeList";

import { GET_DELIVERY_DETAIL } from "lib/api/deliverylist";

import { useNavigate } from "react-router-dom";
import { versionParamas } from "utils";
import { breakListKey } from "../../tabs";
import { DeliveryDetailPauseModal } from "./trans/pause";
import { useDeliveryDetailRestart } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailRestart";
import { DeliveryDetailRestartModal } from "./trans/restart";
import { useDeliveryDetailSend } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailSend";
import { useDeliveryDetailComplete } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailComplete";
import { DeliveryDetailCompleteModal } from "./trans/complete";

const titleHeight = "45px";
const { Link } = Typography;

export const MobileDeliverylistDetailPopup = ({
  reload,
}: {
  reload: () => void;
}) => {
  const [{ detailId }] = useUrlQueryParam(["detailId"]);

  const [isOpen, , close] = useDeliverylistDetail();
  const [editVisible, setEditVisible] = useState<boolean>(false);

  const client = useHttp();

  const {
    run: getDetailInfo,
    data: detailInfo,
    isIdle,
    isLoading,
    setData,
  } = useAsync<DELIVERY_DETAIL_TYPE>();

  const getDetailInfoHandle = () => {
    getDetailInfo(client(GET_DELIVERY_DETAIL(detailId)));
  };
  const reloadDetailInfoHandle = () => {
    getDetailInfo(client(GET_DELIVERY_DETAIL(detailId)));
    window.location.reload();
  };

  const { run: getDetailInfoDebounce } = useDebounceFn(getDetailInfoHandle, {
    wait: 200,
  });

  const { hospital_name, order_id, customer_name } = detailInfo || {};

  const { user } = useAuth();

  const Loading = isIdle || isLoading;
  const isFinished = !!!isIdle;
  const isHasPermission =
    user?.id === detailInfo?.delivery_user_id &&
    detailInfo?.process_name !== "终止交付" &&
    detailInfo?.process_name !== "已完成";
  const [isCanChange, setIsCanChange] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState(false);
  const [nextProcess, setNextProcess] = useState<NEXT_PROCESS_TYPE>();
  const [deliveryDetailStartOpening, setDeliveryDetailStartOpening] =
    useState(false);
  const [deliveryDetailScheduledOpening, openDeliveryDetailScheduled] =
    useDeliveryDetailScheduled();
  const [deliveryDetailTransferOpening, openDeliveryDetailTransfer] =
    useDeliveryDetailTransfer();
  const [deliveryDetailStopOpening, openDeliveryDetailStop] =
    useDeliveryDetailStop();
  const [deliveryDetailReScheduledOpening, openDeliveryDetailReScheduled] =
    useDeliveryDetailReScheduled();
  const [deliveryDetailSendbackOpening, openDeliveryDetailSendback] =
    useDeliveryDetailSendback();
  const [deliveryDetailPauseOpeing, openDeliveryDetailPause] =
    useDeliveryDetailPause();
  const [deliveryDetailRestartOpening, openDeliveryDetailRestart] =
    useDeliveryDetailRestart();
  const [deliveryDetailSendOpening, openDeliveryDetailSend] =
    useDeliveryDetailSend();
  const [deliveryDetailCompleteOpening, openDeliveryDetailComplete] =
    useDeliveryDetailComplete();
  const currentReload = reloadDetailInfoHandle;
  const navigate = useNavigate();

  const { run: trans } = useAsync();
  const onTrans = (value: any, onTransType?: string) => {
    if (detailInfo?.id) {
      trans(
        client(TRANS_DELIVERY(detailInfo?.id), {
          method: "PUT",
          data: {
            ...value,
          },
        }),
      )
        .then((res) => {
          setIsChanged(true);
          setData(res);
          const content = `${res.order_id}转换为${res.process_name}!`;
          Toast.show({
            icon: "success",
            content: <ToastContent content={content} />,
            duration: 3000,
          });
        })
        .catch(() => getDetailInfoDebounce())
        .finally(() => {
          if (onTransType === "start") {
            setDeliveryDetailStartOpening(false);
          }
        });
    }
  };

  useEffect(() => {
    setIsCanChange(isFinished && isHasPermission);
  }, [isHasPermission, isFinished]);

  useEffect(() => {
    if (isOpen && detailId) {
      getDetailInfoDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailId]);

  const base_info = () => {
    switch (detailInfo?.delivery_type_name) {
      case "销售交付":
      case "试用交付":
      case "渠道交付":
        return (
          <>
            <List.Item
              description={
                <>
                  <Grid columns={2} gap={8}>
                    <Grid.Item>
                      <div>
                        <span>{"工单编号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.order_id}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"对接方式:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.docking_type}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"是否涉及定制化:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {getBoolValue(detailInfo?.is_customization)}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"审批单号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.approval_number}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"调出单号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.store_out_number}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"物流单号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.tracking_number}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"收件人及联系方式:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.contact}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"上线产品及版本:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.product_description
                            ?.split("|")
                            .join("、")}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                </>
              }
            >
              <h4 style={{ margin: "0px" }}>基本信息</h4>
            </List.Item>
          </>
        );
      case "产品升级":
        return (
          <>
            <List.Item
              description={
                <>
                  <Grid columns={2} gap={8}>
                    <Grid.Item>
                      <div>
                        <span>{"工单编号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.order_id}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"产品线类型:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.product_type}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"审批单号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.approval_number}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"是否定制版本:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {getBoolValue(detailInfo?.is_customization_version)}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"定制版本内容:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.customization_version_content}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"升级产品线及版本:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.product_description
                            ?.split("|")
                            .join("、")}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"是否存在历史定制化:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {getBoolValue(
                            detailInfo?.is_history_customization_version,
                          )}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"历史定制化内容:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.history_customization_version_content}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                </>
              }
            >
              <h4 style={{ margin: "0px" }}>基本信息</h4>
            </List.Item>
          </>
        );
      case "产品停用":
        return (
          <>
            <List.Item
              description={
                <>
                  <Grid columns={2} gap={8}>
                    <Grid.Item>
                      <div>
                        <span>{"工单编号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.order_id}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"审批单号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.approval_number}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>

                  <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"停用产品线:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.product_description
                            ?.split("|")
                            .join("、")}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                </>
              }
            >
              <h4 style={{ margin: "0px" }}>基本信息</h4>
            </List.Item>
          </>
        );
      case "产品撤机":
        return (
          <>
            <List.Item
              description={
                <>
                  <Grid columns={2} gap={8}>
                    <Grid.Item>
                      <div>
                        <span>{"工单编号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.order_id}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"审批单号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.approval_number}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"撤机类型:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.abort_type}
                        </span>
                      </div>
                    </Grid.Item>
                    {detailInfo?.abort_type === "部分撤机" ? (
                      <>
                        <Grid.Item>
                          <div>
                            <span>{"撤机产品线:"}</span>
                            <span style={{ marginLeft: "8px", color: "black" }}>
                              {detailInfo?.abort_product?.split("|").join("、")}
                            </span>
                          </div>
                        </Grid.Item>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </>
              }
            >
              <h4 style={{ margin: "0px" }}>基本信息</h4>
            </List.Item>
          </>
        );
      case "硬件部署": // 硬件部署
        return (
          <>
            <List.Item
              description={
                <>
                  <Grid columns={2} gap={8}>
                    <Grid.Item>
                      <div>
                        <span>{"工单编号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.order_id}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"是否存在历史定制化:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {getBoolValue(
                            detailInfo?.is_history_customization_version,
                          )}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"历史定制化内容:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.history_customization_version_content}
                        </span>
                      </div>
                    </Grid.Item>

                    <Grid.Item>
                      <div>
                        <span>{"涉及产品及版本:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.product_description
                            ?.split("|")
                            .join("、")}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                </>
              }
            >
              <h4 style={{ margin: "0px" }}>基本信息</h4>
            </List.Item>
          </>
        );
      case "组件升级":
        return (
          <>
            <List.Item
              description={
                <>
                  <Grid columns={2} gap={8}>
                    <Grid.Item>
                      <div>
                        <span>{"工单编号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.order_id}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"是否存在历史定制化:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {getBoolValue(
                            detailInfo?.is_history_customization_version,
                          )}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"历史定制化内容:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.history_customization_version_content}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={2} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"组件名称:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.module_name}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"组件版本:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.module_version}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                </>
              }
            >
              <h4 style={{ margin: "0px" }}>基本信息</h4>
            </List.Item>
          </>
        );
      default:
        return (
          <>
            <List.Item
              description={
                <>
                  <Grid columns={2} gap={8}>
                    <Grid.Item>
                      <div>
                        <span>{"工单编号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.order_id}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                  <Grid columns={1} gap={8} style={{ marginTop: "8px" }}>
                    <Grid.Item>
                      <div>
                        <span>{"审批单号:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.approval_number}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                </>
              }
            >
              <h4 style={{ margin: "0px" }}>基本信息</h4>
            </List.Item>
          </>
        );
    }
  };

  const delivery_info = () => {
    switch (detailInfo?.delivery_type_name) {
      case "产品升级":
      case "硬件部署":
        return (
          <>
            <Grid.Item>
              <div>
                <span>{"是否需要退回硬件:"}</span>
                <span style={{ marginLeft: "8px", color: "black" }}>
                  {getBoolValue(detailInfo?.is_need_send_back)}
                </span>
              </div>
            </Grid.Item>
            <Grid.Item>
              <div>
                <span>{"是否现场处理:"}</span>
                <span style={{ marginLeft: "8px", color: "black" }}>
                  {getBoolValue(detailInfo?.is_deal_with_on_site)}
                </span>
              </div>
            </Grid.Item>
          </>
        );
      case "组件升级":
      case "产品停用":
      case "产品撤机":
        return (
          <>
            <Grid.Item>
              <div>
                <span>{"是否现场处理:"}</span>
                <span style={{ marginLeft: "8px", color: "black" }}>
                  {getBoolValue(detailInfo?.is_deal_with_on_site)}
                </span>
              </div>
            </Grid.Item>
          </>
        );
      default:
        return <></>;
    }
  };

  const send_back_info = () => {
    switch (detailInfo?.delivery_type_name) {
      case "销售交付":
      case "试用交付":
      case "渠道交付":
        return (
          <>
            <Collapse.Panel
              style={{ fontSize: "16px" }}
              key={"sendback-info"}
              title="物料退回"
            >
              <Grid columns={1} gap={10}>
                <Grid.Item>
                  <div>
                    <span>{"是否需要退回硬件:"}</span>
                    <span style={{ marginLeft: "8px", color: "black" }}>
                      {getBoolValue(detailInfo?.is_need_send_back)}
                    </span>
                  </div>
                </Grid.Item>
                <Grid.Item>
                  <div>
                    <span>{"计划退回日期:"}</span>
                    <span style={{ marginLeft: "8px", color: "black" }}>
                      {detailInfo?.planned_return_date}
                    </span>
                  </div>
                </Grid.Item>
                <Grid.Item>
                  <div>
                    <span>{"实际退回日期:"}</span>
                    <span style={{ marginLeft: "8px", color: "black" }}>
                      {detailInfo?.actual_return_date}
                    </span>
                  </div>
                </Grid.Item>
                <Grid.Item>
                  <div>
                    <span>{"退回物流单号:"}</span>
                    <span style={{ marginLeft: "8px", color: "black" }}>
                      {detailInfo?.return_tracking_number}
                    </span>
                  </div>
                </Grid.Item>
              </Grid>
            </Collapse.Panel>
          </>
        );
    }
  };

  return (
    <Popup
      visible={isOpen}
      destroyOnClose
      bodyStyle={{
        height: "100vh",
        overflowY: "scroll",
      }}
    >
      <div className={"breaklist-detail-top"}>
        <NavBar
          style={{ lineHeight: titleHeight, "--height": titleHeight }}
          onBack={() => {
            close();
            setData(null);
            reload();
          }}
        >
          <Ellipsis
            direction="end"
            content={
              (order_id || "") +
              " " +
              (hospital_name || customer_name || "未知客户")
            }
            style={{
              fontSize: "14px",
              lineHeight: "50px",
              textAlign: "center",
            }}
          />
        </NavBar>
      </div>
      <LoadingMask visible={Loading} />
      <PullToRefresh
        onRefresh={async () => getDetailInfoHandle()}
        refreshingText={null}
      >
        <ContentCard style={{ margin: "0px" }}>
          <List>
            {detailInfo?.process_trans.length && isCanChange ? (
              <List.Item
                extra={
                  <Space wrap>
                    <Button
                      onClick={() => {
                        setEditVisible(true);
                      }}
                      color="primary"
                      fill="none"
                      size="small"
                    >
                      <EditSOutline />
                      编辑
                    </Button>
                  </Space>
                }
              >
                <ListHeader text={"工单详情"} loading={Loading} />
              </List.Item>
            ) : (
              <List.Item>
                <ListHeader text={"工单详情"} loading={Loading} />
              </List.Item>
            )}
            <List.Item
              description={
                <div>
                  <span>{"创建时间"}</span>
                  <span style={{ marginLeft: "8px" }}>
                    {FormatDate(detailInfo?.create_time)}
                  </span>
                </div>
              }
              arrow={
                <div
                  style={{
                    fontSize: "var(--adm-font-size-main)",
                    textAlign: "end",
                    color: "var(--adm-color-weak)",
                  }}
                >
                  <div>
                    {detailInfo?.is_urgent_delivery ? (
                      <Tag color="#ff6430" fill="outline">
                        紧急
                      </Tag>
                    ) : (
                      ""
                    )}
                  </div>
                  <Tag color="primary" fill="outline">
                    {detailInfo?.process_name}
                  </Tag>
                </div>
              }
            >
              <span style={{ wordBreak: "break-all" }}>
                {detailInfo?.delivery_type_name}
              </span>
            </List.Item>
            {detailInfo?.process_trans.length ? (
              <List.Item>
                <Grid columns={1} gap={8}>
                  <Grid.Item>
                    <span style={{ fontSize: "14px", color: "gray" }}>
                      工单流转
                    </span>
                  </Grid.Item>
                  <Grid.Item>
                    <Space style={{ marginRight: 24 }} wrap>
                      {detailInfo?.process_trans.map((i, index) => {
                        switch (i.title) {
                          case "生产寄送":
                            return (
                              <Button
                                key={index}
                                fill="outline"
                                size="small"
                                loading={deliveryDetailSendOpening}
                                onClick={() => {
                                  setNextProcess({
                                    next_process: i.next_process,
                                    trans_id: i.trans_id,
                                  });
                                  openDeliveryDetailSend();
                                }}
                              >
                                <SendOutlined />
                                {i.title}
                              </Button>
                            );
                          case "开始排期":
                            return (
                              <Button
                                key={index}
                                fill="outline"
                                size="small"
                                loading={deliveryDetailScheduledOpening}
                                onClick={() => {
                                  setNextProcess({
                                    next_process: i.next_process,
                                    trans_id: i.trans_id,
                                  });
                                  openDeliveryDetailScheduled();
                                }}
                              >
                                <PlayOutline />
                                {i.title}
                              </Button>
                            );
                          case "转派":
                            return (
                              <Button
                                key={index}
                                fill="outline"
                                size="small"
                                loading={deliveryDetailTransferOpening}
                                onClick={() => {
                                  setNextProcess({
                                    next_process: i.next_process,
                                    trans_id: i.trans_id,
                                  });
                                  openDeliveryDetailTransfer();
                                }}
                              >
                                <SwapOutlined />
                                {i.title}
                              </Button>
                            );
                          case "暂停交付":
                            return (
                              <Button
                                key={index}
                                fill="outline"
                                size="small"
                                loading={deliveryDetailPauseOpeing}
                                onClick={() => {
                                  setNextProcess({
                                    next_process: i.next_process,
                                    trans_id: i.trans_id,
                                  });
                                  openDeliveryDetailPause();
                                }}
                              >
                                <PauseCircleOutlined />
                                {i.title}
                              </Button>
                            );
                          case "重启交付":
                            return (
                              <Button
                                key={index}
                                fill="outline"
                                size="small"
                                loading={deliveryDetailRestartOpening}
                                onClick={() => {
                                  setNextProcess({
                                    next_process: i.next_process,
                                    trans_id: i.trans_id,
                                  });
                                  openDeliveryDetailRestart();
                                }}
                              >
                                <SwapOutlined />
                                {i.title}
                              </Button>
                            );
                          case "开始交付":
                          case "开始处理":
                            return (
                              <Button
                                key={index}
                                fill="outline"
                                size="small"
                                loading={deliveryDetailStartOpening}
                                onClick={() => {
                                  setDeliveryDetailStartOpening(true);
                                  onTrans(
                                    {
                                      next_process: i.next_process,
                                      trans_id: i.trans_id,
                                    },
                                    "start",
                                  );
                                }}
                              >
                                <PlayCircleOutlined />
                                {i.title}
                              </Button>
                            );
                          case "重新排期":
                            return (
                              <Button
                                key={index}
                                fill="outline"
                                size="small"
                                onClick={() => {
                                  setNextProcess({
                                    next_process: i.next_process,
                                    trans_id: i.trans_id,
                                  });
                                  openDeliveryDetailReScheduled();
                                }}
                                loading={deliveryDetailReScheduledOpening}
                              >
                                <RedoOutline />
                                {i.title}
                              </Button>
                            );
                          case "物料退回":
                            return (
                              <Button
                                key={index}
                                fill="outline"
                                size="small"
                                onClick={() => {
                                  setNextProcess({
                                    next_process: i.next_process,
                                    trans_id: i.trans_id,
                                  });
                                  openDeliveryDetailSendback();
                                }}
                                loading={deliveryDetailSendbackOpening}
                              >
                                <RollbackOutlined />
                                {i.title}
                              </Button>
                            );
                          case "终止交付":
                            return (
                              <Button
                                key={index}
                                fill="outline"
                                size="small"
                                loading={deliveryDetailStopOpening}
                                onClick={() => {
                                  setNextProcess({
                                    next_process: i.next_process,
                                    trans_id: i.trans_id,
                                  });
                                  openDeliveryDetailStop();
                                }}
                              >
                                <StopOutline />
                                {i.title}
                              </Button>
                            );
                          case "完成交付":
                            return (
                              <Button
                                key={index}
                                fill="outline"
                                size="small"
                                loading={deliveryDetailCompleteOpening}
                                onClick={() => {
                                  setNextProcess({
                                    next_process: i.next_process,
                                    trans_id: i.trans_id,
                                  });
                                  openDeliveryDetailComplete();
                                }}
                              >
                                <RollbackOutlined />
                                {i.title}
                              </Button>
                            );
                          default:
                            return null;
                        }
                      })}
                    </Space>
                  </Grid.Item>
                </Grid>
              </List.Item>
            ) : (
              <></>
            )}
            {base_info()}

            <List.Item
              description={
                <>
                  <Grid columns={2} gap={8}>
                    <Grid.Item>
                      <div>
                        <span>{"交付负责人:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.delivery_user_name}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"工单创建人:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.create_user}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                </>
              }
            >
              <h4 style={{ margin: "0px" }}>工单状态</h4>
            </List.Item>
            <List.Item
              description={
                <>
                  <Grid columns={1} gap={8}>
                    <MobileReactQuill
                      name={"description"}
                      title={"详细描述"}
                      user={""}
                      avatar={""}
                      info={detailInfo?.description || "无"}
                      time={""}
                      isCanChange={false}
                    />
                  </Grid>
                </>
              }
            >
              <h4 style={{ margin: "0px" }}>详细描述</h4>
            </List.Item>
            <List.Item
              description={
                <>
                  <Grid columns={window.innerWidth >= 390 ? 2 : 1} gap={8}>
                    <Grid.Item>
                      <div>
                        <span>{"计划交付日期:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.planned_delivery_date}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"初次入场日期:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.first_entry_date}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"实际交付日期:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.actual_delivery_date}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"下次跟进日期:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.next_entry_date}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"实际入场次数:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.actual_entry_number}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"是否完成退场检查:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {getBoolValue(detailInfo?.is_over_checklist)}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"是否更新客户信息:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {getBoolValue(detailInfo?.is_update_info)}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"多余物料是否退回:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {getBoolValue(detailInfo?.is_send_back, "无多余物料")}
                        </span>
                      </div>
                    </Grid.Item>
                    {delivery_info()}
                  </Grid>
                </>
              }
            >
              <h4 style={{ margin: "0px" }}>交付信息</h4>
            </List.Item>
            <List.Item
              description={
                <>
                  <Grid columns={1} gap={8}>
                    <MobileReactQuill
                      name={"supplementary_info"}
                      title={"详细描述"}
                      user={""}
                      avatar={""}
                      info={detailInfo?.supplementary_info || "无"}
                      time={""}
                      isCanChange={false}
                    />
                  </Grid>
                </>
              }
            >
              <h4 style={{ margin: "0px" }}>补充信息</h4>
            </List.Item>
            <List.Item>
              <h4 style={{ margin: "2px" }}>附件</h4>
              {detailInfo?.attachment_list ? (
                <Space direction="vertical">
                  {detailInfo?.attachment_list?.map((item, index) => (
                    <AttachmentDiv
                      key={index}
                      isImage={isImageFormat(item.attachment_name)}
                      attachment={item}
                    />
                  ))}
                </Space>
              ) : (
                <span style={{ marginLeft: "12px", fontSize: "12px" }}>无</span>
              )}
            </List.Item>
            <List.Item
              description={
                <>
                  <Grid columns={1} gap={8}>
                    <Grid.Item>
                      <div>
                        <span>{"暂停原因分类:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.pause_reason_type_name}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"暂停原因:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.pause_reason}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"重新排期原因分类:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.rescheduling_reason_type_name}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"重新排期原因:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.rescheduling_reason}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"终止原因:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.termination_reason}
                        </span>
                      </div>
                    </Grid.Item>
                    <Grid.Item>
                      <div>
                        <span>{"转派原因:"}</span>
                        <span style={{ marginLeft: "8px", color: "black" }}>
                          {detailInfo?.transfer_reason}
                        </span>
                      </div>
                    </Grid.Item>
                  </Grid>
                </>
              }
            >
              <h4 style={{ margin: "0px" }}>流转信息</h4>
            </List.Item>
            <Collapse defaultActiveKey={["customer-info", "feedback-info"]}>
              <Collapse.Panel
                style={{ fontSize: "16px" }}
                key={"customer-info"}
                title="客户信息"
              >
                <Grid columns={2} gap={8}>
                  <Grid.Item>
                    <div>
                      <span>{"区域:"}</span>
                      <span style={{ marginLeft: "8px", color: "black" }}>
                        {detailInfo?.hospital_info?.area_name}
                      </span>
                    </div>
                  </Grid.Item>
                  <Grid.Item>
                    <div>
                      <span>{"省份:"}</span>
                      <span style={{ marginLeft: "8px", color: "black" }}>
                        {detailInfo?.hospital_info?.province_name}
                      </span>
                    </div>
                  </Grid.Item>
                </Grid>
                <Grid columns={2} gap={8}>
                  <Grid.Item>
                    <div>
                      <span>{"售后:"}</span>
                      <span style={{ marginLeft: "8px", color: "black" }}>
                        {detailInfo?.hospital_info?.execute_user_name}
                      </span>
                    </div>
                  </Grid.Item>
                  <Grid.Item>
                    <div>
                      <span>{"运营:"}</span>
                      <span style={{ marginLeft: "8px", color: "black" }}>
                        {detailInfo?.hospital_info?.operate_user_name}
                      </span>
                    </div>
                  </Grid.Item>
                  <Grid.Item>
                    <div>
                      <span>{"销售:"}</span>
                      <span style={{ marginLeft: "8px", color: "black" }}>
                        {detailInfo?.hospital_info?.operate_user_name}
                      </span>
                    </div>
                  </Grid.Item>
                  <Grid.Item>
                    <div>
                      <span>{"售前:"}</span>
                      <span style={{ marginLeft: "8px", color: "black" }}>
                        {detailInfo?.hospital_info?.operate_user_name}
                      </span>
                    </div>
                  </Grid.Item>
                </Grid>
                <Grid columns={1} gap={10} style={{ marginTop: "4px" }}>
                  <Grid.Item>
                    <>
                      <span>已上线产品:</span>
                      <Space
                        direction="vertical"
                        style={{ marginLeft: "8px", color: "black" }}
                      >
                        {detailInfo?.hospital_info?.product_info ? (
                          detailInfo?.hospital_info?.product_info.map(
                            (item, index) => (
                              <Tag color="#108ee9" key={index}>
                                {item.product_chinese_name}{" "}
                                {item.product_version_name}{" "}
                              </Tag>
                            ),
                          )
                        ) : (
                          <></>
                        )}
                      </Space>
                    </>
                  </Grid.Item>
                </Grid>
              </Collapse.Panel>
              {send_back_info()}
              <Collapse.Panel
                style={{ fontSize: "16px" }}
                key={"change-order-info"}
                title="变更记录"
              >
                {detailInfo?.operation_record_list?.length ? (
                  <Timeline
                    style={{
                      paddingTop: "12px",
                      maxHeight: "400px",
                      overflow: "auto",
                    }}
                    items={detailInfo?.operation_record_list?.map((i) => ({
                      children: (
                        <Space direction="vertical">
                          <span>{FormatTime(i.create_time)}</span>
                          <Space>
                            <span>{i.update_user}</span>
                            <span>{i.process_trans_name}</span>
                            <span>{i.to_user}</span>
                          </Space>
                        </Space>
                      ),
                    }))}
                  />
                ) : (
                  "无"
                )}
              </Collapse.Panel>
              <Collapse.Panel
                style={{ fontSize: "16px" }}
                key={"relation-order-list"}
                title="关联工单"
              >
                <List
                  style={{
                    maxHeight: "400px",
                    overflow: "auto",
                  }}
                >
                  <>
                    {detailInfo?.relation_order_list?.map((item, index) => (
                      <List.Item>
                        <Grid columns={1}>
                          <Grid.Item>
                            <Space wrap>
                              <Link
                                style={{ fontWeight: "normal" }}
                                onClick={() => {
                                  navigate({
                                    search: versionParamas({
                                      detailId: item.id.toString(),
                                      tab: breakListKey,
                                      openBreaklistDetail: "true",
                                    }),
                                  });
                                }}
                              >
                                {item.order_id}
                              </Link>
                              <span
                                style={{
                                  color: " rgba(0, 0, 0, 0.45)",
                                  fontSize: "12px",
                                }}
                              >
                                <Space>{item.order_type_name}</Space>
                              </span>
                              {/* <Button
                                fill="none"
                                size=""
                                onClick={() => {
                                  navigate({
                                    // pathname: `${breakListKey}`,
                                    search: versionParamas({
                                      detailId: item.id.toString(),
                                      tab: breakListKey,
                                      openBreaklistDetail: "true",
                                    }),
                                  });
                                }}
                              >
                                [{item.order_id}]
                              </Button>
                              <span
                                style={{
                                  color: " rgba(0, 0, 0, 0.45)",
                                  fontSize: "12px",
                                }}
                              >
                                <Space>
                                  {item.order_type_name}
                                </Space>
                              </span> */}
                            </Space>
                          </Grid.Item>
                        </Grid>
                        <Grid columns={1}>
                          <Grid.Item>
                            <div>
                              <span
                                style={{
                                  color: " rgba(0, 0, 0, 0.45)",
                                  fontSize: "12px",
                                }}
                              >
                                {"标题:"}
                              </span>
                              <span
                                style={{
                                  marginLeft: "8px",
                                  color: "black",
                                  fontSize: "12px",
                                }}
                              >
                                {item.title}
                              </span>
                            </div>
                          </Grid.Item>
                        </Grid>
                      </List.Item>
                    ))}
                  </>
                </List>
              </Collapse.Panel>
            </Collapse>
          </List>
          <DeliveryDetailScheduledModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
          />
          <DeliveryDetailTransferModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
          />
          <DeliveryDetailRestartModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
          />
          <DeliveryDetailStopModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
          />
          <DeliveryDetailReScheduledModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
          />
          <DeliveryDetailSendbackModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
          />
          <DeliveryDetailPauseModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
          />
          <DeliveryDetailSendModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
          />
          <DeliveryDetailCompleteModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
          />
          <MobileEditDeliveryPopup
            editingItem={detailInfo}
            visible={editVisible}
            setVisible={setEditVisible}
            reload={currentReload}
          ></MobileEditDeliveryPopup>
        </ContentCard>
      </PullToRefresh>
      <SafeArea position="bottom" />
    </Popup>
  );
};
