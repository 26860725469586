import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useBreakDetailTransfer = () => {
  const [{ openBreakDetailTransfer }, setOpenBreakDetailTransfer] =
    useUrlQueryParam(["openBreakDetailTransfer"]);

  const open = () =>
    setOpenBreakDetailTransfer({ openBreakDetailTransfer: true });
  const close = () =>
    setOpenBreakDetailTransfer({
      openBreakDetailTransfer: undefined,
    });

  return [openBreakDetailTransfer === "true", open, close] as const;
};
