export const GET_LNG_LAT = "/rommanAPI/hospitals/get_longitude_latitude/";

/** GET参数   类型       能否为空     示例
 * type	      string	False	    hospital	    按照类型返回结果,   hospital  或者  product   对应客户和产品
 * area_id	  int	    Ture	    1	            筛选条件, 区域id
 * level	  string	True	    L0|L1	        筛选条件, 默认返回所有    枚举值: L0, L1, L2, L3
 * status	  string	True	    使用中|待上线	 筛选条件, 默认返回所有    枚举值: 待上线, 新产品上线, 使用中, 已停用, 已撤出
 */
export const GET_PRODUCT_STAT = "/rommanAPI/hospitals/get_city_status_level/";

export const GET_HOSP_STATUS = "/rommanAPI/hospitals/get_city_hospital_status/";

/**参数名       类型     能否为空     示例    备注
 * area_id	    int	    True	    1	    区域id
 * province_id	int	    True	    5	    省份id
 */
export const GET_HOSP_TYPE = "/rommanAPI/hospitals/get_hospital_type/";
export const GET_HOSP_MAINTENANCE =
  "/rommanAPI/product/get_product_maintenance/";

/**参数名                类型      能否为空    示例     备注
 * using_status	        string	  True	      使用中	 产品状态
 * area_id	            int	      True	      1	      区域id
 * province_id	        int	      True	      5	      省份id
 */
export const GET_PRODUCT_NUM = "/rommanAPI/product/get_product_num/";
export const GET_PRODUCT_VERSION_DETAIL =
  "/rommanAPI/product/get_product_version/";

/**参数名                类型      能否为空    示例     备注
 * area_id	            int	      True	      1	      区域id
 */
export const GET_MAINTENANCE_STATISTIC =
  "/rommanAPI/hospitals/get_user_hospitals/";

/**参数名                类型      能否为空    示例     备注
 * area_id	            int	      True	      1	      区域id
 * province_id	        int	      True	      5	      省份id
 */
export const GET_DOCKING_STATISTIC =
  "/rommanAPI/hospitals/get_mult_docking_product/";

/**参数名                类型      能否为空    示例     备注
 * area_id	            int	      True	      1	      区域id
 */
export const GET_CLIENT_STATISTIC = "/rommanAPI/client_info/get_client_num/";

/**参数名                类型      能否为空    示例     备注
 * area_id	            int	      True	      1	      区域id
 */
export const GET_REMOTE_STATISTIC = "/rommanAPI/remote/get_remote_info/";

/**参数名                类型      能否为空    示例     备注
 * area_id	            int	      True	      1	      区域id
 */
export const GET_EQPT_MODEL_STATISTIC =
  "/rommanAPI/service_record/get_eqpt_model_num/";

export const GET_ASSET_LIST = "/rommanAPI/remote/get_assets/";
