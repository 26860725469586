import { MutableRefObject, useEffect, useRef, useState } from "react";
import { ActionType, PageHeader } from "@ant-design/pro-components";
import { Button, Divider, Form, Modal, Space, Spin, message } from "antd";
import { useDebounceFn, useScroll } from "ahooks";
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  SwapOutlined,
  RedoOutlined,
  RollbackOutlined,
  StopOutlined,
  SendOutlined,
} from "@ant-design/icons";

import { useAuth } from "context/auth";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { useDeliverylistDetail } from "authenticated-app/hooks/deliverylist/useDeliverylistDetail";
import { EditUpload } from "authenticated-app/pc/components/EditUpload";
import {
  DELIVERY_DETAIL_TYPE,
  NEXT_PROCESS_TYPE,
} from "lib/interface/launchChangeList";
import { DeliveryDetailBaseInfo } from "./baseInfo";
import { DeliveryOrderDetailInfo } from "./detailInfo";
import { useDeliveryDetailSend } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailSend";
import { useDeliveryDetailScheduled } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailScheduled";
import { useDeliveryDetailPause } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailPause";
import { useDeliveryDetailTransfer } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailTransfer";
import { useDeliveryDetailRestart } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailRestart";
import { useDeliveryDetailStop } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailStop";
import { useDeliveryDetailSendback } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailSendback";
import { useDeliveryDetailComplete } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailComplete";
import { useDeliveryDetailReScheduled } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailReScheduled";
import { DeliveryDetailSendModal } from "./trans/send";
import { DeliveryDetailScheduledModal } from "./trans/schedule";
import { DeliveryDetailPauseModal } from "./trans/pause";
import { DeliveryDetailTransferModal } from "./trans/transfer";
import { DeliveryDetailRestartModal } from "./trans/restart";
import { DeliveryDetailStopModal } from "./trans/stop";
import { DeliveryDetailSendbackModal } from "./trans/sendback";
import { DeliveryDetailCompleteModal } from "./trans/complete";
import { DeliveryDetailReScheduledModal } from "./trans/reschedule";
import { BreakDetailRight } from "./right";
import { DeliveryTransOrderDetailInfo } from "./transinfo";
import { GET_DELIVERY_DETAIL, TRANS_DELIVERY } from "lib/api/deliverylist";
import { UPDATE_DELIVERY_DETAIL } from "lib/api/deliverylist";
import "./index.css";

export const BreakDetailModal = ({
  actionRef,
}: {
  actionRef: MutableRefObject<ActionType | undefined>;
}) => {
  const { user } = useAuth();
  const [isChanged, setIsChanged] = useState(false);
  const [isOpen, , close] = useDeliverylistDetail();
  const [{ detailId }] = useUrlQueryParam(["detailId"]);
  const [nextProcess, setNextProcess] = useState<NEXT_PROCESS_TYPE>();
  const [deliveryDetailStartOpening, setDeliveryDetailStartOpening] =
    useState(false);
  const [deliveryDetailScheduledOpening, openDeliveryDetailScheduled] =
    useDeliveryDetailScheduled();
  const [deliveryDetailPauseOpeing, openDeliveryDetailPause] =
    useDeliveryDetailPause();
  const [deliveryDetailTransferOpening, openDeliveryDetailTransfer] =
    useDeliveryDetailTransfer();
  const [deliveryDetailRestartOpening, openDeliveryDetailRestart] =
    useDeliveryDetailRestart();
  const [deliveryDetailStopOpening, openDeliveryDetailStop] =
    useDeliveryDetailStop();
  const [deliveryDetailSendOpening, openDeliveryDetailSend] =
    useDeliveryDetailSend();

  const [deliveryDetailSendbackOpening, openDeliveryDetailSendback] =
    useDeliveryDetailSendback();
  const [deliveryDetailCompleteOpening, openDeliveryDetailComplete] =
    useDeliveryDetailComplete();
  const [deliveryDetailReScheduledOpening, openDeliveryDetailReScheduled] =
    useDeliveryDetailReScheduled();

  const divRef = useRef<HTMLDivElement>(null);
  const scroll = useScroll(divRef);

  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getDetailInfo,
    data: detailInfo,
    isIdle,
    isLoading,
    setData,
  } = useAsync<DELIVERY_DETAIL_TYPE>();
  const getDetailInfoHandle = () => {
    getDetailInfo(client(GET_DELIVERY_DETAIL(detailId)));
  };
  const { run: getDetailInfoDebounce } = useDebounceFn(getDetailInfoHandle, {
    wait: 200,
  });

  const { run: update } = useAsync();
  const onUpdate = (value: any) => {
    if (detailInfo?.id) {
      update(
        client(UPDATE_DELIVERY_DETAIL(detailInfo?.id), {
          method: "PUT",
          data: {
            ...value,
          },
        }),
      )
        .then((res) => {
          const content = `${res.order_id}更新成功!`;
          message.success(content, 5);
          setIsChanged(true);
          setData(res);
        })
        .catch(() => getDetailInfoDebounce());
    }
  };

  const { run: trans } = useAsync();
  const onTrans = (value: any, onTransType?: string) => {
    if (detailInfo?.id) {
      trans(
        client(TRANS_DELIVERY(detailInfo?.id), {
          method: "PUT",
          data: {
            ...value,
          },
        }),
      )
        .then((res) => {
          setIsChanged(true);
          setData(res);
          const content = `${res.order_id}转换为${res.process_name}!`;
          message.success(content, 5);
        })
        .catch(() => getDetailInfoDebounce())
        .finally(() => {
          if (onTransType === "start") {
            setDeliveryDetailStartOpening(false);
          }
        });
    }
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      setIsChanged(false);
      if (detailId) {
        getDetailInfoDebounce();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailId]);

  const Loading = isIdle || isLoading;
  const isFinished = !!!isIdle;
  const isHasPermission =
    user?.id === detailInfo?.delivery_user_id &&
    detailInfo?.process_name !== "终止交付" &&
    detailInfo?.process_name !== "已完成";
  const [isCanChange, setIsCanChange] = useState<boolean>(false);

  useEffect(() => {
    setIsCanChange(isFinished && isHasPermission);
  }, [isHasPermission, isFinished]);

  return (
    <Modal
      centered
      maskClosable={false}
      destroyOnClose
      open={isOpen}
      afterClose={() => {
        setData(null);
        if (isChanged) {
          actionRef.current?.reload();
        }
      }}
      onCancel={close}
      footer={null}
      width={"93vw"}
    >
      <Spin spinning={Loading} style={{ height: "100%" }}>
        <PageHeader
          className="page-header"
          title={
            <>
              <span style={{ fontWeight: "normal", marginRight: "16px" }}>
                {detailInfo?.delivery_type_name}{" "}
                {detailInfo?.is_urgent_delivery ? (
                  <span style={{ color: "red" }}>(紧急)</span>
                ) : (
                  <></>
                )}
              </span>
              {detailInfo?.hospital_name || "未知客户"}
            </>
          }
          extra={
            <Space style={{ marginRight: 24 }}>
              {detailInfo?.process_trans.map((i, index) => {
                switch (i.title) {
                  case "生产寄送":
                    return (
                      <Button
                        key={index}
                        icon={<SendOutlined />}
                        onClick={() => {
                          setNextProcess({
                            next_process: i.next_process,
                            trans_id: i.trans_id,
                          });
                          openDeliveryDetailSend();
                        }}
                        loading={deliveryDetailSendOpening}
                      >
                        {i.title}
                      </Button>
                    );
                  case "开始排期":
                    return (
                      <Button
                        key={index}
                        icon={<PlayCircleOutlined />}
                        onClick={() => {
                          setNextProcess({
                            next_process: i.next_process,
                            trans_id: i.trans_id,
                          });
                          openDeliveryDetailScheduled();
                        }}
                        loading={deliveryDetailScheduledOpening}
                      >
                        {i.title}
                      </Button>
                    );
                  case "转派":
                    return (
                      <Button
                        key={index}
                        icon={<SwapOutlined />}
                        onClick={() => {
                          setNextProcess({
                            next_process: i.next_process,
                            trans_id: i.trans_id,
                          });
                          openDeliveryDetailTransfer();
                        }}
                        loading={deliveryDetailTransferOpening}
                      >
                        {i.title}
                      </Button>
                    );
                  case "暂停交付":
                    return (
                      <Button
                        key={index}
                        icon={<PauseCircleOutlined />}
                        onClick={() => {
                          // setNextProcess(i.next_process);
                          setNextProcess({
                            next_process: i.next_process,
                            trans_id: i.trans_id,
                          });
                          openDeliveryDetailPause();
                        }}
                        loading={deliveryDetailPauseOpeing}
                      >
                        {i.title}
                      </Button>
                    );
                  case "重启交付":
                    return (
                      <Button
                        key={index}
                        icon={<SwapOutlined />}
                        onClick={() => {
                          setNextProcess({
                            next_process: i.next_process,
                            trans_id: i.trans_id,
                          });
                          openDeliveryDetailRestart();
                        }}
                        loading={deliveryDetailRestartOpening}
                      >
                        {i.title}
                      </Button>
                    );
                  case "重新排期":
                    return (
                      <Button
                        key={index}
                        icon={<RedoOutlined />}
                        onClick={() => {
                          setNextProcess({
                            next_process: i.next_process,
                            trans_id: i.trans_id,
                          });
                          openDeliveryDetailReScheduled();
                        }}
                        loading={deliveryDetailReScheduledOpening}
                      >
                        {i.title}
                      </Button>
                    );
                  case "开始交付":
                  case "开始处理":
                    return (
                      <Button
                        key={index}
                        icon={<PlayCircleOutlined />}
                        onClick={() => {
                          setDeliveryDetailStartOpening(true);
                          onTrans(
                            {
                              next_process: i.next_process,
                              trans_id: i.trans_id,
                            },
                            "start",
                          );
                        }}
                        loading={deliveryDetailStartOpening}
                      >
                        {i.title}
                      </Button>
                    );
                  case "终止交付":
                    return (
                      <Button
                        key={index}
                        icon={<StopOutlined />}
                        onClick={() => {
                          setNextProcess({
                            next_process: i.next_process,
                            trans_id: i.trans_id,
                          });
                          openDeliveryDetailStop();
                        }}
                        loading={deliveryDetailStopOpening}
                      >
                        {i.title}
                      </Button>
                    );
                  case "物料退回":
                    return (
                      <Button
                        key={index}
                        icon={<RollbackOutlined />}
                        onClick={() => {
                          setNextProcess({
                            next_process: i.next_process,
                            trans_id: i.trans_id,
                          });
                          openDeliveryDetailSendback();
                        }}
                        loading={deliveryDetailSendbackOpening}
                      >
                        {i.title}
                      </Button>
                    );
                  case "完成交付":
                    return (
                      <Button
                        key={index}
                        icon={<RollbackOutlined />}
                        onClick={() => {
                          setNextProcess({
                            next_process: i.next_process,
                            trans_id: i.trans_id,
                          });
                          openDeliveryDetailComplete();
                        }}
                        loading={deliveryDetailCompleteOpening}
                      >
                        {"完成交付"}
                      </Button>
                    );
                  default:
                    return null;
                }
              })}
            </Space>
          }
        />
        <Divider style={{ margin: "12px 0px" }} />
        <div ref={divRef} className="break-detail-div">
          <div style={{ flex: 3, height: "100%" }}>
            <DeliveryDetailBaseInfo
              onUpdate={onUpdate}
              detailInfo={detailInfo}
              isCanChange={isCanChange}
            />
            <Divider style={{ margin: "8px 0px" }} />
            <DeliveryOrderDetailInfo
              onUpdate={onUpdate}
              detailInfo={detailInfo}
              isCanChange={isCanChange}
            />
            <Divider />
            <EditUpload
              onUpdate={onUpdate}
              name={"attachment_id_list"}
              title={"附件"}
              defaultValue={detailInfo?.attachment_list}
              isCanChange={isCanChange}
            />
            <Divider />
            <DeliveryTransOrderDetailInfo
              onUpdate={onUpdate}
              detailInfo={detailInfo}
              isFinished={isFinished}
              isHasPermission={isHasPermission}
            />
          </div>
          <Divider
            style={{ height: `calc(100% + ${scroll?.top}px)` }}
            type="vertical"
          />
          <BreakDetailRight
            onUpdate={onUpdate}
            detailInfo={detailInfo}
            isCanChange={isCanChange}
          />
        </div>
      </Spin>
      <DeliveryDetailSendModal
        detailInfo={detailInfo}
        onTrans={onTrans}
        next={nextProcess}
      />
      <DeliveryDetailScheduledModal
        detailInfo={detailInfo}
        onTrans={onTrans}
        next={nextProcess}
      />
      <DeliveryDetailPauseModal
        detailInfo={detailInfo}
        onTrans={onTrans}
        next={nextProcess}
      />
      <DeliveryDetailTransferModal
        detailInfo={detailInfo}
        onTrans={onTrans}
        next={nextProcess}
      />
      <DeliveryDetailRestartModal
        detailInfo={detailInfo}
        onTrans={onTrans}
        next={nextProcess}
      />
      <DeliveryDetailStopModal
        detailInfo={detailInfo}
        onTrans={onTrans}
        next={nextProcess}
      />
      <DeliveryDetailSendbackModal
        detailInfo={detailInfo}
        onTrans={onTrans}
        next={nextProcess}
      />
      <DeliveryDetailCompleteModal
        detailInfo={detailInfo}
        onTrans={onTrans}
        next={nextProcess}
        isCanChange={isCanChange}
      />
      <DeliveryDetailReScheduledModal
        detailInfo={detailInfo}
        onTrans={onTrans}
        next={nextProcess}
      />
    </Modal>
  );
};
