import { useEffect, Dispatch, SetStateAction } from "react";
import { Modal, Form, Input, message, Select } from "antd";

import { useHttp } from "utils/http";
import { DisposeForm } from "utils";
import { useAsync } from "utils/hooks/useAsync";

export const ChecklistBaseSettingAdd = ({
  title,
  submitAPI,
  visible,
  setVisible,
  reload,
  isSurvey = false,
}: {
  title: string;
  submitAPI: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
  isSurvey?: boolean;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const { run: AddChecklist, isLoading: AddingChecklist } = useAsync();

  const onSubmit = (values: any) => {
    AddChecklist(client(submitAPI, { method: "POST", data: { ...values } }))
      .then(() => {
        message.success("添加成功！");
        setVisible(false);
        reload();
      })
      .catch((e) => {
        console.error(e);
        message.error("添加失败，请检查或联系管理员！");
      });
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={`新增${title}项`}
      destroyOnClose
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={form.submit}
      confirmLoading={AddingChecklist}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit}
      >
        <Form.Item
          name={isSurvey ? "content" : "check_item"}
          label={isSurvey ? "调研内容" : "检查项"}
          rules={[
            {
              required: true,
              message: `必须填写${isSurvey ? "调研内容" : "检查项"}`,
            },
          ]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="参考指标"
          rules={[{ required: true, message: "必须填写参考指标" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="is_need"
          label="必须"
          rules={[{ required: true, message: "必须选择必要性" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            options={[
              { label: "是", value: 1 },
              { label: "否", value: 0 },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="is_start"
          label="启用"
          rules={[{ required: true, message: "必须选择是否启用" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            options={[
              { label: "是", value: 1 },
              { label: "否", value: 0 },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
