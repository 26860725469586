import { Dispatch, SetStateAction, useEffect, useReducer } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import InfoTabPane from "./tab-pane/infoTabPane";
import HardwareTabPane from "./tab-pane/hardwareTabPane";
import DockingTabPane from "./tab-pane/dockingTabPane";
import ProductTabPane from "./tab-pane/productTabPane";
import RemoteTabPane from "./tab-pane/remoteTabPane";
import ClientTabPane from "./tab-pane/clientTabPane";
import OnlineChangeTabPane from "./tab-pane/onlineChangeTabPane";
import { HospListType } from "interface";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const DetailPageSlim = ({
  hospID,
  hospInfo,
  setDisChange,
}: {
  hospID: number | null;
  hospInfo: HospListType | undefined;
  setDisChange: Dispatch<SetStateAction<boolean>>;
}) => {
  const [{ tab }, setTab] = useUrlQueryParam(["tab"]);

  const [changeCount, notifyRender] = useReducer((x) => x + 1, 0);

  const tabItems: TabsProps["items"] = [
    {
      key: "info",
      label: `客户详情`,
      children: <InfoTabPane hospID={hospID} setDisChange={setDisChange} />,
    },
    {
      key: "product",
      label: `产品信息`,
      children: (
        <ProductTabPane
          hospID={hospID}
          setDisChange={setDisChange}
          changeCount={changeCount}
        />
      ),
    },
    {
      key: "hardware",
      label: `硬件信息`,
      children: (
        <HardwareTabPane
          hospID={hospID}
          setDisChange={setDisChange}
          changeCount={changeCount}
        />
      ),
    },
    {
      key: "docking",
      label: `对接信息`,
      children: (
        <DockingTabPane
          hospID={hospID}
          setDisChange={setDisChange}
          changeCount={changeCount}
        />
      ),
    },
    {
      key: "remote",
      label: `远程信息`,
      children: (
        <RemoteTabPane
          hospID={hospID}
          hospCode={hospInfo?.code}
          hospitalName={hospInfo?.name}
          setDisChange={setDisChange}
        />
      ),
    },
    {
      key: "client",
      label: `客户端信息`,
      children: <ClientTabPane hospID={hospID} setDisChange={setDisChange} />,
    },
    {
      key: "onlineChange",
      label: `上线变更`,
      children: (
        <OnlineChangeTabPane hospID={hospID} setDisChange={setDisChange} />
      ),
    },
  ];

  useEffect(() => {
    if (tab === "") {
      setTab({ tab: "info" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <div
      style={{
        height: "100%",
        paddingRight: "8px",
        overflow: "auto",
        minWidth: "1032px",
      }}
    >
      <Tabs
        defaultActiveKey={tab}
        onChange={(v) => {
          if (v) {
            setTab({ tab: v });
            notifyRender();
          }
        }}
        items={tabItems}
      />
    </div>
  );
};
