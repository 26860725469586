import { Dispatch, SetStateAction, useEffect } from "react";
import { Modal, Form, Checkbox } from "antd";

import { DisposeForm, hosStatus, statusOptions } from "utils";
import { GET_OUTPUT } from "lib/api";
import { useAsync } from "utils/hooks/useAsync";
import { useDownload } from "utils/download";

enum sheetName {
  customer = "客户信息",
  product = "产品线信息",
  hardware = "硬件信息",
  docking = "对接信息",
  other = "其它信息",
}

const sheetOptions = [
  { label: sheetName.customer, value: sheetName.customer },
  { label: sheetName.product, value: sheetName.product },
  { label: sheetName.hardware, value: sheetName.hardware },
  { label: sheetName.docking, value: sheetName.docking },
  { label: sheetName.other, value: sheetName.other },
];

export const ListOutput = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  const [form] = Form.useForm();
  const downloadClient = useDownload();

  const { run: getFile, isLoading: gettingFile } = useAsync();

  const submit = (value: { status: string[]; sheet: string[] }) => {
    getFile(
      downloadClient(GET_OUTPUT, {
        data: {
          is_download: 1,
          hospital_status: value?.status?.map((i) => i).join("|"),
          sheet_name: value?.sheet?.map((i) => i).join("|"),
        },
      }),
    );
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="客户信息导出选项"
      maskClosable={false}
      destroyOnClose
      open={visible}
      onCancel={() => setVisible(false)}
      cancelButtonProps={{ disabled: gettingFile }}
      okButtonProps={{
        htmlType: "submit",
        loading: gettingFile,
      }}
      onOk={() => form.submit()}
      okText={"导出"}
      width={720}
    >
      <Form
        id="listOutputForm"
        form={form}
        onFinish={submit}
        initialValues={{
          status: [hosStatus.pending, hosStatus.using, hosStatus.stop],
          sheet: [sheetName.customer, sheetName.product, sheetName.hardware],
        }}
      >
        <Form.Item
          name="status"
          label="客户状态"
          rules={[{ required: true, message: "至少选择一种状态" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Checkbox.Group options={statusOptions} />
        </Form.Item>

        <Form.Item
          name="sheet"
          label="导出信息"
          rules={[{ required: true, message: "至少选择一个信息类" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Checkbox.Group options={sheetOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
