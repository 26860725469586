import { Ellipsis } from "antd-mobile";

export const DelConfirmContent = ({
  content,
  title = "确认删除",
}: {
  content: string;
  title?: string;
}) => {
  return (
    <span>
      <p style={{ textAlign: "center", fontWeight: "bolder" }}>{title}</p>
      <Ellipsis
        direction="end"
        content={content}
        style={{ textAlign: "center" }}
      />
    </span>
  );
};
