import { useState } from "react";
import { Button, Space, message, Popconfirm, Card, Table } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { useMount } from "utils";
import { ChecklistExceptSettingAdd } from "./add";
import { ChecklistSurveySettingEdit } from "./edit";
import { SettingAPI } from "authenticated-app/pc/setting/customer-setting/interface";
import { ChecklistExceptType } from "../interface";

const ChecklistExceptSetting = ({
  title,
  ctrlSetting,
}: {
  title: string;
  ctrlSetting: SettingAPI;
}) => {
  const client = useHttp();

  // 必要信息
  const [addSettingVisible, setAddSettingVisible] = useState(false);
  const [editSettingVisible, setEditSettingVisible] = useState(false);
  const [editSettingInfo, setEditSettingInfo] = useState<ChecklistExceptType>();

  const {
    run: getSettingList,
    isIdle,
    isLoading: gettingSettingList,
    data: setting,
  } = useAsync<ChecklistExceptType[]>();
  const getSettingListHandle = () => {
    getSettingList(client(ctrlSetting.GET_SETTING));
  };

  const reload = getSettingListHandle;
  const loading = gettingSettingList || isIdle;

  useMount(getSettingListHandle);

  const delSetting = (id: number) => {
    if (id)
      client(ctrlSetting.DEL_SETTING(id), { method: "DELETE" })
        .then(() => {
          message.success("删除成功！");
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("删除失败，请联系管理员！");
        });
    else message.error("无法进行删除，请稍后重试或联系管理员！");
  };

  const columns: ColumnsType<ChecklistExceptType> = [
    {
      title: "编号",
      dataIndex: "id",
      width: 64,
      render: (_, __, index) => index + 1,
    },
    {
      title: "产品线",
      dataIndex: "product_name",
    },
    {
      title: "安装分支",
      dataIndex: "product_version",
    },
    {
      title: "问题内容",
      dataIndex: "problem_content",
    },
    {
      title: "修复方式",
      dataIndex: "repair_mode",
    },
    {
      title: "必须",
      dataIndex: "is_need",
      width: 64,
      render: (_, record) => {
        return record.is_need === 1 ? "是" : "否";
      },
    },
    {
      title: "启用",
      dataIndex: "is_start",
      width: 64,
      render: (_, record) => {
        return record.is_start === 1 ? "是" : "否";
      },
    },
    {
      title: "操作",
      align: "center",
      render: (_, record, index) => (
        <Space key={`oprate_${record.id}`}>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            loading={editSettingVisible && record.id === editSettingInfo?.id}
            onClick={() => {
              setEditSettingInfo(record);
              setEditSettingVisible(true);
            }}
          />
          <Popconfirm
            title={`确认删除编号【${index + 1}】的设置信息？`}
            onConfirm={() => delSetting(record.id)}
            okText="确认"
            cancelText="取消"
            icon={<ExclamationCircleOutlined />}
            style={{ color: "red" }}
          >
            <Button
              danger
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card
        title={title}
        extra={
          <Space>
            <Button
              loading={addSettingVisible}
              icon={<PlusOutlined />}
              onClick={() => setAddSettingVisible(true)}
            >
              新增
            </Button>
            <Button
              loading={loading}
              icon={<RedoOutlined />}
              type={"primary"}
              onClick={reload}
            >
              刷新
            </Button>
          </Space>
        }
      >
        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={setting?.map((i) => ({ ...i, key: i.id })) || []}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
          }}
        />
      </Card>
      <ChecklistExceptSettingAdd
        title={title}
        submitAPI={ctrlSetting.ADD_SETTING}
        visible={addSettingVisible}
        setVisible={setAddSettingVisible}
        reload={reload}
      />
      <ChecklistSurveySettingEdit
        title={title}
        settingInfo={editSettingInfo}
        submitAPI={ctrlSetting.UPDATE_SETTING}
        visible={editSettingVisible}
        setVisible={setEditSettingVisible}
        reload={reload}
      />
    </>
  );
};

export default ChecklistExceptSetting;
