import { Dispatch, SetStateAction } from "react";
import { Collapse, List, Popup, Space } from "antd-mobile";

import { GpuType } from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";
import { AttachmentDiv } from "authenticated-app/mobile/components/AttachmentDiv";
import { isImageFormat } from "authenticated-app/mobile/util";
import { BreakSpan } from "authenticated-app/mobile/components/BreakSpan";

export const MobileGpuInfoDetail = ({
  detailInfo,
  visible,
  setVisible,
}: {
  detailInfo: GpuType | undefined | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Popup
      visible={visible}
      destroyOnClose
      bodyStyle={{ height: "61.8vh", overflowY: "scroll" }}
      onMaskClick={() => setVisible(false)}
      showCloseButton
      onClose={() => setVisible(false)}
    >
      <List
        mode="card"
        header={<p style={{ fontWeight: "bold" }}>显卡信息详情</p>}
      >
        <List.Item
          extra={
            Number.isFinite(detailInfo?.slot_num)
              ? detailInfo?.slot_num
              : "未知"
          }
        >
          卡槽编号
        </List.Item>
        <List.Item extra={detailInfo?.gpu_oem_name || "未知"}>
          显卡品牌
        </List.Item>
        <List.Item extra={detailInfo?.gpu_type_name || "未知"}>
          显卡型号
        </List.Item>
        <List.Item extra={detailInfo?.assets_code || "未知"}>
          资产编码
        </List.Item>
        <List.Item extra={<BreakSpan>{detailInfo?.uuid || "未知"}</BreakSpan>}>
          UUID
        </List.Item>
        <List.Item extra={detailInfo?.change_date || "未知"}>
          更换时间
        </List.Item>
        <List.Item
          extra={detailInfo?.products.map((i) => i.product_id).join("、") || ""}
        >
          运行产品线
        </List.Item>
        <Collapse
          defaultActiveKey={
            detailInfo?.remark ? detailInfo?.id.toString() : undefined
          }
          accordion
        >
          <Collapse.Panel
            key={detailInfo?.id.toString() || "gpuDetailRemark"}
            title="备注"
          >
            {detailInfo?.remark || "无"}
          </Collapse.Panel>
        </Collapse>
        <Collapse
          defaultActiveKey={
            detailInfo?.asset_photos.length
              ? detailInfo?.id.toString()
              : undefined
          }
          accordion
        >
          <Collapse.Panel
            key={detailInfo?.id.toString() || "gpuDetailAsset"}
            title="资产照片"
          >
            {detailInfo?.asset_photos.length ? (
              <Space direction="vertical">
                {detailInfo?.asset_photos?.map((item, index) => (
                  <AttachmentDiv
                    key={index}
                    isImage={isImageFormat(item.attachment_name)}
                    attachment={item}
                  />
                ))}
              </Space>
            ) : (
              "无"
            )}
          </Collapse.Panel>
        </Collapse>
      </List>
    </Popup>
  );
};
