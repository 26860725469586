import { useState, Fragment } from "react";
import { Button, Descriptions, Space, message, Popconfirm } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ProTable, ProColumns } from "@ant-design/pro-components";
import { useHttp } from "utils/http";
import { DEL_NETWORK } from "lib/api";
import { NetworkType } from "../interface";
import { NetworkInfoAdd } from "./add";
import { NetworkInfoEdit } from "./edit";

const NetworkInfo = ({
  networkInfo,
  hardware_id,
  reload,
}: {
  networkInfo: NetworkType[] | undefined | null;
  hardware_id: string | null;
  reload: () => void;
}) => {
  const [addNetworkVisible, setAddNetworkVisible] = useState<boolean>(false);
  const [editNetworkVisible, setEditNetworkVisible] = useState<boolean>(false);
  const [editNetworkItem, setEditNetworkItem] = useState<NetworkType>();

  const client = useHttp();

  const delNetwork = (id: number) => {
    client(DEL_NETWORK(id), { method: "DELETE" })
      .then(() => {
        message.success("删除成功！");
        reload();
      })
      .catch((e) => {
        console.error(e);
        message.error("删除失败，请联系管理员！");
      });
  };

  const columns: ProColumns<NetworkType>[] = [
    {
      title: "IP",
      dataIndex: "ip",
    },
    {
      title: "网关",
      dataIndex: "gateway",
    },
    {
      title: "掩码",
      dataIndex: "mask",
    },
    {
      title: "DNS",
      dataIndex: "dns",
      search: false,
    },
    {
      title: "备注",
      dataIndex: "remark",
      search: false,
    },
    {
      title: "操作",
      valueType: "option",
      width: 120,
      render: (text, item, _, action) => [
        <Space>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            loading={editNetworkVisible && item.id === editNetworkItem?.id}
            onClick={() => {
              setEditNetworkItem(item);
              setEditNetworkVisible(true);
            }}
          />
          <Popconfirm
            title={`确认删除网络【${item.ip}】的信息？`}
            onConfirm={() => delNetwork(item.id)}
            okText="确认"
            cancelText="取消"
            icon={<ExclamationCircleOutlined />}
            style={{ color: "red" }}
          >
            <Button
              danger
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>,
      ],
    },
  ];

  const NetworkDescItem = (item: NetworkType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");

    return (
      <Fragment key={`networkDescItem${key}`}>
        <Descriptions.Item label={title("IP")} className={className()}>
          {item?.ip || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("网关")} className={className()}>
          {item?.gateway || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("掩码")} className={className()}>
          {item?.mask || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("DNS")} className={className()}>
          {item?.dns || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("备注")} className={className()}>
          {item?.remark || ""}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("操作")}
          className={className()}
        >
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              loading={editNetworkVisible && item.id === editNetworkItem?.id}
              onClick={() => {
                setEditNetworkItem(item);
                setEditNetworkVisible(true);
              }}
            />
            <Popconfirm
              title={`确认删除网络【${item.ip}】的信息？`}
              onConfirm={() => delNetwork(item.id)}
              okText="确认"
              cancelText="取消"
              icon={<ExclamationCircleOutlined />}
              style={{ color: "red" }}
            >
              <Button
                danger
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <ProTable<NetworkType>
        size="small"
        columns={columns}
        rowKey="id"
        dataSource={networkInfo || []}
        pagination={false}
        search={false}
        options={{
          reload: reload,
        }}
        toolbar={{
          title: <span style={{ fontWeight: "bold" }}>网络信息</span>,
          actions: [
            <Button
              key="add"
              type="default"
              icon={<PlusOutlined />}
              onClick={() => setAddNetworkVisible(true)}
            >
              新增
            </Button>,
          ],
          settings: [],
        }}
        bordered={networkInfo?.length ? true : false}
      />
      {/* 
      <Descriptions
        column={6}
        bordered={networkInfo?.length ? true : false}
        title="网络信息"
        layout="vertical"
        extra={
          <Button
            icon={<PlusOutlined />}
            loading={addNetworkVisible}
            onClick={() => setAddNetworkVisible(true)}
          >
            新增
          </Button>
        }
      >
        {networkInfo?.map((item, index) => NetworkDescItem(item, index))}
      </Descriptions> */}
      <NetworkInfoAdd
        orgID={hardware_id}
        visible={addNetworkVisible}
        setVisible={setAddNetworkVisible}
        reload={reload}
      />
      <NetworkInfoEdit
        editingItem={editNetworkItem}
        visible={editNetworkVisible}
        setVisible={setEditNetworkVisible}
        reload={reload}
      />
    </Fragment>
  );
};

export default NetworkInfo;
