import { MutableRefObject, useEffect, useState } from "react";
import { ActionType } from "@ant-design/pro-components";
import { Form, Input, Modal, Select, Spin, message } from "antd";
import { useDebounceFn } from "ahooks";
import { useHttp } from "utils/http";
import { useAuth } from "context/auth";
import { useAsync } from "utils/hooks/useAsync";
import { useDeliverylistAdd } from "authenticated-app/hooks/deliverylist/useDeliverylistAdd";
import { HospSelectFilter } from "utils";
import { CascaderProduct } from "../components/CascaderProduct";
import "./index.css";
import "authenticated-app/pc/components/EditQuill/quill.snow.css";

import { HospListType } from "interface";
import {
  DELIVERY_ORDER_TYPE,
  DELIVERY_USER_TYPE,
  HospProductsListType,
  ProductType,
} from "lib/interface/launchChangeList";
import { DockingType } from "authenticated-app/pc/customer/customer-detail/tab-pane/dockingTabPane/interface";
import {
  GET_DELIVERY_ORDER_TYPE,
  GET_DELIVERY_USER_LIST,
  ADD_DELIVERY_ORDER,
} from "lib/api/deliverylist";
import {
  GET_PRODUCT_LIST,
  HOSP_LIST_API,
  GET_PRODUCT_DETAIL,
  GET_USED_PRODUCT_DETAIL,
  GET_DOCKING_TYPE,
  IdType,
} from "lib/api";
import { DELIVERY_UPGRADE_MODULE_NAME, DELIVERY_PRODUCT_TYPE } from "utils";

import TextArea from "antd/es/input/TextArea";

const { Item } = Form;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

export const AddModal = ({
  actionRef,
}: {
  actionRef: MutableRefObject<ActionType | undefined>;
}) => {
  const [isOpen, , close] = useDeliverylistAdd();
  const [form] = Form.useForm();
  const [selectedHospId, setSelectedHospId] = useState<number>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<number>();
  const [isCustomization, setIsCustomization] = useState<0 | 1>(1);
  const [selectedBackHostType, setSelectedBackHostType] = useState<string>();
  const client = useHttp();
  const { user } = useAuth();

  const {
    run: getHosp,
    data: hospList,
    isIdle: getHospIsIdle,
    isLoading: gettingHospList,
  } = useAsync<HospListType[]>();
  const getHospFunc = (params?: { [key in any]: any }) => {
    getHosp(
      client(HOSP_LIST_API, {
        data: {
          ...params,
        },
      }),
    );
  };
  const { run: getHospDebounce } = useDebounceFn(getHospFunc, { wait: 200 });
  const { run: AddBreaklist, isLoading: AddingBreaklist } = useAsync();

  const {
    run: getDeliveryTypeList,
    data: deliveryTypeList,
    isIdle: getDeliveryTypeListIsIdle,
    isLoading: gettingDeliveryTypeList,
  } = useAsync<DELIVERY_ORDER_TYPE[]>();
  const getDeliveryTypeListHandle = () => {
    getDeliveryTypeList(client(GET_DELIVERY_ORDER_TYPE));
  };
  const { run: getDeliveryTypeListDebounce } = useDebounceFn(
    getDeliveryTypeListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getAllProductList,
    isIdle: getAllProductListIsIdle,
    isLoading: gettingAllProductList,
    data: allProductList,
  } = useAsync<ProductType[]>();
  const getAllProductListHandle = () => {
    getAllProductList(client(GET_PRODUCT_LIST));
  };
  const { run: getAllProductListHasVersionDebounce } = useDebounceFn(
    getAllProductListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getDockingTypeList,
    isLoading: gettingDockingTypeList,
    data: dockingTypeList,
  } = useAsync<DockingType[]>();
  const getDockingTypeListFunc = () => {
    getDockingTypeList(client(GET_DOCKING_TYPE));
  };

  const {
    run: getDeliveryUserList,
    data: deliveryUserList,
    isIdle: getDeliveryUserListIdle,
    isLoading: gettingDeliveryUserList,
  } = useAsync<DELIVERY_USER_TYPE[]>();
  const getDeliveryUserListHandle = () => {
    getDeliveryUserList(client(GET_DELIVERY_USER_LIST));
  };
  const { run: getDeliveryUserListDebounce } = useDebounceFn(
    getDeliveryUserListHandle,
    {
      wait: 200,
    },
  );

  const onSubmit = (values: any) => {
    let product_description_value: string = "";
    let abort_product_value: string = "";
    if (values.product_id_list) {
      if (typeof values.product_id_list === "string") {
        product_description_value = values.product_id_list;
      } else {
        switch (selectedDeliveryType) {
          case 5:
            product_description_value = values.product_id_list.join("|");
            break;
          case 6:
            abort_product_value = values.product_id_list.join("|");
            break;
          default:
            product_description_value = values.product_id_list
              .map((item: any) => {
                const product = item[0];
                const product_info = allProductList?.filter((i: any) => {
                  if (i.product_id || i.title === product) {
                    return (
                      i.chinese_name ||
                      i.product_chinese_name ||
                      i.prod ||
                      i.product_id ||
                      i.title
                    );
                  }
                });
                if (product_info) {
                  item[0] =
                    product_info[0].chinese_name ||
                    product_info[0].product_chinese_name ||
                    product_info[0].title;
                }
                return item.join(" ");
              })
              .join("|");
            break;
        }
      }
    }
    delete values.product_id_list;
    AddBreaklist(
      client(ADD_DELIVERY_ORDER, {
        method: "POST",
        data: {
          ...values,
          ...(product_description_value
            ? { product_description: product_description_value }
            : {}),
          ...(abort_product_value !== ""
            ? { abort_product: abort_product_value }
            : {}),
        },
      }),
    )
      .then((v) => {
        close();
        const content = `上线变更工单[${v.order_id || ""}]提交成功! `;
        message.success(content, 5, undefined);
        actionRef.current?.reload();
      })
      .catch((error) => {
        close();
        message.error("上线变更工单创建失败!", 3, undefined);
      });
  };

  const {
    run: getHosProductList,
    isIdle: getHosProductListIsIdle,
    isLoading: gettingHosProductList,
    data: hosProductList,
  } = useAsync<HospProductsListType[]>();
  const getHosProductListHandle = (hospital_id: IdType) => {
    getHosProductList(client(GET_USED_PRODUCT_DETAIL(hospital_id)));
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldValue("delivery_type_id", selectedDeliveryType);
    form.setFieldsValue({ delivery_user_id: user?.id });
    setSelectedHospId(undefined);
  }, [selectedDeliveryType]);

  useEffect(() => {
    if (selectedHospId) {
      if (selectedDeliveryType === 5 || selectedDeliveryType === 6) {
        // form.setFieldsValue({ abort_product: [] });
        getHosProductListHandle(selectedHospId);
      }
    } else {
      form.setFieldsValue({ hospital_id: undefined });
    }
  }, [selectedHospId]);

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      setSelectedHospId(undefined);
      setSelectedDeliveryType(1);
      form.setFieldsValue({ delivery_user_id: user?.id });
      getHospDebounce();
      getDeliveryTypeListDebounce();
      getDockingTypeListFunc();
      getDeliveryUserListDebounce();
      getAllProductListHasVersionDebounce();
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const child_approval_number = () => {
    switch (selectedDeliveryType) {
      case 4:
      case 5:
      case 6:
        return (
          <>
            <Item name="approval_number" label="审批单号" tooltip={"审批单号"}>
              <Input.TextArea placeholder="填写审批单号" />
            </Item>
          </>
        );
    }
  };
  const childinfo = () => {
    switch (selectedDeliveryType) {
      case 1:
      case 2:
      case 3:
        return (
          <>
            <Item
              name="product_id_list"
              label="交付产品及版本"
              rules={[{ required: true, message: "必须选择交付产品及版本" }]}
            >
              <CascaderProduct
                hospitalID={selectedHospId}
                showDefaultValue={false}
                filterHospital={true}
                form={form}
                onlyUsed={false}
              />
            </Item>
            <Item
              name="is_customization"
              label="产品定制化"
              rules={[{ required: true, message: "必须选择定制化情况" }]}
            >
              <Select placeholder="选择定制化情况">
                {[
                  { name: "是", value: 1 },
                  { name: "否", value: 0 },
                ].map((i, index) => (
                  <Option key={index} value={i.value}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              name="bill_of_materials"
              label="物料清单"
              tooltip={
                "物料清单内容从流程的订单信息中获取,填写时建议以物料名称*数量组合, 多个物料以间隔即可.例如：P5820 * 2, A4000*3 "
              }
              rules={[{ required: true, message: "必须填写物料清单内容" }]}
            >
              <TextArea
                autoSize={{ minRows: 2 }}
                placeholder="填写物料清单内容"
              />
            </Item>
            <Item
              name="tracking_number"
              label="物流单号"
              tooltip={"从流程中获取物流单号, 如SF1234567890"}
            >
              <Input.TextArea placeholder="填写物流单号" />
            </Item>
            <Item name="contact" label="收件人及联系方式">
              <Input.TextArea
                showCount
                maxLength={300}
                placeholder="填写收件人及联系方式"
              />
            </Item>
            <Item
              name="docking_type"
              label="对接方式"
              rules={[{ required: true, message: "必须选择对接方式" }]}
              // normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingDockingTypeList}
                placeholder="选择对接方式"
              >
                {dockingTypeList?.map((item, index) => (
                  <Option key={index} value={item?.name}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Item>
          </>
        );
      case 4: // 产品升级
        return (
          <>
            <Item
              name="product_id_list"
              label="升级产品及版本"
              rules={[{ required: true, message: "必须选择升级产品及版本" }]}
            >
              <CascaderProduct
                hospitalID={selectedHospId}
                showDefaultValue={false}
                filterHospital={true}
                form={form}
                onlyUsed={true}
              />
            </Item>
            <Item
              name="product_type"
              label="产品线类型"
              rules={[{ required: true, message: "必须选择产品线类型" }]}
            >
              <Select placeholder="选择产品线类型">
                {DELIVERY_PRODUCT_TYPE.map((value, index) => (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              name="is_customization_version"
              label="是否定制版本"
              rules={[{ required: true, message: "必须选择是否定制版本" }]}
            >
              <Select
                placeholder="选择是否定制版本"
                onChange={(v) => {
                  setIsCustomization(v);
                }}
              >
                {[
                  { name: "是", value: 1 },
                  { name: "否", value: 0 },
                ].map((i, index) => (
                  <Option key={index} value={i.value}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Item>
            {isCustomization ? (
              <>
                <Item
                  name="customization_version_content"
                  label="定制版本内容	"
                  rules={[{ required: true, message: "必须填写定制版本内容" }]}
                >
                  <TextArea
                    placeholder="填写定制版本内容"
                    autoSize={{ minRows: 2 }}
                  />
                </Item>
              </>
            ) : (
              <></>
            )}
          </>
        );
      case 5: // 产品停用
        return (
          <>
            <Item
              name="product_id_list"
              label="停用产品线"
              rules={[{ required: true, message: "必须选择停用产品线" }]}
            >
              <Select
                disabled={!!!selectedHospId}
                placeholder={
                  !!!selectedHospId ? "请先选择客户" : "选择停用产品线"
                }
                loading={gettingHosProductList || getHosProductListIsIdle}
                dropdownRender={(originNode) => (
                  <Spin
                    spinning={gettingHosProductList || getHosProductListIsIdle}
                  >
                    {originNode}
                  </Spin>
                )}
                mode="multiple"
              >
                {hosProductList?.map((i, index) => (
                  <Option
                    key={index}
                    value={`${i.product_chinese_name} ${i.product_version_name}`}
                  >
                    {i.product_chinese_name} {i.product_version_name}
                  </Option>
                ))}
              </Select>
            </Item>
          </>
        );
      case 6:
        return (
          <>
            <Item
              name="abort_type"
              label="撤机类型"
              rules={[{ required: true, message: "必须选择撤机类型" }]}
            >
              <Select
                placeholder="选择选择撤机类型"
                onChange={(value, options) => {
                  setSelectedBackHostType(value);
                  form.setFieldValue("product_id_list", []);
                }}
              >
                {[
                  {
                    id: 1,
                    name: "部分撤机",
                  },
                  {
                    id: 2,
                    name: "全部撤机",
                  },
                ].map((item, index) => (
                  <Option key={index} value={item?.name}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              name="product_id_list"
              label="撤机产品线"
              rules={[
                {
                  required: selectedBackHostType === "部分撤机" ? true : false,
                  message: "部分撤机时必须选择撤机产品线",
                },
              ]}
            >
              <Select
                disabled={!!!selectedHospId}
                placeholder={
                  !!!selectedHospId ? "请先选择客户" : "选择撤机产品线"
                }
                loading={gettingHosProductList || getHosProductListIsIdle}
                dropdownRender={(originNode) => (
                  <Spin
                    spinning={gettingHosProductList || getHosProductListIsIdle}
                  >
                    {originNode}
                  </Spin>
                )}
                mode="multiple"
              >
                {hosProductList?.map((i, index) => (
                  <Option
                    key={index}
                    value={`${i.product_chinese_name} ${i.product_version_name}`}
                  >
                    {i.product_chinese_name} {i.product_version_name}
                  </Option>
                ))}
              </Select>
            </Item>
          </>
        );
      case 7:
        return (
          <>
            <Item
              name="product_id_list"
              label="涉及产品及版本"
              rules={[{ required: true, message: "必须选择涉及产品及版本" }]}
            >
              <CascaderProduct
                hospitalID={selectedHospId}
                showDefaultValue={false}
                filterHospital={true}
                form={form}
                onlyUsed={true}
              />
            </Item>
          </>
        );
      case 8:
        return (
          <>
            <Item
              name="module_name"
              label="升级组件名称"
              rules={[{ required: true, message: "必须选择升级组件" }]}
            >
              <Select
                placeholder="选择升级组件"
                onChange={() => {
                  form.setFieldsValue({ app_version: "" });
                }}
              >
                {DELIVERY_UPGRADE_MODULE_NAME?.map((value, index) => (
                  <Option key={index} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              name="module_version"
              label="升级组件版本"
              rules={[{ required: true, message: "必须填写组件版本" }]}
            >
              <Input placeholder="填写组件版本" />
            </Item>
          </>
        );
      default:
        return <></>;
    }
  };

  const loading = getHospIsIdle || gettingHospList;

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="新建上线变更工单"
      centered
      className="breaklist-add-modal"
      onCancel={close}
      onOk={form.submit}
      confirmLoading={AddingBreaklist}
      width={"912px"}
      style={{ minWidth: "912px" }}
    >
      <Form form={form} {...formItemLayout} onFinish={onSubmit}>
        <Item name="order_type_id" label="工单类型" initialValue={4}>
          <Select options={[{ value: 4, label: "上线变更" }]} disabled>
            {" "}
          </Select>
        </Item>
        <Item
          name="delivery_type_id"
          label="交付类型"
          rules={[{ required: true, message: "必须选择交付类型" }]}
        >
          <Select
            loading={gettingDeliveryTypeList || getDeliveryTypeListIsIdle}
            placeholder="选择交付类型"
            dropdownRender={(originNode) => (
              <Spin
                spinning={gettingDeliveryTypeList || getDeliveryTypeListIsIdle}
              >
                {originNode}
              </Spin>
            )}
            onChange={(delivery_type_id) => {
              setSelectedDeliveryType(delivery_type_id);
            }}
          >
            {deliveryTypeList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="hospital_id"
          label="客户名称"
          rules={[{ required: true, message: "必须指定客户名称" }]}
        >
          <Select
            loading={loading}
            placeholder="选择客户名称"
            showSearch
            filterOption={HospSelectFilter}
            onChange={(id) => {
              setSelectedHospId(id);
            }}
            dropdownRender={(originNode) => (
              <Spin spinning={loading}>{originNode}</Spin>
            )}
            options={hospList?.map((i) => ({ label: i.name, value: i.id }))}
          />
        </Item>
        <Item
          name="description"
          label="详细描述"
          rules={[{ required: true, message: "必须填写详细描述" }]}
        >
          <TextArea autoSize={{ minRows: 3 }} placeholder="填写详细描述" />
        </Item>
        {child_approval_number()}
        <Item
          name="is_urgent_delivery"
          label="是否紧急交付"
          rules={[{ required: true, message: "必须选择是否为紧急交付" }]}
          initialValue={0}
        >
          <Select placeholder="选择是否为紧急交付">
            {[
              { name: "是", value: 1 },
              { name: "否", value: 0 },
            ].map((i, index) => (
              <Option key={index} value={i.value}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="delivery_user_id"
          label="交付负责人"
          rules={[{ required: true, message: "必须指定交付负责人" }]}
        >
          <Select
            showSearch
            loading={getDeliveryUserListIdle || gettingDeliveryUserList}
            placeholder="选择交付负责人"
            filterOption={HospSelectFilter}
            options={deliveryUserList?.map((i) => ({
              label: `${i.userName} (${i.jobTitle})`,
              value: i.id,
            }))}
          ></Select>
        </Item>
        {childinfo()}
      </Form>
      <Modal
        centered
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        width={"61.8%"}
      >
        <div style={{ width: "100%" }}>
          <img alt="previwe" style={{ width: "100%" }} src={previewImage} />
        </div>
      </Modal>
    </Modal>
  );
};
