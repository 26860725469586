import { Dispatch, SetStateAction } from "react";

import { Collapse, List, Popup } from "antd-mobile";
import { NetworkType } from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";

export const MobileNetworkInfoDetail = ({
  detailInfo,
  visible,
  setVisible,
}: {
  detailInfo: NetworkType | undefined | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Popup
      visible={visible}
      destroyOnClose
      bodyStyle={{ height: "61.8vh", overflowY: "scroll" }}
      onMaskClick={() => setVisible(false)}
      showCloseButton
      onClose={() => setVisible(false)}
    >
      <List
        mode="card"
        header={<p style={{ fontWeight: "bold" }}>网络信息详情</p>}
      >
        <List.Item extra={detailInfo?.ip || "未知"}>IP</List.Item>
        <List.Item extra={detailInfo?.gateway || "未知"}>网关</List.Item>
        <List.Item extra={detailInfo?.mask || "未知"}>掩码</List.Item>
        <List.Item extra={detailInfo?.dns || "未知"}>DNS</List.Item>
        <Collapse
          defaultActiveKey={
            detailInfo?.remark ? detailInfo?.id.toString() : undefined
          }
          accordion
        >
          <Collapse.Panel
            key={detailInfo?.id.toString() || "networkDetail"}
            title="备注"
          >
            {detailInfo?.remark || "无"}
          </Collapse.Panel>
        </Collapse>
      </List>
    </Popup>
  );
};
