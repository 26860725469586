import { useState, useRef, useEffect } from "react";
import { DatePicker, Form } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

import { FormatDayjs, FormatTime, FormatDate } from "utils";
import dayjs, { Dayjs } from "dayjs";

import "./index.css";

export const UpdateDateSelect = ({
  onUpdate,
  value,
  label,
  name,
  required,
  isCanChange,
  showTime = true,
  ishistory,
}: {
  onUpdate: (value: any) => void;
  value?: string;
  label: string;
  name: string;
  required?: boolean;
  isCanChange?: boolean;
  showTime?: boolean;
  ishistory?: boolean; // 控制仅仅可以选择当天及历史日期
}) => {
  const [form] = Form.useForm();

  const [isEditting, setIsEditting] = useState(false);
  const [isFoucs, setIsFoucs] = useState(false);

  const ref = useRef<any>(null);

  const customDisabledDate = (current: Dayjs | null) => {
    if (ishistory) {
      return current ? current.isAfter(dayjs().endOf("day")) : false;
    }
    return false;
  };

  const onFinish = (value: any) => {
    if (showTime) {
      onUpdate({ [name]: FormatTime(value[name]) });
    } else {
      onUpdate({ [name]: FormatDate(value[name]) });
    }
    ref.current?.blur();
  };

  useEffect(() => {
    form.setFieldsValue({
      [name]: value ? FormatDayjs(value) : "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Form
      form={form}
      className="update-date-form"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label={label}
        name={name}
        rules={[{ required: required, message: `必须选择${label}` }]}
        // style={{ width: "100%" }}
      >
        {isCanChange ? (
          <DatePicker
            showTime={showTime}
            allowClear={false}
            ref={ref}
            style={{
              // width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            placeholder={`选择${label}`}
            disabledDate={customDisabledDate}
            bordered={isEditting}
            suffixIcon={isEditting ? <CalendarOutlined /> : null}
            onChange={form.submit}
            onFocus={() => setIsFoucs(true)}
            onBlur={() => {
              setIsFoucs(false);
              setIsEditting(false);
            }}
            onMouseEnter={() => setIsEditting(true)}
            onMouseLeave={() => {
              if (isFoucs) return;
              setIsEditting(false);
            }}
          />
        ) : showTime ? (
          FormatTime(value)
        ) : (
          FormatDate(value)
        )}
      </Form.Item>
    </Form>
  );
};
