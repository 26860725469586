import { Dispatch, forwardRef, SetStateAction } from "react";

import { exeChildFunc } from "authenticated-app/pc/customer/customer-detail/interface";

import { BreaklistTable } from "./breakTable";
const BreakTabPane = forwardRef<
  exeChildFunc,
  {
    hospID: number | null;
    setDisChange: Dispatch<SetStateAction<boolean>>;
  }
>(({ hospID, setDisChange }, ref) => {
  return <BreaklistTable hospID={hospID} />;
  // return <>{hospID}</>
});

export default BreakTabPane;
