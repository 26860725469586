import { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Select,
  Radio,
  InputNumber,
  Upload,
  UploadFile,
  Button,
  Spin,
} from "antd";
import { useDebounceFn } from "ahooks";
import { PaperClipOutlined } from "@ant-design/icons";

import {
  // GET_ISSUES_MODULE,
  // GET_ISSUES_REASON,
  GET_ISSUES_TYPE,
  GET_ISSUES_REASON_NEW,
} from "lib/api/issues";
import {
  BREAKLIST_OPTION_TYPE,
  BREAK_DETAIL_TYPE,
} from "lib/interface/breaklist";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { editQuillToolbarOption } from "authenticated-app/pc/components/EditQuill/option";
import { useBreakDetailSolve } from "authenticated-app/hooks/breaklist/trans/useBreakDetailSolve";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { ADD_FILE } from "lib/api";
import { DisposeQuill, handleUpload, limitUpload } from "utils";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-zzone";
import "authenticated-app/pc/components/EditQuill/quill.snow.css";
Quill.register("modules/imageResize", ImageResize);

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

export const BreakDetailSolveModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: number[];
  detailInfo: BREAK_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useBreakDetailSolve();
  const [selectedOrderTypeId, setSelectedOrderTypeId] = useState<number>();
  const [isInit, setIsInit] = useState<boolean>(true);
  const [departureConfirmationfile, setDepartureConfirmationfile] = useState<
    UploadFile[]
  >([]);

  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getIssuesTypeList,
    data: issuesTypeList,
    isLoading: gettingIssuesTypeList,
    isIdle: getIssuesTypeListIdle,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesTypeListHandle = () => {
    getIssuesTypeList(client(GET_ISSUES_TYPE));
  };
  const { run: getIssuesTypeListDebounce } = useDebounceFn(
    getIssuesTypeListHandle,
    {
      wait: 200,
    },
  );

  // const {
  //   run: getIssuesModuleList,
  //   data: issuesModuleList,
  //   isLoading: gettingIssuesModuleList,
  //   isIdle: getIssuesModuleListIdle,
  // } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  // const getIssuesModuleListHandle = () => {
  //   getIssuesModuleList(client(GET_ISSUES_MODULE));
  // };
  // const { run: getIssuesModuleListDebounce } = useDebounceFn(
  //   getIssuesModuleListHandle,
  //   {
  //     wait: 200,
  //   },
  // );

  const {
    run: getIssuesReasonList,
    data: issuesReasonList,
    isLoading: gettingIssuesReasonList,
    isIdle: getIssuesReasonListIsIdle,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesReasonListHandle = () => {
    if (selectedOrderTypeId) {
      if (!!!isInit) {
        form.setFieldValue("issue_reason_id", undefined);
      }

      getIssuesReasonList(
        client(GET_ISSUES_REASON_NEW(selectedOrderTypeId)),
      ).then((v: any) => {
        let setValue = undefined;
        if (isInit) {
          if (detailInfo?.issue_reason_id) {
            const filterData = v.filter(
              (item: any) =>
                item.id === detailInfo.issue_reason_id &&
                item.name === detailInfo.issue_reason_name,
            );
            if (filterData) {
              filterData.length
                ? (setValue = detailInfo.issue_reason_id)
                : (setValue = undefined);
            }
          }
          setIsInit(false);
        }
        form.setFieldValue("issue_reason_id", setValue);
      });
    }
  };
  const { run: getIssuesReasonListDebounce } = useDebounceFn(
    getIssuesReasonListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next,
      service_order_id: value.service_order_id?.length
        ? value.service_order_id[0]?.uid
        : undefined,
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      setIsInit(true);
      let initFileList = detailInfo?.service_order_id
        ? [
            {
              uid: detailInfo?.service_order_id.toString() || "",
              name: detailInfo?.service_order_name || "",
              url: detailInfo?.service_order_addr,
            },
          ]
        : [];
      form.setFieldsValue({
        is_deal_with_on_site: detailInfo?.is_deal_with_on_site,
        response_time: detailInfo?.response_time,
        issue_type_id: detailInfo?.issue_type_id,
        issue_module_id: detailInfo?.issue_module_id,
        // issue_reason_id: detailInfo?.issue_reason_id,
        debug_info: detailInfo?.debug_info_dict.debug_info,
        solution_info: detailInfo?.solution_info_dict.solution_info,
        service_order_id: initFileList,
      });
      setDepartureConfirmationfile(initFileList);
      getIssuesTypeListDebounce();
      if (detailInfo?.issue_type_id) {
        setSelectedOrderTypeId(detailInfo.issue_type_id);
      }
    } else {
      form.resetFields();
      setSelectedOrderTypeId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  useEffect(() => {
    if (selectedOrderTypeId) {
      getIssuesReasonListDebounce();
    }
  }, [selectedOrderTypeId]);

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="解决工单"
      onCancel={close}
      onOk={form.submit}
      width={"848px"}
      style={{ minWidth: "848px", maxHeight: "80vh", overflow: "auto" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        <Form.Item
          name="is_deal_with_on_site"
          label="是否现场处理"
          rules={[{ required: true, message: "必须选择是否现场处理" }]}
        >
          <Radio.Group style={{ marginLeft: "12px" }}>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="response_time"
          label="响应时长"
          // rules={[{ required: true, message: "必须填写响应时长" }]}
        >
          <InputNumber
            precision={0}
            addonAfter={"min"}
            controls
            min={0}
            placeholder="填写响应时长"
          />
        </Form.Item>
        <Form.Item
          name="issue_type_id"
          label="反馈类型"
          rules={[{ required: true, message: "必须选择反馈类型" }]}
        >
          <Select
            placeholder="选择反馈类型"
            loading={getIssuesTypeListIdle || gettingIssuesTypeList}
            dropdownRender={(originNode) => (
              <Spin spinning={getIssuesTypeListIdle || gettingIssuesTypeList}>
                {originNode}
              </Spin>
            )}
            options={issuesTypeList?.map((i) => ({
              label: i.name,
              value: i.id,
            }))}
            onChange={(id) => {
              setSelectedOrderTypeId(id);
            }}
          />
        </Form.Item>
        <Form.Item
          name="issue_reason_id"
          label="反馈归因"
          rules={[{ required: true, message: "必须选择反馈归因" }]}
        >
          <Select
            placeholder={
              !!!selectedOrderTypeId ? "请先选择反馈类型" : "选择反馈归因"
            }
            loading={
              getIssuesReasonListIsIdle ||
              gettingIssuesReasonList ||
              getIssuesTypeListIdle ||
              gettingIssuesTypeList
            }
            disabled={!!!selectedOrderTypeId}
            dropdownRender={(originNode) => (
              <Spin
                spinning={
                  getIssuesReasonListIsIdle ||
                  gettingIssuesReasonList ||
                  getIssuesTypeListIdle ||
                  gettingIssuesTypeList
                }
              >
                {originNode}
              </Spin>
            )}
            options={issuesReasonList?.map((i) => ({
              label: i.name,
              value: i.id,
            }))}
          />
        </Form.Item>
        {/* </Spin> */}
        <Form.Item
          name="debug_info"
          label="定位及补充记录"
          rules={[{ required: true, message: "必须填写定位及补充记录" }]}
          normalize={(v) => DisposeQuill(v)}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
              imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
              },
            }}
          />
        </Form.Item>
        <Form.Item
          name="solution_info"
          label="操作及解决"
          rules={[{ required: true, message: "必须填写定位及补充记录" }]}
          normalize={(v) => DisposeQuill(v)}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
              imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
              },
            }}
          />
        </Form.Item>
        <Form.Item
          name="service_order_id"
          label="服务确认单"
          // rules={[{ required: true, message: "必须上传服务确认单" }]}
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e?.fileList;
          }}
        >
          <Upload
            headers={UploadHeaders}
            action={ADD_FILE}
            maxCount={1}
            fileList={departureConfirmationfile}
            beforeUpload={limitUpload}
            onChange={(info) =>
              handleUpload(info, setDepartureConfirmationfile)
            }
          >
            {departureConfirmationfile.length ? null : (
              <Button icon={<PaperClipOutlined />}>点击上传</Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};
