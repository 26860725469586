import { SetStateAction, Dispatch, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  Input,
  message,
  Select,
  Divider,
  Skeleton,
} from "antd";

import { useHttp } from "utils/http";
import { DisposeForm, selectFilter } from "utils";
import {
  PackageType,
  PermissionResourcesType,
  PermissionType,
} from "../interface";
import PermissionResources from "./permission-resources";
import { useAsync } from "utils/hooks/useAsync";
import { GET_PACKAGE } from "../api";

const { Option } = Select;

export const PermissionListSettingEdit = ({
  CTRL_UPDATE,
  edittinItem,
  packageList,
  visible,
  setVisible,
  reload,
}: {
  CTRL_UPDATE: (org_id: number) => string;
  edittinItem: PackageType | undefined;
  packageList: PackageType[] | undefined | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [editResourceList, setEditResourceList] = useState<
    PermissionResourcesType[]
  >([]);

  const {
    run: getPackageDetail,
    isIdle: idlePackageDetail,
    isLoading: gettingPackageDetail,
  } = useAsync<PermissionType>();
  const getPackageDetailHandle = (id: number) => {
    getPackageDetail(client(GET_PACKAGE(id))).then((res: PermissionType) => {
      form.setFieldsValue({
        ...res,
      });
      let tmpEditResourceList = res?.resources?.map((value, index) => ({
        ...value,
        key: index,
        resource: `${value?.packageId}_${value?.resourceName}`, // 我也不知道为什么要这样
        resourceId: value?.packageId,
        domains: value?.domainNames?.map(
          (v, i) => `${value?.domainIds[i]}_${v}`,
        ),
      }));
      setEditResourceList(tmpEditResourceList);
    });
  };

  const init = () => {
    getPackageDetailHandle(edittinItem?.packageId!);
  };

  const submit = (value: any) => {
    if (edittinItem?.packageId) {
      client(CTRL_UPDATE(0), {
        data: {
          ...value,
          packageId: edittinItem?.packageId,
          packageType: 1, // TODO 目前暂只有1
          resources: editResourceList,
        },
        method: "PUT",
      })
        .then(() => {
          message.success("更新成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("更新失败，请检查或联系管理员！");
        });
    } else {
      console.error(edittinItem);
      message.error("暂时无法提交更新，请刷新重试或者联系管理员！");
    }
  };

  useEffect(() => {
    if (visible) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={`编辑权限`}
      maskClosable={false}
      destroyOnClose
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width="61.8%"
    >
      <Skeleton loading={idlePackageDetail || gettingPackageDetail}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={submit}
        >
          <Form.Item
            name="packageName"
            label={"名称"}
            rules={[{ required: true, message: `必须输入权限名称` }]}
            normalize={(v) => DisposeForm(v)}
          >
            <Input placeholder="请输入权限名称" maxLength={50} />
          </Form.Item>
          <Form.Item
            name="parentPackageIds"
            label={"引用权限"}
            normalize={(v) => DisposeForm(v)}
          >
            <Select
              mode="multiple"
              showSearch
              filterOption={selectFilter}
              maxTagCount={5}
            >
              {packageList?.map((item) => (
                <Option key={item.packageId} value={item.packageId}>
                  {item.packageName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Divider />
          <Form.Item
            name="resources"
            label={"资源列表"}
            normalize={(v) => DisposeForm(v)}
          >
            <PermissionResources
              resources={editResourceList}
              update={setEditResourceList}
            />
          </Form.Item>
          <Divider />
          <Form.Item wrapperCol={{ offset: 17, span: 6 }}>
            <Space>
              <Button htmlType="button" onClick={() => setVisible(false)}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Skeleton>
    </Modal>
  );
};
