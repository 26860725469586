import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useDeliveryDetailScheduled = () => {
  const [{ openDeliveryDetailScheduled }, setopenDeliveryDetailScheduled] =
    useUrlQueryParam(["openDeliveryDetailScheduled"]);

  const open = () =>
    setopenDeliveryDetailScheduled({ openDeliveryDetailScheduled: true });
  const close = () =>
    setopenDeliveryDetailScheduled({
      openDeliveryDetailScheduled: undefined,
    });

  return [openDeliveryDetailScheduled === "true", open, close] as const;
};
