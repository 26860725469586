import { useEffect, useState } from "react";
import { ProCard } from "@ant-design/pro-components";
import { useHttp } from "utils/http";
import { GET_ASSET_LIST } from "../api";
import { AssetlistTable } from "./calculate-value/asset-list";

export const AssetInfo = () => {
  const client = useHttp();
  const [data, setData] = useState<{
    total_num: number;
    dongle_num: number;
    connect_num: number;
    machine_num: number;
    feedback_num: number;
    connect_hospital_num: number;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client(GET_ASSET_LIST);
        setData(response);
      } catch (error) {
        setError("Failed to fetch asset data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [client]);

  return (
    <div style={{ height: "100%", minWidth: 988 }}>
      <ProCard
        gutter={[{ xs: 8, sm: 8, md: 16, lg: 24, xl: 32 }, 16]}
        style={{ marginBlockStart: 8 }}
      >
        <ProCard
          title="总资产数"
          layout="center"
          type="inner"
          size="small"
          bordered
          boxShadow
        >
          <div style={{ fontSize: "2em", fontWeight: "bold" }}>
            {data?.total_num}
          </div>
        </ProCard>
        <ProCard
          title="Dongle资产数"
          layout="center"
          type="inner"
          size="small"
          bordered
          boxShadow
        >
          <div style={{ fontSize: "2em", fontWeight: "bold" }}>
            {data?.dongle_num}
          </div>
        </ProCard>
        <ProCard
          title="可连接资产数"
          layout="center"
          type="inner"
          size="small"
          bordered
          boxShadow
        >
          <div style={{ fontSize: "2em", fontWeight: "bold" }}>
            {data?.connect_num}
          </div>
        </ProCard>
        <ProCard
          title="小机器可连接数"
          layout="center"
          type="inner"
          size="small"
          bordered
          boxShadow
        >
          <div style={{ fontSize: "2em", fontWeight: "bold" }}>
            {data?.machine_num}
          </div>
        </ProCard>
        <ProCard
          title="可连接客户数"
          layout="center"
          type="inner"
          size="small"
          bordered
          boxShadow
        >
          <div style={{ fontSize: "2em", fontWeight: "bold" }}>
            {data?.connect_hospital_num}
          </div>
        </ProCard>
        <ProCard
          title="数据回传客户数"
          layout="center"
          type="inner"
          size="small"
          bordered
          boxShadow
        >
          <div style={{ fontSize: "2em", fontWeight: "bold" }}>
            {data?.feedback_num}
          </div>
        </ProCard>
      </ProCard>
      <div>
        <AssetlistTable />
      </div>
    </div>
  );
};
