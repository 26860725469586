import { useState } from "react";
import {
  Button,
  Dialog,
  Ellipsis,
  List,
  SwipeAction,
  Toast,
} from "antd-mobile";
import {
  RightOutline,
  EditSOutline,
  DeleteOutline,
  ExclamationCircleFill,
  AddSquareOutline,
} from "antd-mobile-icons";

import { ProductUpdateRecordType } from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import { RetainAPI } from "authenticated-app/pc/customer/customer-detail/interface";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { useHttp } from "utils/http";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { useAsync } from "utils/hooks/useAsync";
import {
  ListRightIconSize,
  SwipeActionIconSize,
} from "authenticated-app/mobile/util";
import { DelConfirmContent } from "authenticated-app/mobile/components/DelConfirmContent";
import { MobileProductUpdateRecordAdd } from "./add";
import { MobileProductUpdateRecordDetail } from "./detail";
import { MobileProductUpdateRecordEdit } from "./edit";

export const MobileProductUpdateRecord = ({
  orgID,
  records,
  RetainAPI,
  reload,
}: {
  orgID: string | number | null | undefined;
  records: ProductUpdateRecordType[] | undefined;
  RetainAPI: RetainAPI;
  reload: () => void;
}) => {
  const [detailInfo, setDetailInfo] = useState<ProductUpdateRecordType>();
  const [detailVisible, setDetailVisible] = useState<boolean>(false);

  const [addVisible, setAddVisible] = useState(false);

  const [editInfo, setEditInfo] = useState<ProductUpdateRecordType>();
  const [editVisible, setEditVisible] = useState(false);

  const client = useHttp();

  const { run: del, isLoading: deleting } = useAsync();
  const delHandle = (id: number) => {
    if (id)
      del(
        client(RetainAPI.DEL_RETAIN(id), { method: "DELETE" }).then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="删除成功" />,
          });
          reload();
        }),
      ).catch((e) => {
        console.error(e);
        Toast.show({
          icon: "fail",
          content: <ToastContent content="删除失败" />,
        });
      });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法删除" />,
      });
  };
  return (
    <ContentCard>
      <LoadingMask visible={deleting} />
      <List
        mode="card"
        header={
          <ListHeader
            text="更新记录"
            rightIcon={<AddSquareOutline fontSize={ListRightIconSize} />}
            loading={addVisible}
            onClick={() => {
              setAddVisible(true);
            }}
          />
        }
      >
        {records?.map((r) => (
          <SwipeAction
            key={r.id}
            closeOnAction={false}
            rightActions={[
              {
                key: "edit",
                text: <EditSOutline fontSize={SwipeActionIconSize} />,
                color: "primary",
                onClick: () => {
                  setEditInfo(r);
                  setEditVisible(true);
                },
              },
              {
                key: "delete",
                text: <DeleteOutline fontSize={SwipeActionIconSize} />,
                color: "danger",
                onClick: () => {
                  Dialog.confirm({
                    header: (
                      <ExclamationCircleFill
                        style={{
                          fontSize: 48,
                          color: "var(--adm-color-danger)",
                        }}
                      />
                    ),
                    content: (
                      <DelConfirmContent
                        content={`${r.update_date}的更新记录`}
                      />
                    ),
                    confirmText: (
                      <span style={{ color: "var(--adm-color-danger)" }}>
                        确定
                      </span>
                    ),
                    onConfirm: () => delHandle(r.id),
                  });
                },
              },
            ]}
          >
            <List.Item
              extra={
                r.update_date ? (
                  <Ellipsis direction="end" content={r.update_date} />
                ) : (
                  "未知时间"
                )
              }
              arrow={
                <Button
                  fill="none"
                  style={{ height: "100%", padding: 0 }}
                  loading={
                    detailVisible &&
                    r.id.toString() === detailInfo?.id.toString()
                  }
                >
                  <RightOutline style={{ color: "var(--adm-color-light)" }} />
                </Button>
              }
              onClick={() => {
                setDetailInfo(r);
                setDetailVisible(true);
              }}
            >
              <Ellipsis direction="end" content={r.product_version_name} />
            </List.Item>
          </SwipeAction>
        ))}
      </List>
      <MobileProductUpdateRecordDetail
        detailInfo={detailInfo}
        visible={detailVisible}
        setVisible={setDetailVisible}
      />
      <MobileProductUpdateRecordAdd
        orgID={orgID}
        ADD_RETAIN={RetainAPI.ADD_RETAIN}
        visible={addVisible}
        setVisible={setAddVisible}
        reload={reload}
      />
      <MobileProductUpdateRecordEdit
        editingItem={editInfo}
        UPDATE_RETAIN={RetainAPI.UPDATE_RETAIN}
        visible={editVisible}
        setVisible={setEditVisible}
        reload={reload}
      />
    </ContentCard>
  );
};
