import { useEffect } from "react";
import { Button, Card, Divider, Result, Skeleton, Tabs } from "antd";
import type { TabsProps } from "antd";
import {
  DashboardOutlined,
  UserOutlined,
  TagsOutlined,
  LikeOutlined,
  BulbOutlined,
  DesktopOutlined,
} from "@ant-design/icons";

import {
  ADD_HOS_EQPT,
  ADD_EQPT_TYPE,
  ADD_GPU,
  ADD_PRODUCT_CLIENT,
  ADD_PRODUCT_TYPE,
  ADD_PRODUCT_VERSION,
  ADD_SYSTEM_VERSION,
  ADD_USER_TYPE,
  DEL_HOS_EQPT,
  DEL_EQPT_TYPE,
  DEL_GPU,
  DEL_PRODUCT_CLIENT,
  DEL_PRODUCT_TYPE,
  DEL_PRODUCT_VERSION,
  DEL_SYSTEM_VERSION,
  DEL_USER_TYPE,
  GET_HOS_EQPT,
  GET_EQPT_TYPE,
  GET_PRODUCT_CLIENT,
  GET_PRODUCT_TYPE,
  GET_PRODUCT_VERSION,
  GET_SYSTEM_VERSION,
  GET_USER_TYPE,
  UPDATE_HOS_EQPT,
  UPDATE_EQPT_TYPE,
  UPDATE_GPU,
  UPDATE_PRODUCT_CLIENT,
  UPDATE_PRODUCT_TYPE,
  UPDATE_PRODUCT_VERSION,
  UPDATE_SYSTEM_VERSION,
  UPDATE_USER_TYPE,
  GET_GPU_LIST,
  ADD_EMPLOYEE_TYPE,
  UPDATE_EMPLOYEE_TYPE,
  DEL_EMPLOYEE_TYPE,
  GET_EMPLOYEE_TYPE,
  GET_DEPLOYE_TYPE,
  ADD_DEPLOYE_TYPE,
  UPDATE_DEPLOYE_TYPE,
  DEL_DEPLOYE_TYPE,
  GET_SERVICE_TYPE,
  ADD_SERVICE_TYPE,
  UPDATE_SERVICE_TYPE,
  DEL_SERVICE_TYPE,
  GET_USING_STATUS,
  ADD_USING_STATUS,
  UPDATE_USING_STATUS,
  DEL_USING_STATUS,
  GET_SERVICE_LEVEL,
  ADD_SERVICE_LEVEL,
  UPDATE_SERVICE_LEVEL,
  DEL_SERVICE_LEVEL,
  GET_DEPARTMENT_TYPE,
  ADD_DEPARTMENT_TYPE,
  DEL_DEPARTMENT_TYPE,
  UPDATE_DEPARTMENT_TYPE,
  GET_PLUG_TYPE,
  ADD_PLUG_TYPE,
  UPDATE_PLUG_TYPE,
  DEL_PLUG_TYPE,
  GET_IDENTIFY_WAY,
  ADD_IDENTIFY_WAY,
  UPDATE_IDENTIFY_WAY,
  DEL_IDENTIFY_WAY,
  GET_CPU_TYPE,
  ADD_CPU_TYPE,
  UPDATE_CPU_TYPE,
  DEL_CPU_TYPE,
  GET_CLIENT_TYPE,
  ADD_CLIENT_TYPE,
  UPDATE_CLIENT_TYPE,
  DEL_CLIENT_TYPE,
  GET_USER_CONTACT,
  ADD_USER_CONTACT,
  UPDATE_USER_CONTACT,
  DEL_USER_CONTACT,
} from "./api";
import HardwareSetting from "./hardware-setting";
import GPUSetting from "./gpu-setting";
import ProductSetting from "./product-setting";
import ProductVersionSetting from "./product-versino-setting";
import ProductClientSetting from "./product-client-setting";
import BaseSingleSetting from "./base-single-setting";
import { LoadingDiv } from "components";
import { usePermission } from "context/permission";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

const defaultTab = "device";

export const CustomerSettingPage = () => {
  const { permission } = usePermission();

  const [{ tab }, setTab] = useUrlQueryParam(["tab"]);

  useEffect(() => {
    if (!!!tab) {
      setTab({ tab: defaultTab });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const items: TabsProps["items"] = [
    {
      key: "device",
      label: (
        <span>
          <DashboardOutlined />
          设备类
        </span>
      ),
      children: (
        <LoadingDiv>
          <HardwareSetting
            ctrlSetting={{
              GET_SETTING: GET_EQPT_TYPE,
              ADD_SETTING: ADD_EQPT_TYPE,
              UPDATE_SETTING: UPDATE_EQPT_TYPE,
              DEL_SETTING: DEL_EQPT_TYPE,
            }}
          />
          <Divider />
          <GPUSetting
            ctrlSetting={{
              GET_SETTING: GET_GPU_LIST,
              ADD_SETTING: ADD_GPU,
              UPDATE_SETTING: UPDATE_GPU,
              DEL_SETTING: DEL_GPU,
            }}
          />
          <Divider />
          <BaseSingleSetting
            info={{
              title: "医院设备类型",
              label: "设备类型",
              placeholder: "CT",
            }}
            ctrlSetting={{
              GET_SETTING: GET_HOS_EQPT,
              ADD_SETTING: ADD_HOS_EQPT,
              UPDATE_SETTING: UPDATE_HOS_EQPT,
              DEL_SETTING: DEL_HOS_EQPT,
            }}
          />
        </LoadingDiv>
      ),
    },
    {
      key: "user",
      label: (
        <span>
          <UserOutlined />
          人员类
        </span>
      ),
      children: (
        <LoadingDiv>
          <BaseSingleSetting
            info={{
              title: "客户人员类型",
              label: "人员类型",
              placeholder: "PACS工程师",
            }}
            ctrlSetting={{
              GET_SETTING: GET_USER_TYPE,
              ADD_SETTING: ADD_USER_TYPE,
              UPDATE_SETTING: UPDATE_USER_TYPE,
              DEL_SETTING: DEL_USER_TYPE,
            }}
          />
          <Divider />
          <BaseSingleSetting
            info={{
              title: "客户联系方式",
              label: "联系方式",
              placeholder: "微信",
            }}
            ctrlSetting={{
              GET_SETTING: GET_USER_CONTACT,
              ADD_SETTING: ADD_USER_CONTACT,
              UPDATE_SETTING: UPDATE_USER_CONTACT,
              DEL_SETTING: DEL_USER_CONTACT,
            }}
          />
          <Divider />
          <BaseSingleSetting
            info={{
              title: "内部人员类型",
              label: "人员类型",
              placeholder: "执行",
            }}
            ctrlSetting={{
              GET_SETTING: GET_EMPLOYEE_TYPE,
              ADD_SETTING: ADD_EMPLOYEE_TYPE,
              UPDATE_SETTING: UPDATE_EMPLOYEE_TYPE,
              DEL_SETTING: DEL_EMPLOYEE_TYPE,
            }}
          />
        </LoadingDiv>
      ),
    },
    {
      key: "product",
      label: (
        <span>
          <TagsOutlined />
          产品类
        </span>
      ),
      children: (
        <LoadingDiv>
          <ProductSetting
            ctrlSetting={{
              GET_SETTING: GET_PRODUCT_TYPE,
              ADD_SETTING: ADD_PRODUCT_TYPE,
              UPDATE_SETTING: UPDATE_PRODUCT_TYPE,
              DEL_SETTING: DEL_PRODUCT_TYPE,
            }}
          />
          <Divider />
          <ProductVersionSetting
            ctrlSetting={{
              GET_SETTING: GET_PRODUCT_VERSION,
              ADD_SETTING: ADD_PRODUCT_VERSION,
              UPDATE_SETTING: UPDATE_PRODUCT_VERSION,
              DEL_SETTING: DEL_PRODUCT_VERSION,
            }}
          />
          <Divider />
          <ProductClientSetting
            ctrlSetting={{
              GET_SETTING: GET_PRODUCT_CLIENT,
              ADD_SETTING: ADD_PRODUCT_CLIENT,
              UPDATE_SETTING: UPDATE_PRODUCT_CLIENT,
              DEL_SETTING: DEL_PRODUCT_CLIENT,
            }}
          />
        </LoadingDiv>
      ),
    },
    {
      key: "server",
      label: (
        <span>
          <LikeOutlined />
          服务类
        </span>
      ),
      children: (
        <LoadingDiv>
          <BaseSingleSetting
            info={{
              title: "部署方式",
              label: "部署方式",
              placeholder: "本地化部署",
            }}
            ctrlSetting={{
              GET_SETTING: GET_DEPLOYE_TYPE,
              ADD_SETTING: ADD_DEPLOYE_TYPE,
              UPDATE_SETTING: UPDATE_DEPLOYE_TYPE,
              DEL_SETTING: DEL_DEPLOYE_TYPE,
            }}
          />
          <Divider />
          <BaseSingleSetting
            info={{
              title: "客户使用方式",
              label: "使用方式",
              placeholder: "RIS嵌入",
            }}
            ctrlSetting={{
              GET_SETTING: GET_CLIENT_TYPE,
              ADD_SETTING: ADD_CLIENT_TYPE,
              UPDATE_SETTING: UPDATE_CLIENT_TYPE,
              DEL_SETTING: DEL_CLIENT_TYPE,
            }}
          />
          <Divider />
          <BaseSingleSetting
            info={{
              title: "服务类型",
              label: "服务类型",
              placeholder: "试用",
            }}
            ctrlSetting={{
              GET_SETTING: GET_SERVICE_TYPE,
              ADD_SETTING: ADD_SERVICE_TYPE,
              UPDATE_SETTING: UPDATE_SERVICE_TYPE,
              DEL_SETTING: DEL_SERVICE_TYPE,
            }}
          />
          <Divider />
          <BaseSingleSetting
            info={{
              title: "使用状态",
              label: "使用状态",
              placeholder: "使用中",
            }}
            ctrlSetting={{
              GET_SETTING: GET_USING_STATUS,
              ADD_SETTING: ADD_USING_STATUS,
              UPDATE_SETTING: UPDATE_USING_STATUS,
              DEL_SETTING: DEL_USING_STATUS,
            }}
          />
          <Divider />
          <BaseSingleSetting
            info={{
              title: "服务等级",
              label: "服务等级",
              placeholder: "L0",
            }}
            ctrlSetting={{
              GET_SETTING: GET_SERVICE_LEVEL,
              ADD_SETTING: ADD_SERVICE_LEVEL,
              UPDATE_SETTING: UPDATE_SERVICE_LEVEL,
              DEL_SETTING: DEL_SERVICE_LEVEL,
            }}
          />
          <Divider />
          <BaseSingleSetting
            info={{
              title: "科室类型",
              label: "科室类型",
              placeholder: "放射科",
            }}
            ctrlSetting={{
              GET_SETTING: GET_DEPARTMENT_TYPE,
              ADD_SETTING: ADD_DEPARTMENT_TYPE,
              UPDATE_SETTING: UPDATE_DEPARTMENT_TYPE,
              DEL_SETTING: DEL_DEPARTMENT_TYPE,
            }}
          />
        </LoadingDiv>
      ),
    },
    {
      key: "plug",
      label: (
        <span>
          <BulbOutlined />
          小插件
        </span>
      ),
      children: (
        <LoadingDiv>
          <BaseSingleSetting
            info={{
              title: "小插件版本",
              label: "插件版本",
              placeholder: "6.0.0.1",
            }}
            ctrlSetting={{
              GET_SETTING: GET_PLUG_TYPE,
              ADD_SETTING: ADD_PLUG_TYPE,
              UPDATE_SETTING: UPDATE_PLUG_TYPE,
              DEL_SETTING: DEL_PLUG_TYPE,
            }}
          />
          <Divider />
          <BaseSingleSetting
            info={{
              title: "小插件识别方式",
              label: "识别方式",
              placeholder: "抓取1",
            }}
            ctrlSetting={{
              GET_SETTING: GET_IDENTIFY_WAY,
              ADD_SETTING: ADD_IDENTIFY_WAY,
              UPDATE_SETTING: UPDATE_IDENTIFY_WAY,
              DEL_SETTING: DEL_IDENTIFY_WAY,
            }}
          />
        </LoadingDiv>
      ),
    },
    {
      key: "system",
      label: (
        <span>
          <DesktopOutlined />
          系统类
        </span>
      ),
      children: (
        <LoadingDiv>
          <BaseSingleSetting
            info={{
              title: "系统版本",
              label: "系统版本",
              placeholder: "Ubuntu 18.04",
            }}
            ctrlSetting={{
              GET_SETTING: GET_SYSTEM_VERSION,
              ADD_SETTING: ADD_SYSTEM_VERSION,
              UPDATE_SETTING: UPDATE_SYSTEM_VERSION,
              DEL_SETTING: DEL_SYSTEM_VERSION,
            }}
          />
          <Divider />
          <BaseSingleSetting
            info={{
              title: "CPU版本",
              label: "CPU版本",
              placeholder: "i5",
            }}
            ctrlSetting={{
              GET_SETTING: GET_CPU_TYPE,
              ADD_SETTING: ADD_CPU_TYPE,
              UPDATE_SETTING: UPDATE_CPU_TYPE,
              DEL_SETTING: DEL_CPU_TYPE,
            }}
          />
        </LoadingDiv>
      ),
    },
  ];

  return permission?.hasPermissionEdit === 1 ? (
    <Card
      style={{ height: "100%", minWidth: 988 }}
      title={<span style={{ fontWeight: "bolder" }}>配置页</span>}
    >
      <Tabs
        activeKey={tab}
        tabPosition="left"
        items={items}
        onChange={(v) => setTab({ tab: v })}
      />
    </Card>
  ) : permission?.hasPermissionEdit === 0 ? (
    <Result
      status="403"
      title="403"
      subTitle="抱歉，你无权限访问此页面！"
      extra={
        <Button
          type="primary"
          onClick={() => window.location.replace(window.location.origin)}
        >
          返回主页
        </Button>
      }
    />
  ) : (
    <Skeleton active />
  );
};
