import { useEffect } from "react";
import { Form, Modal, Select, Input, Radio } from "antd";
import { useDebounceFn } from "ahooks";

import { GET_DEPARTMENT_USERS } from "lib/api/issues";
import {
  BREAK_DETAIL_TYPE,
  ORDER_DEPARTMENT_TYPE,
} from "lib/interface/breaklist";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { DisposeQuill, HospSelectFilter } from "utils";
import { useBreakDetailUpgrade } from "authenticated-app/hooks/breaklist/trans/useBreakDetailUpgrade";
import { editQuillToolbarOption } from "authenticated-app/pc/components/EditQuill/option";

import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-zzone";
import "authenticated-app/pc/components/EditQuill/quill.snow.css";

Quill.register("modules/imageResize", ImageResize);

const { TextArea } = Input;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

export const BreakDetailUpgradeModal = ({
  onTrans,
  next,
  department,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: number[];
  department: number[];
  detailInfo: BREAK_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useBreakDetailUpgrade();

  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getDepartmentList,
    data: departmentList,
    isIdle: getDepartmentListIdle,
    isLoading: gettingDepartmentList,
  } = useAsync<ORDER_DEPARTMENT_TYPE[]>();
  const getDepartmentListHandle = () => {
    getDepartmentList(client(GET_DEPARTMENT_USERS(department)));
  };
  const { run: getDepartmentListDebounce } = useDebounceFn(
    getDepartmentListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({ ...value, next_process: next });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        up_user_id: undefined,
        description: detailInfo?.description,
        is_on_site: detailInfo?.is_on_site,
        remote_info: detailInfo?.remote_info,
      });
      getDepartmentListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="升级工单"
      onCancel={close}
      onOk={form.submit}
      width={"848px"}
      style={{ minWidth: "848px", maxHeight: "80vh", overflow: "auto" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        <Form.Item
          name="up_user_id"
          label="升级至"
          rules={[{ required: true, message: "必须指定升级人" }]}
        >
          <Select
            showSearch
            loading={getDepartmentListIdle || gettingDepartmentList}
            placeholder="指定升级人"
            filterOption={HospSelectFilter}
            options={departmentList?.map((i) => ({
              label: `${i.user_name} (${i.user_job})`,
              value: i.user_id,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="反馈详情"
          rules={[{ required: true, message: "必须填写反馈详情" }]}
          normalize={(v) => DisposeQuill(v)}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
              imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
              },
            }}
          />
        </Form.Item>
        <Form.Item
          name="is_on_site"
          label="是否在现场"
          rules={[{ required: true, message: "必须选择是否在现场" }]}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="remote_info"
          label="远程信息"
          rules={[{ required: true, message: "必须填写远程信息" }]}
        >
          <TextArea placeholder="填写远程信息" autoSize={{ minRows: 3 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
