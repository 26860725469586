import { Dispatch, ReactNode, SetStateAction } from "react";
import { Button, Typography, Image, Space, Tooltip, Popover } from "antd";
import { Attachment } from "../interface";
import { isImageFormat } from "authenticated-app/mobile/util";
import { getClickableLink } from "utils";

import "./utils.css";

const { Paragraph, Link } = Typography;

export const DesItemLabel = ({ label }: { label: string }) => (
  <b
    style={{
      color: "#4F4F4F",
      lineHeight: "36px",
      width: "56px",
      textAlign: "right",
    }}
  >
    {label}
  </b>
);

export const HardwareDesItemLabelMin = ({ label }: { label: string }) => (
  <b
    style={{
      color: "#4F4F4F",
      lineHeight: "36px",
      width: "64px",
      textAlign: "right",
    }}
  >
    {label}
  </b>
);

export const HardwareDesItemLabelMax = ({ label }: { label: string }) => (
  <b
    style={{
      color: "#4F4F4F",
      lineHeight: "36px",
      width: "116px",
      textAlign: "right",
    }}
  >
    {label}
  </b>
);

export const DesItemLabel2 = ({ label }: { label: string }) => (
  <b
    style={{
      color: "#4F4F4F",
      lineHeight: "36px",
      width: "28px",
      textAlign: "right",
    }}
  >
    {label}
  </b>
);

export const LineDiv = ({ children }: { children: ReactNode }) => (
  <div
    style={{
      width: "100%",
      height: "36px",
      lineHeight: "36px",
    }}
  >
    {/* <Popover content={children}> */}
    <Paragraph style={{ lineHeight: "36px" }} ellipsis={{ rows: 2 }}>
      {children}
    </Paragraph>
    {/* </Popover> */}
  </div>
);

export const DockingDesItemLabel = ({ label }: { label: string }) => (
  <b
    style={{
      color: "#4F4F4F",
      lineHeight: "36px",
      width: "84px",
      textAlign: "right",
    }}
  >
    {label}
  </b>
);

export const ProductDesItemLabel = ({ label }: { label: string }) => (
  <b
    style={{
      color: "#4F4F4F",
      lineHeight: "36px",
      width: "72px",
      textAlign: "right",
    }}
  >
    {label}
  </b>
);

export const RemoteDesItemLabel = ({ label }: { label: string }) => (
  <b
    style={{
      color: "#4F4F4F",
      lineHeight: "36px",
      width: "72px",
      textAlign: "right",
    }}
  >
    {label}
  </b>
);

export const ClientDesItemLabel = ({ label }: { label: string }) => (
  <b
    style={{
      color: "#4F4F4F",
      lineHeight: "36px",
      width: "70px",
      textAlign: "right",
    }}
  >
    {label}
  </b>
);

export const MaintenanceDesItemLabel = ({ label }: { label: string }) => (
  <b
    style={{
      color: "#4F4F4F",
      lineHeight: "36px",
      width: "72px",
      textAlign: "right",
    }}
  >
    {label}
  </b>
);

// 有bug，预计5.2版本antd解决
export const ImageModel = ({
  imgList,
  visible,
  setVisible,
}: {
  imgList: Attachment[];
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <>
      <Button
        type="link"
        style={{ margin: 0, padding: 0 }}
        disabled={!imgList?.length}
        onClick={() => {
          setVisible(true);
        }}
      >
        点击查看（{imgList?.length || 0}张）
      </Button>
      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{
            maskClosable: false,
            destroyOnClose: true,
            forceRender: true,
            visible: visible,
            onVisibleChange: (vis) => {
              setVisible(vis);
            },
          }}
        >
          {imgList?.map((i, index) => (
            <Image
              key={`${i.id}&${index}`}
              src={i.attachment_addr || `#${index}`}
            />
          ))}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

export const SingleImageList = ({ imgList }: { imgList: Attachment[] }) => (
  <Space wrap>
    {imgList?.map((i, index) => (
      <div
        className="single-image-list"
        key={`${i.id}&${index}`}
        style={{
          height: 84,
          width: 84,
          border: "1px solid #d9d9d9",
          borderRadius: "8px",
          padding: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isImageFormat(i.attachment_name) ? (
          <Image
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
            src={i.attachment_addr}
          />
        ) : (
          <Tooltip title={i.attachment_name}>
            <Link
              href={
                i.attachment_addr
                  ? getClickableLink(i.attachment_addr)
                  : undefined
              }
              download
              target="_blank"
              style={{ width: "100px" }}
              ellipsis
            >
              {i.attachment_name}
            </Link>
          </Tooltip>
        )}
      </div>
    ))}
  </Space>
);
