import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useDeliveryDetailTransfer = () => {
  const [{ openDeliveryDetailTransfer }, setopenDeliveryDetailTransfer] =
    useUrlQueryParam(["openDeliveryDetailTransfer"]);

  const open = () =>
    setopenDeliveryDetailTransfer({ openDeliveryDetailTransfer: true });
  const close = () =>
    setopenDeliveryDetailTransfer({
      openDeliveryDetailTransfer: undefined,
    });

  return [openDeliveryDetailTransfer === "true", open, close] as const;
};
