import { Card, Tooltip, Divider, Row, Col, Statistic } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  CaretUpOutlined,
  CaretDownOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import { ReactNode } from "react";

export const ChartCard = ({
  title,
  tipinfo,
  value,
  monthGrowth,
  weeklyGrowth,
}: {
  title: string;
  tipinfo?: string | ReactNode;
  value: number;
  monthGrowth: number;
  weeklyGrowth: number;
}) => {
  const trend = (value: number) => {
    if (value < 0) {
      return (
        <>
          {value}% <CaretDownOutlined style={{ color: "green" }} />{" "}
        </>
      );
    } else {
      return (
        <>
          {value}% <CaretUpOutlined style={{ color: "red" }} />
        </>
      );
    }
  };
  return (
    <>
      <Card
        title={title}
        bordered={true}
        extra={
          <>
            {tipinfo ? (
              <>
                <Tooltip
                  title={tipinfo}
                  placement="rightTop"
                  overlayInnerStyle={{ whiteSpace: "pre-line" }}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </>
            ) : (
              <></>
            )}
          </>
        }
        size={"small"}
        style={{
          borderRadius: 0,
          margin: "6px",
          minWidth: "200px",
        }}
      >
        <Statistic value={value} style={{ textAlign: "center" }} />
        <Divider style={{ margin: "8px" }} />
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Statistic
              title="月同比"
              value={Math.abs(monthGrowth)}
              precision={2}
              valueStyle={
                monthGrowth < 0
                  ? { color: "#3f8600", fontSize: "12px" }
                  : monthGrowth === 0
                  ? { fontSize: "12px" }
                  : { color: "#cf1322", fontSize: "12px" }
              }
              prefix={
                monthGrowth < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />
              }
              suffix="%"
            />
          </Col>
          <Col span={12} style={{ fontSize: "11px" }}>
            <Statistic
              title="周同比"
              value={Math.abs(weeklyGrowth)}
              precision={2}
              valueStyle={
                weeklyGrowth < 0
                  ? { color: "#3f8600", fontSize: "12px" }
                  : weeklyGrowth === 0
                  ? { fontSize: "12px" }
                  : { color: "#cf1322", fontSize: "12px" }
              }
              prefix={
                weeklyGrowth < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />
              }
              suffix="%"
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};
