export const rommanKey = "customer";

/**
 * 客户信息
 *     -- 客户列表
 *     -- 客户详情
 *     -- 点击率
 * 工单信息
 *     -- 工单
 *     -- 信息回执
 * 统计页
 *     -- 客户信息概览
 *     -- 产品信息概览
 *     -- 维护信息概览
 * 配置页
 *     -- 资源权限配置
 *     -- 售后系统配置
 *     -- 工单系统配置
 *
 */
export const customerKey = "customer"; // 一级 客户信息
export const customerListKey = "list"; // 二级 客户列表
export const customerDetailKey = "detail"; // 二级 客户详情
// export const rateKey = "rate"; // 二级 点击率

export const ticketKey = "ticket"; // 一级 工单信息
export const breakKey = "break"; // 二级 工单
export const launchChangeKey = "launchChange"; // 二级 上线变更
export const receiptKey = "receipt"; // 二级 信息回执

export const operateKey = "operate";
export const visitKey = "visit";
export const rateKey = "rate";

export const statKey = "stat"; // 一级 统计页
export const customerStatKey = "user"; // 二级 客户信息概览
export const productStatKey = "product"; // 二级 产品信息概览
export const maintenanceStatKey = "maintenance"; // 二级 维护信息概览
export const breakInfoStatKey = "break_info"; // 二级  工单信息概览
export const breakProcessStatKey = "break_process"; // 二级  工单处理统计
export const settingKey = "setting"; // 一级 配置页
export const settingPermissionKey = "permission"; // 一级 资源权限配置
export const settingCustomerKey = "aftersales"; // 一级 售后系统配置
export const settingTicketKey = "workorder"; // 一级 工单系统配置

// 一级集合默认展开项
export const rootSubmenuKeys = [customerKey, ticketKey];

export const keyPathMap: { [key: string]: string } = {
  [customerKey]: "客户信息",
  [customerListKey]: "客户列表",
  [customerDetailKey]: "客户详情",

  [ticketKey]: "工单信息",
  [breakKey]: "客户反馈",
  [launchChangeKey]: "上线变更",
  // [receiptKey]: "信息回执",

  [operateKey]: "运营信息",
  [visitKey]: "拜访记录",
  [rateKey]: "使用统计",

  [statKey]: "统计页",
  [customerStatKey]: "客户信息概览",
  [productStatKey]: "产品信息概览",
  [maintenanceStatKey]: "维护信息概览",
  [breakInfoStatKey]: "工单信息总览",
  // [breakProcessStatKey]: "工单处理统计",
  [settingKey]: "配置页",
  [settingPermissionKey]: "资源权限配置",
  [settingCustomerKey]: "售后系统配置",
  [settingTicketKey]: "工单系统配置",
};

// 路由
export const customerListRoute = `/${customerKey}/${customerListKey}`;
export const customerDetailRoute = `/${customerKey}/${customerDetailKey}`;
// export const rateoute = `/${customerKey}/${rateKey}`;

export const breaktRoute = `/${ticketKey}/${breakKey}`;
export const launchChangeRoute = `/${ticketKey}/${launchChangeKey}`;
export const receiptRoute = `/${ticketKey}/${receiptKey}`;

export const rateoute = `/${operateKey}/${rateKey}`;
export const visitRoute = `/${operateKey}/${visitKey}`;

export const customerStatRoute = `/${statKey}/${customerStatKey}`;
export const productStatRoute = `/${statKey}/${productStatKey}`;
export const maintenanceStatRoute = `/${statKey}/${maintenanceStatKey}`;
export const breakInfoStatRoute = `/${statKey}/${breakInfoStatKey}`;
// export const breakProcessStateRoute = `/${statKey}/${breakProcessStatKey}`;

export const permissionSettingRoute = `/${settingKey}/${settingPermissionKey}`;
export const customerSettingRoute = `/${settingKey}/${settingCustomerKey}`;
export const ticketSettingRoute = `/${settingKey}/${settingTicketKey}`;
