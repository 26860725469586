import { useEffect, useState } from "react";
import { theme, message } from "antd";
import dayjs from "dayjs";

import { LOGIN } from "lib/api";
import { LoadingPage } from "components";
import { getRedirectUrl, useMount } from "utils";
import AlertErrorMsg from "utils/alertErrorMsg";
import { setToken } from "context/auth/provider";

import "./index.css";

const now = new Date();
const { useToken } = theme;
const loginId = "login-container";

declare global {
  interface Window {
    DDLogin: any;
    attachEvent: any;
  }
}

// 扫码登录
export const Login = () => {
  const {
    token: { colorBgContainer, colorText },
  } = useToken();

  const { DDLogin } = window;
  const [loading, setLoading] = useState(false);
  const [loginTmpCode, setLoginTmpCode] = useState<string>();

  const handleLogin = (event: MessageEvent) => {
    var origin = event.origin;
    if (
      origin === "https://login.dingtalk.com" &&
      typeof event.data === "string"
    ) {
      //判断是否来自ddLogin扫码事件。
      var loginTmpCode = event.data;
      setLoginTmpCode(loginTmpCode);
    }
  };

  useEffect(() => {
    if (loginTmpCode) {
      setLoading(true);
      fetch(LOGIN, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
        credentials: "same-origin",
        method: "POST",
        cache: "no-cache",
        body: JSON.stringify({
          loginChannel: 5,
          loginName: loginTmpCode,
        }),
      })
        .then(async (response) => {
          let data = await response.json();
          if (data.token) {
            setToken(data.token);
            window.location.replace(
              `${window.location.origin}${getRedirectUrl()}`,
            );
          } else {
            setToken(null);
            message.error("登录出错，请刷新重试");
          }
        })
        .catch((e) => {
          AlertErrorMsg(e);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 5000);
        });
    }
  }, [loginTmpCode]);

  useMount(() => {
    DDLogin({
      id: loginId,
      goto: "https%3A%2F%2Foapi.dingtalk.com%2Fconnect%2Foauth2%2Fsns_authorize%3Fappid%3Ddingoagkwwgek73bvbcdnx%26response_type%3Dcode%26scope%3Dsnsapi_login%26state%3DSTATE%26redirect_uri%3Dhttps%3A%2F%2Ftest1.infervision.com%2Fapi%2Fcallback",
      style: `border:none;background-color:${colorBgContainer};color:${colorText};`,
      width: "300",
      height: "300",
    });

    if (typeof window?.addEventListener != "undefined") {
      window.addEventListener("message", handleLogin, false);
    } else if (typeof window?.attachEvent != "undefined") {
      window.attachEvent("onmessage", handleLogin);
    }
  });

  if (loading) return <LoadingPage />;

  return (
    <div
      className="login-page"
      style={{ backgroundColor: colorBgContainer, color: colorText }}
    >
      <div className="login-content">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "2rem",
          }}
        >
          <img
            alt=""
            width={36}
            src="//sso-oss.oss-cn-beijing.aliyuncs.com/portal/favicon.ico"
          />
          Infervision
        </div>
        <div style={{ textAlign: "center" }}>
          With A.I.,We Improve Human Life
        </div>
        <div id={loginId} style={{ textAlign: "center" }} />
      </div>

      <div className="login-page-footer">
        Infervision ©{dayjs(now).format("YYYY")} Created by SRD & CustDev
      </div>
    </div>
  );
};
