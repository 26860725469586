import { useEffect, RefObject, useState } from "react";
import { useAuth } from "context/auth";
import { useDebounceFn } from "ahooks";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";
import {
  Form,
  Button,
  DatePicker,
  DatePickerRef,
  Picker,
  Divider,
  Radio,
  TextArea,
} from "antd-mobile";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";

import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { DisposeForm, FormatDayjs, FormatDate, FormatTime } from "utils";
import {
  DELIVERY_DETAIL_TYPE,
  DELIVERY_USER_TYPE,
  NEXT_PROCESS_TYPE,
} from "lib/interface/launchChangeList";

import { GET_DELIVERY_USER_LIST } from "lib/api/deliverylist";
import { useDeliveryDetailScheduled } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailScheduled";

import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { RadioValue } from "antd-mobile/es/components/radio";

export const DeliveryDetailScheduledModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailScheduled();
  const [isHistoryCustomization, setIsHistoryCustomization] = useState<
    RadioValue | number
  >(0);
  const [form] = Form.useForm();
  const { user } = useAuth();
  const client = useHttp();
  const {
    run: getDeliveryUserList,
    data: deliveryUserList,
    isIdle: getDeliveryUserListIdle,
    isLoading: gettingDeliveryUserList,
  } = useAsync<DELIVERY_USER_TYPE[]>();
  const getDeliveryUserListHandle = () => {
    getDeliveryUserList(client(GET_DELIVERY_USER_LIST));
  };
  const { run: getDeliveryUserListDebounce } = useDebounceFn(
    getDeliveryUserListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      delivery_user_id: value.delivery_user_id[0],
      planned_delivery_date: FormatDate(value.planned_delivery_date),
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      const delivery_user_id = detailInfo?.delivery_user_id
        ? detailInfo?.delivery_user_id
        : user?.id;
      form.setFieldsValue({
        delivery_user_id: [delivery_user_id],
        planned_delivery_date: detailInfo?.planned_delivery_date
          ? FormatDayjs(detailInfo?.planned_delivery_date).toDate()
          : null,
      });
      getDeliveryUserListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  const child_info = () => {
    switch (detailInfo?.delivery_type_name) {
      case "销售交付":
      case "试用交付":
      case "渠道交付":
        return (
          <>
            <Divider>信息提示</Divider>
            <div style={{ fontSize: "14px", marginBottom: "10px" }}>
              <span style={{ color: "gray" }}>本次交付产品:</span>
              <span style={{ marginLeft: "8px", color: "black" }}>
                {detailInfo?.product_description?.split("|").join("、")}
              </span>
            </div>
            <div style={{ fontSize: "14px", marginBottom: "10px" }}>
              <span style={{ color: "gray" }}>当前医院已上线产品:</span>
              <span style={{ marginLeft: "8px", color: "black" }}>
                {detailInfo?.hospital_info?.product_info?.length ? (
                  <>
                    {detailInfo?.hospital_info?.product_info
                      ?.map(
                        (i) =>
                          `${i?.product_chinese_name} ${
                            i?.product_version_name
                              ? i.product_version_name
                              : ""
                          } `,
                      )
                      .join("、")}
                  </>
                ) : (
                  <>无</>
                )}
              </span>
            </div>
          </>
        );
      case "产品升级":
      case "硬件部署":
      case "组件升级":
        return (
          <>
            <Form.Item
              name="is_history_customization_version"
              label="是否存在历史定制化 "
              rules={[
                { required: true, message: "必须选择是否存在历史定制化" },
              ]}
            >
              <Radio.Group
                onChange={(v) => {
                  setIsHistoryCustomization(v);
                }}
              >
                <Radio
                  value={1}
                  style={{
                    marginLeft: "4px",
                    "--icon-size": "18px",
                    "--font-size": "16px",
                    "--gap": "6px",
                  }}
                >
                  是
                </Radio>
                <Radio
                  value={0}
                  style={{
                    marginLeft: "8px",
                    "--icon-size": "18px",
                    "--font-size": "16px",
                    "--gap": "6px",
                  }}
                >
                  否
                </Radio>
              </Radio.Group>
            </Form.Item>
          </>
        );
    }
  };
  return (
    <EditPopup visible={isOpen} submitting={false} setVisible={close}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={false}
          >
            提交
          </Button>
        }
      >
        <Form.Header>开始排期</Form.Header>
        <Form.Item
          name="delivery_user_id"
          label="交付负责人"
          rules={[{ required: true, message: "必须指定交付负责人" }]}
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={getDeliveryUserListIdle || gettingDeliveryUserList}
            columns={
              deliveryUserList
                ? [
                    deliveryUserList.map((i) => ({
                      label: `${i.userName} (${i.jobTitle})`,
                      value: i.id,
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>指定交付负责人</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="planned_delivery_date"
          label="计划交付日期"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择计划交付日期" }]}
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
          layout="horizontal"
        >
          <DatePicker title="计划交付日期" precision="day" tillNow>
            {(value) =>
              value ? (
                <FormItemDiv>{FormatDate(value)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>计划交付日期</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>
        {child_info()}
        {isHistoryCustomization ? (
          <Form.Item
            name="history_customization_version_content"
            label="历史定制化版本内容"
            rules={[
              {
                required: isHistoryCustomization ? true : false,
                message: "必须填写历史定制化版本内容",
              },
            ]}
          >
            <TextArea
              placeholder="请填写历史定制化内容"
              autoSize={{ minRows: 2 }}
            />
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </EditPopup>
  );
};
