import { ComponentProps, FC, useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { Comment } from "@ant-design/compatible";
import { Card, Avatar } from "antd-mobile";
import { mobileQuillToolbarOption } from "./option";

import { FormatTime } from "utils";
import "./index.css";
import "./quill.snow.css";
import "./quill.bubble.css";
import { isEdge } from "react-device-detect";

type ReactQuillType = ComponentProps<typeof ReactQuill>;
interface ReactQuillProps extends ReactQuillType {}

export const MobileReactQuill = ({
  // onUpdate,
  name,
  title,
  user,
  avatar,
  info,
  time,
  isCanChange,
}: {
  // onUpdate: (value: any) => void;
  name: string;
  title: string;
  user?: string;
  avatar?: string;
  info: string;
  time?: string;
  isCanChange?: boolean;
}) => {
  const [IsEditting, setIsEditting] = useState(false);
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(info);
  }, [info]);
  return (
    <>
      {isCanChange ? (
        <ReactQuill
          // {...props}
          className="editting-quill"
          theme={"snow"}
          modules={{
            toolbar: mobileQuillToolbarOption,
          }}
          value={value}
          onChange={setValue}
        />
      ) : (
        <Card style={{ maxHeight: "500px", overflowY: "auto" }}>
          <Comment
            className={user ? "user-comment " : "no-user-comment"}
            author={user}
            avatar={avatar ? <Avatar src={avatar} alt={user} /> : undefined}
            content={<ReactQuill readOnly theme={"bubble"} value={value} />}
            datetime={<span>{FormatTime(time)}</span>}
          />
        </Card>
      )}
    </>
  );
};
