import { Fragment } from "react";
import { PullToRefresh, Toast } from "antd-mobile";
import { useDebounceFn } from "ahooks";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { GET_DOCKING_INFO } from "lib/api";
import { useMount } from "utils";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { DockingInfo } from "authenticated-app/pc/customer/customer-detail/tab-pane/dockingTabPane/interface";
import MobileDockingTypeInfo from "./docking-list";
import { ReceiveStatsTable } from "./docking-stats";
import { MobileRemoteRetainInfo } from "../remote-info/retain-info";

export const MobileDockingInfo = ({
  hospName,
  hospCode,
}: {
  hospName: string;
  hospCode: string;
}) => {
  const [{ detailId }] = useUrlQueryParam(["detailId"]);
  const client = useHttp();

  const {
    run: getDockingDetail,
    isLoading,
    data,
    isIdle,
  } = useAsync<DockingInfo>();
  const getDockingDetailHandle = () => {
    if (detailId) {
      getDockingDetail(client(GET_DOCKING_INFO(detailId)));
    } else {
      Toast.show({
        icon: "fail",
        content: <ToastContent content="获取失败" />,
      });
    }
  };
  const { run } = useDebounceFn(getDockingDetailHandle, { wait: 200 });

  useMount(run);

  return (
    <div style={{ width: "100%" }}>
      <LoadingMask visible={isLoading} />
      <PullToRefresh
        onRefresh={async () => {
          getDockingDetailHandle();
        }}
        refreshingText={null}
      >
        <div className="customer-content">
          <Fragment>
            <MobileDockingTypeInfo
              orgID={detailId}
              docking={data?.docking_list}
              reload={getDockingDetailHandle}
            />
            <MobileRemoteRetainInfo
              orgID={detailId}
              retain={data?.retain_infos}
              reload={getDockingDetailHandle}
            />
            <ReceiveStatsTable hospID={detailId} />
          </Fragment>
        </div>
      </PullToRefresh>
    </div>
  );
};
