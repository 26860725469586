import { useEffect, useRef, useState } from "react";
import { useAsync } from "utils/hooks/useAsync";
import { useDebounceFn } from "ahooks";
import {
  Row,
  Col,
  Radio,
  Button,
  Space,
  Spin,
  Tooltip,
  DatePicker,
  Divider,
} from "antd";
import {
  PageHeader,
  FormInstance,
  ActionType,
} from "@ant-design/pro-components";
import { useHttp } from "utils/http";
import { RangeValue, GetDate } from "utils";
import { MyPieChart } from "../../chart/pie-chart";
import { MyPieChartType } from "../../chart/pie-chart";
import { BREAK_FEEDBACK_PIE_DATA_TYPE } from "../../interface";
import { GET_BREAK_FEEDBACKUP_PIE_DATA } from "lib/api";
import { GetBreakFeedbackPieNum } from "../break-info-stat/utils/break-bar";

const { RangePicker } = DatePicker;

export const PieCharts = () => {
  const client = useHttp();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const [tabName, setTabName] = useState<number>(1);
  //   const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [searchAreaID, setSearchAreaID] = useState<number | null>();
  const [searchProcessID, setSearchProcessID] = useState<number | null>();
  const [searchBreakResolvState, setSearchBreakResolvState] = useState<
    string | null
  >();
  const [queryDateRange, setQueryDateRange] = useState<RangeValue>();
  const [lightButton, setLightButton] = useState<string>();
  const [processPieNum, setProcessPieNum] = useState<MyPieChartType>();
  const [areaPieNum, setAreaPieNum] = useState<MyPieChartType>();
  const [breakStatePieNum, setBreakStatePieNum] = useState<MyPieChartType>();
  const [upgradeStatePieNum, setUpgradeStatePieNum] =
    useState<MyPieChartType>();
  const [teamPieNum, setTeamPieNum] = useState<MyPieChartType>();
  const [issueTypePieNum, setIssueTypePieNum] = useState<MyPieChartType>();
  const [feedbackChannelPieNum, setFeedbackChannelPieNum] =
    useState<MyPieChartType>();

  const weekStr = GetDate("week")?.toString();
  const monthStr = GetDate("month")?.toString();
  const yearStr = GetDate("year")?.toString();
  const lastSixMonthStr = GetDate("last_six_month")?.toString();

  const {
    run: getBreakFeedbackPieData,
    isLoading: gettingBreakFeedbackPieData,
    data: BreakFeedbackPieData,
  } = useAsync<BREAK_FEEDBACK_PIE_DATA_TYPE>();
  const getgetBreakFeedbackPieDataHanle = () => {
    if (queryDateRange) {
      const start_date = queryDateRange[0]?.format("YYYY-MM-DD");
      const end_date = queryDateRange[1]?.format("YYYY-MM-DD");
      const params = {
        order_type_id: tabName,
        start_date: start_date,
        end_date: end_date,
      };
      getBreakFeedbackPieData(
        client(GET_BREAK_FEEDBACKUP_PIE_DATA, {
          data: params,
        }),
      );
    }
  };
  const { run: getBreakFeedbackPieDataDebounce } = useDebounceFn(
    getgetBreakFeedbackPieDataHanle,
    {
      wait: 200,
    },
  );

  useEffect(() => {
    if (queryDateRange) {
      getBreakFeedbackPieDataDebounce();
      const queryDateRangeStr = queryDateRange?.toString();
      if (queryDateRangeStr === weekStr) {
        setLightButton("week");
      } else if (queryDateRangeStr === monthStr) {
        setLightButton("month");
      } else if (queryDateRangeStr === yearStr) {
        setLightButton("year");
      } else if (queryDateRangeStr === lastSixMonthStr) {
        setLightButton("last_six_month");
      } else {
        setLightButton("");
      }
    }
  }, [queryDateRange, tabName]);

  useEffect(() => {
    if (BreakFeedbackPieData) {
      setAreaPieNum(GetBreakFeedbackPieNum(BreakFeedbackPieData.area));
      setBreakStatePieNum(GetBreakFeedbackPieNum(BreakFeedbackPieData.state));
      setTeamPieNum(GetBreakFeedbackPieNum(BreakFeedbackPieData.team));
      setUpgradeStatePieNum(
        GetBreakFeedbackPieNum(BreakFeedbackPieData.upgradeState),
      );
      setFeedbackChannelPieNum(
        GetBreakFeedbackPieNum(BreakFeedbackPieData.feedbackChannel),
      );
      setIssueTypePieNum(
        GetBreakFeedbackPieNum(BreakFeedbackPieData.issueType),
      );
    }
  }, [BreakFeedbackPieData]);
  useEffect(() => {
    setQueryDateRange(GetDate("last_six_month"));
  }, []);

  return (
    <>
      <Row
        style={{
          minWidth: 988,
        }}
      >
        <Col span={24}>
          <PageHeader>
            <Row>
              <Col span={6}>
                <Space>
                  <Radio.Group
                    onChange={(e) => {
                      setTabName(e.target.value);
                    }}
                    defaultValue={tabName}
                    buttonStyle="solid"
                  >
                    <Radio.Button value={1}>使用故障</Radio.Button>
                    <Radio.Button value={2}>使用需求</Radio.Button>
                    <Radio.Button value={3}>日常服务</Radio.Button>
                  </Radio.Group>
                </Space>
              </Col>
              <Col span={18} style={{ textAlign: "right" }}>
                <Space wrap>
                  <Button
                    size="small"
                    type={lightButton === "week" ? "link" : "text"}
                    style={{ padding: "1px" }}
                    onClick={() => {
                      setQueryDateRange(GetDate("week"));
                    }}
                  >
                    本周
                  </Button>
                  <Tooltip title="工单创建时间 = 本月1日到本月末.">
                    <Button
                      size="small"
                      type={lightButton === "month" ? "link" : "text"}
                      style={{ padding: "1px" }}
                      onClick={() => {
                        setQueryDateRange(GetDate("month"));
                      }}
                    >
                      本月
                    </Button>
                  </Tooltip>
                  <Tooltip title="工单创建时间 = 本年1月1日到本年12月31日.">
                    <Button
                      size="small"
                      type={lightButton === "year" ? "link" : "text"}
                      style={{ padding: "1px" }}
                      onClick={() => {
                        setQueryDateRange(GetDate("year"));
                      }}
                    >
                      本年
                    </Button>
                  </Tooltip>
                  <Button
                    size="small"
                    style={{ padding: "1px" }}
                    type={lightButton === "last_six_month" ? "link" : "text"}
                    onClick={() => {
                      setQueryDateRange(GetDate("last_six_month"));
                    }}
                  >
                    最近半年
                  </Button>
                  <RangePicker
                    style={{
                      minWidth: "250px",
                    }}
                    allowClear={false}
                    onChange={(val) => {
                      setQueryDateRange(val);
                    }}
                    value={queryDateRange}
                    picker="date"
                  />
                </Space>
              </Col>
            </Row>
          </PageHeader>
        </Col>
      </Row>
      <Divider style={{ marginTop: "4px", marginBottom: "4px" }} />
      <Row style={{ height: "300px", width: "100%" }}>
        <Col
          span={8}
          style={{
            borderRight: "1px solid #ddd",
            borderLeft: "1px solid #ddd",
          }}
        >
          <Spin spinning={gettingBreakFeedbackPieData}>
            <Row style={{ maxHeight: "20px", width: "100%" }}>
              <span
                style={{
                  marginLeft: "6px",
                  textAlign: "center",

                  fontSize: "14px",
                }}
              >
                客户区域
              </span>
            </Row>
            <Row style={{ minHeight: "280px", width: "100%" }}>
              <Col span={24}>
                <MyPieChart Data={areaPieNum} showLegend={false} />
              </Col>
            </Row>
          </Spin>
        </Col>
        <Col span={8} style={{ borderRight: "1px solid #ddd" }}>
          <Spin spinning={gettingBreakFeedbackPieData}>
            <Row style={{ maxHeight: "20px", width: "100%" }}>
              <span
                style={{
                  marginLeft: "6px",
                  textAlign: "center",

                  fontSize: "14px",
                }}
              >
                工单状态
              </span>
            </Row>
            <Row style={{ minHeight: 280, width: "100%" }}>
              <Col span={24}>
                <MyPieChart Data={breakStatePieNum} showLegend={false} />
              </Col>
            </Row>
          </Spin>
        </Col>

        <Col span={8} style={{ borderRight: "1px solid #ddd" }}>
          <Spin spinning={gettingBreakFeedbackPieData}>
            <Row style={{ maxHeight: "20px", width: "100%" }}>
              <span
                style={{
                  marginLeft: "6px",
                  textAlign: "center",

                  fontSize: "14px",
                }}
              >
                升级情况
              </span>
            </Row>
            <Row style={{ minHeight: 280, width: "100%" }}>
              <Col span={24}>
                <MyPieChart Data={upgradeStatePieNum} showLegend={false} />
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
      <Divider
        style={{
          marginTop: "4px",
          marginBottom: "4px",
          border: "0.5px solid",
          color: "#ddd",
        }}
      />
      <Row style={{ height: 300, width: "100%" }}>
        <Col
          span={8}
          style={{
            borderRight: "1px solid #ddd",
            borderLeft: "1px solid #ddd",
          }}
        >
          <Spin spinning={gettingBreakFeedbackPieData}>
            <Row style={{ maxHeight: "20px", width: "100%" }}>
              <span
                style={{
                  marginLeft: "6px",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                团队分布
              </span>
            </Row>
            <Row style={{ minHeight: 280, width: "100%" }}>
              <Col span={24}>
                <MyPieChart Data={teamPieNum} showLegend={false} />
              </Col>
            </Row>
          </Spin>
        </Col>
        <Col span={8} style={{ borderRight: "1px solid #ddd" }}>
          <Spin spinning={gettingBreakFeedbackPieData}>
            <Row style={{ maxHeight: "20px", width: "100%" }}>
              <span
                style={{
                  marginLeft: "6px",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                反馈类型
              </span>
            </Row>
            <Row style={{ minHeight: 280, width: "100%" }}>
              <Col span={24}>
                <MyPieChart Data={issueTypePieNum} showLegend={false} />
              </Col>
            </Row>
          </Spin>
        </Col>
        <Col span={8} style={{ borderRight: "1px solid #ddd" }}>
          <Spin spinning={gettingBreakFeedbackPieData}>
            <Row style={{ maxHeight: "20px", width: "100%" }}>
              <span
                style={{
                  marginLeft: "6px",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                反馈渠道
              </span>
            </Row>
            <Row style={{ minHeight: 280, width: "100%" }}>
              <Col span={24}>
                <MyPieChart Data={feedbackChannelPieNum} showLegend={false} />
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
      <Divider
        style={{
          color: "#ddd",
          marginTop: "4px",
          marginBottom: "4px",
          borderTop: "1px solid ",
        }}
      />
      <Divider
        style={{
          borderTop: "1px dashed grey ",
        }}
      />
    </>
  );
};
