import { MutableRefObject, useEffect, useState } from "react";
import { ActionType } from "@ant-design/pro-components";
import {
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Upload,
  UploadFile,
  message,
} from "antd";
import { FormatDate } from "utils";
import { PlusOutlined } from "@ant-design/icons";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { useCompetitiveAdd } from "authenticated-app/hooks/Competitive/useCompetitiveAdd";
import { ADD_FILE, ADD_COMPETITIVE_PRODUCT } from "lib/api";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { handleUpload, limitUpload } from "utils";
import { handlePreview } from "utils/handlePreview";
import { editQuillToolbarOption } from "authenticated-app/pc/components/EditQuill/option";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-zzone";
Quill.register("modules/imageResize", ImageResize);

const { Item } = Form;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

export const AddCompetitiveModal = ({
  actionRef,
  hospID,
}: {
  actionRef: MutableRefObject<ActionType | undefined>;
  hospID: number | string | null;
}) => {
  const client = useHttp();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [isOpen, , close] = useCompetitiveAdd();
  const [form] = Form.useForm();
  const { run: AddCompetitive, isLoading: AddingCompetitive } = useAsync();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onSubmit = (values: any) => {
    AddCompetitive(
      client(ADD_COMPETITIVE_PRODUCT, {
        method: "POST",
        data: {
          hospital_id: hospID,
          ...values,
          online_date: values?.online_date
            ? FormatDate(values?.online_date)
            : undefined,
          attachment_id_list: fileList.map((i) => i.uid),
        },
      }),
    )
      .then((v) => {
        close();
        const content = `竞品信息提交成功! `;
        message.success(content, 5, undefined);
        actionRef.current?.reload();
      })
      .catch((error) => {
        close();
        message.error("竞品信息提交失败!", 3, undefined);
      });
  };

  useEffect(() => {
    form.resetFields();
    setFileList([]);
  }, [isOpen]);

  return (
    <>
      <Modal
        maskClosable={false}
        open={isOpen}
        title="新建竞品信息"
        centered
        className="breaklist-add-modal"
        onCancel={close}
        onOk={form.submit}
        confirmLoading={AddingCompetitive}
        width={"912px"}
        bodyStyle={{
          minHeight: "12rem",
          maxHeight: "80vh",
          overflow: "auto",
        }}
      >
        <Form form={form} {...formItemLayout} onFinish={onSubmit}>
          <Item
            name="name"
            label="竞品名称"
            rules={[{ required: true, message: "必须填写竞品名称" }]}
          >
            <Input placeholder="填写竞品名称" />
          </Item>
          <Item
            name="product_info"
            label="产品线及版本"
            rules={[{ required: true, message: "必须填写产品线及版本" }]}
          >
            <Input placeholder="填写竞品产品线及版本" />
          </Item>
          <Item
            name="access_address"
            label="访问链接"
            rules={[{ required: false, message: "填写竞品访问链接" }]}
          >
            <Input placeholder="填写竞品访问链接" />
          </Item>
          <Item
            name="user_passwd"
            label="用户名密码"
            rules={[{ required: false, message: "填写竞品访问用户名密码" }]}
          >
            <Input placeholder="填写竞品访问用户名密码" />
          </Item>
          {/* <Item
            name="online_date"
            label="上线时间"
            rules={[{ required: false, message: "填写竞品上线时间" }]}
          >
            <Input placeholder="填写竞品上线时间" />
          </Item> */}
          <Item
            name="online_date"
            label="上线时间"
            // rules={[{ required: true, message: "必须选择反馈时间" }]}
          >
            <DatePicker
              // showTime
              placeholder="请选择竞品上线时间"
              style={{ width: "100%" }}
            />
          </Item>
          <Item
            name="function_info"
            label="竞品功能描述"
            rules={[{ required: false, message: "填写竞品功能描述" }]}
          >
            <ReactQuill
              theme={"snow"}
              modules={{
                toolbar: editQuillToolbarOption,
                imageResize: {
                  parchment: Quill.import("parchment"),
                  modules: ["Resize", "DisplaySize"],
                },
              }}
            />
          </Item>
          <Item
            name="lightspot"
            label="竞品亮点"
            rules={[{ required: false, message: "填写竞品亮点" }]}
          >
            <ReactQuill
              theme={"snow"}
              modules={{
                toolbar: editQuillToolbarOption,
                imageResize: {
                  parchment: Quill.import("parchment"),
                  modules: ["Resize", "DisplaySize"],
                },
              }}
            />
          </Item>
          <Form.Item name="attachment_id_list" label="附件上传">
            <Upload
              headers={UploadHeaders}
              maxCount={20}
              multiple={true}
              action={ADD_FILE}
              listType="picture-card"
              fileList={fileList}
              beforeUpload={limitUpload}
              onChange={(info) => handleUpload(info, setFileList)}
              onPreview={(f) =>
                handlePreview(f, {
                  setPreviewImage,
                  setPreviewOpen,
                  setPreviewTitle,
                })
              }
            >
              {fileList.length >= 20 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>上传(限20个)</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
