import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import {
  Avatar,
  Breadcrumb,
  Dropdown,
  Layout,
  Menu,
  MenuProps,
  Space,
  theme,
} from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { env } from "dingtalk-jsapi";
import dayjs from "dayjs";

import { CustomerList } from "authenticated-app/pc/customer/customer-list";
import { CustomerDetail } from "authenticated-app/pc/customer/customer-detail";
import { BreaklistTable } from "authenticated-app/pc/operate/visit/index";
import { useAuth } from "context/auth";
import { CustomerSettingPage } from "./setting/customer-setting";
import { RateDetail } from "./customer/rate-detail";
import { UserStat } from "./stat/user-stat";
import { ProductStat } from "./stat/product-stat";
import { MaintenancetStat } from "./stat/maintenance-stat";

import {
  breaktRoute,
  launchChangeRoute,
  customerDetailRoute,
  customerListRoute,
  customerSettingRoute,
  customerStatRoute,
  keyPathMap,
  maintenanceStatRoute,
  permissionSettingRoute,
  productStatRoute,
  rateoute,
  receiptRoute,
  rootSubmenuKeys,
  ticketSettingRoute,
  visitRoute,
  breakInfoStatRoute,
} from "./routeSetting";
import { MenuItems } from "./menu";
// import { PcSharedPage } from "./ticket/shared";
import { Checklist } from "./ticket/checklist";
import { HospListProvider } from "context/hosp";
import { BreakList } from "./ticket/breakList";
import { LaunchChangeList } from "./ticket/launchChangeList";
import { TicketSettingPage } from "./setting/ticket-setting";
import { PermissionSettingPage } from "./setting/permission-setting";

import "./index.css";
import logoLarge from "assets/logo/menu-large.png";
import logoSmall from "assets/logo/menu-samll.png";
import { BreakInfo } from "./stat/break-info";

const now = new Date();
const { Header, Sider } = Layout;

export const Home = () => {
  const {
    token: { colorText },
  } = theme.useToken();

  const { logout, user } = useAuth();

  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);

  const [openkeys, setOpenKeys] = useState(rootSubmenuKeys);

  const [menuRoute, setMenuRoute] = useState<{
    openKey: string;
    selectedKey: string;
  }>({ openKey: ``, selectedKey: "" });

  const logoutItems: MenuProps["items"] = [
    {
      key: "logout",
      label: "登出",
      icon: <LogoutOutlined />,
      onClick: logout,
    },
  ];

  useEffect(() => {
    if (location.pathname && location.pathname !== "/") {
      const locationSplit = location.pathname
        .split("/")
        .filter((i) => i !== "");
      setMenuRoute({
        openKey: locationSplit[0],
        selectedKey: locationSplit[1],
      });
      if (!!!openkeys.includes(locationSplit[0])) {
        setOpenKeys([...openkeys, locationSplit[0]]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Layout className="navigation-layout">
      <Sider
        breakpoint="lg"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        collapsedWidth={48}
      >
        <div className="logo-content">
          {collapsed ? (
            <img src={logoSmall} alt="infer-logo" />
          ) : (
            <img src={logoLarge} alt="infer-logo" />
          )}
        </div>
        <Menu
          theme="dark"
          openKeys={openkeys}
          onOpenChange={setOpenKeys}
          selectedKeys={[menuRoute.selectedKey]}
          mode="inline"
          items={MenuItems}
          onClick={({ keyPath }) => {
            setMenuRoute({
              openKey: keyPath[1],
              selectedKey: keyPath[0],
            });
          }}
        />
      </Sider>
      <Layout className="navigation-layout">
        <Header className="navigation-header" style={{ background: "#f5f5f5" }}>
          <Breadcrumb
            style={{
              marginLeft: "12px",
            }}
            items={[
              {
                title: keyPathMap[menuRoute.openKey],
              },
              {
                title: keyPathMap[menuRoute.selectedKey],
              },
            ]}
          />
          {env.platform === "notInDingTalk" ? (
            <Dropdown
              className="navigation-avatar"
              menu={{ items: logoutItems }}
            >
              <Space>
                <Avatar size={42} icon={<UserOutlined />} src={user?.avatar} />
                <div style={{ color: colorText }}>{user?.userName}</div>
              </Space>
            </Dropdown>
          ) : (
            <Space style={{ marginRight: "24px" }}>
              <Avatar size={42} icon={<UserOutlined />} src={user?.avatar} />
              <div style={{ color: colorText }}>{user?.userName}</div>
            </Space>
          )}
        </Header>
        <div className="navigation-content">
          <Routes>
            <Route path={customerListRoute} element={<CustomerList />} />
            <Route path={customerDetailRoute} element={<CustomerDetail />} />

            <Route path={breaktRoute} element={<BreakList />} />
            <Route path={launchChangeRoute} element={<LaunchChangeList />} />
            {/* <Route path={receiptRoute} element={<Checklist />} /> */}
            <Route path={receiptRoute} element={<Checklist />} />

            <Route path={visitRoute} element={<BreaklistTable />} />
            <Route path={rateoute} element={<RateDetail />} />

            <Route path={customerStatRoute} element={<UserStat />} />
            <Route path={productStatRoute} element={<ProductStat />} />
            <Route path={maintenanceStatRoute} element={<MaintenancetStat />} />
            {/* <Route path={breakInfoStatRoute} element={<BreakInfoStats />} /> */}
            <Route path={breakInfoStatRoute} element={<BreakInfo />} />
            {/* <Route
                path={breakProcessStateRoute}
                element={<BreakProcessStats />}
              /> */}

            <Route
              path={permissionSettingRoute}
              element={<PermissionSettingPage />}
            />
            <Route
              path={customerSettingRoute}
              element={<CustomerSettingPage />}
            />
            <Route path={ticketSettingRoute} element={<TicketSettingPage />} />

            {/* <Route path={"/shared/checklist"} element={<PcSharedPage />} /> */}
            <Route
              path="*"
              element={<Navigate to={customerListRoute} replace />}
            />
          </Routes>
          {/* <HospListProvider></HospListProvider> */}
        </div>
        <div className="navigation-footer">
          Infervision ©{dayjs(now).format("YYYY")} Created by SRD & CustDev
        </div>
      </Layout>
    </Layout>
  );
};
