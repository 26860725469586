import { useEffect } from "react";
import { Form, Modal, Select, Input, DatePicker } from "antd";
import { useDebounceFn } from "ahooks";
import { useDeliveryDetailReScheduled } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailReScheduled";
import { useAuth } from "context/auth";
import {
  DELIVERY_USER_TYPE,
  DELIVERY_DETAIL_TYPE,
  DELIVERY_RESCHEDULING_REASON_LIST_TYPE,
  NEXT_PROCESS_TYPE,
} from "lib/interface/launchChangeList";
import {
  GET_DELIVERY_USER_LIST,
  GET_DELIVERY_RESCHEDULING_REASON_TYPE,
} from "lib/api/deliverylist";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { HospSelectFilter, FormatDate, FormatDayjs } from "utils";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const { TextArea } = Input;

export const DeliveryDetailReScheduledModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailReScheduled();
  const [form] = Form.useForm();
  const client = useHttp();
  const { user } = useAuth();
  const {
    run: getDeliveryUserList,
    data: deliveryUserList,
    isIdle: getDeliveryUserListIdle,
    isLoading: gettingDeliveryUserList,
  } = useAsync<DELIVERY_USER_TYPE[]>();
  const getDeliveryUserListHandle = () => {
    getDeliveryUserList(client(GET_DELIVERY_USER_LIST));
  };
  const { run: getDeliveryUserListDebounce } = useDebounceFn(
    getDeliveryUserListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getDeliveryRescheduleReasonList,
    data: deliverRescheduleReasonList,
    isIdle: getDeliveryRescheduleReasonListIdle,
    isLoading: gettingDeliverRescheduleReasonList,
  } = useAsync<DELIVERY_RESCHEDULING_REASON_LIST_TYPE[]>();
  const getDeliveryRescheduleReasonListHandle = () => {
    getDeliveryRescheduleReasonList(
      client(GET_DELIVERY_RESCHEDULING_REASON_TYPE),
    );
  };
  const { run: getDeliveryRescheduleReasonListDebounce } = useDebounceFn(
    getDeliveryRescheduleReasonListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      planned_delivery_date: FormatDate(value.planned_delivery_date),
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      const delivery_user_id = detailInfo?.delivery_user_id
        ? detailInfo?.delivery_user_id
        : user?.id;
      form.resetFields();
      form.setFieldsValue({
        delivery_user_id: delivery_user_id,
        planned_delivery_date: detailInfo?.planned_delivery_date
          ? FormatDayjs(detailInfo?.planned_delivery_date)
          : "",
        rescheduling_reason_type_id: detailInfo?.rescheduling_reason_type_id,
        rescheduling_reason: detailInfo?.rescheduling_reason,
      });
      getDeliveryUserListDebounce();
      getDeliveryRescheduleReasonListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="重新排期"
      onCancel={close}
      onOk={form.submit}
      width={"700px"}
      style={{ minWidth: "600px" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        <Form.Item
          name="delivery_user_id"
          label="交付负责人"
          rules={[{ required: true, message: "必须选择交付负责人" }]}
        >
          <Select
            showSearch
            loading={getDeliveryUserListIdle || gettingDeliveryUserList}
            placeholder="选择交付负责人"
            filterOption={HospSelectFilter}
            options={deliveryUserList?.map((i) => ({
              label: `${i.userName} (${i.jobTitle})`,
              value: i.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="planned_delivery_date"
          label="计划交付日期"
          rules={[{ required: true, message: "必须选择计划交付日期" }]}
        >
          <DatePicker
            style={{ width: "200px" }}
            placeholder="请选择计划交付日期"
          />
        </Form.Item>
        <Form.Item
          name="rescheduling_reason_type_id"
          label="重新排期分类"
          rules={[{ required: true, message: "必须选择重新排期原因分类" }]}
        >
          <Select
            showSearch
            loading={
              getDeliveryRescheduleReasonListIdle ||
              gettingDeliverRescheduleReasonList
            }
            placeholder="选择重新排期原因分类"
            filterOption={HospSelectFilter}
            options={deliverRescheduleReasonList?.map((i) => ({
              label: `${i.name}`,
              value: i.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="rescheduling_reason"
          label="重新排期原因"
          rules={[{ required: true, message: "必须填写重新排期原因" }]}
        >
          <TextArea placeholder="填写重新排期原因" autoSize={{ minRows: 3 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
