import { useEffect, useState, useRef } from "react";
import {
  MyBarChart,
  MyBarChartType,
} from "authenticated-app/pc/stat/chart/bar-chart";

import { MyBarChartTime } from "authenticated-app/pc/stat/chart/bar-chart-time";
import { PredictType } from "../interface";
import { GetPredictNum } from "./utils";
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  MarkLineComponent,
  MarkLineComponentOption,
} from "echarts/components";
import { BarChart, BarSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { message } from "antd";
import { LineChart, LineSeriesOption } from "echarts/charts";

type EChartsOption = echarts.ComposeOption<
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | MarkLineComponentOption
  | BarSeriesOption
>;

export const PredictLineChart = ({
  data,
}: {
  data: PredictType[] | undefined | null;
}) => {
  echarts.use([
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    BarChart,
    CanvasRenderer,
  ]);

  const getOption = (seris: BarSeriesOption[]): EChartsOption => ({
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      type: "scroll",
    },
    grid: {
      left: "0%",
      right: "3%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "time",
    },
    yAxis: {
      type: "value",
    },
    series: seris.length ? seris : [{ data: [], type: "bar" }],
  });

  const timeChartRef = useRef(null);
  let myTimeChart: echarts.ECharts;

  const renderChart = (option: EChartsOption) => {
    try {
      const renderedInstance = echarts.getInstanceByDom(
        timeChartRef.current as unknown as HTMLElement,
      );
      if (renderedInstance) {
        myTimeChart = renderedInstance;
      } else {
        myTimeChart = echarts.init(
          timeChartRef.current as unknown as HTMLElement,
        );
      }
      myTimeChart.setOption(option);
    } catch (error) {
      console.error(error);
      myTimeChart && myTimeChart.dispose();
    }
  };

  const resizeHandler = () => {
    myTimeChart.resize();
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      renderChart(getOption(GetPredictNum(data)));
    }
  }, [data]);

  return (
    <div style={{ height: "450px", width: "100%" }} ref={timeChartRef}></div>
  );
};
