import { useEffect } from "react";
import { Form, Modal, Select, Input, DatePicker } from "antd";
import { useDebounceFn } from "ahooks";
import { useDeliveryDetailTransfer } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailTransfer";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import {
  DELIVERY_DETAIL_TYPE,
  NEXT_PROCESS_TYPE,
} from "lib/interface/launchChangeList";
import { DELIVERY_USER_TYPE } from "lib/interface/launchChangeList";
import { GET_DELIVERY_USER_LIST } from "lib/api/deliverylist";
import { HospSelectFilter } from "utils";
import { FormatDate, FormatDayjs } from "utils";

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const DeliveryDetailTransferModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailTransfer();
  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getDeliveryUserList,
    data: deliveryUserList,
    isIdle: getDeliveryUserListIdle,
    isLoading: gettingDeliveryUserList,
  } = useAsync<DELIVERY_USER_TYPE[]>();
  const getDeliveryUserListHandle = () => {
    getDeliveryUserList(client(GET_DELIVERY_USER_LIST));
  };
  const { run: getDeliveryUserListDebounce } = useDebounceFn(
    getDeliveryUserListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      ...(value.planned_delivery_date
        ? { planned_delivery_date: FormatDate(value.planned_delivery_date) }
        : {}),
      ...(value.planned_return_date
        ? { planned_return_date: FormatDate(value.planned_return_date) }
        : {}),
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      form.setFieldsValue({
        transfer_reason: detailInfo?.transfer_reason,
        ...(detailInfo?.process_name === "硬件退回"
          ? {
              planned_return_date: detailInfo?.planned_return_date
                ? FormatDayjs(detailInfo?.planned_return_date)
                : "",
            }
          : {
              planned_delivery_date: detailInfo?.planned_delivery_date
                ? FormatDayjs(detailInfo?.planned_delivery_date)
                : "",
            }),
      });
      getDeliveryUserListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="转派工单"
      onCancel={close}
      onOk={form.submit}
      width={"700px"}
      style={{ minWidth: "600px" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        <Form.Item
          name="delivery_user_id"
          label="转派至"
          rules={[{ required: true, message: "必须选择转派人" }]}
        >
          <Select
            showSearch
            loading={getDeliveryUserListIdle || gettingDeliveryUserList}
            placeholder="选择交付转派人"
            filterOption={HospSelectFilter}
            options={deliveryUserList?.map((i) => ({
              label: `${i.userName} (${i.jobTitle})`,
              value: i.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="transfer_reason"
          label="转派原因"
          rules={[{ required: true, message: "必须填写转派原因" }]}
        >
          <TextArea placeholder="填写转派原因" autoSize={{ minRows: 3 }} />
        </Form.Item>

        <Form.Item
          name={
            detailInfo?.process_name === "硬件退回"
              ? "planned_return_date"
              : "planned_delivery_date"
          }
          label={
            detailInfo?.process_name === "硬件退回"
              ? "计划退回日期"
              : "计划交付日期"
          }
          rules={
            detailInfo?.process_name === "硬件退回"
              ? [{ required: true, message: "必须选择计划退回日期" }]
              : [{ required: true, message: "必须选择计划交付日期" }]
          }
        >
          <DatePicker
            style={{ width: "200px" }}
            placeholder="请选择计划交付日期"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
