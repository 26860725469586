import { IdType } from "lib/api";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useVisitListDetail = () => {
  const [{ openVisitListDetail }, setopenVisitListDetail] = useUrlQueryParam([
    "openVisitListDetail",
    "detailId",
  ]);

  const open = (id: IdType) =>
    setopenVisitListDetail({ openVisitListDetail: true, detailId: id });
  const close = () =>
    setopenVisitListDetail({
      openVisitListDetail: undefined,
      detailId: undefined,
    });

  return [openVisitListDetail === "true", open, close] as const;
};
