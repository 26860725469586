import { RangePickerProps } from "antd/lib/date-picker";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { RcFile, UploadChangeParam } from "antd/lib/upload/interface";
import { message, UploadFile } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/lib/select";
import { Attachment } from "authenticated-app/pc/customer/customer-detail/interface";
import { createSearchParams } from "react-router-dom";
import { DepartmentType } from "authenticated-app/pc/customer/customer-detail/tab-pane/infoTabPane/interface";
import { HosNodesTree } from "authenticated-app/pc/customer/customer-list/interface";

import dayjs, { Dayjs } from "dayjs";

export type RangeValue = [Dayjs | null, Dayjs | null] | null;

export const DateFormate = "YYYY-MM-DD";
export const DateTimeFormate = "YYYY-MM-DD HH:mm:ss";

export const FormatTime = (date?: Date | null | string) => {
  if (date) {
    return dayjs(date).format(DateTimeFormate);
  }
  return "";
};

export const FormatDate = (date?: Date | null | string) => {
  if (date) {
    return dayjs(date).format(DateFormate);
  }
  return "";
};

export const FormatDayjs = (date?: Date | null | string) => {
  return dayjs(date);
};

export const GetDate = (mode: string): RangeValue => {
  const now = dayjs();
  switch (mode) {
    case "week":
    case "month":
    case "year":
      return [now.startOf(mode), now.endOf(mode)];
    case "last_3_days":
      return [now.subtract(3, "day").startOf("day"), now.endOf("day")];
    case "last_7_days":
      return [now.subtract(7, "day").startOf("day"), now.endOf("day")];
    case "last_30_days":
      return [now.subtract(30, "day").startOf("day"), now.endOf("day")];
    case "last_six_month":
      return [now.subtract(6, "month").startOf("month"), now.endOf("month")];
    case "before_this_week":
      return [null, dayjs().startOf("week")];
    case "before_two_weeks":
      return [null, dayjs().subtract(1, "week").startOf("week")];
    default:
      return [now.startOf("week"), now.endOf("week")];
  }
};

export const isFalsy = (value: unknown): boolean =>
  value === 0 ? false : !value;

export const cleanObject = (object: object) => {
  const result = { ...object };
  Object.keys(result).forEach((key) => {
    // @ts-ignore
    const value = result[key];
    if (isFalsy(value)) {
      // @ts-ignore
      delete result[key];
    }
  });
  return result;
};

export const removeAllParam = (object: object) => {
  const result = { ...object };
  Object.keys(result).forEach((key) => {
    // @ts-ignore
    const value = result[key];
    if (isFalsy(value) || value === "all") {
      // @ts-ignore
      delete result[key];
    }
  });
  return result;
};

export const useMount = (callback: () => void) => {
  useEffect(() => {
    callback();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useDebounce = <V>(value: V, delay?: number) => {
  const [debounceValue, setDebounceValue] = useState(value);

  useEffect(() => {
    // 每次在value变化以后，设置一个定时器
    const timeout = setTimeout(() => setDebounceValue(value), delay);
    // 每次在上一个useEffect处理完以后再运行
    return () => clearTimeout(timeout);
  }, [value, delay]);

  return debounceValue;
};

export const trimStr = (str: string | null | undefined) => {
  if (str === "" || str === null || str === undefined) return null;
  if (typeof str == "string") return str.replace(/(^\s*)|(\s*$)/g, "");
  return str;
};

// export const DisabledDate: RangePickerProps["disabledDate"] = (
//   current: moment.Moment
// ) => {
//   // Can not select days after today
//   return current > moment().endOf("day");
// };

export const DisabledDate: RangePickerProps["disabledDate"] = (
  current: dayjs.Dayjs,
) => {
  // Can not select days after today
  return current > dayjs().endOf("day");
};

// export const formatDate = (date: moment.Moment | undefined) =>
//   date ? moment(date).format(DateFormate) : null;

export const formatDate = (date: dayjs.Dayjs | undefined) =>
  date ? dayjs(date).format(DateFormate) : null;

export const DisposeNullStr = (str: string | undefined | null) => {
  if (str === "" || str === null) return null;
  if (str === undefined) return undefined;
  return trimStr(str);
};

export const DisposeForm = (value: any) => {
  if (value === null || value === undefined) return null;
  if (typeof value === "string") return trimStr(value);
  return value;
};

export const DisposeMobileText = (item: any) => {
  if (typeof item == "string") return trimStr(item);
  if (item === null || item === undefined) return "";
  return item;
};

export const DisposeQuill = (value: any) => {
  return value === "<p><br></p>" ? "" : value;
};

export const initFormValue = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  return Object.entries(obj).reduce(
    (acc: { [key: string]: any }, [key, value]) => {
      acc[key] = trimStr(value);
      return acc;
    },
    {},
  );
};

export const getClickableLink = (link: string) => {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `http://${link}`;
};

export const fileSize = 1024 * 1024 * 500;

export const handleUpload = (
  info: UploadChangeParam<UploadFile>,
  setFileList: Dispatch<SetStateAction<UploadFile<any>[]>>,
) => {
  let newFileList: UploadFile[] = info.fileList.filter((f) => {
    if (f.size) {
      return f.size <= fileSize;
    } else {
      return true;
    }
  });
  newFileList = newFileList.map((file) => {
    if (file.response) {
      file.uid = file.response.id;
    }
    return file;
  });
  setFileList(newFileList);
};

export const handleUploadImage = (
  info: UploadChangeParam<UploadFile>,
  setFileList: Dispatch<SetStateAction<UploadFile<any>[]>>,
) => {
  let newFileList: UploadFile[] = info.fileList.filter((f) => {
    if (f?.type?.startsWith("image/")) {
      if (f.size) {
        return f.size <= fileSize;
      } else {
        return true;
      }
    } else if (f?.type) {
      return false;
    } else {
      return true;
    }
  });
  newFileList = newFileList.map((file) => {
    if (file.response) {
      file.uid = file.response.id;
    }
    return file;
  });
  setFileList(newFileList);
};

export const initFileList = (item: Attachment[] | undefined) => {
  let newFileList: UploadFile[] | undefined = [];
  newFileList = item?.map((file) => {
    let newFile: UploadFile = {
      uid: file.id.toString(),
      name: file.attachment_name,
      url: file.attachment_addr,
      status: "done",
    };
    return newFile;
  });
  return newFileList;
};

export const initEditUploadFileList = (item: Attachment[] | undefined) => {
  let newFileList: UploadFile[] | undefined = [];
  newFileList = item?.map((file) => {
    let newFile: UploadFile = {
      uid: file.attachment_id.toString(),
      name: file.attachment_name,
      url: file.attachment_addr,
      status: "done",
    };
    return newFile;
  });
  return newFileList;
};

export const limitUpload = (file: RcFile) => {
  const isLt500M = file.size <= fileSize;
  if (!isLt500M) {
    message.error(file.name + "文件大小超出500M，无法上传！", 2);
    return false;
  } else {
    return true;
  }
};

export const limitUploadImage = (file: RcFile) => {
  const isLt500M = file.size <= fileSize;
  if (file.type.startsWith("image/")) {
    if (!isLt500M) {
      message.error(file.name + "文件大小超出500M，无法上传！", 2);
      return false;
    } else {
      return true;
    }
  } else {
    message.error(file.name + "不是图片类型！", 2);
    return false;
  }
};

export const selectFilter = (
  input: string,
  option: DefaultOptionType | undefined,
) =>
  (option!.children as unknown as string)
    .toLowerCase()
    .includes(trimStr(input.toLowerCase()) || "");

export const HospSelectFilter = (
  input: string,
  option?: BaseOptionType | undefined,
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const cascaderFilter = (inputValue: string, path: DefaultOptionType[]) =>
  path.some(
    (option) =>
      (option?.departmentName as string)
        ?.toLowerCase()
        .includes(trimStr(inputValue?.toLowerCase()) || ""),
  );

export const devVersion = process.env.REACT_APP_VERSION || "unknow";
export const versionParamas = (params?: { [x in any]: string }) => {
  return createSearchParams({
    ...params,
    version: devVersion || "null",
  }).toString();
};

export const getLinkTo = (route: string) => ({
  pathname: route,
  search: `?version=${devVersion}`,
});

export const isNumber = (num: undefined | null | number) => {
  if (num !== undefined && num !== null && !isNaN(num) && num >= 0) {
    return true;
  } else {
    return false;
  }
};

export const createDeparmentTree = (nodeList: DepartmentType[]) => {
  let result = nodeList;
  // 判断是否为尾节点
  // 判断依据：该节点不为根节点或者该节点存在子节点
  const isNotLastNode = (tempNode: DepartmentType) =>
    !tempNode.parentDepartmentId ||
    result.filter((item) => item.parentDepartmentId === tempNode.id).length > 0;
  // 将尾节点收入到它的父节点中
  const setChildren = (tempNode: DepartmentType) => {
    const parent = tempNode;
    const children = result.filter(
      (item) => !isNotLastNode(item) && item.parentDepartmentId === parent.id,
    );
    if (children.length > 0) {
      parent.children = (parent.children || []).concat(children);
    }
    return parent;
  };
  // 每次循环都会过滤掉尾节点，直到只剩根节点为止
  while (
    result.filter((tempNode: DepartmentType) => tempNode.parentDepartmentId)
      .length > 0
  ) {
    result = result.map(setChildren).filter(isNotLastNode);
  }
  return result;
};

export const findDepartmentArray = (
  tree: DepartmentType[] | null,
  func: (department: DepartmentType) => boolean,
  path: number[] = [],
) => {
  if (!tree) return [];
  for (let data of tree) {
    path.push(data.userDepartmentId);
    if (func(data)) return path;
    if (data.children) {
      const findChildren: number[] = findDepartmentArray(
        data.children,
        func,
        path,
      );
      if (findChildren?.length) return findChildren;
    }
    path.pop();
  }
  return [];
};

export const createNodesTree = (nodeList: HosNodesTree[]) => {
  let result = nodeList.map((i) => ({
    ...i,
    label: i.nodeName,
    value: `${i.nodeLevel}_${i.nodeId}`,
    isLeaf: i.nodeLevel === 2 && i.parentNodeId === 5 ? true : false, // true为海外国家
  }));
  // 判断是否为尾节点
  // 判断依据：该节点不为根节点或者该节点存在子节点
  const isNotLastNode = (tempNode: HosNodesTree) =>
    !(tempNode.parentNodeId !== null) ||
    result.filter(
      (item) =>
        item.parentNodeId === tempNode.nodeId &&
        item.nodeLevel - 1 === tempNode.nodeLevel,
    ).length > 0;

  // // 将尾节点收入到它的父节点中
  const setChildren = (tempNode: HosNodesTree) => {
    const parent = tempNode;
    const children = result.filter(
      (item) =>
        !isNotLastNode(item) &&
        item.parentNodeId === parent.nodeId &&
        item.nodeLevel - 1 === parent.nodeLevel,
    );
    if (children.length > 0) {
      parent.children = (parent.children || []).concat(children);
    }
    return parent;
  };
  // // 每次循环都会过滤掉尾节点，直到只剩根节点为止
  while (
    result.filter(
      (tempNode: HosNodesTree) =>
        tempNode.parentNodeId !== null && tempNode.nodeLevel !== 0,
    ).length > 0
  ) {
    result = result.map(setChildren).filter(isNotLastNode);
  }
  return result;
};

export const findNodesArray = (
  tree: HosNodesTree[] | null,
  func: (node: HosNodesTree) => boolean,
  path: string[] = [],
) => {
  if (!tree) return [];
  for (let data of tree) {
    path.push(`${data.nodeLevel}_${data.nodeId}`);
    if (func(data)) return path;
    if (data.children) {
      const findChildren: string[] = findNodesArray(data.children, func, path);
      if (findChildren?.length) return findChildren;
    }
    path.pop();
  }
  return [];
};

export const findNodes = (
  tree: HosNodesTree[] | null,
  func: (node: HosNodesTree) => boolean,
): HosNodesTree | null => {
  if (!tree) return null;
  for (let data of tree) {
    if (func(data)) return data;
    if (data.children) {
      const findChildren: HosNodesTree | null = findNodes(data.children, func);
      if (findChildren) return findChildren;
    }
  }
  return null;
};

export const MemoryCapacityList = [8, 16, 32, 64, 128];
export const MemoryNumberList = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
];

export const DiskCapacityList = [0, 2.5, 3, 4, 6, 8, 10, 12, 16];
export const DiskNumberList = Array.from({ length: 25 }, (_, i) => i);

export const GpuNumberList = Array.from({ length: 8 }, (_, i) => i);

export const SsdCapacityList = [250, 500, 1000, 2000, 3000];

export enum hosStatus {
  unknown = "未知",
  never = "未上线",
  pending = "待上线",
  new = "新产品上线",
  using = "使用中",
  stop = "已停用",
  out = "已撤出",
}

export const defaultUserStatus = [
  hosStatus.pending,
  hosStatus.using,
  hosStatus.stop,
];

export const statusOptions = [
  { label: hosStatus.never, value: hosStatus.never },
  { label: hosStatus.pending, value: hosStatus.pending },
  // { label: hosStatus.new, value: hosStatus.new },
  { label: hosStatus.using, value: hosStatus.using },
  { label: hosStatus.stop, value: hosStatus.stop },
  { label: hosStatus.out, value: hosStatus.out },
];

export const statusColor = new Map([
  [hosStatus.unknown, "#999999"],
  [hosStatus.never, "#666666"],
  [hosStatus.pending, "#cccccc"],
  // [hosStatus.new, "#0080FF"],
  [hosStatus.using, "#00b578"],
  [hosStatus.stop, "#ff8f1f"],
  [hosStatus.out, "#ff3141"],
]);

export const productIdToName = new Map([
  ["MG_BREAST", "乳腺"],
  ["CT_LUNG", "肺结节"],
  ["BONEAGE", "骨龄"],
  ["DR_TUBERCULOSIS", "肺结核"],
  ["CT_BONE", "CT骨疾病"],
  ["DR_CHEST", "DR胸部"],
  ["CT_STROKE", "脑卒中"],
  ["ICR", "临床科研"],
  ["INFERSCHOLAR", "科研学者平台"],
  ["CT_PNEUMONIA", "肺炎"],
  ["THORACIC_SURGERY", "胸外科"],
  ["TR", "靶重建"],
  ["CT_Cerebral", "头颈"],
  ["Urology_Planning", "泌尿"],
  ["INFER_READ_RESEARCH", "科研支持板块"],
  ["CT_Coronary", "冠脉"],
  ["Liver_Planning", "肝胆"],
  ["unknown", "未知产品线"],
]);

export const THID = "THORACIC_SURGERY";
export const RID = "CT_LUNG";
export const RIDCHINESENAME = "肺结节";
export const CBID = "CT_BONE";
export const CPID = "CT_PNEUMONIA";
export const isR = (p: string | null) => {
  return p === RID || p === CBID || p === CPID;
};
export const isRRateTip = "肺结节、骨折、肺炎为同一点击率";

export const MaintenanceStatusColor = new Map([
  ["未知", "#999999"],
  ["在保", "#00b578"],
  ["过保", "#ff3141"],
]);

export const CT_LUNG_VERSION_TYPE = [
  "标准版",
  "精英版",
  "全能版",
  "MDT版",
  "肺结节版",
  "全肺版",
];

export const DELIVERY_TYPE = [
  {
    id: 1,
    name: "试用交付",
  },
  {
    id: 2,
    name: "销售交付",
  },
  {
    id: 3,
    name: "渠道交付",
  },
  {
    id: 4,
    name: "产品升级",
  },
  {
    id: 5,
    name: "产品停用",
  },
  {
    id: 6,
    name: "产品撤机",
  },
  {
    id: 7,
    name: "硬件部署",
  },
  {
    id: 8,
    name: "组件升级",
  },
];

export const DELIVERY_UPGRADE_MODULE_NAME = [
  "DS",
  "UB",
  "OCR客户端",
  "产品客户端",
  "部署工程端",
  "运维组件",
];

export const DELIVERY_PRODUCT_TYPE = ["销售", "试用", "销售+试用"];

export const valueIsUseful = (value: any) =>
  value !== null && value !== undefined && value !== "";

export const ChecklistStatusColor = new Map([
  [0, "#999999"],
  [1, "#00b578"],
]);

export const getRedirectUrl = () => {
  return decodeURIComponent(
    `${window.location.href
      .replace(window.location.origin, "")
      .replace("/?redirect=", "")}`,
  );
};

export const getBoolValue = (
  value?: number | null | undefined,
  tipInfo?: string,
) => {
  switch (value) {
    case 1:
      return "是";
    case 0:
      return "否";
    case 2:
      return tipInfo;
    default:
      return "";
  }
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
