import { useEffect, RefObject } from "react";
import { useAuth } from "context/auth";
import { useHttp } from "utils/http";

import { Form, Button, DatePicker, DatePickerRef, TextArea } from "antd-mobile";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { DisposeForm, FormatDayjs, FormatDate } from "utils";
import {
  DELIVERY_DETAIL_TYPE,
  NEXT_PROCESS_TYPE,
} from "lib/interface/launchChangeList";

import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { useDeliveryDetailSendback } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailSendback";

export const DeliveryDetailSendbackModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailSendback();
  const [form] = Form.useForm();

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      actual_return_date: FormatDate(value.actual_return_date),
    });
    close();
  };

  useEffect(() => {
    form.resetFields();
    if (isOpen) {
      form.setFieldsValue({
        actual_return_date: detailInfo?.actual_return_date
          ? FormatDayjs(detailInfo?.actual_return_date).toDate()
          : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <EditPopup visible={isOpen} submitting={false} setVisible={close}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={false}
          >
            提交
          </Button>
        }
      >
        <Form.Header>物料退回</Form.Header>
        <Form.Item
          name="return_tracking_number"
          label="退回物流单号"
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
        >
          <TextArea placeholder="填写退回物流单号" />
        </Form.Item>
        <Form.Item
          name="actual_return_date"
          label="实际退回日期"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择实际退回日期" }]}
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
          layout="horizontal"
        >
          <DatePicker title="选择实际退回日期" precision="day" tillNow>
            {(value) =>
              value ? (
                <FormItemDiv>{FormatDate(value)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择实际退回日期</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
