import { Ellipsis, ImageViewer, Image } from "antd-mobile";
import { LinkOutline } from "antd-mobile-icons";
import { Typography } from "antd";

import { Attachment } from "authenticated-app/pc/customer/customer-detail/interface";
import { getClickableLink } from "utils";

const { Link } = Typography;

export const AttachmentDiv = ({
  isImage,
  attachment,
  width = "86vw",
}: {
  isImage: boolean;
  attachment: Attachment;
  width?: string | number;
}) => {
  const attachmentImageSize = 32;

  const attachmentEllipsis = (
    <Ellipsis
      style={{
        flexGrow: 1,
        height: `${attachmentImageSize}px`,
        lineHeight: `${attachmentImageSize}px`,
      }}
      direction="middle"
      content={attachment.attachment_name}
    />
  );

  return (
    <div
      style={{
        display: "flex",
        padding: "6px",
        border: "1px solid lightGrey",
        width: width,
        wordBreak: "break-word",
      }}
      onClick={
        isImage
          ? () => {
              ImageViewer.show({ image: attachment.attachment_addr });
            }
          : undefined
      }
    >
      <Image
        src={attachment.attachment_addr}
        width={attachmentImageSize}
        height={attachmentImageSize}
        fit="contain"
        style={{
          marginRight: "6px",
          border: "1px solid lightGrey",
          borderRadius: 3,
        }}
        fallback={<LinkOutline fontSize={attachmentImageSize} />}
      />
      {isImage ? (
        attachmentEllipsis
      ) : (
        <Link
          target="_self"
          href={
            attachment.attachment_addr
              ? getClickableLink(attachment.attachment_addr)
              : undefined
          }
          download={
            attachment.attachment_name ? attachment.attachment_name : undefined
          }
        >
          {attachmentEllipsis}
        </Link>
      )}
    </div>
  );
};
