import { useEffect } from "react";
import { Ellipsis, List, NavBar, SafeArea } from "antd-mobile";
import { useDebounceFn } from "ahooks";

import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import {
  CHECKLIST_DETAIL_TYPE,
  CHECKLIST_ENUM,
  CHECKLIST_ENUM_MAP,
} from "lib/interface/checklist";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { GET_CHECKLIST_DETAIL } from "lib/api/checklist";

import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { FormatTime } from "utils";
import { GetListType } from "./types";

export const titleHeight = "45px";

export const MobileSharedPage = () => {
  const client = useHttp();

  const [{ id }] = useUrlQueryParam(["id"]);

  const {
    run: getDetailInfo,
    data: detailInfo,
    isIdle,
    isLoading,
  } = useAsync<CHECKLIST_DETAIL_TYPE>();
  const getDetailInfoHandle = (id: string) => {
    if (id) {
      getDetailInfo(client(GET_CHECKLIST_DETAIL(id)));
    }
  };

  const { run } = useDebounceFn(getDetailInfoHandle, { wait: 200 });

  const {
    hospital_name,
    assignee_name,
    check_type,
    create_time,
    submit_time,
    checklist_values,
  } = detailInfo || {};

  useEffect(() => {
    run(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const Loading = isIdle || isLoading;

  return (
    <div className={"mobile-app"}>
      <div className={"mobile-top"}>
        <NavBar
          style={{ lineHeight: titleHeight, "--height": titleHeight }}
          backArrow={false}
        >
          <Ellipsis
            direction="end"
            content={hospital_name || "未知医院"}
            style={{ fontSize: 18, lineHeight: titleHeight }}
          />
        </NavBar>
      </div>
      <div className={"mobile-body"}>
        <div style={{ width: "100%" }}>
          <ContentCard>
            <List
              mode="card"
              header={<ListHeader text={"基本信息"} loading={Loading} />}
            >
              <List.Item
                extra={
                  CHECKLIST_ENUM_MAP.get(check_type as CHECKLIST_ENUM) || "未知"
                }
              >
                类型
              </List.Item>
              <List.Item extra={assignee_name || "未知"}>经办人</List.Item>
              <List.Item extra={FormatTime(create_time) || "未知"}>
                创建时间
              </List.Item>
              <List.Item extra={FormatTime(submit_time) || "未知"}>
                提交时间
              </List.Item>
            </List>
          </ContentCard>
          <ContentCard>
            {checklist_values?.map((item, index) =>
              GetListType({
                key: index,
                detail: item,
                index: index + 1,
                check_type,
                loading: Loading,
              }),
            )}
          </ContentCard>
        </div>
      </div>
      <SafeArea position="bottom" />
    </div>
  );
};
