import { useEffect, RefObject } from "react";
import { Button, Form, Picker, TextArea } from "antd-mobile";
import { useDebounceFn } from "ahooks";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";

import { useBreakDetailTransfer } from "authenticated-app/hooks/breaklist/trans/useBreakDetailTransfer";
import { GET_DEPARTMENT_USERS } from "lib/api/issues";
import {
  BREAK_DETAIL_TYPE,
  ORDER_DEPARTMENT_TYPE,
} from "lib/interface/breaklist";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";

import { DisposeForm } from "utils";

import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";

export const BreakDetailTransferModal = ({
  onTrans,
  next,
  department,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: number[];
  department: number[];
  detailInfo: BREAK_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useBreakDetailTransfer();

  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getDepartmentList,
    data: departmentList,
    isIdle: getDepartmentListIdle,
    isLoading: gettingDepartmentList,
  } = useAsync<ORDER_DEPARTMENT_TYPE[]>();
  const getDepartmentListHandle = () => {
    getDepartmentList(client(GET_DEPARTMENT_USERS(department)));
  };
  const { run: getDepartmentListDebounce } = useDebounceFn(
    getDepartmentListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      up_user_id: value?.up_user_id
        ? parseInt(value?.up_user_id[0])
        : undefined,
      next_process: next,
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        up_user_id: undefined,
        reasons_for_transfer: detailInfo?.reasons_for_transfer,
        remote_info: detailInfo?.remote_info,
      });
      getDepartmentListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);
  return (
    <EditPopup visible={isOpen} submitting={false} setVisible={close}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={false}
          >
            提交
          </Button>
        }
      >
        <Form.Header>转派工单</Form.Header>
        <Form.Item
          name="up_user_id"
          label="转派至"
          rules={[{ required: true, message: "必须指定转派人" }]}
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={getDepartmentListIdle || gettingDepartmentList}
            columns={
              departmentList
                ? [
                    departmentList.map((i) => ({
                      label: `${i.user_name} (${i.user_job})`,
                      value: i.user_id,
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>指定转派人</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>

        <Form.Item
          name="reasons_for_transfer"
          label="转派原因"
          rules={[{ required: true, message: "必须填写转派原因" }]}
        >
          <TextArea placeholder="填写转派原因" autoSize={{ minRows: 3 }} />
        </Form.Item>
        <Form.Item
          name="remote_info"
          label="远程信息"
          rules={[{ required: true, message: "必须填写远程信息" }]}
        >
          <TextArea placeholder="填写远程信息" autoSize={{ minRows: 3 }} />
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
