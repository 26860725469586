import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
} from "react";
import { Divider, Spin } from "antd";
import { useDebounceFn } from "ahooks";

import { exeChildFunc } from "authenticated-app/pc/customer/customer-detail/interface";
import { useHttp } from "utils/http";
import {
  ADD_CLIENT_RETAIN,
  ADD_DOCTOR_PC_INFO,
  DEL_CLIENT_RETAIN,
  DEL_DOCTOR_PC_INFO,
  GET_CLIENT_DETAIL,
  GET_CLIENT_TYPE,
  UPDATE_CLIENT_RETAIN,
  UPDATE_DOCTOR_PC_INFO,
} from "lib/api";
import { ClinetDetailType, ClientType } from "./interface";
import ClientBaseInfo from "./client-base-info";
import UserPCInfo from "./user-pc-info";
import ClientRetainInfo from "./client-retain-info";
import { useAsync } from "utils/hooks/useAsync";
import "../../index.css";
import ClientTypeInfo from "./client-type-info";

const ClientTabPane = forwardRef<
  exeChildFunc,
  {
    hospID: number | null;
    setDisChange: Dispatch<SetStateAction<boolean>>;
  }
>(({ hospID, setDisChange }, ref) => {
  const client = useHttp();

  const { run, isLoading, data: detail } = useAsync<ClinetDetailType>();
  const getDetail = () => {
    if (hospID) {
      run(client(GET_CLIENT_DETAIL(hospID)));
    }
  };
  const { run: getDetailDebounce } = useDebounceFn(getDetail, {
    wait: 200,
  });

  const {
    run: getClientType,
    isLoading: gettingClientType,
    data: clientType,
  } = useAsync<ClientType[]>();
  const getClientTypeFunc = () => {
    if (hospID) {
      getClientType(client(GET_CLIENT_TYPE(hospID)));
    }
  };
  const { run: getClientTypeDebounce } = useDebounceFn(getClientTypeFunc, {
    wait: 200,
  });

  useImperativeHandle(ref, () => ({
    exeFunc() {
      getDetail();
    },
  }));

  useEffect(() => {
    getDetailDebounce();
    getClientTypeDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospID]);

  return (
    <Spin spinning={isLoading || gettingClientType}>
      <ClientTypeInfo
        detail={clientType}
        hospID={hospID}
        reload={getClientTypeFunc}
        setDisChange={setDisChange}
      />
      <Divider style={{ margin: "8px" }} />
      {clientType?.find((i) => i.name === "小插件") ? (
        <>
          <ClientBaseInfo
            detail={detail || undefined}
            reload={getDetail}
            setDisChange={setDisChange}
          />
          <Divider />
        </>
      ) : null}
      <UserPCInfo
        hospID={hospID}
        retain={detail?.doctor_infos}
        RetainAPI={{
          ADD_RETAIN: () => ADD_DOCTOR_PC_INFO,
          UPDATE_RETAIN: UPDATE_DOCTOR_PC_INFO,
          DEL_RETAIN: DEL_DOCTOR_PC_INFO,
        }}
        reload={getDetail}
      />
      <Divider style={{ margin: "8px" }} />
      <ClientRetainInfo
        hospID={hospID}
        retain={detail?.retain_infos}
        RetainAPI={{
          ADD_RETAIN: ADD_CLIENT_RETAIN,
          UPDATE_RETAIN: UPDATE_CLIENT_RETAIN,
          DEL_RETAIN: DEL_CLIENT_RETAIN,
        }}
        reload={getDetail}
      />
    </Spin>
  );
});

export default ClientTabPane;
