import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Form, Input, Space, TextArea, Toast } from "antd-mobile";
import { AddOutline } from "antd-mobile-icons";
import { Upload } from "antd";
import { UploadFile } from "antd/es/upload/interface";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import {
  DisposeForm,
  DisposeMobileText,
  handleUpload,
  initFileList,
} from "utils";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { UrlRegex } from "utils/regexp";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { ADD_FILE } from "lib/api";
import { MobileLimitUpload } from "authenticated-app/mobile/util";
import { RetainInfosType } from "authenticated-app/pc/customer/customer-detail/tab-pane/infoTabPane/interface";

export const MobileRetainInfoEdit = ({
  editingItem,
  UPDATE_RETAIN,
  visible,
  setVisible,
  reload,
}: {
  editingItem: RetainInfosType | undefined;
  UPDATE_RETAIN: (hospID: number) => string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { run: submit, isLoading: submitting } = useAsync();
  const submitHandle = (value: any) => {
    let id = editingItem?.id;
    if (id)
      submit(
        client(UPDATE_RETAIN(id), {
          data: {
            // ...value,
            title: value?.title || null,
            description: value?.description || null,
            wiki_addr: value?.wiki_addr || null,
            remark: value?.remark || null,
            attachment: fileList.map((i) => i.uid),
          },
          method: "PUT",
        }),
      )
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="修改成功" />,
          });
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="修改失败" />,
          });
        });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法提交" />,
      });
  };

  useEffect(() => {
    if (visible) {
      setFileList(initFileList(editingItem?.attachment) || []);
      form.setFieldsValue({
        // ...editingItem,
        title: editingItem?.title || "",
        description: editingItem?.description || "",
        wiki_addr: editingItem?.wiki_addr || "",
        remark: editingItem?.remark || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <EditPopup
      visible={visible}
      submitting={submitting}
      setVisible={setVisible}
    >
      <Form
        form={form}
        onFinish={submitHandle}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={submitting}
          >
            提交
          </Button>
        }
      >
        <Form.Header>编辑必要留存信息</Form.Header>
        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true, message: "必须输入标题" }]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input style={{ "--text-align": "right" }} placeholder="请输入标题" />
        </Form.Item>
        <Form.Item
          name="description"
          label="简要描述"
          help="简要描述信息, 不超过255个字"
          rules={[{ required: true, message: "必须输入简要描述信息" }]}
          normalize={(v) => DisposeMobileText(v)}
          layout={"vertical"}
        >
          <TextArea
            maxLength={255}
            showCount
            autoSize
            placeholder="输入简要描述"
          />
        </Form.Item>
        <Form.Item
          name="wiki_addr"
          label="相关wiki"
          rules={[
            {
              pattern: UrlRegex(),
              message: "必须输入合法的URL格式",
            },
          ]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input
            style={{ "--text-align": "right" }}
            placeholder="输入相关输入wiki地址"
          />
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
          help="额外的补充信息"
          normalize={(v) => DisposeMobileText(v)}
          layout={"vertical"}
        >
          <TextArea autoSize placeholder="输入备注" />
        </Form.Item>
        <Form.Item
          name="attachment"
          className="adm-form-item-label-attachment"
          label="附件 (数量限制3个)"
          normalize={(v) => DisposeForm(v)}
          layout={"vertical"}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={3}
            className="upload-list-inline"
            action={ADD_FILE}
            listType="picture"
            fileList={fileList}
            beforeUpload={MobileLimitUpload}
            onChange={(info) => handleUpload(info, setFileList)}
          >
            {fileList.length >= 3 ? null : (
              <Button color="primary" fill="outline">
                <Space>
                  <AddOutline />
                  <span>点击上传</span>
                </Space>
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
