import { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "antd";
import type { ProColumns } from "@ant-design/pro-components";
import { ActionType, ProTable, FormInstance } from "@ant-design/pro-components";
import { DownloadOutlined } from "@ant-design/icons";
import { useHttp } from "utils/http";
import { trimStr, DateTimeFormate, FormatDate } from "utils";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { GET_ASSET_LIST_INFO } from "authenticated-app/pc/stat/interface";
import { GET_ASSET_LIST } from "authenticated-app/pc/stat/api";
import { useAsync } from "utils/hooks/useAsync";
import { useAuth } from "context/auth";
import { useDownload } from "utils/download";
import dayjs from "dayjs";

const getNode = (params: string): string => {
  const path = params.split("/");
  return path[path.length - 1];
};

export const AssetlistTable = () => {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<FormInstance>();
  const { height } = useWindowSize();

  const { user } = useAuth();
  const client = useHttp();
  const downloadClient = useDownload();

  const [selectedTag, setSelectedTag] = useState<string[]>(["全部资产"]);

  const [searchParams, setSearchParams] = useState<any>();
  const [downloadParams, setDownloadParams] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const { run: getFile, isLoading: gettingFile } = useAsync();
  const getFileHandle = () => {
    getFile(
      downloadClient(GET_ASSET_LIST, {
        data: { is_download: 1, ...downloadParams },
      }),
    );
  };

  const getParams = (params: any) => {
    let queryParams = {
      ...params,
      hostname: params?.asset_name ? trimStr(params?.asset_name) : undefined,
      customer_name: params?.hospital_name
        ? trimStr(params?.hospital_name)
        : undefined,
      connectivity: params?.connectivity
        ? trimStr(params?.connectivity)
        : undefined,
      date_created_start: params?.date_created?.[0]
        ? FormatDate(params?.date_created?.[0])
        : undefined,
      date_created_end: params?.date_created?.[1]
        ? FormatDate(params?.date_created?.[1])
        : undefined,
      date_created: undefined,
      iccid: params?.iccid ? trimStr(params?.iccid) : undefined,
      ip: params?.ip ? trimStr(params?.ip) : undefined,
    };
    return {
      ...params,
      ...queryParams,
    };
  };

  const search = (params: any) => {
    return client(GET_ASSET_LIST, {
      data: { ...params, ...searchParams },
    });
  };

  const AssetlistColumns: ProColumns<GET_ASSET_LIST_INFO>[] = [
    {
      title: "资产名称",
      dataIndex: "asset_name",
      key: "asset_name",
    },
    {
      title: "所属客户",
      dataIndex: "hospital_name",
      key: "hospital_name",
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "ICCID",
      dataIndex: "iccid",
      key: "iccid",
    },
    {
      title: "运营商",
      dataIndex: "carrier",
      hideInSearch: true,
      key: "carrier",
      valueType: "select",
      valueEnum: {
        unicom: {
          text: "联通",
        },
        cmcc: {
          text: "移动",
        },
        chinanet: {
          text: "电信",
        },
      },
      fieldProps: {
        mode: "multiple",
        maxTagCount: 3,
      },
    },
    {
      title: "节点",
      dataIndex: "nodes_display",
      key: "nodes_display",
      hideInSearch: true,
      render: (_, record) => {
        return getNode(record.nodes_display);
      },
    },

    {
      title: "资产状态",
      dataIndex: "is_active",
      key: "is_active",
      valueType: "select",
      valueEnum: {
        1: {
          text: "激活",
          status: "Success",
        },
        0: {
          text: "关闭",
          status: "Default",
        },
      },
    },
    {
      title: "创建时间",
      dataIndex: "date_created",
      key: "date_created",
      valueType: "dateRange",
      render: (_, record, __) => {
        return dayjs(record.date_created).format(DateTimeFormate);
      },
    },
    {
      title: "数据回传时间",
      search: false,
      dataIndex: "feedback_time",
      key: "feedback_time",
    },
    {
      title: "可连接性",
      dataIndex: "connectivity",
      key: "connectivity",
      valueType: "select",
      valueEnum: {
        ok: {
          text: "可连接",
          status: "Success",
        },
        failed: {
          text: "连接失败",
          status: "Error",
        },
        unknown: {
          text: "未知",
          status: "Default",
        },
      },
    },
    {
      title: "最后连接时间",
      dataIndex: "last_connect",
      key: "last_connect",
      hideInSearch: true,
    },
    {
      title: "最后连接人",
      dataIndex: "last_user",
      key: "last_user",
      hideInSearch: true,
    },
  ];

  useEffect(() => {
    actionRef.current?.reload();
    setDownloadParams(searchParams);
  }, [searchParams]);

  return (
    <div>
      <Fragment>
        <ProTable<GET_ASSET_LIST_INFO>
          style={{
            minWidth: 988,
          }}
          rowKey="id"
          actionRef={actionRef}
          formRef={formRef}
          cardBordered
          columns={AssetlistColumns}
          request={async (params) => {
            try {
              let queryParams = getParams(params);
              const { current, pageSize, ...downloadParamsD } = queryParams;
              setDownloadParams({ ...downloadParamsD, ...searchParams });
              const res = await search(queryParams);
              return {
                data: res.results,
                total: res.count,
                success: true,
              };
            } catch (e) {
              return { success: false };
            }
          }}
          onReset={() => {
            setSearchParams(undefined);
            setSelectedTag(["全部资产"]);
          }}
          search={{
            labelWidth: "auto",
            searchText: "搜索",
            span: 6,
          }}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
            pageSize:
              Math.trunc((height - 382) / 47) > 5
                ? Math.trunc((height - 382) / 47)
                : 5,
            current: page,
            onChange: (v) => {
              setPage(v);
            },
          }}
          toolBarRender={() => [
            <Button
              key="export_break"
              loading={gettingFile}
              icon={<DownloadOutlined />}
              onClick={getFileHandle}
            >
              导出
            </Button>,
          ]}
        />
      </Fragment>
    </div>
  );
};
