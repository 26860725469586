import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useBreakDetailSuspend = () => {
  const [{ openBreakDetailSuspend }, setOpenBreakDetailSuspend] =
    useUrlQueryParam(["openBreakDetailSuspend"]);

  const open = () =>
    setOpenBreakDetailSuspend({ openBreakDetailSuspend: true });
  const close = () =>
    setOpenBreakDetailSuspend({
      openBreakDetailSuspend: undefined,
    });

  return [openBreakDetailSuspend === "true", open, close] as const;
};
