import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useChecklistAdd = () => {
  const [{ openChecklistAdd }, setopenChecklistAdd] = useUrlQueryParam([
    "openChecklistAdd",
  ]);

  const open = () => setopenChecklistAdd({ openChecklistAdd: true });
  const close = () => setopenChecklistAdd({ openChecklistAdd: undefined });

  return [openChecklistAdd === "true", open, close] as const;
};
