import { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Select,
  DatePicker,
  Descriptions,
  Divider,
  Radio,
  Input,
  RadioChangeEvent,
} from "antd";
import { useDebounceFn } from "ahooks";
import { useDeliveryDetailScheduled } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailScheduled";
import {
  DELIVERY_USER_TYPE,
  DELIVERY_DETAIL_TYPE,
  NEXT_PROCESS_TYPE,
} from "lib/interface/launchChangeList";
import { GET_DELIVERY_USER_LIST } from "lib/api/deliverylist";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { HospSelectFilter, FormatDate, FormatDayjs } from "utils";
import { useAuth } from "context/auth";

const { Item } = Descriptions;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const DeliveryDetailScheduledModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailScheduled();
  const [isHistoryCustomization, setIsHistoryCustomization] =
    useState<number>(0);

  const [form] = Form.useForm();
  const { user } = useAuth();
  const client = useHttp();
  const {
    run: getDeliveryUserList,
    data: deliveryUserList,
    isIdle: getDeliveryUserListIdle,
    isLoading: gettingDeliveryUserList,
  } = useAsync<DELIVERY_USER_TYPE[]>();
  const getDeliveryUserListHandle = () => {
    getDeliveryUserList(client(GET_DELIVERY_USER_LIST));
  };
  const { run: getDeliveryUserListDebounce } = useDebounceFn(
    getDeliveryUserListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      planned_delivery_date: FormatDate(value.planned_delivery_date),
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      const delivery_user_id = detailInfo?.delivery_user_id
        ? detailInfo?.delivery_user_id
        : user?.id;
      form.setFieldsValue({
        delivery_user_id: delivery_user_id,
        planned_delivery_date: detailInfo?.planned_delivery_date
          ? FormatDayjs(detailInfo?.planned_delivery_date)
          : "",
      });
      getDeliveryUserListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  const childinfo = () => {
    switch (detailInfo?.delivery_type_name) {
      case "销售交付":
      case "试用交付":
      case "渠道交付":
        return (
          <>
            <Divider plain style={{ margin: "12px 0", color: "gray" }}>
              信息提示
            </Divider>
            <Descriptions column={1} size={"small"}>
              <Item label={"本次上线交付产品"}>
                {detailInfo?.product_description?.split("|").join("、")}
              </Item>
            </Descriptions>
            <Descriptions column={1} size={"small"}>
              <Item label={"当前医院已上线"}>
                {detailInfo?.hospital_info?.product_info?.length ? (
                  <>
                    {detailInfo?.hospital_info?.product_info
                      ?.map(
                        (i) =>
                          `${i?.product_chinese_name} ${
                            i?.product_version_name
                              ? i.product_version_name
                              : ""
                          } `,
                      )
                      .join("、")}
                  </>
                ) : (
                  <>无</>
                )}
              </Item>
            </Descriptions>
          </>
        );
      case "产品升级":
      case "硬件部署":
      case "组件升级":
        return (
          <>
            <Form.Item
              name="is_history_customization_version"
              label="是否存在历史定制化 "
              rules={[
                { required: true, message: "必须选择是否存在历史定制化" },
              ]}
            >
              <Radio.Group
                style={{ marginLeft: "12px" }}
                onChange={(e: RadioChangeEvent) => {
                  setIsHistoryCustomization(e.target.value);
                }}
              >
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        );
    }
  };

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="开始排期"
      onCancel={close}
      onOk={form.submit}
      width={"700px"}
      style={{ minWidth: "600px" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        <Form.Item
          name="delivery_user_id"
          label="交付负责人"
          rules={[{ required: true, message: "必须选择交付负责人" }]}
        >
          <Select
            showSearch
            loading={getDeliveryUserListIdle || gettingDeliveryUserList}
            placeholder="选择交付负责人"
            filterOption={HospSelectFilter}
            options={deliveryUserList?.map((i) => ({
              label: `${i.userName} (${i.jobTitle})`,
              value: i.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="planned_delivery_date"
          label="计划交付日期"
          rules={[{ required: true, message: "必须选择计划交付日期" }]}
        >
          <DatePicker
            style={{ width: "200px" }}
            placeholder="请选择计划交付日期"
          />
        </Form.Item>
        {childinfo()}
        {isHistoryCustomization ? (
          <Form.Item
            name="history_customization_version_content"
            label="历史定制化版本内容"
            rules={[
              {
                required: isHistoryCustomization ? true : false,
                message: "必须填写历史定制化版本内容",
              },
            ]}
          >
            <Input.TextArea
              placeholder="请填写历史定制化内容"
              autoSize={{ minRows: 2 }}
            />
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </Modal>
  );
};
