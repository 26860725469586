import { Ellipsis, List } from "antd-mobile";
import { useDebounceFn } from "ahooks";

import {
  InsidersType,
  UserType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/infoTabPane/interface";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { GET_USER_TYPE } from "lib/api";
import { useMount } from "utils";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";

export const MobileInsiderInfo = ({
  insiders,
}: {
  insiders: InsidersType[] | undefined;
}) => {
  const client = useHttp();

  const {
    run: getInsiderInfo,
    data: insiderInfo,
    isLoading: gettingInsiderInfo,
  } = useAsync<UserType[]>();
  const getInsiderInfoHandle = () => {
    getInsiderInfo(client(GET_USER_TYPE));
  };

  const { run } = useDebounceFn(getInsiderInfoHandle, { wait: 200 });

  useMount(run);

  return (
    <ContentCard>
      <LoadingMask visible={gettingInsiderInfo} />
      <List mode="card" header={<ListHeader text="内部人员信息" />}>
        {insiderInfo?.map((u) => (
          <List.Item
            key={u.id}
            extra={
              <Ellipsis
                direction="end"
                content={
                  insiders?.find((i) => i.type_name === u.name)?.user_name ||
                  "未知"
                }
              />
            }
          >
            <Ellipsis direction="end" content={u.name || "未知类型"} />
          </List.Item>
        ))}
      </List>
    </ContentCard>
  );
};
