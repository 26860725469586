import { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  MarkLineComponent,
  MarkLineComponentOption,
} from "echarts/components";
import { BarChart, BarSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { message } from "antd";

type EChartsOption = echarts.ComposeOption<
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | MarkLineComponentOption
  | BarSeriesOption
>;

export interface MyBarChartType {
  xData: string[] | undefined;
  series: BarSeriesOption[] | undefined;
}

export const MyBarChart = ({
  Data,
  Title,
  height,
}: {
  Data: MyBarChartType | undefined;
  Title?: string;
  height?: number;
}) => {
  echarts.use([
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    BarChart,
    CanvasRenderer,
  ]);

  const [option, setOption] = useState<EChartsOption>({});

  const getOption = (): EChartsOption => ({
    title: {
      text: Title,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {},
    grid: {
      left: "0%",
      right: "3%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: Data?.xData,
        axisLabel: {
          interval: 0,
          rotate: -38,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: Data?.series,
  });

  const chartRef = useRef(null);
  let myChart: echarts.ECharts;

  const renderChart = (option: EChartsOption) => {
    try {
      const renderedInstance = echarts.getInstanceByDom(
        chartRef.current as unknown as HTMLElement,
      );
      if (renderedInstance) {
        // myChart = renderedInstance;
        myChart = echarts.init(chartRef.current as unknown as HTMLElement);
      } else {
        myChart = echarts.init(chartRef.current as unknown as HTMLElement);
      }
      myChart.setOption(option);
    } catch (error) {
      message.error("初始化图表失败！");
      console.error(error);
      myChart && myChart.dispose();
    }
  };

  const resizeHandler = () => {
    myChart.resize();
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    renderChart(option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  useEffect(() => {
    setOption(getOption());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  return height ? (
    <div style={{ height: height }}>
      <div style={{ height: "100%", width: "100%" }} ref={chartRef}></div>
    </div>
  ) : (
    <div style={{ height: "100%", width: "100%" }} ref={chartRef}></div>
  );
};
