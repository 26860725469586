import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useCompetitiveAdd = () => {
  const [{ openCompetitiveAdd }, setOpenCompetitiveAdd] = useUrlQueryParam([
    "openCompetitiveAdd",
  ]);

  const open = () => setOpenCompetitiveAdd({ openCompetitiveAdd: true });
  const close = () => setOpenCompetitiveAdd({ openCompetitiveAdd: undefined });

  return [openCompetitiveAdd === "true", open, close] as const;
};
