import { useEffect, useRef, useState, useMemo } from "react";
import { useAuth } from "context/auth";
import { useHttp } from "utils/http";
import { Space, Button, message, Divider, Input } from "antd";
import { Comment } from "@ant-design/compatible";

import { useFullscreen } from "ahooks";
import ReactQuill, { Quill } from "react-quill";

import "react-quill/dist/quill.snow.css";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "./index.css";
import "./quill.snow.css";
import "./quill.bubble.css";
import ImageResize from "quill-image-resize-module-zzone";

import { GET_USER_LIST } from "lib/api";
import { COMMENT_LIST } from "lib/interface/breaklist";
import { useAsync } from "utils/hooks/useAsync";
import { ADD_COMMENTS, UPDATE_COMMENT } from "lib/api";

// Quill.register("modules/imageResize", ImageResize);

export const MentionQuill = ({
  name,
  info,
  isCanChange,
  relatedField,
  relatedFiename,
  issues_id,
  parent_id,
  comment_id,
  user_id,
  type,
  reload,
}: {
  name: string;
  info: string;
  isCanChange: boolean;
  relatedField?: { id: number; name: string }[];
  relatedFiename?: string;
  commentInfo?: COMMENT_LIST;
  issues_id: number | string | undefined;
  parent_id?: number | string | undefined;
  comment_id?: number | string;
  user_id?: number | string;
  type: string;
  reload: () => void;
}) => {
  const client = useHttp();
  const { user } = useAuth();

  const [IsEditting, setIsEditting] = useState<boolean>(false);
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [IsReply, setIsReply] = useState<boolean>(false);
  const [parentId, setParentId] = useState<number | string>();
  const [value, setValue] = useState("");
  const [replyValue, setReplyValue] = useState("");
  const [notifyUsers, setNotifyUsers] = useState<number[]>([]);
  const fullRef = useRef<HTMLDivElement>(null);
  const [, { enterFullscreen }] = useFullscreen(fullRef);
  const [openInput, setOpenInput] = useState<boolean>(true);

  const handleChange = (
    content: string,
    delta: any,
    source: string,
    editor: any,
  ) => {
    const newDelta = editor.getContents();
    if (IsReply) {
      setReplyValue(content);
    } else {
      setValue(content);
    }
  };

  const handleCancle = () => {
    setIsEditting(false);
    setIsReply(false);
    setValue(info);
  };

  const handleSubmit = () => {
    setIsEditting(false);
  };

  const search = (params: any) => {
    return client(GET_USER_LIST, {
      data: { ...params },
    });
  };

  useEffect(() => {
    setValue(info);
  }, [info]);

  const modules = useMemo(() => {
    return {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ color: [] }, { background: [] }],
        ["link", "image"],
        ["clean"],
      ],
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
      mention: {
        mentionDenotationChars: ["@"],
        dataAttributes: [
          "id",
          "userName",
          "denotationChar",
          "link",
          "target",
          "email",
        ],
        allowedChars: /^[\u4e00-\u9fa5]*$/,
        positioningStrategy: "fixed",
        source: async (searchTerm: any, renderItem: any, mentionChar: any) => {
          let values;
          if (mentionChar === "@") {
            values = await search({ userName: searchTerm });
          }
          if (searchTerm.length === 0) {
            renderItem(values, searchTerm);
          } else {
            if (values) {
              const matches = values.filter((item: any) =>
                item.userName.toLowerCase().includes(searchTerm.toLowerCase()),
              );
              renderItem(matches, searchTerm);
            }
          }
        },
        onSelect: (data: any, insertItem: any) => {
          const item = {
            id: data.id,
            value: data.userName,
            denotationChar: "@",
            email: data.email,
          };
          insertItem(item);
          setNotifyUsers((prevNotifyUsers) => [...prevNotifyUsers, data.id]);
        },
        renderItem: (item: any, searchTerm: any) => {
          return `${item.userName}`;
        },
      },
    };
  }, []);

  useEffect(() => {
    setIsEditting(isCanChange);
  }, [isCanChange]);

  useEffect(() => {
    setDisableEdit(user_id !== user?.id);
    setNotifyUsers([]);
  }, []);

  const child = () => {
    switch (type) {
      default:
        return (
          <>
            <ReactQuill
              readOnly
              theme={"bubble"}
              value={value}
              modules={{
                imageResize: { displaySize: true, readOnly: true },
              }}
            />
            <Divider style={{ margin: "0px" }} />
          </>
        );
    }
  };
  return (
    <>
      <div>{child()}</div>
    </>
  );
};
