import { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Radio,
  Button,
  Space,
  Spin,
  Tooltip,
  DatePicker,
  Select,
  Divider,
} from "antd";
import type { TableProps } from "antd";

import {
  ProTable,
  PageHeader,
  ActionType,
  FormInstance,
  ProColumns,
} from "@ant-design/pro-components";
import { RangeValue, GetDate, useMount } from "utils";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { HosNodesType, BREAK_DISTRIBUTION_LIST_TYPE } from "../../interface";
import { useHttp } from "utils/http";
import {
  GET_HOSP_NODES,
  GET_BREAK_DISTRIBUTION_STATISTICS_LIST,
} from "lib/api";
import { useAsync } from "utils/hooks/useAsync";
import { useDebounceFn } from "ahooks";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const Table = () => {
  const client = useHttp();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const { height } = useWindowSize();
  const [queryDateRange, setQueryDateRange] = useState<RangeValue>();
  const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [searchAreaID, setSearchAreaID] = useState<number | null>();
  const [searchDistributionType, setSearchDistributionType] =
    useState<number>(1);
  const [lightButton, setLightButton] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [BreaklistColumns, setBreaklistColumns] =
    useState<ProColumns<BREAK_DISTRIBUTION_LIST_TYPE>[]>();
  const currentWeekStr = GetDate("week")?.toString();
  const currentYearsStr = GetDate("year")?.toString();
  const currentMonthStr = GetDate("month")?.toString();
  const lastSixMonthStr = GetDate("last_six_month")?.toString();

  const getColumns = (
    tabName: number,
  ): ProColumns<BREAK_DISTRIBUTION_LIST_TYPE>[] => {
    const commonColumns = [
      {
        title: "工单数量",
        dataIndex: "total_num",
      },
    ];
    switch (tabName) {
      case 1:
        return [
          { title: "省份", dataIndex: "province_name" },
          { title: "区域", dataIndex: "area_name" },
          {
            title: "工单数量",
            dataIndex: "total_num",
            key: "total_num",
            sorter: (a, b) => a.total_num - b.total_num,
            showSorterTooltip: false,
          },
          {
            title: "已解决工单数量",
            dataIndex: "resolved_num",
            key: "resolved_num",
            sorter: (a, b) => a.resolved_num - b.resolved_num,
            showSorterTooltip: false,
          },
          {
            title: "未解决工单数量",
            dataIndex: "unresolved_num",
            sorter: (a, b) => a.unresolved_num - b.unresolved_num,
            showSorterTooltip: false,
            tooltip: (
              <span
                dangerouslySetInnerHTML={{
                  __html: `未解决工单数量说明:<br />
               包括挂起的工单.<br />`,
                }}
              />
            ),
          },
          {
            title: "升级SRD工单数量",
            dataIndex: "srd_num",
            sorter: (a, b) => a.srd_num - b.srd_num,
            showSorterTooltip: false,
          },

          {
            title: "升级研发工单数量",
            dataIndex: "dev_num",
            sorter: (a, b) => a.dev_num - b.dev_num,
            showSorterTooltip: false,
          },
          {
            title: "解决率",
            dataIndex: "resolution_rate",
            sorter: (a, b) => a.resolution_rate - b.resolution_rate,
            showSorterTooltip: false,
          },
          {
            title: "自解率",
            dataIndex: "self_solving_rate",
            sorter: (a, b) => a.self_solving_rate - b.self_solving_rate,
            showSorterTooltip: false,
            tooltip: (
              <span
                dangerouslySetInnerHTML={{
                  __html: `自解率指标说明:<br />
               已解决的工单中, 未升级SRD和研发的比例.<br />`,
                }}
              />
            ),
          },
          {
            title: "远程解决数量",
            dataIndex: "remote_solution_num",
            sorter: (a, b) =>
              a.remote_solution_num !== undefined &&
              b.remote_solution_num !== undefined
                ? a.remote_solution_num - b.remote_solution_num
                : 0,
            showSorterTooltip: false,
            tooltip: (
              <span
                dangerouslySetInnerHTML={{
                  __html: `远程解决数量指标说明:<br />
                已解决的工单中，是否现场处理为否的数量.<br />`,
                }}
              />
            ),
          },
          {
            title: "远程解决率",
            dataIndex: "remote_solution_rate",
            sorter: (a, b) =>
              a.remote_solution_rate !== undefined &&
              b.remote_solution_rate !== undefined
                ? a.remote_solution_rate - b.remote_solution_rate
                : 0,
            showSorterTooltip: false,
            tooltip: (
              <span
                dangerouslySetInnerHTML={{
                  __html: `远程解决数量指标说明:<br />
                已解决的工单中，是否现场处理为否的数量.<br />`,
                }}
              />
            ),
          },
        ];
      case 2:
        return [
          { title: "姓名", dataIndex: "user_name" },
          {
            title: "工单数量",
            dataIndex: "total_num",
            sorter: (a, b) => a.total_num - b.total_num,
            showSorterTooltip: false,
          },
          {
            title: "已解决工单数量",
            dataIndex: "resolved_num",
            sorter: (a, b) => a.resolved_num - b.resolved_num,
            showSorterTooltip: false,
          },
          {
            title: "未解决工单数量",
            dataIndex: "unresolved_num",
            sorter: (a, b) => a.unresolved_num - b.unresolved_num,
            showSorterTooltip: false,
            tooltip: (
              <span
                dangerouslySetInnerHTML={{
                  __html: `未解决工单数量说明:<br />
               包括挂起的工单.<br />`,
                }}
              />
            ),
          },
          {
            title: "转单到现场数量",
            dataIndex: "transfer_site_num",
            sorter: (a, b) => a.transfer_site_num - b.transfer_site_num,
            showSorterTooltip: false,
          },
          {
            title: "升级SRD工单数量",
            dataIndex: "srd_num",
            sorter: (a, b) => a.srd_num - b.srd_num,
            showSorterTooltip: false,
          },
          {
            title: "升级研发工单数量",
            dataIndex: "dev_num",
            sorter: (a, b) => a.dev_num - b.dev_num,
            showSorterTooltip: false,
          },
          {
            title: "解决率",
            dataIndex: "resolution_rate",
            sorter: (a, b) => a.resolution_rate - b.resolution_rate,
            showSorterTooltip: false,
          },
          {
            title: "自解率",
            dataIndex: "self_solving_rate",
            sorter: (a, b) => a.self_solving_rate - b.self_solving_rate,
            showSorterTooltip: false,
            tooltip: (
              <span
                dangerouslySetInnerHTML={{
                  __html: `自解率指标说明:<br />
               已解决的工单中, 未升级SRD和研发的比例.<br />`,
                }}
              />
            ),
          },
        ];
      case 3:
        return [
          { title: "姓名", dataIndex: "user_name" },
          {
            title: "工单数量",
            dataIndex: "total_num",
            sorter: (a, b) => a.total_num - b.total_num,
            showSorterTooltip: false,
          },
          {
            title: "已解决工单数量",
            dataIndex: "resolved_num",
            sorter: (a, b) => a.resolved_num - b.resolved_num,
            showSorterTooltip: false,
          },
          {
            title: "未解决工单数量",
            dataIndex: "unresolved_num",
            sorter: (a, b) => a.unresolved_num - b.unresolved_num,
            showSorterTooltip: false,
            tooltip: (
              <span
                dangerouslySetInnerHTML={{
                  __html: `未解决工单数量说明:<br />
               包括挂起的工单.<br />`,
                }}
              />
            ),
          },
          {
            title: "升级SRD工单数量",
            dataIndex: "srd_num",
            sorter: (a, b) => a.srd_num - b.srd_num,
            showSorterTooltip: false,
          },
          {
            title: "升级研发工单数量",
            dataIndex: "dev_num",
            sorter: (a, b) => a.dev_num - b.dev_num,
            showSorterTooltip: false,
          },

          {
            title: "解决率",
            dataIndex: "resolution_rate",
            sorter: (a, b) => a.resolution_rate - b.resolution_rate,
            showSorterTooltip: false,
          },
          {
            title: "自解率",
            dataIndex: "self_solving_rate",
            sorter: (a, b) => a.self_solving_rate - b.self_solving_rate,
            showSorterTooltip: false,
            tooltip: (
              <span
                dangerouslySetInnerHTML={{
                  __html: `自解率指标说明:<br />
               已解决的工单中, 未升级SRD和研发的比例.<br />`,
                }}
              />
            ),
          },
          {
            title: "远程解决数量",
            dataIndex: "remote_solution_num",
            sorter: (a, b) =>
              a.remote_solution_num !== undefined &&
              b.remote_solution_num !== undefined
                ? a.remote_solution_num - b.remote_solution_num
                : 0,
            showSorterTooltip: false,
            tooltip: (
              <span
                dangerouslySetInnerHTML={{
                  __html: `远程解决数量指标说明:<br />
                已解决的工单中，是否现场处理为否的数量.<br />`,
                }}
              />
            ),
          },
          {
            title: "远程解决率",
            dataIndex: "remote_solution_rate",
            sorter: (a, b) =>
              a.remote_solution_rate !== undefined &&
              b.remote_solution_rate !== undefined
                ? a.remote_solution_rate - b.remote_solution_rate
                : 0,
            showSorterTooltip: false,
            tooltip: (
              <span
                dangerouslySetInnerHTML={{
                  __html: `远程解决数量指标说明:<br />
                已解决的工单中，是否现场处理为否的数量.<br />`,
                }}
              />
            ),
          },
        ];
    }
    return commonColumns;
  };

  const search = (params: any) => {
    if (queryDateRange) {
      const start_date = queryDateRange[0]?.format("YYYY-MM-DD");
      const end_date = queryDateRange[1]?.format("YYYY-MM-DD");
      const queryParams = {
        start_date: start_date,
        end_date: end_date,
        type: searchDistributionType,
        area_id: searchAreaID,
      };
      return client(GET_BREAK_DISTRIBUTION_STATISTICS_LIST, {
        data: { ...queryParams },
      });
    }
  };

  const {
    run: getHosNodes,
    isLoading: gettingNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };
  const { run: getHosNodesDebounce } = useDebounceFn(getHosNodesHandle, {
    wait: 200,
  });

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.nodeLevel === 1 && item.nodeName !== "海外") {
        areaListTMP?.push(item);
      }
    });
    setAreaList(areaListTMP);
  }, [hosNodes]);

  useEffect(() => {
    if (queryDateRange) {
      const queryDateRangeStr = queryDateRange?.toString();
      if (queryDateRangeStr === currentWeekStr) {
        setLightButton("week");
      } else if (queryDateRangeStr === currentYearsStr) {
        setLightButton("year");
      } else if (queryDateRangeStr === currentMonthStr) {
        setLightButton("month");
      } else if (queryDateRangeStr === lastSixMonthStr) {
        setLightButton("last_six_month");
      } else {
        setLightButton("");
      }
    }
  }, [queryDateRange]);

  useEffect(() => {
    if (searchDistributionType) {
      setBreaklistColumns(getColumns(searchDistributionType));
    }
  }, [searchDistributionType]);

  useEffect(() => {
    setPage(1);
    actionRef.current?.reload();
  }, [queryDateRange, searchAreaID, searchDistributionType]);

  useEffect(() => {
    setQueryDateRange(GetDate("last_six_month"));
  }, []);

  useMount(() => {
    getHosNodesDebounce();
  });

  const Loading = false;

  return (
    <>
      <Row
        style={{
          minWidth: 988,
        }}
      >
        <Col span={24}>
          <PageHeader>
            <Row>
              <Col span={6}>
                <Space>
                  <Radio.Group
                    onChange={(e) => {
                      setSearchDistributionType(e.target.value);
                    }}
                    defaultValue={searchDistributionType}
                    buttonStyle="solid"
                  >
                    <Radio.Button value={1}>省份分布</Radio.Button>
                    <Radio.Button value={2}>线上分布</Radio.Button>
                    <Radio.Button value={3}>现场分布</Radio.Button>
                  </Radio.Group>
                </Space>
              </Col>
              <Col span={18} style={{ textAlign: "right" }}>
                <Space wrap>
                  <Button
                    size="small"
                    type={lightButton === "week" ? "link" : "text"}
                    style={{ padding: "1px" }}
                    onClick={() => {
                      setQueryDateRange(GetDate("week"));
                    }}
                  >
                    本周
                  </Button>
                  <Tooltip title="工单创建时间 = 本月1日到本月末.">
                    <Button
                      size="small"
                      type={lightButton === "month" ? "link" : "text"}
                      style={{ padding: "1px" }}
                      onClick={() => {
                        setQueryDateRange(GetDate("month"));
                      }}
                    >
                      本月
                    </Button>
                  </Tooltip>
                  <Tooltip title="工单创建时间 = 本年1月1日到本年12月31日.">
                    <Button
                      size="small"
                      type={lightButton === "year" ? "link" : "text"}
                      style={{ padding: "1px" }}
                      onClick={() => {
                        setQueryDateRange(GetDate("year"));
                      }}
                    >
                      本年
                    </Button>
                  </Tooltip>
                  <Button
                    size="small"
                    style={{ padding: "1px" }}
                    type={lightButton === "last_six_month" ? "link" : "text"}
                    onClick={() => {
                      setQueryDateRange(GetDate("last_six_month"));
                    }}
                  >
                    最近半年
                  </Button>
                  <RangePicker
                    style={{
                      minWidth: "250px",
                    }}
                    allowClear={false}
                    onChange={(val) => {
                      setQueryDateRange(val);
                    }}
                    value={queryDateRange}
                    picker="date"
                  />
                  <Select
                    allowClear
                    placeholder="区域"
                    disabled={gettingNodes}
                    loading={gettingNodes}
                    value={searchAreaID}
                    onChange={(nodeId) => {
                      setSearchAreaID(nodeId);
                    }}
                  >
                    {areaList?.map((item, index) => (
                      <Option key={index} value={item.nodeId}>
                        {item.nodeName}
                      </Option>
                    ))}
                  </Select>
                </Space>
              </Col>
            </Row>
          </PageHeader>
        </Col>
      </Row>
      <Divider style={{ marginTop: "4px", marginBottom: "5px" }} />
      <Row>
        <Col span={24}>
          <ProTable<BREAK_DISTRIBUTION_LIST_TYPE>
            style={{
              minWidth: 988,
            }}
            rowKey="id"
            actionRef={actionRef}
            formRef={formRef}
            cardBordered
            columns={BreaklistColumns}
            request={async (params) => {
              try {
                const res = await search(params);
                return {
                  data: res,
                  success: true,
                };
              } catch (e) {
                return { success: false };
              }
            }}
            // loading={}
            search={false}
            pagination={{
              showQuickJumper: true,
              showSizeChanger: false,
              pageSize: 50,
              current: page,
              onChange: (v) => {
                setPage(v);
              },
            }}
          />
        </Col>
      </Row>
      <Divider
        style={{
          marginTop: "4px",
          marginBottom: "4px",
          borderTop: "1px  grey",
          // borderTop: "1px dashed grey",
          fontWeight: "bold",
        }}
      />
    </>
  );
};
