import { Form, Radio } from "antd";
import type { ColumnsType } from "antd/es/table";

import { CHECKLIST_VALUE_TYPE } from "lib/interface/checklist";
import { valueIsUseful } from "utils";

const { Item } = Form;

export const AbnormalColumns: ColumnsType<CHECKLIST_VALUE_TYPE> = [
  {
    title: "编号",
    dataIndex: "id",
    width: 64,
    align: "center",
    render: (_, __, index) => {
      return index + 1;
    },
  },
  {
    title: "产品组件",
    dataIndex: "product_name",
    width: 96,
  },
  {
    title: "安装分支",
    dataIndex: "product_version",
    width: 96,
  },
  {
    title: "问题内容",
    dataIndex: "problem_content",
  },
  {
    title: "修复方式",
    dataIndex: "repair_mode",
  },
  {
    title: "修复结果",
    dataIndex: "value",
    width: 288,
    render: (_, record, __) => {
      return (
        <Item
          key={record.id}
          name={record.id}
          rules={[{ required: record.is_need === 1, message: "必须填写此项" }]}
          initialValue={record.value}
        >
          <Radio.Group disabled={valueIsUseful(record.value)}>
            <Radio value={0}>未修复</Radio>
            <Radio value={1}>已修复</Radio>
            <Radio value={2}>不适用</Radio>
          </Radio.Group>
        </Item>
      );
    },
  },
];
