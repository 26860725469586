import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  Input,
  message,
  Select,
  Divider,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";

import { Eqpt } from "../interface";
import { useAsync } from "utils/hooks/useAsync";
import { GET_EQPT, GET_OEM_LIST } from "../api";
import { DisposeForm } from "utils";

const { Option } = Select;

export const HardwareSettingAdd = ({
  submitAPI,
  visible,
  setVisible,
  reload,
}: {
  submitAPI: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  // 提交data
  const [addType, setAddType] = useState<string>();
  const [addTypeItem, setAddTypeItem] = useState<string[]>([]);
  const [addOemItem, setAddOemItem] = useState<string[]>([]);
  const [newType, setNewType] = useState<string | null>(null);
  const [newOem, setNewOem] = useState<string | null>(null);

  const initState = () => {
    setAddType(undefined);
    setAddTypeItem([]);
    setAddOemItem([]);
    setNewType(null);
    setNewOem(null);
  };

  const {
    run: getEqptList,
    isLoading: gettingEqptList,
    data: eqptList,
  } = useAsync<Eqpt[]>();
  const getEqptListFunc = () => {
    getEqptList(client(GET_EQPT)).then((res: Eqpt[]) => {
      if (res) {
        let arr: string[] = res?.map((i) => i.name);
        setAddTypeItem(arr);
      }
    });
  };

  const {
    run: getOemList,
    isLoading: gettingOemList,
    data: oemList,
  } = useAsync<Eqpt[]>();
  const getOemListFunc = (eqpt_type_id: number) => {
    getOemList(client(GET_OEM_LIST(eqpt_type_id)));
  };

  const newTypeHandle = (e: React.MouseEvent) => {
    e.preventDefault();
    if (newType) {
      setAddTypeItem([...addTypeItem, newType]);
      setNewType(null);
    }
  };

  const newOemHandle = (e: React.MouseEvent) => {
    e.preventDefault();
    if (newOem) {
      setAddOemItem([...addOemItem, newOem]);
      setNewOem(null);
    }
  };

  const submit = (value: any) => {
    client(submitAPI, {
      data: {
        ...value,
      },
      method: "POST",
    })
      .then(() => {
        message.success("添加成功！");
        setVisible(false);
        reload();
      })
      .catch((e) => {
        console.error(e);
        message.error("添加失败，请检查或联系管理员！");
      });
  };

  const clear = () => {
    setNewOem(null);
    form.setFieldsValue({
      eqpt_oem_name: null,
    });
  };

  useEffect(() => {
    let id = eqptList?.find((i) => i.name === addType)?.id;
    if (id) {
      getOemListFunc(id);
    } else {
      setAddOemItem([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addType]);

  useEffect(() => {
    if (oemList) {
      let arr: string[] = oemList?.map((i) => i.name);
      setAddOemItem(arr);
    }
  }, [oemList]);

  useEffect(() => {
    if (visible) {
      getEqptListFunc();
      initState();
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="新增硬件品牌型号"
      destroyOnClose
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="eqpt_type_name"
          label="类型"
          rules={[{ required: true, message: "必须输入类型" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingEqptList}
            placeholder="选择设备类型"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    allowClear
                    maxLength={50}
                    onChange={(e) => setNewType(e.target.value)}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={newTypeHandle}
                  >
                    添加类型
                  </Button>
                </Space>
              </>
            )}
            onChange={(v) => {
              setAddType(v);
              clear();
            }}
          >
            {addTypeItem?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="eqpt_oem_name"
          label="品牌"
          rules={[{ required: true, message: "必须输入品牌" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingOemList}
            placeholder="选择品牌"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    allowClear
                    maxLength={50}
                    value={newOem || undefined}
                    onChange={(e) => setNewOem(e.target.value)}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={newOemHandle}
                  >
                    添加品牌
                  </Button>
                </Space>
              </>
            )}
          >
            {addOemItem?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="eqpt_model_name"
          label="型号"
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear maxLength={50} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
