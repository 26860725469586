import { Dispatch, RefObject, SetStateAction, useEffect } from "react";
import {
  Button,
  DatePicker,
  DatePickerRef,
  Form,
  Picker,
  TextArea,
  Toast,
} from "antd-mobile";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";
import dayjs from "dayjs";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { DateFormate, DisposeForm, DisposeMobileText } from "utils";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { GET_PRODUCT_VERSION } from "lib/api";
import { ProductVersionType } from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";

const now = new Date();

export const MobileProductUpdateRecordAdd = ({
  orgID,
  ADD_RETAIN,
  visible,
  setVisible,
  reload,
}: {
  orgID: string | number | null | undefined;
  ADD_RETAIN: (orgID: string | number) => string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getProductVersionList,
    isLoading: gettingProductVersionList,
    data: productVersionList,
  } = useAsync<ProductVersionType[]>();
  const getProductVersionListFunc = () => {
    getProductVersionList(client(GET_PRODUCT_VERSION));
  };

  const { run: submit, isLoading: submitting } = useAsync();
  const submitHandle = (value: any) => {
    if (orgID)
      submit(
        client(ADD_RETAIN(""), {
          data: {
            product_id: orgID,
            product_version_id: value?.product_version
              ? parseInt(value?.product_version[0])
              : null,
            update_date: value?.update
              ? dayjs(value?.update).format(DateFormate)
              : null,
            remark: value.remark || null,
          },
          method: "POST",
        }),
      )
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="添加成功" />,
          });
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="添加失败" />,
          });
        });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法提交" />,
      });
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      getProductVersionListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <EditPopup
      visible={visible}
      submitting={submitting}
      setVisible={setVisible}
    >
      <Form
        form={form}
        onFinish={submitHandle}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={submitting}
          >
            提交
          </Button>
        }
      >
        <Form.Header>新增产品升级记录</Form.Header>
        <Form.Item
          name="product_version"
          label="安装分支"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择安装分支" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingProductVersionList}
            columns={
              productVersionList
                ? [
                    productVersionList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选安装分支</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="update"
          label="更新时间"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择更新时间" }]}
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
        >
          <DatePicker tillNow max={now}>
            {(value) =>
              value ? (
                <FormItemDiv>{dayjs(value).format(DateFormate)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择更新时间</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>
        <Form.Item
          name="remark"
          label="注意事项"
          help="额外的补充信息"
          normalize={(v) => DisposeMobileText(v)}
          layout={"vertical"}
        >
          <TextArea autoSize placeholder="输入注意事项" />
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
