import { Dispatch, SetStateAction, useEffect } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  Input,
  message,
  Select,
  InputNumber,
} from "antd";

import { useHttp } from "utils/http";
import { DisposeForm } from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { ClientDepartmentType, CpuType } from "../interface";
import { GET_CUP_TYPE, GET_PRODUCT_DEPARTMENT, GET_SYS_VERSION } from "lib/api";
import { SystemList } from "../../hardwareTabPane/interface";

const { TextArea } = Input;
const { Option } = Select;

export const UserPCInfoAdd = ({
  hospID,
  ADD_RETAIN,
  visible,
  setVisible,
  reload,
}: {
  hospID: number | null;
  productID?: string | undefined;
  ADD_RETAIN: (orgID: string | number) => string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getClientDepartmentTypeList,
    isLoading: gettingClientDepartmentTypeList,
    data: clientDepartmentTypeList,
  } = useAsync<ClientDepartmentType[]>();
  const getClientDepartmentTypeListFunc = () => {
    getClientDepartmentTypeList(client(GET_PRODUCT_DEPARTMENT));
  };

  const {
    run: getCpuTypeList,
    isLoading: gettingCpuTypeList,
    data: cpuTypeList,
  } = useAsync<CpuType[]>();
  const getCpuTypeListFunc = () => {
    getCpuTypeList(client(GET_CUP_TYPE));
  };

  const {
    run: getSysVersionList,
    isLoading: gettingSysVersionList,
    data: sysVersionList,
  } = useAsync<SystemList[]>();
  const getSysVersionListFunc = () => {
    getSysVersionList(client(GET_SYS_VERSION));
  };

  useEffect(() => {
    if (visible) {
      getClientDepartmentTypeListFunc();
      getCpuTypeListFunc();
      getSysVersionListFunc();
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const submit = (value: any) => {
    if (hospID)
      client(ADD_RETAIN(""), {
        data: {
          ...value,
          hospital_id: hospID,
        },
        method: "POST",
      })
        .then(() => {
          message.success("添加成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("添加失败，请检查或联系管理员！");
        });
    else message.error("暂时无法添加，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="添加医生电脑信息"
      maskClosable={false}
      destroyOnClose
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="department_id"
          label="科室"
          rules={[{ required: true, message: "必须选择科室" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingClientDepartmentTypeList}
            placeholder="选择使用科室"
          >
            {clientDepartmentTypeList?.map((i, index) => (
              <Option key={index} value={i?.id}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="cpu_type_id"
          label="CPU"
          rules={[{ required: true, message: "必须选择CPU" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select loading={gettingCpuTypeList} placeholder="选择CPU">
            {cpuTypeList?.map((i, index) => (
              <Option key={index} value={i?.id}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="memory"
          label="内存大小(G)"
          rules={[{ required: true, message: "必须输入内存大小" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <InputNumber precision={0} min={0} max={1024} />
        </Form.Item>
        <Form.Item
          name="system_version_id"
          label="操作系统"
          rules={[{ required: true, message: "必须选择操作系统" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select loading={gettingSysVersionList} placeholder="选择操作系统">
            {sysVersionList?.map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="is_discrete_graphics_card"
          label="是否独显"
          rules={[{ required: true, message: "必须说明独显情况" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select placeholder="是/否">
            {[
              { id: 0, name: "否" },
              { id: 1, name: "是" },
            ].map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="proportion"
          label="占比(百分制)"
          rules={[{ required: true, message: "必须输入占比" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <InputNumber precision={0} min={0} max={100} />
        </Form.Item>
        <Form.Item name="remark" label="备注" normalize={(v) => DisposeForm(v)}>
          <TextArea allowClear />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
