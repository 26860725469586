import { useState, useEffect, Dispatch, SetStateAction } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  Input,
  Select,
  message,
  DatePicker,
  Upload,
  UploadFile,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import {
  ADD_FILE,
  GET_GPU_MODEL,
  GET_GPU_OEM,
  GET_HOS_PRODUCT_LIST,
  UPDATE_GPU_INFO,
} from "lib/api";
import { GPUModel, GPUOem, GpuType, HospProductsListType } from "../interface";
import {
  DateFormate,
  DisabledDate,
  DisposeForm,
  formatDate,
  GpuNumberList,
  handleUploadImage,
  initFileList,
  limitUploadImage,
} from "utils";
import dayjs from "dayjs";
import { useAsync } from "utils/hooks/useAsync";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";

const { TextArea } = Input;
const { Option } = Select;

export const GPUInfoEdit = ({
  hospID,
  editingItem,
  editable,
  setEditable,
  reload,
}: {
  hospID: number | null;
  editingItem: GpuType | undefined;
  editable: boolean;
  setEditable: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [editOemID, setEditOemID] = useState<number>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const {
    run: getGPUOemList,
    isLoading: gettingGPUOemList,
    data: gpuOemList,
  } = useAsync<GPUOem[]>();
  const getGPUOemListFunc = () => {
    getGPUOemList(client(GET_GPU_OEM));
  };

  const {
    run: getGPUModelList,
    isLoading: gettingGPUModelList,
    data: gpuModelList,
  } = useAsync<GPUModel[]>();
  const getGPUModelListFunc = (org_id: number) => {
    getGPUModelList(client(GET_GPU_MODEL(org_id)));
  };

  const {
    run: getHosProductList,
    isLoading: gettingHosProductList,
    data: hospProductList,
  } = useAsync<HospProductsListType[]>();
  const getHosProductListFunc = (hospital_id: number) => {
    getHosProductList(client(GET_HOS_PRODUCT_LIST(hospital_id)));
  };

  const submit = (value: any) => {
    let id = editingItem?.id;
    if (id)
      client(UPDATE_GPU_INFO(id), {
        data: {
          ...value,
          asset_photos: fileList.map((i) => i.uid),
          change_date: formatDate(value?.change_date),
        },
        method: "PUT",
      })
        .then(() => {
          message.success("修改成功！");
          setEditable(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    else message.error("修改失败，请稍后重试或联系管理员！");
  };

  const clear = () => {
    form.setFieldsValue({
      gpu_type_id: null,
    });
  };

  useEffect(() => {
    if (editOemID) {
      getGPUModelListFunc(editOemID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOemID]);

  useEffect(() => {
    if (editable) {
      setFileList(initFileList(editingItem?.asset_photos) || []);
      form.setFieldsValue({
        ...editingItem,
        products: editingItem?.products.map((i) => i.product_id),
        change_date: dayjs(editingItem?.change_date, DateFormate),
      });
      getGPUOemListFunc();
      if (hospID) {
        getHosProductListFunc(hospID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);

  useEffect(() => {
    if (editingItem) {
      if (editingItem?.gpu_oem_id) {
        setEditOemID(editingItem?.gpu_oem_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  return (
    <Modal
      title="编辑显卡信息"
      destroyOnClose
      maskClosable={false}
      open={editable}
      onCancel={() => setEditable(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="slot_num"
          label="卡槽编号"
          rules={[{ required: true, message: "必须选择卡槽编号" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select placeholder="选择卡槽编号">
            {GpuNumberList.map((i) => (
              <Option key={i} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="gpu_oem_id"
          label="显卡品牌"
          rules={[{ required: true, message: "必须选择显卡品牌" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingGPUOemList}
            placeholder="选择显卡品牌"
            onChange={(v) => {
              clear();
              setEditOemID(v);
            }}
          >
            {gpuOemList?.map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="gpu_type_id"
          label="显卡型号"
          rules={[{ required: true, message: "必须选择显卡型号" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select loading={gettingGPUModelList} placeholder="选择显卡型号">
            {gpuModelList?.map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="assets_code"
          label="资产编码"
          rules={[{ required: true, message: "必须输入资产编码" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item name="uuid" label="UUID" normalize={(v) => DisposeForm(v)}>
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="change_date"
          label="更换时间"
          rules={[{ required: true, message: "必须选择更换时间" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <DatePicker picker={"date"} disabledDate={DisabledDate} />
        </Form.Item>
        <Form.Item
          name="products"
          label="运行产品线"
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingHosProductList}
            mode="multiple"
            placeholder="选择产品线"
          >
            {hospProductList?.map((i, index) => (
              <Option key={index} value={i.product_id}>
                {i.product_id}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="remark" label="备注" normalize={(v) => DisposeForm(v)}>
          <TextArea allowClear />
        </Form.Item>
        <Form.Item name="asset_photos" label="资产照片">
          <Upload
            headers={UploadHeaders}
            maxCount={3}
            multiple={true}
            action={ADD_FILE}
            listType="picture-card"
            fileList={fileList}
            beforeUpload={limitUploadImage}
            onChange={(info) => handleUploadImage(info, setFileList)}
          >
            {fileList?.length >= 3 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传(限3个)</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setEditable(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
