import { Fragment } from "react";
import { Col, Row, Statistic } from "antd";

import { RateCalculate } from "../interface";

export const Calculate = ({
  data,
  isTh,
}: {
  data: RateCalculate | undefined;
  isTh: boolean;
}) => {
  return (
    <Fragment>
      {isTh ? (
        <Row gutter={16}>
          <Col span={6}>
            <Statistic
              title={"病例数"}
              value={
                data?.total_predict_num === 0
                  ? 0
                  : data?.total_predict_num || ""
              }
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="重建数"
              value={data?.total_rc2_num === 0 ? 0 : data?.total_rc2_num || ""}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="访问数"
              value={
                data?.total_access_num === 0 ? 0 : data?.total_access_num || ""
              }
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="报告下载数"
              value={
                data?.total_download_num === 0
                  ? 0
                  : data?.total_download_num || ""
              }
            />
          </Col>
        </Row>
      ) : (
        <Row gutter={16}>
          <Col span={8}>
            <Statistic
              title={"预测量"}
              value={
                data?.total_predict_num === 0
                  ? 0
                  : data?.total_predict_num || ""
              }
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="点击量"
              value={data?.total_usv_num === 0 ? 0 : data?.total_usv_num || ""}
            />
          </Col>
          <Col span={8}>
            <Statistic
              title="点击率"
              value={data?.average_click_rate || "0%"}
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
};
