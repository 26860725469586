import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Button,
  DatePicker,
  DatePickerRef,
  Form,
  Picker,
  Selector,
  Skeleton,
  Stepper,
  Toast,
} from "antd-mobile";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";
import dayjs from "dayjs";

import { useHttp } from "utils/http";
import {
  UPDATE_PRODUCT_DETAIL,
  GET_PRODUCT_LIST,
  GET_DEPLOY_TYPE,
  GET_PRODUCT_DEPARTMENT,
  GET_SERVICE_TYPE,
  GET_USING_STATUS,
  GET_SERVICE_LEVEL,
  UPDATE_CUSTOMIZATION,
  GET_PRODUCT_VERSION_BY_ID,
  GET_PRODUCT_CLIENT,
} from "lib/api";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import {
  ProductClientType,
  ProductDeployType,
  ProductInfoType,
  ProductIsCustomization,
  ProductServiceLevelType,
  ProductServiceType,
  ProductType,
  ProductUsingDepartmentType,
  ProductUsingType,
  ProductVersionType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import { useAsync } from "utils/hooks/useAsync";
import {
  CT_LUNG_VERSION_TYPE,
  DisposeForm,
  RID,
  isNumber,
  DateFormate,
} from "utils";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import {
  ConvertDateToPickerValueList,
  ConvertToPickerValueList,
} from "authenticated-app/mobile/util";

const now = new Date();

export const MobileProductBaseInfoEdit = ({
  editingItem,
  visible,
  setVisible,
  reload,
}: {
  editingItem: ProductInfoType | undefined | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [isModify, setIsModify] = useState(false);
  const [serviceType, setServiceType] = useState<number>();

  const licenseStatus = [
    { value: 1, label: "已授权" },
    { value: 2, label: "未授权" },
    { value: 3, label: "无需授权" },
  ];

  const {
    run: updateCustomizationStatus,
    isLoading: updattingCustomizationStatus,
  } = useAsync<ProductIsCustomization>();
  const updateCustomizationStatusHandle = (checked: string) => {
    if (isNumber(editingItem?.id))
      updateCustomizationStatus(
        client(UPDATE_CUSTOMIZATION(editingItem!.id), {
          data: {
            is_customization: checked === "1" ? 1 : 0,
          },
          method: "PUT",
        }),
      ).then((res) => {
        form.setFieldsValue({
          is_product_customization: ConvertToPickerValueList(
            res.is_customization,
          ),
        });
      });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法更改" />,
      });
  };

  const {
    run: getProductList,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductType[]>();
  const getProductListFunc = () => {
    getProductList(client(GET_PRODUCT_LIST));
  };

  const {
    run: getProductVersionList,
    isLoading: gettingProductVersionList,
    data: productVersionList,
  } = useAsync<ProductVersionType[]>();
  const getProductVersionListFunc = (product_id: string) => {
    getProductVersionList(client(GET_PRODUCT_VERSION_BY_ID(product_id)));
  };

  const {
    run: getUbVersionList,
    isLoading: gettingUbVersionList,
    data: ubVersionList,
  } = useAsync<ProductVersionType[]>();
  const getUbVersionListFunc = () => {
    getUbVersionList(client(GET_PRODUCT_VERSION_BY_ID("UB")));
  };

  const {
    run: getProductClientList,
    isLoading: gettingProductClientList,
    data: productClientList,
  } = useAsync<ProductClientType[]>();
  const getProductClientListFunc = (product_id: string) => {
    getProductClientList(client(GET_PRODUCT_CLIENT(product_id)));
  };

  const {
    run: getDeployTypeList,
    isLoading: gettingDeployTypeList,
    data: deployTypeList,
  } = useAsync<ProductDeployType[]>();
  const getDeployTypeListFunc = () => {
    getDeployTypeList(client(GET_DEPLOY_TYPE));
  };

  const {
    run: getServiceTypeList,
    isLoading: gettingServiceTypeList,
    data: serviceTypeList,
  } = useAsync<ProductServiceType[]>();
  const getServiceTypeListFunc = () => {
    getServiceTypeList(client(GET_SERVICE_TYPE));
  };

  const {
    run: getUsingDepartmentTypeList,
    isLoading: gettingUsingDepartmentTypeList,
    data: usingDepartmentTypeList,
  } = useAsync<ProductUsingDepartmentType[]>();
  const getUsingDepartmentTypeListFunc = () => {
    getUsingDepartmentTypeList(client(GET_PRODUCT_DEPARTMENT));
  };

  const {
    run: getUsingStatusTypeList,
    isLoading: gettingUsingStatusTypeList,
    data: usingStatusTypeList,
  } = useAsync<ProductUsingType[]>();
  const getUsingStatusTypeListFunc = () => {
    getUsingStatusTypeList(client(GET_USING_STATUS));
  };

  const {
    run: getServiceLevelTypeList,
    isLoading: gettingServiceLevelTypeList,
    data: serviceLevelTypeList,
  } = useAsync<ProductServiceLevelType[]>();
  const getServiceLevelTypeListFunc = () => {
    getServiceLevelTypeList(client(GET_SERVICE_LEVEL));
  };

  const [editProduct, setEditProduct] = useState<string>();

  const { run: submit, isLoading: submitting } = useAsync();
  const submitHandle = (value: any) => {
    if (editingItem?.id) {
      submit(
        client(UPDATE_PRODUCT_DETAIL(editingItem?.id), {
          data: {
            product_id: value?.product[0],
            product_status: value?.product_status[0],
            deploy_type_id: value?.deploy_type
              ? parseInt(value?.deploy_type[0])
              : null,
            using_status_id: value?.using_status
              ? parseInt(value?.using_status[0])
              : null,
            version_type: value?.product_version_type
              ? value?.product_version_type[0]
              : undefined,
            product_version_id: value?.product_version
              ? parseInt(value?.product_version[0])
              : undefined,
            ub_version_id: value?.ub_version
              ? parseInt(value?.ub_version[0])
              : undefined,
            client_version_id: value?.client_version
              ? parseInt(value?.client_version[0])
              : undefined,
            online_date: value?.online
              ? dayjs(value?.online).format(DateFormate)
              : undefined,
            service_type_id: value?.service_type
              ? parseInt(value?.service_type[0])
              : undefined,
            service_level_id: value?.service_level
              ? parseInt(value?.service_level[0])
              : undefined,
            number: value?.number ? parseInt(value?.number) : 0,
            threshold: value?.threshold ? parseFloat(value?.threshold) : 0,
            departments: value?.departments || [],
            start_date: value?.start
              ? dayjs(value?.start).format(DateFormate)
              : undefined,
            end_date: value?.end
              ? dayjs(value?.end).format(DateFormate)
              : undefined,
          },
          method: "PUT",
        }),
      )
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="更新成功" />,
          });
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="更新失败" />,
          });
        });
    } else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法更新" />,
      });
  };

  const clear = () => {
    form.setFieldsValue({
      product_version_id: null,
      client_version_id: null,
    });
  };

  useEffect(() => {
    if (editProduct) {
      getProductVersionListFunc(editProduct);
      getProductClientListFunc(editProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editProduct]);

  useEffect(() => {
    if (visible) {
      setIsModify(false);
      getProductListFunc();
      getDeployTypeListFunc();
      getServiceTypeListFunc();
      getUsingDepartmentTypeListFunc();
      getUsingStatusTypeListFunc();
      getServiceLevelTypeListFunc();
      getUbVersionListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (editingItem) {
      setEditProduct(editingItem?.product_id);
      setServiceType(editingItem?.service_type_id);
      form.setFieldsValue({
        product: ConvertToPickerValueList(editingItem?.product_id),
        is_product_customization: ConvertToPickerValueList(
          editingItem?.is_customization,
        ),
        deploy_type: ConvertToPickerValueList(editingItem?.deploy_type_id),
        using_status: ConvertToPickerValueList(editingItem?.using_status_id),
        product_version_type: ConvertToPickerValueList(
          editingItem?.version_type,
        ),
        product_version: ConvertToPickerValueList(
          editingItem?.product_version_id,
        ),
        ub_version: ConvertToPickerValueList(editingItem?.ub_version_id),
        client_version: ConvertToPickerValueList(
          editingItem?.client_version_id,
        ),
        online: ConvertDateToPickerValueList(editingItem?.online_date),
        service_type: ConvertToPickerValueList(editingItem?.service_type_id),
        service_level: ConvertToPickerValueList(editingItem?.service_level_id),
        number: editingItem?.number || 0,
        threshold: editingItem?.threshold || 0,
        departments: editingItem?.departments?.map((i) => i?.id),
        start: ConvertDateToPickerValueList(editingItem?.start_date),
        end: ConvertDateToPickerValueList(editingItem?.end_date),
        product_status: [editingItem?.product_status],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  return (
    <EditPopup
      visible={visible}
      submitting={submitting}
      setVisible={setVisible}
      isProductModify={isModify}
      reload={reload}
    >
      <Form
        form={form}
        onFinish={submitHandle}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={submitting}
          >
            提交
          </Button>
        }
      >
        <Form.Header>编辑产品线信息</Form.Header>
        <Form.Item
          name="product"
          label="产品线"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择产品线" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingProductList}
            columns={
              productList
                ? [
                    productList
                      .filter((i) => i.title !== "UB" && i.title !== "DS")
                      .map((i) => ({
                        label:
                          i.product_name || `${i.title}(${i.chinese_name})`,
                        value: i.title,
                      })),
                  ]
                : []
            }
            onConfirm={(v) => {
              clear();
              setEditProduct((v[0] as string) || undefined);
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择产品线</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>

        <Form.Item
          name="product_status"
          label="授权状态"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择授权状态" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            columns={
              licenseStatus
                ? [
                    licenseStatus.map((i) => ({
                      label: i.label,
                      value: i.value,
                    })),
                  ]
                : []
            }
            onConfirm={(v) => {
              clear();
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择授权状态</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="is_product_customization"
          label="产品定制化"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择定制化情况" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
          disabled={updattingCustomizationStatus}
        >
          <Picker
            loading={updattingCustomizationStatus}
            columns={[
              [
                { label: "是", value: "1" },
                { label: "否", value: "0" },
              ],
            ]}
            onConfirm={(v) => {
              setIsModify(!isModify);
              if (v[0]) {
                updateCustomizationStatusHandle(v[0] as string);
              }
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择定制化情况</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="deploy_type"
          label="部署类型"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择部署类型" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingDeployTypeList}
            columns={
              deployTypeList
                ? [
                    deployTypeList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择部署类型</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="using_status"
          label="使用状态"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择使用状态" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingUsingStatusTypeList}
            columns={
              usingStatusTypeList
                ? [
                    usingStatusTypeList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择使用状态</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        {editProduct === RID ? (
          <Form.Item
            name="product_version_type"
            label="版本类型"
            trigger="onConfirm"
            normalize={(v) => DisposeForm(v)}
            onClick={(_, pickerRef: RefObject<PickerRef>) =>
              pickerRef.current?.open()
            }
          >
            <Picker
              columns={[
                CT_LUNG_VERSION_TYPE.map((i, index) => ({
                  key: index,
                  label: i,
                  value: i,
                })),
              ]}
            >
              {(items: (PickerColumnItem | null)[]) =>
                items[0] ? (
                  <FormItemDiv>{items[0]?.label}</FormItemDiv>
                ) : (
                  <FormItemPlaceholder>选择版本类型</FormItemPlaceholder>
                )
              }
            </Picker>
          </Form.Item>
        ) : null}
        <Form.Item
          name="product_version"
          label="安装分支"
          trigger="onConfirm"
          // validateTrigger="onConfirm"
          // rules={[{ required: true, message: "必须选择安装分支" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingProductVersionList}
            columns={
              productVersionList
                ? [
                    productVersionList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择分支版本</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="ub_version"
          label="UB版本"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择UB版本" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingUbVersionList}
            columns={
              ubVersionList
                ? [
                    ubVersionList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择UB版本</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="client_version"
          label="客户端版本"
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingProductClientList}
            columns={
              productClientList
                ? [
                    productClientList.map((i) => ({
                      label: i.product_client_version_name,
                      value: i.product_client_version_id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择客户端版本</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="online"
          label="上线时间"
          trigger="onConfirm"
          // validateTrigger="onConfirm"
          // rules={[{ required: true, message: "必须选择上线时间" }]}
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
        >
          <DatePicker tillNow max={now}>
            {(value) =>
              value ? (
                <FormItemDiv>{dayjs(value).format(DateFormate)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择上线时间</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>
        <Form.Item
          name="service_type"
          label="服务类型"
          trigger="onConfirm"
          // validateTrigger="onConfirm"
          // rules={[{ required: true, message: "必须选择服务类型" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingServiceTypeList}
            columns={
              serviceTypeList
                ? [
                    serviceTypeList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
            onConfirm={(v) =>
              setServiceType(v[0] ? parseInt(v[0] as string) : undefined)
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择服务类型</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="service_level"
          label="服务等级"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[
            {
              required: serviceType === 2 ? true : false,
              message: "必须选择服务等级",
            },
          ]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingServiceLevelTypeList}
            columns={
              serviceLevelTypeList
                ? [
                    serviceLevelTypeList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择服务等级</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="number"
          label="单日体量"
          normalize={(v) => DisposeForm(v)}
        >
          <Stepper min={0} step={1} style={{ float: "right", width: "40vw" }} />
        </Form.Item>
        <Form.Item
          name="threshold"
          label="模型阈值"
          normalize={(v) => DisposeForm(v)}
        >
          <Stepper
            max={1}
            min={0}
            step={0.01}
            style={{ float: "right", width: "40vw" }}
          />
        </Form.Item>
        <Form.Item
          name="departments"
          label="使用科室"
          normalize={(v) => DisposeForm(v)}
          layout="vertical"
        >
          {gettingUsingDepartmentTypeList ? (
            <Skeleton.Paragraph animated />
          ) : (
            <Selector
              columns={3}
              multiple
              options={
                usingDepartmentTypeList
                  ? usingDepartmentTypeList.map((i) => ({
                      label: i.name,
                      value: i.id,
                    }))
                  : []
              }
            />
          )}
        </Form.Item>
        {/* <Form.Header>维保时间</Form.Header>
        <Form.Item
          name="start"
          label="开始时间"
          trigger="onConfirm"
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
          disabled
        >
          <DatePicker>
            {(value) =>
              value ? (
                <FormItemDiv>{dayjs(value).format(DateFormate)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择维保开始时间</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>
        <Form.Item
          name="end"
          label="结束时间"
          trigger="onConfirm"
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
          disabled
        >
          <DatePicker>
            {(value) =>
              value ? (
                <FormItemDiv>{dayjs(value).format(DateFormate)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择维保结束时间</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item> */}
      </Form>
    </EditPopup>
  );
};
