import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
} from "react";
import { Spin } from "antd";
import { useDebounceFn } from "ahooks";

import { exeChildFunc } from "authenticated-app/pc/customer/customer-detail/interface";
import { useHttp } from "utils/http";
import { GET_PRODUCT_DETAIL } from "lib/api";
import { ProductBaseInfoType, ProductInfoType } from "./interface";
import ProductBaseInfo from "./product-base-info";
import { useAsync } from "utils/hooks/useAsync";

import "../../index.css";

const ProductTabPane = forwardRef<
  exeChildFunc,
  {
    hospID: number | null;
    setDisChange: Dispatch<SetStateAction<boolean>>;
    changeCount: any;
  }
>(({ hospID, setDisChange, changeCount }, ref) => {
  const client = useHttp();

  const {
    run,
    isLoading,
    data: detail,
  } = useAsync<[ProductInfoType[], ProductBaseInfoType[]]>();
  const getProductDetail = () => {
    if (hospID) {
      run(client(GET_PRODUCT_DETAIL(hospID)));
    }
  };
  const { run: getProductDetailDebounce } = useDebounceFn(getProductDetail, {
    wait: 200,
  });

  useImperativeHandle(ref, () => ({
    exeFunc() {
      getProductDetail();
    },
  }));

  useEffect(() => {
    getProductDetailDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospID]);

  return (
    <Spin spinning={isLoading}>
      <ProductBaseInfo
        hospID={hospID}
        detail={detail || undefined}
        reload={getProductDetail}
        setDisChange={setDisChange}
        changeCount={changeCount}
      />
    </Spin>
  );
});

export default ProductTabPane;
