import { Dispatch, SetStateAction, useEffect } from "react";
import { Button, Modal, Space, Form, message, Select, Spin } from "antd";

import { useHttp } from "utils/http";
import { DisposeForm } from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { GET_PACKAGE_LIST, UPDATE_FRONT_RESOURCES } from "../api";
import { GET_EMPLOYEE_TYPE } from "authenticated-app/pc/setting/customer-setting/api";
import { UserType } from "authenticated-app/pc/setting/customer-setting/interface";
import { ResourceRelationType, PackageType } from "../interface";

const { Option } = Select;

export const FrontPermissionSettingEdit = ({
  setting,
  visible,
  setVisible,
  reload,
}: {
  setting: ResourceRelationType[] | undefined | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getUserList,
    isLoading: gettingUserList,
    data: userList,
  } = useAsync<UserType[]>();
  const getUserListFunc = () => {
    getUserList(client(GET_EMPLOYEE_TYPE));
  };

  const {
    run: getPackageList,
    isLoading: gettingPackageList,
    data: packageList,
  } = useAsync<PackageType[]>();
  const getPackageListFunc = () => {
    getPackageList(client(GET_PACKAGE_LIST));
  };

  const submit = (value: { [key: number]: number[] } = {}) => {
    let subValue: ResourceRelationType[] = [];
    for (let [k, v] of Object.entries(value)) {
      subValue.push({
        user_type_id: parseInt(k),
        resourceIds: v,
      });
    }

    client(UPDATE_FRONT_RESOURCES, {
      data: subValue,
      method: "PUT",
    })
      .then(() => {
        message.success("修改成功！");
        setVisible(false);
        reload();
      })
      .catch((e) => {
        console.error(e);
        message.error("修改失败，请检查或联系管理员！");
      });
  };

  useEffect(() => {
    if (visible) {
      getUserListFunc();
      getPackageListFunc();
      let initValue: { [key: number]: number[] } = {};
      setting?.forEach((i) => (initValue[i.user_type_id] = i.resourceIds));
      form.setFieldsValue(initValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="编辑一线权限资源"
      destroyOnClose
      open={visible}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Spin spinning={gettingUserList}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          onFinish={submit}
        >
          {setting?.map((item) => {
            return (
              <Form.Item
                key={item.user_type_id}
                name={item.user_type_id}
                label={
                  `${userList?.find((i) => i.id === item?.user_type_id)
                    ?.name}权限` || ""
                }
                rules={[{ required: true, message: "至少选择一个权限" }]}
                normalize={(v) => DisposeForm(v)}
              >
                <Select
                  allowClear
                  loading={gettingPackageList}
                  mode="multiple"
                  placeholder="选择权限"
                >
                  {packageList?.map((i, index) => (
                    <Option key={index} value={i.packageId}>
                      {i.packageName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            );
          })}
          <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
            <Space>
              <Button htmlType="button" onClick={() => setVisible(false)}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
