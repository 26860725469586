import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-components";
import { Button, Col, Row, Select, Space, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDebounceFn, useMount } from "ahooks";

import { useHttp } from "utils/http";
import { HosNodesType } from "authenticated-app/pc/customer/customer-list/interface";
import { useAsync } from "utils/hooks/useAsync";
import { GET_HOSP_NODES } from "lib/api";
import { selectFilter } from "utils";
import { BaseValueType, StatisticsHospType } from "../interface";
import { GET_HOSP_MAINTENANCE, GET_HOSP_TYPE } from "../api";
import { MyPieChart, MyPieChartType } from "../chart/pie-chart";
import { GetHospTypePie } from "./calculate-value/hosp-type-pie";
import { GetHospTypeBar } from "./calculate-value/hosp-type-bar";
import { MyBarChart, MyBarChartType } from "../chart/bar-chart";
import { GetMaintenancePie } from "./calculate-value/maintenance-pie";
import { GetMaintenanceBar } from "./calculate-value/maintenance-bar";
import { useWindowSize } from "utils/hooks/useWindowSize";

const { Option } = Select;

export const UserInfo = () => {
  const client = useHttp();
  const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [provinceList, setProvinceList] = useState<HosNodesType[]>();
  const [searchArea, setSearchArea] = useState<number>();
  const [searchProvince, setSearchProvince] = useState<number>();

  const [hospType, setHospType] = useState<MyPieChartType>();
  const [publicType, setPublicType] = useState<MyBarChartType>();

  const [maintenancePie, setMaintenancePie] = useState<MyPieChartType>();
  const [maintenanceBar, setMaintenanceBar] = useState<MyBarChartType>();

  const { height } = useWindowSize();

  const {
    run: getHosNodes,
    isLoading: gettingNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };
  const { run: getHosNodesDebounce } = useDebounceFn(getHosNodesHandle, {
    wait: 200,
  });

  const {
    run: getHospType,
    isLoading: gettingHospType,
    data: hospTypeData,
  } = useAsync<StatisticsHospType[]>();
  const getHospTypeHandle = () => {
    getHospType(
      client(GET_HOSP_TYPE, {
        data: {
          area_id: searchArea,
          province_id: searchProvince,
        },
      }),
    );
  };
  const { run: getHospTypeDebounce } = useDebounceFn(getHospTypeHandle, {
    wait: 200,
  });

  const {
    run: getHospMaintenance,
    isLoading: gettingHospMaintenance,
    data: hospMaintenanceeData,
  } = useAsync<BaseValueType[]>();
  const getHospMaintenanceHandle = () => {
    getHospMaintenance(
      client(GET_HOSP_MAINTENANCE, {
        data: {
          area_id: searchArea,
          province_id: searchProvince,
        },
      }),
    );
  };
  const { run: getHospMaintenanceDebounce } = useDebounceFn(
    getHospMaintenanceHandle,
    { wait: 200 },
  );

  useEffect(() => {
    let provinceListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.parentNodeId === searchArea && item.parentNodeId !== null) {
        provinceListTMP?.push(item);
      }
    });
    setProvinceList(provinceListTMP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchArea]);

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.nodeLevel === 1 && item.nodeName !== "海外") {
        areaListTMP?.push(item);
      }
    });
    setAreaList(areaListTMP);
  }, [hosNodes]);

  useEffect(() => {
    if (hospTypeData) {
      setHospType(GetHospTypePie(hospTypeData));
      setPublicType(GetHospTypeBar(hospTypeData));
    }
  }, [hospTypeData]);

  useEffect(() => {
    if (hospMaintenanceeData) {
      setMaintenancePie(GetMaintenancePie(hospMaintenanceeData));
      setMaintenanceBar(GetMaintenanceBar(hospMaintenanceeData));
    }
  }, [hospMaintenanceeData]);

  useMount(() => {
    getHosNodesDebounce();
    getHospTypeDebounce();
    getHospMaintenanceDebounce();
  });

  const Loading = gettingNodes || gettingHospType || gettingHospMaintenance;

  return (
    <div style={{ height: "100%", minWidth: 988 }}>
      <Spin spinning={Loading}>
        <PageHeader
          style={{ padding: 0 }}
          extra={
            <Space>
              <Select
                allowClear
                style={{ width: 120 }}
                placeholder="区域"
                disabled={Loading}
                loading={Loading}
                value={searchArea}
                onChange={(nodeId) => {
                  setSearchProvince(undefined);
                  setSearchArea(nodeId);
                }}
              >
                {areaList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Select
                disabled={Loading}
                loading={Loading}
                value={searchProvince}
                allowClear
                placeholder="省份"
                style={{ width: 140 }}
                showSearch
                filterOption={selectFilter}
                onChange={(nodeId) => {
                  setSearchProvince(nodeId);
                }}
              >
                {provinceList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                loading={Loading}
                disabled={Loading}
                onClick={() => {
                  getHospTypeHandle();
                  getHospMaintenanceHandle();
                }}
              >
                查询
              </Button>
            </Space>
          }
        />
        <Row
          style={{ height: (height - 256) / 2, minHeight: 380, width: "100%" }}
        >
          <Col span={10}>
            <MyPieChart Data={hospType} />
          </Col>
          <Col span={14}>
            <MyBarChart Data={publicType} />
          </Col>
        </Row>
        <Row
          style={{ height: (height - 256) / 2, minHeight: 380, width: "100%" }}
        >
          <Col span={10}>
            <MyPieChart Data={maintenancePie} />
          </Col>
          <Col span={14}>
            <MyBarChart Data={maintenanceBar} />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
