import { useEffect, useState, useRef } from "react";
import { Button, Input, Space, Table, Divider, Badge, Tabs } from "antd";
import type { TabsProps } from "antd";
import {
  PageHeader,
  ProTable,
  ProColumns,
  ActionType,
} from "@ant-design/pro-components";
import { SearchOutlined, RedoOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useDebounceFn, useMount } from "ahooks";
import { hosStatus, statusColor } from "utils";
import { HospListType, HOSP_LIST_TYPE } from "interface";
import { defaultUserStatus, trimStr } from "utils";
import { HosRateDetail } from "./hos-detail";
import { HosPredictDetail } from "./hos-predict-detail";

import { HosPredictTime } from "./hos-predict-time";
import { HosPrintQueueInfo } from "./hos-print-queue-info";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { HOSP_LIST_API } from "lib/api";

import "./index.css";

export const RateDetail = () => {
  const [{ rate_id }, setRate] = useUrlQueryParam(["rate_id"]);
  const { height } = useWindowSize();
  const client = useHttp();

  const [pageSize, setPageSize] = useState(10);
  const [searchInput, setSearchInput] = useState<string>();
  const [hospInfo, setHospInfo] = useState<HospListType>();
  const [activeKey, setActiveKey] = useState("rate");
  const actionRef = useRef<ActionType>();

  useEffect(() => {
    const size = Math.trunc((height - 167) / 65);
    setPageSize(size > 5 ? size : 5);
  }, [height]);

  useEffect(() => {
    if (rate_id) {
      setActiveKey("rate");
    }
  }, [rate_id]);

  const columns: ProColumns<HospListType>[] = [
    {
      title: (
        <Space>
          <Input
            value={searchInput}
            size={"small"}
            allowClear
            placeholder="输入名称"
            onPressEnter={(v) => {
              actionRef.current?.reload();
            }}
            onChange={(v) => setSearchInput(v.target.value)}
          />
          <Button
            icon={<RedoOutlined />}
            onClick={() => {
              setSearchInput(() => {
                actionRef.current?.reload();
                return "";
              });
            }}
            size={"small"}
          />
          <Button
            icon={<SearchOutlined />}
            onClick={() => actionRef.current?.reload()}
            size={"small"}
            type={"primary"}
          />
        </Space>
      ),
      dataIndex: "name",
      width: "100%",
      render: (text) => (
        <div style={{ height: "40px", lineHeight: "40px", overflow: "hidden" }}>
          {text}
        </div>
      ),
    },
  ];

  const tabItems: TabsProps["items"] = [
    {
      key: "rate",
      label: "点击率",
      children: <HosRateDetail hospInfo={hospInfo} stateKey={activeKey} />,
    },
    {
      key: "predict",
      label: "预测统计",
      children: <HosPredictDetail hospInfo={hospInfo} stateKey={activeKey} />,
    },
    {
      key: "predict_time",
      label: "预测耗时",
      children: <HosPredictTime hospInfo={hospInfo} stateKey={activeKey} />,
    },
    {
      key: "print",
      label: "推送打印",
      children: <HosPrintQueueInfo hospInfo={hospInfo} stateKey={activeKey} />,
    },
  ];

  return (
    <div
      style={{
        height: "100%",
        minWidth: "928px",
        display: "flex",
      }}
    >
      <ProTable<HospListType>
        actionRef={actionRef}
        columns={columns}
        request={async (params, sort, filter) => {
          const {
            pageSize = 10,
            current = 1,
            hospital_status,
            ...rest
          } = params;
          const result = await client(HOSP_LIST_API, {
            data: {
              pageSize,
              current,
              ...rest,
              ...(searchInput ? { name: searchInput } : {}),
              ...(hospital_status?.length
                ? { hospital_status: hospital_status.join("|") }
                : {}),
            },
          });
          if (result.results && result.results.length > 0) {
            const firstItem = result.results[0];
            setHospInfo(firstItem);
            setRate({ rate_id: firstItem.id.toString() });
          }
          return {
            data: result.results || [],
            total: result.count || 0,
            success: true,
          };
        }}
        rowKey={"id"}
        style={{ height: "100%", width: "248px", minWidth: "248px" }}
        size="middle"
        onRow={(record) => {
          return {
            onClick: () => {
              setRate({ rate_id: record.id });
              setHospInfo(record);
            },
          };
        }}
        search={false}
        toolBarRender={false}
        rowClassName={(record) =>
          record.id === parseInt(rate_id) ? "selectedRow" : ""
        }
        pagination={{
          simple: true,
          showQuickJumper: true,
          showSizeChanger: false,
          pageSize: pageSize,
          showTotal: () => null,
        }}
      />
      <Divider style={{ height: "100%" }} type="vertical" />
      <div
        style={{
          height: "100%",
          flex: 1,
        }}
      >
        <PageHeader
          title={`${hospInfo?.name || "未知"} ${hospInfo?.code || "未知"}`}
          subTitle={
            <span style={{ fontWeight: "bolder" }}>
              <Badge
                style={{ marginLeft: "10px", color: "#5B5B5B" }}
                color={statusColor.get(
                  (hospInfo?.hospital_status as hosStatus) || hosStatus.unknown,
                )}
                text={hospInfo?.hospital_status || "未知"}
              />
            </span>
          }
          style={{ paddingLeft: "0" }}
        />
        <Tabs
          activeKey={activeKey}
          size="small"
          style={{ marginLeft: "6px" }}
          onChange={(v) => {
            setActiveKey(v);
          }}
          items={tabItems}
        />
      </div>
    </div>
  );
};
