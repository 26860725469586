import { Fragment } from "react";
import { Badge, Descriptions } from "antd";
import { ProductMaintenanceType as InfoType } from "../interface";
import { MaintenanceStatusColor } from "utils";

const keyHeader = "ProductManitenancInfo";

const ProductManitenancInfo = ({
  detail,
}: {
  detail: InfoType[] | undefined | null;
}) => {
  const RetainInfoDescItem = (item: InfoType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`${keyHeader}-${key}`}>
        <Descriptions.Item label={title("合同编号")} className={className()}>
          {item?.contract_number || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("销售产品线")} className={className()}>
          {item?.products || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("验收日期")} className={className()}>
          {item?.reception_time || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("维保年限")} className={className()}>
          {item?.period_of_maintenance
            ? `
                  ${
                    Math.trunc(item?.period_of_maintenance / 12)
                      ? Math.trunc(item?.period_of_maintenance / 12) + "年"
                      : ""
                  }
                  ${
                    Math.trunc(item?.period_of_maintenance % 12)
                      ? Math.trunc(item?.period_of_maintenance % 12) + "个月"
                      : ""
                  }
                `
            : ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("维保到期日")} className={className()}>
          {item?.expiration_time || ""}
        </Descriptions.Item>
        <Descriptions.Item
          label={title("维保状态")}
          className={className()}
          style={{ textAlign: "center" }}
        >
          {item?.maintenance_status ? (
            <Badge
              style={{ marginLeft: "10px", color: "#5B5B5B" }}
              color={MaintenanceStatusColor.get(
                item?.maintenance_status || "未知",
              )}
              text={item?.maintenance_status || "未知"}
            />
          ) : (
            ""
          )}
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Descriptions
        column={6}
        bordered={detail?.length ? true : false}
        title="维保信息"
        layout="vertical"
      >
        {detail?.map((item, index) => RetainInfoDescItem(item, index))}
      </Descriptions>
    </Fragment>
  );
};

export default ProductManitenancInfo;
