import { useEffect, RefObject, useState } from "react";
import { useAuth } from "context/auth";
import { useDebounceFn } from "ahooks";
import {
  Form,
  Button,
  DatePicker,
  DatePickerRef,
  Picker,
  Divider,
  TextArea,
} from "antd-mobile";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";

import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { DisposeForm, FormatDayjs, FormatDate, FormatTime } from "utils";
import {
  DELIVERY_DETAIL_TYPE,
  DELIVERY_USER_TYPE,
  NEXT_PROCESS_TYPE,
  DELIVERY_RESCHEDULING_REASON_LIST_TYPE,
} from "lib/interface/launchChangeList";

import {
  GET_DELIVERY_USER_LIST,
  GET_DELIVERY_RESCHEDULING_REASON_TYPE,
} from "lib/api/deliverylist";

import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";
import { useDeliveryDetailReScheduled } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailReScheduled";

export const DeliveryDetailReScheduledModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailReScheduled();
  const [isHistoryCustomization, setIsHistoryCustomization] =
    useState<number>(0);
  const [form] = Form.useForm();
  const { user } = useAuth();
  const client = useHttp();
  const {
    run: getDeliveryUserList,
    data: deliveryUserList,
    isIdle: getDeliveryUserListIdle,
    isLoading: gettingDeliveryUserList,
  } = useAsync<DELIVERY_USER_TYPE[]>();
  const getDeliveryUserListHandle = () => {
    getDeliveryUserList(client(GET_DELIVERY_USER_LIST));
  };
  const { run: getDeliveryUserListDebounce } = useDebounceFn(
    getDeliveryUserListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getDeliveryRescheduleReasonList,
    data: deliverRescheduleReasonList,
    isIdle: getDeliveryRescheduleReasonListIdle,
    isLoading: gettingDeliverRescheduleReasonList,
  } = useAsync<DELIVERY_RESCHEDULING_REASON_LIST_TYPE[]>();
  const getDeliveryRescheduleReasonListHandle = () => {
    getDeliveryRescheduleReasonList(
      client(GET_DELIVERY_RESCHEDULING_REASON_TYPE),
    );
  };
  const { run: getDeliveryRescheduleReasonListDebounce } = useDebounceFn(
    getDeliveryRescheduleReasonListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      delivery_user_id: value.delivery_user_id[0],
      rescheduling_reason_type_id: value.rescheduling_reason_type_id[0],
      planned_delivery_date: FormatDate(value.planned_delivery_date),
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      const delivery_user_id = detailInfo?.delivery_user_id
        ? detailInfo?.delivery_user_id
        : user?.id;
      form.setFieldsValue({
        delivery_user_id: [delivery_user_id],
        planned_delivery_date: detailInfo?.planned_delivery_date
          ? FormatDayjs(detailInfo?.planned_delivery_date).toDate()
          : null,
        // rescheduling_reason_type_id: detailInfo?.rescheduling_reason_type_id,
        // rescheduling_reason: detailInfo?.rescheduling_reason,
      });
      getDeliveryUserListDebounce();
      getDeliveryRescheduleReasonListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <EditPopup visible={isOpen} submitting={false} setVisible={close}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={false}
          >
            提交
          </Button>
        }
      >
        <Form.Header>重新排期</Form.Header>
        <Form.Item
          name="delivery_user_id"
          label="交付负责人"
          rules={[{ required: true, message: "必须指定交付负责人" }]}
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={getDeliveryUserListIdle || gettingDeliveryUserList}
            columns={
              deliveryUserList
                ? [
                    deliveryUserList.map((i) => ({
                      label: `${i.userName} (${i.jobTitle})`,
                      value: i.id,
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>指定交付负责人</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="planned_delivery_date"
          label="计划交付日期"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择计划交付日期" }]}
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
          layout="horizontal"
        >
          <DatePicker title="计划交付日期" precision="day" tillNow>
            {(value) =>
              value ? (
                <FormItemDiv>{FormatDate(value)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>计划交付日期</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>
        <Form.Item
          name="rescheduling_reason_type_id"
          label="重新排期分类"
          rules={[{ required: true, message: "必须选择重新排期原因分类" }]}
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={
              getDeliveryRescheduleReasonListIdle ||
              gettingDeliverRescheduleReasonList
            }
            columns={
              deliverRescheduleReasonList
                ? [
                    deliverRescheduleReasonList.map((i) => ({
                      label: `${i.name}`,
                      value: i.id,
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>指定重新排期分类</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="rescheduling_reason"
          label="重新排期原因"
          rules={[{ required: true, message: "必须填写重新排期原因" }]}
        >
          <TextArea placeholder="填写重新排期原因" autoSize={{ minRows: 3 }} />
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
