import { useState, useRef, useEffect } from "react";
import type { BaseSelectRef } from "rc-select";
import { Form, Select } from "antd";

import { BoolValue } from "lib/interface/base";

import "./index.css";

const boolOption = [
  { value: 1, label: "是" },
  { value: 0, label: "否" },
];

const getBoolValue = (value?: string | number) => {
  return value === 1 || value === "1"
    ? "是"
    : value === 0 || value === "0"
    ? "否"
    : "未知";
};

export const UpdateBoolSelect = ({
  onUpdate,
  value,
  label,
  name,
  required,
  isCanChange,
}: {
  onUpdate: (value: any) => void;
  value?: BoolValue | string;
  label: string;
  name: string;
  required?: boolean;
  isCanChange?: boolean;
}) => {
  const [form] = Form.useForm();

  const [isEditting, setIsEditting] = useState(false);
  const [isFoucs, setIsFoucs] = useState(false);

  const ref = useRef<BaseSelectRef>(null);

  const onFinish = (value: any) => {
    onUpdate(value);
    ref.current?.blur();
  };

  useEffect(() => {
    form.setFieldsValue({
      [name]: value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Form
      form={form}
      className="update-bool-form"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label={label}
        name={name}
        rules={[{ required: required, message: `必须选择${label}` }]}
        style={{ width: "100%" }}
      >
        {isCanChange ? (
          <Select
            ref={ref}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            placeholder={`选择${label}`}
            bordered={isEditting}
            suffixIcon={isEditting ? undefined : null}
            onChange={form.submit}
            onFocus={() => setIsFoucs(true)}
            onBlur={() => {
              setIsFoucs(false);
              setIsEditting(false);
            }}
            onMouseEnter={() => setIsEditting(true)}
            onMouseLeave={() => {
              if (isFoucs) return;
              setIsEditting(false);
            }}
            options={boolOption}
          />
        ) : (
          getBoolValue(value)
        )}
      </Form.Item>
    </Form>
  );
};
