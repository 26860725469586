import { useEffect } from "react";
import { Button, Card, Divider, Result, Skeleton, Tabs } from "antd";
import type { TabsProps } from "antd";
import {
  AuditOutlined,
  UsergroupAddOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

import {
  GET_PACKAGE_LIST,
  DEL_PACKAGE,
  UPDATE_PACKAGE,
  ADD_PACKAGE,
  GET_PERMISSION_ASSOSIATION,
  ADD_PERMISSION_ASSOSIATION,
  UPDATE_PERMISSION_ASSOSIATION,
  DEL_PERMISSION_ASSOSIATION,
} from "./api";
import FrontPermissionSetting from "./front-permission-setting";
import ResourceSetting from "./front-resource-setting";
import PermissionListSetting from "./permission-list-setting";
import PermissionAssociationSetting from "./permission-association-setting";
import { FrontAuthSetting } from "./front-auth-setting";
import { LoadingDiv } from "components";
import { usePermission } from "context/permission";
import DepartmentSetting from "./department-setting";

const defaultTab = "resource";

export const PermissionSettingPage = () => {
  const { permission } = usePermission();

  const [{ tab }, setTab] = useUrlQueryParam(["tab"]);

  useEffect(() => {
    if (!!!tab) {
      setTab({ tab: defaultTab });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const items: TabsProps["items"] = [
    {
      key: "resource",
      label: (
        <span>
          <AuditOutlined />
          资源类
        </span>
      ),
      children: (
        <LoadingDiv>
          <FrontPermissionSetting />
          <Divider />
          <ResourceSetting />
        </LoadingDiv>
      ),
    },
    {
      key: "permission",
      label: (
        <span>
          <UsergroupAddOutlined />
          权限类
        </span>
      ),
      children: (
        <LoadingDiv>
          <FrontAuthSetting />
          <Divider />
          <PermissionListSetting
            ctrl={{
              GET_SETTING: GET_PACKAGE_LIST,
              ADD_SETTING: ADD_PACKAGE,
              UPDATE_SETTING: UPDATE_PACKAGE,
              DEL_SETTING: DEL_PACKAGE,
            }}
          />
          <Divider />
          <PermissionAssociationSetting
            ctrl={{
              GET_SETTING: GET_PERMISSION_ASSOSIATION,
              ADD_SETTING: ADD_PERMISSION_ASSOSIATION,
              UPDATE_SETTING: UPDATE_PERMISSION_ASSOSIATION,
              DEL_SETTING: DEL_PERMISSION_ASSOSIATION,
            }}
          />
        </LoadingDiv>
      ),
    },
    {
      key: "role",
      label: (
        <span>
          <TeamOutlined />
          角色配置
        </span>
      ),
      children: (
        <LoadingDiv>
          <DepartmentSetting />
        </LoadingDiv>
      ),
    },
  ];

  return permission?.hasPermissionEdit === 1 ? (
    <Card
      style={{ height: "100%", minWidth: 988 }}
      title={<span style={{ fontWeight: "bolder" }}>配置页</span>}
    >
      <Tabs
        activeKey={tab}
        tabPosition="left"
        items={items}
        onChange={(v) => setTab({ tab: v })}
      />
    </Card>
  ) : permission?.hasPermissionEdit === 0 ? (
    <Result
      status="403"
      title="403"
      subTitle="抱歉，你无权限访问此页面！"
      extra={
        <Button
          type="primary"
          onClick={() => window.location.replace(window.location.origin)}
        >
          返回主页
        </Button>
      }
    />
  ) : (
    <Skeleton active />
  );
};
