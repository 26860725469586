import { ReactNode } from "react";
import { Card } from "antd-mobile";

export const ContentCard = ({
  children,
  style = { margin: 10, padding: 0, borderRadius: 10 },
}: {
  children: ReactNode;
  style?: (React.CSSProperties & Partial<Record<never, string>>) | undefined;
}) => {
  return <Card style={style}>{children}</Card>;
};
