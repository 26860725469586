import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useBreakDetailReverse = () => {
  const [{ openBreakDetailReverse }, setOpenBreakDetailReverse] =
    useUrlQueryParam(["openBreakDetailReverse"]);

  const open = () =>
    setOpenBreakDetailReverse({ openBreakDetailReverse: true });
  const close = () =>
    setOpenBreakDetailReverse({
      openBreakDetailReverse: undefined,
    });

  return [openBreakDetailReverse === "true", open, close] as const;
};
