import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
} from "react";
import { Divider, Spin } from "antd";
import { useDebounceFn } from "ahooks";

import { exeChildFunc } from "authenticated-app/pc/customer/customer-detail/interface";
import BaseInfo from "./base-info";
import Insidernfo from "./insider-info";
import HosUserInfo from "./hos-user-info";
import BaseRetainInfo from "./base-retain-info";
import { useHttp } from "utils/http";
import {
  ADD_RETAIN,
  DEL_RETAIN,
  GET_HOSP_DETAIL,
  UPDATE_RETAIN,
} from "lib/api";
import { DetailType } from "./interface";
import { useAsync } from "utils/hooks/useAsync";

import "../../index.css";

const InfoTabPane = forwardRef<
  exeChildFunc,
  {
    hospID: number | null;
    setDisChange: Dispatch<SetStateAction<boolean>>;
  }
>(({ hospID, setDisChange }, ref) => {
  const client = useHttp();

  const { run, isLoading, data: detail } = useAsync<DetailType>();
  const getHospDetail = () => {
    if (hospID) {
      run(client(GET_HOSP_DETAIL(hospID)));
    }
  };
  const { run: getHospDetailDebounce } = useDebounceFn(getHospDetail, {
    wait: 200,
  });

  useImperativeHandle(ref, () => ({
    exeFunc() {
      getHospDetail();
    },
  }));

  useEffect(() => {
    getHospDetailDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospID]);

  return (
    <Spin spinning={isLoading}>
      <BaseInfo
        detail={detail || undefined}
        reload={getHospDetail}
        setDisChange={setDisChange}
      />
      <Divider />

      <Insidernfo
        detail={detail || undefined}
        reload={getHospDetail}
        setDisChange={setDisChange}
      />
      <Divider />

      <HosUserInfo
        detail={detail || undefined}
        reload={getHospDetail}
        setDisChange={setDisChange}
      />
      <Divider />

      <BaseRetainInfo
        orgID={hospID}
        retain={detail?.retain_infos}
        RetainAPI={{
          ADD_RETAIN: ADD_RETAIN,
          UPDATE_RETAIN: UPDATE_RETAIN,
          DEL_RETAIN: DEL_RETAIN,
        }}
        reload={getHospDetail}
      />
    </Spin>
  );
});

export default InfoTabPane;
