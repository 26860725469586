import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useDeliveryDetailRestart = () => {
  const [{ openDeliveryDetailRestart }, setopenDeliveryDetailRestart] =
    useUrlQueryParam(["openDeliveryDetailRestart"]);

  const open = () =>
    setopenDeliveryDetailRestart({ openDeliveryDetailRestart: true });
  const close = () =>
    setopenDeliveryDetailRestart({
      openDeliveryDetailRestart: undefined,
    });

  return [openDeliveryDetailRestart === "true", open, close] as const;
};
