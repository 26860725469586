import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import {
  GET_PRODUCT_TYPE,
  GET_PRODUCT_CLIENT_VERSION_LIST,
  GET_PRODUCT_BRANCH,
} from "../api";
import { ProductBranch, ProductClientVersion, ProductType } from "../interface";
import { DisposeForm, selectFilter, trimStr } from "utils";

const { Option } = Select;

export const ProductClientSettingAdd = ({
  submitAPI,
  visible,
  setVisible,
  reload,
}: {
  submitAPI: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [addProductID, setAddProductID] = useState<string>();
  const [addClientItem, setAddClientItem] = useState<string[]>([]);
  const [newClient, setNewClient] = useState<string | null>(null);

  const initState = () => {
    setAddProductID(undefined);
    setAddClientItem([]);
    setNewClient(null);
    setBranchList([]);
  };

  const {
    run: getProductList,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductType[]>();
  const getProductListFunc = () => {
    getProductList(client(GET_PRODUCT_TYPE));
  };

  const {
    run: getBranchList,
    isLoading: gettingBranchList,
    data: branchList,
    setData: setBranchList,
  } = useAsync<ProductBranch[]>();
  const getBranchListFunc = (product_id?: string) => {
    getBranchList(client(GET_PRODUCT_BRANCH(product_id || null)));
  };

  const {
    run: getClientList,
    isLoading: gettingClientList,
    // data: clientList,
  } = useAsync<ProductType[]>();
  const getClientListFunc = (product_id: string) => {
    getClientList(client(GET_PRODUCT_CLIENT_VERSION_LIST(product_id))).then(
      (res: ProductClientVersion[]) => {
        if (res) {
          let arr: string[] = res?.map((i) => i.product_client_version_name);
          setAddClientItem(arr);
        }
      },
    );
  };

  const newClientHandle = (e: React.MouseEvent) => {
    e.preventDefault();
    let client = trimStr(newClient);
    if (client) {
      if (addClientItem.find((i) => i === client)) {
        message.warning("重复添加，请检查！");
      } else {
        setAddClientItem([...addClientItem, client]);
        setNewClient(null);
      }
    }
  };

  const clearBranch = () => {
    form.setFieldsValue({
      product_version_list: undefined,
    });
  };

  const clearClient = () => {
    setNewClient(null);
    form.setFieldsValue({
      product_client_version_name: undefined,
    });
  };

  const submit = (value: any) => {
    client(submitAPI, {
      data: {
        ...value,
      },
      method: "POST",
    })
      .then(() => {
        message.success("添加成功！");
        setVisible(false);
        reload();
      })
      .catch((e) => {
        console.error(e);
        message.error("添加失败，请检查或联系管理员！");
      });
  };

  useEffect(() => {
    if (addProductID) {
      getClientListFunc(addProductID);
      getBranchListFunc(addProductID);
    } else {
      setAddClientItem([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProductID]);

  useEffect(() => {
    if (visible) {
      initState();
      form.resetFields();
      if (!!!productList) {
        getProductListFunc();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="新增产品线客户端"
      destroyOnClose
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="product_id"
          label="产品线"
          rules={[{ required: true, message: "必须选择产品线" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingProductList}
            placeholder="选择产品线"
            showSearch
            filterOption={selectFilter}
            onChange={(e) => {
              setAddProductID(e);
              clearBranch();
              clearClient();
            }}
          >
            {productList?.map((i, index) => (
              <Option key={index} value={i.title}>
                {i.chinese_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="product_version_list"
          label="产品分支"
          rules={[{ required: true, message: "必须选择产品分支" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingBranchList}
            placeholder="选择产品分支"
            mode="multiple"
            showSearch
            filterOption={selectFilter}
          >
            {branchList?.map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="product_client_version_name"
          label="客户端版本"
          rules={[{ required: true, message: "必须输入客户端版本" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingClientList}
            placeholder="选择客户端版本"
            showSearch
            filterOption={selectFilter}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    allowClear
                    maxLength={50}
                    value={newClient || undefined}
                    onChange={(e) => setNewClient(e.target.value)}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={newClientHandle}
                  >
                    添加版本
                  </Button>
                </Space>
              </>
            )}
          >
            {addClientItem?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
