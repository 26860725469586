import { PageHeader } from "@ant-design/pro-components";
import {
  Button,
  DatePicker,
  Divider,
  Segmented,
  Select,
  Space,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useDebounceFn, useMount } from "ahooks";

import { DisabledDate, formatDate, RID, selectFilter, THID } from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import {
  GET_HOSP_CITY,
  GET_HOSP_NODES,
  GET_PRODUCT_LIST,
  GET_RATE,
} from "lib/api";
import { useEffect, useState } from "react";
import {
  DateStep,
  RateType,
} from "authenticated-app/pc/customer/rate-detail/interface";
import { Calculate } from "authenticated-app/pc/customer/rate-detail/hos-detail/calculate";
import { RateLineChart } from "authenticated-app/pc/customer/rate-detail/hos-detail/rateLineChart";
import {
  CityType,
  HosNodesType,
} from "authenticated-app/pc/customer/customer-list/interface";
import { ProductType } from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const ProductRate = () => {
  const [product, setProduct] = useState<string | null>(null);
  const [queryStep, setQueryStep] = useState<DateStep>(DateStep.day);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(
    dayjs().subtract(30, "days"),
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());

  const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [provinceList, setProvinceList] = useState<HosNodesType[]>();
  const [searchArea, setSearchArea] = useState<number>();
  const [searchProvince, setSearchProvince] = useState<number>();
  const [searchCity, setSearchCity] = useState<number>();

  const client = useHttp();

  const {
    run: getHospRate,
    isLoading: gettingHospRate,
    data: hospRate,
  } = useAsync<RateType>();
  const getHospRateHandle = ({
    product_line,
    unit,
    start,
    end,
  }: {
    product_line?: string;
    unit?: DateStep;
    start?: dayjs.Dayjs;
    end?: dayjs.Dayjs;
  }) => {
    getHospRate(
      client(GET_RATE, {
        data: {
          area: searchArea,
          province: searchProvince,
          city: searchCity,
          product_line: product_line || product,
          unit: unit || queryStep,
          start: formatDate(start) || formatDate(startDate),
          end: formatDate(end) || formatDate(endDate),
        },
      }),
    );
  };

  const {
    run: getHosNodes,
    isLoading: gettingNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };
  const { run: getHosNodesDebounce } = useDebounceFn(getHosNodesHandle, {
    wait: 200,
  });

  const {
    run: getProductList,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductType[]>();
  const getProductListHandle = () => {
    getProductList(client(GET_PRODUCT_LIST));
  };
  const { run: getProductListDebounce } = useDebounceFn(getProductListHandle, {
    wait: 200,
  });

  const {
    run: getCityList,
    isLoading: gettingCityList,
    data: cityList,
    setData: setCityList,
  } = useAsync<CityType[]>();
  const getCityListFunc = (province_id: number | null | undefined) => {
    if (province_id) {
      getCityList(client(GET_HOSP_CITY(province_id)));
    }
  };

  useEffect(() => {
    getCityListFunc(searchProvince);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProvince]);

  useEffect(() => {
    let provinceListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.parentNodeId === searchArea && item.parentNodeId !== null) {
        provinceListTMP?.push(item);
      }
    });
    setProvinceList(provinceListTMP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchArea]);

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.nodeLevel === 1 && item.nodeName !== "海外") {
        areaListTMP?.push(item);
      }
    });
    setAreaList(areaListTMP);
  }, [hosNodes]);

  useEffect(() => {
    if (productList) {
      if (!product) {
        setProduct(RID);
        getHospRateHandle({ product_line: RID });
      } else {
        getHospRateHandle({ product_line: product });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList]);

  useMount(() => {
    getHosNodesDebounce();
    getProductListDebounce();
  });

  const loadingData = gettingProductList || gettingHospRate;

  return (
    <div style={{ height: "100%", minWidth: 988 }}>
      <Spin spinning={loadingData}>
        <PageHeader
          style={{ padding: 0 }}
          title={
            <Space>
              <Select
                disabled={loadingData}
                loading={loadingData}
                value={product}
                placeholder="选择产品线"
                style={{ width: 140 }}
                showSearch
                filterOption={selectFilter}
                onChange={(v) => {
                  setProduct(v);
                  getHospRateHandle({ product_line: v });
                }}
              >
                {productList?.map((i, index) => (
                  <Option key={index} value={i?.title}>
                    {i?.chinese_name}
                  </Option>
                ))}
              </Select>
              <RangePicker
                disabled={loadingData}
                allowClear={false}
                disabledDate={DisabledDate}
                value={[startDate, endDate]}
                style={{ width: 240 }}
                onChange={(v) => {
                  if (v) {
                    setStartDate(v[0] || dayjs());
                    setEndDate(v[1] || dayjs());
                  }
                }}
                onOpenChange={(open) => {
                  if (!open) {
                    getHospRateHandle({});
                  }
                }}
              />
              <Segmented
                disabled={loadingData}
                options={[
                  { label: "日", value: DateStep.day },
                  { label: "周", value: DateStep.week },
                  { label: "月", value: DateStep.month },
                  { label: "年", value: DateStep.year },
                ]}
                value={queryStep}
                onChange={(v) => {
                  setQueryStep(v as DateStep);
                  getHospRateHandle({ unit: v as DateStep });
                }}
              />
            </Space>
          }
          extra={
            <Space>
              <Select
                allowClear
                style={{ width: 140 }}
                placeholder="区域"
                disabled={gettingNodes}
                loading={gettingNodes}
                value={searchArea}
                onChange={(nodeId) => {
                  setSearchProvince(undefined);
                  setCityList([]);
                  setSearchCity(undefined);
                  setSearchArea(nodeId);
                }}
              >
                {areaList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Select
                disabled={gettingNodes}
                loading={gettingNodes}
                value={searchProvince}
                showSearch
                allowClear
                placeholder="省份"
                style={{ width: 140 }}
                filterOption={selectFilter}
                onChange={(nodeId) => {
                  setSearchCity(undefined);
                  setSearchProvince(nodeId);
                }}
              >
                {provinceList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Select
                loading={gettingCityList}
                disabled={gettingCityList}
                value={searchCity}
                showSearch
                allowClear
                filterOption={selectFilter}
                style={{ width: 140 }}
                onChange={(v) => {
                  setSearchCity(v);
                }}
              >
                {cityList?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.cityName}
                  </Option>
                ))}
              </Select>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                loading={loadingData}
                disabled={loadingData}
                onClick={() => getHospRateHandle({})}
              >
                查询
              </Button>
            </Space>
          }
        />
        <Divider />
        <Calculate data={hospRate?.calculate} isTh={product === THID} />
        <Divider />
        <RateLineChart
          data={hospRate?.results}
          unit={hospRate?.unit || DateStep.day}
          isTh={product === THID}
          product={product}
          isDownload={false}
          chartHeight={600}
        />
      </Spin>
    </div>
  );
};
