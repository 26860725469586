import { UploadFile, message } from "antd";
import { RcFile } from "antd/es/upload";

import { isImageFormat } from "authenticated-app/mobile/util";
import { Dispatch, SetStateAction } from "react";
import { getBase64 } from "utils";

export const handlePreview = async (
  file: UploadFile,
  dispatch: {
    setPreviewImage: Dispatch<SetStateAction<string>>;
    setPreviewOpen: Dispatch<SetStateAction<boolean>>;
    setPreviewTitle: Dispatch<SetStateAction<string>>;
  },
) => {
  if (!!!isImageFormat(file.name)) {
    message.warning("非图片文件, 无法预览");
    return;
  }
  if (!file.url && !file.preview) {
    file.preview = await getBase64(file.originFileObj as RcFile);
  }

  dispatch.setPreviewImage(file.url || (file.preview as string));
  dispatch.setPreviewOpen(true);
  dispatch.setPreviewTitle(
    file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1),
  );
};
