import { useEffect, useState, RefObject } from "react";
import {
  Form,
  Button,
  TextArea,
  DatePicker,
  DatePickerRef,
  Picker,
  Radio,
} from "antd-mobile";
import { useDebounceFn } from "ahooks";
import { useAuth } from "context/auth";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";
import {
  NEXT_PROCESS_TYPE,
  DELIVERY_DETAIL_TYPE,
  DELIVERY_USER_TYPE,
} from "lib/interface/launchChangeList";
import { FormatDayjs, FormatDate, DisposeForm, FormatTime } from "utils";
import { useDeliveryDetailTransfer } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailTransfer";
import { GET_DELIVERY_USER_LIST } from "lib/api/deliverylist";
import { useDeliveryDetailStop } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailStop";
import { RadioValue } from "antd-mobile/es/components/radio";
export const DeliveryDetailStopModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailStop();
  const [form] = Form.useForm();
  const { user } = useAuth();
  const client = useHttp();
  const [suppliesReturn, setSuppliesReturn] = useState<
    number | null | RadioValue
  >(null);
  const {
    run: getDeliveryUserList,
    data: deliveryUserList,
    isIdle: getDeliveryUserListIdle,
    isLoading: gettingDeliveryUserList,
  } = useAsync<DELIVERY_USER_TYPE[]>();
  const getDeliveryUserListHandle = () => {
    getDeliveryUserList(client(GET_DELIVERY_USER_LIST));
  };
  const { run: getDeliveryUserListDebounce } = useDebounceFn(
    getDeliveryUserListHandle,
    {
      wait: 200,
    },
  );

  const childinfo = () => {
    switch (detailInfo?.delivery_type_name) {
      case "销售交付":
      case "试用交付":
      case "渠道交付":
        return (
          <>
            <Form.Item
              name="is_need_send_back"
              label="是否退回硬件"
              rules={[{ required: true, message: "必须选择是否需要退回硬件" }]}
            >
              <Radio.Group
                onChange={(v) => {
                  setSuppliesReturn(v);
                }}
              >
                <Radio value={1} style={{ marginLeft: "8px" }}>
                  {" "}
                  是
                </Radio>
                <Radio value={0} style={{ marginLeft: "16px" }}>
                  否
                </Radio>
              </Radio.Group>
            </Form.Item>
            {suppliesReturn === 1 ? (
              <>
                <Form.Item
                  name="planned_return_date"
                  label="计划退回日期"
                  trigger="onConfirm"
                  validateTrigger="onConfirm"
                  rules={[{ required: true, message: "必须选择计划退回日期" }]}
                  onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
                    datePickerRef.current?.open();
                  }}
                  layout="horizontal"
                >
                  <DatePicker title="计划退回日期" precision="day" tillNow>
                    {(value) =>
                      value ? (
                        <FormItemDiv>{FormatDate(value)}</FormItemDiv>
                      ) : (
                        <FormItemPlaceholder>计划退回日期</FormItemPlaceholder>
                      )
                    }
                  </DatePicker>
                </Form.Item>
                <Form.Item
                  name="delivery_user_id"
                  label="交付负责人"
                  rules={[{ required: true, message: "必须指定交付负责人" }]}
                  trigger="onConfirm"
                  normalize={(v) => DisposeForm(v)}
                  onClick={(_, pickerRef: RefObject<PickerRef>) =>
                    pickerRef.current?.open()
                  }
                >
                  <Picker
                    loading={getDeliveryUserListIdle || gettingDeliveryUserList}
                    columns={
                      deliveryUserList
                        ? [
                            deliveryUserList.map((i) => ({
                              label: `${i.userName} (${i.jobTitle})`,
                              value: i.id,
                            })),
                          ]
                        : []
                    }
                  >
                    {(items: (PickerColumnItem | null)[]) =>
                      items[0] ? (
                        <FormItemDiv>{items[0]?.label}</FormItemDiv>
                      ) : (
                        <FormItemPlaceholder>
                          指定交付负责人
                        </FormItemPlaceholder>
                      )
                    }
                  </Picker>
                </Form.Item>
              </>
            ) : (
              <></>
            )}
          </>
        );
      case "产品升级":
      case "硬件部署":
        return (
          <>
            <Form.Item
              name="is_need_send_back"
              label="是否退回硬件"
              rules={[{ required: true, message: "必须选择是否需要退回硬件" }]}
            >
              <Radio.Group
                onChange={(v) => {
                  setSuppliesReturn(v);
                }}
              >
                <Radio style={{ marginLeft: "8px" }} value={1}>
                  是
                </Radio>
                <Radio style={{ marginLeft: "16px" }} value={0}>
                  否
                </Radio>
              </Radio.Group>
            </Form.Item>
          </>
        );
    }
  };

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      ...(value.delivery_user_id
        ? { delivery_user_id: value.delivery_user_id[0] }
        : {}),
      ...(value.planned_return_date
        ? { planned_return_date: FormatDate(value.planned_return_date) }
        : {}),
    });
    close();
  };

  useEffect(() => {
    form.resetFields();
    setSuppliesReturn(0);
    if (isOpen) {
      const delivery_user_id = detailInfo?.delivery_user_id
        ? detailInfo?.delivery_user_id
        : user?.id;
      form.setFieldsValue({
        delivery_user_id: [delivery_user_id],
        transfer_reason: detailInfo?.transfer_reason,
        planned_delivery_date: detailInfo?.planned_delivery_date
          ? FormatDayjs(detailInfo.planned_delivery_date).toDate()
          : null,
      });
      form.setFieldsValue({
        planned_return_date: detailInfo?.planned_return_date
          ? FormatDayjs(detailInfo.planned_return_date).toDate()
          : null,
      });
      form.setFieldsValue({
        planned_delivery_date: detailInfo?.planned_delivery_date
          ? FormatDayjs(detailInfo.planned_delivery_date).toDate()
          : null,
      });
      getDeliveryUserListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <EditPopup visible={isOpen} submitting={false} setVisible={close}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={false}
          >
            提交
          </Button>
        }
      >
        <Form.Header>终止交付</Form.Header>
        <Form.Item
          name="termination_reason"
          label="终止原因"
          rules={[{ required: true, message: "必须填写终止原因" }]}
        >
          <TextArea placeholder="填写终止原因" autoSize={{ minRows: 3 }} />
        </Form.Item>
        {childinfo()}
      </Form>
    </EditPopup>
  );
};
