import React, { useState, useRef, useEffect, useMemo } from "react";
import type { BaseSelectRef } from "rc-select";
import { PlusOutlined } from "@ant-design/icons";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import {
  Form,
  Select,
  Spin,
  Divider,
  Space,
  Input,
  Button,
  message,
  Tag,
} from "antd";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { HospSelectFilter, trimStr } from "utils";
import "./index.css";

export const UpdateSelectLabel = ({
  onUpdate,
  defaultValue,
  defaultIds,
  label,
  name,
  options,
  required,
  api,
  addApi,
  isCanChange,
  maxTag,
  maxCount = 5,
  updating,
}: {
  onUpdate: (value: any) => void;
  defaultValue?: { id: number; name?: string; userName?: string }[];
  defaultIds?: number[];
  label: string;
  name: string;
  options: { label: string; value: string };
  required?: boolean;
  api: string;
  addApi: string;
  isCanChange?: boolean;
  maxTag?: number;
  maxCount?: number;
  updating: boolean;
}) => {
  const [form] = Form.useForm();
  const client = useHttp();

  const [isEditing, setIsEditing] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [newLabel, setNewLabel] = useState<string | null>(null);
  const [labelItem, setLabelItem] = useState<
    { id: number; name?: string; userName?: string }[]
  >([]);
  const ref = useRef<BaseSelectRef>(null);

  const onFinish = (value: any) => {
    value[name] = value[name] || [];
    if (value) {
      value = {
        ...value,
      };
    }
    onUpdate(value);
    ref.current?.blur();
  };

  const { run, data, isLoading, isIdle } = useAsync<any>();
  const runHandle = () => {
    run(client(api)).then(() => {
      if (defaultIds) {
        form.setFieldsValue({
          [name]: defaultIds,
        });
      }
    });
  };

  const { run: add, isLoading: addLoading } = useAsync();
  const onAddLabel = (value: any) => {
    add(
      client(addApi, {
        method: "POST",
        data: {
          ...value,
        },
      }),
    )
      .then((res) => {
        message.success("标签新建成功!", 5);
        onFinish({ [name]: [...labelItem.map((i) => i.id), res.id] });
        runHandle();
      })
      .catch(() => {});
  };

  const addLabelHandle = (e: React.MouseEvent) => {
    e.preventDefault();
    const label = trimStr(newLabel);
    if (label) {
      if (labelItem.find((i: any) => i.name === label)) {
        message.warning("该标签已存在，请勿重复添加！");
      } else {
        onAddLabel({ name: newLabel });
        setNewLabel(null);
      }
    } else {
      message.warning("标签不能为空格，请正确添加！");
      setNewLabel(null);
    }
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose, disabled } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"blue"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  useEffect(() => {
    if (defaultValue) {
      form.setFieldsValue({
        [name]: defaultValue ? defaultValue.map((item) => item.id) : [],
      });
      setLabelItem(defaultValue);
    }
  }, [defaultValue]);

  const loading = isLoading || isIdle;

  return (
    <Form
      form={form}
      className="update-multiple-select-form"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label={
          <span style={{ fontWeight: "bold", color: "black" }}>{label}</span>
        }
        name={name}
        rules={[{ required: required, message: `必须选择${label}` }]}
        style={{ width: "100%" }}
      >
        {isCanChange || maxTag ? (
          <>
            <Select
              ref={ref}
              showSearch
              filterOption={HospSelectFilter}
              maxTagCount={"responsive"}
              tagRender={tagRender}
              mode="multiple"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              loading={updating || addLoading}
              disabled={updating || addLoading}
              bordered={isEditing}
              suffixIcon={isEditing ? undefined : null}
              onChange={(value) => {
                if (value.length > maxCount) {
                  message.error(`每个工单最多只能添加${maxCount}标签!`);
                } else {
                  form.submit();
                }
              }}
              onFocus={() => setIsFocus(true)}
              onBlur={() => {
                setIsFocus(false);
                setIsEditing(false);
              }}
              onMouseEnter={() => {
                setIsEditing(true);
              }}
              onMouseLeave={() => {
                if (isFocus) return;
                setIsEditing(false);
              }}
              options={data?.map((i: any) => ({
                label: i[options.label],
                value: i[options.value],
              }))}
              onClick={() => {
                if (!data) {
                  runHandle();
                }
              }}
              dropdownRender={(menu) => (
                <>
                  <Spin spinning={loading}>{menu}</Spin>
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Input
                      allowClear
                      minLength={1}
                      maxLength={10}
                      value={newLabel || undefined}
                      onChange={(e) => setNewLabel(e.target.value)}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addLabelHandle}
                      loading={addLoading}
                    >
                      添加标签
                    </Button>
                  </Space>
                </>
              )}
            >
              {defaultValue?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name || item.userName}
                </Select.Option>
              ))}
            </Select>
          </>
        ) : (
          <>
            <>
              {defaultValue
                ?.map((item) => item.name || item.userName)
                .join(", ")}
              <Button
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                编辑
              </Button>
            </>
          </>
        )}
      </Form.Item>
    </Form>
  );
};
