import { useState, Fragment } from "react";
import { Button, Descriptions, Space, message, Popconfirm } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ProTable, ProColumns } from "@ant-design/pro-components";
import { useHttp } from "utils/http";
import { DocterPcInfoType } from "../interface";
import { RetainAPI } from "../../../interface";
import { UserPCInfoAdd } from "./add";
import { UserPCInfoEdit } from "./edit";

const UserPCInfo = ({
  hospID,
  retain,
  RetainAPI,
  reload,
}: {
  hospID: number | null;
  retain: DocterPcInfoType[] | undefined | null;
  RetainAPI: RetainAPI;
  reload: () => void;
}) => {
  // 必要信息state
  const [addRetainVisible, setAddRetainVisible] = useState(false);
  const [editRetainInfoVisible, setEditRetainInfoVisible] = useState(false);
  const [editRetainInfo, setEditRetainInfo] = useState<DocterPcInfoType>();

  const client = useHttp();

  const delRetain = (id: number) => {
    if (id)
      client(RetainAPI.DEL_RETAIN(id), { method: "DELETE" })
        .then(() => {
          message.success("删除成功！");
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("删除失败，请联系管理员！");
        });
  };
  const columns: ProColumns<DocterPcInfoType>[] = [
    {
      title: "科室",
      dataIndex: "department_name",
    },
    {
      title: "CPU",
      dataIndex: "cpu_type_name",
    },
    {
      title: "内存",
      dataIndex: "memory",
    },
    {
      title: "系统",
      dataIndex: "system_version_name",
    },
    {
      title: "是否独显",
      dataIndex: "is_discrete_graphics_card",
      valueEnum: {
        1: { text: "是", status: "success" },
        0: { text: "否", status: "error" },
      },
    },
    {
      title: "占比",
      dataIndex: "proportion",
    },
    {
      title: "备注",
      dataIndex: "remark",
    },
    {
      title: "操作",
      valueType: "option",
      width: 120,
      render: (text, record, _, action) => [
        <Space>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            loading={editRetainInfoVisible && record.id === editRetainInfo?.id}
            onClick={() => {
              setEditRetainInfo(record);
              setEditRetainInfoVisible(true);
            }}
          />
          <Popconfirm
            title={`确认删除【${record.department_name}】的电脑信息？`}
            onConfirm={() => delRetain(record.id)}
            okText="确认"
            cancelText="取消"
            icon={<ExclamationCircleOutlined />}
            style={{ color: "red" }}
          >
            <Button
              danger
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>,
      ],
    },
  ];
  const RetainInfoDescItem = (item: DocterPcInfoType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`userPcInfo${key}`}>
        <Descriptions.Item label={title("科室")} className={className()}>
          {item?.department_name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("CPU")} className={className()}>
          {item?.cpu_type_name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("内存")} className={className()}>
          {item?.memory || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("系统")} className={className()}>
          {item?.system_version_name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("是否独显")} className={className()}>
          {item?.is_discrete_graphics_card === 1
            ? "是"
            : item?.is_discrete_graphics_card === 0
            ? "否"
            : null}
        </Descriptions.Item>
        <Descriptions.Item label={title("占比")} className={className()}>
          {`${item?.proportion}%` || "0%"}
        </Descriptions.Item>
        <Descriptions.Item label={title("备注")} className={className()}>
          {item?.remark || ""}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("操作")}
          className={className()}
        >
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              loading={editRetainInfoVisible && item.id === editRetainInfo?.id}
              onClick={() => {
                setEditRetainInfo(item);
                setEditRetainInfoVisible(true);
              }}
            />
            <Popconfirm
              title={`确认删除【${item.department_name}】的电脑信息？`}
              onConfirm={() => delRetain(item.id)}
              okText="确认"
              cancelText="取消"
              icon={<ExclamationCircleOutlined />}
              style={{ color: "red" }}
            >
              <Button
                danger
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <ProTable<DocterPcInfoType>
        size="small"
        columns={columns}
        rowKey="id"
        dataSource={retain || []}
        pagination={false}
        search={false}
        dateFormatter="string"
        options={{
          reload: reload,
        }}
        toolbar={{
          title: <span style={{ fontWeight: "bold" }}>医生电脑信息</span>,
          actions: [
            <Button
              key="add"
              type="default"
              icon={<PlusOutlined />}
              onClick={() => setAddRetainVisible(true)}
            >
              新增
            </Button>,
          ],
          settings: [],
        }}
        bordered={retain?.length ? true : false}
      />
      {/* <Descriptions
        column={8}
        bordered={retain?.length ? true : false}
        title="医生电脑信息"
        layout="vertical"
        extra={
          <Button
            loading={addRetainVisible}
            icon={<PlusOutlined />}
            onClick={() => setAddRetainVisible(true)}
          >
            新增
          </Button>
        }
      >
        {retain?.map((item, index) => RetainInfoDescItem(item, index))}
      </Descriptions> */}
      <UserPCInfoAdd
        hospID={hospID}
        ADD_RETAIN={RetainAPI.ADD_RETAIN}
        visible={addRetainVisible}
        setVisible={setAddRetainVisible}
        reload={reload}
      />
      <UserPCInfoEdit
        editingItem={editRetainInfo}
        UPDATE_RETAIN={RetainAPI.UPDATE_RETAIN}
        visible={editRetainInfoVisible}
        setVisible={setEditRetainInfoVisible}
        reload={reload}
      />
    </Fragment>
  );
};

export default UserPCInfo;
