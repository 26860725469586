import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  message,
  Select,
  Divider,
  Switch,
} from "antd";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { DisposeForm, isNumber, selectFilter } from "utils";
import {
  PermissionResourcesType,
  ResourceClass,
  ResourceType,
} from "../../interface";
import { GET_RESOURCE_CLASS, GET_RESOURCE_LIST } from "../../api";
import { resourceActions } from ".";

const { Option } = Select;

export const PermissionResourcesEdit = ({
  editingItem,
  visible,
  setVisible,
  update,
}: {
  editingItem: PermissionResourcesType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  update: (data: PermissionResourcesType) => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [addResourceID, setAddResourceID] = useState<number | null>(null);

  const [isAllResource, setIsAllResource] = useState<boolean>(false);
  const [isAllDomain, setIsAllDomain] = useState<boolean>(false);

  const {
    run: getResourcesList,
    isLoading: gettingResourcesList,
    data: resourcesList,
  } = useAsync<ResourceType[]>();
  const getResourcesListHandle = () => {
    getResourcesList(client(GET_RESOURCE_LIST));
  };

  const {
    run: getResourcesClass,
    isLoading: gettingResourcesClass,
    data: resourcesClass,
    setData: setResourcesClass,
  } = useAsync<ResourceClass[]>();
  const getResourcesClassHandle = (id: number) => {
    getResourcesClass(client(GET_RESOURCE_CLASS(id)));
  };

  const submit = (value: any) => {
    try {
      if (isNumber(editingItem?.key)) {
        let resource = value?.resource?.split("_");
        let domainIds: number[] = [];
        let domainNames: string[] = [];
        value?.domains?.forEach((i: string) => {
          let tmp = i.split("_");
          domainIds.push(parseInt(tmp[0]));
          domainNames.push(tmp[1]);
        });
        let res = {
          key: editingItem?.key!,
          resource: isAllResource ? null : value.resource,
          resourceId: isAllResource ? null : parseInt(resource[0]),
          resourceName: isAllResource ? null : resource[1],
          domains: isAllDomain || isAllResource ? [] : value.domains,
          domainIds: isAllDomain || isAllResource ? null : domainIds,
          domainNames: isAllDomain || isAllResource ? null : domainNames,
          actions: value.actions.join(","),
          isAllResource: isAllResource ? 1 : 0,
          isAllDomain: isAllDomain || isAllResource ? 1 : 0,
        };
        update(res);
        setVisible(false);
        message.success("提交成功");
      } else {
        console.error(editingItem);
        throw new Error(`无法识别ID`);
      }
    } catch (error) {
      console.error(error);
      message.error("提交出错，请联系管理员！");
    }
  };

  const init = () => {
    setIsAllResource(editingItem?.isAllResource === 1 ? true : false);
    setIsAllDomain(editingItem?.isAllDomain === 1 ? true : false);
    if (isNumber(editingItem?.resourceId)) {
      setAddResourceID(editingItem?.resourceId!);
    } else {
      setResourcesClass([]);
    }
    form.setFieldsValue({
      ...editingItem,
      actions: editingItem?.actions.split(","),
    });
  };

  const clear = () => {
    form.setFieldsValue({
      domains: undefined,
    });
  };

  useEffect(() => {
    if (addResourceID) {
      getResourcesClassHandle(addResourceID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResourceID]);

  useEffect(() => {
    if (visible) {
      init();
      getResourcesListHandle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="编辑关联资源"
      destroyOnClose
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item name={"isAllResource"} label={"资源全选"}>
          <Switch
            checkedChildren="是"
            unCheckedChildren="否"
            checked={isAllResource}
            onClick={(v) => setIsAllResource(v)}
          />
        </Form.Item>
        {isAllResource ? null : (
          <Form.Item
            name="resource"
            label="资源"
            rules={[{ required: true, message: "必须选择资源" }]}
            normalize={(v) => DisposeForm(v)}
          >
            <Select
              disabled={isAllResource}
              loading={gettingResourcesList}
              placeholder="选择资源"
              showSearch
              filterOption={selectFilter}
              onChange={(v) => {
                clear();
                setAddResourceID(v.split("_")[0]);
              }}
            >
              {resourcesList?.map((item) => (
                <Option
                  key={item.resourceId}
                  value={`${item.resourceId}_${item.resourceName}`}
                >
                  {item.resourceName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Divider />
        <Form.Item name={"isAllDomain"} label={"分类全选"}>
          <Switch
            checkedChildren="是"
            unCheckedChildren="否"
            checked={isAllDomain || isAllResource}
            onClick={(v) => {
              if (isAllResource && !v) {
                message.warning("资源全选无法取消分类全选！");
              } else {
                setIsAllDomain(v);
              }
            }}
          />
        </Form.Item>
        {isAllDomain || isAllResource ? null : (
          <Form.Item
            name="domains"
            label="分类"
            rules={[{ required: true, message: "必须选择分类(多选)" }]}
            normalize={(v) => DisposeForm(v)}
          >
            <Select
              disabled={isAllDomain || isAllResource}
              loading={gettingResourcesClass}
              mode="multiple"
              placeholder="选择分类(多选)"
              showSearch
              filterOption={selectFilter}
              maxTagCount={5}
            >
              {resourcesClass?.map((item) => (
                <Option
                  key={item.classFicationId}
                  value={`${item.classFicationId}_${item.classFicationName}`}
                >
                  {item.classFicationName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Divider />
        <Form.Item
          name="actions"
          label="操作"
          rules={[{ required: true, message: "必须选择操作(多选)" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select mode="multiple" placeholder="选择操作(多选)">
            {resourceActions?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Divider />
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
