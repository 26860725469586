import { Fragment, useEffect, useState } from "react";
import {
  Button,
  CapsuleTabs,
  ErrorBlock,
  PullToRefresh,
  Skeleton,
  Space,
  Toast,
  Tag,
  List,
  Dialog,
} from "antd-mobile";
import { AddOutline, AddSquareOutline } from "antd-mobile-icons";
import { useDebounceFn } from "ahooks";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import {
  ProductInfoType,
  ProductBaseInfoType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import {
  ADD_PRODUCT_RECORD,
  ADD_PRODUCT_RETAIN,
  DEL_PRODUCT_RECORD,
  DEL_PRODUCT_RETAIN,
  GET_PRODUCT_DETAIL,
  UPDATE_PRODUCT_RECORD,
  UPDATE_PRODUCT_RETAIN,
  GET_LICENSE_INFO,
} from "lib/api";
import { useMount } from "utils";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { MobileProductBaseInfo } from "./base-info";
import { MobileProductUpdateRecord } from "./update-record";
import { MobileRetainInfo } from "../general/retain-info";
import { MobileProductBaseInfoAdd } from "./base-info/add";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

import "../index.css";

interface LicenseInfo {
  solution_name: string;
  valid_start: string;
  valid_end: string;
  duration: number;
}

export const MobileProductInfo = () => {
  const [{ detailId, product }, setProduct] = useUrlQueryParam([
    "detailId",
    "product",
  ]);

  const client = useHttp();

  const [addVisible, setAddVisible] = useState(false);

  const {
    run: getProductDetail,
    isLoading,
    data,
    isIdle,
  } = useAsync<ProductInfoType[]>();
  const getProductDetailHandle = () => {
    if (detailId) {
      getProductDetail(client(GET_PRODUCT_DETAIL(detailId)));
    } else {
      Toast.show({
        icon: "fail",
        content: <ToastContent content="获取失败" />,
      });
    }
  };

  const reload = getProductDetailHandle;

  const [displayItem, setDisplayItem] = useState<ProductInfoType>();

  const [detailInfo, setDetailInfo] = useState<any[]>();

  const [licenseInfo, setLicenseInfo] = useState<ProductInfoType>();
  const [activeTab, setActiveTab] = useState<string>("license");

  const [licenseDetailInfo, setLicenseDetailInfo] = useState<LicenseInfo[]>([]);

  const { run: getLicenseInfo, data: licenseData } = useAsync<LicenseInfo[]>();

  useEffect(() => {
    if (detailId) {
      getLicenseInfo(client(GET_LICENSE_INFO(detailId)));
    }
  }, [detailId]);

  useEffect(() => {
    if (licenseData) {
      setLicenseDetailInfo(licenseData);
    }
  }, [licenseData]);

  useEffect(() => {
    if (displayItem) {
      setProduct({
        product: displayItem.product_id,
      });
    }
  }, [displayItem]);

  useEffect(() => {
    if (data) {
      const [licenseInfo, ...productInfo] = data;
      setDetailInfo(productInfo);
      setLicenseInfo(licenseInfo);
    }
  }, [data]);

  useEffect(() => {
    if (detailInfo) {
      if (activeTab === "license") {
        setDisplayItem(licenseInfo);
      } else if (product && product !== "undefined") {
        let index = detailInfo?.findIndex((d) => d.product_id === product);
        if (index !== undefined && index >= 0) {
          setDisplayItem(detailInfo[index]);
        } else {
          if (detailInfo[0]) {
            setDisplayItem(detailInfo[0]);
          }
        }
      } else {
        if (detailInfo[0]) {
          setDisplayItem(detailInfo[0]);
        }
      }
    }
  }, [detailInfo, activeTab, product]);

  const PublicDisplay = ({ info }: { info: ProductBaseInfoType[] }) => {
    const [publicInfoArray] = info;
    const [visible, setVisible] = useState(false);

    const itemStyle = {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "14px",
      padding: "4px 0",
    } as const;

    const showAllInfo = (item: ProductBaseInfoType) => {
      Dialog.show({
        content: (
          <List style={{ "--prefix-width": "100%" }}>
            <List.Item>
              <Space direction="vertical" style={{ width: "100%" }}>
                <div style={itemStyle}>
                  <span>服务器</span>
                  <span>{item?.server_ip || "未知"}</span>
                </div>
                <div style={itemStyle}>
                  <span>UB版本</span>
                  <span>{item?.ub_version_name || "未知"}</span>
                </div>
                <div style={itemStyle}>
                  <span>AGI平台</span>
                  <span>{item?.ga_version_name || "未知"}</span>
                </div>
                <div style={itemStyle}>
                  <span>AI CT算法平台</span>
                  <span>{item?.aic_version_name || "未知"}</span>
                </div>
                <div style={itemStyle}>
                  <span>AI MR算法平台</span>
                  <span>{item?.aim_version_name || "未知"}</span>
                </div>
                <div style={itemStyle}>
                  <span>OP 工作站</span>
                  <span>{item?.w_version_name || "未知"}</span>
                </div>
                <div style={itemStyle}>
                  <span>语言包</span>
                  <span>{item?.lang_version_name || "未知"}</span>
                </div>
                <div style={itemStyle}>
                  <span>更新人</span>
                  <span>{item?.update_user || "未知"}</span>
                </div>
                <div style={itemStyle}>
                  <span>更新时间</span>
                  <span>{item?.update_time || "未知"}</span>
                </div>
              </Space>
            </List.Item>
          </List>
        ),
        closeOnAction: true,
        actions: [{ key: "close", text: "关闭" }],
      });
    };

    return (
      <>
        <List
          header="公共信息"
          mode="card"
          style={{ "--prefix-width": "100%" }}
        >
          {Array.isArray(publicInfoArray) &&
            publicInfoArray.map((item, index) => (
              <List.Item
                key={index}
                onClick={() => showAllInfo(item)}
                arrow={true}
              >
                <div style={itemStyle}>
                  <span>{item?.server_ip || "未知"}</span>
                </div>
              </List.Item>
            ))}
        </List>

        <List
          header="授权信息"
          mode="card"
          style={{ "--prefix-width": "100%", marginTop: "12px" }}
        >
          {licenseDetailInfo.map((item, index) => (
            <List.Item
              key={index}
              arrow={true}
              onClick={() => {
                Dialog.show({
                  content: (
                    <List style={{ "--prefix-width": "100%" }}>
                      <List.Item>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          <div style={itemStyle}>
                            <span>{item?.solution_name || "未知"}</span>
                          </div>
                          <div style={itemStyle}>
                            <span>开始日期</span>
                            <span>
                              {new Date(item?.valid_start)
                                .toISOString()
                                .split("T")[0] || "未知"}
                            </span>
                          </div>
                          <div style={itemStyle}>
                            <span>截止日期</span>
                            <span>
                              {new Date(item?.valid_end)
                                .toISOString()
                                .split("T")[0] || "未知"}
                            </span>
                          </div>
                          <div style={itemStyle}>
                            <span>授权时长(天)</span>
                            <span>{item?.duration || "未知"}</span>
                          </div>
                        </Space>
                      </List.Item>
                    </List>
                  ),
                  closeOnAction: true,
                  actions: [{ key: "close", text: "关闭" }],
                });
              }}
            >
              <div style={itemStyle}>
                <span>{item?.solution_name || "未知"}</span>
                <span>
                  {(() => {
                    const now = new Date();
                    const endDate = new Date(item?.valid_end);
                    return now > endDate ? (
                      <Tag color="#ff3141">失效</Tag>
                    ) : (
                      <Tag color="#00b578">有效</Tag>
                    );
                  })()}
                </span>
              </div>
            </List.Item>
          ))}
        </List>
      </>
    );
  };

  const TabDisplay = ({ item }: { item: ProductInfoType }) => {
    return (
      <Fragment>
        <MobileProductBaseInfo detail={item} reload={reload} />
        <MobileRetainInfo
          orgID={detailId}
          productID={item.product_id}
          retain={item.retain_infos}
          RetainAPI={{
            ADD_RETAIN: ADD_PRODUCT_RETAIN,
            UPDATE_RETAIN: UPDATE_PRODUCT_RETAIN,
            DEL_RETAIN: DEL_PRODUCT_RETAIN,
          }}
          reload={reload}
        />
        <MobileProductUpdateRecord
          orgID={item.id}
          records={item.update_info}
          RetainAPI={{
            ADD_RETAIN: () => ADD_PRODUCT_RECORD,
            UPDATE_RETAIN: UPDATE_PRODUCT_RECORD,
            DEL_RETAIN: DEL_PRODUCT_RECORD,
          }}
          reload={reload}
        />
      </Fragment>
    );
  };

  const { run } = useDebounceFn(getProductDetailHandle, { wait: 200 });

  useMount(run);

  const Loading = isLoading || isIdle;

  const Create = () => {
    return (
      <ErrorBlock
        fullPage
        status="empty"
        title={<span>当前医院没有添加产品线</span>}
        description={<span>点击下方按钮添加一个新产品线吧</span>}
        style={{ backgroundColor: "#ffffff", height: "100vh" }}
      >
        <Button
          color="primary"
          onClick={() => setAddVisible(true)}
          loading={addVisible}
        >
          <Space>
            <AddOutline />
            新增产品线
          </Space>
        </Button>
      </ErrorBlock>
    );
  };

  const getProductStatus = (status: number | undefined, icon?: boolean) => {
    if (status === 1) {
      return icon ? (
        <Tag color="#009b51" style={{ margin: 0 }}>
          已授权
        </Tag>
      ) : (
        "已授权"
      );
    } else if (status === 2) {
      return icon ? (
        <Tag color="#ffae11" style={{ margin: 0 }}>
          未授权
        </Tag>
      ) : (
        "未授权"
      );
    } else if (status === 3) {
      return icon ? (
        <Tag color="#009b51" style={{ margin: 0 }}>
          无需授权
        </Tag>
      ) : (
        "无需授权"
      );
    } else {
      return "未知状态";
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <LoadingMask visible={Loading} />
      <PullToRefresh
        onRefresh={async () => getProductDetailHandle()}
        refreshingText={null}
      >
        {detailInfo?.length === 0 && !isIdle ? (
          <Create />
        ) : (
          <Fragment>
            {detailInfo?.length ? (
              <div className={"customer-header"}>
                <CapsuleTabs
                  className={"tabs"}
                  activeKey={activeTab}
                  onChange={(v) => {
                    setActiveTab(v);
                  }}
                >
                  <CapsuleTabs.Tab
                    key={"license"}
                    title={<Space>公共信息</Space>}
                  />
                  {detailInfo?.map((i) => (
                    <CapsuleTabs.Tab
                      key={i?.product_id}
                      title={
                        <Space>
                          {i?.product_chinese_name || i?.product_id}
                          {getProductStatus(i.product_status, true)}
                        </Space>
                      }
                    >
                      {activeTab === i.product_id && <TabDisplay item={i} />}
                    </CapsuleTabs.Tab>
                  ))}
                </CapsuleTabs>
                <Button
                  className={"more-icon"}
                  fill="none"
                  loading={addVisible}
                  onClick={() => setAddVisible(true)}
                >
                  <AddSquareOutline
                    fontSize={24}
                    style={{
                      transform: "translateY(2px)",
                    }}
                  />
                </Button>
              </div>
            ) : null}
            {displayItem ? (
              <div className="customer-content">
                {activeTab === "license" ? (
                  <PublicDisplay
                    info={[licenseInfo as unknown as ProductBaseInfoType]}
                  />
                ) : (
                  <TabDisplay item={displayItem} />
                )}
              </div>
            ) : (
              <div style={{ margin: 16 }}>
                <Skeleton.Title animated />
                <Skeleton.Paragraph lineCount={5} animated />
              </div>
            )}
          </Fragment>
        )}
      </PullToRefresh>
      <MobileProductBaseInfoAdd
        id={detailId}
        visible={addVisible}
        setVisible={setAddVisible}
        reload={reload}
      />
    </div>
  );
};
