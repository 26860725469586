import { SetStateAction, Dispatch, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  Input,
  message,
  Select,
  Divider,
} from "antd";

import { useHttp } from "utils/http";
import { DisposeForm, selectFilter } from "utils";
import { PackageType, PermissionResourcesType } from "../interface";
import PermissionResources from "./permission-resources";

const { Option } = Select;

export const PermissionListSettingAdd = ({
  CTRL_ADD,
  packageList,
  visible,
  setVisible,
  reload,
}: {
  CTRL_ADD: string;
  packageList: PackageType[] | undefined | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [addResourceList, setAddResourceList] = useState<
    PermissionResourcesType[]
  >([]);

  const init = () => {
    setAddResourceList([]);
    form.resetFields();
  };

  const submit = (value: any) => {
    client(CTRL_ADD, {
      data: {
        ...value,
        packageId: null,
        packageType: 1, // TODO 目前暂只有1
        resources: addResourceList,
      },
      method: "POST",
    })
      .then(() => {
        message.success("添加成功！");
        setVisible(false);
        reload();
      })
      .catch((e) => {
        console.error(e);
        message.error("添加失败，请检查或联系管理员！");
      });
  };

  useEffect(() => {
    if (visible) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={`新增权限`}
      maskClosable={false}
      destroyOnClose
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width="61.8%"
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        onFinish={submit}
      >
        <Form.Item
          name="packageName"
          label={"名称"}
          rules={[{ required: true, message: `必须输入权限名称` }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input placeholder="请输入权限名称" maxLength={50} />
        </Form.Item>
        <Form.Item
          name="parentPackageIds"
          label={"引用权限"}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            mode="multiple"
            showSearch
            filterOption={selectFilter}
            maxTagCount={5}
          >
            {packageList?.map((item) => (
              <Option key={item.packageId} value={item.packageId}>
                {item.packageName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Divider />
        <Form.Item
          name="resources"
          label={"资源列表"}
          normalize={(v) => DisposeForm(v)}
        >
          <PermissionResources
            resources={addResourceList}
            update={setAddResourceList}
          />
        </Form.Item>
        <Divider />
        <Form.Item wrapperCol={{ offset: 17, span: 6 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
