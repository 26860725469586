import { SetStateAction, Dispatch, useEffect } from "react";
import { Button, Modal, Space, Form, Input, message } from "antd";

import { useHttp } from "utils/http";
import { UPDATE_NETWORK } from "lib/api";
import { NetworkType } from "../interface";
import { DisposeForm } from "utils";
import { DnsRegex, IpRegex, MaskRegex } from "utils/regexp";

const { TextArea } = Input;

export const NetworkInfoEdit = ({
  editingItem,
  visible,
  setVisible,
  reload,
}: {
  editingItem: NetworkType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const submit = (value: any) => {
    let id = editingItem?.id;
    if (id)
      client(UPDATE_NETWORK(id), {
        data: {
          ...value,
        },
        method: "PUT",
      })
        .then(() => {
          message.success("修改成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    else message.error("修改网络错误，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...editingItem,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="编辑网络信息"
      destroyOnClose
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="ip"
          label="IP"
          rules={[
            { required: true, message: "必须输入IP" },
            {
              pattern: IpRegex(),
              message: "必须输入合法的IP格式",
            },
          ]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="gateway"
          label="网关"
          rules={[
            { required: true, message: "必须输入网关" },
            {
              pattern: IpRegex(),
              message: "必须输入合法的网关地址",
            },
          ]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="mask"
          label="掩码"
          rules={[
            { required: true, message: "必须输入掩码" },
            {
              pattern: MaskRegex(),
              message: "必须输入合法的掩码格式",
            },
          ]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="dns"
          label="DNS"
          rules={[
            {
              pattern: DnsRegex(),
              message: "必须输入合法的DNS地址",
            },
          ]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item name="remark" label="备注" normalize={(v) => DisposeForm(v)}>
          <TextArea allowClear />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
