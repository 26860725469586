import { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
} from "echarts/components";
import { PieChart, PieSeriesOption } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { message } from "antd";

type EChartsOption = echarts.ComposeOption<
  TooltipComponentOption | LegendComponentOption | PieSeriesOption
>;

export interface MyPieChartType {
  data: { value: number; name: string }[];
}

export const MyPieChart = ({
  Data,
  showLegend = true,
  Title,
}: {
  Data: MyPieChartType | undefined;
  showLegend?: boolean;
  Title?: string;
}) => {
  echarts.use([
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout,
  ]);

  const [option, setOption] = useState<EChartsOption>({});

  const getOption = (): EChartsOption => ({
    title: {
      text: Title,
    },
    tooltip: {
      trigger: "item",
    },
    legend: showLegend
      ? {
          orient: "vertical",
          left: "left",
        }
      : undefined,
    series: [
      {
        type: "pie",
        radius: ["40%", "70%"],
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        data: Data?.data,
      },
    ],
    label: {
      formatter: "{b}: {d}%",
    },
  });

  const chartRef = useRef(null);
  let myChart: echarts.ECharts;

  const renderChart = (option: EChartsOption) => {
    try {
      const renderedInstance = echarts.getInstanceByDom(
        chartRef.current as unknown as HTMLElement,
      );
      if (renderedInstance) {
        myChart = renderedInstance;
      } else {
        myChart = echarts.init(chartRef.current as unknown as HTMLElement);
      }
      myChart.setOption(option);
    } catch (error) {
      message.error("初始化图表失败！");
      console.error(error);
      myChart && myChart.dispose();
    }
  };

  const resizeHandler = () => {
    myChart.resize();
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    renderChart(option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  useEffect(() => {
    setOption(getOption());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  return <div style={{ height: "100%", width: "100%" }} ref={chartRef}></div>;
};
