import { Col, Row, Divider, Typography, Tag } from "antd";
import { ChartCard } from "./components/chartCard";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { STATISTICS_TYPE } from "../../interface";
import { GET_DASHBOARD_STATISTICS } from "lib/api";
import { useDebounceFn, useMount } from "ahooks";
import { useEffect, useState } from "react";
import { Spin, Space, Card, Skeleton } from "antd";

const { Title, Paragraph, Text, Link } = Typography;

export const IntroduceRow = () => {
  const client = useHttp();
  const cardInitInfo = {
    name: "正在获取",
    value: 999,
    monthGrowth: 999,
    weeklyGrowth: 999,
  };
  const [useFaultInfo, setUseFaultInfo] = useState<any>(cardInitInfo);
  const [useNeedInfo, setUseNeedInfo] = useState<any>(cardInitInfo);
  const [useServiceInfo, setUseServiceInfo] = useState<any>(cardInitInfo);
  const [useDeliveryInfo, setUseDeliveryInfo] = useState<any>(cardInitInfo);
  const [useUpgradeInfo, setUseUpgradeInfo] = useState<any>(cardInitInfo);

  const {
    run: getDashboardStatistics,
    isLoading: gettingStatistics,
    isIdle: getDashboardStatisticsIsIdle,
    data: statistics,
  } = useAsync<STATISTICS_TYPE[]>();
  const getDashboardStatisticsHanle = () => {
    getDashboardStatistics(client(GET_DASHBOARD_STATISTICS));
  };
  const { run: getDashboardStatisticsDebounce } = useDebounceFn(
    getDashboardStatisticsHanle,
    {
      wait: 200,
    },
  );

  useEffect(() => {
    if (statistics?.length) {
      statistics?.map((item) => {
        switch (item.name) {
          case "使用故障":
            setUseFaultInfo({
              name: item.name,
              value: item.value,
              monthGrowth: item.monthGrowth,
              weeklyGrowth: item.weeklyGrowth,
            });
            break;
          case "使用需求":
            setUseNeedInfo({
              name: item.name,
              value: item.value,
              monthGrowth: item.monthGrowth,
              weeklyGrowth: item.weeklyGrowth,
            });
            break;
          case "上线交付":
            setUseDeliveryInfo({
              name: item.name,
              value: item.value,
              monthGrowth: item.monthGrowth,
              weeklyGrowth: item.weeklyGrowth,
            });
            break;
          case "日常服务":
            setUseServiceInfo({
              name: item.name,
              value: item.value,
              monthGrowth: item.monthGrowth,
              weeklyGrowth: item.weeklyGrowth,
            });
            break;
          case "产品升级":
            setUseUpgradeInfo({
              name: item.name,
              value: item.value,
              monthGrowth: item.monthGrowth,
              weeklyGrowth: item.weeklyGrowth,
            });
            break;
        }
      });
    }
  }, [statistics]);

  // useEffect(() => {
  //   getDashboardStatisticsDebounce();
  // }, []);

  useMount(() => {
    getDashboardStatisticsDebounce();
  });

  const getTipsInfo = (name: string) => {
    switch (name) {
      case "使用故障":
        return `指标说明: 
            1.工单类型为使用故障的总工单量
            2.月同比：前两个月对应工单数对比 
            3.周同比：前两周对应工单数对比`;
      case "使用需求":
        return `指标说明: 
        1.工单类型为使用需求的总工单量
        2.月同比：前两个月对应工单数对比 
        3.周同比：前两周对应工单数对比`;
      case "日常服务":
        return `指标说明: 
        1.工单类型为日常服务的总工单量
        2.月同比：前两个月对应工单数对比 
        3.周同比：前两周对应工单数对比`;
      case "产品升级":
        return `指标说明: 
        1.工单类型为产品升级的总工单量
        2.月同比：前两个月对应工单数对比 
        3.周同比：前两周对应工单数对比`;
      case "上线交付":
        return `指标说明: 
        1.工单类型为渠道交付、试用交付及销售交付的总工单量
        2.月同比：前两个月对应工单数对比
        3.周同比：前两周对应工单数对比`;
    }
  };

  return (
    <>
      <Row>
        {gettingStatistics || getDashboardStatisticsIsIdle ? (
          <>
            <Skeleton active />
          </>
        ) : (
          <>
            <Col span={4}>
              <ChartCard
                title={useFaultInfo.name}
                tipinfo={getTipsInfo(useFaultInfo.name)}
                value={useFaultInfo.value}
                monthGrowth={useFaultInfo.monthGrowth}
                weeklyGrowth={useFaultInfo.weeklyGrowth}
              />
            </Col>
            <Col span={1}></Col>
            <Col span={4}>
              <ChartCard
                title={useNeedInfo.name}
                tipinfo={getTipsInfo(useNeedInfo.name)}
                value={useNeedInfo.value}
                monthGrowth={useNeedInfo.monthGrowth}
                weeklyGrowth={useNeedInfo.weeklyGrowth}
              />
            </Col>
            <Col span={1}></Col>
            <Col span={4}>
              <ChartCard
                title={useServiceInfo.name}
                tipinfo={getTipsInfo(useServiceInfo.name)}
                value={useServiceInfo.value}
                monthGrowth={useServiceInfo.monthGrowth}
                weeklyGrowth={useServiceInfo.weeklyGrowth}
              />
            </Col>

            <Col span={1}></Col>

            <Col span={4}>
              <ChartCard
                title={useDeliveryInfo.name}
                tipinfo={getTipsInfo(useDeliveryInfo.name)}
                value={useDeliveryInfo.value}
                monthGrowth={useDeliveryInfo.monthGrowth}
                weeklyGrowth={useDeliveryInfo.weeklyGrowth}
              />
            </Col>
            <Col span={1}></Col>
            <Col span={4}>
              <ChartCard
                title={useUpgradeInfo.name}
                tipinfo={getTipsInfo(useUpgradeInfo.name)}
                value={useUpgradeInfo.value}
                monthGrowth={useUpgradeInfo.monthGrowth}
                weeklyGrowth={useUpgradeInfo.weeklyGrowth}
              />
            </Col>
          </>
        )}
      </Row>
      {/* </Spin> */}
      <Divider style={{ color: "black", margin: "12px" }} />
    </>
  );
};
