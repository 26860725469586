import { Dispatch, SetStateAction, useEffect } from "react";
import { Button, Descriptions, Space, Select, message, Form } from "antd";

import { useHttp } from "utils/http";
import {
  GET_DOCKING_TYPE,
  GET_DOCKING_WAY,
  GET_HOS_PRODUCT_LIST,
  UPDATE_DOCKING_INFO,
} from "lib/api";
import { DockingDesItemLabel, LineDiv } from "../../utils";
import { DockingListType, DockingType, DockingWay } from "../interface";
import { HospProductsListType } from "../../hardwareTabPane/interface";
import { useAsync } from "utils/hooks/useAsync";
import { DisposeForm } from "utils";

const { Option } = Select;

export const DockingBaseInfoEdit = ({
  hospID,
  editingItem,
  editable,
  setEditable,
  setDisChange,
  reload,
}: {
  hospID: number | null;
  editingItem: DockingListType;
  editable: boolean;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setDisChange: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getDockingTypeList,
    isLoading: gettingDockingTypeList,
    data: dockingTypeList,
  } = useAsync<DockingType[]>();
  const getDockingTypeListFunc = () => {
    getDockingTypeList(client(GET_DOCKING_TYPE));
  };

  const {
    run: getDockingWayList,
    isLoading: gettingDockingWayList,
    data: dockingWayList,
  } = useAsync<DockingWay[]>();
  const getDockingWayListFunc = () => {
    getDockingWayList(client(GET_DOCKING_WAY));
  };

  const {
    run: getProductTypeList,
    isLoading: gettingProductTypeList,
    data: productTypeList,
  } = useAsync<HospProductsListType[]>();
  const getProductTypeListFunc = (hospital_id: number) => {
    getProductTypeList(client(GET_HOS_PRODUCT_LIST(hospital_id)));
  };

  const submit = (value: any) => {
    if (editingItem?.id)
      client(UPDATE_DOCKING_INFO(editingItem?.id), {
        data: {
          ...value,
        },
        method: "PUT",
      })
        .then(() => {
          message.success("修改成功！");
          setEditable(false);
          setDisChange(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    else {
      message.error("修改失败，请稍后重试或联系管理员！");
      setEditable(false);
      setDisChange(false);
    }
  };

  useEffect(() => {
    if (editable) {
      if (hospID) {
        getProductTypeListFunc(hospID);
      }
      getDockingWayListFunc();
      getDockingTypeListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);

  useEffect(() => {
    if (editingItem) {
      form.setFieldsValue({
        ...editingItem,
        products: editingItem?.products.map((i) => i.product_id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  return (
    <Form form={form} onFinish={submit}>
      <Descriptions column={2}>
        <Descriptions.Item label={<DockingDesItemLabel label={"对接产品线"} />}>
          <LineDiv>
            <Form.Item
              name="products"
              rules={[{ required: true, message: "必须选择对接产品线" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingProductTypeList}
                mode="multiple"
                maxTagCount={3}
                style={{ width: "256px" }}
                placeholder="选择产品线"
              >
                {productTypeList?.map((i, index) => (
                  <Option key={index} value={i.product_id}>
                    {i.product_id}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<DockingDesItemLabel label={"对接类型"} />}>
          <LineDiv>
            <Form.Item
              name="docking_type_id"
              rules={[{ required: true, message: "必须选择对接类型" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingDockingTypeList}
                style={{ width: "256px" }}
                placeholder="选择对接类型"
              >
                {dockingTypeList?.map((i, index) => (
                  <Option key={index} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<DockingDesItemLabel label={"现片对接方式"} />}
        >
          <LineDiv>
            <Form.Item name="cur_docking_id" normalize={(v) => DisposeForm(v)}>
              <Select
                loading={gettingDockingWayList}
                allowClear
                style={{ width: "256px" }}
                placeholder="选择现片对接方式"
              >
                {dockingWayList?.map((i, index) => (
                  <Option key={index} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<DockingDesItemLabel label={"前片对接方式"} />}
        >
          <LineDiv>
            <Form.Item
              name="history_docking_id"
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingDockingWayList}
                allowClear
                style={{ width: "256px" }}
                placeholder="选择前片对接方式"
              >
                {dockingWayList?.map((i, index) => (
                  <Option key={index} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
      </Descriptions>
      <Form.Item
        wrapperCol={{ offset: 20 }}
        style={{ marginTop: "2rem", marginBottom: "-1rem" }}
      >
        <Space>
          <Button
            htmlType="button"
            onClick={() => {
              setDisChange(false);
              setEditable(false);
            }}
          >
            取消
          </Button>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
