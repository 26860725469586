import { useEffect } from "react";
import { Form, Modal, Select, Input, DatePicker } from "antd";
import { useDebounceFn } from "ahooks";
import { useDeliveryDetailPause } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailPause";
import { FormatDate, FormatDayjs } from "utils";
import {
  DELIVERY_DETAIL_TYPE,
  DELIVERY_PAUSE_REASON_LIST_TYPE,
  NEXT_PROCESS_TYPE,
} from "lib/interface/launchChangeList";
import { GET_DELIVERY_PAUSE_REASON_TYPE } from "lib/api/deliverylist";

import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { HospSelectFilter } from "utils";

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const DeliveryDetailPauseModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailPause();

  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getDeliveryPauseReasonList,
    data: deliverPauseReasonList,
    isIdle: getDeliveryPauseReasonListIdle,
    isLoading: gettingDeliverPauseReasonList,
  } = useAsync<DELIVERY_PAUSE_REASON_LIST_TYPE[]>();
  const getDeliveryPauseReasonListHandle = () => {
    getDeliveryPauseReasonList(client(GET_DELIVERY_PAUSE_REASON_TYPE));
  };
  const { run: getDeliveryPauseReasonListDebounce } = useDebounceFn(
    getDeliveryPauseReasonListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      next_entry_date: FormatDate(value.next_entry_date),
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        pause_reason_type_id: detailInfo?.pause_reason_type_id,
        pause_reason: detailInfo?.pause_reason,
        next_entry_date: detailInfo?.next_entry_date
          ? FormatDayjs(detailInfo?.next_entry_date)
          : "",
      });
      getDeliveryPauseReasonListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="暂停交付"
      onCancel={close}
      onOk={form.submit}
      width={"700px"}
      style={{ minWidth: "600px" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        <Form.Item
          name="pause_reason_type_id"
          label="暂停原因分类"
          rules={[{ required: true, message: "必须选择暂停原因分类" }]}
        >
          <Select
            showSearch
            loading={
              getDeliveryPauseReasonListIdle || gettingDeliverPauseReasonList
            }
            placeholder="选择暂停原因分类"
            filterOption={HospSelectFilter}
            options={deliverPauseReasonList?.map((i) => ({
              label: `${i.name}`,
              value: i.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="pause_reason"
          label="暂停原因"
          rules={[{ required: true, message: "必须填写暂停原因" }]}
        >
          <TextArea placeholder="填写暂停原因" autoSize={{ minRows: 3 }} />
        </Form.Item>
        <Form.Item
          name="next_entry_date"
          label="下次跟进日期"
          rules={[{ required: true, message: "必须选择下次跟进日期" }]}
        >
          <DatePicker
            style={{ width: "200px" }}
            placeholder="请选择下次跟进日期"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
