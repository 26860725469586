import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Form,
  Input,
  Space,
  TextArea,
  Toast,
  Picker,
  Selector,
  Skeleton,
  DatePicker,
  DatePickerRef,
} from "antd-mobile";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";
import { AddOutline } from "antd-mobile-icons";
import { Upload } from "antd";
import { UploadFile } from "antd/es/upload/interface";
import dayjs from "dayjs";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import {
  DateFormate,
  DisposeForm,
  DisposeMobileText,
  GpuNumberList,
  handleUploadImage,
  initFileList,
} from "utils";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import {
  ADD_FILE,
  GET_GPU_MODEL,
  GET_GPU_OEM,
  GET_HOS_PRODUCT_LIST,
  UPDATE_GPU_INFO,
} from "lib/api";
import {
  ConvertDateToPickerValueList,
  ConvertToPickerValueList,
  MobileLimitUploadImage,
} from "authenticated-app/mobile/util";
import {
  GPUModel,
  GPUOem,
  GpuType,
  HospProductsListType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";

const now = new Date();

export const MobileGpuInfoEdit = ({
  hospID,
  editingItem,
  visible,
  setVisible,
  reload,
}: {
  hospID: string | number | undefined;
  editingItem: GpuType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [editOemID, setEditOemID] = useState<number>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const {
    run: getGPUOemList,
    isLoading: gettingGPUOemList,
    data: gpuOemList,
  } = useAsync<GPUOem[]>();
  const getGPUOemListFunc = () => {
    getGPUOemList(client(GET_GPU_OEM));
  };

  const {
    run: getGPUModelList,
    isLoading: gettingGPUModelList,
    data: gpuModelList,
  } = useAsync<GPUModel[]>();
  const getGPUModelListFunc = (org_id: string | number) => {
    getGPUModelList(client(GET_GPU_MODEL(org_id)));
  };

  const {
    run: getHosProductList,
    isLoading: gettingHosProductList,
    data: hospProductList,
  } = useAsync<HospProductsListType[]>();
  const getHosProductListFunc = (hospital_id: number | string) => {
    getHosProductList(client(GET_HOS_PRODUCT_LIST(hospital_id)));
  };

  const { run: submit, isLoading: submitting } = useAsync();
  const submitHandle = (value: any) => {
    if (editingItem?.id)
      submit(
        client(UPDATE_GPU_INFO(editingItem?.id), {
          data: {
            // ...value,
            slot_num: value?.slot_num_value
              ? parseInt(value?.slot_num_value[0])
              : null,
            gpu_oem_id: value?.gpu_oem ? parseInt(value?.gpu_oem[0]) : null,
            gpu_type_id: value?.gpu_type ? parseInt(value?.gpu_type[0]) : null,
            assets_code: value.assets_code || null,
            uuid: value.uuid || null,
            change_date: value?.change_date_value
              ? dayjs(value?.change_date_value).format(DateFormate)
              : null,
            products: value.products || null,
            asset_photos: fileList.map((i) => i.uid),
            remark: value.remark || null,
          },
          method: "PUT",
        }),
      )
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="更新成功" />,
          });
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="更新失败" />,
          });
        });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法提交" />,
      });
  };

  const clear = () => {
    form.setFieldsValue({
      gpu_type_id: null,
    });
  };

  useEffect(() => {
    if (editOemID) {
      getGPUModelListFunc(editOemID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOemID]);

  useEffect(() => {
    if (visible) {
      setFileList(initFileList(editingItem?.asset_photos) || []);
      form.setFieldsValue({
        slot_num_value: ConvertToPickerValueList(editingItem?.slot_num),
        gpu_oem: ConvertToPickerValueList(editingItem?.gpu_oem_id),
        gpu_type: ConvertToPickerValueList(editingItem?.gpu_type_id),
        assets_code: editingItem?.assets_code || "",
        uuid: editingItem?.uuid || "",
        change_date_value: ConvertDateToPickerValueList(
          editingItem?.change_date,
        ),
        products: editingItem?.products?.map((i) => i?.product_id),
        remark: editingItem?.remark || "",
        asset_photos: fileList.map((i) => i.uid),
      });
      getGPUOemListFunc();
      if (hospID) {
        getHosProductListFunc(hospID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (editingItem) {
      if (editingItem?.gpu_oem_id) {
        setEditOemID(editingItem?.gpu_oem_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  return (
    <EditPopup
      visible={visible}
      submitting={submitting}
      setVisible={setVisible}
    >
      <Form
        form={form}
        onFinish={submitHandle}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={submitting}
          >
            提交
          </Button>
        }
      >
        <Form.Header>新增显卡信息</Form.Header>
        <Form.Item
          name="slot_num_value"
          label="卡槽编号"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择卡槽编号" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            columns={
              GpuNumberList
                ? [
                    GpuNumberList.map((i) => ({
                      label: i,
                      value: i.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择卡槽编号</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="gpu_oem"
          label="显卡品牌"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择显卡品牌" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingGPUOemList}
            columns={
              gpuOemList
                ? [
                    gpuOemList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
            onConfirm={(v) => {
              clear();
              setEditOemID(v[0] ? parseInt(v[0] as string) : undefined);
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择显卡品牌</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="gpu_type"
          label="显卡型号"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择显卡型号" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingGPUModelList}
            columns={
              gpuModelList
                ? [
                    gpuModelList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择显卡型号</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="assets_code"
          label="资产编码"
          rules={[{ required: true, message: "必须输入资产编码" }]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input
            style={{ "--text-align": "right" }}
            placeholder="请输入资产编码"
          />
        </Form.Item>
        <Form.Item
          name="uuid"
          label="UUID"
          rules={[{ required: true, message: "必须输入UUID" }]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input style={{ "--text-align": "right" }} placeholder="请输入UUID" />
        </Form.Item>
        <Form.Item
          name="change_date_value"
          label="更换时间"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择更换时间" }]}
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
        >
          <DatePicker tillNow max={now}>
            {(value) =>
              value ? (
                <FormItemDiv>{dayjs(value).format(DateFormate)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择更换时间</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>
        <Form.Item
          name="products"
          label="运行产品线"
          normalize={(v) => DisposeForm(v)}
          layout="vertical"
        >
          {gettingHosProductList ? (
            <Skeleton.Paragraph animated />
          ) : (
            <Selector
              multiple
              options={
                hospProductList
                  ? hospProductList.map((i) => ({
                      label: i.product_id,
                      value: i.product_id,
                    }))
                  : []
              }
            />
          )}
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
          help="额外的补充信息"
          normalize={(v) => DisposeMobileText(v)}
          layout={"vertical"}
        >
          <TextArea autoSize placeholder="输入备注" />
        </Form.Item>
        <Form.Item
          name="asset_photos"
          className="adm-form-item-label-attachment"
          label="资产照片 (数量限制3个)"
          normalize={(v) => DisposeForm(v)}
          layout={"vertical"}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={3}
            className="upload-list-inline"
            action={ADD_FILE}
            listType="picture"
            fileList={fileList}
            beforeUpload={MobileLimitUploadImage}
            onChange={(info) => handleUploadImage(info, setFileList)}
          >
            {fileList.length >= 3 ? null : (
              <Button color="primary" fill="outline">
                <Space>
                  <AddOutline />
                  <span>点击上传</span>
                </Space>
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
