import { useEffect, Dispatch, SetStateAction } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  Input,
  message,
  DatePicker,
  Select,
} from "antd";

import { useHttp } from "utils/http";
import { ProductVersionType } from "../interface";
import { DisabledDate, DisposeForm, formatDate, selectFilter } from "utils";
import { GET_PRODUCT_VERSION } from "lib/api";
import { useAsync } from "utils/hooks/useAsync";

const { TextArea } = Input;
const { Option } = Select;

export const UpdateRecordAdd = ({
  orgID,
  ADD_RETAIN,
  visible,
  setVisible,
  reload,
}: {
  orgID: string | number | null | undefined;
  ADD_RETAIN: (orgID: string | number) => string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getProductVersionList,
    isLoading: gettingProductVersionList,
    data: productVersionList,
  } = useAsync<ProductVersionType[]>();
  const getProductVersionListFunc = () => {
    getProductVersionList(client(GET_PRODUCT_VERSION));
  };

  const submit = (value: any) => {
    if (orgID)
      client(ADD_RETAIN(""), {
        data: {
          ...value,
          product_id: orgID,
          update_date: formatDate(value?.update_date),
        },
        method: "POST",
      })
        .then(() => {
          message.success("添加成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("添加失败，请检查或联系管理员！");
        });
    else message.error("无法添加，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      getProductVersionListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="新增产品升级记录"
      destroyOnClose
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="product_version_id"
          label="安装分支"
          rules={[{ required: true, message: "必须选择安装分支" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            showSearch
            filterOption={selectFilter}
            loading={gettingProductVersionList}
            placeholder="选择安装分支"
          >
            {productVersionList?.map((i, index) => (
              <Option key={index} value={i?.id}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="update_date"
          label="更新时间"
          rules={[{ required: true, message: "必须选择更新时间" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <DatePicker
            placeholder="选择更新时间"
            picker={"date"}
            disabledDate={DisabledDate}
          />
        </Form.Item>
        <Form.Item
          name="remark"
          label="注意事项"
          normalize={(v) => DisposeForm(v)}
        >
          <TextArea allowClear />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
