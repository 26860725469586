import { useState, Fragment } from "react";
import { Button, Descriptions, Space, message, Popconfirm } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ProTable, ProColumns } from "@ant-design/pro-components";
import { useHttp } from "utils/http";
import { ProductUpdateRecordType } from "../interface";
import { RetainAPI } from "../../../interface";
import { UpdateRecordAdd } from "./add";
import { UpdateRecordEdit } from "./edit";

const UpdateRecord = ({
  orgID,
  retain,
  RetainAPI,
  reload,
}: {
  orgID: string | number | null | undefined;
  retain: ProductUpdateRecordType[] | undefined;
  RetainAPI: RetainAPI;
  reload: () => void;
}) => {
  const [addRetainVisible, setAddRetainVisible] = useState(false);
  const [editRetainInfo, setEditRetainInfo] =
    useState<ProductUpdateRecordType>();
  const [editRetainInfoVisible, setEditRetainInfoVisible] = useState(false);

  const client = useHttp();

  const delRetain = (id: number) => {
    if (id)
      client(RetainAPI.DEL_RETAIN(id), { method: "DELETE" })
        .then(() => {
          message.success("删除成功！");
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("删除失败，请联系管理员！");
        });
  };

  const columns: ProColumns<ProductUpdateRecordType>[] = [
    {
      title: "安装分支",
      dataIndex: "product_version_name",
    },
    {
      title: "更新时间",
      dataIndex: "update_date",
      valueType: "date",
    },
    {
      title: "更新人",
      dataIndex: "update_user",
    },
    {
      title: "注意事项",
      dataIndex: "remark",
    },
    {
      title: "操作",
      valueType: "option",
      width: 120,
      render: (text, item, _, action) => [
        <Space>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            loading={editRetainInfoVisible && item.id === editRetainInfo?.id}
            onClick={() => {
              setEditRetainInfo(item);
              setEditRetainInfoVisible(true);
            }}
          />
          <Popconfirm
            title={`确认删除【${item.product_version_name}】的记录？`}
            onConfirm={() => delRetain(item.id)}
            okText="确认"
            cancelText="取消"
            icon={<ExclamationCircleOutlined />}
            style={{ color: "red" }}
          >
            <Button
              danger
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>,
      ],
    },
  ];
  const RetainInfoDescItem = (item: ProductUpdateRecordType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`updateRecord${key}`}>
        <Descriptions.Item label={title("安装分支")} className={className()}>
          {item?.product_version_name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("更新时间")} className={className()}>
          {item?.update_date || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("更新人")} className={className()}>
          {item?.update_user || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("注意事项")} className={className()}>
          {item?.remark || ""}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("操作")}
          className={className()}
        >
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              loading={editRetainInfoVisible && item.id === editRetainInfo?.id}
              onClick={() => {
                setEditRetainInfo(item);
                setEditRetainInfoVisible(true);
              }}
            />
            <Popconfirm
              title={`确认删除【${item.product_version_name}】的记录？`}
              onConfirm={() => delRetain(item.id)}
              okText="确认"
              cancelText="取消"
              icon={<ExclamationCircleOutlined />}
              style={{ color: "red" }}
            >
              <Button
                danger
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <ProTable<ProductUpdateRecordType>
        size="small"
        columns={columns}
        dataSource={retain || []}
        rowKey="id"
        pagination={false}
        search={false}
        options={{
          reload: reload,
        }}
        toolbar={{
          title: <span style={{ fontWeight: "bold" }}>产品升级记录</span>,
          actions: [
            <Button
              key="add"
              type="default"
              icon={<PlusOutlined />}
              onClick={() => setAddRetainVisible(true)}
            >
              新增
            </Button>,
          ],
          settings: [],
        }}
        bordered={true}
      />
      {/* <Descriptions
        column={5}
        bordered={retain?.length ? true : false}
        title="产品升级记录"
        layout="vertical"
        extra={
          <Button
            loading={addRetainVisible}
            icon={<PlusOutlined />}
            onClick={() => {
              setAddRetainVisible(true);
            }}
          >
            新增
          </Button>
        }
      >
        {retain?.map((item, index) => RetainInfoDescItem(item, index))}
      </Descriptions> */}
      <UpdateRecordAdd
        orgID={orgID}
        ADD_RETAIN={RetainAPI.ADD_RETAIN}
        visible={addRetainVisible}
        setVisible={setAddRetainVisible}
        reload={reload}
      />
      <UpdateRecordEdit
        editingItem={editRetainInfo}
        UPDATE_RETAIN={RetainAPI.UPDATE_RETAIN}
        visible={editRetainInfoVisible}
        setVisible={setEditRetainInfoVisible}
        reload={reload}
      />
    </Fragment>
  );
};

export default UpdateRecord;
