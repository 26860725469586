import { ReactNode, createContext, useContext, useState } from "react";
import { env } from "dingtalk-jsapi";

import * as auth from "./provider";
import { useMount } from "utils/hooks/useMount";
import { User } from "interface";
import { http } from "utils/http";
import { GET_ME } from "lib/api";
import { useAsync } from "utils/hooks/useAsync";
import { useDebounceFn } from "ahooks";

const bootstrapUser = async () => {
  let user = null;
  const token = auth.getToken();
  if (token) {
    try {
      const data = await http(GET_ME, { token });
      user = data;
    } catch {
      auth.logout();
    }
  }
  return user;
};

const AuthContext = createContext<
  | {
      user: User | null;
      logout: () => Promise<void>;
      isLogining: boolean;
      isIdle: boolean;
    }
  | undefined
>(undefined);

AuthContext.displayName = "InferCsmAuthContext";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const {
    data: user,
    run: getUser,
    setData: setUser,
  } = useAsync<User | null>();

  const [isIdle, setIsIdle] = useState(true);
  const [isLogining, setIsLogining] = useState(false);

  const logout = () =>
    auth.logout().then(() => {
      setUser(null);
    });

  const login = () => auth.login();

  const { run } = useDebounceFn(
    () => {
      getUser(bootstrapUser())
        .then((user: User) => {
          if (env.platform !== "notInDingTalk" && !user) {
            login();
          }
        })
        .finally(() => setIsLogining(false));
    },
    {
      wait: 200,
    },
  );

  useMount(() => {
    setIsIdle(false);
    setIsLogining(true);
    run();
  });

  return (
    <AuthContext.Provider value={{ user, logout, isLogining, isIdle }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth必须在AuthProvider中使用");
  }
  return context;
};
