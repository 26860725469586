import { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "context/auth";
import { ThemeProvider } from "context/theme";
import { PermissionProvider } from "context/permission";

export const AppProvider = ({ children }: { children: ReactNode }) => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider>
          <PermissionProvider>{children}</PermissionProvider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
};
