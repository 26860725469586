import { useState, Fragment } from "react";
import { Button, Descriptions, Space, message, Popconfirm } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ProTable, ProColumns } from "@ant-design/pro-components";
import { useHttp } from "utils/http";
import { DEL_DOCKING_VERSION_INFO } from "lib/api";
import { DockingVersionType } from "../interface";
import { DockingVersionInfoAdd } from "./add";
import { DockingVersionInfoEdit } from "./edit";

const title = "对接版本信息";

const DockingVersionInfo = ({
  info,
  hospID,
  reload,
}: {
  info: DockingVersionType[] | undefined;
  hospID: number | null;
  reload: () => void;
}) => {
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [editingItem, setEditingItem] = useState<DockingVersionType>();

  const client = useHttp();

  const del = (id: number) => {
    client(DEL_DOCKING_VERSION_INFO(id), { method: "DELETE" })
      .then(() => {
        message.success("删除成功！");
        reload();
      })
      .catch((e) => {
        console.error(e);
        message.error("删除失败，请联系管理员！");
      });
  };

  const columns: ProColumns<DockingVersionType>[] = [
    {
      title: "IP",
      dataIndex: "ip",
    },
    {
      title: "DS版本",
      dataIndex: "version_name",
    },
    {
      title: "备注",
      dataIndex: "remark",
    },
    {
      title: "操作",
      valueType: "option",
      width: 120,
      render: (text, record, _, action) => [
        <Space>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            loading={editVisible && record.id === editingItem?.id}
            onClick={() => {
              setEditingItem(record);
              setEditVisible(true);
            }}
          />
          <Popconfirm
            title={`确认删除【${record.version_name}】的信息？`}
            onConfirm={() => del(record.id)}
            okText="确认"
            cancelText="取消"
            icon={<ExclamationCircleOutlined />}
            style={{ color: "red" }}
          >
            <Button
              danger
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>,
      ],
    },
  ];

  const DescItem = (item: DockingVersionType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");

    return (
      <Fragment key={`eqptRecordDescItem${key}`}>
        <Descriptions.Item label={title("IP")} className={className()}>
          {item?.ip || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("DS版本")} className={className()}>
          {item?.version_name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("备注")} className={className()}>
          {item?.remark || ""}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("操作")}
          className={className()}
        >
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              loading={editVisible && item.id === editingItem?.id}
              onClick={() => {
                setEditingItem(item);
                setEditVisible(true);
              }}
            />
            <Popconfirm
              title={`确认删除【${item.version_name}】的信息？`}
              onConfirm={() => del(item.id)}
              okText="确认"
              cancelText="取消"
              icon={<ExclamationCircleOutlined />}
              style={{ color: "red" }}
            >
              <Button
                danger
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <ProTable<DockingVersionType>
        size="small"
        columns={columns}
        rowKey="id"
        dataSource={info}
        dateFormatter="string"
        pagination={false}
        search={false}
        options={{
          reload: reload,
        }}
        toolbar={{
          title: <span style={{ fontWeight: "bold" }}>对接版本信息</span>,
          actions: [
            <Button
              key="add"
              type="default"
              icon={<PlusOutlined />}
              onClick={() => setAddVisible(true)}
            >
              新增
            </Button>,
          ],
          settings: [],
        }}
        bordered={info?.length ? true : false}
      />
      <DockingVersionInfoAdd
        title={title}
        hospID={hospID}
        visible={addVisible}
        setVisible={setAddVisible}
        reload={reload}
      />
      <DockingVersionInfoEdit
        title={title}
        hospID={hospID}
        editingItem={editingItem}
        visible={editVisible}
        setVisible={setEditVisible}
        reload={reload}
      />
    </Fragment>
  );
};

export default DockingVersionInfo;
