import { ErrorBlock } from "antd-mobile";
import { Checklist, Tool, People, ExchangeFour } from "@icon-park/react";
import { MobileUserList } from "./customer";
import { MobileTicketList } from "./checklist";
import { MobileBreakList } from "./breaklist";
import { MobileLaunchChangeList } from "./launchChangeList";

export const userListKey = "user";
export const checkListKey = "check";
export const breakListKey = "break";
export const launchChangeKey = "launchChange";

export const tabs = [
  {
    key: userListKey,
    title: "客户列表",
    icon: <People theme="outline" />,
  },
  // {
  //   key: checkListKey,
  //   title: "任务",
  //   icon: <Checklist theme="outline" />,
  // },
  {
    key: breakListKey,
    title: "客户反馈",
    icon: <Tool theme="outline" />,
  },
  {
    key: launchChangeKey,
    title: "上线变更",
    icon: <ExchangeFour theme="outline" />,
  },
];

export const ListBodyComponent = ({ tabKey }: { tabKey: string }) => {
  switch (tabKey) {
    case userListKey:
      return <MobileUserList />;
    // case checkListKey:
    //   return <MobileTicketList />;
    case breakListKey:
      return <MobileBreakList />;
    case launchChangeKey:
      return <MobileLaunchChangeList />;
    default:
      return <ErrorBlock status="empty" />;
  }
};
