export type IdType = number | string;

// 医院详情
// hospitals
export const HOSP_LIST_API = "/rommanAPI/hospitals/list/";
export const GET_HOSP_PWD = (hospID: string | number) =>
  `/rommanAPI/hospitals/get_passwd/${hospID}/`;
export const GET_HOSP_DETAIL = (hospID: string | number) =>
  `/rommanAPI/hospitals/hospital/${hospID}/`;
export const GET_HOSP_TYPE = "/rommanAPI/hospitals/hospital_types/";
export const GET_HOSP_NODES = "/rommanAPI/hospitals/_getHostpitalNodes";
export const GET_HOSP_CITY = (provinceID: number) =>
  `/rommanAPI/hospitals/${provinceID}/citys/`;
export const GET_HOSP_COUNTY = (hosCity: number) =>
  `/rommanAPI/hospitals/get_counties/${hosCity}/`;
export const UPDATE_HOSP_INFO = (hospID: string | number) =>
  `/rommanAPI/hospitals/hospital/${hospID}/`;
export const GET_OUTPUT = "/rommanAPI/hospitals/list/";

export const GET_LICENSE_INFO = (hospID: number | string) =>
  `/rommanAPI/hospitals/licenses/${hospID}/`;

// hos_user
export const GET_HOSP_USER_TYPE = "/rommanAPI/hos_user/user_type";
export const ADD_HOSP_USER_TYPE = "/rommanAPI/hos_user/user_type";
export const ADD_HOSP_USER = "/rommanAPI/hos_user/users/";
export const UPDATE_HOSP_USER = (org_id: string | number) =>
  `/rommanAPI/hos_user/${org_id}/`;
export const GET_HOSP_CONTACT = "/rommanAPI/hos_user/contact/";
export const DEL_HOSP_USER = (hospID: string | number) =>
  `/rommanAPI/hos_user/${hospID}/`;

// user
export const GET_USER_TYPE = "/csmAPI/api/user/user_type";
export const GET_DEPARTMENT_LIST = "/csmAPI/api/departments/list/";
export const GET_DEPARTMENT_USER = (departmentID: number) =>
  `/csmAPI/api/departments/${departmentID}/users/`;
export const UPDATE_USER = (hospID: string | number) =>
  `/rommanAPI/maintainers/_updateMaintainers/${hospID}/`;

// retain
export const ADD_FILE = "/rommanAPI/system/uploadFile/";
export const ADD_RETAIN = (hospID: string | number) =>
  `/rommanAPI/hospitals/create_retain/${hospID}/`;
export const UPDATE_RETAIN = (org_id: number) => `/rommanAPI/retain/${org_id}/`;
export const DEL_RETAIN = (org_id: number) => `/rommanAPI/retain/${org_id}/`;

// 硬件信息
export const GET_SYS_VERSION = "/rommanAPI/service_record/system_version/";

// export const GET_PRODUCT = "/rommanAPI/product/products/";
export const GET_HOS_PRODUCT_LIST = (hospID: number | string) =>
  `/rommanAPI/product/products/${hospID}/`;

export const GET_HARDWARES_LIST = (hospID: number | string) =>
  `/rommanAPI/service_record/hardwares/list/${hospID}/`;
export const ADD_HARDWARE = "/rommanAPI/service_record/hardwares/";
export const UPDATE_HARDWARE = (org_id: number | string) =>
  `/rommanAPI/service_record/hardwares/${org_id}`;
export const DEL_HARDWARE = (org_id: number | string) =>
  `/rommanAPI/service_record/hardwares/${org_id}`;

// 竞品信息
export const GET_COMPETITIVE_PRODUCT = (hospID: number | string) =>
  `/rommanAPI/competitive_product/list/${hospID}/`;
export const ADD_COMPETITIVE_PRODUCT = `/rommanAPI/competitive_product/`;
export const GET_COMPETITIVE_PRODUCT_DETAIL = (detailId: number | string) =>
  `/rommanAPI/competitive_product/${detailId}/`;
export const DELETE_COMPETITIVE_PRODUCT = (detailId: number | string) =>
  `/rommanAPI/competitive_product/${detailId}`;
export const UPDATE_COMPETITIVE_PRODUCT = (detailId: number | string) =>
  `/rommanAPI/competitive_product/${detailId}`;

// 医院设备类型设置
export const GET_HOS_EQPT = "/rommanAPI/service_record/hos_eqpt_type";

export const GET_EQPT_TYPE = "/rommanAPI/service_record/eqpt/type";
export const ADD_EQPT_TYPE = "/rommanAPI/service_record/eqpt/type";
export const GET_EQPT_OEM = (org_id: number | string) =>
  `/rommanAPI/service_record/eqpt/oem/${org_id}`;
export const ADD_EQPT_OEM = "/rommanAPI/service_record/eqpt/oem/";
export const GET_EQPT_MODEL = (org_id: number | string) =>
  `/rommanAPI/service_record/eqpt/model/${org_id}`;

export const ADD_HARDWARE_MATTER = "/rommanAPI/service_record/other_info/";
export const UPDATE_HARDWARE_MATTER = (org_id: number | string) =>
  `/rommanAPI/service_record/other_info/${org_id}/`;
export const DEL_HARDWARE_MATTER = (org_id: number) =>
  `/rommanAPI/service_record/other_info/${org_id}/`;

export const ADD_NETWORK = "/rommanAPI/service_record/network";
export const UPDATE_NETWORK = (network_id: number) =>
  `/rommanAPI/service_record/network/${network_id}/`;
export const DEL_NETWORK = (network_id: number) =>
  `/rommanAPI/service_record/network/${network_id}`;

export const GET_GPU_LIST = "/rommanAPI/service_record/gpu_type";
export const GET_GPU_OEM = "/rommanAPI/service_record/gpu_oem";
export const GET_GPU_MODEL = (org_id: number | string) =>
  `/rommanAPI/service_record/gpu_type/${org_id}`;
export const ADD_GPU_INFO = "/rommanAPI/service_record/gpu";
export const UPDATE_GPU_INFO = (gpu_id: number) =>
  `/rommanAPI/service_record/gpu/${gpu_id}`;
export const DEL_GPU_INFO = (gpu_id: number) =>
  `/rommanAPI/service_record/gpu/${gpu_id}/`;

export const UPDATE_MULT = (hospID: number | string) =>
  `/rommanAPI/hospitals/mult/${hospID}/`;

export const ADD_HARDWARE_RETAIN = (hospID: number | string) =>
  `/rommanAPI/service_record/hardwares/create_retain/${hospID}/`;
export const UPDATE_HARDWARE_RETAIN = (org_id: number) =>
  `/rommanAPI/retain/${org_id}/`;
export const DEL_HARDWARE_RETAIN = (org_id: number) =>
  `/rommanAPI/retain/${org_id}/`;

// 对接信息
export const GET_DOCKING_INFO = (hospID: number | string) =>
  `/rommanAPI/service_record/docking/list/${hospID}/`;
export const UPDATE_DOCKING_INFO = (org_id: number | string) =>
  `/rommanAPI/service_record/docking/${org_id}/`;
export const ADD_DOCKING_INFO = "/rommanAPI/service_record/docking";
export const DEL_DOCKING_INFO = (org_id: number | string) =>
  `/rommanAPI/service_record/docking/${org_id}/`;

export const GET_DOCKING_TYPE = "/rommanAPI/service_record/docking/type/";
export const GET_DOCKING_WAY = "/rommanAPI/service_record/docking/way/";

export const ADD_EQPT_RECORD = "/rommanAPI/service_record/eqpt";
export const UPDATE_EQPT_RECORD = (org_id: number | string) =>
  `/rommanAPI/service_record/eqpt/${org_id}/`;
export const DEL_EQPT_RECORD = (org_id: number | string) =>
  `/rommanAPI/service_record/eqpt/${org_id}/`;

export const ADD_DOCKING_VERSION_INFO = "/rommanAPI/service_record/ds_info/";
export const UPDATE_DOCKING_VERSION_INFO = (org_id: number | string) =>
  `/rommanAPI/service_record/ds_info/${org_id}/`;
export const DEL_DOCKING_VERSION_INFO = (org_id: number | string) =>
  `/rommanAPI/service_record/ds_info/${org_id}/`;

export const ADD_DOCKING_RETAIN = (hospID: number | string) =>
  `/rommanAPI/service_record/docking/create_retain/${hospID}/`;
export const UPDATE_DOCKING_RETAIN = (org_id: number) =>
  `/rommanAPI/retain/${org_id}/`;
export const DEL_DOCKING_RETAIN = (org_id: number) =>
  `/rommanAPI/retain/${org_id}/`;
// 数据接收信息
export const GET_DATA_RECEIVE_FILTER_INFO = (hospID: number | string) =>
  `/rommanAPI/service_record/filter_info/${hospID}/`;

// 设备接收数据信息
export const GET_DEVICE_DATA_RECEIVE_INFO = (hospID: number | string) =>
  `/rommanAPI/service_record/study_statics/${hospID}/`;

export const GET_PRODUCT_DETAIL = (hospID: number | string) =>
  `/rommanAPI/product/list/${hospID}/`;

export const GET_USED_PRODUCT_DETAIL = (hospID: number | string) =>
  `/rommanAPI/product/list/${hospID}/?using_status_id=1`;

export const GET_PRODUCT_DETAIL_HAS_NOTONLINE = (hospID: number | string) =>
  `/rommanAPI/product/list/${hospID}?has_not_online=true`;

export const ADD_PRODUCT_DETAIL = "/rommanAPI/product/";
export const UPDATE_PRODUCT_DETAIL = (org_id: number | string) =>
  `/rommanAPI/product/${org_id}/`;
export const DEL_PRODUCT_DETAIL = (org_id: number | string) =>
  `/rommanAPI/product/${org_id}/`;

export const UPDATE_CUSTOMIZATION = (org_id: number | string) =>
  `/rommanAPI/product/customization/${org_id}/`;

export const GET_PRODUCT_CLIENT = (product_id: string) =>
  `/rommanAPI/product/product_client_version_relation?product_id=${product_id}`;

export const GET_PRODUCT_LIST = "/rommanAPI/product/products";
export const GET_PRODUCT_VERSION = "/rommanAPI/product/version";
export const GET_PRODUCT_VERSION_BY_ID = (product_id: string) =>
  `/rommanAPI/product/version?product_id=${product_id}`;
export const ADD_PRODUCT_VERSION = "/rommanAPI/product/version";

export const UPDATE_GLOBAL_PRODUCT_VERSION = (org_id: number) =>
  `/rommanAPI/product/special_hospital_product/${org_id}/`;

export const ADD_GLOBAL_PRODUCT_VERSION =
  "/rommanAPI/product/special_hospital_product/";
export const GET_DEPLOY_TYPE = "/rommanAPI/product/deploy_type";
export const ADD_DEPLOY_TYPE = "/rommanAPI/product/deploy_type";

export const GET_SERVICE_TYPE = "/rommanAPI/product/service_type";
export const ADD_SERVICE_TYPE = "/rommanAPI/product/service_type";

export const GET_PRODUCT_DEPARTMENT = "/csmAPI/api/departments/department_type";
export const ADD_PRODUCT_DEPARTMENT = "/csmAPI/api/departments/department_type";

export const GET_USING_STATUS = "/rommanAPI/product/using_status";
export const ADD_USING_STATUS = "/rommanAPI/product/using_status";

export const GET_SERVICE_LEVEL = "/rommanAPI/product/service_level";
export const ADD_SERVICE_LEVEL = "/rommanAPI/product/service_level";

export const ADD_PRODUCT_RECORD = "/rommanAPI/product/update_record";
export const UPDATE_PRODUCT_RECORD = (org_id: number) =>
  `/rommanAPI/product/update_record/${org_id}/`;
export const DEL_PRODUCT_RECORD = (org_id: number) =>
  `/rommanAPI/product/update_record/${org_id}/`;

export const ADD_PRODUCT_RETAIN = (org_id: number | string) =>
  `/rommanAPI/product/create_retain/${org_id}/`;
export const UPDATE_PRODUCT_RETAIN = (org_id: number) =>
  `/rommanAPI/retain/${org_id}/`;
export const DEL_PRODUCT_RETAIN = (org_id: number) =>
  `/rommanAPI/retain/${org_id}/`;

export const IMPORT_MAINTENANCE = "/rommanAPI/product/add_maintenance/";

// 远程信息
export const GET_REMOTE_DETAIL = (hospID: number | string) =>
  `/rommanAPI/remote/${hospID}/`;

export const GET_REMOTE_TYPE = "/rommanAPI/remote/types";
export const ADD_REMOTE_TYPE = "/rommanAPI/remote/types";

export const UPDATE_REMOTE_STATUS = (hospID: number | string) =>
  `/rommanAPI/remote/${hospID}/`;

export const ADD_REMOTE_RETAIN = (hospID: number | string) =>
  `/rommanAPI/remote/create_retain/${hospID}/`;
export const UPDATE_REMOTE_RETAIN = (org_id: number) =>
  `/rommanAPI/retain/${org_id}/`;
export const DEL_REMOTE_RETAIN = (org_id: number) =>
  `/rommanAPI/retain/${org_id}/`;

// 客户端信息
export const GET_CLIENT_TYPE_LIST = "/rommanAPI/client_info/type";
export const GET_CLIENT_TYPE = (hospID: number | string) =>
  `/rommanAPI/client_info/client_type/${hospID}/`;
export const UPDATE_CLIENT_TYPE = (hospID: number | string) =>
  `/rommanAPI/client_info/client_type/${hospID}/`;

export const GET_CLIENT_DETAIL = (hospID: number | string) =>
  `/rommanAPI/client_info/${hospID}/`;
export const UPDATE_CLIENT_DETAIL = (hospID: number | string) =>
  `/rommanAPI/client_info/${hospID}/`;

export const GET_CLIENT_VERSION = "/rommanAPI/client_info/version";
export const ADD_CLIENT_VERSION = "/rommanAPI/client_info/version";

export const GET_IDENTIFY_WAY = "/rommanAPI/client_info/identify_way";
export const ADD_IDENTIFY_WAY = "/rommanAPI/client_info/identify_way";

export const GET_CUP_TYPE = "/rommanAPI/client_info/cpu_type";
export const ADD_CUP_TYPE = "/rommanAPI/client_info/cpu_type";

export const ADD_DOCTOR_PC_INFO = "/rommanAPI/client_info/doctor_pc_info";
export const UPDATE_DOCTOR_PC_INFO = (org_id: number) =>
  `/rommanAPI/client_info/doctor_pc_info/${org_id}/`;
export const DEL_DOCTOR_PC_INFO = (org_id: number) =>
  `/rommanAPI/client_info/doctor_pc_info/${org_id}/`;

export const ADD_CLIENT_RETAIN = (hospID: number | string) =>
  `/rommanAPI/client_info/create_retain/${hospID}/`;
export const UPDATE_CLIENT_RETAIN = (org_id: number) =>
  `/rommanAPI/retain/${org_id}/`;
export const DEL_CLIENT_RETAIN = (org_id: number) =>
  `/rommanAPI/retain/${org_id}/`;

// login
export const LOGIN = "/csmAPI/api/auth/login";
export const GET_ME = "/csmAPI/api/auth/me/";

export const GET_PERMISSION = "/rommanAPI/system/urlPermission/";
export const GET_STATISTIC_PERMISSION =
  "/csmAPI/api/resources/statisticsPermitted/";

// rate
// ?unit=week&product_line=CT_LUNG&start=2021-11-09&end=2022-01-04&hospital_id=120
export const GET_RATE = "/rommanAPI/click_rate/display/";
export const ADD_RATE_MANUALLY = "/rommanAPI/click_rate/set_click_info/";
export const ADD_RATE_FILE = "/rommanAPI/click_rate/add_by_file/";
export const ADD_RATE_QR = "/rommanAPI/click_rate/qr_code/";

// mobile scan
export const SERVICE_RECORD = "/rommanAPI/service_record/qr_code_hospital/";
export const SERVICE_RECORD_CONFIRM = "/rommanAPI/service_record/qr_code/";

// statitscis
export const GET_DASHBOARD_STATISTICS = "/ticketAPI/dashboard/statistics";
export const GET_BREAK_TOTAL_MONTH_STATISTICS =
  "/ticketAPI/dashboard/monthly_statistics";
export const GET_BREAK_ISSUES_MONTH_STATISTICS =
  "/ticketAPI/dashboard/issues_monthly_statistics";
export const GET_BREAK_ISSUES_TOP = "/ticketAPI/dashboard/issues_tops";
export const GET_BREAK_ISSUES_TOP_STATISTICS =
  "/ticketAPI/dashboard/issues_statistics";

export const GET_ISSUES_PROCESS_LIST =
  "/ticketAPI/dashboard/issues_process_list";

export const GET_ISSUES_PROCESS_STATISTICS =
  "/ticketAPI/dashboard/issues_process_list_statistics";

export const GET_BREAK_OVER_LIST = "/ticketAPI/dashboard/issues_process_list"; // wait update.

export const GET_BREAK_FEEDBACKUP_PIE_DATA =
  "/ticketAPI/dashboard/percentage_statistics";

export const GET_BREAK_DISTRIBUTION_STATISTICS_LIST =
  "/ticketAPI/dashboard/distribution_statistics";

export const GET_BREAK_SUMMARY_STATISTICS =
  "/ticketAPI/dashboard/summary_statistics";

export const GET_BREAK_PRODUCT_LINE_STATISTICS =
  "/ticketAPI/dashboard/product_line_statistics";

export const GET_USER_LIST = "/csmAPI/api/departments/user/list/";

export const ADD_COMMENTS = "/ticketAPI/issues/comment";

export const UPDATE_COMMENT = (id: IdType) => `/ticketAPI/issues/comment/${id}`;

export const GET_LABEL_LIST = "/ticketAPI/issues/label";

export const ADD_LABEL = "/ticketAPI/issues/label";

export const UPDATE_ISSUES_LAEL = (id: IdType) =>
  `/ticketAPI/issues/label/issues/${id}`;

// predict info
export const GET_PREDICT_INFO = (id: IdType) =>
  `/rommanAPI/hospitals/predict_number/${id}`;
export const GET_PREDICT_TYPE = (id: IdType) =>
  `/rommanAPI/hospitals/predict_type/${id}`;
export const GET_PREDICT_TASK_TYPE = (hospID: string | number) =>
  `/rommanAPI/service_record/type_name/${hospID}/`;
export const GET_PREDICT_TIME_INFO = (hospID: string | number) => {
  return `/rommanAPI/service_record/predict_info/${hospID}/`;
};
export const GET_PUSH_INFO = (hospID: string | number) => {
  return `/rommanAPI/service_record/push_push_info/${hospID}/`;
};

// alert info
export const GET_MONITOR_ALERT_INFO = "/monitorAPI/v1/n9e/alert-his-events";
export const GET_TARGET_UP_INFO = "/monitorAPI/v1/n9e/targets";

export const QUERY_BREAK = "/ticketAPI/issues/query_issues";

export const GET_HOSPITAL_ISSUES_STATS = "/ticketAPI/dashboard/hospital_issues";
