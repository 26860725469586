import { MyPieChartType } from "../../chart/pie-chart";
import { DockingStatisticType as PieType } from "../../interface";

export const GetDockingTypeNum = (data: PieType) => {
  let tmpData: MyPieChartType = {
    data: [],
  };

  data?.docking?.forEach((item) => {
    tmpData.data.push({
      name: item.name,
      value: item.value,
    });
  });

  return tmpData;
};
