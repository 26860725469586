import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useDeliveryDetailStop = () => {
  const [{ openDeliveryDetailStop }, setopenDeliveryDetailStop] =
    useUrlQueryParam(["openDeliveryDetailStop"]);

  const open = () =>
    setopenDeliveryDetailStop({ openDeliveryDetailStop: true });
  const close = () =>
    setopenDeliveryDetailStop({
      openDeliveryDetailStop: undefined,
    });

  return [openDeliveryDetailStop === "true", open, close] as const;
};
