import { Dispatch, SetStateAction, useState } from "react";
import { Button, Descriptions } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { RemoteDesItemLabel, LineDiv } from "../../utils";
import { RemoteDetailType } from "../interface";
import { RemoteBaseInfoEdit } from "./edit";

const RemoteBaseInfo = ({
  detail,
  reload,
  setDisChange,
}: {
  detail: RemoteDetailType | undefined;
  reload: () => void;
  setDisChange: Dispatch<SetStateAction<boolean>>;
}) => {
  // 基本信息state
  const [baseInfoEditable, setbaseInfoEditableable] = useState(false);

  return baseInfoEditable ? (
    <RemoteBaseInfoEdit
      editingItem={detail}
      setEditable={setbaseInfoEditableable}
      setDisChange={setDisChange}
      reload={reload}
    />
  ) : (
    <Descriptions
      title="远程基本信息"
      column={2}
      extra={
        <Button
          onClick={() => {
            setbaseInfoEditableable(true);
            setDisChange(true);
          }}
          icon={<EditOutlined />}
        >
          编辑
        </Button>
      }
    >
      <Descriptions.Item label={<RemoteDesItemLabel label={"远程方式"} />}>
        <LineDiv>{detail?.remote_type_name}</LineDiv>
      </Descriptions.Item>
      {/* <Descriptions.Item label={<RemoteDesItemLabel label={"连接状态"} />}>
        <LineDiv>{detail?.status}</LineDiv>
      </Descriptions.Item> */}
      {/* <Descriptions.Item label={<RemoteDesItemLabel label={"MQTT密码"} />}>
        <LineDiv>{detail?.mqtt_passwd}</LineDiv>
      </Descriptions.Item> */}
    </Descriptions>
  );
};

export default RemoteBaseInfo;
