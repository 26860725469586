import { useEffect } from "react";
import { Tabs, Result, Button, Skeleton } from "antd";
import type { TabsProps } from "antd";
import { usePermission } from "context/permission";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
// import { OverviewStat } from "./overview";
import { DataSummary } from "./data-summary";
// import { UserStatus } from "./user-status";
import { UserInfo } from "./user-info";

const defaultTab = "user";

export const UserStat = () => {
  const [{ tab }, setTab] = useUrlQueryParam(["tab"]);
  const { permission } = usePermission();

  useEffect(() => {
    if (!!!tab) {
      setTab({ tab: defaultTab });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const items: TabsProps["items"] = [
    // {
    //   key: 'overview',
    //   label: `全国数量概览`,
    //   children:<OverviewStat />,
    // },
    {
      key: "user",
      label: `客户单位汇总`,
      children: <DataSummary type="hospital" />,
    },
    {
      key: "product",
      label: `产品单位汇总`,
      children: <DataSummary type="product" />,
    },
    // {
    //   key: 'status',
    //   label: `状态信息统计`,
    //   children: <UserStatus />,
    // },
    {
      key: "info",
      label: `客户信息汇总`,
      children: <UserInfo />,
    },
  ];

  return permission?.customerProfile === 1 ? (
    <Tabs
      activeKey={tab}
      size="small"
      items={items}
      onChange={(v) => setTab({ tab: v })}
    />
  ) : permission?.customerProfile === 0 ? (
    <Result
      status="403"
      title="403"
      subTitle="抱歉，你无权限访问此页面！"
      extra={
        <Button
          type="primary"
          onClick={() => window.location.replace(window.location.origin)}
        >
          返回主页
        </Button>
      }
    />
  ) : (
    <Skeleton active />
  );
};
