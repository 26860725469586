import { useEffect, useRef, useState } from "react";
import { Button, DatePicker, Card } from "antd";
import { ProTable, ProColumns, ActionType } from "@ant-design/pro-components";
import { useHttp } from "utils/http";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { HospListType } from "interface";
import { PUSH_INFO_TYPE } from "../interface";
import { GET_PUSH_INFO } from "lib/api";
import dayjs from "dayjs";
import { formatDate } from "utils";

const { RangePicker } = DatePicker;

export interface HosPrintQueueInfoProps {
  date: string;
  print_total: number;
  print_finish: number;
  print_error: number;
  push_total: number;
  push_finish: number;
  push_error: number;
}
const columns: ProColumns<PUSH_INFO_TYPE>[] = [
  {
    title: "日期",
    dataIndex: "statics_date",
    valueType: "date",
    width: 120,
  },
  {
    title: "打印总数",
    dataIndex: "push_film_num",
  },
  { title: "打印成功数", dataIndex: "push_film_success", hideInSearch: true },
  { title: "推送总数", dataIndex: "push_series_num", hideInSearch: true },
  { title: "推送成功数", dataIndex: "push_series_success", hideInSearch: true },
];

export const HosPrintQueueInfo = ({
  hospInfo,
  stateKey,
}: {
  hospInfo: HospListType | undefined;
  stateKey?: string;
}) => {
  const client = useHttp();
  const actionRef = useRef<ActionType>();
  const { height } = useWindowSize();
  const [page, setPage] = useState<number>(1);
  const DEFAULT_DAYS = 7;
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(
    dayjs().subtract(DEFAULT_DAYS, "days"),
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());

  const [loading, setLoading] = useState<boolean>(false);
  const getParams = (params: any) => {
    let start_date = formatDate(startDate);
    let end_date = formatDate(endDate);
    let queryParams = {
      start_date,
      end_date,
    };
    return {
      ...params,
      ...queryParams,
    };
  };

  const search = (params: any) => {
    if (hospInfo?.id) {
      return client(GET_PUSH_INFO(hospInfo.id), {
        data: { ...params },
      });
    }
  };

  useEffect(() => {
    setStartDate(dayjs().subtract(DEFAULT_DAYS, "days"));
    setEndDate(dayjs());
  }, [hospInfo]);

  useEffect(() => {
    if (stateKey === "print") {
      actionRef.current?.reload();
    }
  }, [stateKey]);

  return (
    <Card>
      <ProTable<PUSH_INFO_TYPE>
        rowKey="id"
        actionRef={actionRef}
        search={false}
        dateFormatter="string"
        columns={columns}
        request={async (params) => {
          try {
            let queryParams = getParams(params);
            setLoading(true);
            const res = await search(queryParams);
            return {
              data: res.results,
              total: res.count,
              success: true,
            };
          } catch (e) {
            return { success: false };
          } finally {
            setLoading(false);
          }
        }}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: false,
          pageSize:
            Math.trunc((height - 382) / 47) > 5
              ? Math.trunc((height - 382) / 47)
              : 5,
          current: page,
          onChange: (v) => {
            setPage(v);
          },
        }}
        options={false}
        tableExtraRender={(props) => [
          <RangePicker
            disabled={loading}
            allowClear={false}
            value={[startDate, endDate]}
            style={{ width: 240 }}
            onChange={(v) => {
              if (v) {
                setStartDate(v[0] || dayjs());
                setEndDate(v[1] || dayjs());
              }
            }}
          />,
          <Button
            type="primary"
            style={{ marginLeft: 10 }}
            loading={loading}
            onClick={() => {
              setPage(1);
              actionRef.current?.reload();
            }}
          >
            查询
          </Button>,
        ]}
      />
    </Card>
  );
};

export default HosPrintQueueInfo;
