import { useState, useEffect } from "react";
import { Button, Modal, Space, Upload, UploadFile } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import {
  PlusOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { SingleImageList } from "authenticated-app/pc/customer/customer-detail/tab-pane/utils";
import {
  Attachment,
  UploadHeaders,
} from "authenticated-app/pc/customer/customer-detail/interface";
import { ADD_FILE } from "lib/api";
import { handleUpload, initEditUploadFileList, limitUpload } from "utils";
import { handlePreview } from "utils/handlePreview";

import "./index.css";
import { isEdge } from "react-device-detect";

export const EditUpload = ({
  onUpdate,
  name,
  title,
  defaultValue,
  isCanChange,
  isEditMode,
  maxCount = 10,
}: {
  onUpdate: (value: any) => void;
  name: string;
  title: string;
  defaultValue?: Attachment[];
  isCanChange?: boolean;
  isEditMode?: boolean; // 允许默认编辑模式
  maxCount?: number;
}) => {
  const [IsEditting, setIsEditting] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  useEffect(() => {
    let initList = initEditUploadFileList(defaultValue);
    setFileList(initList || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (isEditMode) {
      setIsEditting(true);
    }
  }, []);

  return (
    <div className="update-upload">
      <PageHeader
        className="page-header"
        subTitle={<span className="page-header-span">{title}</span>}
        extra={
          <Space>
            {isEditMode ? (
              <></>
            ) : (
              <>
                {IsEditting ? (
                  <>
                    <Button
                      icon={
                        <CheckOutlined
                          style={{ color: "var(--adm-color-primary)" }}
                        />
                      }
                      type="text"
                      onClick={() => {
                        onUpdate({
                          [name]: fileList?.map((i) => i.uid.toString()),
                        });
                        setIsEditting(false);
                      }}
                    />
                    <Button
                      icon={<CloseOutlined />}
                      type="text"
                      onClick={() => {
                        setIsEditting(false);
                      }}
                    />
                  </>
                ) : isCanChange ? (
                  <Button
                    icon={
                      <EditOutlined
                        style={{ color: "var(--adm-color-primary)" }}
                      />
                    }
                    type="text"
                    onClick={() => setIsEditting(true)}
                  />
                ) : null}
              </>
            )}
          </Space>
        }
      />
      {IsEditting ? (
        <Upload
          headers={UploadHeaders}
          maxCount={maxCount}
          multiple={true}
          action={ADD_FILE}
          listType="picture-card"
          fileList={fileList}
          beforeUpload={limitUpload}
          onChange={(info) => handleUpload(info, setFileList)}
          onPreview={(f) =>
            handlePreview(f, {
              setPreviewImage,
              setPreviewOpen,
              setPreviewTitle,
            })
          }
        >
          {fileList.length >= maxCount ? null : (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>上传(限{maxCount}个)</div>
            </div>
          )}
        </Upload>
      ) : (
        <SingleImageList imgList={defaultValue || []} />
      )}
      <Modal
        centered
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        width={"61.8%"}
      >
        <div style={{ width: "100%" }}>
          <img alt="previwe" style={{ width: "100%" }} src={previewImage} />
        </div>
      </Modal>
    </div>
  );
};
