import { useEffect, useRef } from "react";
import {
  ActionType,
  ProTable,
  ProColumns,
  ProDescriptions,
} from "@ant-design/pro-components";
import { Badge, Button, Space, Typography, Tag } from "antd";
import { RemoteDetailType, RemoteMonitorInfoType } from "../interface";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { GET_TARGET_UP_INFO } from "lib/api";
const { Link } = Typography;

export const MonitorInfo = ({
  detail,
  hospCode,
  hospID,
  hospitalName,
  mode,
}: {
  detail?: RemoteDetailType | undefined;
  hospCode: string | undefined;
  hospID: number | undefined;
  hospitalName: string | undefined;
  mode?: string;
}) => {
  const client = useHttp();
  const actionRef = useRef<ActionType>();
  const {
    run: getTargetUpInfo,
    data: targetUpInfo,
    isLoading: getTargetUpLoading,
    isIdle: getTargetUpIsIdle,
  } = useAsync<RemoteMonitorInfoType>();
  const getTargetUpInfoHandle = () => {
    if (hospCode) {
      getTargetUpInfo(
        client(GET_TARGET_UP_INFO, {
          data: {
            bgid: -1,
            crm_code: hospCode,
          },
        }),
      );
    }
  };

  const formatTimestamp = (timestamp: any) => {
    if (!timestamp) return "";
    if (typeof timestamp === "string") {
      timestamp = Number(timestamp);
    }
    const date = new Date(timestamp * 1000); // 将秒转换为毫秒
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 获取月份并补零
    const day = String(date.getDate()).padStart(2, "0"); // 获取日期并补零
    const hours = String(date.getHours()).padStart(2, "0"); // 获取小时并补零
    const minutes = String(date.getMinutes()).padStart(2, "0"); // 获取分钟并补零
    const seconds = String(date.getSeconds()).padStart(2, "0"); // 获取秒并补零

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // 返回格式化后的字符串
  };

  const monitorColumns: ProColumns<any>[] = [
    {
      title: "主机标识",
      dataIndex: "ident",
    },
    {
      title: "机器IP",
      dataIndex: "server_ip",
      valueType: "text",
    },
    {
      title: "远程IP",
      dataIndex: "remote_ip",
      valueType: "text",
    },
    {
      title: "维保编码",
      dataIndex: "server_code",
      valueType: "text",
    },
    {
      title: "创建时间",
      dataIndex: "created_at",
      render: (text, record, _, action) => formatTimestamp(text),
      hideInSearch: true,
    },
    {
      title: "告警数量",
      dataIndex: "alert_current_count",
      valueType: "text",
      render: (text, record, _, action) => {
        return record.alert_current_count !== 0 ? (
          <span style={{ color: "red" }}>{record.alert_current_count}</span>
        ) : (
          <span>{record.alert_current_count}</span>
        );
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      valueType: "text",
      render: (text, record, _, action) => {
        return (
          <Badge
            status={record.status === 1 ? "success" : "error"}
            text={record.status === 1 ? "在线" : "离线"}
          />
        );
      },
    },
    {
      title: "操作",
      dataIndex: "action",
      valueType: "option",
      width: 150,
      render: (text, record, _, action) => {
        return (
          <Space>
            <Button
              type="link"
              href={`http://monitor.infervision.com:3009/grafana/d/d1ad5d08-081b-46ec-9468-344e84a9164a/fcd6c01d-adb4-5d5b-ab6d-01b6b7571eb2?orgId=1&var-hospital_name=${hospitalName}&var-crm_code=${hospCode}&from=now-24h&to=now`}
              target="_blank"
            >
              查看数据大屏
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (mode === "overview" && hospCode) {
      getTargetUpInfoHandle();
    }
  }, []);

  useEffect(() => {
    if (mode === "detail") {
      actionRef.current?.reload();
    }
    if (mode === "overview" && hospCode) {
      getTargetUpInfoHandle();
    }
  }, [hospCode]);

  return (
    <>
      {mode === "detail" ? (
        <>
          <ProTable<any>
            size="small"
            rowKey="id"
            actionRef={actionRef}
            columns={monitorColumns}
            search={false}
            dateFormatter="string"
            toolbar={{
              title: <span style={{ fontWeight: "bold" }}>远程监控信息</span>,
            }}
            toolBarRender={() => [
              <Button
                type="primary"
                href="https://monitor.infervision.com/alert-his-events"
                target="_blank"
              >
                访问监控平台
              </Button>,
            ]}
            request={async (params, sorter, filter) => {
              if (hospCode) {
                const ret = await client(GET_TARGET_UP_INFO, {
                  data: {
                    bgid: -1,
                    crm_code: hospCode,
                  },
                });
                return {
                  data: ret.dat.list,
                  total: ret.dat.total,
                };
              } else
                return {
                  data: [],
                  total: 0,
                };
            }}
            pagination={false}
          />
        </>
      ) : (
        <>
          <ProDescriptions
            title="远程监控信息"
            column={4}
            emptyText={"空"}
            columns={[
              {
                title: <span style={{ fontWeight: "bold" }}>监控主机数</span>,
                tooltip: "该客户接入了远程监控系统的主机数量",
                valueType: "text",
                render: () => {
                  if (targetUpInfo && targetUpInfo.dat.total) {
                    return <>{targetUpInfo && targetUpInfo.dat.total}</>;
                  } else {
                    return <span>未接入监控</span>;
                  }
                },
              },
              {
                title: <span style={{ fontWeight: "bold" }}>在线主机数</span>,
                tooltip: "该客户在线的主机数量",
                valueType: "text",
                render: () => {
                  if (targetUpInfo && targetUpInfo.dat.list) {
                    return (
                      <span>
                        {
                          targetUpInfo.dat.list.filter(
                            (item: any) => item.status === 1,
                          ).length
                        }
                      </span>
                    );
                  } else {
                    return <span>无</span>;
                  }
                },
              },
              {
                title: <span style={{ fontWeight: "bold" }}>告警数量</span>,
                tooltip: "该客户当前触发的告警数量",
                valueType: "text",
                render: () => {
                  if (targetUpInfo && targetUpInfo.dat.list) {
                    return (
                      <>
                        {targetUpInfo.dat.list.reduce(
                          (acc, cur) => acc + cur.alert_current_count,
                          0,
                        )}
                      </>
                    );
                  } else {
                    return <span>-</span>;
                  }
                },
              },
              {
                title: "操作",
                valueType: "option",
                render: () => [
                  <Link
                    href={`/customer/detail?hospitalId=${hospID}&tab=remote`}
                    target="_blank"
                    // disabled={!targetUpInfo}
                    hidden={!targetUpInfo}
                  >
                    查看远程监控详细信息
                  </Link>,
                ],
              },
            ]}
          ></ProDescriptions>
        </>
      )}
    </>
  );
};

export default MonitorInfo;
