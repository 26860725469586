import { IdType } from "lib/api";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useBreaklistSearch = () => {
  const [{ openBreaklistSearch }, setopenBreaklistSearch] = useUrlQueryParam([
    "openBreaklistSearch",
  ]);

  const open = () => setopenBreaklistSearch({ openBreaklistSearch: true });
  const close = () =>
    setopenBreaklistSearch({
      openBreaklistSearch: undefined,
    });

  return [openBreaklistSearch === "true", open, close] as const;
};
