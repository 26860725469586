import { Fragment, useEffect, useState } from "react";
import {
  AutoCenter,
  Button,
  Divider,
  Grid,
  List,
  Modal,
  Popover,
  PullToRefresh,
  SearchBar,
  Space,
  Tag,
  Toast,
  InfiniteScroll,
} from "antd-mobile";
import {
  ExclamationCircleFill,
  AddOutline,
  CloseOutline,
  SystemQRcodeOutline,
  KeyOutline,
} from "antd-mobile-icons";
import { Action } from "antd-mobile/es/components/popover";
import { LoadingMask } from "../../components/LoadingMask";
import { ToastContent } from "../../components/ToastContent";
import { searchNameStorageKey } from "../..";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { GET_HOS_PRODUCT_LIST, GET_HOSP_PWD, HOSP_LIST_API } from "lib/api";
import { hosStatus, statusColor, trimStr, useMount } from "utils";
import { PamInfoType } from "authenticated-app/pc/customer/customer-list/interface";
import { HospListType, HOSP_LIST_TYPE } from "interface";
import { useHospList } from "context/hosp";
import { MobileServerInfoScan } from "./serverInfoScan";
import { MobileCustomerDetailPopup } from "./detail";
import { useCustomerDetail } from "authenticated-app/hooks/customer/useCustomerDetail";
import { useDebounceFn } from "ahooks";

export const detailNameStorageKey = "infer-mobile-detail-name";
export const detailStatusStorageKey = "infer-mobile-detail-status";
export const detailCodeStorageKey = "infer-mobile-detail-code";

export const MobileUserList = () => {
  const client = useHttp();
  const pageSize = 15;

  const [searchName, setSearchName] = useState<string>(
    window.sessionStorage.getItem(searchNameStorageKey) || "",
  );
  const [, openCustomerDetail] = useCustomerDetail();

  // 分页
  const [data, setData] = useState<HospListType[]>([]);
  const [next, setNext] = useState<number>();
  const [hasMore, setHasMore] = useState(false);

  const {
    run: getHospList,
    isLoading: gettingHospList,
    isIdle: getHospListIsIdle,
  } = useAsync<HOSP_LIST_TYPE[]>();
  const getNewHospListHandle = (params?: object) => {
    let queryParams: object = {};
    if (params) {
      queryParams = { ...params };
    }
    getHospList(
      client(HOSP_LIST_API, {
        data: {
          pageSize,
          current: 1,
          name: trimStr(searchName) ? trimStr(searchName) : undefined,
        },
      }),
    ).then((res) => {
      setData(res?.results || []);
      setNext((res?.number || 0) + 1);
      setHasMore(!!res?.links?.next);
    });
  };
  const { run: getHospListFunc } = useDebounceFn(getNewHospListHandle, {
    wait: 200,
  });

  const loadMore = async () => {
    let append = await client(HOSP_LIST_API, {
      data: {
        pageSize,
        current: next || 1,
        name: trimStr(searchName) ? trimStr(searchName) : undefined,
      },
    });
    setData((val) => [...val, ...(append?.results || [])]);
    setNext((append?.number || 0) + 1);
    setHasMore(!!append?.links?.next);
  };

  const { run: postFile, isLoading: postingFile } = useAsync();
  const { run: confirmScan, isLoading: confirmingScan } = useAsync();

  const [isPamVisible, setIsPamVisible] = useState(false);
  const [pamInfo, setPamInfo] = useState<PamInfoType>();

  const [isActionsVisible, setIsActionsVisible] = useState(false);

  const actions: Action[] = [
    {
      key: "dataCollection",
      icon: <SystemQRcodeOutline />,
      text: (
        <MobileServerInfoScan
          postFile={postFile}
          confirmScan={confirmScan}
          setIsActionsVisible={setIsActionsVisible}
        />
      ),
    },
  ];

  const { run: getPwd, isLoading: gettingPwd } = useAsync<HospListType[]>();
  const getPwdHandle = (record: HospListType) => {
    if (process.env.REACT_APP_VERSION === "dev") {
      setPamInfo({
        id: record?.id,
        name: record?.name,
        code: record?.code,
        hospitalPwd: "test_pwd",
      });
      setIsPamVisible(true);
    } else {
      getPwd(client(GET_HOSP_PWD(record?.id)))
        .then((response) => {
          setPamInfo({
            id: record?.id,
            name: record?.name,
            code: record?.code,
            ...response[0],
          });
          setIsPamVisible(true);
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="获取出错" />,
          });
        });
    }
  };

  useMount(() => {
    getHospListFunc();
  });

  const LoadingList =
    gettingPwd || postingFile || confirmingScan || gettingHospList;

  return (
    <div>
      <LoadingMask visible={LoadingList} />
      <div className={"mobile-header"}>
        <SearchBar
          defaultValue={searchName}
          placeholder="搜索客户名称"
          onSearch={(v) => {
            getHospListFunc();
            setSearchName(v);
            window.sessionStorage.setItem(searchNameStorageKey, v || "");
          }}
          onClear={() => {
            getHospListFunc();
            setSearchName("");
            window.sessionStorage.removeItem(searchNameStorageKey);
          }}
          style={{ width: "100%", marginRight: 12 }}
        />
        <Popover.Menu
          actions={actions}
          mode="dark"
          placement="bottom-start"
          trigger="click"
          visible={isActionsVisible}
        >
          {isActionsVisible ? (
            <CloseOutline
              fontSize={32}
              onClick={() => setIsActionsVisible(false)}
            />
          ) : (
            <AddOutline
              fontSize={32}
              onClick={() => setIsActionsVisible(true)}
            />
          )}
        </Popover.Menu>
      </div>
      <div className={"mobile-body"}>
        <PullToRefresh
          onRefresh={async () => {
            getHospListFunc();
          }}
          refreshingText={null}
        >
          <Fragment>
            <List>
              {data?.map((i) => (
                <List.Item
                  key={i.id}
                  description={
                    <Space>
                      {i.code}
                      <Tag
                        round
                        color={
                          statusColor.get(i.hospital_status as hosStatus) ||
                          "#5B5B5B"
                        }
                        style={{
                          margin: 0,
                          padding: "2px",
                          transform: "translateY(-2px)",
                          marginLeft: "12px",
                        }}
                      />
                      {i.hospital_status}
                    </Space>
                  }
                  extra={
                    <Button
                      fill="none"
                      color="primary"
                      loading={
                        isPamVisible &&
                        pamInfo?.code.toString() === i.code.toString()
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        getPwdHandle(i);
                      }}
                    >
                      <KeyOutline />
                    </Button>
                  }
                  arrow={false}
                  onClick={() => {
                    window.sessionStorage.setItem(
                      detailNameStorageKey,
                      i.name || "客户服务系统",
                    );
                    window.sessionStorage.setItem(
                      "infer-mobile-detail-code",
                      i.code || "",
                    );
                    window.sessionStorage.setItem(
                      detailStatusStorageKey,
                      i.hospital_status || hosStatus.unknown,
                    );
                    openCustomerDetail(i.id);
                  }}
                >
                  {i.name}
                </List.Item>
              ))}
            </List>
          </Fragment>
          <Modal
            visible={isPamVisible}
            title={pamInfo?.name || "未知"}
            closeOnAction
            onClose={() => setIsPamVisible(false)}
            actions={[
              {
                key: "confirm",
                text: "确定",
              },
            ]}
            content={
              <Grid columns={3} gap={8}>
                <Grid.Item span={1}>医院编号：</Grid.Item>
                <Grid.Item span={2}>
                  <span style={{ fontWeight: "bold" }}>
                    {pamInfo?.code || "未知"}
                  </span>
                </Grid.Item>
                <Grid.Item span={1}>动态密码：</Grid.Item>
                <Grid.Item span={2}>
                  <span style={{ fontWeight: "bold" }}>
                    {pamInfo?.hospitalPwd || "未知"}
                  </span>
                </Grid.Item>
                <Grid.Item span={3}>
                  <Divider />
                  <AutoCenter>
                    <Space>
                      <ExclamationCircleFill
                        style={{
                          color: "var(--adm-color-warning)",
                        }}
                      />
                      <span>密码当天有效，切勿泄露！</span>
                    </Space>
                  </AutoCenter>
                  <Divider />
                </Grid.Item>
              </Grid>
            }
          />
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </PullToRefresh>
        <MobileCustomerDetailPopup />
      </div>
    </div>
  );
};
