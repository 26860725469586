import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useDeliveryDetailSendback = () => {
  const [{ openDeliveryDetailSendback }, setopenDeliveryDetailSendback] =
    useUrlQueryParam(["openDeliveryDetailSendback"]);

  const open = () =>
    setopenDeliveryDetailSendback({ openDeliveryDetailSendback: true });
  const close = () =>
    setopenDeliveryDetailSendback({
      openDeliveryDetailSendback: undefined,
    });

  return [openDeliveryDetailSendback === "true", open, close] as const;
};
