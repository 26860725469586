import { List, Badge, Row, Col, Spin } from "antd";

export const RankingList = ({
  title,
  rankingListData,
  labelKey,
  valueKey,
  loading,
  lightTop3,
}: {
  title: string;
  rankingListData: any[];
  labelKey: string;
  valueKey: string;
  loading: boolean;
  lightTop3?: boolean;
}) => {
  return (
    <>
      <Spin spinning={loading}>
        <h4>{title}</h4>
        <List
          size="small"
          dataSource={rankingListData}
          renderItem={(item, index) => (
            <List.Item style={{ margin: "4px", padding: "0 6px 0 6px" }}>
              <List.Item.Meta
                avatar={
                  lightTop3 ? (
                    index < 3 ? (
                      <Badge
                        count={index + 1}
                        style={{
                          backgroundColor:
                            index === 0
                              ? "#f00"
                              : index === 1
                              ? "#faad14"
                              : "#52c41a",
                          fontSize: "14px",
                        }}
                      />
                    ) : (
                      <Badge
                        count={index + 1}
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                          color: "black",
                          fontSize: "14px",
                        }}
                      />
                    )
                  ) : (
                    <Badge
                      count={index + 1}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                        color: "black",
                        fontSize: "14px",
                      }}
                    />
                  )
                }
                title={
                  <>
                    <Row style={{ fontWeight: "normal" }}>
                      <Col span={20}>{item[labelKey]}</Col>
                      <Col span={4} style={{ textAlign: "right" }}>
                        {item[valueKey]}
                      </Col>
                    </Row>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Spin>
    </>
  );
};
