import { Dispatch, SetStateAction } from "react";
import { Collapse, List, Popup, Space } from "antd-mobile";

import { RemoteRetainType } from "authenticated-app/pc/customer/customer-detail/tab-pane/remoteTabPane/interface";
import { isImageFormat } from "authenticated-app/mobile/util";
import { AttachmentDiv } from "authenticated-app/mobile/components/AttachmentDiv";

export const MobileRemoteRetainInfoDetail = ({
  retainInfo,
  visible,
  setVisible,
}: {
  retainInfo: RemoteRetainType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Popup
      visible={visible}
      destroyOnClose
      bodyStyle={{ height: "61.8vh", overflowY: "scroll" }}
      onMaskClick={() => setVisible(false)}
      showCloseButton
      onClose={() => setVisible(false)}
    >
      <List
        mode="card"
        header={<p style={{ fontWeight: "bold" }}>必要信息详情</p>}
      >
        <List.Item extra={retainInfo?.title || "未知"}>标题</List.Item>
        <Collapse
          defaultActiveKey={
            retainInfo?.description ? retainInfo?.id.toString() : undefined
          }
          accordion
        >
          <Collapse.Panel
            key={retainInfo?.id.toString() || "retainInfoDesc"}
            title="简要描述"
          >
            {retainInfo?.description || "无"}
          </Collapse.Panel>
        </Collapse>
        <Collapse
          defaultActiveKey={
            retainInfo?.remark ? retainInfo?.id.toString() : undefined
          }
          accordion
        >
          <Collapse.Panel
            key={retainInfo?.id.toString() || "retainInfo"}
            title="备注"
          >
            {retainInfo?.remark || "无"}
          </Collapse.Panel>
        </Collapse>

        <Collapse
          defaultActiveKey={
            retainInfo?.attachment.length
              ? retainInfo?.id.toString()
              : undefined
          }
          accordion
        >
          <Collapse.Panel
            key={retainInfo?.id.toString() || "retainInfoDesc"}
            title="附件"
          >
            {retainInfo?.attachment.length ? (
              <Space direction="vertical">
                {retainInfo?.attachment?.map((item, index) => (
                  <AttachmentDiv
                    key={index}
                    isImage={isImageFormat(item.attachment_name)}
                    attachment={item}
                  />
                ))}
              </Space>
            ) : (
              "无"
            )}
          </Collapse.Panel>
        </Collapse>
      </List>
      <List mode="card" header={"修改记录"}>
        <List.Item extra={retainInfo?.docking_user || "未知"}>对接人</List.Item>
        <List.Item extra={retainInfo?.contact_info || "未知"}>
          联系方式
        </List.Item>
        <List.Item extra={retainInfo?.update_user || "未知"}>修改人</List.Item>
        <List.Item extra={retainInfo?.update_date || "未知"}>
          修改时间
        </List.Item>
      </List>
    </Popup>
  );
};
export default MobileRemoteRetainInfoDetail;
