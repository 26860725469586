import { ReactNode } from "react";

export const BreakSpan = ({
  children,
  style = { wordBreak: "break-all" },
}: {
  children: ReactNode;
  style?: (React.CSSProperties & Partial<Record<never, string>>) | undefined;
}) => {
  return <span style={style}>{children}</span>;
};
