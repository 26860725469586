import { useState, SetStateAction, Dispatch, useEffect } from "react";
import { Button, Modal, Space, Form, Input, message, Upload } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { PlusOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import { ADD_FILE } from "lib/api";
import { UploadHeaders } from "../../../interface";
import { DisposeForm, handleUpload, initFileList, limitUpload } from "utils";
import { ClientRetainType } from "../interface";

const { TextArea } = Input;

export const ClientRetainInfoEdit = ({
  editingItem,
  UPDATE_RETAIN,
  visible,
  setVisible,
  reload,
}: {
  editingItem: ClientRetainType | undefined;
  UPDATE_RETAIN: (hospID: number) => string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const updateRetain = (value: any) => {
    let id = editingItem?.id;
    if (id)
      client(UPDATE_RETAIN(id), {
        data: {
          ...value,
          attachment: fileList.map((i) => i.uid),
        },
        method: "PUT",
      })
        .then(() => {
          message.success("修改成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    else message.error("无法提交修改，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    if (visible) {
      setFileList(initFileList(editingItem?.attachment) || []);
      form.setFieldsValue({
        ...editingItem,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="修改必要留存信息"
      destroyOnClose
      open={visible}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={updateRetain}
      >
        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true, message: "必须输入标题" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <TextArea maxLength={64} allowClear showCount />
        </Form.Item>
        <Form.Item
          name="description"
          label="简要描述"
          rules={[{ required: true, message: "必须输入简要描述信息" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <TextArea maxLength={255} allowClear showCount />
        </Form.Item>
        <Form.Item name="remark" label="备注" normalize={(v) => DisposeForm(v)}>
          <TextArea allowClear />
        </Form.Item>
        <Form.Item name="attachment" label="附件">
          <Upload
            headers={UploadHeaders}
            maxCount={3}
            multiple={true}
            action={ADD_FILE}
            listType="picture-card"
            fileList={fileList}
            beforeUpload={limitUpload}
            onChange={(info) => handleUpload(info, setFileList)}
          >
            {fileList?.length >= 3 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传(限3个)</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
