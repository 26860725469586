import { useEffect, useState } from "react";
import { Form, Modal, Select, Radio, DatePicker, Input } from "antd";
import { useDebounceFn } from "ahooks";

import {
  GET_DEPARTMENT_USERS,
  // GET_ISSUES_MODULE,
  // GET_ISSUES_REASON,
  GET_ISSUES_REASON_NEW,
  GET_ISSUES_TYPE,
} from "lib/api/issues";
import {
  BREAKLIST_OPTION_TYPE,
  BREAK_DETAIL_TYPE,
  ORDER_DEPARTMENT_TYPE,
} from "lib/interface/breaklist";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { DisposeQuill, FormatDayjs, FormatTime, HospSelectFilter } from "utils";
import { useBreakDetailReverse } from "authenticated-app/hooks/breaklist/trans/useBreakDetailReverse";
import { editQuillToolbarOption } from "authenticated-app/pc/components/EditQuill/option";
import { UrlRegex } from "utils/regexp";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-zzone";
import "authenticated-app/pc/components/EditQuill/quill.snow.css";
Quill.register("modules/imageResize", ImageResize);

const { TextArea } = Input;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

export const BreakDetailReverseModal = ({
  onTrans,
  next,
  department,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: number[];
  department: number[];
  detailInfo: BREAK_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useBreakDetailReverse();
  const [isInit, setIsInit] = useState<boolean>(true);

  const [form] = Form.useForm();
  const client = useHttp();

  const [selectedOrderTypeId, setSelectedOrderTypeId] = useState<number>();

  const {
    run: getDepartmentList,
    data: departmentList,
    isIdle: getDepartmentListIdle,
    isLoading: gettingDepartmentList,
  } = useAsync<ORDER_DEPARTMENT_TYPE[]>();
  const getDepartmentListHandle = () => {
    getDepartmentList(client(GET_DEPARTMENT_USERS(department)));
  };
  const { run: getDepartmentListDebounce } = useDebounceFn(
    getDepartmentListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getIssuesTypeList,
    data: issuesTypeList,
    isLoading: gettingIssuesTypeList,
    isIdle: getIssuesTypeListIdle,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesTypeListHandle = () => {
    getIssuesTypeList(client(GET_ISSUES_TYPE));
  };
  const { run: getIssuesTypeListDebounce } = useDebounceFn(
    getIssuesTypeListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getIssuesReasonList,
    data: issuesReasonList,
    isLoading: gettingIssuesReasonList,
    isIdle: getIssuesReasonListIsIdle,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesReasonListHandle = () => {
    if (selectedOrderTypeId) {
      if (!!!isInit) {
        form.setFieldValue("issue_reason_id", undefined);
      }
      getIssuesReasonList(
        client(GET_ISSUES_REASON_NEW(selectedOrderTypeId)),
      ).then((v: any) => {
        let setValue = undefined;
        if (isInit) {
          if (detailInfo?.issue_reason_id) {
            const filterData = v.filter(
              (item: any) =>
                item.id === detailInfo.issue_reason_id &&
                item.name === detailInfo.issue_reason_name,
            );
            if (filterData) {
              filterData.length
                ? (setValue = detailInfo.issue_reason_id)
                : (setValue = undefined);
            }
          }
          form.setFieldValue("issue_reason_id", setValue);
          setIsInit(false);
        }
      });
    }
  };
  const { run: getIssuesReasonListDebounce } = useDebounceFn(
    getIssuesReasonListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      delivery_time: FormatTime(value.delivery_time),
      next_process: next,
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      getDepartmentListDebounce();
      if (detailInfo?.process_name !== "研发处理中") {
        getIssuesTypeListDebounce();
        if (detailInfo?.issue_type_id) {
          setSelectedOrderTypeId(detailInfo.issue_type_id);
        }
        // getIssuesReasonListDebounce();
        form.setFieldsValue({
          issue_type_id: detailInfo?.issue_type_id,
          knowledge_link: detailInfo?.knowledge_link,
          is_known: detailInfo?.is_known,
          is_temporary: detailInfo?.is_temporary,
          delivery_time: detailInfo?.delivery_time
            ? FormatDayjs(detailInfo?.delivery_time)
            : "",
        });
      }
      form.setFieldsValue({
        up_user_id: undefined,
        debug_info: detailInfo?.debug_info_dict.debug_info,
        solution_info: detailInfo?.solution_info_dict.solution_info,
      });
    } else {
      form.resetFields();
      setSelectedOrderTypeId(undefined);
      setIsInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  useEffect(() => {
    getIssuesReasonListDebounce();
    // 判断当前归因是否在该类型中. 如果在的话保留, 如果不在则清空.
    form.setFieldValue("issue_reason_id", "");
    // }
  }, [selectedOrderTypeId]);

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="转回工单"
      onCancel={close}
      onOk={form.submit}
      width={"848px"}
      style={{ minWidth: "848px", maxHeight: "80vh", overflow: "auto" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        <Form.Item
          name="up_user_id"
          label="转回至"
          rules={[{ required: true, message: "必须转回人员" }]}
        >
          <Select
            showSearch
            loading={getDepartmentListIdle || gettingDepartmentList}
            placeholder="指定转回人员"
            filterOption={HospSelectFilter}
            options={departmentList?.map((i) => ({
              label: `${i.user_name} (${i.user_job})`,
              value: i.user_id,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="debug_info"
          label="定位及补充记录"
          rules={[{ required: true, message: "必须填写定位及补充记录" }]}
          normalize={(v) => DisposeQuill(v)}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
              imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
              },
            }}
          />
        </Form.Item>
        <Form.Item
          name="solution_info"
          label="操作及解决"
          rules={[{ required: true, message: "必须填写操作及解决" }]}
          normalize={(v) => DisposeQuill(v)}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
              imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
              },
            }}
          />
        </Form.Item>
        {detailInfo?.process_name !== "研发处理中" ? (
          <>
            <Form.Item
              name="issue_type_id"
              label="反馈类型"
              rules={[{ required: true, message: "必须选择反馈类型" }]}
            >
              <Select
                placeholder="选择反馈类型"
                loading={getIssuesTypeListIdle || gettingIssuesTypeList}
                options={issuesTypeList?.map((i) => ({
                  label: i.name,
                  value: i.id,
                }))}
                onChange={(id) => {
                  setSelectedOrderTypeId(id);
                }}
              />
            </Form.Item>

            <Form.Item
              name="issue_reason_id"
              label="反馈归因"
              rules={[{ required: true, message: "必须选择反馈归因" }]}
            >
              <Select
                placeholder={
                  !!!selectedOrderTypeId ? "请先选择反馈类型" : "选择反馈归因"
                }
                disabled={!!!selectedOrderTypeId}
                loading={
                  getIssuesReasonListIsIdle ||
                  gettingIssuesReasonList ||
                  getIssuesTypeListIdle ||
                  gettingIssuesTypeList
                }
                options={issuesReasonList?.map((i) => ({
                  label: i.name,
                  value: i.id,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="knowledge_link"
              label="知识库链接"
              rules={[
                {
                  pattern: UrlRegex(),
                  message: "必须输入合法的URL格式",
                },
              ]}
            >
              <TextArea
                placeholder="填写知识库链接"
                autoSize={{ minRows: 1 }}
              />
            </Form.Item>
            <Form.Item name="is_known" label="是否已知问题">
              <Radio.Group style={{ marginLeft: "13px" }}>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="is_temporary"
              label="是否临时解决"
              rules={[{ required: true, message: "必须选择是否临时解决" }]}
            >
              <Radio.Group style={{ marginLeft: "12px" }}>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="delivery_time" label="预期交付日期">
              <DatePicker style={{ marginLeft: "12px" }} />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
      </Form>
    </Modal>
  );
};
