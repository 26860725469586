import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Divider,
  DatePicker,
  Button,
  Space,
  Radio,
  Spin,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { MyBarChart, MyBarChartType } from "../../chart/bar-chart";
import { GetBreakBar, GetProcessNum } from "./utils/break-bar";
import { BreakRankingListType } from "../../interface";
import { RankingList } from "./components/rankingList";
import { GetDate, RangeValue } from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { useDebounceFn, useMount } from "ahooks";
import {
  GET_BREAK_TOTAL_MONTH_STATISTICS,
  GET_BREAK_ISSUES_MONTH_STATISTICS,
  GET_BREAK_ISSUES_TOP_STATISTICS,
  GET_BREAK_ISSUES_TOP,
} from "lib/api";
import {
  BreakInfoValueType,
  BreakProcessValueType,
  BreakStatisticRankingListType,
} from "../../interface";

const { RangePicker } = DatePicker;

export const BreakInfoChart = () => {
  const client = useHttp();
  const [tabName, setTabName] = useState<number>(1);
  const [totalNum, setTotalNumBar] = useState<MyBarChartType>();
  const [processNum, setProcessNum] = useState<MyBarChartType>();
  const [rankingListData, setRankingListData] =
    useState<BreakRankingListType[]>();
  const [statisticsRankingListData, setStatisticsRankingListData] =
    useState<BreakStatisticRankingListType[]>();
  const [queryDateRange, setQueryDateRange] = useState<RangeValue>();
  const [lightButton, setLightButton] = useState<string>();

  const {
    run: getBreakMonthTotalStatistics,
    isLoading: gettingBreakMonthTotalStatistics,
    data: BreakMonthTotalStatistics,
  } = useAsync<BreakInfoValueType[]>();
  const getBreakMonthTotalStatisticsHanle = () => {
    let params = {};
    switch (tabName) {
      case 4:
        params = { order_type_id: 4, delivery_type_id_list: "1|2|3" };
        break;
      case 5:
        params = { order_type_id: 4, delivery_type_id_list: "4" };
        break;
      default:
        params = { order_type_id: tabName };
        break;
    }
    getBreakMonthTotalStatistics(
      client(GET_BREAK_TOTAL_MONTH_STATISTICS, {
        data: {
          ...params,
        },
      }),
    );
  };
  const { run: getBreakMonthTotalStatisticsDebounce } = useDebounceFn(
    getBreakMonthTotalStatisticsHanle,
    {
      wait: 200,
    },
  );

  const {
    run: getBreakMonthStatistics,
    isLoading: gettingBreakMonthStatistics,
    data: BreakMonthStatistics,
  } = useAsync<BreakProcessValueType[]>();
  const getBreakMonthStatisticsHanle = () => {
    getBreakMonthStatistics(client(GET_BREAK_ISSUES_MONTH_STATISTICS));
  };
  const { run: getBreakMonthStatisticsDebounce } = useDebounceFn(
    getBreakMonthStatisticsHanle,
    {
      wait: 200,
    },
  );

  const {
    run: getBreakIssueTop,
    isLoading: gettingBreakIssueTop,
    data: BreakIssueTop,
  } = useAsync<BreakRankingListType[]>();
  const getBreakIssueTopHanle = () => {
    if (queryDateRange) {
      const start_date = queryDateRange[0]?.format("YYYY-MM-DD");
      const end_date = queryDateRange[1]?.format("YYYY-MM-DD");
      getBreakIssueTop(
        client(GET_BREAK_ISSUES_TOP, {
          data: {
            start_date: start_date,
            end_date: end_date,
          },
        }),
      );
    }
  };
  const { run: getBreakIssueTopDebounce } = useDebounceFn(
    getBreakIssueTopHanle,
    {
      wait: 200,
    },
  );

  const {
    run: getBreakIssueTopStatistics,
    isLoading: gettingBreakIssueTopStatistics,
    data: BreakIssueTopStatistics,
  } = useAsync<BreakStatisticRankingListType[]>();
  const getBreakIssueTopStatisticsHanle = () => {
    getBreakIssueTopStatistics(client(GET_BREAK_ISSUES_TOP_STATISTICS));
  };
  const { run: getBreakIssueTopStatisticsDebounce } = useDebounceFn(
    getBreakIssueTopStatisticsHanle,
    {
      wait: 200,
    },
  );

  const weekStr = GetDate("week")?.toString();
  const monthStr = GetDate("month")?.toString();
  const yearStr = GetDate("year")?.toString();

  useMount(() => {
    getBreakMonthTotalStatisticsDebounce();
    getBreakIssueTopDebounce();
    getBreakIssueTopStatisticsDebounce();
    getBreakMonthStatisticsDebounce();
    // setQueryDateRange(GetDate("week"));
    setQueryDateRange(GetDate("last_30_days"));
  });

  useEffect(() => {
    getBreakMonthTotalStatisticsDebounce();
  }, [tabName]);

  useEffect(() => {
    if (queryDateRange) {
      getBreakIssueTopDebounce();
      const queryDateRangeStr = queryDateRange?.toString();
      if (queryDateRangeStr === weekStr) {
        setLightButton("week");
      } else if (queryDateRangeStr === monthStr) {
        setLightButton("month");
      } else if (queryDateRangeStr === yearStr) {
        setLightButton("year");
      } else {
        setLightButton("");
      }
    }
  }, [queryDateRange]);

  useEffect(() => {
    // 使用故障排行榜
    if (BreakIssueTop) {
      setRankingListData(BreakIssueTop);
    }
  }, [BreakIssueTop]);

  useEffect(() => {
    // 使用故障统计排行榜
    if (BreakIssueTopStatistics) {
      setStatisticsRankingListData(BreakIssueTopStatistics);
    }
  }, [BreakIssueTopStatistics]);

  useEffect(() => {
    // 各种工单总数
    if (BreakMonthTotalStatistics) {
      setTotalNumBar(GetBreakBar(BreakMonthTotalStatistics));
    }
  }, [BreakMonthTotalStatistics]);

  useEffect(() => {
    // 处理分析
    if (BreakMonthStatistics) {
      setProcessNum(GetProcessNum(BreakMonthStatistics));
    }
  }, [BreakMonthStatistics]);

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader style={{ paddingInline: "0px", paddingBlock: "0px" }}>
            <Row gutter={8}>
              <Col span={12}>
                <Space.Compact block>
                  <Radio.Group
                    onChange={(e) => {
                      setTabName(e.target.value);
                    }}
                    buttonStyle="solid"
                    defaultValue={tabName}
                  >
                    <Radio.Button value={1}>使用故障</Radio.Button>
                    <Radio.Button value={2}>使用需求</Radio.Button>
                    <Radio.Button value={3}>日常服务</Radio.Button>
                    <Radio.Button value={4}>上线交付</Radio.Button>
                    <Radio.Button value={5}>产品升级</Radio.Button>
                  </Radio.Group>
                </Space.Compact>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Space>
                  <Button
                    type={lightButton === "week" ? "link" : "text"}
                    onClick={() => {
                      setQueryDateRange(GetDate("week"));
                    }}
                  >
                    本周
                  </Button>
                  <Button
                    type={lightButton === "month" ? "link" : "text"}
                    onClick={() => {
                      setQueryDateRange(GetDate("month"));
                    }}
                  >
                    本月
                  </Button>
                  <Button
                    type={lightButton === "year" ? "link" : "text"}
                    onClick={() => {
                      setQueryDateRange(GetDate("year"));
                    }}
                  >
                    本年
                  </Button>
                  <RangePicker
                    allowClear={false}
                    onChange={(val) => {
                      setQueryDateRange(val);
                    }}
                    value={queryDateRange}
                    picker="date"
                    onOk={(dates) => {}}
                  />
                </Space>
              </Col>
            </Row>
          </PageHeader>
          <Divider style={{ margin: "2px" }} />
        </Col>
      </Row>
      <Row style={{ minHeight: 400, width: "100%" }}>
        <Col span={16}>
          <Spin spinning={gettingBreakMonthTotalStatistics}>
            <Row style={{ minHeight: 400, width: "100%" }}>
              <Col span={24}>
                <MyBarChart Data={totalNum} />
              </Col>
            </Row>
          </Spin>
        </Col>
        <Col span={8}>
          <RankingList
            title={"使用故障排行"}
            rankingListData={rankingListData ? rankingListData : []}
            labelKey="hospital_name"
            valueKey="value"
            loading={gettingBreakIssueTop}
            lightTop3={true}
          />
        </Col>
      </Row>
      <Divider style={{ margin: "8px" }} />
      <Row style={{ minHeight: 400, width: "100%" }}>
        <Col span={16}>
          <Spin spinning={gettingBreakMonthStatistics}>
            <Row style={{ minHeight: 400, width: "100%" }}>
              <Col span={24}>
                <MyBarChart Data={processNum} />
              </Col>
            </Row>
          </Spin>
        </Col>
        <Col span={8}>
          <RankingList
            title={"使用故障处理统计"}
            rankingListData={
              statisticsRankingListData ? statisticsRankingListData : []
            }
            labelKey="name"
            valueKey="value"
            loading={gettingBreakIssueTopStatistics}
          />
        </Col>
      </Row>
    </>
  );
};
