import { useState, useRef, useEffect } from "react";
import type { BaseSelectRef } from "rc-select";
import { Form, Select, Button, List, Space } from "antd";
import { useDebounceFn } from "ahooks";
import { StopOutlined } from "@ant-design/icons";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import {
  BREAK_TYPE,
  DELIVERY_DETAIL_TYPE,
} from "lib/interface/launchChangeList";

import { breaktRoute } from "../../../../routeSetting";
import { useNavigate } from "react-router-dom";
import { versionParamas } from "utils";

// import "./index.css";

const { Option } = Select;
export const UpdateRelationOrderListSelect = ({
  onUpdate,
  defaultValue,
  name,
  options,
  api,
  isCanChange,
  detailInfo,
  setEditMode,
  setSubmit,
  submit,
}: {
  onUpdate: (value: any) => void;
  defaultValue?: BREAK_TYPE[];
  detailInfo: DELIVERY_DETAIL_TYPE | null;
  label: string;
  name: string;
  options: { label: string; value: string };
  required?: boolean;
  api: string;
  isCanChange?: boolean;
  setEditMode: (value: boolean) => void;
  setSubmit: (value: boolean) => void;
  submit: boolean;
}) => {
  const [form] = Form.useForm();
  const [isEditting, setIsEditting] = useState(false);
  const [isFoucs, setIsFoucs] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const ref = useRef<BaseSelectRef>(null);
  const client = useHttp();
  const navigate = useNavigate();

  const onFinish = (value: any) => {
    if (isChanged) {
      onUpdate(value);
      setIsChanged(false);
      setEditMode(false);
    }
  };

  const { run, data, isLoading, isIdle } = useAsync<any>();
  const runHandle = () => {
    run(client(api));
  };
  const { run: runDebounce } = useDebounceFn(runHandle, {
    wait: 200,
  });

  useEffect(() => {
    form.setFieldsValue({
      [name]: defaultValue?.map((i) => i.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (api && isCanChange) {
      runDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, isCanChange]);

  useEffect(() => {
    if (submit) {
      setIsFoucs(false);
      setIsEditting(false);
      setEditMode(false);
      form.submit();
      setSubmit(false);
    }
  }, [submit]);

  const loading = isLoading || isIdle;

  return (
    <>
      {isCanChange ? (
        <Form
          form={form}
          style={{ width: "380px" }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item name={name}>
            <Select
              disabled={loading}
              loading={loading}
              mode={"multiple"}
              allowClear={true}
              ref={ref}
              placement={"bottomRight"}
              style={{
                whiteSpace: "normal",
                overflowX: "hidden",
                overflowY: "hidden",
                textOverflow: "ellipsis",
                border: "1px solid",
                borderColor: "var(--adm-color-primary)",
                borderRadius: "6px",
              }}
              notFoundContent={
                <div style={{ textAlign: "center" }}>
                  <StopOutlined style={{ fontSize: 14 }} />
                  <p>无可关联工单</p>
                </div>
              }
              placeholder={`选择关联工单`}
              bordered={isEditting}
              suffixIcon={isEditting ? undefined : null}
              onChange={() => {
                setIsChanged(true);
              }}
              onFocus={() => setIsFoucs(true)}
              onMouseEnter={() => setIsEditting(true)}
              onMouseLeave={() => {
                if (isFoucs) return;
                setIsEditting(false);
              }}
              options={data?.map((i: any) => ({
                label: `${i.order_id} (${i[options.label]})`,
                value: i[options.value],
              }))}
            >
              {detailInfo?.relation_order_list?.map((item, index) => (
                <Option key={index} value={item?.id} title={item?.title}>
                  {item?.order_id} {item?.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      ) : (
        <List
          style={{
            maxHeight: "400px",
            overflow: "auto",
            width: "100%",
          }}
          locale={{ emptyText: <span>无关联工单</span> }}
          itemLayout="horizontal"
          dataSource={detailInfo?.relation_order_list}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                className="break-relation-list"
                description={
                  <Space>
                    <Button
                      type="link"
                      onClick={() => {
                        navigate({
                          pathname: `${breaktRoute}`,
                          search: versionParamas({
                            detailId: item.id.toString(),
                            openBreaklistDetail: "true",
                          }),
                        });
                      }}
                    >
                      [{item.order_id}]
                    </Button>
                    <span
                      style={{
                        color: "rgba(0, 0, 0, 0.88)",
                        fontSize: "14px",
                        fontWeight: "normal",
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.title}
                    </span>
                  </Space>
                }
              />
            </List.Item>
          )}
        />
      )}
    </>
  );
};
