import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Input,
  Select,
  message,
  Modal,
  Form,
  Divider,
  Upload,
  UploadFile,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import {
  GET_SYS_VERSION,
  GET_HOS_PRODUCT_LIST,
  GET_EQPT_TYPE,
  GET_EQPT_OEM,
  ADD_HARDWARE,
  GET_EQPT_MODEL,
  ADD_FILE,
} from "lib/api";
import {
  EqptModel,
  EqptOem,
  EqptType,
  HospProductsListType,
  SystemList,
} from "../interface";
import { useAsync } from "utils/hooks/useAsync";
import { IpRegex } from "utils/regexp";
import {
  DiskCapacityList,
  DiskNumberList,
  DisposeForm,
  handleUploadImage,
  limitUploadImage,
  MemoryCapacityList,
  MemoryNumberList,
  SsdCapacityList,
} from "utils";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { handlePreview } from "utils/handlePreview";

const { TextArea } = Input;
const { Option } = Select;

export const ServerBaseInfoAdd = ({
  hospID,
  visible,
  setVisible,
  reload,
}: {
  hospID: number | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const [, setServer] = useUrlQueryParam([]);

  const client = useHttp();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const {
    run: getHosProductList,
    isLoading: gettingHosProductList,
    data: hospProductList,
  } = useAsync<HospProductsListType[]>();
  const getHosProductListFunc = (hospital_id: number) => {
    getHosProductList(client(GET_HOS_PRODUCT_LIST(hospital_id)));
  };

  const {
    run: getEqptTypeList,
    // isLoading: gettingEqptTypeList,
    data: eqptTypeList,
  } = useAsync<EqptType[]>();
  const getEqptTypeListFunc = () => {
    getEqptTypeList(client(GET_EQPT_TYPE));
  };

  const {
    run: getEqptOemList,
    isLoading: gettingEqptOemList,
    data: eqptOemList,
  } = useAsync<EqptOem[]>();
  const getEqptOemListFunc = (eqpt_type_id: number) => {
    getEqptOemList(client(GET_EQPT_OEM(eqpt_type_id)));
  };

  const {
    run: getEqptModeList,
    isLoading: gettingEqptModeList,
    data: eqptModeList,
  } = useAsync<EqptModel[]>();
  const getEqptModeListFunc = (eqpt_oem_id: number) => {
    getEqptModeList(client(GET_EQPT_MODEL(eqpt_oem_id)));
  };

  const {
    run: getSysVersionList,
    isLoading: gettingSysVersionList,
    data: sysVersionList,
  } = useAsync<SystemList[]>();
  const getSysVersionListFunc = () => {
    getSysVersionList(client(GET_SYS_VERSION));
  };

  const [eqptTypeID, setEqptTypeID] = useState<number | null>(null);
  const [eqptOemID, setEqptOemID] = useState<number | null>(null);

  const submit = (value: any) => {
    if (hospID) {
      client(ADD_HARDWARE, {
        data: {
          ...value,
          asset_photos: fileList.map((i) => i.uid),
          hospital_id: parseInt(hospID?.toString()),
        },
        method: "POST",
      })
        .then((res) => {
          message.success("添加成功！");
          setServer({ server: res.id });
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("添加失败！请检查或者联系管理员！");
        });
    } else {
      message.error("添加失败！请刷新重试或者联系管理员！");
    }
  };

  const clear = () => {
    form.setFieldsValue({
      eqpt_model_id: null,
    });
  };

  useEffect(() => {
    if (visible) {
      if (hospID) {
        getHosProductListFunc(hospID);
      }
      setFileList([]);
      form.resetFields();
      getSysVersionListFunc();
      getEqptTypeListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (eqptTypeList?.length) {
      let serverID = eqptTypeList.find((t) => t.name === "服务器")?.id;
      if (serverID) {
        setEqptTypeID(serverID);
      }
    }
  }, [eqptTypeList]);

  useEffect(() => {
    if (eqptTypeID) {
      getEqptOemListFunc(eqptTypeID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqptTypeID]);

  useEffect(() => {
    if (eqptOemID) {
      getEqptModeListFunc(eqptOemID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqptOemID]);

  return (
    <Modal
      centered
      title="新增硬件信息"
      open={visible}
      destroyOnClose
      forceRender
      maskClosable={false}
      onOk={form.submit}
      okText="提交"
      onCancel={() => setVisible(false)}
      bodyStyle={{
        minHeight: "12rem",
        maxHeight: "80vh",
        overflow: "auto",
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 17 }}
        onFinish={submit}
      >
        <Form.Item
          name="server_ip"
          label="服务器IP"
          rules={[
            { required: true, message: "必须输入IP" },
            {
              pattern: IpRegex(),
              message: "必须输入合法的IP格式",
            },
          ]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="products"
          label="运行产品"
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingHosProductList}
            mode="multiple"
            placeholder="选择产品线"
          >
            {hospProductList?.map((i, index) => (
              <Option key={index} value={i.product_id}>
                {i.product_id}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="products"
          label="安装分支"
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingHosProductList}
            mode="multiple"
            placeholder="选择产品线"
          >
            {hospProductList?.map((i, index) => (
              <Option key={index} value={i.product_id}>
                {i.product_id}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="is_master"
          label="是否主机"
          rules={[{ required: true, message: "必须说明是否为(非)主机" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select placeholder="是否主机">
            {[
              { value: 1, name: "是" },
              { value: 0, name: "否" },
            ].map((i) => (
              <Option key={i.value} value={i.value}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="eqpt_oem_id"
          label="设备品牌"
          rules={[{ required: true, message: "必须选择设备品牌" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingEqptOemList}
            placeholder="选择设备品牌"
            allowClear
            onChange={(v) => {
              clear();
              setEqptOemID(v);
            }}
          >
            {eqptOemList?.map((item, index) => (
              <Option key={index} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="eqpt_model_id"
          label="设备型号"
          rules={[{ required: true, message: "必须选择设备型号" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingEqptModeList}
            placeholder="选择设备型号"
            allowClear
          >
            {eqptModeList?.map((item, index) => (
              <Option key={index} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="maintenance_code"
          label="维保代码"
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="assets_code"
          label="资产编码"
          rules={[{ required: true, message: "必须输入资产编码" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="system_version_id"
          label="系统版本"
          rules={[{ required: true, message: "必须选择系统版本" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingSysVersionList}
            placeholder="选择系统版本"
            allowClear
          >
            {sysVersionList?.map((item, index) => (
              <Option key={index} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="ssd_capacity"
          label="SSD配置"
          rules={[{ required: true, message: "必须选择SSD配置" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select placeholder="SSD大小(GB)">
            {SsdCapacityList.map((i, index) => (
              <Option key={index} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Divider style={{ margin: "8px" }} />
        <Form.Item
          name="memory_capacity"
          label="内存条配置"
          rules={[{ required: true, message: "必须选择内存条配置" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select placeholder="单根内存大小(GB)">
            {MemoryCapacityList.map((i, index) => (
              <Option key={index} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="memory_num"
          label="内存条数量"
          rules={[{ required: true, message: "必须选择内存条数量" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select placeholder="内存条数量">
            {MemoryNumberList.map((i, index) => (
              <Option key={index} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Divider style={{ margin: "8px" }} />
        <Form.Item
          name="disk_capacity"
          label="机械硬盘配置"
          normalize={(v) => DisposeForm(v)}
        >
          <Select allowClear placeholder="单个硬盘大小(TB)">
            {DiskCapacityList.map((i, index) => (
              <Option key={index} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="disk_num"
          label="机械硬盘数量"
          normalize={(v) => DisposeForm(v)}
        >
          <Select allowClear placeholder="机械硬盘数量">
            {DiskNumberList.map((i, index) => (
              <Option key={index} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Divider style={{ margin: "8px" }} />
        <Form.Item
          name="location"
          label="放置位置"
          rules={[{ required: true, message: "必须输入放置位置" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <TextArea allowClear />
        </Form.Item>
        <Form.Item
          name="asset_photos"
          label="资产照片"
          normalize={(v) => DisposeForm(v)}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={3}
            multiple={true}
            action={ADD_FILE}
            listType="picture-card"
            fileList={fileList}
            beforeUpload={limitUploadImage}
            onChange={(info) => handleUploadImage(info, setFileList)}
            accept="image/*"
            onPreview={(f) =>
              handlePreview(f, {
                setPreviewImage,
                setPreviewOpen,
                setPreviewTitle,
              })
            }
          >
            {fileList.length >= 3 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传(限3个)</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </Form>
      <Modal
        centered
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        width={"61.8%"}
      >
        <div style={{ width: "100%" }}>
          <img alt="previwe" style={{ width: "100%" }} src={previewImage} />
        </div>
      </Modal>
    </Modal>
  );
};
