import { useState, Fragment, Dispatch, SetStateAction } from "react";
import { Button, Descriptions, Space, message, Popconfirm } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import {
  IHospitalGroup,
  IPackageData,
} from "authenticated-app/pc/setting/customer-setting/interface";
import { SelectedPermissionAdd } from "./add";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { HosNodesTree } from "authenticated-app/pc/customer/customer-list/interface";
import { GET_HOSP_NODES, HOSP_LIST_API } from "lib/api";
import { useMount } from "utils";
import { HospListType } from "interface";
import { SelectedPermissionEdit } from "./edit";
import { PackageType } from "../../interface";
import { GET_GROUP_LIST, GET_PACKAGE_LIST } from "../../api";
// import { PermissionResourcesAdd } from "./add";
// import { PermissionResourcesEdit } from "./edit";

const SelectedPermission = ({
  packageData,
  update,
}: {
  packageData: IPackageData[];
  update: Dispatch<SetStateAction<IPackageData[]>>;
}) => {
  const client = useHttp();

  const [addSettingVisible, setAddSettingVisible] = useState(false);
  const [editSettingVisible, setEditSettingVisible] = useState(false);

  const [canEdit, setCanEdit] = useState(false);

  const [edittingInfo, setEdittingInfo] = useState<IPackageData>();

  const {
    run: getPackageList,
    isIdle: idleGetPackageList,
    isLoading: gettingPackageList,
    data: packageList,
  } = useAsync<PackageType[]>();
  const getPackageListHandle = () => {
    getPackageList(client(GET_PACKAGE_LIST));
  };

  const {
    run: getAreaList,
    isIdle: idleGetAreaList,
    isLoading: gettingAreaList,
    data: areaList,
  } = useAsync<HosNodesTree[]>();
  const getAreaListHandle = () => {
    getAreaList(client(GET_HOSP_NODES));
  };

  const {
    run: getHospList,
    isIdle: idleGetHospList,
    isLoading: gettingHospList,
    data: hospList,
  } = useAsync<HospListType[]>();
  const getHospListHandle = () => {
    getHospList(client(HOSP_LIST_API));
  };

  const {
    run: getGroupList,
    isIdle: idleGetGroupList,
    isLoading: gettingGroupList,
    data: groupList,
  } = useAsync<IHospitalGroup[]>();
  const getGroupListHandle = () => {
    getGroupList(client(GET_GROUP_LIST));
  };

  const delSetting = (key: number) => {
    try {
      let tmp = packageData.filter((i) => i.key !== key);
      update(tmp);
      message.success("删除成功");
    } catch (error) {
      console.error(error);
      message.error("无法进行删除，请稍后重试或联系管理员！");
    }
  };

  const updateList = (data: IPackageData) => {
    try {
      packageData.forEach((v, i) => {
        if (v.key === data.key) {
          packageData[i] = data;
        }
      });
      update(packageData);
    } catch (e) {
      console.error(e);
      console.error(data);
      message.error("更新出错，请联系管理员！");
    }
  };

  const SettingDescItem = (item: IPackageData, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`gpuSetting${key}`}>
        <Descriptions.Item label={title("权限")} className={className()}>
          {item?.packageName || "*"}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("操作")}
          className={className()}
        >
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              loading={
                (editSettingVisible && item?.key === edittingInfo?.key) ||
                !canEdit
              }
              onClick={() => {
                setEdittingInfo(item);
                setEditSettingVisible(true);
              }}
            />
            <Popconfirm
              title={`确认删除【${item.packageName || "*"}】？`}
              onConfirm={() => delSetting(item.key)}
              okText="确认"
              cancelText="取消"
              icon={<ExclamationCircleOutlined />}
              style={{ color: "red" }}
            >
              <Button
                danger
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        </Descriptions.Item>
      </Fragment>
    );
  };

  useMount(() => {
    getPackageListHandle();
    getAreaListHandle();
    getHospListHandle();
    getGroupListHandle();
  });

  const loading =
    idleGetPackageList ||
    gettingPackageList ||
    idleGetAreaList ||
    gettingAreaList ||
    idleGetHospList ||
    gettingHospList ||
    idleGetGroupList ||
    gettingGroupList;

  return (
    <Fragment>
      <Descriptions
        column={2}
        bordered={packageData?.length ? true : false}
        layout="vertical"
        extra={
          <Button
            loading={addSettingVisible}
            icon={<PlusOutlined />}
            onClick={() => setAddSettingVisible(true)}
          >
            新增
          </Button>
        }
      >
        {packageData?.map((item, index) => SettingDescItem(item, index))}
      </Descriptions>
      <SelectedPermissionAdd
        loading={loading}
        packageData={packageData}
        packageList={packageList || []}
        areaList={areaList || []}
        hospList={hospList || []}
        groutList={groupList || []}
        visible={addSettingVisible}
        setVisible={setAddSettingVisible}
        update={update}
      />
      <SelectedPermissionEdit
        loading={loading}
        editingItem={edittingInfo}
        packageList={packageList || []}
        areaList={areaList || []}
        hospList={hospList || []}
        groutList={groupList || []}
        visible={editSettingVisible}
        setVisible={setEditSettingVisible}
        update={updateList}
        setCanEdit={setCanEdit}
      />
    </Fragment>
  );
};

export default SelectedPermission;
