import { useEffect } from "react";
import { Form, Modal, Input, DatePicker } from "antd";
import { useDeliveryDetailSendback } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailSendback";
import {
  DELIVERY_DETAIL_TYPE,
  NEXT_PROCESS_TYPE,
} from "lib/interface/launchChangeList";
import { FormatDate, FormatDayjs } from "utils";
// import { useHttp } from "utils/http";
// import { HospSelectFilter } from "utils";

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const DeliveryDetailSendbackModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailSendback();

  const [form] = Form.useForm();
  // const client = useHttp();

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      actual_return_date: FormatDate(value.actual_return_date),
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        ...(detailInfo?.actual_return_date
          ? {
              actual_return_date: FormatDayjs(detailInfo?.actual_delivery_date),
            }
          : {}),
        return_tracking_number: detailInfo?.return_tracking_number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="物料退回"
      onCancel={close}
      onOk={form.submit}
      width={"700px"}
      style={{ minWidth: "550px" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        <Form.Item name="return_tracking_number" label="退回物流单号">
          <TextArea autoSize placeholder="填写退回物料的物流单号" />
        </Form.Item>
        <Form.Item
          name="actual_return_date"
          label="实际退回日期"
          rules={[{ required: true, message: "必须选择实际退回日期" }]}
        >
          <DatePicker
            style={{ width: "100%" }}
            placeholder="请选择实际退回日期"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
