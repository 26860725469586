import { Fragment } from "react";
import { Descriptions, Badge } from "antd";

import { RemoteAssetType } from "../interface";
import { ProTable, ProColumns } from "@ant-design/pro-components";

const RemoteAssetList = ({
  assetInfo,
}: {
  assetInfo: RemoteAssetType[] | undefined;
}) => {
  const columns: ProColumns<RemoteAssetType>[] = [
    {
      title: "编号",
      dataIndex: "index",
      valueType: "index",
      width: 50,
    },
    {
      title: "IP",
      dataIndex: "ip",
    },
    {
      title: "资产名称",
      dataIndex: "asset_name",
    },
    {
      title: "可连接性",
      dataIndex: "connectivity",
      render: (_, record) => (
        <Badge
          status={
            record.connectivity === "ok"
              ? "success"
              : record.connectivity === "failed"
              ? "error"
              : "default"
          }
          text={
            record.connectivity === "ok"
              ? "可连接"
              : record.connectivity === "failed"
              ? "连接失败"
              : record.connectivity === "unknown"
              ? "未知"
              : record.connectivity
          }
        />
      ),
    },
    {
      title: "APN",
      dataIndex: "apn",
    },
    {
      title: "最后连接人",
      dataIndex: "last_user",
    },
    {
      title: "最后连接时间",
      dataIndex: "last_connect",
    },
    {
      title: "ICCID",
      dataIndex: "iccid",
    },
    {
      title: "卡状态",
      dataIndex: "status",
    },
  ];

  return (
    <Fragment>
      <h3 style={{ margin: "0px 4px 4px 0px" }}>远程资产列表</h3>
      <ProTable<RemoteAssetType>
        size="small"
        columns={columns}
        dataSource={assetInfo}
        rowKey="index"
        search={false}
        pagination={false}
        toolBarRender={false}
      ></ProTable>
    </Fragment>
  );
};

export default RemoteAssetList;
