import { Dispatch, forwardRef, SetStateAction } from "react";

import { exeChildFunc } from "authenticated-app/pc/customer/customer-detail/interface";

import { VisitListTable } from "./visitTable";
const VisitTabPane = forwardRef<
  exeChildFunc,
  {
    hospID: number | null;
    setDisChange: Dispatch<SetStateAction<boolean>>;
  }
>(({ hospID, setDisChange }, ref) => {
  return <VisitListTable hospID={hospID} />;
});

export default VisitTabPane;
