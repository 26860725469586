import { Fragment, useEffect, useState } from "react";
import {
  FloatingBubble,
  Form,
  InfiniteScroll,
  List,
  PullToRefresh,
  SearchBar,
  Tag,
  Grid,
} from "antd-mobile";
import { AddOutline, CloseOutline, FileOutline } from "antd-mobile-icons";
import { Action } from "antd-mobile/es/components/popover";
import { useDebounceFn } from "ahooks";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { FormatDate, useMount } from "utils";
import { priorityColor, priorityStatus } from "utils/ticket";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { BREAK_LIST_TYPE, BREAK_TYPE } from "lib/interface/breaklist";
import { GET_BREAKLIST } from "lib/api/issues";
import { breakNameStorageKey } from "authenticated-app/mobile";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { useBreaklistAdd } from "authenticated-app/hooks/breaklist/useBreaklistAdd";
import { MobileAddBreaklistPopup } from "./add";
import { BreaklistSelectDropdown } from "./dropdown/select";
import { BreaklistSearchDropdown } from "./dropdown/search";
import { getSelectParams } from "./dropdown";
import { useAuth } from "context/auth";
import { useBreaklistDetail } from "authenticated-app/hooks/breaklist/useBreaklistDetail";
import { MobileBreaklistDetailPopup } from "./detail";

const pageSize = 15;

export const MobileBreakList = () => {
  const { user } = useAuth();

  const [{ search_type, select_type }] = useUrlQueryParam([
    "search_type",
    "select_type",
  ]);

  const [floatButtonVisible, setFloatButtonVisible] = useState<boolean>(false);
  const [, openBreaklistAdd] = useBreaklistAdd();
  const [, openBreaklistDetail] = useBreaklistDetail();

  const floatButtonActions: Action[] = [
    {
      key: "manual",
      icon: <FileOutline />,
      text: "客户反馈",
      onClick: () => {
        setFloatButtonVisible(false);
        openBreaklistAdd();
      },
    },
  ];

  const client = useHttp();

  const [searchName, setSearchName] = useState<string>(
    window.sessionStorage.getItem(breakNameStorageKey) || "",
  );

  // 分页
  const [data, setData] = useState<BREAK_TYPE[]>([]);
  const [next, setNext] = useState<number>();
  const [hasMore, setHasMore] = useState(false);

  const [searchForm] = Form.useForm();
  const [selectForm] = Form.useForm();

  const {
    run: getBreaklist,
    isLoading: gettingBreaklist,
    isIdle: getBreaklistIsIdle,
  } = useAsync<BREAK_LIST_TYPE>();
  const getBreaklistHandle = (
    params?: object,
    search?: string,
    search_type?: string,
  ) => {
    let queryParams: object = {};
    if (params) {
      queryParams = { ...params };
    } else {
      let selectParams = getSelectParams(select_type, user);
      queryParams = { ...selectParams };
    }
    if (search) {
      queryParams = {
        ...queryParams,
        [search_type === "sd" ? "order_id" : "customer_name"]:
          search || undefined,
      };
    }
    getBreaklist(
      client(GET_BREAKLIST, {
        data: { pageSize, current: 1, ...queryParams },
      }),
    ).then((res) => {
      setData(res?.results || []);
      setNext((res?.number || 0) + 1);
      setHasMore(!!res?.links?.next);
    });
  };
  const { run: getBreaklistBounce } = useDebounceFn(getBreaklistHandle, {
    wait: 200,
  });

  const onSubmit = (params: any, search_type?: string) => {
    let subValue = { ...params };
    getBreaklistHandle({ ...subValue }, searchName, search_type);
  };

  const loadMore = async () => {
    let selectParams = getSelectParams(select_type, user);
    let append = await client(GET_BREAKLIST, {
      data: {
        pageSize,
        current: next || 1,
        ...selectParams,
        [search_type === "sd" ? "order_id" : "customer_name"]:
          searchName || undefined,
      },
    });
    setData((val) => [...val, ...(append?.results || [])]);
    setNext((append?.number || 0) + 1);
    setHasMore(!!append?.links?.next);
  };

  useMount(() => getBreaklistBounce(undefined, searchName, search_type));

  const Loading = gettingBreaklist || getBreaklistIsIdle;

  return (
    <div>
      <LoadingMask visible={Loading} />
      <div className={"mobile-header"}>
        <BreaklistSearchDropdown form={searchForm} onSubmit={onSubmit} />
        <SearchBar
          defaultValue={searchName}
          placeholder={search_type === "sd" ? "搜索SD号码" : "搜索客户名称"}
          onSearch={(v) => {
            getBreaklistHandle(undefined, v, search_type);
            setSearchName(v);
            window.sessionStorage.setItem(breakNameStorageKey, v || "");
          }}
          onClear={() => {
            getBreaklistHandle(undefined, undefined, search_type);
            setSearchName("");
            window.sessionStorage.removeItem(breakNameStorageKey);
          }}
          style={{ width: "100%" }}
        />
        <BreaklistSelectDropdown form={selectForm} onSubmit={onSubmit} />
      </div>
      <div className={"mobile-body"}>
        <PullToRefresh
          onRefresh={async () => {
            getBreaklistHandle(undefined, searchName, search_type);
          }}
          refreshingText={null}
        >
          <Fragment>
            <List>
              {data?.map((i) => (
                <List.Item
                  key={i.id}
                  description={
                    <div>
                      <Grid columns={1}>
                        <Grid.Item>
                          <span>
                            {i?.hospital_name || i?.customer_name || "未知"}
                          </span>
                        </Grid.Item>
                      </Grid>
                      <Grid columns={1}>
                        <Grid.Item>
                          {/* <span style={{ marginLeft: "4px" }}> */}
                          <span> {i?.order_type_name}</span>
                          <span style={{ marginLeft: "8px" }}>
                            {/* <span> */}
                            {FormatDate(i?.create_time)}
                          </span>
                        </Grid.Item>
                      </Grid>
                    </div>
                  }
                  onClick={() => {
                    openBreaklistDetail(i.id);
                  }}
                  arrow={
                    <div
                      style={{
                        fontSize: "var(--adm-font-size-main)",
                        textAlign: "end",
                        color: "var(--adm-color-weak)",
                      }}
                    >
                      <div>
                        <Tag
                          round
                          color={priorityColor.get(
                            (i?.priority as priorityStatus) ||
                              priorityStatus.medium,
                          )}
                          style={{
                            margin: 0,
                            padding: "2px",
                            transform: "translateY(-2px)",
                            marginRight: "4px",
                          }}
                        />
                        {i?.priority}
                      </div>
                      <div>{i?.process_name}</div>
                    </div>
                  }
                >
                  <span style={{ wordBreak: "break-all" }}>
                    [{i?.order_id}]&nbsp;{i?.title}
                  </span>
                </List.Item>
              ))}
            </List>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
          </Fragment>
        </PullToRefresh>
        <FloatingBubble
          axis="y"
          magnetic="x"
          style={{
            "--initial-position-bottom": "62px",
            "--initial-position-right": "12px",
            "--edge-distance": "12px 12px 62px 12px",
            "--z-index": "999",
          }}
          // onClick={() => {
          //   setFloatButtonVisible(!floatButtonVisible);
          // }}
          onClick={() => {
            setFloatButtonVisible(false);
            openBreaklistAdd();
          }}
        >
          <AddOutline fontSize={32} />
          {/* <Popover.Menu
            mode="dark"
            actions={floatButtonActions}
            trigger="click"
            visible={floatButtonVisible}
          >
            <Button
              fill="none"
              style={{ padding: 0, color: "var(--adm-color-white)" }}
              shape="rounded"
            >
              {floatButtonVisible ? (
                <CloseOutline fontSize={28} />
              ) : (
                <AddOutline fontSize={32} />
              )}
            </Button>
          </Popover.Menu> */}
        </FloatingBubble>
        <MobileAddBreaklistPopup
          reload={() => getBreaklistHandle(undefined, searchName, search_type)}
        />
        <MobileBreaklistDetailPopup
          reload={() => getBreaklistHandle(undefined, searchName, search_type)}
        />
      </div>
    </div>
  );
};
