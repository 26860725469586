import { message } from "antd";
import { ready, runtime } from "dingtalk-jsapi";
import { LOGIN } from "lib/api";
import { corpId } from "lib/content/login";
import { getRedirectUrl } from "utils";

const localStorageKey = "Infer-CSM-Authorization";

export const getToken = () => window.localStorage.getItem(localStorageKey);

export const setToken = (token: string | null) => {
  window.localStorage.setItem(localStorageKey, token || "");
  return token;
};

// 自动登录
export const login = () => {
  ready(() => {
    runtime.permission
      .requestAuthCode({
        corpId: corpId,
      })
      .then(
        (response) => {
          fetch(LOGIN, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json; charset=utf-8",
            },
            method: "POST",
            cache: "no-cache",
            credentials: "include",
            body: JSON.stringify({
              loginChannel: 6,
              loginName: response.code,
            }),
          }).then(async (response) => {
            setToken((await response.json()).token);
            setTimeout(() => {
              window.location.replace(
                `${window.location.origin}${getRedirectUrl()}`,
              );
            }, 500);
          });
        },
        async (reject) => {
          let data = await reject;
          console.error(`自动登录错误: ${JSON.parse(data)}`);
          message.error(
            data?.errorMessage || "自动登录未知错误，请稍后重试或者联系管理员",
          );
          setToken(null);
        },
      );
  });
};

export const logout = async () => {
  window.localStorage.removeItem(localStorageKey);
  window.location.replace(
    `${window.location.origin}?redirect=${window.location.href.replace(
      window.location.origin,
      "",
    )}`,
  );
};
