import { useEffect, useState, RefObject } from "react";
import { useDebounceFn } from "ahooks";
import { useAuth } from "context/auth";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import {
  Form,
  Button,
  TextArea,
  DatePicker,
  DatePickerRef,
  Picker,
} from "antd-mobile";
import { RadioValue } from "antd-mobile/es/components/radio";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";
import {
  NEXT_PROCESS_TYPE,
  DELIVERY_DETAIL_TYPE,
  DELIVERY_USER_TYPE,
  DELIVERY_PAUSE_REASON_LIST_TYPE,
} from "lib/interface/launchChangeList";
import { FormatDayjs, FormatDate, DisposeForm } from "utils";
import {
  GET_DELIVERY_USER_LIST,
  GET_DELIVERY_PAUSE_REASON_TYPE,
} from "lib/api/deliverylist";
import { useDeliveryDetailPause } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailPause";

export const DeliveryDetailPauseModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailPause();
  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getDeliveryPauseReasonList,
    data: deliverPauseReasonList,
    isIdle: getDeliveryPauseReasonListIdle,
    isLoading: gettingDeliverPauseReasonList,
  } = useAsync<DELIVERY_PAUSE_REASON_LIST_TYPE[]>();
  const getDeliveryPauseReasonListHandle = () => {
    getDeliveryPauseReasonList(client(GET_DELIVERY_PAUSE_REASON_TYPE));
  };
  const { run: getDeliveryPauseReasonListDebounce } = useDebounceFn(
    getDeliveryPauseReasonListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      pause_reason_type_id: value.pause_reason_type_id[0],
      ...(value.next_entry_date
        ? { next_entry_date: FormatDate(value.next_entry_date) }
        : {}),
    });
    close();
  };

  useEffect(() => {
    form.resetFields();
    if (isOpen) {
      form.setFieldsValue({
        pause_reason_type_id: [detailInfo?.pause_reason_type_id],
        pause_reason: detailInfo?.pause_reason,
        next_entry_date: detailInfo?.next_entry_date
          ? FormatDayjs(detailInfo?.next_entry_date).toDate()
          : null,
      });
      getDeliveryPauseReasonListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <EditPopup visible={isOpen} submitting={false} setVisible={close}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={false}
          >
            提交
          </Button>
        }
      >
        <Form.Header>暂停交付</Form.Header>
        <Form.Item
          name="pause_reason_type_id"
          label="暂停交付分类"
          rules={[{ required: true, message: "必须选择暂停交付原因分类" }]}
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={
              getDeliveryPauseReasonListIdle || gettingDeliverPauseReasonList
            }
            columns={
              deliverPauseReasonList
                ? [
                    deliverPauseReasonList.map((i) => ({
                      label: `${i.name}`,
                      value: i.id,
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择暂停交付分类</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="pause_reason"
          label="暂停交付原因"
          rules={[{ required: true, message: "必须填写暂停交付原因" }]}
        >
          <TextArea placeholder="填写暂停交付原因" autoSize={{ minRows: 3 }} />
        </Form.Item>
        <Form.Item
          name="next_entry_date"
          label="下次跟进日期"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择下次跟进日期" }]}
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
          layout="horizontal"
        >
          <DatePicker title="下次跟进日期" precision="day" tillNow>
            {(value) =>
              value ? (
                <FormItemDiv>{FormatDate(value)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择下次跟进日期</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
