import { useEffect, RefObject, useState } from "react";
import {
  Form,
  Button,
  Radio,
  TextArea,
  Space,
  DatePicker,
  DatePickerRef,
  Picker,
} from "antd-mobile";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { useDebounceFn } from "ahooks";
import ReactQuill from "react-quill";

import {
  GET_DEPARTMENT_USERS,
  // GET_ISSUES_MODULE,
  GET_ISSUES_REASON,
  GET_ISSUES_REASON_NEW,
  GET_ISSUES_TYPE,
} from "lib/api/issues";
import {
  BREAKLIST_OPTION_TYPE,
  BREAK_DETAIL_TYPE,
  ORDER_DEPARTMENT_TYPE,
} from "lib/interface/breaklist";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import {
  DisposeQuill,
  FormatTime,
  FormatDate,
  DisposeForm,
  FormatDayjs,
} from "utils";

import { useBreakDetailReverse } from "authenticated-app/hooks/breaklist/trans/useBreakDetailReverse";
import { editQuillToolbarOption } from "authenticated-app/pc/components/EditQuill/option";
import { UrlRegex } from "utils/regexp";

import "authenticated-app/pc/components/EditQuill/quill.snow.css";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";

import {
  ConvertDateToPickerValueList,
  ConvertToPickerValueList,
} from "authenticated-app/mobile/util";

const now = new Date();

export const BreakDetailReverseModal = ({
  onTrans,
  next,
  department,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: number[];
  department: number[];
  detailInfo: BREAK_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useBreakDetailReverse();
  const [selectedIssuesTypeId, setSelectedIssuesTypeId] = useState<number>();

  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getDepartmentList,
    data: departmentList,
    isIdle: getDepartmentListIdle,
    isLoading: gettingDepartmentList,
  } = useAsync<ORDER_DEPARTMENT_TYPE[]>();
  const getDepartmentListHandle = () => {
    getDepartmentList(client(GET_DEPARTMENT_USERS(department)));
  };
  const { run: getDepartmentListDebounce } = useDebounceFn(
    getDepartmentListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getIssuesTypeList,
    data: issuesTypeList,
    isLoading: gettingIssuesTypeList,
    isIdle: getIssuesTypeListIdle,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesTypeListHandle = () => {
    getIssuesTypeList(client(GET_ISSUES_TYPE));
  };
  const { run: getIssuesTypeListDebounce } = useDebounceFn(
    getIssuesTypeListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getIssuesReasonList,
    data: issuesReasonList,
    isLoading: gettingIssuesReasonList,
    isIdle: getIssuesReasonListIdle,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesReasonListHandle = () => {
    if (selectedIssuesTypeId) {
      getIssuesReasonList(client(GET_ISSUES_REASON_NEW(selectedIssuesTypeId)));
    }
  };
  const { run: getIssuesReasonListDebounce } = useDebounceFn(
    getIssuesReasonListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      up_user_id: value?.up_user_id
        ? parseInt(value?.up_user_id[0])
        : undefined,
      issue_type_id: value?.issue_type_id
        ? parseInt(value?.issue_type_id[0])
        : undefined,
      issue_reason_id: value?.issue_reason_id
        ? parseInt(value?.issue_reason_id[0])
        : undefined,
      delivery_time: FormatTime(value.delivery_time),
      next_process: next,
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedIssuesTypeId(undefined);
      form.resetFields();
      getDepartmentListDebounce();
      if (detailInfo?.process_name !== "研发处理中") {
        getIssuesTypeListDebounce();
        if (detailInfo?.issue_type_id) {
          setSelectedIssuesTypeId(detailInfo.issue_type_id);
        }
        form.setFieldsValue({
          issue_type_id: detailInfo?.issue_type_id
            ? [detailInfo?.issue_type_id]
            : [],
          issue_reason_id: detailInfo?.issue_reason_id
            ? [detailInfo?.issue_reason_id]
            : [],
          knowledge_link: detailInfo?.knowledge_link,
          is_known: detailInfo?.is_known,
          is_temporary: detailInfo?.is_temporary,
        });
      }
      form.setFieldsValue({
        up_user_id: undefined,
        debug_info: detailInfo?.debug_info_dict.debug_info,
        solution_info: detailInfo?.solution_info_dict.solution_info,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  useEffect(() => {
    getIssuesReasonListDebounce();
  }, [selectedIssuesTypeId]);

  return (
    <EditPopup visible={isOpen} submitting={false} setVisible={close}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={false}
          >
            提交
          </Button>
        }
      >
        <Form.Header>转回工单</Form.Header>
        <Form.Item
          name="up_user_id"
          label="转回至"
          rules={[{ required: true, message: "必须转回人员" }]}
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={getDepartmentListIdle || gettingDepartmentList}
            columns={
              departmentList
                ? [
                    departmentList.map((i) => ({
                      label: `${i.user_name} (${i.user_job})`,
                      value: i.user_id,
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>指定转回人员</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>

        <Form.Item
          name="debug_info"
          label="定位及补充记录"
          rules={[{ required: true, message: "必须填写定位及补充记录" }]}
          layout="vertical"
          normalize={(v) => DisposeQuill(v)}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
            }}
          />
        </Form.Item>
        <Form.Item
          name="solution_info"
          label="操作及解决"
          rules={[{ required: true, message: "必须填写操作及解决" }]}
          layout="vertical"
          normalize={(v) => DisposeQuill(v)}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
            }}
          />
        </Form.Item>
        {detailInfo?.process_name !== "研发处理中" ? (
          <>
            <Form.Item
              name="issue_type_id"
              label="反馈类型"
              trigger="onConfirm"
              rules={[{ required: true, message: "必须选择反馈类型" }]}
              normalize={(v) => DisposeForm(v)}
              onClick={(_, pickerRef: RefObject<PickerRef>) =>
                pickerRef.current?.open()
              }
            >
              <Picker
                loading={gettingIssuesTypeList || getIssuesTypeListIdle}
                columns={
                  issuesTypeList
                    ? [
                        issuesTypeList.map((i) => ({
                          label: `${i.name}`,
                          value: i.id,
                        })),
                      ]
                    : []
                }
                onConfirm={(v: any[]) => {
                  if (v) {
                    setSelectedIssuesTypeId(v[0]);
                    form.setFieldValue("issue_reason_id", undefined);
                  }
                }}
              >
                {(items: (PickerColumnItem | null)[]) =>
                  items[0] ? (
                    <FormItemDiv>{items[0]?.label}</FormItemDiv>
                  ) : (
                    <FormItemPlaceholder>选择反馈类型</FormItemPlaceholder>
                  )
                }
              </Picker>
            </Form.Item>
            <Form.Item
              name="issue_reason_id"
              label="反馈归因"
              trigger="onConfirm"
              rules={[{ required: true, message: "必须选择反馈归因" }]}
              normalize={(v) => DisposeForm(v)}
              onClick={(_, pickerRef: RefObject<PickerRef>) =>
                pickerRef.current?.open()
              }
              disabled={!!!selectedIssuesTypeId}
              help={"请先选择反馈类型!"}
            >
              <Picker
                loading={getIssuesReasonListIdle || gettingIssuesReasonList}
                columns={
                  issuesReasonList
                    ? [
                        issuesReasonList.map((i) => ({
                          label: `${i.name}`,
                          value: i.id,
                        })),
                      ]
                    : []
                }
              >
                {(items: (PickerColumnItem | null)[]) =>
                  items[0] ? (
                    <FormItemDiv>{items[0]?.label}</FormItemDiv>
                  ) : (
                    <FormItemPlaceholder>选择反馈归因</FormItemPlaceholder>
                  )
                }
              </Picker>
            </Form.Item>

            <Form.Item
              name="knowledge_link"
              label="知识库链接"
              rules={[
                {
                  pattern: UrlRegex(),
                  message: "必须输入合法的URL格式",
                },
              ]}
            >
              <TextArea
                placeholder="填写知识库链接"
                autoSize={{ minRows: 1 }}
              />
            </Form.Item>
            <Form.Item name="is_known" label="是否已知问题">
              <Radio.Group>
                <Space>
                  <Radio value={1}>是</Radio>
                  <Radio value={0}>否</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="is_temporary"
              label="是否临时解决"
              rules={[{ required: true, message: "必须选择是否临时解决" }]}
            >
              <Radio.Group>
                <Space>
                  <Radio value={1}>是</Radio>
                  <Radio value={0}>否</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="delivery_time"
              label="预计交付日期"
              trigger="onConfirm"
              validateTrigger="onConfirm"
              // rules={[{ required: true, message: "必须选择预计交付日期" }]}
              onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
                datePickerRef.current?.open();
              }}
              layout="horizontal"
            >
              <DatePicker title="预计交付日期" precision="day" tillNow>
                {(value) =>
                  value ? (
                    <FormItemDiv>{FormatDate(value)}</FormItemDiv>
                  ) : (
                    <FormItemPlaceholder>预计交付日期</FormItemPlaceholder>
                  )
                }
              </DatePicker>
            </Form.Item>
          </>
        ) : (
          <></>
        )}
      </Form>
    </EditPopup>
  );
};
