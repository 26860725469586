import { useState } from "react";
import { Collapse, List } from "antd-mobile";
import { EditSOutline } from "antd-mobile-icons";

import { DetailType } from "authenticated-app/pc/customer/customer-detail/tab-pane/infoTabPane/interface";
import { ListRightIconSize } from "authenticated-app/mobile/util";
import { MobileHospBaseInfoEdit } from "./edit";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";

export const MobileHospBaseInfo = ({
  detail,
  reload,
}: {
  detail: DetailType | null;
  reload: () => void;
}) => {
  const [editVisible, setEditVisible] = useState<boolean>(false);

  return (
    <ContentCard>
      <List
        mode="card"
        header={
          <ListHeader
            text="客户基本信息"
            rightIcon={<EditSOutline fontSize={ListRightIconSize} />}
            loading={editVisible}
            onClick={() => {
              setEditVisible(true);
            }}
          />
        }
      >
        <List.Item extra={detail?.name || "未知"}>客户名称</List.Item>
        <List.Item extra={detail?.code || "未知"}>客户编码</List.Item>
        <List.Item
          extra={
            detail?.type
              ? `${detail?.type[0]} ${
                  detail?.type[1] ? `(${detail?.type[1]})` : ""
                }`
              : "未知"
          }
        >
          客户类型
        </List.Item>
        <List.Item extra={detail?.area_name || "未知"}>区域</List.Item>
        <List.Item extra={detail?.province_name || "未知"}>省份</List.Item>
        <List.Item extra={detail?.city_name || "未知"}>城市</List.Item>
        <List.Item extra={detail?.county_name || "未知"}>市/区/县</List.Item>
        <Collapse>
          <Collapse.Panel
            key={(detail?.id || "hosp-address").toString()}
            title="客户地址"
          >
            {detail?.address || "未知"}
          </Collapse.Panel>
        </Collapse>
      </List>
      <MobileHospBaseInfoEdit
        editingItem={detail}
        visible={editVisible}
        setVisible={setEditVisible}
        reload={reload}
      />
    </ContentCard>
  );
};
