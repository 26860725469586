import { Fragment } from "react";
import { PullToRefresh, Toast, Collapse, List } from "antd-mobile";
import { useDebounceFn } from "ahooks";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { GET_REMOTE_DETAIL } from "lib/api";
import { useMount } from "utils";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { MobileRemoteRetainInfo } from "./retain-info";
import { RemoteDetailType } from "authenticated-app/pc/customer/customer-detail/tab-pane/remoteTabPane/interface";
import MobileRemoteAssetInfo from "./asset-info";
import MobileRemoteMonitorInfo from "./monitor-info";

export const MobileRemoteInfo = ({
  hospName,
  hospCode,
}: {
  hospName: string;
  hospCode: string;
}) => {
  const [{ detailId }] = useUrlQueryParam(["detailId"]);
  const client = useHttp();

  const {
    run: getRemoteDetail,
    isLoading,
    data,
    isIdle,
  } = useAsync<RemoteDetailType>();
  const getRemoteDetailHandle = () => {
    if (detailId) {
      getRemoteDetail(client(GET_REMOTE_DETAIL(detailId)));
    } else {
      Toast.show({
        icon: "fail",
        content: <ToastContent content="获取失败" />,
      });
    }
  };
  const { run } = useDebounceFn(getRemoteDetailHandle, { wait: 200 });

  useMount(run);

  return (
    <div style={{ width: "100%" }}>
      <LoadingMask visible={isLoading} />
      <PullToRefresh
        onRefresh={async () => {
          getRemoteDetailHandle();
        }}
        refreshingText={null}
      >
        <div className="customer-content">
          <Fragment>
            <ContentCard>
              <List mode="card">
                <List.Item extra={<span>{data?.remote_type_name}</span>}>
                  <span>远程方式</span>
                </List.Item>
              </List>
            </ContentCard>
            <MobileRemoteAssetInfo
              orgID={detailId}
              assets={data?.assets}
              reload={getRemoteDetailHandle}
            />
            <MobileRemoteMonitorInfo hospCode={hospCode} />
            <MobileRemoteRetainInfo
              orgID={detailId}
              retain={data?.retain_infos}
              reload={getRemoteDetailHandle}
            />
          </Fragment>
        </div>
      </PullToRefresh>
    </div>
  );
};
