import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Button, Form, List, Picker, TextArea, Toast } from "antd-mobile";
import {
  PickerRef,
  PickerValue,
  PickerColumnItem,
} from "antd-mobile/es/components/picker";

import { useHttp } from "utils/http";
import {
  GET_HOSP_TYPE,
  GET_HOSP_NODES,
  GET_HOSP_COUNTY,
  UPDATE_HOSP_INFO,
  GET_HOSP_CITY,
} from "lib/api";
import {
  CityType,
  HosNodesType,
} from "authenticated-app/pc/customer/customer-list/interface";
import { DisposeForm, DisposeMobileText } from "utils";
import { useAsync } from "utils/hooks/useAsync";
import {
  CountyType,
  DetailType,
  HosType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/infoTabPane/interface";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { ConvertToPickerValueList } from "authenticated-app/mobile/util";

export const MobileHospBaseInfoEdit = ({
  editingItem,
  visible,
  setVisible,
  reload,
}: {
  editingItem: DetailType | undefined | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getHosNodes,
    isLoading: gettingHosNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesFunc = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };

  const {
    run: getHosTypeList,
    isLoading: gettingHosTypeList,
    data: hosTypeList,
  } = useAsync<HosType[]>();
  const getHosTypeListFunc = () => {
    getHosTypeList(client(GET_HOSP_TYPE));
  };

  const {
    run: getCityList,
    isLoading: gettingCityList,
    data: cityList,
    setData: setCityList,
  } = useAsync<CityType[]>();
  const getCityListFunc = (province_id: number | null | undefined) => {
    if (province_id) {
      getCityList(client(GET_HOSP_CITY(province_id)));
    }
  };

  const {
    run: getCountyList,
    isLoading: gettingCountyList,
    data: countyList,
  } = useAsync<CountyType[]>();
  const getCountyListFunc = (city_id: number | null | undefined) => {
    if (city_id) {
      getCountyList(client(GET_HOSP_COUNTY(city_id)));
    }
  };

  // 基本信息state
  const [areaList, setAreaList] = useState<HosNodesType[]>([]);
  const [provinceList, setProvinceList] = useState<HosNodesType[]>([]);

  const [hosFirTypeList, setHosFirTypeList] = useState<HosType[]>([]);
  const [hosSecTypeList, setHosSecTypeList] = useState<HosType[]>([]);

  const [hosFirTypeID, setHosFirTypeID] = useState<number | null>(null);

  const [hosArea, setHosArea] = useState<number | null>(null);
  const [hosProvince, setHosProvince] = useState<number | null>(null);
  const [hosCity, setHosCity] = useState<number | null>(null);

  const { run: submit, isLoading: submitting } = useAsync();
  const submitHandle = (value: any) => {
    if (editingItem?.id)
      submit(
        client(UPDATE_HOSP_INFO(editingItem?.id), {
          data: {
            hospital_id: editingItem?.id,
            hospital_type_id:
              value.hospital_type[1] || value.hospital_type[0] || null,
            area_id: value.area[0] || null,
            province_id: value.province[0] || null,
            city_id: value.city[0] || null,
            county_id: value.county[0] || null,
            address: value.address || null,
          },
          method: "PUT",
        }),
      )
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="更新成功" />,
          });
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="更新失败" />,
          });
        });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法提交" />,
      });
  };

  const provinceClear = () => {
    setHosProvince(null);
    form.setFieldsValue({
      province: [],
    });
  };
  const cityClear = () => {
    setHosCity(null);
    form.setFieldsValue({
      city: [],
    });
  };
  const countyClear = () => {
    form.setFieldsValue({
      county: [],
    });
  };

  const updateProvinceList = (area_id: number | undefined) => {
    if (area_id) {
      let provinceListTMP: HosNodesType[] = [];
      hosNodes?.forEach((item) => {
        switch (item.nodeLevel) {
          case 2:
            if (item.parentNodeId === (area_id || 0)) {
              provinceListTMP?.push(item);
            }
            break;
        }
      });
      setProvinceList(provinceListTMP);
    }
  };

  useEffect(() => {
    let firTypeList: HosType[] = [];
    let secTypeList: HosType[] = [];
    hosTypeList?.forEach((item) => {
      if (item.parent_id === null) {
        firTypeList.push(item);
      }
      if (hosFirTypeID && item.parent_id === hosFirTypeID) {
        secTypeList.push(item);
      }
    });
    setHosFirTypeList(firTypeList);
    setHosSecTypeList(secTypeList);
  }, [hosFirTypeID, hosTypeList]);

  useEffect(() => {
    if (hosTypeList && editingItem) {
      let hosSecType = hosTypeList?.find(
        (i) => i.parent_id && i.id === editingItem?.hospital_type_id,
      );
      let hosFirTypeID =
        hosSecType?.parent_id || editingItem?.hospital_type_id || null;
      setHosFirTypeID(hosFirTypeID);
      form.setFieldsValue({
        hospital_type: [
          hosFirTypeID?.toString() as PickerValue,
          hosSecType?.id.toString() as PickerValue,
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hosTypeList, editingItem]);

  useEffect(() => {
    getCountyListFunc(hosCity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hosCity]);

  useEffect(() => {
    getCityListFunc(hosProvince);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hosProvince]);

  useEffect(() => {
    if (hosArea) {
      let provinceListTMP: HosNodesType[] = [];
      hosNodes?.forEach((item) => {
        switch (item.nodeLevel) {
          case 2:
            if (item.parentNodeId === (hosArea || 0)) {
              provinceListTMP?.push(item);
            }
            break;
        }
      });
      setProvinceList(provinceListTMP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hosArea]);

  useEffect(() => {
    if (areaList) {
      updateProvinceList(editingItem?.area_id);
      getCityListFunc(editingItem?.province_id);
      getCountyListFunc(editingItem?.city_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaList]);

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      switch (item.nodeLevel) {
        case 1:
          areaListTMP?.push(item);
          break;
      }
    });
    setAreaList(areaListTMP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hosNodes]);

  useEffect(() => {
    if (visible && editingItem) {
      getHosNodesFunc();
      getHosTypeListFunc();
      form.setFieldsValue({
        // ...editingItem,
        area: ConvertToPickerValueList(editingItem.area_id),
        province: ConvertToPickerValueList(editingItem.province_id),
        city: ConvertToPickerValueList(editingItem.city_id),
        county: ConvertToPickerValueList(editingItem.county_id),
        address: editingItem?.address || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, editingItem]);

  return (
    <EditPopup
      visible={visible}
      submitting={submitting}
      setVisible={setVisible}
    >
      <List mode="card" header={"客户主体"}>
        <List.Item extra={editingItem?.name || "未知"}>客户名称</List.Item>
        <List.Item extra={editingItem?.code || "未知"}>客户编码</List.Item>
      </List>
      <Form
        form={form}
        onFinish={submitHandle}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={submitting}
          >
            提交
          </Button>
        }
      >
        <Form.Header>编辑信息</Form.Header>
        <Form.Item
          name="hospital_type"
          label="客户类型"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择客户类型" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingHosNodes}
            columns={[
              hosFirTypeList.map((i) => ({
                label: i.name,
                value: i.id.toString(),
              })),
              hosSecTypeList.map((i) => ({
                label: i.name,
                value: i.id.toString(),
              })),
            ]}
            onSelect={(v) => {
              let firstID = v[0];
              let secTypeList: HosType[] = [];
              hosTypeList?.forEach((item) => {
                if (item.parent_id?.toString() === firstID) {
                  secTypeList.push(item);
                }
              });
              setHosSecTypeList(secTypeList);
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>
                  {items[0]?.label +
                    `${items[1] ? " (" + items[1].label + ")" : ""}`}
                </FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择区域</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="area"
          label="区域"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择区域" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingHosTypeList}
            columns={[
              areaList.map((i) => ({
                label: i.nodeName,
                value: i.nodeId.toString(),
              })),
            ]}
            onConfirm={(v) => {
              provinceClear();
              cityClear();
              setCityList([]);
              countyClear();
              setHosArea(v[0] ? parseInt(v[0] as string) : null);
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择区域</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="province"
          label="省份"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择省份" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingHosTypeList}
            columns={[
              provinceList.map((i) => ({
                label: i.nodeName,
                value: i.nodeId.toString(),
              })),
            ]}
            onConfirm={(v) => {
              cityClear();
              countyClear();
              setHosProvince(v[0] ? parseInt(v[0] as string) : null);
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择省份</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="city"
          label="城市"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择城市" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingCityList}
            columns={
              cityList
                ? [
                    cityList.map((i) => ({
                      label: i.cityName,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
            onConfirm={(v) => {
              countyClear();
              setHosCity(v[0] ? parseInt(v[0] as string) : null);
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择城市</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="county"
          label="市/区/县"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingCountyList}
            columns={
              countyList
                ? [
                    countyList.map((i) => ({
                      label: i.county_name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择市/区/县</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="address"
          label="客户地址"
          help="客户详情地址"
          normalize={(v) => DisposeMobileText(v)}
          layout={"vertical"}
        >
          <TextArea autoSize placeholder="输入地址客户地址" />
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
