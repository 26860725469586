import { IdType } from ".";
import { TicketPrefix } from "./prefix";

export const GET_CHECKLIST = `${TicketPrefix}/checklist/checklist`;
export const ADD_CHECKLIST = `${TicketPrefix}/checklist/checklist`;
export const UPDATE_CHECKLIST = (id: IdType) =>
  `${TicketPrefix}/checklist/checklist/${id}`;
export const GET_CHECKLIST_DETAIL = (id: IdType) =>
  `${TicketPrefix}/checklist/checklist/${id}`;

export const SHARE_CHECKLIST = `${TicketPrefix}/checklist/share_checklist`;
