import { useState, useRef, useEffect } from "react";
import type { BaseSelectRef } from "rc-select";
import { Form, Select, Spin } from "antd";

import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { BREAK_DETAIL_TYPE } from "lib/interface/breaklist";

import "./index.css";

export const UpdateSelect = ({
  onUpdate,
  defaultValue,
  defaultId,
  label,
  name,
  options,
  required,
  api,
  isCanChange,
  preDependency,
  preData,
  enableDependency,
}: {
  onUpdate: (value: any) => void;
  defaultValue?: string;
  defaultId?: number;
  label: string;
  name: string;
  options: { label: string; value: string };
  required?: boolean;
  api: string;
  isCanChange?: boolean;
  preDependency?: {
    type: string;
    label: string;
    value: number | string | undefined;
  };
  preData?: BREAK_DETAIL_TYPE | null;
  enableDependency?: boolean;
}) => {
  const [form] = Form.useForm();

  const client = useHttp();

  const [isEditting, setIsEditting] = useState(false);
  const [isFoucs, setIsFoucs] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const ref = useRef<BaseSelectRef>(null);

  const onFinish = (value: any) => {
    if (value.issue_type_id) {
      value = {
        ...value,
        issue_reason_id: null,
        issue_reason_name: null,
      };
      form.setFieldValue("issues_reason_id", undefined);
    }
    onUpdate(value);
    ref.current?.blur();
  };

  const { run, data, isLoading, isIdle } = useAsync<any>();
  const runHandle = () => {
    if (preDependency?.value) {
      api = `${api}?${preDependency.type}=${preDependency.value}`;
    }
    run(client(api)).then(() => {
      if (defaultId) {
        form.setFieldsValue({
          [name]: defaultId,
        });
      } else {
        form.setFieldsValue({
          [name]: undefined,
        });
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      [name]: defaultValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (enableDependency) {
      if (preDependency?.value) {
        runHandle();
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [enableDependency, preDependency?.value]);

  const loading = isLoading || isIdle;
  return (
    <Form
      form={form}
      className="update-select-form"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label={label}
        name={name}
        rules={[{ required: required, message: `必须选择${label}` }]}
        style={{ width: "100%" }}
      >
        {isCanChange ? (
          <Select
            ref={ref}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            placeholder={
              disabled ? `请先选择${preDependency?.label}!` : `选择${label}`
            }
            disabled={disabled}
            bordered={isEditting}
            suffixIcon={isEditting ? undefined : null}
            onChange={form.submit}
            onFocus={() => setIsFoucs(true)}
            onBlur={() => {
              setIsFoucs(false);
              setIsEditting(false);
            }}
            onMouseEnter={() => {
              setIsEditting(true);
            }}
            onMouseLeave={() => {
              if (isFoucs) return;
              setIsEditting(false);
            }}
            options={data?.map((i: any) => ({
              label: i[options.label],
              value: i[options.value],
            }))}
            onClick={() => {
              if (!data) {
                runHandle();
              }
            }}
            dropdownRender={(originNode) => (
              <Spin spinning={loading}>{originNode}</Spin>
            )}
          />
        ) : (
          defaultValue
        )}
      </Form.Item>
    </Form>
  );
};
