import { Toast, List, Tag, Grid } from "antd-mobile";
import { useDebounceFn } from "ahooks";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { GET_TARGET_UP_INFO } from "lib/api";
import { useMount } from "utils";
// RemoteMonitorInfoType
import { RemoteMonitorInfoType } from "authenticated-app/pc/customer/customer-detail/tab-pane/remoteTabPane/interface";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";

export const MobileRemoteMonitorInfo = ({ hospCode }: { hospCode: string }) => {
  const client = useHttp();
  const formatTimestamp = (timestamp: any) => {
    if (!timestamp) return "";
    if (typeof timestamp === "string") {
      timestamp = Number(timestamp);
    }
    const date = new Date(timestamp * 1000); // 将秒转换为毫秒
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 获取月份并补零
    const day = String(date.getDate()).padStart(2, "0"); // 获取日期并补零
    const hours = String(date.getHours()).padStart(2, "0"); // 获取小时并补零
    const minutes = String(date.getMinutes()).padStart(2, "0"); // 获取分钟并补零
    const seconds = String(date.getSeconds()).padStart(2, "0"); // 获取秒并补零

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // 返回格式化后的字符串
  };
  const {
    run: getMonitorInfo,
    isLoading,
    data,
    isIdle,
  } = useAsync<RemoteMonitorInfoType>();
  const getRemoteDetailHandle = () => {
    if (hospCode) {
      getMonitorInfo(
        client(GET_TARGET_UP_INFO, {
          data: {
            bgid: -1,
            crm_code: hospCode,
          },
        }),
      );
    } else {
      Toast.show({
        icon: "fail",
        content: <ToastContent content="获取失败" />,
      });
    }
  };
  const { run } = useDebounceFn(getRemoteDetailHandle, { wait: 200 });

  useMount(run);

  return (
    <div>
      <ContentCard>
        <List mode="card" header={<ListHeader text="远程监控信息" />}>
          {data?.dat?.list?.length === 0 ? (
            <span style={{ marginLeft: "12px", color: "red" }}>未接入监控</span>
          ) : (
            <>
              {data?.dat?.list?.map((item, index) => (
                <List.Item
                  key={index}
                  description={
                    <Grid columns={2}>
                      <Grid.Item>
                        <div>
                          <span>{"服务器IP:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {item?.server_ip}
                          </span>
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <div>
                          <span>{"远程IP:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {item?.remote_ip || "未知"}
                          </span>
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <div>
                          <span>{"维保编码:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {item?.server_code || "未知"}
                          </span>
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <div>
                          <span>{"告警数量:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {item?.alert_current_count ? (
                              <Tag color="danger">
                                {item?.alert_current_count}
                              </Tag>
                            ) : (
                              "0"
                            )}
                          </span>
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <div>
                          <span>{"创建时间:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {formatTimestamp(item?.created_at)}
                          </span>
                        </div>
                      </Grid.Item>
                      <Grid.Item>
                        <div>
                          <span>{"更新时间:"}</span>
                          <span style={{ marginLeft: "8px", color: "black" }}>
                            {formatTimestamp(item?.update_at)}
                          </span>
                        </div>
                      </Grid.Item>
                    </Grid>
                  }
                >
                  <div>
                    {item?.status ? (
                      <span style={{ color: "green", fontSize: "14px" }}>
                        {" "}
                        在线
                      </span>
                    ) : (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        离线
                      </span>
                    )}
                  </div>
                </List.Item>
              ))}
            </>
          )}
        </List>
      </ContentCard>
    </div>
  );
};

export default MobileRemoteMonitorInfo;
