import { Dispatch, SetStateAction, useEffect } from "react";
import { Button, Modal, Space, Form, Input, message } from "antd";

import { useHttp } from "utils/http";
import { GPUInfo } from "../interface";
import { DisposeForm } from "utils";

export const GPUSettingEdit = ({
  settingInfo,
  submitAPI,
  visible,
  setVisible,
  reload,
}: {
  settingInfo: GPUInfo | undefined;
  submitAPI: (org_id: number) => string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const submit = (value: any) => {
    let id = settingInfo?.id;
    if (id)
      client(submitAPI(id), {
        data: {
          ...value,
        },
        method: "PUT",
      })
        .then(() => {
          message.success("修改成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    else message.error("无法请求修改，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    form.setFieldsValue({
      ...settingInfo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingInfo]);

  return (
    <Modal
      title="修改显卡型号"
      destroyOnClose
      open={visible}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="gpu_oem_name"
          label="品牌"
          rules={[{ required: true, message: "必须输入品牌" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input maxLength={50} />
        </Form.Item>
        <Form.Item
          name="gpu_model_name"
          label="型号"
          rules={[{ required: true, message: "必须输入型号" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input maxLength={50} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
