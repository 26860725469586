import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Divider } from "antd";
import {
  ProTable,
  ActionType,
  FormInstance,
  ProColumns,
} from "@ant-design/pro-components";
import { useHttp } from "utils/http";
import {
  GET_DATA_RECEIVE_FILTER_INFO,
  GET_DEVICE_DATA_RECEIVE_INFO,
} from "lib/api";
import {
  DataReceiveInfoType,
  DeviceReceiveInfoList,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/dockingTabPane/interface";

interface ReceiveStatsTableProps {
  hospID: number | string;
}
export const ReceiveStatsTable: React.FC<ReceiveStatsTableProps> = ({
  hospID,
}) => {
  const client = useHttp();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const now_date = new Date(Date.now());
  const end_date = now_date.toISOString().split("T")[0];

  const threeDaysAgo = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000);
  const start_date = threeDaysAgo.toISOString().split("T")[0];

  const receive_columns: ProColumns<DataReceiveInfoType>[] = [
    {
      title: "日期",
      dataIndex: "statics_date",
      key: "statics_date",
      responsive: ["xs", "sm", "md"],
    },
    {
      title: "处理量",
      dataIndex: "total",
      key: "total",
      responsive: ["xs", "sm", "md"],
    },
    {
      title: "过滤量",
      dataIndex: "filter_num",
      key: "filter_num",
      responsive: ["xs", "sm", "md"],
    },
  ];

  const device_columns: ProColumns<DeviceReceiveInfoList>[] = [
    {
      title: "日期",
      dataIndex: "statics_date",
      key: "statics_date",
      responsive: ["xs", "sm", "md"],
      width: 90,
    },
    {
      title: "设备数",
      dataIndex: "device_total",
      key: "device_total",
      responsive: ["xs", "sm", "md"],
      width: 60,
    },
    {
      title: "接收数",
      dataIndex: "total",
      key: "total",
      responsive: ["xs", "sm", "md"],
      width: 60,
    },
    {
      title: "详情",
      dataIndex: "data",
      key: "data",
      ellipsis: true,
      responsive: ["xs", "sm", "md"],
      render: (
        dom: React.ReactNode,
        entity: DeviceReceiveInfoList,
        index: number,
      ) => {
        const stationData = entity.data;
        const formattedData = stationData
          .map((item) => `${item.ae_title}:${item.station_name}(${item.count})`)
          .join(", ");
        return (
          <span style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
            {formattedData}
          </span>
        );
      },
    },
  ];

  const search = async (): Promise<DataReceiveInfoType[]> => {
    const url = GET_DATA_RECEIVE_FILTER_INFO(hospID);
    const response = await client(url, {
      method: "GET",
      data: {
        start_date: start_date,
        end_date: end_date,
      },
    });
    return response.results || [];
  };
  const request = async (params: any) => {
    try {
      const data = await search();
      return {
        data: data || [],
        success: true,
      };
    } catch (e) {
      console.error("请求数据失败:", e);
      return { success: false, data: [] };
    }
  };

  const device_search = async (): Promise<DeviceReceiveInfoList[]> => {
    if (hospID) {
      const url = GET_DEVICE_DATA_RECEIVE_INFO(hospID);
      const response = await client(url, {
        method: "GET",
        data: {
          start_date: start_date,
          end_date: end_date,
        },
      });
      return response.results;
    }
    return [];
  };

  const device_request = async (params: any) => {
    try {
      const data = await device_search();
      return {
        data: data || [],
        success: true,
      };
    } catch (e) {
      console.error("请求数据失败:", e);
      return { success: false, data: [] };
    }
  };

  useEffect(() => {
    actionRef.current?.reload();
  }, [hospID]);

  return (
    <>
      <Divider style={{ marginTop: "4px", marginBottom: "5px" }} />
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <ProTable<DataReceiveInfoType>
            rowKey="hospital_id"
            actionRef={actionRef}
            formRef={formRef}
            cardBordered
            columns={receive_columns}
            request={request}
            search={false}
            options={{
              reload: false,
              density: false,
              setting: false,
            }}
            defaultSize={"small"}
            pagination={false}
            headerTitle="数据接收统计"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <ProTable<DeviceReceiveInfoList>
            rowKey="hospital_id"
            actionRef={actionRef}
            formRef={formRef}
            cardBordered
            columns={device_columns}
            request={device_request}
            search={false}
            options={{
              reload: false,
              density: false,
              setting: false,
            }}
            defaultSize={"small"}
            pagination={false}
            headerTitle="设备接收统计"
          />
        </Col>
      </Row>
    </>
  );
};
