// 硬件设置
export const GET_EQPT_TYPE = "/rommanAPI/service_record/eqpt_type";
export const ADD_EQPT_TYPE = "/rommanAPI/service_record/eqpt_type";
export const UPDATE_EQPT_TYPE = (org_id: number) =>
  `/rommanAPI/service_record/eqpt_type/${org_id}`;
export const DEL_EQPT_TYPE = (org_id: number) =>
  `/rommanAPI/service_record/eqpt_type/${org_id}`;

export const GET_EQPT = "/rommanAPI/service_record/eqpt/type";

export const GET_OEM_LIST = (org_id: number) =>
  `/rommanAPI/service_record/eqpt/oem/${org_id}`;

// 显卡设置
export const GET_GPU_LIST = "/rommanAPI/service_record/gpu_type_oem";
export const GET_GPU_OEM = "/rommanAPI/service_record/gpu_oem";
export const GET_GPU_TYPE = (org_id: number) =>
  `/rommanAPI/service_record/gpu_type/${org_id}`;
export const ADD_GPU = "/rommanAPI/service_record/gpu_type_oem";
export const UPDATE_GPU = (org_id: number) =>
  `/rommanAPI/service_record/gpu_type_oem/${org_id}`;
export const DEL_GPU = (org_id: number) =>
  `/rommanAPI/service_record/gpu_type_oem/${org_id}`;

// 产品设置
export const GET_PRODUCT_TYPE = "/rommanAPI/product/products";
export const ADD_PRODUCT_TYPE = "/rommanAPI/product/products";
export const UPDATE_PRODUCT_TYPE = (org_id: number) =>
  `/rommanAPI/product/products/${org_id}`;
export const DEL_PRODUCT_TYPE = (org_id: number) =>
  `/rommanAPI/product/products/${org_id}`;

// 医院设备类型设置
export const GET_HOS_EQPT = "/rommanAPI/service_record/hos_eqpt_type";
export const ADD_HOS_EQPT = "/rommanAPI/service_record/hos_eqpt_type";
export const UPDATE_HOS_EQPT = (org_id: number) =>
  `/rommanAPI/service_record/hos_eqpt_type/${org_id}`;
export const DEL_HOS_EQPT = (org_id: number) =>
  `/rommanAPI/service_record/hos_eqpt_type/${org_id}`;

// 外部人员类型设置
export const GET_USER_TYPE = "/rommanAPI/hos_user/user_type";
export const ADD_USER_TYPE = "/rommanAPI/hos_user/user_type";
export const UPDATE_USER_TYPE = (org_id: number) =>
  `/rommanAPI/hos_user/user_type/${org_id}`;
export const DEL_USER_TYPE = (org_id: number) =>
  `/rommanAPI/hos_user/user_type/${org_id}`;

// 外部人员联系方式设置
export const GET_USER_CONTACT = "/rommanAPI/hos_user/contact";
export const ADD_USER_CONTACT = "/rommanAPI/hos_user/contact";
export const UPDATE_USER_CONTACT = (org_id: number) =>
  `/rommanAPI/hos_user/contact/${org_id}`;
export const DEL_USER_CONTACT = (org_id: number) =>
  `/rommanAPI/hos_user/contact/${org_id}`;

// 产品发布版本设置
export const GET_PRODUCT_VERSION =
  "/rommanAPI/product/product_version_relation";
export const GET_PRODUCT_BRANCH = (org_id: string | null) =>
  `/rommanAPI/product/version${org_id ? `?product_id=${org_id}` : ""}`;
export const ADD_PRODUCT_VERSION =
  "/rommanAPI/product/product_version_relation";
export const UPDATE_PRODUCT_VERSION = (org_id: number) =>
  `/rommanAPI/product/product_version_relation/${org_id}`;
export const DEL_PRODUCT_VERSION = (org_id: number) =>
  `/rommanAPI/product/product_version_relation/${org_id}`;

// 系统版本设置
export const GET_SYSTEM_VERSION = "/rommanAPI/service_record/system_version";
export const ADD_SYSTEM_VERSION = "/rommanAPI/service_record/system_version";
export const UPDATE_SYSTEM_VERSION = (org_id: number) =>
  `/rommanAPI/service_record/system_version/${org_id}`;
export const DEL_SYSTEM_VERSION = (org_id: number) =>
  `/rommanAPI/service_record/system_version/${org_id}`;

// 产品客户端版本设置
export const GET_PRODUCT_CLIENT_VERSION_LIST = (org_id: string) =>
  `/rommanAPI/product/product_client_version_relation?product_id=${org_id}`;
export const GET_PRODUCT_CLIENT =
  "/rommanAPI/product/product_client_version_relation";
export const ADD_PRODUCT_CLIENT =
  "/rommanAPI/product/product_client_version_relation";
export const UPDATE_PRODUCT_CLIENT = (org_id: number) =>
  `/rommanAPI/product/product_client_version_relation/${org_id}`;
export const DEL_PRODUCT_CLIENT = (org_id: number) =>
  `/rommanAPI/product/product_client_version_relation/${org_id}`;

// 内部人员类型设置
export const GET_EMPLOYEE_TYPE = "/csmAPI/api/user/user_type";
export const ADD_EMPLOYEE_TYPE = "/csmAPI/api/user/user_type";
export const UPDATE_EMPLOYEE_TYPE = (org_id: number) =>
  `/csmAPI/api/user/user_type/${org_id}`;
export const DEL_EMPLOYEE_TYPE = (org_id: number) =>
  `/csmAPI/api/user/user_type/${org_id}`;

// 部署方式设置
export const GET_DEPLOYE_TYPE = "/rommanAPI/product/deploy_type";
export const ADD_DEPLOYE_TYPE = "/rommanAPI/product/deploy_type";
export const UPDATE_DEPLOYE_TYPE = (org_id: number) =>
  `/rommanAPI/product/deploy_type/${org_id}`;
export const DEL_DEPLOYE_TYPE = (org_id: number) =>
  `/rommanAPI/product/deploy_type/${org_id}`;

// 服务类型设置
export const GET_SERVICE_TYPE = "/rommanAPI/product/service_type";
export const ADD_SERVICE_TYPE = "/rommanAPI/product/service_type";
export const UPDATE_SERVICE_TYPE = (org_id: number) =>
  `/rommanAPI/product/service_type/${org_id}`;
export const DEL_SERVICE_TYPE = (org_id: number) =>
  `/rommanAPI/product/service_type/${org_id}`;

// 使用状态设置
export const GET_USING_STATUS = "/rommanAPI/product/using_status";
export const ADD_USING_STATUS = "/rommanAPI/product/using_status";
export const UPDATE_USING_STATUS = (org_id: number) =>
  `/rommanAPI/product/using_status/${org_id}`;
export const DEL_USING_STATUS = (org_id: number) =>
  `/rommanAPI/product/using_status/${org_id}`;

// 服务等级设置
export const GET_SERVICE_LEVEL = "/rommanAPI/product/service_level";
export const ADD_SERVICE_LEVEL = "/rommanAPI/product/service_level";
export const UPDATE_SERVICE_LEVEL = (org_id: number) =>
  `/rommanAPI/product/service_level/${org_id}`;
export const DEL_SERVICE_LEVEL = (org_id: number) =>
  `/rommanAPI/product/service_level/${org_id}`;

// 使用科室设置
export const GET_DEPARTMENT_TYPE = "/csmAPI/api/departments/department_type";
export const ADD_DEPARTMENT_TYPE = "/csmAPI/api/departments/department_type";
export const UPDATE_DEPARTMENT_TYPE = (org_id: number) =>
  `/csmAPI/api/departments/department_type/${org_id}`;
export const DEL_DEPARTMENT_TYPE = (org_id: number) =>
  `/csmAPI/api/departments/department_type/${org_id}`;

// 小插件版本设置
export const GET_PLUG_TYPE = "/rommanAPI/client_info/version";
export const ADD_PLUG_TYPE = "/rommanAPI/client_info/version";
export const UPDATE_PLUG_TYPE = (org_id: number) =>
  `/rommanAPI/client_info/version/${org_id}`;
export const DEL_PLUG_TYPE = (org_id: number) =>
  `/rommanAPI/client_info/version/${org_id}`;

// 小插件识别方式设置
export const GET_IDENTIFY_WAY = "/rommanAPI/client_info/identify_way";
export const ADD_IDENTIFY_WAY = "/rommanAPI/client_info/identify_way";
export const UPDATE_IDENTIFY_WAY = (org_id: number) =>
  `/rommanAPI/client_info/identify_way/${org_id}`;
export const DEL_IDENTIFY_WAY = (org_id: number) =>
  `/rommanAPI/client_info/identify_way/${org_id}`;

// CPU类型设置
export const GET_CPU_TYPE = "/rommanAPI/client_info/cpu_type";
export const ADD_CPU_TYPE = "/rommanAPI/client_info/cpu_type";
export const UPDATE_CPU_TYPE = (org_id: number) =>
  `/rommanAPI/client_info/cpu_type/${org_id}`;
export const DEL_CPU_TYPE = (org_id: number) =>
  `/rommanAPI/client_info/cpu_type/${org_id}`;

// 客户端类型（使用类型）设置
export const GET_CLIENT_TYPE = "/rommanAPI/client_info/type";
export const ADD_CLIENT_TYPE = "/rommanAPI/client_info/type";
export const UPDATE_CLIENT_TYPE = (org_id: number) =>
  `/rommanAPI/client_info/type/${org_id}`;
export const DEL_CLIENT_TYPE = (org_id: number) =>
  `/rommanAPI/client_info/type/${org_id}`;
