import { Descriptions } from "antd";

import { DELIVERY_DETAIL_TYPE } from "lib/interface/launchChangeList";

const { Item } = Descriptions;

export const DeliveryTransOrderDetailInfo = ({
  onUpdate,
  detailInfo,
  isFinished,
  isHasPermission,
}: {
  onUpdate: (value: any) => void;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
  isFinished: boolean;
  isHasPermission: boolean;
}) => {
  return (
    <>
      <Descriptions
        className="break-detail-desc-title"
        size="small"
        title="流转信息"
        column={2}
      >
        <Item label={"暂停原因分类"}>
          {" "}
          {detailInfo?.pause_reason_type_name}{" "}
        </Item>
        <Item label={"暂停原因"}> {detailInfo?.pause_reason} </Item>
        <Item label={"重新排期原因分类"}>
          {" "}
          {detailInfo?.rescheduling_reason_type_name}{" "}
        </Item>
        <Item label={"重新排期原因"}> {detailInfo?.rescheduling_reason} </Item>
      </Descriptions>
      <Descriptions className="break-detail-desc-title" size="small" column={2}>
        <Item label={"终止原因"}> {detailInfo?.termination_reason} </Item>
        <Item label={"转派原因"}> {detailInfo?.transfer_reason}</Item>
      </Descriptions>
    </>
  );
};
