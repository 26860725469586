import { MutableRefObject, useEffect, useRef, useState } from "react";
import { ActionType, PageHeader } from "@ant-design/pro-components";
import {
  Divider,
  Form,
  Modal,
  Spin,
  Descriptions,
  Space,
  Button,
  Tooltip,
} from "antd";
import { useDebounceFn } from "ahooks";
import { UpdateBoolSelect } from "authenticated-app/pc/components/UpdateBoolSelect";
import { UpdateDateSelect } from "authenticated-app/pc/components/UpdateDateSelect";
import { UpdateMultiSelect } from "authenticated-app/pc/components/UpdateMultipleSelect";
import { UpdateSelect } from "authenticated-app/pc/components/UpdateSelect";
import { UpdateInputNoTip } from "authenticated-app/pc/components/UpdateInputNoTip";
import { useAuth } from "context/auth";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
// import { useBreaklistDetail } from "authenticated-app/hooks/breaklist/useBreaklistDetail";
import { useVisitListDetail } from "authenticated-app/hooks/visitlist/useVisitListDetail";
import { VISIT_DETAIL_LIST } from "lib/interface/visitlist";
import {
  GET_VISIT_DETAIL,
  GET_EVENT_TYPE,
  GET_VISIT_TYPE,
  UPDATE_VISIT_DETAIL,
  GET_BUSINESS_TYPE,
} from "lib/api/issues";
import { GET_PRODUCT_DEPARTMENT } from "lib/api";
import { EditQuill } from "authenticated-app/pc/components/EditQuill";
import { EditUpload } from "authenticated-app/pc/components/EditUpload";

import "./index.css";
import { UserOutlined, FieldTimeOutlined } from "@ant-design/icons";

export const VisitDetailModal = ({
  actionRef,
}: {
  actionRef: MutableRefObject<ActionType | undefined>;
}) => {
  const { user } = useAuth();

  const [isChanged, setIsChanged] = useState(false);

  const [isOpen, , close] = useVisitListDetail();

  const [{ detailId }] = useUrlQueryParam(["detailId"]);

  const divRef = useRef<HTMLDivElement>(null);
  // const scroll = useScroll(divRef);

  const [form] = Form.useForm();
  const client = useHttp();
  const { Item } = Descriptions;
  const {
    run: getDetailInfo,
    data: detailInfo,
    isIdle,
    isLoading,
    setData,
  } = useAsync<VISIT_DETAIL_LIST>();

  const getDetailInfoHandle = () => {
    getDetailInfo(client(GET_VISIT_DETAIL(detailId)));
  };
  const { run: getDetailInfoDebounce } = useDebounceFn(getDetailInfoHandle, {
    wait: 200,
  });

  const { run: update } = useAsync();
  const onUpdate = (value: any) => {
    if (detailInfo?.id) {
      update(
        client(UPDATE_VISIT_DETAIL(detailInfo?.id), {
          method: "PUT",
          data: {
            attachment_id_list: detailInfo.attachment_list?.map(
              (i) => i.attachment_id,
            ),
            ...value,
          },
        }),
      )
        .then((res) => {
          setIsChanged(true);
          setData(res);
        })
        .catch(() => getDetailInfoDebounce());
    }
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      setIsChanged(false);
      if (detailId) {
        getDetailInfoDebounce();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailId]);

  const Loading = isIdle || isLoading;
  const isFinished = !!!isIdle && detailInfo?.id === 6;
  const isHasPermission = user?.userName === detailInfo?.create_user;
  // user?.id === detailInfo?.issue_user_id;

  const isCanChange = !!!isFinished && isHasPermission;
  return (
    <Modal
      centered
      maskClosable={false}
      destroyOnClose
      open={isOpen}
      afterClose={() => {
        setData(null);
        if (isChanged) {
          actionRef.current?.reload();
        }
      }}
      onCancel={close}
      footer={null}
      width={"88vw"}
    >
      <Spin spinning={Loading} style={{ height: "100%" }}>
        <PageHeader
          className="page-header"
          title={
            <>
              <span style={{ fontWeight: "normal", marginRight: "16px" }}>
                {"拜访记录"}
              </span>
              {detailInfo?.hospital_name}
            </>
          }
          extra={
            <Space style={{ marginRight: 60 }}>
              <Tooltip title="创建人">
                <Button type="primary" shape="round" icon={<UserOutlined />}>
                  {detailInfo?.create_user}
                </Button>
              </Tooltip>
              <Tooltip title="更新时间">
                <Button
                  type="primary"
                  shape="round"
                  icon={<FieldTimeOutlined />}
                >
                  {detailInfo?.update_time}
                </Button>
              </Tooltip>
            </Space>
          }
        />
        <Divider style={{ margin: "12px 0" }} />

        <div ref={divRef} className="break-detail-div">
          <div style={{ flex: 3, height: "100%" }}>
            <Descriptions
              className="break-base-desc-title"
              size="small"
              column={3}
            >
              <Item label={"涉及产品线"}>
                {detailInfo?.product_list
                  .map((item) => item.chinese_name)
                  .join("、")}
              </Item>
              <Item>
                <UpdateMultiSelect
                  onUpdate={onUpdate}
                  defaultValue={detailInfo?.department_type_list}
                  defaultIds={detailInfo?.department_type_list.map(
                    (item) => item.id,
                  )}
                  label={"拜访科室"}
                  name={"department_type_id_list"}
                  options={{ value: "id", label: "name" }}
                  api={GET_PRODUCT_DEPARTMENT}
                  isCanChange={isCanChange}
                />
              </Item>
              <Item>
                <UpdateMultiSelect
                  onUpdate={onUpdate}
                  defaultValue={detailInfo?.visit_type_list}
                  defaultIds={detailInfo?.visit_type_list.map(
                    (item) => item.id,
                  )}
                  label={"拜访类别"}
                  name={"visit_type_id_list"}
                  options={{ value: "id", label: "name" }}
                  api={GET_VISIT_TYPE}
                  isCanChange={isCanChange}
                />
              </Item>
              <Item>
                <UpdateDateSelect
                  value={detailInfo?.visit_date}
                  onUpdate={onUpdate}
                  label={"拜访日期"}
                  name={"visit_date"}
                  isCanChange={isCanChange}
                  showTime={false}
                  ishistory={true}
                />
              </Item>
              <Item>
                <UpdateBoolSelect
                  onUpdate={onUpdate}
                  value={detailInfo?.is_special_focus}
                  label={"特别关注"}
                  name={"is_special_focus"}
                  isCanChange={isCanChange}
                />
              </Item>
              <Item>
                <UpdateSelect
                  onUpdate={onUpdate}
                  defaultValue={detailInfo?.event_type_name}
                  defaultId={detailInfo?.event_type_id}
                  label={"重大事件类别"}
                  name={"event_type_id"}
                  options={{ value: "id", label: "name" }}
                  api={GET_EVENT_TYPE}
                  isCanChange={isCanChange}
                />
              </Item>
              <Item>
                <UpdateMultiSelect
                  onUpdate={onUpdate}
                  defaultValue={detailInfo?.business_opportunity_type_list}
                  defaultIds={detailInfo?.business_opportunity_type_list.map(
                    (item) => item.id,
                  )}
                  label={"商机类别"}
                  name={"business_opportunity_type_id_list"}
                  options={{ value: "id", label: "name" }}
                  api={GET_BUSINESS_TYPE}
                  isCanChange={isCanChange}
                />
              </Item>
              <Item>
                <UpdateInputNoTip
                  value={detailInfo?.of_num}
                  onUpdate={onUpdate}
                  label={"产品反馈OF记录"}
                  name={"of_num"}
                  isCanChange={isCanChange}
                />
              </Item>
              <Item label={"创建时间"}>{detailInfo?.create_time}</Item>
              <Item>
                <UpdateInputNoTip
                  value={detailInfo?.material_link}
                  onUpdate={onUpdate}
                  label={"运营素材链接"}
                  name={"material_link"}
                  isCanChange={isCanChange}
                />
              </Item>
            </Descriptions>
            <Divider style={{ margin: "12px 0" }} />
            <EditQuill
              onUpdate={onUpdate}
              name={"description"}
              title={"拜访记录"}
              info={detailInfo?.description || ""}
              isCanChange={isCanChange}
            />
            <EditQuill
              onUpdate={onUpdate}
              name={"event_desc"}
              title={"重大事件记录"}
              info={detailInfo?.event_desc || ""}
              isCanChange={isCanChange}
              relatedFiename={detailInfo?.event_type_name}
            />
            <EditQuill
              onUpdate={onUpdate}
              name={"business_opportunity_description"}
              title={"商机内容记录"}
              info={detailInfo?.business_opportunity_description || ""}
              isCanChange={isCanChange}
              relatedField={detailInfo?.business_opportunity_type_list}
            />
            <EditUpload
              onUpdate={onUpdate}
              name={"attachment_id_list"}
              title={"附件"}
              defaultValue={detailInfo?.attachment_list}
              isCanChange={isCanChange}
            />
            <br />
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
