import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Space, Spin, Tag } from "antd";
import type { ProColumns } from "@ant-design/pro-components";
import { ActionType, ProTable, FormInstance } from "@ant-design/pro-components";
import {
  PlusOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { DateTimeFormate, FormatDate, trimStr } from "utils";
import { useHttp } from "utils/http";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { HosNodesType } from "authenticated-app/pc/customer/customer-list/interface";
import { useAsync } from "utils/hooks/useAsync";
import { GET_HOSP_NODES, GET_PRODUCT_LIST } from "lib/api";
import { useBreaklistAdd } from "authenticated-app/hooks/breaklist/useBreaklistAdd";
import { AddModal } from "../add";
import { BREAKLIST_OPTION_TYPE, BREAK_TYPE } from "lib/interface/breaklist";
import {
  GET_BREAKLIST,
  GET_BREAKLIST_FILE,
  GET_ORDER_TYPE,
} from "lib/api/issues";
import { GET_LABEL_LIST } from "lib/api";
import { useBreaklistDetail } from "authenticated-app/hooks/breaklist/useBreaklistDetail";
import { BreakDetailModal } from "../detail";
import { ProductType } from "authenticated-app/pc/setting/customer-setting/interface";
import { useAuth } from "context/auth";
import { useDownload } from "utils/download";
import { useBreaklistSearch } from "authenticated-app/hooks/breaklist/useBreaklistSearch";
import { BreakSearchModal } from "../search";

const { CheckableTag } = Tag;

const getSeachParams = (params: number[] | string[]) => {
  return params?.map((i) => i).join("|");
};

export const BreaklistTable = () => {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<FormInstance>();
  const { height } = useWindowSize();
  const { user } = useAuth();
  const client = useHttp();
  const downloadClient = useDownload();
  const [, openBreaklistDetail] = useBreaklistDetail();
  const [, openBreaklistSearch] = useBreaklistSearch();
  const [breaklistAddOpening, openBreaklisttAdd] = useBreaklistAdd();
  const [selectedTag, setSelectedTag] = useState<string[]>(["全部工单"]);
  const [area, setArea] = useState<string[]>();
  const [areaListEnum, setAreaListEnum] = useState<{
    [key in number]: { text: string };
  }>({});
  const [provinceListEnum, setProvinceListEnum] = useState<{
    [key in number]: { text: string };
  }>({});
  const [searchParams, setSearchParams] = useState<any>();
  const [downloadParams, setDownloadParams] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const { run: getFile, isLoading: gettingFile } = useAsync();
  const getFileHandle = () => {
    getFile(
      downloadClient(GET_BREAKLIST_FILE, {
        data: { is_download: 1, ...downloadParams },
      }),
    );
  };

  const {
    run: getHosNodes,
    isLoading: gettingHosNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };

  const {
    run: getOrderTypeList,
    data: orderTypeList,
    isLoading: gettingOrderTypeList,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getOrderTypeListHandle = () => {
    getOrderTypeList(client(GET_ORDER_TYPE));
  };

  const {
    run: getOrderLabelList,
    data: orderLabelList,
    isLoading: gettingOrderLabelList,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getOrderLabelListHandle = () => {
    getOrderLabelList(client(GET_LABEL_LIST));
  };

  const {
    run: getProductList,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductType[]>();
  const getProductListHandle = () => {
    getProductList(client(GET_PRODUCT_LIST));
  };

  const getParams = (params: any) => {
    const { create_time } = params;
    let start_time = create_time?.[0]
      ? FormatDate(create_time?.[0])
      : undefined;
    let end_time = create_time?.[1] ? FormatDate(create_time?.[1]) : undefined;
    let queryParams = {
      ...params,
      order_id: params?.order_id ? trimStr(params?.order_id) : undefined,
      customer_name: params?.customer_name
        ? trimStr(params?.customer_name)
        : undefined,
      node_user_name: params?.node_user_name
        ? trimStr(params?.node_user_name)
        : undefined,
      issue_user_name: params?.issue_user_name
        ? trimStr(params?.issue_user_name)
        : undefined,
      create_user: params?.create_user
        ? trimStr(params?.create_user)
        : undefined,
      order_type_id_list: getSeachParams(params?.order_type_name),
      order_type_name: undefined,
      process_id_list: getSeachParams(params?.process_name),
      process_name: undefined,
      area_id_list: getSeachParams(params?.area_id_list),
      province_id_list: getSeachParams(params?.province_id_list),
      label_id_list: getSeachParams(params?.label_id_list),
      start_time,
      end_time,
      create_time: undefined,
    };
    return {
      ...params,
      ...queryParams,
    };
  };

  const search = (params: any) => {
    return client(GET_BREAKLIST, {
      data: { ...params, ...searchParams },
    });
  };

  const handleTagChange = async (tag: string) => {
    setPage(1);
    if (tag !== selectedTag?.[0]) {
      setSelectedTag([tag]);
      switch (tag) {
        case "全部工单":
          setSearchParams(undefined);
          break;
        case "未关单":
          setSearchParams({
            process_id_list: "1|2|3|4|5|7",
          });
          break;
        // case "负责中工单":
        //   setSearchParams({
        //     issue_user_id: user?.id,
        //     process_id_list: "1|2|3|4|5|7",
        //   });
        //   break;
        case "处理中工单":
          setSearchParams({
            node_user_id: user?.id,
            process_id_list: "1|2|3|4|5",
          });
          break;
        case "挂起中工单":
          setSearchParams({ node_user_id: user?.id, process_id_list: "7" });
          break;
        case "已创建工单":
          setSearchParams({ create_user: user?.userName });
          break;
        // case "已解决工单":
        //   setSearchParams({ issue_user_id: user?.id, process_id_list: "6" });
        //   break;
        case "升级SRD工单":
          setSearchParams({ has_srd: true });
          break;
        case "升级研发工单":
          setSearchParams({ has_dev: true });
          break;
        // case "我处理的工单":
        //   setSearchParams({ processed: true });
        //   break;
        case "关注工单":
          setSearchParams({
            node_user_id: user?.id,
            follow_user_id: user?.id,
          });
          break;
      }
    }
  };

  const BreaklistColumns: ProColumns<BREAK_TYPE>[] = [
    {
      title: "工单ID",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "工单类别",
      dataIndex: "order_type_name",
      key: "order_type_name",
      valueEnum: () => {
        let enums: { [key in number]: { text: string } } = {};
        orderTypeList?.forEach((i) => {
          enums[i.id] = { text: i.name };
        });
        return enums;
      },
      fieldProps: {
        loading: gettingOrderTypeList,
        showSearch: true,
        onClick: () => {
          if (!!!orderTypeList) {
            getOrderTypeListHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin key="search_order_type_name" spinning={gettingOrderTypeList}>
            {originNode}
          </Spin>
        ),
        mode: "multiple",
        maxTagCount: 3,
      },
    },
    {
      title: "工单状态",
      dataIndex: "process_name",
      key: "process_name",
      valueType: "select",
      valueEnum: {
        1: {
          text: "未分配",
        },
        2: {
          text: "现场处理中",
        },
        3: {
          text: "客服处理中",
        },
        4: {
          text: "SRD处理中",
        },
        5: {
          text: "研发处理中",
        },
        6: {
          text: "已解决",
        },
        7: {
          text: "挂起中",
        },
      },
      fieldProps: {
        mode: "multiple",
        maxTagCount: 3,
      },
    },
    {
      title: "客户名称",
      dataIndex: "customer_name",
      key: "customer_name",
      render: (_, record, __) => {
        return record.hospital_name || record.customer_name;
      },
    },
    {
      title: "节点负责人",
      dataIndex: "node_user_name",
      key: "node_user_name",
    },
    {
      title: "标签",
      dataIndex: "label_list",
      hideInSearch: true,
      key: "label_list",
      render: (_, record, __) => {
        return (
          <Space wrap>
            {record.label_list.map((i) => (
              <Tag style={{ marginRight: 0 }} key={`${i.id}_tag`} color="blue">
                {i.name}
              </Tag>
            ))}
          </Space>
        );
      },
    },
    {
      title: "标签",
      dataIndex: "label_id_list",
      hideInTable: true,
      // key: "label",
      valueEnum: () => {
        let enums: { [key in string]: { text: string } } = {};
        orderLabelList?.forEach((i) => {
          if (i.name) {
            enums[i.id] = { text: i.name };
          }
        });
        return enums;
      },
      fieldProps: {
        loading: gettingOrderTypeList,
        showSearch: true,
        onClick: () => {
          if (!!!orderLabelList) {
            getOrderLabelListHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin key="search_label" spinning={gettingOrderLabelList}>
            {originNode}
          </Spin>
        ),
        mode: "multiple",
        maxTagCount: 3,
      },
    },

    {
      title: "工单创建人",
      dataIndex: "create_user",
      key: "create_user",
    },
    {
      title: "标题",
      search: false,
      dataIndex: "title",
      key: "title",
    },
    {
      title: "产品线",
      hideInTable: true,
      dataIndex: "product_id",
      key: "product_id",
      valueEnum: () => {
        let enums: { [key in string]: { text: string } } = {};
        productList?.forEach((i) => {
          enums[i.title] = {
            text: i.product_name || `${i.title}(${i.chinese_name})`,
          };
        });
        return enums;
      },
      fieldProps: {
        loading: gettingProductList,
        showSearch: true,
        onClick: () => {
          if (!!!productList) {
            getProductListHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin spinning={gettingProductList}>{originNode}</Spin>
        ),
      },
    },
    {
      title: "区域",
      dataIndex: "area_id_list",
      key: "area_id_list",
      valueType: "select",
      valueEnum: areaListEnum,
      fieldProps: {
        loading: gettingHosNodes,
        onChange: (i: string[]) => {
          setArea(i);
          formRef.current?.setFieldsValue({
            province_name: undefined,
          });
        },
        onClick: () => {
          if (!!!hosNodes) {
            getHosNodesHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin spinning={gettingHosNodes}>{originNode}</Spin>
        ),
        mode: "multiple",
        maxTagCount: 4,
      },
      hideInTable: true,
    },
    {
      title: "省份",
      dataIndex: "province_id_list",
      key: "province_id_list",
      valueType: "select",
      valueEnum: provinceListEnum,
      fieldProps: {
        loading: gettingHosNodes,
        showSearch: true,
        mode: "multiple",
        maxTagCount: 3,
      },
      hideInTable: true,
    },
    {
      title: "创建时间",
      valueType: "dateRange",
      key: "create_time",
      hideInTable: true,
      dataIndex: "create_time", // start_time    end_time
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      key: "create_time",
      search: false,
      render: (_, record, __) => {
        return dayjs(record.create_time).format(DateTimeFormate);
      },
    },
    {
      title: "解决时长",
      dataIndex: "processing_time",
      key: "processing_time",
      search: false,
    },
  ];

  useEffect(() => {
    let enums: { [key in number]: { text: string } } = {};
    hosNodes?.forEach((i) => {
      if (i.nodeLevel === 1 && i.nodeId !== 5) {
        // 去除海外 nodeId 5
        enums[i.nodeId] = { text: i.nodeName || "未知" };
      }
    });
    setAreaListEnum(enums);
  }, [hosNodes]);

  useEffect(() => {
    let enums: { [key in number]: { text: string } } = {};
    hosNodes?.forEach((i) => {
      if (
        (area || []).includes(i.parentNodeId?.toString() || "-1") &&
        i.parentNodeId !== null
      ) {
        enums[i.nodeId] = { text: i.nodeName || "未知" };
      }
    });
    setProvinceListEnum(enums);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  useEffect(() => {
    actionRef.current?.reload();
    setDownloadParams(searchParams);
  }, [searchParams]);

  return (
    <Fragment>
      <ProTable<BREAK_TYPE>
        style={{
          minWidth: 988,
        }}
        rowKey="id"
        actionRef={actionRef}
        formRef={formRef}
        cardBordered
        columns={BreaklistColumns}
        request={async (params) => {
          try {
            let queryParams = getParams(params);
            const { current, pageSize, ...downloadParamsD } = queryParams;
            setDownloadParams({ ...downloadParamsD, ...searchParams });
            const res = await search(queryParams);
            return {
              data: res.results,
              total: res.count,
              success: true,
            };
          } catch (e) {
            return { success: false };
          }
        }}
        onReset={() => {
          setSearchParams(undefined);
          setSelectedTag(["全部工单"]);
        }}
        search={{
          labelWidth: "auto",
          searchText: "搜索",
          span: 6,
        }}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: false,
          pageSize:
            Math.trunc((height - 382) / 47) > 5
              ? Math.trunc((height - 382) / 47)
              : 5,
          current: page,
          onChange: (v) => {
            setPage(v);
          },
        }}
        onRow={(record) => ({
          onClick: () => openBreaklistDetail(record.id),
        })}
        headerTitle={
          <Space size={[0, 10]} wrap>
            {[
              "全部工单",
              "未关单",
              // "负责中工单",
              "处理中工单",
              "挂起中工单",
              "已创建工单",
              // "已解决工单",
              "升级SRD工单",
              "升级研发工单",
              "关注工单",
            ].map((tag) => (
              <CheckableTag
                key={tag}
                checked={selectedTag.includes(tag)}
                onChange={() => handleTagChange(tag)}
              >
                {tag}
              </CheckableTag>
            ))}
          </Space>
        }
        toolBarRender={() => [
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => {
              openBreaklistSearch();
            }}
          >
            工单检索
          </Button>,
          <Button
            key="add_break"
            loading={breaklistAddOpening}
            icon={<PlusOutlined />}
            type="primary"
            onClick={openBreaklisttAdd}
          >
            创建
          </Button>,
          <Button
            key="export_break"
            loading={gettingFile}
            icon={<DownloadOutlined />}
            onClick={getFileHandle}
          >
            导出
          </Button>,
        ]}
      />
      <BreakDetailModal actionRef={actionRef} />
      <AddModal actionRef={actionRef} />
      <BreakSearchModal />
    </Fragment>
  );
};
