import { List } from "antd-mobile";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { CheckValue } from "lib/interface/base";
import { CHECKLIST_VALUE_TYPE } from "lib/interface/checklist";

const getValue = (value: CheckValue) => {
  switch (value) {
    case 0:
      return <>未修复</>;
    case 1:
      return <>已修复</>;
    case 2:
      return <>不适用</>;
    default:
      return <>不适用</>;
  }
};

export const SharedAbnormalList = ({
  detail,
  index,
  loading,
}: {
  detail: CHECKLIST_VALUE_TYPE;
  index: number;
  loading: boolean;
}) => {
  const { product_name, product_version, problem_content, repair_mode, value } =
    detail || {};

  return (
    <>
      <List
        mode="card"
        header={<ListHeader text={`第${index}项`} loading={loading} />}
      >
        <List.Item extra={product_name || "无"}>产品组件</List.Item>
        <List.Item extra={product_version || "无"}>安装分支</List.Item>
        <List.Item extra={problem_content || "无"}>问题内容</List.Item>
        <List.Item extra={repair_mode || "无"}>修复方式</List.Item>
        <List.Item extra={getValue(value) || "无"}>修复结果</List.Item>
      </List>
    </>
  );
};
