import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Descriptions,
  Space,
  Input,
  Select,
  message,
  Form,
  Upload,
  UploadFile,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import {
  GET_EQPT_TYPE,
  GET_EQPT_OEM,
  UPDATE_HARDWARE_MATTER,
  GET_EQPT_MODEL,
  ADD_FILE,
} from "lib/api";
import { HardwareDesItemLabelMin, LineDiv } from "../../utils";

import {
  EqptModel,
  EqptOem,
  EqptType,
  HardwaresMatterInfos,
} from "../interface";
import { useAsync } from "utils/hooks/useAsync";
import {
  DisposeForm,
  handleUploadImage,
  initFileList,
  limitUploadImage,
} from "utils";
import { IpRegex } from "utils/regexp";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";

const { TextArea } = Input;
const { Option } = Select;

export const MatterInfoEdit = ({
  editingItem,
  editable,
  setEditable,
  setDisChange,
  reload,
}: {
  editingItem: HardwaresMatterInfos | undefined;
  editable: boolean;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setDisChange: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [eqptTypeID, setEqptTypeID] = useState<number | null>(null);
  const [eqptOemID, setEqptOemID] = useState<number | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const {
    run: getEqptTypeList,
    isLoading: gettingEqptTypeList,
    data: eqptTypeList,
  } = useAsync<EqptType[]>();
  const getEqptTypeListFunc = () => {
    getEqptTypeList(client(GET_EQPT_TYPE));
  };

  const {
    run: getEqptOemList,
    isLoading: gettingEqptOemList,
    data: eqptOemList,
  } = useAsync<EqptOem[]>();
  const getEqptOemListFunc = (eqpt_type_id: number) => {
    getEqptOemList(client(GET_EQPT_OEM(eqpt_type_id)));
  };

  const {
    run: getEqptModeList,
    isLoading: gettingEqptModeList,
    data: eqptModeList,
    setData: setEqptModel,
  } = useAsync<EqptModel[]>();
  const getEqptModeListFunc = (eqpt_oem_id: number) => {
    getEqptModeList(client(GET_EQPT_MODEL(eqpt_oem_id)));
  };

  const submit = (value: any) => {
    if (editingItem?.id)
      client(UPDATE_HARDWARE_MATTER(editingItem?.id), {
        data: {
          ...value,
          asset_photos: fileList.map((i) => i.uid),
        },
        method: "PUT",
      })
        .then(() => {
          message.success("修改成功！");
          setEditable(false);
          setDisChange(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    else {
      message.error("无法提交修改，请稍后重试或联系管理员！");
    }
  };

  const clearModel = () => {
    setEqptModel([]);
    form.setFieldsValue({
      eqpt_model_id: null,
    });
  };

  const clearOem = () => {
    setEqptOemID(null);
    form.setFieldsValue({
      eqpt_oem_id: null,
    });
  };

  useEffect(() => {
    if (editable) {
      getEqptTypeListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);

  useEffect(() => {
    if (editingItem) {
      setFileList(initFileList(editingItem?.asset_photos) || []);
      if (editingItem.eqpt_type_id) {
        setEqptTypeID(editingItem.eqpt_type_id);
      }
      if (editingItem.eqpt_oem_id) {
        setEqptOemID(editingItem.eqpt_oem_id);
      }
      form.setFieldsValue({
        eqpt_type_id: editingItem.eqpt_type_id,
        eqpt_oem_id: editingItem.eqpt_oem_id,
        eqpt_model_id: editingItem.eqpt_model_id,
        ip: editingItem.ip,
        maintenance_code: editingItem.maintenance_code,
        assets_code: editingItem.assets_code,
        description: editingItem.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  useEffect(() => {
    if (eqptTypeID) {
      getEqptOemListFunc(eqptTypeID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqptTypeID]);

  useEffect(() => {
    if (eqptOemID) {
      getEqptModeListFunc(eqptOemID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqptOemID]);

  return (
    <Form form={form} onFinish={submit}>
      <Descriptions column={2}>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"设备类型"} />}
        >
          <LineDiv>
            <Form.Item
              name="eqpt_type_id"
              rules={[{ required: true, message: "必须选择设备类型" }]}
            >
              <Select
                loading={gettingEqptTypeList}
                style={{ width: "256px" }}
                placeholder="选择设备类型"
                onChange={(v) => {
                  clearOem();
                  clearModel();
                  setEqptTypeID(v);
                }}
              >
                {eqptTypeList?.map((i, index) => (
                  <Option key={index} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<HardwareDesItemLabelMin label={"物料IP"} />}>
          <LineDiv>
            <Form.Item
              name="ip"
              normalize={(v) => DisposeForm(v)}
              rules={[
                {
                  pattern: IpRegex(),
                  message: "必须输入合法的IP格式",
                },
              ]}
            >
              <Input allowClear style={{ width: "256px" }} />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"设备品牌"} />}
        >
          <LineDiv>
            <Form.Item
              name="eqpt_oem_id"
              rules={[{ required: true, message: "必须选择设备品牌" }]}
            >
              <Select
                loading={gettingEqptOemList}
                style={{ width: "256px" }}
                placeholder="设备品牌"
                onChange={(v) => {
                  clearModel();
                  setEqptOemID(v);
                }}
              >
                {eqptOemList?.map((i, index) => (
                  <Option key={index} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"维保代码"} />}
        >
          <LineDiv>
            <Form.Item
              name="maintenance_code"
              normalize={(v) => DisposeForm(v)}
            >
              <Input allowClear style={{ width: "256px" }} />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"设备型号"} />}
        >
          <LineDiv>
            <Form.Item name="eqpt_model_id" normalize={(v) => DisposeForm(v)}>
              <Select
                allowClear
                loading={gettingEqptModeList}
                style={{ width: "256px" }}
                placeholder="选择设备型号"
              >
                {eqptModeList?.map((i, index) => (
                  <Option key={index} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"资产编码"} />}
        >
          <LineDiv>
            <Form.Item
              name="assets_code"
              normalize={(v) => DisposeForm(v)}
              rules={[{ required: true, message: "必须输入资产编码" }]}
            >
              <Input style={{ width: "256px" }} />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"应用描述"} />}
        >
          <LineDiv>
            <Form.Item name="description" normalize={(v) => DisposeForm(v)}>
              <TextArea
                allowClear
                style={{ width: "256px" }}
                autoSize={{ minRows: 4 }}
              />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"资产照片"} />}
        >
          <Form.Item name="asset_photos">
            <Upload
              headers={UploadHeaders}
              maxCount={3}
              multiple={true}
              action={ADD_FILE}
              listType="picture-card"
              fileList={fileList}
              beforeUpload={limitUploadImage}
              onChange={(info) => handleUploadImage(info, setFileList)}
            >
              {fileList?.length >= 3 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>上传(限3个)</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
      <Form.Item
        wrapperCol={{ offset: 20 }}
        style={{ marginTop: "2rem", marginBottom: "-1rem" }}
      >
        <Space>
          <Button
            htmlType="button"
            onClick={() => {
              setDisChange(false);
              setEditable(false);
            }}
          >
            取消
          </Button>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
