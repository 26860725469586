import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Spin, Empty } from "antd";
import type { ProColumns } from "@ant-design/pro-components";
import { ActionType, ProTable, FormInstance } from "@ant-design/pro-components";
import { PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { DateTimeFormate, FormatDate, trimStr } from "utils";
import { useHttp } from "utils/http";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { HosNodesType } from "authenticated-app/pc/customer/customer-list/interface";
import { useAsync } from "utils/hooks/useAsync";
import { GET_HOSP_NODES, GET_PRODUCT_LIST } from "lib/api";
import { AddModal } from "../add";
import {
  DELIVERY_ORDER_TYPE,
  DELIVERY_ORDER_STATUS_TYPE,
  DELIVERY_TYPE,
} from "lib/interface/launchChangeList";
import {
  GET_DELIVERY_ORDER_TYPE,
  GET_DELIVERY_ORDER,
  GET_DELIVERY_ORDER_STATUS,
  GET_LAUNCH_CHANGE_LIST_FILE,
} from "lib/api/deliverylist";
import { useDeliverylistAdd } from "authenticated-app/hooks/deliverylist/useDeliverylistAdd";
import { useDeliverylistDetail } from "authenticated-app/hooks/deliverylist/useDeliverylistDetail";
import { BreakDetailModal } from "../detail";
import { ProductType } from "authenticated-app/pc/setting/customer-setting/interface";
import { useDownload } from "utils/download";

const getSeachParams = (params: number[] | string[]) => {
  return params?.map((i) => i).join("|");
};

export const OnlineChangeListTable = () => {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<FormInstance>();
  const { height } = useWindowSize();
  const client = useHttp();
  const downloadClient = useDownload();

  const [, openDeliveryDetail] = useDeliverylistDetail();
  const [deliverylisttAddOpening, openDeliverylisttAdd] = useDeliverylistAdd();

  const [area, setArea] = useState<string[]>();
  const [areaListEnum, setAreaListEnum] = useState<{
    [key in number]: { text: string };
  }>({});
  const [provinceListEnum, setProvinceListEnum] = useState<{
    [key in number]: { text: string };
  }>({});

  const [searchParams, setSearchParams] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const { run: getFile, isLoading: gettingFile } = useAsync();
  const [downloadParams, setDownloadParams] = useState<any>();

  const getFileHandle = () => {
    getFile(
      downloadClient(GET_LAUNCH_CHANGE_LIST_FILE, {
        data: { is_download: 1, ...downloadParams },
      }),
    );
  };

  const {
    run: getHosNodes,
    isLoading: gettingHosNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };

  const {
    run: getProductList,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductType[]>();
  const getProductListHandle = () => {
    getProductList(client(GET_PRODUCT_LIST));
  };

  const {
    run: getOrderTypeList,
    data: orderTypeList,
    isIdle: getOrderTypeListIsIdle,
    isLoading: gettingOrderTypeList,
  } = useAsync<DELIVERY_ORDER_TYPE[]>();
  const getOrderTypeListHandle = () => {
    getOrderTypeList(client(GET_DELIVERY_ORDER_TYPE));
  };

  const {
    run: getOrderStatusList,
    data: orderStatusList,
    isIdle: getOrderStatusListIsIdle,
    isLoading: gettingOrderStatusList,
  } = useAsync<DELIVERY_ORDER_STATUS_TYPE[]>();
  const getOrderStatusListHandle = () => {
    getOrderStatusList(client(GET_DELIVERY_ORDER_STATUS));
  };

  const getParams = (params: any) => {
    const { create_time } = params;
    let start_time = create_time?.[0]
      ? FormatDate(create_time?.[0])
      : undefined;
    let end_time = create_time?.[1] ? FormatDate(create_time?.[1]) : undefined;
    let queryParams = {
      ...params,
      order_id: params?.order_id ? trimStr(params?.order_id) : undefined,
      customer_name: params?.customer_name
        ? trimStr(params?.customer_name)
        : undefined,
      node_user_name: params?.node_user_name
        ? trimStr(params?.node_user_name)
        : undefined,
      issue_user_name: params?.issue_user_name
        ? trimStr(params?.issue_user_name)
        : undefined,
      create_user: params?.create_user
        ? trimStr(params?.create_user)
        : undefined,
      delivery_type_id_list: getSeachParams(params?.delivery_type_name),
      delivery_type_name: undefined,
      process_id_list: getSeachParams(params?.process_name),
      process_name: undefined,
      area_id_list: getSeachParams(params?.area_id_list),
      province_id_list: getSeachParams(params?.province_id_list),
      start_time,
      end_time,
      create_time: undefined,
    };
    return {
      ...params,
      ...queryParams,
    };
  };

  const search = (params: any) => {
    return client(GET_DELIVERY_ORDER, {
      data: { ...params, ...searchParams },
    });
  };

  const DeliverylistColumns: ProColumns<DELIVERY_TYPE>[] = [
    {
      title: "工单编号",
      dataIndex: "order_id",
    },
    {
      title: "交付类型",
      dataIndex: "delivery_type_name",
      valueEnum: () => {
        let enums: { [key in number]: { text: string } } = {};
        orderTypeList?.forEach((i) => {
          enums[i.id] = { text: i.name };
        });
        return enums;
      },
      fieldProps: {
        loading: gettingOrderTypeList,
        showSearch: true,
        onClick: () => {
          if (!!!orderTypeList) {
            getOrderTypeListHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin spinning={gettingOrderTypeList}>{originNode}</Spin>
        ),
        mode: "multiple",
        maxTagCount: 3,
      },
    },
    {
      title: "紧急交付",
      dataIndex: "is_urgent_delivery",
      // hideInTable: true,
      valueType: "select",
      valueEnum: () => {
        let enums: { [key in number]: { text: string } } = {};
        [
          { name: "是", value: 1 },
          { name: "否", value: 0 },
        ]?.forEach((i) => {
          enums[i.value] = { text: i.name };
        });
        return enums;
      },
    },
    {
      title: "工单状态",
      dataIndex: "process_name",
      valueType: "select",
      valueEnum: () => {
        let enums: { [key in number]: { text: string } } = {};
        orderStatusList?.forEach((i) => {
          enums[i.id] = { text: i.name };
        });
        return enums;
      },
      fieldProps: {
        loading: gettingOrderStatusList,
        showSearch: true,
        onClick: () => {
          if (!!!orderStatusList) {
            getOrderStatusListHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin spinning={gettingOrderStatusList}>{originNode}</Spin>
        ),
        mode: "multiple",
        maxTagCount: 3,
      },
    },

    {
      title: "客户名称",
      dataIndex: "customer_name",
      render: (_, record, __) => {
        return record.customer_name || record.hospital_name;
      },
    },
    {
      title: "交付负责人",
      dataIndex: "delivery_user_name",
    },
    {
      title: "产品线",
      hideInTable: true,
      dataIndex: "product_name",
      valueEnum: () => {
        let enums: { [key in string]: { text: string } } = {};
        productList?.forEach((i) => {
          enums[i.chinese_name] = {
            text: i.chinese_name || `${i.product_name}(${i.chinese_name})`,
          };
        });
        return enums;
      },
      fieldProps: {
        loading: gettingProductList,
        showSearch: true,
        onClick: () => {
          if (!!!productList) {
            getProductListHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin spinning={gettingProductList}>{originNode}</Spin>
        ),
      },
    },

    {
      title: "区域",
      dataIndex: "area_id_list",
      valueType: "select",
      valueEnum: areaListEnum,
      fieldProps: {
        loading: gettingHosNodes,
        onChange: (i: string[]) => {
          setArea(i);
          formRef.current?.setFieldsValue({
            province_name: undefined,
          });
        },
        onClick: () => {
          if (!!!hosNodes) {
            getHosNodesHandle();
          }
        },
        dropdownRender: (originNode: any) => (
          <Spin spinning={gettingHosNodes}>{originNode}</Spin>
        ),
        mode: "multiple",
        maxTagCount: 4,
      },
      hideInTable: true,
    },
    {
      title: "省份",
      dataIndex: "province_id_list",
      valueType: "select",
      valueEnum: provinceListEnum,
      fieldProps: {
        loading: gettingHosNodes,
        showSearch: true,
        mode: "multiple",
        maxTagCount: 3,
        notFoundContent: (
          <Empty description={<span>请先选择区域！</span>}></Empty>
        ),
      },
      hideInTable: true,
    },
    {
      title: "创建时间",
      valueType: "dateRange",
      hideInTable: true,
      dataIndex: "create_time", // start_time    end_time
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      search: false,
      render: (_, record, __) => {
        return dayjs(record.create_time).format(DateTimeFormate);
      },
    },
  ];

  useEffect(() => {
    let enums: { [key in number]: { text: string } } = {};
    hosNodes?.forEach((i) => {
      if (i.nodeLevel === 1 && i.nodeId !== 5) {
        // 去除海外 nodeId 5
        enums[i.nodeId] = { text: i.nodeName || "未知" };
      }
    });
    setAreaListEnum(enums);
  }, [hosNodes]);

  useEffect(() => {
    let enums: { [key in number]: { text: string } } = {};
    hosNodes?.forEach((i) => {
      if (
        (area || []).includes(i.parentNodeId?.toString() || "-1") &&
        i.parentNodeId !== null
      ) {
        enums[i.nodeId] = { text: i.nodeName || "未知" };
      }
    });
    setProvinceListEnum(enums);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  useEffect(() => {
    actionRef.current?.reload();
    setDownloadParams(searchParams);
  }, [searchParams]);

  return (
    <Fragment>
      <ProTable<DELIVERY_TYPE>
        style={{
          minWidth: 988,
        }}
        rowKey="id"
        actionRef={actionRef}
        formRef={formRef}
        cardBordered
        columns={DeliverylistColumns}
        request={async (params) => {
          try {
            let queryParams = getParams(params);
            const { current, pageSize, ...downloadParamsD } = queryParams;
            setDownloadParams({ ...downloadParamsD, ...searchParams });
            const res = await search(queryParams);
            return {
              data: res.results,
              total: res.count,
              success: true,
            };
          } catch (e) {
            return { success: false };
          }
        }}
        onReset={() => {
          setSearchParams(undefined);
        }}
        search={{
          labelWidth: "auto",
          searchText: "搜索",
          span: 6,
        }}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: false,
          pageSize:
            Math.trunc((height - 382) / 47) > 5
              ? Math.trunc((height - 382) / 47)
              : 5,
          current: page,
          onChange: (v) => {
            setPage(v);
          },
        }}
        onRow={(record) => ({
          onClick: () => openDeliveryDetail(record.id),
        })}
        toolBarRender={() => [
          <Button
            loading={deliverylisttAddOpening}
            icon={<PlusOutlined />}
            type="primary"
            onClick={openDeliverylisttAdd}
          >
            创建
          </Button>,
          <Button
            key={"export_launch_change_file"}
            loading={gettingFile}
            icon={<DownloadOutlined />}
            onClick={getFileHandle}
          >
            导出
          </Button>,
        ]}
      />
      <BreakDetailModal actionRef={actionRef} />
      <AddModal actionRef={actionRef} />
    </Fragment>
  );
};
