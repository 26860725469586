import { Dispatch, RefObject, SetStateAction, useEffect } from "react";
import { Button, Form, Input, Picker, TextArea, Toast } from "antd-mobile";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";

import {
  ContactType,
  HosUserType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/infoTabPane/interface";
import {
  GET_HOSP_CONTACT,
  GET_HOSP_USER_TYPE,
  UPDATE_HOSP_USER,
} from "lib/api";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { DisposeForm, DisposeMobileText } from "utils";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { ConvertToPickerValueList } from "authenticated-app/mobile/util";

export const MobileHospUserInfoEdit = ({
  editingItem,
  visible,
  setVisible,
  reload,
}: {
  editingItem: HosUserType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getContact,
    isLoading: gettingContact,
    data: contact,
  } = useAsync<ContactType[]>();
  const getContactFunc = () => {
    getContact(client(GET_HOSP_CONTACT));
  };

  const {
    run: getHosUserType,
    isLoading: gettingHosUserType,
    data: hosUserType,
  } = useAsync<HosUserType[]>();
  const getHosUserTypeFunc = () => {
    getHosUserType(client(GET_HOSP_USER_TYPE));
  };

  const { run: submit, isLoading: submitting } = useAsync();
  const submitHandle = (value: any) => {
    let id = editingItem?.id;
    if (id)
      submit(
        client(UPDATE_HOSP_USER(id), {
          data: {
            // ...value,
            hos_user_name: value?.hos_user_name || null,
            contact_id: value?.contact ? parseInt(value?.contact[0]) : null,
            hos_user_type_id: value?.hos_user_type
              ? parseInt(value?.hos_user_type[0])
              : null,
            mobile: value.mobile || null,
            remark: value.remark || null,
          },
          method: "PUT",
        }),
      )
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="更新成功" />,
          });
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="更新失败" />,
          });
        });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法提交" />,
      });
  };

  useEffect(() => {
    if (visible) {
      getContactFunc();
      getHosUserTypeFunc();
      form.setFieldsValue({
        // ...editingItem,
        contact: ConvertToPickerValueList(editingItem?.contact_id),
        hos_user_type: ConvertToPickerValueList(editingItem?.hos_user_type_id),
        hos_user_name: editingItem?.hos_user_name || "",
        mobile: editingItem?.mobile || "",
        remark: editingItem?.remark || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <EditPopup
      visible={visible}
      submitting={submitting}
      setVisible={setVisible}
    >
      <Form
        form={form}
        onFinish={submitHandle}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={submitting}
          >
            提交
          </Button>
        }
      >
        <Form.Header>编辑外部人员信息</Form.Header>
        <Form.Item
          name="hos_user_name"
          label="姓名"
          rules={[{ required: true, message: "姓名不能为空" }]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input style={{ "--text-align": "right" }} placeholder="请输入姓名" />
        </Form.Item>
        <Form.Item
          name="contact"
          label="联系方式"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "联系方式不能为空" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingContact}
            columns={
              contact
                ? [
                    contact.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择联系方式</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="mobile"
          label="联系号码"
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input
            style={{ "--text-align": "right" }}
            placeholder="输入联系号码"
          />
        </Form.Item>
        <Form.Item
          name="hos_user_type"
          label="人员类型"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择人员类型" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingHosUserType}
            columns={
              hosUserType
                ? [
                    hosUserType.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择人员类型</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
          help="额外的补充信息"
          normalize={(v) => DisposeMobileText(v)}
          layout={"vertical"}
        >
          <TextArea autoSize placeholder="输入备注" />
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
