import { useEffect, useState } from "react";
import { Form, Modal, Select, Input, DatePicker, Radio } from "antd";
import { useDebounceFn } from "ahooks";
import { FormatDayjs, formatDate } from "utils";
import { useDeliveryDetailStop } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailStop";

import {
  DELIVERY_DETAIL_TYPE,
  NEXT_PROCESS_TYPE,
  DELIVERY_USER_TYPE,
} from "lib/interface/launchChangeList";
import { GET_DELIVERY_USER_LIST } from "lib/api/deliverylist";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { HospSelectFilter } from "utils";

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const DeliveryDetailStopModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailStop();

  const [form] = Form.useForm();
  const client = useHttp();
  const [suppliesReturn, setSuppliesReturn] = useState<number | null>(null);

  const {
    run: getDeliveryUserList,
    data: deliveryUserList,
    isIdle: getDeliveryUserListIdle,
    isLoading: gettingDeliveryUserList,
  } = useAsync<DELIVERY_USER_TYPE[]>();
  const getDeliveryUserListHandle = () => {
    getDeliveryUserList(client(GET_DELIVERY_USER_LIST));
  };
  const { run: getDeliveryUserListDebounce } = useDebounceFn(
    getDeliveryUserListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      ...(value.planned_return_date
        ? { planned_return_date: formatDate(value.planned_return_date) }
        : {}),
    });
    close();
  };
  useEffect(() => {
    if (suppliesReturn) {
      form.setFieldsValue({
        delivery_user_id: detailInfo?.delivery_user_id,
        planned_return_date: detailInfo?.planned_return_date
          ? FormatDayjs(detailInfo?.planned_return_date)
          : "",
      });
    }
  }, [suppliesReturn]);

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      form.setFieldsValue({
        is_need_send_back: suppliesReturn,
      });
      getDeliveryUserListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  const childinfo = () => {
    switch (detailInfo?.delivery_type_name) {
      case "销售交付":
      case "试用交付":
      case "渠道交付":
        return (
          <>
            <Form.Item
              name="is_need_send_back"
              label="是否退回硬件"
              rules={[{ required: true, message: "必须选择是否需要退回硬件" }]}
            >
              <Radio.Group
                style={{ marginLeft: "12px" }}
                onChange={(v) => {
                  setSuppliesReturn(v.target.value);
                }}
              >
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>
            </Form.Item>
            {suppliesReturn === 1 ? (
              <>
                <Form.Item
                  name="planned_return_date"
                  label="计划退回日期"
                  rules={[{ required: true, message: "必须选择计划退回日期" }]}
                >
                  <DatePicker
                    style={{ width: "300px" }}
                    placeholder="选择硬件退回计划日期"
                  />
                </Form.Item>
                <Form.Item
                  name="delivery_user_id"
                  label="交付负责人"
                  rules={[{ required: true, message: "必须指定交付负责人" }]}
                >
                  <Select
                    showSearch
                    loading={getDeliveryUserListIdle || gettingDeliveryUserList}
                    placeholder="指定交付负责人"
                    filterOption={HospSelectFilter}
                    options={deliveryUserList?.map((i) => ({
                      label: `${i.userName} (${i.jobTitle})`,
                      value: i.id,
                    }))}
                  />
                </Form.Item>
              </>
            ) : (
              <></>
            )}
          </>
        );
      case "产品升级":
      case "硬件部署":
        return (
          <>
            <Form.Item
              name="is_need_send_back"
              label="是否退回硬件"
              rules={[{ required: true, message: "必须选择是否需要退回硬件" }]}
            >
              <Radio.Group
                style={{ marginLeft: "12px" }}
                onChange={(v) => {
                  setSuppliesReturn(v.target.value);
                }}
              >
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        );
    }
  };

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="终止交付"
      onCancel={close}
      onOk={form.submit}
      width={"700px"}
      style={{ minWidth: "600px" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        <Form.Item
          name="termination_reason"
          label="终止原因"
          rules={[{ required: true, message: "必须填写终止原因" }]}
        >
          <TextArea placeholder="填写终止原因" autoSize={{ minRows: 3 }} />
        </Form.Item>
        {childinfo()}
      </Form>
    </Modal>
  );
};
