import { useEffect, useState, RefObject } from "react";
import { Select, InputNumber, Upload, UploadFile } from "antd";
import { Form, Radio, Button, Space, Picker } from "antd-mobile";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";

import { useDebounceFn } from "ahooks";
import ReactQuill from "react-quill";
import { AddOutline } from "antd-mobile-icons";

import {
  // GET_ISSUES_MODULE,
  GET_ISSUES_REASON_NEW,
  GET_ISSUES_REASON,
  GET_ISSUES_TYPE,
} from "lib/api/issues";
import {
  BREAKLIST_OPTION_TYPE,
  BREAK_DETAIL_TYPE,
} from "lib/interface/breaklist";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { editQuillToolbarOption } from "authenticated-app/pc/components/EditQuill/option";
import { useBreakDetailSolve } from "authenticated-app/hooks/breaklist/trans/useBreakDetailSolve";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { ADD_FILE } from "lib/api";
import { DisposeQuill, handleUpload, limitUpload, DisposeForm } from "utils";
import { MobileLimitUpload } from "authenticated-app/mobile/util";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";

import "authenticated-app/pc/components/EditQuill/quill.snow.css";

export const BreakDetailSolveModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: number[];
  detailInfo: BREAK_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useBreakDetailSolve();
  const [selectedIssuesTypeId, setSelectedIssuesTypeId] = useState<number>();

  const [departureConfirmationfile, setDepartureConfirmationfile] = useState<
    UploadFile[]
  >([]);

  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getIssuesTypeList,
    data: issuesTypeList,
    isLoading: gettingIssuesTypeList,
    isIdle: getIssuesTypeListIdle,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesTypeListHandle = () => {
    getIssuesTypeList(client(GET_ISSUES_TYPE));
  };
  const { run: getIssuesTypeListDebounce } = useDebounceFn(
    getIssuesTypeListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getIssuesReasonList,
    data: issuesReasonList,
    isLoading: gettingIssuesReasonList,
    isIdle: getIssuesReasonListIdle,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesReasonListHandle = () => {
    if (selectedIssuesTypeId) {
      getIssuesReasonList(client(GET_ISSUES_REASON_NEW(selectedIssuesTypeId)));
    }
  };
  const { run: getIssuesReasonListDebounce } = useDebounceFn(
    getIssuesReasonListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next,
      issue_type_id: value?.issue_type_id
        ? parseInt(value?.issue_type_id[0])
        : undefined,
      issue_reason_id: value?.issue_reason_id
        ? parseInt(value?.issue_reason_id[0])
        : undefined,
      service_order_id: departureConfirmationfile.length
        ? departureConfirmationfile.map((i) => i.uid)[0]
        : undefined,
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      let initFileList = detailInfo?.service_order_id
        ? [
            {
              uid: detailInfo?.service_order_id.toString() || "",
              name: detailInfo?.service_order_name || "",
              url: detailInfo?.service_order_addr,
            },
          ]
        : [];
      form.setFieldsValue({
        is_deal_with_on_site: detailInfo?.is_deal_with_on_site || 0,
        response_time: detailInfo?.response_time,
        issue_type_id: detailInfo?.issue_type_id
          ? [detailInfo?.issue_type_id]
          : [],
        issue_reason_id: detailInfo?.issue_reason_id
          ? [detailInfo?.issue_reason_id]
          : [],
        debug_info: detailInfo?.debug_info_dict.debug_info,
        solution_info: detailInfo?.solution_info_dict.solution_info,
        service_order_id: initFileList,
      });
      setDepartureConfirmationfile(initFileList);
      setSelectedIssuesTypeId(detailInfo?.issue_type_id);
      getIssuesTypeListDebounce();
      getIssuesReasonListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  useEffect(() => {
    getIssuesReasonListDebounce();
  }, [selectedIssuesTypeId]);

  return (
    <EditPopup visible={isOpen} submitting={false} setVisible={close}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={false}
          >
            提交
          </Button>
        }
      >
        <Form.Header>解决工单</Form.Header>
        <Form.Item
          name="is_deal_with_on_site"
          label="是否现场处理"
          rules={[{ required: true, message: "必须选择是否现场处理" }]}
          initialValue={false}
        >
          <Radio.Group>
            <Space>
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="response_time"
          label="响应时长"
          // rules={[{ required: true, message: "必须填写响应时长" }]}
        >
          <InputNumber
            precision={0}
            addonAfter={"min"}
            controls
            min={0}
            placeholder="填写响应时长"
          />
        </Form.Item>

        <Form.Item
          name="issue_type_id"
          label="反馈类型"
          trigger="onConfirm"
          rules={[{ required: true, message: "必须选择反馈类型" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingIssuesTypeList || getIssuesTypeListIdle}
            columns={
              issuesTypeList
                ? [
                    issuesTypeList.map((i) => ({
                      label: `${i.name}`,
                      value: i.id,
                    })),
                  ]
                : []
            }
            onConfirm={(v: any[]) => {
              if (v) {
                setSelectedIssuesTypeId(v[0]);
                form.setFieldValue("issue_reason_id", undefined);
              }
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择反馈类型</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="issue_reason_id"
          label="反馈归因"
          trigger="onConfirm"
          rules={[{ required: true, message: "必须选择反馈归因" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
          disabled={!!!selectedIssuesTypeId}
          help={"请先选择反馈类型!"}
        >
          <Picker
            loading={getIssuesReasonListIdle || gettingIssuesReasonList}
            columns={
              issuesReasonList
                ? [
                    issuesReasonList.map((i) => ({
                      label: `${i.name}`,
                      value: i.id,
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择反馈归因</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>

        <Form.Item
          name="debug_info"
          label="定位及补充记录"
          rules={[{ required: true, message: "必须填写定位及补充记录" }]}
          normalize={(v) => DisposeQuill(v)}
          layout="vertical"
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
            }}
          />
        </Form.Item>
        <Form.Item
          name="solution_info"
          label="操作及解决"
          rules={[{ required: true, message: "必须填写定位及补充记录" }]}
          normalize={(v) => DisposeQuill(v)}
          layout="vertical"
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
            }}
          />
        </Form.Item>
        {/* <Form.Item
          name="service_order_id"
          className="adm-form-item-label-attachment"
          label="服务确认单"
          normalize={(v) => DisposeForm(v)}
          layout={"vertical"}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={1}
            className="upload-list-inline"
            action={ADD_FILE}
            listType="picture"
            fileList={departureConfirmationfile}
            beforeUpload={MobileLimitUpload}
            onChange={(info) =>
              handleUpload(info, setDepartureConfirmationfile)
            }
          >
            {departureConfirmationfile.length ? null : (
              <Button color="primary" fill="outline">
                <Space>
                  <AddOutline />
                  <span>点击上传</span>
                </Space>
              </Button>
            )}
          </Upload>
        </Form.Item> */}
        <Form.Item
          name="service_order_id"
          className="adm-form-item-label-attachment"
          label="服务确认单"
          normalize={(v) => DisposeForm(v)}
          layout={"vertical"}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={1}
            className="upload-list-inline"
            action={ADD_FILE}
            listType="picture"
            fileList={departureConfirmationfile}
            beforeUpload={MobileLimitUpload}
            onChange={(info) =>
              handleUpload(info, setDepartureConfirmationfile)
            }
          >
            {departureConfirmationfile.length ? null : (
              <Button color="primary" fill="outline">
                <Space>
                  <AddOutline />
                  <span>点击上传</span>
                </Space>
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
