import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useBreakDetailUpgrade = () => {
  const [{ openBreakDetailUpgrade }, setOpenBreakDetailUpgrade] =
    useUrlQueryParam(["openBreakDetailUpgrade"]);

  const open = () =>
    setOpenBreakDetailUpgrade({ openBreakDetailUpgrade: true });
  const close = () =>
    setOpenBreakDetailUpgrade({
      openBreakDetailUpgrade: undefined,
    });

  return [openBreakDetailUpgrade === "true", open, close] as const;
};
