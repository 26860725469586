import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Checkbox, Descriptions, Form, message, Space } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { useDebounceFn } from "ahooks";

import { LineDiv } from "../../utils";
import { ClientType } from "../interface";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { useHttp } from "utils/http";
import { GET_CLIENT_TYPE_LIST, UPDATE_CLIENT_TYPE } from "lib/api";
import { useAsync } from "utils/hooks/useAsync";

const ClientTypeInfo = ({
  detail,
  hospID,
  reload,
  setDisChange,
}: {
  detail: ClientType[] | null;
  hospID: number | null;
  reload: () => void;
  setDisChange: Dispatch<SetStateAction<boolean>>;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const { width } = useWindowSize();
  const [canSubmit, setCanSubmit] = useState(false);

  const { run: getClientTypeList, data: clientTypeList } =
    useAsync<ClientType[]>();
  const getClientTypeListFunc = () => {
    if (hospID) {
      getClientTypeList(client(GET_CLIENT_TYPE_LIST));
    }
  };
  const { run: getClientTypeListDebounce } = useDebounceFn(
    getClientTypeListFunc,
    {
      wait: 200,
    },
  );

  useEffect(() => {
    getClientTypeListDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospID]);

  useEffect(() => {
    if (detail) {
      let value: any = {};
      clientTypeList?.forEach((i) => {
        let index = detail.findIndex((d) => d.id === i.id);
        value[i.id] = index > -1 ? true : false;
      });
      form.setFieldsValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, clientTypeList]);

  const submit = (value: Object) => {
    if (hospID) {
      let result: number[] = [];
      for (let [k, v] of Object.entries(value)) {
        if (v) {
          result.push(parseInt(k));
        }
      }

      client(UPDATE_CLIENT_TYPE(hospID), {
        data: result,
        method: "PUT",
      })
        .then(() => {
          message.success("更新成功！");
          setDisChange(false);
          setCanSubmit(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("更新失败！请检查或者联系管理员");
        });
    } else message.error("无法完成更新，请刷新重试或联系管理员！");
  };

  return (
    <Form form={form} onFinish={submit}>
      <Descriptions
        title="客户使用方式"
        column={width <= 1440 ? 2 : 4}
        extra={
          canSubmit ? (
            <Space>
              <Button
                onClick={() => {
                  setDisChange(false);
                  setCanSubmit(false);
                  reload();
                }}
                icon={<CloseOutlined />}
              >
                取消
              </Button>
              <Button type="primary" htmlType="submit" icon={<CheckOutlined />}>
                提交
              </Button>
            </Space>
          ) : (
            <div style={{ height: "32px" }}></div>
          )
        }
      >
        {clientTypeList?.map((item, index) => (
          <Descriptions.Item key={index}>
            <LineDiv>
              <Form.Item
                name={item.id}
                getValueProps={(value) => ({ checked: value === true, value })}
                getValueFromEvent={(e) => (e.target.checked ? true : false)}
              >
                <Checkbox
                  onChange={() => {
                    setDisChange(true);
                    setCanSubmit(true);
                  }}
                >
                  {item.name}
                </Checkbox>
              </Form.Item>
            </LineDiv>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Form>
  );
};

export default ClientTypeInfo;
