import { Dispatch, Fragment, SetStateAction, useEffect } from "react";
import { Modal, Descriptions, Spin, Alert } from "antd";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { GET_PACKAGE } from "../api";
import { PackageType, PermissionRuleType, PermissionType } from "../interface";

export const PermissionListSettingShow = ({
  info,
  visible,
  setVisible,
}: {
  info: PackageType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  const client = useHttp();
  const {
    run: getPackgeDetail,
    data: packageDetail,
    isLoading: gettingPackgeDetail,
  } = useAsync<PermissionType>();
  const getPackgeDetailHandle = (id: number) => {
    getPackgeDetail(client(GET_PACKAGE(id)));
  };

  useEffect(() => {
    if (visible && info?.packageId) {
      getPackgeDetailHandle(info?.packageId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const ShowDescItem = (item: PermissionRuleType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`permissionList${key}`}>
        <Descriptions.Item label={title("编号")} className={className()}>
          {key + 1}
        </Descriptions.Item>
        <Descriptions.Item label={title("资源")} className={className()}>
          {item?.isAllResource === 1 ? "*" : item.resourceName || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("分类")} className={className()}>
          {item?.isAllDomain === 1 ? "*" : item?.domainNames.join("、")}
        </Descriptions.Item>
        <Descriptions.Item label={title("规则")} className={className()}>
          {item?.actions.split(",").join("、")}
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Modal
      title={info?.packageName}
      destroyOnClose
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={"61.8%"}
    >
      <Spin spinning={gettingPackgeDetail}>
        {packageDetail?.parentPackageNames.length ? (
          <Alert
            message={`引用权限：${packageDetail?.parentPackageNames.join(
              "、",
            )}`}
            type="info"
            showIcon
            style={{ marginBottom: "1rem" }}
          />
        ) : null}
        <Descriptions
          column={4}
          bordered={packageDetail?.resources?.length ? true : false}
          layout="vertical"
        >
          {packageDetail?.resources?.map((item, index) =>
            ShowDescItem(item, index),
          )}
        </Descriptions>
      </Spin>
    </Modal>
  );
};
