import { useState } from "react";
import {
  Button,
  Dialog,
  Ellipsis,
  List,
  SwipeAction,
  Toast,
} from "antd-mobile";
import {
  RightOutline,
  EditSOutline,
  DeleteOutline,
  ExclamationCircleFill,
  AddSquareOutline,
} from "antd-mobile-icons";

import { HosUserType } from "authenticated-app/pc/customer/customer-detail/tab-pane/infoTabPane/interface";
import { MobileHospUserInfoDetail } from "./detail";
import {
  ListRightIconSize,
  SwipeActionIconSize,
} from "authenticated-app/mobile/util";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { DEL_HOSP_USER } from "lib/api";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { MobileHospUserInfoEdit } from "./edit";
import { MobileHospUserInfoAdd } from "./add";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { DelConfirmContent } from "authenticated-app/mobile/components/DelConfirmContent";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";

export const MobileHospUserInfoList = ({
  hosUserInfo,
  reload,
}: {
  hosUserInfo: HosUserType[] | undefined;
  reload: () => void;
}) => {
  const [detailInfo, setDetailInfo] = useState<HosUserType>();
  const [detailVisible, setDetailVisible] = useState<boolean>(false);

  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [editHosUserInfo, setEditHosUserInfo] = useState<HosUserType>();

  const [addVisible, setAddVisible] = useState<boolean>(false);

  const client = useHttp();

  const { run: del, isLoading: deleting } = useAsync();
  const delHandle = (id?: string | number) => {
    if (id)
      del(client(DEL_HOSP_USER(id), { method: "DELETE" }))
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="删除成功" />,
          });
          reload();
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="删除失败" />,
          });
        });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法删除" />,
      });
  };

  return (
    <ContentCard>
      <LoadingMask visible={deleting} />
      <List
        mode="card"
        header={
          <ListHeader
            text="外部人员信息"
            rightIcon={<AddSquareOutline fontSize={ListRightIconSize} />}
            loading={addVisible}
            onClick={() => {
              setAddVisible(true);
            }}
          />
        }
      >
        {hosUserInfo?.map((u) => (
          <SwipeAction
            key={u.id}
            closeOnAction={false}
            rightActions={[
              {
                key: "edit",
                text: <EditSOutline fontSize={SwipeActionIconSize} />,
                color: "primary",
                onClick: () => {
                  setEditHosUserInfo(u);
                  setEditVisible(true);
                },
              },
              {
                key: "delete",
                text: <DeleteOutline fontSize={SwipeActionIconSize} />,
                color: "danger",
                onClick: () => {
                  Dialog.confirm({
                    header: (
                      <ExclamationCircleFill
                        style={{
                          fontSize: 48,
                          color: "var(--adm-color-danger)",
                        }}
                      />
                    ),
                    content: <DelConfirmContent content={u.hos_user_name} />,
                    confirmText: (
                      <span style={{ color: "var(--adm-color-danger)" }}>
                        确定
                      </span>
                    ),
                    onConfirm: () => delHandle(u.id),
                  });
                },
              },
            ]}
          >
            <List.Item
              extra={
                u.hos_user_type_name ? (
                  <Ellipsis direction="end" content={u.hos_user_type_name} />
                ) : (
                  "未知类型"
                )
              }
              arrow={
                <Button
                  fill="none"
                  style={{ height: "100%", padding: 0 }}
                  loading={
                    detailVisible &&
                    u.id.toString() === detailInfo?.id.toString()
                  }
                >
                  <RightOutline style={{ color: "var(--adm-color-light)" }} />
                </Button>
              }
              onClick={() => {
                setDetailInfo(u);
                setDetailVisible(true);
              }}
            >
              <Ellipsis direction="end" content={u.hos_user_name} />
            </List.Item>
          </SwipeAction>
        ))}
      </List>
      <MobileHospUserInfoDetail
        hosUserInfo={detailInfo}
        visible={detailVisible}
        setVisible={setDetailVisible}
      />
      <MobileHospUserInfoEdit
        editingItem={editHosUserInfo}
        visible={editVisible}
        setVisible={setEditVisible}
        reload={reload}
      />
      <MobileHospUserInfoAdd
        visible={addVisible}
        setVisible={setAddVisible}
        reload={reload}
      />
    </ContentCard>
  );
};
