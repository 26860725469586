import { useState, useEffect, Dispatch, SetStateAction } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  message,
  DatePicker,
  Upload,
  UploadFile,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import {
  ADD_FILE,
  ADD_GPU_INFO,
  GET_GPU_MODEL,
  GET_GPU_OEM,
  GET_HOS_PRODUCT_LIST,
} from "lib/api";
import { GPUModel, GPUOem, HospProductsListType } from "../interface";
import {
  DisabledDate,
  DisposeForm,
  formatDate,
  GpuNumberList,
  handleUploadImage,
  limitUploadImage,
} from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { handlePreview } from "utils/handlePreview";

const { TextArea } = Input;
const { Option } = Select;

export const GPUInfoAdd = ({
  hospID,
  hardware_id,
  visible,
  setVisible,
  reload,
}: {
  hospID: number | null;
  hardware_id: string | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [addGPUOemID, setAddGPUOemID] = useState<number | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const {
    run: getGPUOemList,
    isLoading: gettingGPUOemList,
    data: gpuOemList,
  } = useAsync<GPUOem[]>();
  const getGPUOemListFunc = () => {
    getGPUOemList(client(GET_GPU_OEM));
  };

  const {
    run: getGPUModelList,
    isLoading: gettingGPUModelList,
    data: gpuModelList,
  } = useAsync<GPUModel[]>();
  const getGPUModelListFunc = (org_id: string | number) => {
    getGPUModelList(client(GET_GPU_MODEL(org_id)));
  };

  const {
    run: getHosProductList,
    isLoading: gettingHosProductList,
    data: hospProductList,
  } = useAsync<HospProductsListType[]>();
  const getHosProductListFunc = (hospital_id: number) => {
    getHosProductList(client(GET_HOS_PRODUCT_LIST(hospital_id)));
  };

  const submit = (value: any) => {
    if (hardware_id)
      client(ADD_GPU_INFO, {
        data: {
          ...value,
          hardware_id: parseInt(hardware_id),
          asset_photos: fileList.map((i) => i.uid),
          change_date: formatDate(value?.change_date),
        },
        method: "POST",
      })
        .then(() => {
          message.success("添加成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("添加失败，请检查或联系管理员！");
        });
    else {
      message.error("添加失败，请稍后重试或联系管理员！");
    }
  };

  const clear = () => {
    form.setFieldsValue({
      gpu_type_id: null,
    });
  };

  useEffect(() => {
    if (visible) {
      setFileList([]);
      form.resetFields();
      if (hospID) {
        getHosProductListFunc(hospID);
      }
      getGPUOemListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (addGPUOemID) {
      getGPUModelListFunc(addGPUOemID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addGPUOemID]);

  return (
    <Modal
      title="新增显卡信息"
      destroyOnClose
      open={visible}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      onOk={form.submit}
      okText="提交"
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={submit}
      >
        <Form.Item
          name="slot_num"
          label="卡槽编号"
          rules={[{ required: true, message: "必须选择卡槽编号" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select placeholder="选择卡槽编号">
            {GpuNumberList.map((i) => (
              <Option key={i} value={i}>
                {i}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="gpu_oem_id"
          label="显卡品牌"
          rules={[{ required: true, message: "必须选择显卡品牌" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingGPUOemList}
            placeholder="选择显卡品牌"
            onChange={(v) => {
              clear();
              setAddGPUOemID(v);
            }}
          >
            {gpuOemList?.map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="gpu_type_id"
          label="显卡型号"
          rules={[{ required: true, message: "必须选择显卡型号" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select loading={gettingGPUModelList} placeholder="选择显卡型号">
            {gpuModelList?.map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="assets_code"
          label="资产编码"
          rules={[{ required: true, message: "必须输入资产编码" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input />
        </Form.Item>
        <Form.Item name="uuid" label="UUID" normalize={(v) => DisposeForm(v)}>
          <Input />
        </Form.Item>
        <Form.Item
          name="change_date"
          label="更换时间"
          rules={[{ required: true, message: "必须选择更换时间" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <DatePicker picker={"date"} disabledDate={DisabledDate} />
        </Form.Item>
        <Form.Item
          name="products"
          label="运行产品线"
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            allowClear
            loading={gettingHosProductList}
            mode="multiple"
            placeholder="选择产品线"
          >
            {hospProductList?.map((i, index) => (
              <Option key={index} value={i.product_id}>
                {i.product_id}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="remark" label="备注" normalize={(v) => DisposeForm(v)}>
          <TextArea allowClear />
        </Form.Item>
        <Form.Item
          name="asset_photos"
          label="资产照片"
          normalize={(v) => DisposeForm(v)}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={3}
            multiple={true}
            action={ADD_FILE}
            listType="picture-card"
            fileList={fileList}
            beforeUpload={limitUploadImage}
            onChange={(info) => handleUploadImage(info, setFileList)}
            onPreview={(f) =>
              handlePreview(f, {
                setPreviewImage,
                setPreviewOpen,
                setPreviewTitle,
              })
            }
          >
            {fileList.length >= 3 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传(限3个)</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </Form>
      <Modal
        centered
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        width={"61.8%"}
      >
        <div style={{ width: "100%" }}>
          <img alt="previwe" style={{ width: "100%" }} src={previewImage} />
        </div>
      </Modal>
    </Modal>
  );
};
