import { Dispatch, SetStateAction } from "react";
import { Collapse, List, Popup } from "antd-mobile";

import { ProductUpdateRecordType } from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";

export const MobileProductUpdateRecordDetail = ({
  detailInfo,
  visible,
  setVisible,
}: {
  detailInfo: ProductUpdateRecordType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Popup
      visible={visible}
      destroyOnClose
      bodyStyle={{ height: "61.8vh", overflowY: "scroll" }}
      onMaskClick={() => setVisible(false)}
      showCloseButton
      onClose={() => setVisible(false)}
    >
      <List
        mode="card"
        header={<p style={{ fontWeight: "bold" }}>更新记录详情</p>}
      >
        <List.Item extra={detailInfo?.product_version_name || "未知"}>
          安装分支
        </List.Item>
        <List.Item extra={detailInfo?.update_date || "未知"}>
          更新时间
        </List.Item>
        <Collapse
          defaultActiveKey={
            detailInfo?.remark ? detailInfo?.id.toString() : undefined
          }
          accordion
        >
          <Collapse.Panel
            key={detailInfo?.id.toString() || "retainInfo"}
            title="注意事项"
          >
            {detailInfo?.remark || "无"}
          </Collapse.Panel>
        </Collapse>
      </List>
      <List mode="card" header={"更新记录"}>
        <List.Item extra={detailInfo?.update_user || "未知"}>更新人</List.Item>
      </List>
    </Popup>
  );
};
