import { useEffect, Dispatch, SetStateAction } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  Input,
  message,
  DatePicker,
  Select,
} from "antd";

import dayjs from "dayjs";

import { useHttp } from "utils/http";

import { ProductUpdateRecordType, ProductVersionType } from "../interface";
import {
  DateFormate,
  DisabledDate,
  DisposeForm,
  formatDate,
  selectFilter,
} from "utils";
import { GET_PRODUCT_VERSION } from "lib/api";
import { useAsync } from "utils/hooks/useAsync";

const { TextArea } = Input;
const { Option } = Select;

export const UpdateRecordEdit = ({
  editingItem,
  UPDATE_RETAIN,
  visible,
  setVisible,
  reload,
}: {
  editingItem: ProductUpdateRecordType | undefined;
  UPDATE_RETAIN: (hospID: number) => string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getProductVersionList,
    isLoading: gettingProductVersionList,
    data: productVersionList,
  } = useAsync<ProductVersionType[]>();
  const getProductVersionListFunc = () => {
    getProductVersionList(client(GET_PRODUCT_VERSION));
  };

  const submit = (value: any) => {
    let id = editingItem?.id;
    if (id)
      client(UPDATE_RETAIN(id), {
        data: {
          ...value,
          id: id,
          update_date: formatDate(value?.update_date),
        },
        method: "PUT",
      })
        .then(() => {
          message.success("修改成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    else message.error("无法提交修改，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    if (visible) {
      getProductVersionListFunc();
      form.setFieldsValue({
        ...editingItem,
        update_date: dayjs(editingItem?.update_date, DateFormate),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="修改产品升级记录"
      destroyOnClose
      open={visible}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="product_version_id"
          label="安装分支"
          rules={[{ required: true, message: "必须选择安装分支" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            showSearch
            filterOption={selectFilter}
            loading={gettingProductVersionList}
            placeholder="选择安装分支"
          >
            {productVersionList?.map((i, index) => (
              <Option key={index} value={i?.id}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="update_date"
          label="更新时间"
          rules={[{ required: true, message: "必须输入更新时间" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <DatePicker picker={"date"} disabledDate={DisabledDate} />
        </Form.Item>
        <Form.Item
          name="remark"
          label="注意事项"
          normalize={(v) => DisposeForm(v)}
        >
          <TextArea allowClear />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
