import { HospListType } from "interface";
import { PredictType } from "../interface";
import { useDebounceFn } from "ahooks";
import { Card, Row, Col, DatePicker, Button, message } from "antd";
import { useAsync } from "utils/hooks/useAsync";
import { useEffect, Fragment, useState } from "react";
import { PredictLineChart } from "./predictLineChart";
import { RedoOutlined } from "@ant-design/icons";
import { GET_PREDICT_INFO } from "lib/api";
import { useHttp } from "utils/http";
import dayjs from "dayjs";
import { formatDate } from "utils";
import { DisabledDate } from "utils";

const { RangePicker } = DatePicker;

export const HosPredictDetail = ({
  hospInfo,
  stateKey,
}: {
  hospInfo: HospListType | undefined;
  stateKey?: string;
}) => {
  const client = useHttp();
  const DEFAULT_DAYS = 7;

  const [startDate, setStartDate] = useState<dayjs.Dayjs>(
    dayjs().subtract(DEFAULT_DAYS, "days"),
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());

  const {
    run: getHospPredict,
    isLoading: gettingHospPredict,
    isIdle: getHospPredictIsIdle,
    data: hospPredict,
  } = useAsync<PredictType[]>();
  const getHospPredictHandle = () => {
    if (hospInfo) {
      getHospPredict(
        client(GET_PREDICT_INFO(hospInfo.id), {
          data: {
            start_date: formatDate(startDate),
            end_date: formatDate(endDate),
          },
        }),
      );
    } else {
      message.error("无法请求当前医院预测数据，请稍后重试或者联系管理员！");
    }
  };
  const { run: getHospPredictDebounce } = useDebounceFn(getHospPredictHandle, {
    wait: 200,
  });
  const loadingData = getHospPredictIsIdle || gettingHospPredict;

  useEffect(() => {
    setStartDate(dayjs().subtract(DEFAULT_DAYS, "days"));
    setEndDate(dayjs());
    if (hospInfo && stateKey === "predict") {
      getHospPredictHandle();
    }
  }, [hospInfo]);

  useEffect(() => {
    if (stateKey === "predict" && hospInfo) {
      getHospPredictDebounce();
    }
  }, [stateKey]);

  return (
    <>
      <Card
        style={{ height: "450px" }}
        title={
          <Row gutter={[16, 16]}>
            <Col>
              <RangePicker
                disabled={loadingData}
                allowClear={false}
                disabledDate={DisabledDate}
                value={[startDate, endDate]}
                style={{ width: 240 }}
                onChange={(v) => {
                  if (v) {
                    setStartDate(v[0] || dayjs());
                    setEndDate(v[1] || dayjs());
                  }
                }}
              />
            </Col>
            <Col>
              <Button
                disabled={loadingData}
                loading={loadingData}
                icon={<RedoOutlined />}
                type="primary"
                onClick={() => getHospPredictHandle()}
              >
                查询
              </Button>
            </Col>
          </Row>
        }
        loading={loadingData}
      >
        <Fragment>
          <PredictLineChart data={hospPredict} />
        </Fragment>
      </Card>
    </>
  );
};
