import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-components";
import { Button, Col, Divider, Row, Select, Space, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDebounceFn, useMount } from "ahooks";

import { useHttp } from "utils/http";
import { HosNodesType } from "authenticated-app/pc/customer/customer-list/interface";
import { useAsync } from "utils/hooks/useAsync";
import { GET_HOSP_NODES, GET_PRODUCT_LIST } from "lib/api";
import { RID, hosStatus, selectFilter } from "utils";
import { BaseValueType } from "../interface";
import { GET_PRODUCT_NUM, GET_PRODUCT_VERSION_DETAIL } from "../api";
import { MyPieChart, MyPieChartType } from "../chart/pie-chart";
import { GetProductBar } from "./calculate-value/product-bar";
import { GetProductPie } from "./calculate-value/product-pie";
import { MyBarChart, MyBarChartType } from "../chart/bar-chart";
import { ProductType } from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import { useWindowSize } from "utils/hooks/useWindowSize";

const { Option } = Select;

export const ProductNumberStatistical = () => {
  const client = useHttp();
  const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [provinceList, setProvinceList] = useState<HosNodesType[]>();
  const [searchArea, setSearchArea] = useState<number>();
  const [searchProvince, setSearchProvince] = useState<number>();
  const [searchUserStatus, setSearchUserStatus] = useState<string>();

  const [searchProduct, setSearchProduct] = useState<string>();

  const [numPie, setNumPie] = useState<MyPieChartType>();
  const [numBar, setNumBar] = useState<MyBarChartType>();

  const [versionPie, setVersionPie] = useState<MyPieChartType>();
  const [versionBar, setVersionBar] = useState<MyBarChartType>();

  const { height } = useWindowSize();

  const {
    run: getProductNum,
    isLoading: gettingProductNum,
    data: productNum,
  } = useAsync<BaseValueType[]>();
  const getProductNumHandle = () => {
    getProductNum(
      client(GET_PRODUCT_NUM, {
        data: {
          using_status: searchUserStatus ?? hosStatus.using,
          area_id: searchArea,
          province_id: searchProvince,
        },
      }),
    );
  };
  const { run: getProductNumDebounce } = useDebounceFn(getProductNumHandle, {
    wait: 200,
  });

  const {
    run: getHosNodes,
    isLoading: gettingNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };
  const { run: getHosNodesDebounce } = useDebounceFn(getHosNodesHandle, {
    wait: 200,
  });

  const {
    run: getProductList,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductType[]>();
  const getProductListHandle = () => {
    getProductList(client(GET_PRODUCT_LIST));
  };
  const { run: getProductListDebounce } = useDebounceFn(getProductListHandle, {
    wait: 200,
  });

  const {
    run: getProductVersion,
    isLoading: gettingProductVersionm,
    data: productVersion,
  } = useAsync<BaseValueType[]>();
  const getProductVersionHandle = () => {
    getProductVersion(
      client(GET_PRODUCT_VERSION_DETAIL, {
        data: {
          product_id: searchProduct ?? RID,
        },
      }),
    );
  };
  const { run: getProductVersionDebounce } = useDebounceFn(
    getProductVersionHandle,
    { wait: 200 },
  );

  useEffect(() => {
    let provinceListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.parentNodeId === searchArea && item.parentNodeId !== null) {
        provinceListTMP?.push(item);
      }
    });
    setProvinceList(provinceListTMP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchArea]);

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.nodeLevel === 1 && item.nodeName !== "海外") {
        areaListTMP?.push(item);
      }
    });
    setAreaList(areaListTMP);
  }, [hosNodes]);

  useEffect(() => {
    if (productNum) {
      setNumPie(GetProductPie(productNum));
      setNumBar(GetProductBar(productNum));
    }
  }, [productNum]);

  useEffect(() => {
    if (productVersion) {
      setVersionPie(GetProductPie(productVersion));
      setVersionBar(GetProductBar(productVersion));
    }
  }, [productVersion]);

  useEffect(() => {
    if (productList) {
      if (!searchProduct) {
        setSearchProduct(RID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList]);

  useMount(() => {
    getProductNumDebounce();
    getHosNodesDebounce();
    getProductListDebounce();
    getProductVersionDebounce();
    setSearchUserStatus(hosStatus.using);
  });

  const NumLoading = gettingNodes || gettingProductNum;
  const VersionLoading = gettingProductList || gettingProductVersionm;

  return (
    <div style={{ height: "100%", minWidth: 988 }}>
      <Spin spinning={NumLoading}>
        <PageHeader
          style={{ padding: 0 }}
          extra={
            <Space>
              <Select
                style={{ width: 120 }}
                placeholder="状态"
                value={searchUserStatus}
                loading={NumLoading}
                disabled={NumLoading}
                onChange={(status) => setSearchUserStatus(status)}
              >
                {[
                  hosStatus.pending,
                  hosStatus.using,
                  hosStatus.stop,
                  hosStatus.out,
                ].map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <Select
                allowClear
                style={{ width: 120 }}
                placeholder="区域"
                disabled={NumLoading}
                loading={NumLoading}
                value={searchArea}
                onChange={(nodeId) => {
                  setSearchProvince(undefined);
                  setSearchArea(nodeId);
                }}
              >
                {areaList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Select
                disabled={NumLoading}
                loading={NumLoading}
                value={searchProvince}
                allowClear
                placeholder="省份"
                style={{ width: 140 }}
                showSearch
                filterOption={selectFilter}
                onChange={(nodeId) => {
                  setSearchProvince(nodeId);
                }}
              >
                {provinceList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                loading={NumLoading}
                disabled={NumLoading}
                onClick={getProductNumHandle}
              >
                查询
              </Button>
            </Space>
          }
        />
        <Row
          style={{ height: (height - 256) / 2, minHeight: 360, width: "100%" }}
        >
          <Col span={10}>
            <MyPieChart Data={numPie} showLegend={false} />
          </Col>
          <Col span={14}>
            <MyBarChart Data={numBar} />
          </Col>
        </Row>
      </Spin>
      <Divider style={{ marginTop: 0, marginBottom: "9px" }} />
      <Spin spinning={VersionLoading}>
        <PageHeader
          style={{ padding: 0 }}
          extra={
            <Space>
              <Select
                disabled={VersionLoading}
                loading={VersionLoading}
                value={searchProduct}
                placeholder="选择产品线"
                style={{ width: 140 }}
                showSearch
                filterOption={selectFilter}
                onChange={(v) => {
                  setSearchProduct(v);
                }}
              >
                {productList?.map((i, index) => (
                  <Option key={index} value={i?.title}>
                    {i?.chinese_name}
                  </Option>
                ))}
              </Select>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                loading={VersionLoading}
                disabled={VersionLoading}
                onClick={getProductVersionHandle}
              >
                查询
              </Button>
            </Space>
          }
        />
        <Row
          style={{ height: (height - 256) / 2, minHeight: 360, width: "100%" }}
        >
          <Col span={10}>
            <MyPieChart Data={versionPie} showLegend={false} />
          </Col>
          <Col span={14}>
            <MyBarChart Data={versionBar} />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
