import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Descriptions,
  Space,
  Input,
  Select,
  message,
  Form,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import {
  GET_HOSP_TYPE,
  GET_HOSP_NODES,
  GET_HOSP_COUNTY,
  UPDATE_HOSP_INFO,
  GET_HOSP_CITY,
} from "lib/api";
import { DesItemLabel, LineDiv } from "../../utils";

import { CountyType, DetailType, HosType } from "../interface";
import {
  CityType,
  HosNodesType,
} from "authenticated-app/pc/customer/customer-list/interface";
import { DisposeForm, selectFilter } from "utils";
import { useAsync } from "utils/hooks/useAsync";

const { Option } = Select;
const { TextArea } = Input;

const BaseInfoEdit = ({
  editingItem,
  setEditable,
  setDisChange,
  reload,
}: {
  editingItem: DetailType | undefined;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setDisChange: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getHosNodes,
    isLoading: gettingHosNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesFunc = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };

  const {
    run: getHosTypeList,
    isLoading: gettingHosTypeList,
    data: hosTypeList,
  } = useAsync<HosType[]>();
  const getHosTypeListFunc = () => {
    getHosTypeList(client(GET_HOSP_TYPE));
  };

  const {
    run: getCityList,
    isLoading: gettingCityList,
    data: cityList,
    setData: setCityList,
  } = useAsync<CityType[]>();
  const getCityListFunc = (province_id: number | null | undefined) => {
    if (province_id) {
      getCityList(client(GET_HOSP_CITY(province_id)));
    }
  };

  const {
    run: getCountyList,
    isLoading: gettingCountyList,
    data: countyList,
  } = useAsync<CountyType[]>();
  const getCountyListFunc = (city_id: number | null | undefined) => {
    if (city_id) {
      getCountyList(client(GET_HOSP_COUNTY(city_id)));
    }
  };

  // 基本信息state
  const [areaList, setAreaList] = useState<HosNodesType[]>([]);
  const [provinceList, setProvinceList] = useState<HosNodesType[]>([]);

  const [hosFirTypeList, setHosFirTypeList] = useState<HosType[]>([]);
  const [hosSecTypeList, setHosSecTypeList] = useState<HosType[]>([]);

  const [hosFirTypeID, setHosFirTypeID] = useState<number | null>(null);

  const [hosArea, setHosArea] = useState<number | null>(null);
  const [hosProvince, setHosProvince] = useState<number | null>(null);
  const [hosCity, setHosCity] = useState<number | null>(null);

  const submit = (value: any) => {
    if (editingItem?.id)
      client(UPDATE_HOSP_INFO(editingItem?.id), {
        data: {
          ...value,
          hospital_id: editingItem?.id,
          hospital_type_id:
            form.getFieldValue("hospital_type_id_2") ||
            form.getFieldValue("hospital_type_id_1"),
        },
        method: "PUT",
      })
        .then(() => {
          message.success("更新成功！");
          setEditable(false);
          setDisChange(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("更新失败！请检查或者联系管理员");
        });
    else message.error("无法提交更新！请检查或者联系管理员");
  };

  const hosTypeClear = () => {
    // setHosSecTypeID(null);
    form.setFieldsValue({
      hospital_type_id_2: null,
    });
    setHosSecTypeList([]);
  };
  const provinceClear = () => {
    setHosProvince(null);
    form.setFieldsValue({
      province_id: null,
    });
  };
  const cityClear = () => {
    setHosCity(null);
    form.setFieldsValue({
      city_id: null,
    });
  };
  const countyClear = () => {
    form.setFieldsValue({
      county_id: null,
    });
  };

  const updateProvinceList = (area_id: number | undefined) => {
    if (area_id) {
      let provinceListTMP: HosNodesType[] = [];
      hosNodes?.forEach((item) => {
        switch (item.nodeLevel) {
          case 2:
            if (item.parentNodeId === (area_id || 0)) {
              provinceListTMP?.push(item);
            }
            break;
        }
      });
      setProvinceList(provinceListTMP);
    }
  };

  useEffect(() => {
    let firTypeList: HosType[] = [];
    let secTypeList: HosType[] = [];
    hosTypeList?.forEach((item) => {
      if (item.parent_id === null) {
        firTypeList.push(item);
      }
      if (hosFirTypeID && item.parent_id === hosFirTypeID) {
        secTypeList.push(item);
      }
    });
    setHosFirTypeList(firTypeList);
    setHosSecTypeList(secTypeList);
  }, [hosFirTypeID, hosTypeList]);

  useEffect(() => {
    if (hosTypeList && editingItem) {
      let hosSecType = hosTypeList?.find(
        (i) => i.parent_id && i.id === editingItem?.hospital_type_id,
      );
      let hosFirTypeID =
        hosSecType?.parent_id || editingItem?.hospital_type_id || null;
      setHosFirTypeID(hosFirTypeID);
      form.setFieldsValue({
        hospital_type_id_1: hosFirTypeID,
        hospital_type_id_2: hosSecType?.id || null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hosTypeList, editingItem]);

  useEffect(() => {
    getCountyListFunc(hosCity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hosCity]);

  useEffect(() => {
    getCityListFunc(hosProvince);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hosProvince]);

  useEffect(() => {
    if (hosArea) {
      let provinceListTMP: HosNodesType[] = [];
      hosNodes?.forEach((item) => {
        switch (item.nodeLevel) {
          case 2:
            if (item.parentNodeId === (hosArea || 0)) {
              provinceListTMP?.push(item);
            }
            break;
        }
      });
      setProvinceList(provinceListTMP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hosArea]);

  useEffect(() => {
    if (areaList) {
      updateProvinceList(editingItem?.area_id);
      getCityListFunc(editingItem?.province_id);
      getCountyListFunc(editingItem?.city_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaList]);

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      switch (item.nodeLevel) {
        case 1:
          areaListTMP?.push(item);
          break;
      }
    });
    setAreaList(areaListTMP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hosNodes]);

  useEffect(() => {
    if (editingItem) {
      getHosNodesFunc();
      getHosTypeListFunc();
      form.setFieldsValue({
        ...editingItem,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  return (
    <Form form={form} onFinish={submit}>
      <Descriptions
        title="客户基本信息"
        column={2}
        extra={
          <Space>
            <Button
              onClick={() => {
                setEditable(false);
                setDisChange(false);
              }}
              icon={<CloseOutlined />}
            >
              取消
            </Button>
            <Button type="primary" htmlType="submit" icon={<CheckOutlined />}>
              提交
            </Button>
          </Space>
        }
      >
        <Descriptions.Item label={<DesItemLabel label={"客户名称"} />}>
          <LineDiv>{editingItem?.name}</LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<DesItemLabel label={"区域"} />}>
          <LineDiv>
            <Form.Item
              name="area_id"
              rules={[{ required: true, message: "必须选择区域" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingHosTypeList}
                style={{ width: 180 }}
                onChange={(v) => {
                  provinceClear();
                  cityClear();
                  setCityList([]);
                  countyClear();
                  setHosArea(v);
                }}
              >
                {areaList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<DesItemLabel label={"客户编码"} />}>
          <LineDiv>{editingItem?.code}</LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<DesItemLabel label={"省份"} />}>
          <LineDiv>
            <Form.Item
              name="province_id"
              rules={[{ required: true, message: "必须选择省份" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                showSearch
                filterOption={selectFilter}
                style={{ width: 180 }}
                onChange={(v) => {
                  cityClear();
                  countyClear();
                  setHosProvince(v);
                }}
              >
                {provinceList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<DesItemLabel label={"客户类型"} />}>
          <LineDiv>
            <Space>
              <Form.Item
                name="hospital_type_id_1"
                rules={[{ required: true, message: "必须选择客户类型" }]}
                normalize={(v) => DisposeForm(v)}
              >
                <Select
                  loading={gettingHosNodes}
                  onChange={(v) => {
                    hosTypeClear();
                    setHosFirTypeID(v);
                  }}
                  style={{ width: 120 }}
                >
                  {hosFirTypeList?.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {hosSecTypeList?.length ? (
                <Form.Item
                  name="hospital_type_id_2"
                  rules={[{ required: true, message: "必须选择客户类型" }]}
                  normalize={(v) => DisposeForm(v)}
                >
                  <Select loading={gettingHosNodes} style={{ width: 120 }}>
                    {hosSecTypeList?.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null}
            </Space>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<DesItemLabel label={"城市"} />}>
          <LineDiv>
            <Form.Item
              name="city_id"
              rules={[{ required: true, message: "必须选择城市" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingCityList}
                showSearch
                filterOption={selectFilter}
                style={{ width: 180 }}
                onChange={(v) => {
                  countyClear();
                  setHosCity(v);
                }}
              >
                {cityList?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.cityName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<DesItemLabel label={"客户地址"} />}>
          <LineDiv>
            <Form.Item name="address" normalize={(v) => DisposeForm(v)}>
              <TextArea
                allowClear
                style={{ width: 248 }}
                autoSize={{ minRows: 1 }}
              />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<DesItemLabel label={"市/区/县"} />}>
          <LineDiv>
            <Form.Item name="county_id" normalize={(v) => DisposeForm(v)}>
              <Select
                loading={gettingCountyList}
                allowClear
                showSearch
                filterOption={selectFilter}
                style={{ width: 180 }}
              >
                {countyList?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.county_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
      </Descriptions>
    </Form>
  );
};

export default BaseInfoEdit;
