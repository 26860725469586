import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useDeliveryDetailReScheduled = () => {
  const [{ openDeliveryDetailReScheduled }, setopenDeliveryDetailReScheduled] =
    useUrlQueryParam(["openDeliveryDetailReScheduled"]);

  const open = () =>
    setopenDeliveryDetailReScheduled({ openDeliveryDetailReScheduled: true });
  const close = () =>
    setopenDeliveryDetailReScheduled({
      openDeliveryDetailReScheduled: undefined,
    });

  return [openDeliveryDetailReScheduled === "true", open, close] as const;
};
