import { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Radio,
  Button,
  Space,
  Spin,
  Tooltip,
  DatePicker,
  Select,
  Divider,
} from "antd";
import {
  ProTable,
  PageHeader,
  ActionType,
  FormInstance,
  ProColumns,
} from "@ant-design/pro-components";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { RangeValue, GetDate, useMount } from "utils";
import { BREAK_SUMMARY_STATISTICS_TYPE } from "../../interface";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { HosNodesType, BaseValueType } from "../../interface";
import { useHttp } from "utils/http";
import {
  GET_HOSP_NODES,
  GET_BREAK_SUMMARY_STATISTICS,
  GET_BREAK_PRODUCT_LINE_STATISTICS,
} from "lib/api";
import { useAsync } from "utils/hooks/useAsync";
import { useDebounceFn } from "ahooks";
import { MyBarChart } from "../../chart/bar-chart";
import { MyBarChartType } from "../../chart/bar-chart";
import { GetProductLineBarNum } from "../break-info-stat/utils/break-bar";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const BarCharts = () => {
  const client = useHttp();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const [tabName, setTabName] = useState<number>(1);
  const [tableTitle, setTableTitle] = useState<string>();
  const [queryDateRange, setQueryDateRange] = useState<RangeValue>();
  const [prevQueryDateRange, setPrevQueryDateRange] = useState<RangeValue>();

  const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [searchAreaID, setSearchAreaID] = useState<number | null>();
  const [prevSearchAreaID, setPrevSearchAreaID] = useState<number | null>();

  const [lightButton, setLightButton] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [productLineNum, setProductLineNum] = useState<MyBarChartType>();
  const currentWeekStr = GetDate("week")?.toString();
  const currentYearsStr = GetDate("year")?.toString();
  const currentMonthStr = GetDate("month")?.toString();
  const lastSixMonthStr = GetDate("last_six_month")?.toString();

  const BreaklistColumns: ProColumns<BREAK_SUMMARY_STATISTICS_TYPE>[] = [
    {
      title: `${tableTitle}工单数量统计Top10`, // Dynamically set the title
      children: [
        {
          dataIndex: "index",
          valueType: "indexBorder",
          width: 15,
        },
        { title: "客户名称", dataIndex: "customer_name" },
        { title: "区域", dataIndex: "area_name", width: 50 },
        { title: "数量", dataIndex: "count", width: 50 },
      ],
    },
  ];

  const search = (params: any) => {
    if (queryDateRange) {
      const start_date = queryDateRange[0]?.format("YYYY-MM-DD");
      const end_date = queryDateRange[1]?.format("YYYY-MM-DD");
      const queryParams = {
        start_date: start_date,
        end_date: end_date,
        area_id: searchAreaID,
        type: tabName,
      };
      return client(GET_BREAK_SUMMARY_STATISTICS, {
        data: { ...queryParams, ...params },
      });
    }
  };

  const {
    run: getHosNodes,
    isLoading: gettingNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };
  const { run: getHosNodesDebounce } = useDebounceFn(getHosNodesHandle, {
    wait: 200,
  });

  const {
    run: getBreakProductLineData,
    isLoading: gettingBreakProductLineData,
    data: BreakProductLineData,
  } = useAsync<BaseValueType[]>();
  const getgetBreakProductLineDataHanle = () => {
    if (queryDateRange) {
      const start_date = queryDateRange[0]?.format("YYYY-MM-DD");
      const end_date = queryDateRange[1]?.format("YYYY-MM-DD");
      const params = {
        start_date: start_date,
        end_date: end_date,
        area_id: searchAreaID,
      };
      getBreakProductLineData(
        client(GET_BREAK_PRODUCT_LINE_STATISTICS, {
          data: params,
        }),
      );
    }
  };
  const { run: getBreakProductLineDataDebounce } = useDebounceFn(
    getgetBreakProductLineDataHanle,
    {
      wait: 200,
    },
  );

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.nodeLevel === 1 && item.nodeName !== "海外") {
        areaListTMP?.push(item);
      }
    });
    setAreaList(areaListTMP);
  }, [hosNodes]);

  useEffect(() => {
    if (queryDateRange) {
      const queryDateRangeStr = queryDateRange?.toString();
      if (queryDateRangeStr === currentWeekStr) {
        setLightButton("week");
      } else if (queryDateRangeStr === currentYearsStr) {
        setLightButton("year");
      } else if (queryDateRangeStr === currentMonthStr) {
        setLightButton("month");
      } else if (queryDateRangeStr === lastSixMonthStr) {
        setLightButton("last_six_month");
      } else {
        setLightButton("");
      }
    }
  }, [queryDateRange]);

  useEffect(() => {
    switch (tabName) {
      case 1:
        setTableTitle("使用故障");
        break;
      case 2:
        setTableTitle("使用需求");
        break;
      case 3:
        setTableTitle("日常服务");
        break;
      case 4:
        setTableTitle("升级SRD");
        break;
      case 5:
        setTableTitle("升级研发");
        break;
    }
  }, [tabName]);

  useEffect(() => {
    setPage(1);
    actionRef.current?.reload();
    if (
      queryDateRange !== prevQueryDateRange ||
      searchAreaID !== prevSearchAreaID
    ) {
      getBreakProductLineDataDebounce();
    }
    setPrevQueryDateRange(queryDateRange);
    setPrevSearchAreaID(searchAreaID);
  }, [queryDateRange, searchAreaID, tabName]);

  useEffect(() => {
    if (BreakProductLineData) {
      setProductLineNum(GetProductLineBarNum(BreakProductLineData));
    }
  }, [BreakProductLineData]);

  useEffect(() => {
    setQueryDateRange(GetDate("last_six_month"));
  }, []);

  useMount(() => {
    getHosNodesDebounce();
  });

  return (
    <>
      <Row
        style={{
          minWidth: 988,
        }}
      >
        <Col span={24}>
          <PageHeader>
            <Row>
              <Col span={10}>
                <Space>
                  <Radio.Group
                    onChange={(e) => {
                      setTabName(e.target.value);
                    }}
                    defaultValue={tabName}
                    buttonStyle="solid"
                  >
                    <Radio.Button value={1}>使用故障</Radio.Button>
                    <Radio.Button value={2}>使用需求</Radio.Button>
                    <Radio.Button value={3}>日常服务</Radio.Button>
                    <Radio.Button value={4}>升级SRD</Radio.Button>
                    <Radio.Button value={5}>升级研发</Radio.Button>
                  </Radio.Group>
                </Space>
              </Col>
              <Col span={14} style={{ textAlign: "right" }}>
                <Space wrap>
                  <Button
                    size="small"
                    type={lightButton === "week" ? "link" : "text"}
                    style={{ padding: "1px" }}
                    onClick={() => {
                      setQueryDateRange(GetDate("week"));
                    }}
                  >
                    本周
                  </Button>
                  <Tooltip title="工单创建时间 = 本月1日到本月末.">
                    <Button
                      size="small"
                      type={lightButton === "month" ? "link" : "text"}
                      style={{ padding: "1px" }}
                      onClick={() => {
                        setQueryDateRange(GetDate("month"));
                      }}
                    >
                      本月
                    </Button>
                  </Tooltip>
                  <Tooltip title="工单创建时间 = 本年1月1日到本年12月31日.">
                    <Button
                      size="small"
                      type={lightButton === "year" ? "link" : "text"}
                      style={{ padding: "1px" }}
                      onClick={() => {
                        setQueryDateRange(GetDate("year"));
                      }}
                    >
                      本年
                    </Button>
                  </Tooltip>
                  <Button
                    size="small"
                    style={{ padding: "1px" }}
                    type={lightButton === "last_six_month" ? "link" : "text"}
                    onClick={() => {
                      setQueryDateRange(GetDate("last_six_month"));
                    }}
                  >
                    最近半年
                  </Button>
                  <RangePicker
                    style={{
                      minWidth: "250px",
                    }}
                    allowClear={false}
                    onChange={(val) => {
                      setQueryDateRange(val);
                    }}
                    value={queryDateRange}
                    picker="date"
                  />
                  <Select
                    allowClear
                    placeholder="区域"
                    disabled={gettingNodes}
                    loading={gettingNodes}
                    value={searchAreaID}
                    onChange={(nodeId) => {
                      setSearchAreaID(nodeId);
                    }}
                  >
                    {areaList?.map((item, index) => (
                      <Option key={index} value={item.nodeId}>
                        {item.nodeName}
                      </Option>
                    ))}
                  </Select>
                </Space>
              </Col>
            </Row>
          </PageHeader>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col
          span={11}
          style={{
            borderRight: "1px solid #ddd",
          }}
        >
          <div style={{ height: "100%" }}>
            <ProTable<BREAK_SUMMARY_STATISTICS_TYPE>
              style={{
                minHeight: "400px",
              }}
              defaultSize="small"
              rowKey="id"
              actionRef={actionRef}
              formRef={formRef}
              bordered={false}
              columns={BreaklistColumns}
              request={async (params) => {
                try {
                  const res = await search(params);
                  return {
                    data: res,
                    success: true,
                  };
                } catch (e) {
                  return { success: false };
                }
              }}
              search={false}
              pagination={false}
              size="small"
              options={false}
            />
          </div>
        </Col>
        <Col span={13} style={{ minHeight: "450px" }}>
          <Spin spinning={gettingBreakProductLineData}>
            <Row style={{ maxHeight: "20px", width: "100%" }}>
              <Tooltip
                title={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: `使用故障工单产品分布统计柱状图:<br />
                 支持选择时间与区域进行查询.<br />`,
                    }}
                  />
                }
              >
                <span
                  style={{
                    marginLeft: "6px",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  使用故障工单总量产品分布统计图
                  <QuestionCircleOutlined />
                </span>
              </Tooltip>
            </Row>
            <Row style={{ minHeight: 430, width: "100%", padding: "15px" }}>
              <Col span={24}>
                <MyBarChart Data={productLineNum} />
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
      <Divider
        style={{
          borderTop: "1px dashed grey ",
        }}
      />
    </>
  );
};
