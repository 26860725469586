import { Toast } from "antd-mobile";
import { RcFile } from "antd/lib/upload/interface";
import dayjs from "dayjs";

import { fileSize } from "utils";
import { ToastContent } from "./components/ToastContent";
import { PickerValue } from "antd-mobile/es/components/picker";
import { PickerDate } from "antd-mobile/es/components/date-picker/util";

export const PrimaryColor = "var(--adm-color-primary)"; // var(--adm-color-primary)
export const SuccessColor = "#00b578"; // var(--adm-color-success)
export const WarningColor = "#ff8f1f"; // var(--adm-color-warning)
export const DangerColor = "#ff3141"; // var(--adm-color-danger)

export const ListRightIconSize = 21;
export const SwipeActionIconSize = 20;

export const ImageFormatList = ["jpg", "jpeg", "png", "gif", "bmp"];

export const isImageFormat = (fileName: string) => {
  let index = fileName.lastIndexOf(".");
  if (index < 0) {
    return false;
  } else {
    let ext = fileName.substring(index + 1).toLowerCase();
    return ImageFormatList.some((i) => i === ext);
  }
};

export const MobileLimitUpload = (file: RcFile) => {
  const isLt500M = file.size <= fileSize;
  if (!isLt500M) {
    Toast.show({
      icon: "fail",
      content: <ToastContent content={"文件大小超出500M，无法上传"} />,
    });
    return false;
  } else {
    return true;
  }
};

export const MobileLimitUploadImage = (file: RcFile) => {
  const isLt500M = file.size <= fileSize;
  if (file.type.startsWith("image/")) {
    if (!isLt500M) {
      Toast.show({
        icon: "fail",
        content: <ToastContent content={"文件大小超出500M，无法上传"} />,
      });
      return false;
    } else {
      return true;
    }
  } else {
    Toast.show({
      icon: "fail",
      content: <ToastContent content={"非图片文件无法上传"} />,
    });
    return false;
  }
};

export const ConvertToPickerValueList = (value: any) => {
  if (value === null || value === undefined) return [];
  return [value.toString() as PickerValue];
};

export const ConvertDateToPickerValueList = (
  date: string | null | undefined,
) => {
  if (date === null || date === undefined) return undefined;
  return dayjs(date).toDate() as PickerDate;
};
