import {
  RefObject,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";
import {
  Button,
  Form,
  Picker,
  Selector,
  Space,
  TextArea,
  Toast,
  Radio,
  Skeleton,
  CascadePicker,
  Divider,
  Grid,
  Tag,
  SearchBar,
  Input,
  Card,
  CheckList,
  Popup,
  ErrorBlock,
} from "antd-mobile";

import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";
import { useDebounceFn, useSafeState } from "ahooks";
import { Upload, UploadFile } from "antd";
import { AddOutline, DeleteOutline } from "antd-mobile-icons";
import ReactQuill from "react-quill";
import { mobileQuillToolbarOption } from "authenticated-app/mobile/components/MobileQuill/option";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { ConvertToPickerValueList } from "authenticated-app/mobile/util";
import {
  DisposeForm,
  DisposeQuill,
  initEditUploadFileList,
  handleUpload,
} from "utils";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { ADD_FILE, GET_PRODUCT_DETAIL } from "lib/api";
import { ProductInfoType } from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import {
  GET_ISSUES_TYPE,
  UPDATE_BREAK_DETAIL,
  GET_ISSUES_REASON_NEW,
} from "lib/api/issues";
import {
  GET_PRODUCT_DETAIL_HAS_NOTONLINE,
  GET_PRODUCT_VERSION_BY_ID,
  GET_PRODUCT_LIST,
  GET_LABEL_LIST,
  UPDATE_ISSUES_LAEL,
  ADD_LABEL,
} from "lib/api";
import {
  BREAKLIST_OPTION_TYPE,
  BREAK_DETAIL_TYPE,
} from "lib/interface/breaklist";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { MobileLimitUpload } from "authenticated-app/mobile/util";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { UrlRegex } from "utils/regexp";
import { trimStr } from "utils";
import { SelectPopup } from "authenticated-app/mobile/components/SelectPopup";
import { GET_ORDER_SEARCH } from "lib/api/deliverylist";

export const MobileEditBreakListPopup = ({
  editingItem,
  visible,
  setVisible,
  reload,
}: {
  editingItem: BREAK_DETAIL_TYPE | undefined | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const [form] = Form.useForm();
  const client = useHttp();
  const { run: update, isLoading: submitting } = useAsync();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectedIssuesTypeId, setSelectedIssuesTypeId] = useState<number>();
  const [searchLabelName, setSearchLabelName] = useState<string>("");
  const [selectedLabels, setSelectedLabels] = useState<any[]>([]);
  const [labelIsChange, setLabelIsChange] = useState<boolean>(false);
  const [searchEffectProduct, setSearchEffectProduct] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<Options[]>([]);
  // const [labelUpdate, setLabelUpdate] = useState<boolean>(true);
  interface Options {
    value: string;
    label: string;
    children?: Options[];
    isLeaf?: boolean;
  }

  const {
    run: getIssuesTypeList,
    data: issuesTypeList,
    isIdle: getIssuesTypeListIsIdle,
    isLoading: gettingIssuesTypeList,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesTypeListHandle = () => {
    getIssuesTypeList(client(GET_ISSUES_TYPE));
  };
  const { run: getIssuesTypeListDebounce } = useDebounceFn(
    getIssuesTypeListHandle,
    {
      wait: 200,
    },
  );
  const {
    run: getIssuesReasonList,
    data: issuesReasonList,
    isIdle: getIssuesReasonListIsIdle,
    isLoading: gettingIssuesReasonList,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesReasonListHandle = () => {
    if (selectedIssuesTypeId) {
      getIssuesReasonList(client(GET_ISSUES_REASON_NEW(selectedIssuesTypeId)));
    }
  };
  const { run: getIssuesReasonListDebounce } = useDebounceFn(
    getIssuesReasonListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getProductList,
    isIdle: getProductListIsIdle,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductInfoType[]>();

  const getProductListHandle = () => {
    if (hospital_id) {
      getProductList(client(GET_PRODUCT_DETAIL(hospital_id)));
    }
  };
  const { run: getProductListDebounce } = useDebounceFn(getProductListHandle, {
    wait: 200,
  });

  const { hospital_id } = editingItem || {};

  const fetchFirstLevelData = async (hospID: number | any) => {
    try {
      setProductLoading(true);
      let firstLevelData: any[] = [];
      if (hospID) {
        firstLevelData = await client(GET_PRODUCT_DETAIL_HAS_NOTONLINE(hospID));
      } else {
        firstLevelData = await client(GET_PRODUCT_LIST);
      }
      const formattedData = await fetchSecondLevelData(firstLevelData);
      let defaultSelectedValues: any[] = [];
      if (editingItem?.product_list) {
        defaultSelectedValues = editingItem.product_list.map((item: any) => ({
          label: `${item.chinese_name} ${
            item.product_version_name || "无版本"
          }`,
          value: `${item.title}|${
            item.product_version_id ? item.product_version_id.toString() : ""
          }`,
        }));
      } else {
        defaultSelectedValues = [];
      }
      setOptions(formattedData);
      setSelectedValues(defaultSelectedValues);
      setProductLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSecondLevelData = async (firstLevelData: any[]) => {
    const formattedData: any[] = [];
    for (const item of firstLevelData) {
      const current_product_version_id = item.product_version_id;
      const product: Options = {
        value: item.product_id || item.title,
        label:
          item.product_chinese_name || item.chinese_name || item.title || "",
        children: [],
      };
      const product_id = item.product_id || item.title;
      const secondLevelData: any[] = await client(
        GET_PRODUCT_VERSION_BY_ID(product_id),
      );
      if (secondLevelData.length) {
        for (const version of secondLevelData) {
          if (product.children) {
            version.id.toString() === `${current_product_version_id}`
              ? product.children.unshift({
                  value: version.id.toString(),
                  label: `${version.name}`,
                })
              : product.children.push({
                  value: version.id.toString(),
                  label: `${version.name}`,
                });
          }
        }
      } else {
        if (product.children) {
          product.children.push({
            value: "",
            label: "无版本",
          });
        }
      }
      formattedData.push(product);
    }
    return formattedData;
  };

  const { run: updateLabel } = useAsync();
  const onUpdateLabel = (value: any) => {
    if (editingItem?.id) {
      updateLabel(
        client(UPDATE_ISSUES_LAEL(editingItem?.id), {
          method: "PUT",
          data: {
            ...value,
          },
        }),
      )
        .then((res) => {
          const content = `${res.order_id}更新成功!`;
        })
        .catch(() => {});
    }
  };

  const { run: addLabel, isLoading: addLoading } = useAsync();
  const onSubmitAddLable = (values: any) => {
    const label = trimStr(values.name);
    if (label) {
      if (labelList?.find((i: any) => i.name === label)) {
        Toast.show({
          icon: "fail",
          content: <ToastContent content={"该标签已存在，请勿重复添加"} />,
        });
      } else {
        addLabel(
          client(ADD_LABEL, {
            method: "POST",
            data: {
              ...values,
            },
          }),
        )
          .then((res) => {
            Toast.show({
              icon: "success",
              content: <ToastContent content={"新建标签成功！"} />,
              position: "top",
            });
            setSelectedLabels((prevSelectedLabels) => [
              ...prevSelectedLabels,
              res,
            ]);
            onUpdateLabel({
              label_id_list: [...selectedLabels.map((item) => item.id), res.id],
            });
            setLabelAddVisible(false);
          })
          .catch(() => {
            Toast.show({
              icon: "fail",
              content: <ToastContent content={"新建标签失败！"} />,
            });
          });
      }
    }
  };

  const onSubmit = (values: any) => {
    if (editingItem?.id) {
      const data = {
        // product_id_list: values.product_id_list || null,
        title: values?.title,
        description: values?.description || "",
        debug_info: values?.debug_info || "",
        is_deal_with_on_site: values?.is_deal_with_on_site ? 1 : 0,
        issue_type_id: values?.issue_type_id
          ? parseInt(values?.issue_type_id[0])
          : null,
        issue_reason_id: values?.issue_reason_id
          ? parseInt(values?.issue_reason_id[0])
          : null,
        attachment_id_list: fileList.map((i) => i.uid),
        knowledge_link: values.knowledge_link || null,
        is_known: values?.is_known || 0,
        is_temporary: values?.is_temporary || 0,
        is_on_site: values?.is_on_site ? 1 : 0,
        product_id_list: selectedValues.map((item) => [
          item.value.split("|")[0],
          item.value.split("|")[1],
        ]),
        similar_id_order_list: similarOrderSelected.map((item) => item.id),
      };
      const cleanedData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => {
          return value !== null && (!Array.isArray(value) || value.length > 0);
        }),
      );
      if (labelIsChange) {
        if (editingItem?.id) {
          update(
            client(UPDATE_ISSUES_LAEL(editingItem?.id), {
              method: "PUT",
              data: {
                label_id_list: selectedLabels.map((item) => item.id),
              },
            }),
          )
            .then((res) => {
              update(
                client(UPDATE_BREAK_DETAIL(editingItem?.id), {
                  method: "PUT",
                  data: cleanedData,
                }),
              )
                .then(() => {
                  Toast.show({
                    icon: "success",
                    content: <ToastContent content={"更新成功"} />,
                  });
                  setVisible(false);
                  reload();
                })
                .catch(() => {
                  Toast.show({
                    icon: "fail",
                    content: <ToastContent content={"更新失败"} />,
                  });
                });
            })
            .catch(() => {});
        }
      }
    }
  };

  const {
    run: getLabel,
    data: labelList,
    isIdle: getLabelIsIdle,
    isLoading: gettingLabelList,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getHospFunc = () => {
    getLabel(client(GET_LABEL_LIST));
  };
  const { run: getLabelDebounce } = useDebounceFn(getHospFunc, { wait: 1000 });

  const filteredItems = useMemo(() => {
    if (searchLabelName) {
      return labelList?.filter((item) => item.name?.includes(searchLabelName));
    } else {
      return labelList;
    }
  }, [labelList, searchLabelName]);

  const [loading, setLoding] = useState(true);
  const [productLoading, setProductLoading] = useState(true);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [productVisible, setProductVisible] = useState<boolean>(false);
  const [labelVisible, setLabelVisible] = useState<boolean>(false);
  const [labelAddVisible, setLabelAddVisible] = useState<boolean>(false);
  const [options, setOptions] = useState<Options[]>([]);
  const [similarOrderVisible, setSimilarOrderVisible] =
    useState<boolean>(false);
  const [similarOrderSelected, setSimilarOrderSelected] = useState<any[]>([]);

  useEffect(() => {
    form.setFieldsValue({ product_id_list: selectedValues });
  }, [selectedValues]);

  useEffect(() => {
    setLabelIsChange(true);
  }, [selectedLabels]);

  useEffect(() => {
    if (labelList) {
    }
  }, [labelList]);

  useEffect(() => {
    if (visible && editingItem) {
      form.resetFields();
      fetchFirstLevelData(hospital_id);
      getIssuesTypeListDebounce();
      getIssuesReasonListDebounce();
      getLabelDebounce();
      setSelectedIssuesTypeId(editingItem.issue_type_id);
      setSelectedLabels(editingItem.label_list);
      form.setFieldsValue({
        title: editingItem.title,
        description: editingItem.description || "",
        is_deal_with_on_site: editingItem.is_deal_with_on_site || 0,
        is_on_site: editingItem.is_on_site || 0,
        is_known: editingItem.is_known || 0,
        is_temporary: editingItem.is_temporary || 0,
        debug_info: editingItem.debug_info_dict.debug_info || "",
        issue_type_id: ConvertToPickerValueList(editingItem.issue_type_id),
        issue_reason_id: ConvertToPickerValueList(editingItem.issue_reason_id),
        knowledge_link: editingItem.knowledge_link || "",
      });
      setSimilarOrderSelected(editingItem.similar_order_list);
      let initList = initEditUploadFileList(editingItem.attachment_list);
      setFileList(initList || []);
      setLoding(false);
    }
  }, [visible, editingItem]);

  useEffect(() => {
    getIssuesReasonListDebounce();
  }, [selectedIssuesTypeId]);

  const filterOptions = (value: string) => {
    if (!value) {
      setFilteredOptions(options);
      return;
    }
    const filtered = options
      .map((item) => ({
        ...item,
        children: item.children?.filter((child) =>
          child.label.toLowerCase().includes(value.toLowerCase()),
        ),
      }))
      .filter((item) => item.children && item.children.length > 0);
    setFilteredOptions(filtered);
  };

  useEffect(() => {
    if (!!!productVisible) {
      return;
    }
    filterOptions(searchEffectProduct);
  }, [searchEffectProduct, productVisible]);

  return (
    <EditPopup
      visible={visible}
      submitting={submitting}
      setVisible={setVisible}
      reload={reload}
    >
      <LoadingMask visible={loading || submitting} />
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={submitting}
          >
            提交
          </Button>
        }
      >
        <Form.Header>编辑工单</Form.Header>
        <Form.Item
          name="title"
          label="工单标题"
          rules={[
            {
              required: true,
              message: "必须填写",
            },
          ]}
        >
          <TextArea placeholder="工单标题" autoSize={{ minRows: 1 }} />
        </Form.Item>
        <Form.Item
          name="product_id_list"
          label="影响产品"
          normalize={(v) => DisposeForm(v)}
          rules={
            editingItem?.customer_owner !== "其他"
              ? [{ required: true, message: "必须选择影响产品" }]
              : []
          }
          layout="vertical"
        >
          {productLoading ? (
            <Skeleton.Paragraph animated />
          ) : (
            <>
              {selectedValues.length ? (
                <>
                  <Space wrap>
                    <Button
                      color="primary"
                      fill="outline"
                      onClick={() => {
                        setProductVisible(true);
                      }}
                      size="small"
                    >
                      <Space>
                        <AddOutline />
                        <span>添加产品线</span>
                      </Space>
                    </Button>
                    <Button
                      color="danger"
                      fill="outline"
                      onClick={() => {
                        setSelectedValues([]);
                      }}
                      size="small"
                    >
                      <Space>
                        <DeleteOutline />
                        <span>全部清空</span>
                      </Space>
                    </Button>
                  </Space>
                  <Divider />
                  <Selector
                    style={{
                      wordBreak: "break-all",
                    }}
                    columns={3}
                    multiple
                    options={
                      selectedValues
                        ? selectedValues.map((item) => ({
                            label: (
                              <>
                                <Grid columns={1}>
                                  <Grid.Item>
                                    <span>{item.label}</span>
                                  </Grid.Item>
                                </Grid>
                                <Grid columns={1}>
                                  <Grid.Item style={{ textAlign: "center" }}>
                                    <Tag color="red" fill="outline">
                                      点击删除
                                    </Tag>
                                  </Grid.Item>
                                </Grid>
                              </>
                            ),
                            value: `${item.value}`,
                          }))
                        : []
                    }
                    onChange={(val, extend) => {
                      const currentValue: any[] = selectedValues.filter(
                        (item) => item.value !== val[val.length - 1],
                      );
                      setSelectedValues(currentValue);
                    }}
                  />
                </>
              ) : (
                <>
                  <Button
                    color="primary"
                    fill="outline"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setProductVisible(true);
                    }}
                    size="small"
                  >
                    <Space>
                      <AddOutline />
                      <span>添加产品线</span>
                    </Space>
                  </Button>
                </>
              )}
              <CascadePicker
                title={
                  <SearchBar
                    placeholder="搜索产品分支版本"
                    value={searchEffectProduct}
                    onChange={(v) => {
                      setSearchEffectProduct(v);
                    }}
                  />
                }
                options={filteredOptions}
                visible={productVisible}
                loading={productLoading}
                onClose={() => {
                  setProductVisible(false);
                }}
                onConfirm={(val, extend) => {
                  const currentValue = {
                    label: `${extend.items[0]?.label} ${
                      extend.items[1]?.label || ""
                    }`,
                    value: `${val[0]}|${val[1] || ""}`,
                  };
                  const cur_list: any[] = selectedValues.filter(
                    (item) => item.value.split("|")[0] !== `${val[0]}`,
                  );
                  cur_list.push(currentValue);
                  setSelectedValues(cur_list);
                }}
              />
            </>
          )}
        </Form.Item>
        <Form.Item
          name="label_id_list"
          label="标签"
          normalize={(v) => DisposeForm(v)}
          layout="vertical"
        >
          {gettingLabelList ? (
            <Skeleton.Paragraph animated />
          ) : (
            <>
              <Space wrap>
                <Button
                  key={"modify_label_button"}
                  color="primary"
                  fill="outline"
                  onClick={() => {
                    if (selectedLabels.length >= 5) {
                      Toast.show({
                        icon: "fail",
                        content: (
                          <ToastContent content={"最大支持添加5个标签！"} />
                        ),
                      });
                    } else {
                      setLabelVisible(true);
                    }
                  }}
                  size="small"
                >
                  <Space>
                    <AddOutline />
                    <span>选择标签</span>
                  </Space>
                </Button>
                <Button
                  key={"add_label_button"}
                  color="primary"
                  fill="outline"
                  onClick={() => {
                    if (selectedLabels.length >= 5) {
                      Toast.show({
                        icon: "fail",
                        content: (
                          <ToastContent content={"最大支持添加5个标签！"} />
                        ),
                      });
                    } else {
                      setLabelAddVisible(true);
                    }
                  }}
                  size="small"
                  loading={addLoading}
                >
                  <Space>
                    <AddOutline />
                    <span>新建标签</span>
                  </Space>
                </Button>
              </Space>
              <Divider />
              <Selector
                style={{
                  wordBreak: "break-all",
                }}
                showCheckMark={false}
                columns={3}
                multiple
                options={
                  selectedLabels
                    ? selectedLabels.map((item, index) => ({
                        label: (
                          <>
                            <Grid columns={1}>
                              <Grid.Item>
                                <span>{item.name}</span>
                              </Grid.Item>
                            </Grid>
                            <Grid columns={1}>
                              <Grid.Item style={{ textAlign: "center" }}>
                                <Tag
                                  color="red"
                                  fill="outline"
                                  key={`select_label_tag_${item.id}`}
                                >
                                  点击删除
                                </Tag>
                              </Grid.Item>
                            </Grid>
                          </>
                        ),
                        value: `${item.id}`,
                      }))
                    : []
                }
                onChange={(val, extend) => {
                  const currentValue = selectedLabels.filter(
                    (item) => item.id.toPrecision() !== val[val.length - 1],
                  );
                  setSelectedLabels(currentValue);
                }}
              />
              <Picker
                key={"lable_select_picker"}
                loading={gettingLabelList || getLabelIsIdle}
                visible={labelVisible}
                onClose={() => {
                  setLabelVisible(false);
                }}
                columns={[
                  filteredItems?.map((i: any) => ({
                    label: i.name,
                    value: i.id,
                  })) || [],
                ]}
                title={
                  <>
                    <SearchBar
                      placeholder="搜索标签"
                      value={searchLabelName}
                      onChange={(v) => {
                        setSearchLabelName(v);
                      }}
                    />
                  </>
                }
                onConfirm={(val, extend) => {
                  const label_id = val[val.length - 1];
                  if (selectedLabels?.find((i: any) => i.id === label_id)) {
                    Toast.show({
                      icon: "fail",
                      content: (
                        <ToastContent content={"该标签已存在，请勿重复添加"} />
                      ),
                    });
                  } else {
                    const currentLabelValue = labelList?.filter(
                      (item) => item.id === val[val.length - 1],
                    );
                    if (currentLabelValue) {
                      setSelectedLabels((prevSelectedLabels) => [
                        ...prevSelectedLabels,
                        ...currentLabelValue,
                      ]);
                    }
                  }
                }}
              />
              <EditPopup
                height={40}
                visible={labelAddVisible}
                submitting={submitting}
                setVisible={setLabelAddVisible}
                reload={reload}
              >
                <Card title={"新建标签"}>
                  <Form
                    onFinish={onSubmitAddLable}
                    layout="vertical"
                    footer={
                      <Button block color="primary" type="submit" size="middle">
                        提交
                      </Button>
                    }
                  >
                    <Form.Item
                      label="标签名称"
                      name="name"
                      rules={[
                        { required: true, message: "请输入要新建的标签" },
                        { whitespace: true, message: "标签名称不能全为空格" },
                        { max: 10, message: "标签名称不能超过10个字符" },
                      ]}
                    >
                      <Input placeholder="请输入要新建的标签" clearable />
                    </Form.Item>
                  </Form>
                </Card>
              </EditPopup>
            </>
          )}
        </Form.Item>

        <Form.Item name="similar_order_list" label="相似工单">
          <Space wrap>
            <span>已选择 {similarOrderSelected.length} 个相似工单</span>
            <Button
              size="small"
              color="primary"
              fill="outline"
              onClick={() => {
                setSimilarOrderVisible(true);
              }}
              // loading={HospitalOrderListIsIdle || gettingHospitalOrderList}
            >
              点击管理
            </Button>
          </Space>
        </Form.Item>
        <Form.Item
          name="description"
          label="反馈详情"
          rules={[{ required: true, message: "必须填写反馈详情" }]}
          normalize={(v) => DisposeQuill(v)}
          layout={"vertical"}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: mobileQuillToolbarOption,
            }}
          />
        </Form.Item>

        <Form.Item
          name="debug_info"
          label="定位及补充记录"
          normalize={(v) => DisposeQuill(v)}
          layout={"vertical"}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: mobileQuillToolbarOption,
            }}
          />
        </Form.Item>
        <Form.Item
          name="solution_info"
          label="操作及解决"
          normalize={(v) => DisposeQuill(v)}
          layout={"vertical"}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: mobileQuillToolbarOption,
            }}
          />
        </Form.Item>
        <Form.Item
          name="is_deal_with_on_site"
          label="是否现场处理"
          rules={[{ required: true, message: "必须选择是否现场处理" }]}
          initialValue={false}
        >
          <Radio.Group>
            <Space>
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="is_on_site"
          label="是否在现场"
          rules={[{ required: true, message: "必须选择是否在现场" }]}
          initialValue={false}
        >
          <Radio.Group>
            <Space>
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="is_temporary"
          label="是否临时解决"
          rules={[{ required: true, message: "必须选择是否临时解决" }]}
        >
          <Radio.Group>
            <Space>
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="is_known" label="是否已知问题">
          <Radio.Group>
            <Space>
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="knowledge_link"
          label="知识库链接"
          rules={[
            {
              pattern: UrlRegex(),
              message: "必须输入合法的URL格式",
            },
          ]}
        >
          <TextArea placeholder="填写知识库链接" autoSize={{ minRows: 1 }} />
        </Form.Item>
        <Form.Item
          name="issue_type_id"
          label="反馈类型"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          // rules={[{ required: true, message: "必须选择反馈类型" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
          layout="horizontal"
        >
          <Picker
            loading={gettingIssuesTypeList || getIssuesTypeListIsIdle}
            columns={
              issuesTypeList
                ? [
                    issuesTypeList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
            onConfirm={(v: any[]) => {
              if (v) {
                setSelectedIssuesTypeId(v[0]);
                form.setFieldValue("issue_reason_id", undefined);
              }
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择反馈类型</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="issue_reason_id"
          label="反馈归因"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
          layout="horizontal"
          disabled={!!!selectedIssuesTypeId}
          help={"请先选择反馈类型!"}
        >
          <Picker
            loading={gettingIssuesReasonList || getIssuesReasonListIsIdle}
            columns={
              issuesReasonList
                ? [
                    issuesReasonList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择反馈归因</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>

        <Form.Item
          name="attachment"
          className="adm-form-item-label-attachment"
          label="附件 (数量限制10个)"
          normalize={(v) => DisposeForm(v)}
          layout={"vertical"}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={10}
            multiple={true}
            action={ADD_FILE}
            listType="picture"
            fileList={fileList}
            beforeUpload={MobileLimitUpload}
            onChange={(info) => handleUpload(info, setFileList)}
          >
            {fileList.length >= 10 ? null : (
              <Button size="middle" color="primary" fill="outline">
                <Space>
                  <AddOutline />
                  <span>点击上传</span>
                </Space>
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
      <SelectPopup
        visible={similarOrderVisible}
        api={GET_ORDER_SEARCH}
        label={"相似工单"}
        searchLabel="工单号"
        setVisible={setSimilarOrderVisible}
        seleceted={similarOrderSelected}
        setSelected={setSimilarOrderSelected}
        height={80}
      />
    </EditPopup>
  );
};
