import {
  SetStateAction,
  Dispatch,
  useEffect,
  useState,
  useReducer,
} from "react";
import {
  Button,
  Modal,
  Form,
  Select,
  message,
  DatePicker,
  Divider,
  Table,
  InputNumber,
  Alert,
  Space,
  Result,
  Spin,
} from "antd";
import { UndoOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import type { ColumnsType } from "antd/es/table";

import { useHttp } from "utils/http";
import {
  DisabledDate,
  DisposeForm,
  formatDate,
  isNumber,
  productIdToName,
  THID,
} from "utils";
import { HospProductsListType } from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";
import { HospListType } from "interface";
import "../../index.css";
import { ADD_RATE_MANUALLY, GET_RATE } from "lib/api";
import { useAsync } from "utils/hooks/useAsync";
import { DateStep, RateType } from "../../interface";

const { Option } = Select;

interface DataType {
  key: string;
  start?: string;
  predict_num?: number;
  usv_num?: number;
  click_rate?: number;
}

const datePrefix = "start";
const predictPrefix = "predict_num";
const usvPrefix = "usv_num";

const thPredictPrefix = "thPredict_num";
const rc2Prefix = "rc2_num";
const accessPrefix = "access_num";
const downloadPrefix = "download_num";

export const RateImportManually = ({
  hospInfo,
  productList,
  defaultProduct,
  visible,
  setVisible,
  reload,
}: {
  hospInfo: HospListType | undefined;
  productList: HospProductsListType[];
  defaultProduct: string | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();

  const [form] = Form.useForm();
  const { resetFields, getFieldValue } = form;

  const [finalTime, setFinalTime] = useState<dayjs.Dayjs>(
    dayjs().subtract(1, "days"),
  );
  const [product, setProduct] = useState<string | null>(null);
  const [isTh, setIsTh] = useState<boolean>(false);

  const [warningClose, setWarningClose] = useState<boolean>(false);
  const [isSubSuccess, setIsSubSuccess] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);

  const [, notifyRender] = useReducer((x) => x + 1, 0);

  const { run: postRate, isLoading: posttingRate } = useAsync();

  const {
    run: getHospRate,
    isLoading: gettingHospRate,
    data: hospRate,
  } = useAsync<RateType>();
  const getHospRateHandle = ({ product_line }: { product_line?: string }) => {
    if (hospInfo) {
      getHospRate(
        client(GET_RATE, {
          data: {
            hospital_id: hospInfo.id,
            product_line: product_line || product,
            unit: DateStep.day,
            start:
              formatDate(finalTime.clone().subtract(13, "days")) ||
              formatDate(dayjs().subtract(13, "days")),
            end:
              formatDate(finalTime) || formatDate(dayjs().subtract(1, "days")),
          },
        }),
      ).then(() => {
        setLoadingForm(true);
        setTimeout(() => {
          initForm();
          setLoadingForm(false);
        }, 500);
      });
    } else {
      message.error("无法请求当前医院数据，请稍后重试或者联系管理员！");
    }
  };

  let dataSource = [];
  for (let i = 0; i < 14; i++) {
    dataSource.push({ key: String(i) });
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "日期",
      render: (_, __, index) => {
        return (
          <Form.Item
            key={`${datePrefix}-${index}`}
            style={{ marginBottom: 0 }}
            name={`${datePrefix}-${index}`}
            normalize={(v) => DisposeForm(v)}
            initialValue={finalTime.clone().subtract(index, "days")}
          >
            <DatePicker
              style={{ width: 120, marginLeft: -11 }}
              bordered={false}
              disabled
            />
          </Form.Item>
        );
      },
    },
    {
      title: "预测量",
      render: (_, __, index) => (
        <Form.Item
          key={`${predictPrefix}-${index}`}
          style={{ marginBottom: 0 }}
          name={`${predictPrefix}-${index}`}
          normalize={(v) => DisposeForm(v)}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  value !== "" &&
                  value < getFieldValue(`${usvPrefix}-${index}`)
                ) {
                  return Promise.reject(new Error("点击率不能大于100%"));
                }
                return Promise.resolve();
              },
            }),
          ]}
          initialValue={
            hospRate?.results.find(
              (i) =>
                i.date[0] ===
                formatDate(finalTime.clone().subtract(index, "days")),
            )?.predict_num
          }
        >
          <InputNumber
            min={0}
            style={{ width: 120 }}
            onChange={() => notifyRender()}
          />
        </Form.Item>
      ),
    },
    {
      title: "点击量(USV)",
      render: (_, __, index) => (
        <Form.Item
          key={`${usvPrefix}-${index}`}
          style={{ marginBottom: 0 }}
          name={`${usvPrefix}-${index}`}
          normalize={(v) => DisposeForm(v)}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  value !== "" &&
                  value > getFieldValue(`${predictPrefix}-${index}`)
                ) {
                  return Promise.reject(new Error("点击率不能大于100%"));
                }
                return Promise.resolve();
              },
            }),
          ]}
          initialValue={
            hospRate?.results.find(
              (i) =>
                i.date[0] ===
                formatDate(finalTime.clone().subtract(index, "days")),
            )?.usv_num
          }
        >
          <InputNumber
            min={0}
            style={{ width: 120 }}
            onChange={() => notifyRender()}
          />
        </Form.Item>
      ),
    },
    {
      title: "点击率",
      align: "center",
      render: (_, __, index) => {
        let rate =
          getFieldValue(`${predictPrefix}-${index}`) ||
          hospRate?.results.find(
            (i) =>
              i.date[0] ===
              formatDate(finalTime.clone().subtract(index, "days")),
          )?.predict_num
            ? (
                ((getFieldValue(`${usvPrefix}-${index}`) ||
                  hospRate?.results.find(
                    (i) =>
                      i.date[0] ===
                      formatDate(finalTime.clone().subtract(index, "days")),
                  )?.usv_num ||
                  0) /
                  (getFieldValue(`${predictPrefix}-${index}`) ||
                    hospRate?.results.find(
                      (i) =>
                        i.date[0] ===
                        formatDate(finalTime.clone().subtract(index, "days")),
                    )?.predict_num ||
                    0)) *
                100
              ).toFixed(2)
            : null;
        return (
          <span key={`rate-${index}}`}>
            {rate
              ? rate + "%"
              : hospRate?.results.find(
                  (i) =>
                    i.date[0] ===
                    formatDate(finalTime.clone().subtract(index, "days")),
                )?.click_rate}
          </span>
        );
      },
    },
  ];

  const thColumns: ColumnsType<DataType> = [
    {
      title: "日期",
      render: (_, __, index) => {
        return (
          <Form.Item
            key={`${datePrefix}-${index}`}
            style={{ marginBottom: 0 }}
            name={`${datePrefix}-${index}`}
            normalize={(v) => DisposeForm(v)}
            initialValue={finalTime.clone().subtract(index, "days")}
          >
            <DatePicker
              style={{ width: 120, marginLeft: -11 }}
              bordered={false}
              disabled
            />
          </Form.Item>
        );
      },
    },
    {
      title: "病例数",
      render: (_, __, index) => (
        <Form.Item
          key={`${thPredictPrefix}-${index}`}
          style={{ marginBottom: 0 }}
          name={`${thPredictPrefix}-${index}`}
          normalize={(v) => DisposeForm(v)}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  value !== "" &&
                  value < getFieldValue(`${rc2Prefix}-${index}`)
                ) {
                  return Promise.reject(new Error("病例数应大于重建数"));
                }
                return Promise.resolve();
              },
            }),
          ]}
          initialValue={
            hospRate?.results.find(
              (i) =>
                i.date[0] ===
                formatDate(finalTime.clone().subtract(index, "days")),
            )?.predict_num
          }
        >
          <InputNumber
            min={0}
            style={{ width: 120 }}
            onChange={() => notifyRender()}
          />
        </Form.Item>
      ),
    },
    {
      title: "重建数",
      render: (_, __, index) => (
        <Form.Item
          key={`${rc2Prefix}-${index}`}
          style={{ marginBottom: 0 }}
          name={`${rc2Prefix}-${index}`}
          normalize={(v) => DisposeForm(v)}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  value !== "" &&
                  value > getFieldValue(`${thPredictPrefix}-${index}`)
                ) {
                  return Promise.reject(new Error("重建数不能大于病例数"));
                }
                return Promise.resolve();
              },
            }),
          ]}
          initialValue={
            hospRate?.results.find(
              (i) =>
                i.date[0] ===
                formatDate(finalTime.clone().subtract(index, "days")),
            )?.rc2_num
          }
        >
          <InputNumber
            min={0}
            style={{ width: 120 }}
            onChange={() => notifyRender()}
          />
        </Form.Item>
      ),
    },
    {
      title: "访问数",
      render: (_, __, index) => (
        <Form.Item
          key={`${accessPrefix}-${index}`}
          style={{ marginBottom: 0 }}
          name={`${accessPrefix}-${index}`}
          normalize={(v) => DisposeForm(v)}
          initialValue={
            hospRate?.results.find(
              (i) =>
                i.date[0] ===
                formatDate(finalTime.clone().subtract(index, "days")),
            )?.access_num
          }
        >
          <InputNumber
            min={0}
            style={{ width: 120 }}
            onChange={() => notifyRender()}
          />
        </Form.Item>
      ),
    },
    {
      title: "报告下载数",
      render: (_, __, index) => (
        <Form.Item
          key={`${downloadPrefix}-${index}`}
          style={{ marginBottom: 0 }}
          name={`${downloadPrefix}-${index}`}
          normalize={(v) => DisposeForm(v)}
          initialValue={
            hospRate?.results.find(
              (i) =>
                i.date[0] ===
                formatDate(finalTime.clone().subtract(index, "days")),
            )?.download_num
          }
        >
          <InputNumber
            min={0}
            style={{ width: 120 }}
            onChange={() => notifyRender()}
          />
        </Form.Item>
      ),
    },
  ];

  const submit = (value: any) => {
    let data = [];
    try {
      for (let i = 0; i < 14; i++) {
        if (isTh) {
          if (
            isNumber(value[`${thPredictPrefix}-${i}`]) &&
            isNumber(value[`${rc2Prefix}-${i}`]) &&
            isNumber(value[`${accessPrefix}-${i}`]) &&
            isNumber(value[`${downloadPrefix}-${i}`])
          ) {
            data.push({
              start: formatDate(value[`${datePrefix}-${i}`]),
              predict_num: value[`${thPredictPrefix}-${i}`],
              rc2_num: value[`${rc2Prefix}-${i}`],
              access_num: value[`${accessPrefix}-${i}`],
              download_num: value[`${downloadPrefix}-${i}`],
            });
          }
        } else {
          if (
            isNumber(value[`${predictPrefix}-${i}`]) &&
            isNumber(value[`${usvPrefix}-${i}`])
          ) {
            data.push({
              start: formatDate(value[`${datePrefix}-${i}`]),
              predict_num: value[`${predictPrefix}-${i}`],
              usv_num: value[`${usvPrefix}-${i}`],
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
      message.error("无法提交，导入数据格式化失败");
      throw new Error("导入数据格式化失败");
    }
    if (hospInfo) {
      postRate(
        client(ADD_RATE_MANUALLY, {
          data: {
            product_line: product,
            hospital_id: hospInfo.id,
            click_rate: data,
          },
          method: "POST",
        }),
      ).then(() => setIsSubSuccess(true));
    } else message.error("无法提交，请检查或联系管理员！");
  };

  const initForm = () => {
    resetFields();
    notifyRender();
  };

  useEffect(() => {
    if (product === THID) {
      setIsTh(true);
    } else {
      setIsTh(false);
    }

    if (product) {
      getHospRateHandle({ product_line: product });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (product) {
      getHospRateHandle({ product_line: product });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalTime]);

  useEffect(() => {
    if (visible) {
      resetFields();
      if (defaultProduct === THID) {
        setIsTh(true);
      } else {
        setIsTh(false);
      }
      setProduct(defaultProduct);
      setWarningClose(false);
      setIsSubSuccess(false);
      getHospRateHandle({ product_line: defaultProduct || undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={`${hospInfo?.name}点击率导入`}
      open={visible}
      destroyOnClose
      maskClosable={false}
      onCancel={() => setVisible(false)}
      width={"61.80%"}
      okText={"提交"}
      okButtonProps={{ htmlType: "submit" }}
      onOk={() => form.submit()}
      footer={isSubSuccess ? null : undefined}
      closable={!isSubSuccess}
      style={{ top: 0 }}
    >
      {isSubSuccess ? (
        <Result
          status="success"
          title="提交成功"
          subTitle={`${hospInfo?.name}: ${formatDate(
            finalTime.clone().subtract(13, "days"),
          )}至${formatDate(finalTime)}日期段《${
            productIdToName.get(product || "unknown") || "未知产品线"
          }产品》点击率`}
          extra={[
            <Button
              key="subAgain"
              type="primary"
              onClick={() => {
                getHospRateHandle({});
                setIsSubSuccess(false);
              }}
            >
              再次填写
            </Button>,
            <Button
              key="backCharts"
              onClick={() => {
                reload();
                setVisible(false);
              }}
            >
              返回图表
            </Button>,
          ]}
        />
      ) : (
        <Spin spinning={posttingRate || gettingHospRate || loadingForm}>
          <Space>
            <span>最终日期:</span>
            <DatePicker
              allowClear={false}
              disabledDate={DisabledDate}
              value={finalTime}
              style={{ width: 120 }}
              onChange={(v) => {
                if (v) {
                  setFinalTime(v);
                }
              }}
            />
            &nbsp;
            <span>导入产品线:</span>
            <Select
              placeholder="选择产品线"
              style={{ width: 220 }}
              value={product}
              onChange={(v) => setProduct(v)}
            >
              {productList?.map((item, index) => (
                <Option key={index} value={item?.product_id}>
                  {item?.product_id}
                </Option>
              ))}
            </Select>
          </Space>
          <Button
            danger
            icon={<UndoOutlined />}
            onClick={() => getHospRateHandle({})}
            style={{ float: "right" }}
          >
            重置
          </Button>
          <Divider />
          <Alert
            type="warning"
            showIcon
            closable
            onClose={() => setWarningClose(true)}
            message="友情提示：请先确定导入的“最终日期”再填写数据，变更日期会重置数据。导入日期段为最终日期前14天。"
          />
          {warningClose ? null : <Divider />}
          <Form id="rateManuallyForm" form={form} onFinish={submit}>
            <Table
              pagination={false}
              columns={isTh ? thColumns : columns}
              dataSource={dataSource}
              rowKey={"key"}
              size={"small"}
            />
          </Form>
        </Spin>
      )}
    </Modal>
  );
};
