import { Space, Spin, theme } from "antd";

import logo from "./logo/logo.png";

import "./index.css";

const { useToken } = theme;

const LoadingPage = () => {
  const {
    token: { colorBgContainer, colorText },
  } = useToken();

  return (
    <div
      className="loading-page"
      style={{ backgroundColor: colorBgContainer, color: colorText }}
    >
      <Space className="loading-page-space" direction="vertical">
        <img src={logo} alt="" width="256" />
        <Spin size="large" style={{ margin: "4rem 0" }} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "4rem",
          }}
        >
          InferVision
        </div>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1rem",
          }}
        >
          With A.I.,We Improve Human Life
        </span>
      </Space>
    </div>
  );
};

export default LoadingPage;
