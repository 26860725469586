import { Popup } from "antd-mobile";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { LoadingMask } from "../LoadingMask";

export const EditPopup = ({
  children,
  visible,
  isProductModify = false,
  submitting,
  setVisible,
  reload = () => {},
  height = 90,
}: {
  children: ReactNode;
  visible: boolean;
  isProductModify?: boolean;
  submitting: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload?: () => void;
  height?: number;
}) => {
  const closer = () =>
    !submitting
      ? isProductModify
        ? () => {
            reload();
            setVisible(false);
          }
        : () => setVisible(false)
      : undefined;

  return (
    <Popup
      visible={visible}
      destroyOnClose
      bodyStyle={{ height: `${height}vh`, overflowY: "scroll" }}
      onMaskClick={closer()}
      showCloseButton={!submitting}
      onClose={closer()}
    >
      <LoadingMask visible={submitting} />
      {children}
    </Popup>
  );
};
