import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { Descriptions, message, Tabs, Modal } from "antd";
import {
  EditOutlined,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import { useHttp } from "utils/http";
import { DEL_HARDWARE_MATTER } from "lib/api";
import { HardwareDesItemLabelMin, LineDiv, SingleImageList } from "../../utils";
import { HardwaresMatterInfos } from "../interface";
import { MatterInfoEdit } from "./edit";
import { MatterInfoAdd } from "./add";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

const MatterInfo = ({
  matterInfo,
  hospID,
  reload,
  setDisChange,
  changeCount,
}: {
  matterInfo: HardwaresMatterInfos[] | undefined | null;
  hospID: number | null;
  reload: () => void;
  setDisChange: Dispatch<SetStateAction<boolean>>;
  changeCount: any;
}) => {
  const [{ tab, server, matter }, setMatter] = useUrlQueryParam([
    "tab",
    "server",
    "matter",
  ]);

  const [edittingID, setEdittingID] = useState<string | null>(null);
  const [matterIsEditable, setMatterIsEditable] = useState(false);
  const [addMatterVisible, setAddMatterVisible] = useState(false);

  const client = useHttp();

  const onEdit = (
    targetKey:
      | string
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>,
    action: "add" | "remove",
  ) => {
    if (action === "add") {
      setAddMatterVisible(true);
    } else {
      setDisChange(true);
      setEdittingID(targetKey as string);
      if (!matterIsEditable) {
        setMatterIsEditable(true);
      }
    }
  };

  const delMatter = (id: number, name: string) => {
    Modal.confirm({
      title: "删除确认",
      content: `确认删除【${name}】的信息？`,
      okText: "删除",
      cancelText: "取消",
      okButtonProps: { type: "primary", danger: true },
      closable: true,
      icon: <ExclamationCircleFilled style={{ color: "#f5222d" }} />,
      onOk: () =>
        client(DEL_HARDWARE_MATTER(id), { method: "DELETE" })
          .then(() => {
            message.success("删除成功！");
            setMatterIsEditable(false);
            setDisChange(false);
            Modal.destroyAll();
            reload();
          })
          .catch((e) => {
            console.error(e);
            message.error("删除失败，请联系管理员！");
          }),
    });
  };

  useEffect(() => {
    if (server && server !== "undefined") {
      if (tab === "hardware" && edittingID) {
        let index = matterInfo?.findIndex(
          (d) => d.id.toString() === edittingID,
        );
        if (index !== undefined && index >= 0 && matterInfo) {
          setMatter({ matter: edittingID });
        } else {
          if (matterInfo && matterInfo[0]) {
            let settingID = matterInfo[0].id.toString();
            setEdittingID(settingID);
            setMatter({ matter: settingID });
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCount, edittingID, server]);

  useEffect(() => {
    if (matter) {
      let index = matterInfo?.findIndex((d) => d.id.toString() === matter);
      if (index !== undefined && index >= 0 && matterInfo) {
        setEdittingID(matter);
      } else {
        if (matterInfo && matterInfo[0]) {
          setEdittingID(matterInfo[0].id.toString());
        }
      }
    } else if (matterInfo && matterInfo[0]) {
      setEdittingID(matterInfo[0].id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matterInfo]);

  return (
    <Fragment>
      <Descriptions column={1} title={"其它物料信息"}>
        <Descriptions.Item>
          <Tabs
            type="editable-card"
            onEdit={onEdit}
            activeKey={edittingID || undefined}
            hideAdd={matterIsEditable}
            onTabClick={(v) => {
              setEdittingID(v);
              setMatter({ matter: v });
            }}
            items={matterInfo?.map((i) => ({
              disabled: edittingID !== i.id.toString() && matterIsEditable,
              key: i.id.toString(),
              closable: edittingID === i.id.toString(),
              label: `${i.eqpt_type_name}-${i.eqpt_oem_name}`,
              closeIcon:
                matterIsEditable && i.id.toString() === edittingID ? (
                  <CloseCircleFilled
                    style={{ color: "#f5222d" }}
                    onClick={() => delMatter(i.id, i.eqpt_type_name)}
                  />
                ) : (
                  <EditOutlined />
                ),
              children:
                matterIsEditable && i.id.toString() === edittingID ? (
                  <MatterInfoEdit
                    editingItem={i}
                    editable={matterIsEditable}
                    setEditable={setMatterIsEditable}
                    setDisChange={setDisChange}
                    reload={reload}
                  />
                ) : (
                  <Descriptions column={2}>
                    <Descriptions.Item
                      label={<HardwareDesItemLabelMin label={"设备类型"} />}
                    >
                      <LineDiv>{i.eqpt_type_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<HardwareDesItemLabelMin label={"物料IP"} />}
                    >
                      <LineDiv>{i.ip}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<HardwareDesItemLabelMin label={"设备品牌"} />}
                    >
                      <LineDiv>{i.eqpt_oem_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<HardwareDesItemLabelMin label={"维保代码"} />}
                    >
                      <LineDiv>{i.maintenance_code}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<HardwareDesItemLabelMin label={"设备型号"} />}
                    >
                      <LineDiv>{i.eqpt_model_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<HardwareDesItemLabelMin label={"资产编码"} />}
                    >
                      <LineDiv>{i.assets_code}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<HardwareDesItemLabelMin label={"应用说明"} />}
                    >
                      <LineDiv>{i.description}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<HardwareDesItemLabelMin label={"资产照片"} />}
                    >
                      <SingleImageList imgList={i?.asset_photos} />
                    </Descriptions.Item>
                  </Descriptions>
                ),
            }))}
          />
        </Descriptions.Item>
      </Descriptions>
      <MatterInfoAdd
        hospID={hospID}
        visible={addMatterVisible}
        setVisible={setAddMatterVisible}
        reload={reload}
      />
    </Fragment>
  );
};

export default MatterInfo;
