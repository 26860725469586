import { SetStateAction, Dispatch, useEffect } from "react";
import { Button, Modal, Space, Form, Input, message } from "antd";

import { useHttp } from "utils/http";
import { DisposeForm } from "utils";
import { ResourceType } from "../interface";
import { ADD_RESOURCE_CLASS } from "../api";

export const ResourceClassAdd = ({
  resource,
  visible,
  setVisible,
  reload,
}: {
  resource: ResourceType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const submit = (value: any) => {
    client(ADD_RESOURCE_CLASS, {
      data: {
        ...value,
        resourceId: resource?.resourceId,
      },
      method: "POST",
    })
      .then(() => {
        message.success("添加成功！");
        setVisible(false);
        reload();
      })
      .catch((e) => {
        console.error(e);
        message.error("添加失败，请检查或联系管理员！");
      });
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={`新增资源分类(${resource?.resourceName})`}
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 19 }}
        onFinish={submit}
      >
        <Form.Item
          name="classficationName"
          label={`分类名称`}
          rules={[{ required: true, message: `必须输入分类名称` }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input placeholder={`例：base`} maxLength={50} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
