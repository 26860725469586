import { useState, Fragment } from "react";
import { Button, Descriptions, Space, message, Popconfirm, Image } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { ProTable, ProColumns } from "@ant-design/pro-components";

import { useHttp } from "utils/http";
import { DEL_GPU_INFO } from "lib/api";
import { GpuType } from "../interface";
import { GPUInfoEdit } from "./edit";
import { GPUInfoAdd } from "./add";

const GPUInfo = ({
  hospID,
  gpuInfo,
  hardware_id,
  reload,
}: {
  hospID: number | null;
  gpuInfo: GpuType[] | undefined | null;
  hardware_id: string | null;
  reload: () => void;
}) => {
  const client = useHttp();

  const [addGPUVisible, setAddGPUVisible] = useState(false);
  const [editGPUVisible, setEditGPUVisible] = useState(false);
  const [editGPUItem, setEditGPUItem] = useState<GpuType>();

  const delGPU = (id: number) => {
    client(DEL_GPU_INFO(id), { method: "DELETE" })
      .then(() => {
        message.success("删除成功！");
        reload();
      })
      .catch((e) => {
        console.error(e);
        message.error("删除失败，请联系管理员！");
      });
  };
  const columns: ProColumns<GpuType>[] = [
    {
      title: "卡槽编号",
      dataIndex: "slot_num",
      valueType: "digit",
    },
    {
      title: "显卡品牌",
      dataIndex: "gpu_oem_name",
    },
    {
      title: "显卡型号",
      dataIndex: "gpu_type_name",
    },
    {
      title: "资产编码",
      dataIndex: "assets_code",
    },
    {
      title: "UUID",
      dataIndex: "uuid",
    },
    {
      title: "资产照片",
      dataIndex: "asset_photos",
      render: (_, record) => (
        <Space>
          {record.asset_photos?.map((i, index) => (
            <div
              key={`${i.id}&${index}`}
              style={{
                height: 48,
                width: 48,
                border: "1px solid #d9d9d9",
                borderRadius: "2px",
                padding: "2px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image src={i.attachment_addr} />
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: "运行产品线",
      dataIndex: "products",
      render: (_, record) => (
        <Space>
          {record.products?.map((i) => i.product_id).join("、") || ""}
        </Space>
      ),
    },
    {
      title: "备注",
      dataIndex: "remark",
    },
    {
      title: "更换时间",
      dataIndex: "change_date",
      valueType: "date",
    },
    {
      title: "操作",
      valueType: "option",
      render: (text, item, _, action) => [
        <Space>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            loading={editGPUVisible && item.id === editGPUItem?.id}
            onClick={() => {
              setEditGPUItem(item);
              setEditGPUVisible(true);
            }}
          />
          <Popconfirm
            title={`确认删除显卡【${item.assets_code}】的信息？`}
            onConfirm={() => delGPU(item.id)}
            okText="确认"
            cancelText="取消"
            icon={<ExclamationCircleOutlined />}
            style={{ color: "red" }}
          >
            <Button
              danger
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>,
      ],
    },
  ];

  const GPUDescItem = (item: GpuType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");

    return (
      <Fragment key={`gpuDescItem${key}`}>
        <Descriptions.Item label={title("卡槽编号")} className={className()}>
          {Number.isFinite(item?.slot_num) ? item?.slot_num : ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("显卡品牌")} className={className()}>
          {item?.gpu_oem_name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("显卡型号")} className={className()}>
          {item?.gpu_type_name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("资产编码")} className={className()}>
          {item?.assets_code || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("UUID")} className={className()}>
          {item?.uuid || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("资产照片")} className={className()}>
          <Space>
            {item?.asset_photos?.map((i, index) => (
              <div
                key={`${i.id}&${index}`}
                style={{
                  height: 48,
                  width: 48,
                  border: "1px solid #d9d9d9",
                  borderRadius: "2px",
                  padding: "2px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image src={i.attachment_addr} />
              </div>
            ))}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label={title("运行产品线")} className={className()}>
          {item?.products.map((i) => i.product_id).join("、") || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("备注")} className={className()}>
          {item?.remark || ""}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("更换时间")}
          className={className()}
        >
          {item?.change_date || ""}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("操作")}
          className={className()}
        >
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              loading={editGPUVisible && item.id === editGPUItem?.id}
              onClick={() => {
                setEditGPUItem(item);
                setEditGPUVisible(true);
              }}
            />
            <Popconfirm
              title={`确认删除显卡【${item.assets_code}】的信息？`}
              onConfirm={() => delGPU(item.id)}
              okText="确认"
              cancelText="取消"
              icon={<ExclamationCircleOutlined />}
              style={{ color: "red" }}
            >
              <Button
                danger
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <ProTable<GpuType>
        size="small"
        bordered
        columns={columns}
        rowKey="id"
        dataSource={gpuInfo || []}
        pagination={false}
        search={false}
        dateFormatter="string"
        options={{
          reload: reload,
        }}
        toolbar={{
          title: <span style={{ fontWeight: "bold" }}>显卡信息</span>,
          actions: [
            <Button
              key="add"
              type="default"
              icon={<PlusOutlined />}
              onClick={() => setAddGPUVisible(true)}
            >
              新增
            </Button>,
          ],
          settings: [],
        }}
      />
      {/* <Descriptions
        column={10}
        bordered={gpuInfo?.length ? true : false}
        title="显卡信息"
        layout="vertical"
        extra={
          <Button
            icon={<PlusOutlined />}
            loading={addGPUVisible}
            onClick={() => {
              setAddGPUVisible(true);
            }}
          >
            新增
          </Button>
        }
      >
        {gpuInfo?.map((item, index) => GPUDescItem(item, index))}
      </Descriptions> */}
      <GPUInfoAdd
        hospID={hospID}
        hardware_id={hardware_id}
        visible={addGPUVisible}
        setVisible={setAddGPUVisible}
        reload={reload}
      />
      <GPUInfoEdit
        hospID={hospID}
        editingItem={editGPUItem}
        editable={editGPUVisible}
        setEditable={setEditGPUVisible}
        reload={reload}
      />
    </Fragment>
  );
};

export default GPUInfo;
