import { Dispatch, SetStateAction, useEffect } from "react";
import { Button, Form, Input, TextArea, Toast } from "antd-mobile";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { ADD_NETWORK } from "lib/api";
import { DisposeMobileText } from "utils";
import { DnsRegex, IpRegex, MaskRegex } from "utils/regexp";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";

export const MobileNetworkInfoAdd = ({
  orgID,
  visible,
  setVisible,
  reload,
}: {
  orgID: string | number | null | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const { run: submit, isLoading: submitting } = useAsync();
  const submitHandle = (value: any) => {
    if (orgID)
      submit(
        client(ADD_NETWORK, {
          data: {
            // ...value,
            hardware_id: parseInt(orgID.toString()),
            ip: value?.ip || null,
            gateway: value?.gateway || null,
            mask: value?.mask || null,
            dns: value?.dns || null,
            remark: value?.remark || null,
          },
          method: "POST",
        }),
      )
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="添加成功" />,
          });
          reload();
        })
        .catch((e) => {
          Toast.show({
            icon: "fail",
            content: <ToastContent content="添加失败" />,
          });
          console.error(e);
        });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法提交" />,
      });
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <EditPopup
      visible={visible}
      submitting={submitting}
      setVisible={setVisible}
    >
      <Form
        form={form}
        onFinish={submitHandle}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={submitting}
          >
            提交
          </Button>
        }
      >
        <Form.Header>新增网络信息</Form.Header>
        <Form.Item
          name="ip"
          label="IP"
          rules={[
            { required: true, message: "必须输入IP" },
            {
              pattern: IpRegex(),
              message: "必须输入合法的IP格式",
            },
          ]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input style={{ "--text-align": "right" }} placeholder="请输入IP" />
        </Form.Item>
        <Form.Item
          name="gateway"
          label="网关"
          rules={[
            { required: true, message: "必须输入网关" },
            {
              pattern: IpRegex(),
              message: "必须输入合法的网关地址",
            },
          ]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input style={{ "--text-align": "right" }} placeholder="请输入网关" />
        </Form.Item>
        <Form.Item
          name="mask"
          label="掩码"
          rules={[
            { required: true, message: "必须输入掩码" },
            {
              pattern: MaskRegex(),
              message: "必须输入合法的掩码格式",
            },
          ]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input style={{ "--text-align": "right" }} placeholder="请输入掩码" />
        </Form.Item>
        <Form.Item
          name="dns"
          label="DNS"
          rules={[
            {
              pattern: DnsRegex(),
              message: "必须输入合法的DNS地址",
            },
          ]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input
            style={{ "--text-align": "right" }}
            placeholder="输入DNS地址"
          />
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
          help="额外的补充信息"
          normalize={(v) => DisposeMobileText(v)}
          layout={"vertical"}
        >
          <TextArea autoSize placeholder="输入备注" />
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
