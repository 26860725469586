import { Descriptions, Drawer, message } from "antd";
import { DetailPageSlim } from "authenticated-app/pc/customer/customer-detail/detalPageSlim";
import { HospListType } from "interface";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BREAK_DETAIL_TYPE } from "lib/interface/breaklist";

const { Item } = Descriptions;

export const BreakDetailBaseInfo = ({
  detailInfo,
  isFinished,
  isHasPermission,
}: {
  detailInfo: BREAK_DETAIL_TYPE | null;
  isFinished: boolean;
  isHasPermission: boolean;
}) => {
  const navigate = useNavigate();
  const [hospInfo, setHospInfo] = useState<HospListType | undefined>(undefined);
  const [disChange, setDisChange] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (updates: {
    set?: Record<string, string>;
    delete?: string[];
  }) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    if (updates.set) {
      Object.entries(updates.set).forEach(([key, value]) => {
        newSearchParams.set(key, value);
      });
    }
    if (updates.delete) {
      updates.delete.forEach((key) => {
        newSearchParams.delete(key);
      });
    }
    setSearchParams(newSearchParams);
  };

  const handleCustomerClick = () => {
    if (detailInfo?.customer_owner === "商业化事业部") {
      setIsDrawerOpen(true);
      updateSearchParams({ set: { drawer: "true" } });
    } else {
      message.warning("客户所属非商业化事业部，无法查看详情！");
    }
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setHospInfo(undefined);
    setDisChange(false);
    updateSearchParams({
      delete: ["drawer", "tab", "product", "server", "matter", "docking"],
    });
  };

  return (
    <>
      <Descriptions
        className="break-base-desc-title"
        size="small"
        title="基本信息"
        column={3}
      >
        <Item label={"工单号"}>{detailInfo?.order_id}</Item>
        <Item label={"工单类别"}>{detailInfo?.order_type_name}</Item>
        <Item label={"客户名称"}>
          <span
            onClick={handleCustomerClick}
            style={{
              color: "#1890ff",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {detailInfo?.hospital_name || detailInfo?.customer_name}
          </span>
        </Item>
        {detailInfo?.order_type_name === "使用故障" ? (
          <>
            <Item label={"影响功能"}>{detailInfo?.influence_function}</Item>
            <Item label={"影响范围"}>{detailInfo?.influence_sphere}</Item>
          </>
        ) : (
          <></>
        )}
        <Item label={"客户所属"}>{detailInfo?.customer_owner}</Item>
      </Descriptions>

      <Drawer
        title={`${detailInfo?.hospital_name || "未知"} ${
          detailInfo?.hospital_info?.hospital_code || "未知"
        }`}
        placement="right"
        onClose={handleCloseDrawer}
        open={isDrawerOpen}
        width="70%"
      >
        <DetailPageSlim
          hospID={detailInfo?.hospital_id || null}
          hospInfo={hospInfo}
          setDisChange={setDisChange}
        />
      </Drawer>
    </>
  );
};
