import { useEffect } from "react";
import { Button, Card, Divider, Result, Skeleton, Tabs } from "antd";
import type { TabsProps } from "antd";
import { FileProtectOutlined } from "@ant-design/icons";

import {
  GET_EXIT_TYPE,
  ADD_EXIT_TYPE,
  UPDATE_EXIT_TYPE,
  DEL_EXIT_TYPE,
  GET_ONLINE_TYPE,
  ADD_ONLINE_TYPE,
  UPDATE_ONLINE_TYPE,
  DEL_ONLINE_TYPE,
  GET_SURVEY_TYPE,
  ADD_SURVEY_TYPE,
  DEL_SURVEY_TYPE,
  UPDATE_SURVEY_TYPE,
  GET_EXCEPT_TYPE,
  ADD_EXCEPT_TYPE,
  UPDATE_EXCEPT_TYPE,
  DEL_EXCEPT_TYPE,
} from "./api";
import ChecklistBaseSetting from "./checklist-base-setting";
import ChecklistExceptSetting from "./checklist-except-setting";
import { LoadingDiv } from "components";
import { usePermission } from "context/permission";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

const defaultTab = "receipt";

export const TicketSettingPage = () => {
  const { permission } = usePermission();

  const [{ tab }, setTab] = useUrlQueryParam(["tab"]);

  useEffect(() => {
    if (!!!tab) {
      setTab({ tab: defaultTab });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const items: TabsProps["items"] = [
    {
      key: "receipt",
      label: (
        <span>
          <FileProtectOutlined />
          信息回执
        </span>
      ),
      children: (
        <LoadingDiv>
          <ChecklistBaseSetting
            title="退场清单"
            ctrlSetting={{
              GET_SETTING: GET_EXIT_TYPE,
              ADD_SETTING: ADD_EXIT_TYPE,
              UPDATE_SETTING: UPDATE_EXIT_TYPE,
              DEL_SETTING: DEL_EXIT_TYPE,
            }}
          />
          <Divider />
          <ChecklistBaseSetting
            title="上线清单"
            ctrlSetting={{
              GET_SETTING: GET_ONLINE_TYPE,
              ADD_SETTING: ADD_ONLINE_TYPE,
              UPDATE_SETTING: UPDATE_ONLINE_TYPE,
              DEL_SETTING: DEL_ONLINE_TYPE,
            }}
          />
          <Divider />
          <ChecklistBaseSetting
            title="调研清单"
            ctrlSetting={{
              GET_SETTING: GET_SURVEY_TYPE,
              ADD_SETTING: ADD_SURVEY_TYPE,
              UPDATE_SETTING: UPDATE_SURVEY_TYPE,
              DEL_SETTING: DEL_SURVEY_TYPE,
            }}
            isSurvey={true}
          />
          <Divider />
          <ChecklistExceptSetting
            title="版本异常清单"
            ctrlSetting={{
              GET_SETTING: GET_EXCEPT_TYPE,
              ADD_SETTING: ADD_EXCEPT_TYPE,
              UPDATE_SETTING: UPDATE_EXCEPT_TYPE,
              DEL_SETTING: DEL_EXCEPT_TYPE,
            }}
          />
        </LoadingDiv>
      ),
    },
  ];

  return permission?.hasPermissionEdit === 1 ? (
    <Card
      style={{ height: "100%", minWidth: 988 }}
      title={<span style={{ fontWeight: "bolder" }}>配置页</span>}
    >
      <Tabs
        activeKey={tab}
        tabPosition="left"
        items={items}
        onChange={(v) => setTab({ tab: v })}
      />
    </Card>
  ) : permission?.hasPermissionEdit === 0 ? (
    <Result
      status="403"
      title="403"
      subTitle="抱歉，你无权限访问此页面！"
      extra={
        <Button
          type="primary"
          onClick={() => window.location.replace(window.location.origin)}
        >
          返回主页
        </Button>
      }
    />
  ) : (
    <Skeleton active />
  );
};
