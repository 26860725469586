import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal, Form, Input, message, Select } from "antd";

import { useHttp } from "utils/http";
import { ChecklistExceptType } from "../interface";
import { DisposeForm, selectFilter } from "utils";
import { useAsync } from "utils/hooks/useAsync";
import {
  ProductType,
  ProductVersionType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import { GET_PRODUCT_LIST, GET_PRODUCT_VERSION_BY_ID } from "lib/api";

const { Option } = Select;

export const ChecklistSurveySettingEdit = ({
  title,
  settingInfo,
  submitAPI,
  visible,
  setVisible,
  reload,
}: {
  title: string;
  settingInfo: ChecklistExceptType | undefined;
  submitAPI: (org_id: number) => string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const { run: updateChecklist, isLoading: updatingChecklist } = useAsync();

  const [editProduct, setEditProduct] = useState<string>();

  const clear = () => {
    form.setFieldsValue({
      product_version: null,
    });
  };

  const {
    run: getProductList,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductType[]>();
  const getProductListFunc = () => {
    getProductList(client(GET_PRODUCT_LIST));
  };

  const {
    run: getProductVersionList,
    isLoading: gettingProductVersionList,
    data: productVersionList,
    setData: setProductVersionList,
  } = useAsync<ProductVersionType[]>();
  const getProductVersionListFunc = (product_id: string) => {
    getProductVersionList(client(GET_PRODUCT_VERSION_BY_ID(product_id)));
  };

  const onSubmit = (values: any) => {
    let id = settingInfo?.id;
    if (id) {
      updateChecklist(
        client(submitAPI(id), { method: "PUT", data: { ...values } }),
      )
        .then(() => {
          message.success("修改成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    } else message.error("无法请求修改，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    if (editProduct) {
      getProductVersionListFunc(editProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editProduct]);

  useEffect(() => {
    if (visible) {
      getProductListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (settingInfo) {
      setEditProduct(settingInfo.product_id);
      form.setFieldsValue({
        ...settingInfo,
      });
    } else {
      setProductVersionList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingInfo]);

  return (
    <Modal
      title={`修改${title}项`}
      destroyOnClose
      open={visible}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      onOk={form.submit}
      confirmLoading={updatingChecklist}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit}
      >
        <Form.Item
          name="product_id"
          label="产品线"
          rules={[{ required: true, message: "必须选择产品线" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            showSearch
            loading={gettingProductList}
            placeholder="选择产品线"
            onChange={(v) => {
              clear();
              setEditProduct(v);
            }}
          >
            {productList?.map((i, index) => (
              <Option key={index} value={i?.title}>
                {i?.chinese_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="product_version"
          label="安装分支"
          rules={[{ required: true, message: "必须选择安装分支" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            showSearch
            loading={gettingProductVersionList}
            placeholder="选择安装分支"
            filterOption={selectFilter}
          >
            {productVersionList?.map((i, index) => (
              <Option key={index} value={i?.name}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="problem_content"
          label="问题内容"
          rules={[{ required: true, message: "必须填写问题内容" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="repair_mode"
          label="修复方式"
          rules={[{ required: true, message: "必须填写修复方式" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="is_need"
          label="必须"
          rules={[{ required: true, message: "必须选择必要性" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            options={[
              { label: "是", value: 1 },
              { label: "否", value: 0 },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="is_start"
          label="启用"
          rules={[{ required: true, message: "必须选择是否启用" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            options={[
              { label: "是", value: 1 },
              { label: "否", value: 0 },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
