import { Fragment, useEffect, useState } from "react";
import {
  Button,
  CapsuleTabs,
  Divider,
  ErrorBlock,
  PullToRefresh,
  Skeleton,
  Space,
  Tag,
  Toast,
} from "antd-mobile";
import { AddOutline, AddSquareOutline } from "antd-mobile-icons";
import { useDebounceFn } from "ahooks";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import {
  ADD_HARDWARE_RETAIN,
  DEL_HARDWARE_RETAIN,
  GET_HARDWARES_LIST,
  UPDATE_HARDWARE_RETAIN,
} from "lib/api";
import { useMount } from "utils";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import {
  HardwareServerType,
  HardwaresType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";
import { MobileServerInfo } from "./server-info";
import { MobileNetworkInfo } from "./network-info";
import { MobileGpuInfo } from "./gpu-info";
import { MobileServerAdd } from "./server-info/add";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { MobileRetainInfo } from "../general/retain-info";
import { MobileMatterInfo } from "./matter-info";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

import "../index.css";

export const MobileHardwareInfo = () => {
  const [{ detailId, server }, setServer] = useUrlQueryParam([
    "detailId",
    "server",
  ]);

  const client = useHttp();

  const [addVisible, setAddVisible] = useState(false);

  const {
    run: getHardwareDetail,
    isLoading,
    data,
    isIdle,
  } = useAsync<HardwaresType>();
  const getHardwareDetailHandle = () => {
    if (detailId) {
      getHardwareDetail(client(GET_HARDWARES_LIST(detailId)));
    } else {
      Toast.show({
        icon: "fail",
        content: <ToastContent content="获取失败" />,
      });
    }
  };

  const reload = getHardwareDetailHandle;

  const [displayItem, setDisplayItem] = useState<HardwareServerType>();

  useEffect(() => {
    if (displayItem) {
      setServer({
        server: displayItem.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayItem]);

  useEffect(() => {
    if (data) {
      if (server && server !== "undefined") {
        let index = data?.hardware_list?.findIndex(
          (h) => h.id.toString() === server,
        );
        if (index !== undefined && index >= 0) {
          setDisplayItem(data.hardware_list[index]);
        } else {
          if (data?.hardware_list[0]) {
            setDisplayItem(data.hardware_list[0]);
          }
        }
      } else {
        if (data?.hardware_list[0]) {
          setDisplayItem(data.hardware_list[0]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const TabDisplay = ({ item }: { item: HardwareServerType }) => {
    return (
      <Fragment>
        <MobileServerInfo hospID={detailId} detail={item} reload={reload} />
        <MobileNetworkInfo
          networkInfo={item.network}
          hardware_id={item.id}
          reload={reload}
        />
        <MobileGpuInfo
          hospID={detailId}
          gpuInfo={item.gpus}
          hardware_id={item.id}
          reload={reload}
        />
      </Fragment>
    );
  };

  const { run } = useDebounceFn(getHardwareDetailHandle, { wait: 200 });

  useMount(run);

  const Loading = isLoading || isIdle;

  const Create = () => {
    return (
      <ErrorBlock
        status="empty"
        title={<span>当前医院没有添加服务器信息</span>}
        description={<span>点击下方按钮添加一个服务器吧</span>}
      >
        <Button color="primary" onClick={() => setAddVisible(true)}>
          <Space>
            <AddOutline />
            新增服务器
          </Space>
        </Button>
      </ErrorBlock>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <LoadingMask visible={Loading} />
      <PullToRefresh
        onRefresh={async () => getHardwareDetailHandle()}
        refreshingText={null}
      >
        {data?.hardware_list?.length === 0 && !isIdle ? (
          <ContentCard
            style={{ margin: 10, padding: "0 0 32px 0", borderRadius: 10 }}
          >
            <Create />
          </ContentCard>
        ) : (
          <Fragment>
            {data?.hardware_list?.length ? (
              <div className={"customer-header"}>
                <CapsuleTabs
                  className={"tabs"}
                  activeKey={displayItem?.id.toString()}
                  onChange={(v) => {
                    setDisplayItem(
                      data?.hardware_list.find((i) => i.id.toString() === v),
                    );
                  }}
                >
                  {data?.hardware_list?.map((i) => (
                    <CapsuleTabs.Tab
                      key={i?.id}
                      title={
                        <div>
                          <span>{i?.server_ip || `服务器${i.id}`}</span>
                          {i.is_master === 1 ? (
                            <Tag
                              round
                              color="#87d068"
                              style={{
                                transform: "translateY(-2px)",
                                marginLeft: "6px",
                              }}
                            >
                              主机
                            </Tag>
                          ) : null}
                        </div>
                      }
                    />
                  ))}
                </CapsuleTabs>
                <Button
                  className={"more-icon"}
                  fill="none"
                  loading={addVisible}
                  onClick={() => setAddVisible(true)}
                >
                  <AddSquareOutline
                    fontSize={24}
                    style={{
                      transform: "translateY(2px)",
                    }}
                  />
                </Button>
              </div>
            ) : null}
            {displayItem ? (
              <div className="customer-content">
                <TabDisplay item={displayItem} />
                <MobileMatterInfo
                  matterInfo={data?.other_infos}
                  hospID={detailId}
                  reload={reload}
                />
                <MobileRetainInfo
                  orgID={detailId}
                  retain={data?.retain_infos}
                  RetainAPI={{
                    ADD_RETAIN: ADD_HARDWARE_RETAIN,
                    UPDATE_RETAIN: UPDATE_HARDWARE_RETAIN,
                    DEL_RETAIN: DEL_HARDWARE_RETAIN,
                  }}
                  reload={getHardwareDetailHandle}
                />
              </div>
            ) : (
              <div className="customer-content">
                <div style={{ margin: 16 }}>
                  <Skeleton.Title animated />
                  <Skeleton.Paragraph lineCount={5} animated />
                </div>
              </div>
            )}
          </Fragment>
        )}
        <Divider />
      </PullToRefresh>
      <MobileServerAdd
        hospID={detailId}
        visible={addVisible}
        setVisible={setAddVisible}
        reload={reload}
      />
    </div>
  );
};
