import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Input, Select, message, Form, Modal, Upload, UploadFile } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import {
  GET_EQPT_TYPE,
  GET_EQPT_OEM,
  GET_EQPT_MODEL,
  ADD_HARDWARE_MATTER,
  ADD_FILE,
} from "lib/api";
import { EqptModel, EqptOem, EqptType } from "../interface";
import { useAsync } from "utils/hooks/useAsync";
import { DisposeForm, handleUploadImage, limitUploadImage } from "utils";
import { IpRegex } from "utils/regexp";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { handlePreview } from "utils/handlePreview";

const { TextArea } = Input;
const { Option } = Select;

export const MatterInfoAdd = ({
  hospID,
  visible,
  setVisible,
  reload,
}: {
  hospID: number | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const [, setMatter] = useUrlQueryParam([]);

  const client = useHttp();
  const [form] = Form.useForm();

  const [eqptTypeID, setEqptTypeID] = useState<number | null>(null);
  const [eqptOemID, setEqptOemID] = useState<number | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const {
    run: getEqptTypeList,
    isLoading: gettingEqptTypeList,
    data: eqptTypeList,
  } = useAsync<EqptType[]>();
  const getEqptTypeListFunc = () => {
    getEqptTypeList(client(GET_EQPT_TYPE));
  };

  const {
    run: getEqptOemList,
    isLoading: gettingEqptOemList,
    data: eqptOemList,
  } = useAsync<EqptOem[]>();
  const getEqptOemListFunc = (eqpt_type_id: number) => {
    getEqptOemList(client(GET_EQPT_OEM(eqpt_type_id)));
  };

  const {
    run: getEqptModeList,
    isLoading: gettingEqptModeList,
    data: eqptModeList,
    setData: setEqptModel,
  } = useAsync<EqptModel[]>();
  const getEqptModeListFunc = (eqpt_oem_id: number) => {
    getEqptModeList(client(GET_EQPT_MODEL(eqpt_oem_id)));
  };

  const submit = (value: any) => {
    if (hospID)
      client(ADD_HARDWARE_MATTER, {
        data: {
          ...value,
          asset_photos: fileList.map((i) => i.uid),
          hospital_id: parseInt(hospID.toString()),
        },
        method: "POST",
      })
        .then((res) => {
          message.success("添加成功！");
          setVisible(false);
          setMatter({ matter: res.id });
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("添加失败，请检查或联系管理员！");
        });
    else {
      message.error("添加失败，请稍后重试或联系管理员！");
    }
  };

  const clearModel = () => {
    setEqptModel([]);
    form.setFieldsValue({
      eqpt_model_id: null,
    });
  };

  const clearOem = () => {
    setEqptOemID(null);
    form.setFieldsValue({
      eqpt_oem_id: null,
    });
  };

  useEffect(() => {
    if (visible) {
      setFileList([]);
      form.resetFields();
      getEqptTypeListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (eqptTypeID) {
      getEqptOemListFunc(eqptTypeID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqptTypeID]);

  useEffect(() => {
    if (eqptOemID) {
      getEqptModeListFunc(eqptOemID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqptOemID]);

  return (
    <Modal
      title="新增其它物料信息"
      open={visible}
      destroyOnClose
      forceRender
      maskClosable={false}
      onCancel={() => setVisible(false)}
      onOk={form.submit}
      okText="提交"
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={submit}
      >
        <Form.Item
          name="eqpt_type_id"
          label="设备类型"
          rules={[{ required: true, message: "必须选择设备类型" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingEqptTypeList}
            placeholder="选择设备类型"
            onChange={(v) => {
              clearOem();
              clearModel();
              setEqptTypeID(v);
            }}
          >
            {eqptTypeList?.map((item, index) => (
              <Option key={index} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="eqpt_oem_id"
          label="设备品牌"
          rules={[{ required: true, message: "必须选择设备品牌" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingEqptOemList}
            placeholder="选择设备品牌"
            onChange={(v) => {
              clearModel();
              setEqptOemID(v);
            }}
          >
            {eqptOemList?.map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="eqpt_model_id"
          label="设备型号"
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            allowClear
            loading={gettingEqptModeList}
            placeholder="选择设备型号"
          >
            {eqptModeList?.map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="ip"
          label="物料IP"
          normalize={(v) => DisposeForm(v)}
          rules={[
            {
              pattern: IpRegex(),
              message: "必须输入合法的IP格式",
            },
          ]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="maintenance_code"
          label="维保代码"
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="assets_code"
          label="资产编码"
          normalize={(v) => DisposeForm(v)}
          rules={[{ required: true, message: "必须输入资产编码" }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="description"
          label="应用描述"
          normalize={(v) => DisposeForm(v)}
        >
          <TextArea allowClear />
        </Form.Item>
        <Form.Item
          name="asset_photos"
          label="资产照片"
          normalize={(v) => DisposeForm(v)}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={3}
            multiple={true}
            action={ADD_FILE}
            listType="picture-card"
            fileList={fileList}
            beforeUpload={limitUploadImage}
            onChange={(info) => handleUploadImage(info, setFileList)}
            onPreview={(f) =>
              handlePreview(f, {
                setPreviewImage,
                setPreviewOpen,
                setPreviewTitle,
              })
            }
          >
            {fileList.length >= 3 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传(限3个)</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </Form>
      <Modal
        centered
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        width={"61.8%"}
      >
        <div style={{ width: "100%" }}>
          <img alt="previwe" style={{ width: "100%" }} src={previewImage} />
        </div>
      </Modal>
    </Modal>
  );
};
