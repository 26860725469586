import { User } from "interface";

export const getSelectParams = (value: string, user: User | null) => {
  switch (value) {
    case "all":
      return undefined;
    case "unclosed":
      return {
        process_id_list: "1|2|3|4|5|7",
      };
    case "charge":
      return {
        issue_user_id: user?.id,
        process_id_list: "1|2|3|4|5|7",
      };
    case "dispose":
      return {
        node_user_id: user?.id,
        process_id_list: "1|2|3|4|5",
      };
    case "suspend":
      return { node_user_id: user?.id, process_id_list: "7" };
    case "created":
      return { create_user: user?.userName };
    case "solved":
      return { issue_user_id: user?.id, process_id_list: "6" };
    case "dev_processing":
      return { has_dev: true };
    case "srd_processing":
      return { has_srd: true };
    // case "processed":
    //   return { processed: true };
    case "follow":
      return { node_user_id: user?.id, follow_user_id: user?.id };
    default:
      return undefined;
  }
};

export const getSelectTitle = (value: string) => {
  switch (value) {
    case "unclosed":
      return "未关单";
    case "all":
      return "全部工单";
    // case "charge":
    //   return "负责中工单";
    case "dispose":
      return "处理中工单";
    case "suspend":
      return "挂起中工单";
    case "created":
      return "已创建工单";
    // case "solved":
    //   return "已解决工单";
    case "srd_processing":
      return "升级SRD工单";
    case "dev_processing":
      return "升级研发工单";
    // case "processed":
    //   return "我处理的工单";
    case "follow":
      return "关注工单";
    default:
      return value;
  }
};
