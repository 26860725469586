import { useEffect, useState } from "react";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import {
  Form,
  Modal,
  Select,
  Input,
  Radio,
  DatePicker,
  InputNumber,
  Space,
  Upload,
  UploadFile,
  Divider,
  Alert,
  List,
} from "antd";
import type { RadioChangeEvent } from "antd";
import { useDeliveryDetailComplete } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailComplete";
import {
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { ADD_FILE } from "lib/api";
import {
  DELIVERY_DETAIL_TYPE,
  NEXT_PROCESS_TYPE,
} from "lib/interface/launchChangeList";
import { FormatDate, FormatDayjs, limitUpload, handleUpload } from "utils";
import { handlePreview } from "utils/handlePreview";
import { initEditUploadFileList } from "utils";
import { GET_HOSPITAL_ORDER } from "lib/api/deliverylist";

const { TextArea } = Input;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const DeliveryDetailCompleteModal = ({
  onTrans,
  next,
  detailInfo,
  isCanChange,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
  isCanChange: boolean;
}) => {
  const [isOpen, , close] = useDeliveryDetailComplete();
  const [form] = Form.useForm();
  const client = useHttp();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [isOnSite, setIsOnSite] = useState<number>(1);

  const integerParser = (value: string | null | undefined) => {
    if (value === undefined || value === null) {
      return "";
    }
    return Math.floor(parseInt(value));
  };
  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      first_entry_date: FormatDate(value.first_entry_date),
      actual_delivery_date: FormatDate(value.actual_delivery_date),
      attachment_id_list: fileList?.map((i) => i.uid.toString()),
    });
    close();
  };

  const {
    run: getHospitalOrder,
    data: hospitalOrderList,
    isIdle: HospitalOrderListIsIdle,
    isLoading: gettingHospitalOrderList,
  } = useAsync<[]>();
  const getHospOrderFunc = (params?: { [key in any]: any }) => {
    if (detailInfo?.hospital_id) {
      getHospitalOrder(client(GET_HOSPITAL_ORDER(detailInfo?.hospital_id)));
    }
  };

  useEffect(() => {
    if (hospitalOrderList) {
      const issues_id_list = detailInfo?.relation_order_list.map(
        (i: any) => i.id,
      );
      form.setFieldsValue({
        issues_id_list: issues_id_list,
      });
    }
  }, [hospitalOrderList]);

  useEffect(() => {
    if (isOpen) {
      form.resetFields();

      let initList = initEditUploadFileList(detailInfo?.attachment_list);
      setFileList(initList || []);
      getHospOrderFunc();
      form.setFieldsValue({
        ...(detailInfo?.first_entry_date
          ? { first_entry_date: FormatDayjs(detailInfo?.first_entry_date) }
          : { first_entry_date: "" }),
        ...(detailInfo?.actual_delivery_date
          ? {
              actual_delivery_date: FormatDayjs(
                detailInfo?.actual_delivery_date,
              ),
            }
          : { actual_delivery_date: "" }),
        actual_entry_number: detailInfo?.actual_entry_number,
        is_send_back: detailInfo?.is_send_back || 2,
        is_deal_with_on_site: detailInfo?.is_deal_with_on_site || 1,
        is_over_checklist: detailInfo?.is_over_checklist,
        is_update_info: detailInfo?.is_update_info,
        actual_person_days: detailInfo?.actual_person_days,
        supplementary_info: detailInfo?.supplementary_info,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  const onsite_info = () => {
    switch (detailInfo?.delivery_type_name) {
      case "产品升级":
      case "产品停用":
      case "产品撤机":
      case "硬件部署":
      case "组件升级":
        return (
          <>
            <Form.Item
              name="is_deal_with_on_site"
              label="是否现场处理"
              rules={[{ required: true, message: "必须选择是否现场处理" }]}
            >
              <Radio.Group
                style={{ marginLeft: "12px" }}
                onChange={(e: RadioChangeEvent) => {
                  setIsOnSite(e.target.value);
                }}
              >
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        );
    }
  };

  const tips_info = () => {
    switch (detailInfo?.delivery_type_name) {
      case "产品升级":
      case "产品停用":
      case "产品撤机":
      case "硬件部署":
      case "组件升级":
        return (
          <>
            <List size="small">
              <List.Item>1.现场硬件核对，确保与实际申请相符。</List.Item>
              <List.Item>
                2.产品线及版本核对，确保实际上线与申请相符。
              </List.Item>
              <List.Item>
                3.通过工程端二维码或手动录入等方式，更新客户相关信息。
              </List.Item>
            </List>
          </>
        );
      default:
        return (
          <>
            <List size="small">
              <List.Item>1.现场硬件核对，确保与实际申请相符。</List.Item>
              <List.Item>
                {" "}
                2.产品线及版本核对，确保实际上线与申请相符。
              </List.Item>
              <List.Item>3.完成ERP下推调入流程。</List.Item>
              <List.Item>
                {" "}
                4.通过工程端二维码或手动录入等方式，更新客户相关信息。
              </List.Item>
            </List>
          </>
        );
    }
  };

  const sendback_info = () => {
    switch (detailInfo?.delivery_type_name) {
      case "产品停用":
        return <></>;
      default:
        return (
          <>
            <Form.Item
              name="is_send_back"
              label="是否已退回多余物料"
              rules={[
                { required: true, message: "必须选择是否已退回多余物料" },
              ]}
            >
              <Radio.Group style={{ marginLeft: "12px" }}>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
                <Radio value={2}>无多余物料</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        );
    }
  };

  const over_checklist_info = () => {
    if (detailInfo?.delivery_type_name !== "产品撤机") {
      return (
        <Form.Item
          name="is_over_checklist"
          label="是否已完成退场检查"
          rules={[{ required: true, message: "必须选择是否已完成退场检查" }]}
        >
          <Radio.Group style={{ marginLeft: "12px" }}>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
      );
    }
  };

  const options = { value: "id", label: "title" };

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="完成交付"
      onCancel={close}
      onOk={form.submit}
      width={"700px"}
      style={{ minWidth: "550px" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        {over_checklist_info()}
        <Form.Item
          name="is_update_info"
          label="是否已更新客户信息"
          rules={[{ required: true, message: "必须选择是否已更新客户信息" }]}
        >
          <Radio.Group style={{ marginLeft: "12px" }}>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        {sendback_info()}
        {onsite_info()}
        <Form.Item
          name="first_entry_date"
          label="第一次入场日期"
          rules={[
            {
              required: isOnSite ? true : false,
              message: "必须选择第一次入场日期",
            },
          ]}
        >
          <DatePicker
            style={{ width: "300px" }}
            placeholder="请选择第一次入场日期"
          />
        </Form.Item>
        <Form.Item
          name="actual_entry_number"
          label="实际入场次数"
          rules={[
            {
              required: isOnSite ? true : false,
              message: "必须填写实际入场次数",
            },
          ]}
        >
          <InputNumber
            style={{ width: "300px" }}
            min={0}
            parser={integerParser}
            step={1}
            placeholder="请输入或选择实际入场次数"
          />
        </Form.Item>
        <Form.Item
          name="actual_delivery_date"
          label="实际交付日期"
          rules={[{ required: true, message: "必须选择实际交付日期" }]}
        >
          <DatePicker
            style={{ width: "300px" }}
            placeholder="请选择实际交付日期"
          />
        </Form.Item>
        <Form.Item
          name="actual_person_days"
          label="实际投入人天"
          rules={[
            {
              required: true,
              message: "必须填写实际交付人天,单位为数值,可输入小数.",
            },
          ]}
          tooltip={{ title: "可输入小数, 如0.5", icon: <InfoCircleOutlined /> }}
        >
          <InputNumber<string>
            style={{ width: 300 }}
            min="0"
            step="0.5"
            stringMode
            placeholder="请输入填写实际交付人天"
          />
        </Form.Item>

        <Form.Item
          name="supplementary_info"
          label="补充信息"
          help={
            !!!isCanChange ? (
              <Space>
                <ExclamationCircleOutlined />
                <span>交付负责人可编辑</span>
              </Space>
            ) : (
              ""
            )
          }
        >
          <TextArea
            disabled={!!!isCanChange}
            autoSize={{ minRows: 3 }}
            placeholder="填写补充信息"
          ></TextArea>
        </Form.Item>
        <Form.Item
          name="issues_id_list"
          label="关联工单"
          help={
            !!!isCanChange ? (
              <Space>
                <ExclamationCircleOutlined />
                <span>交付负责人可编辑</span>
              </Space>
            ) : (
              ""
            )
          }
        >
          <Select
            showSearch
            mode="multiple"
            placement="topLeft"
            disabled={!!!isCanChange}
            options={hospitalOrderList?.map((i: any) => ({
              label: `${i.order_id} (${i[options.label]})`,
              value: i[options.value],
            }))}
          >
            {detailInfo?.relation_order_list?.map((item, index) => (
              <Option key={index} value={item?.id} title={item?.title}>
                {item?.order_id} {item?.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="attachment_id_list" label="附件上传">
          <Upload
            headers={UploadHeaders}
            maxCount={10}
            multiple={true}
            action={ADD_FILE}
            listType="picture-card"
            fileList={fileList}
            beforeUpload={limitUpload}
            onChange={(info) => handleUpload(info, setFileList)}
            onPreview={(f) =>
              handlePreview(f, {
                setPreviewImage,
                setPreviewOpen,
                setPreviewTitle,
              })
            }
          >
            {fileList.length >= 10 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传(限10个)</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </Form>
      <Divider />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Alert
          style={{
            marginBottom: "8px",
            width: "550px",
            backgroundColor: "white",
            borderColor: "rgba(0, 0, 0, 0.45)",
          }}
          message={
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
              完成交付关闭工单前，请确保已完成以下内容：
            </span>
          }
          description={tips_info()}
          showIcon
          icon={<InfoCircleOutlined />}
        />
      </div>
    </Modal>
  );
};
