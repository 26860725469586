import { useState, Dispatch, SetStateAction, useEffect } from "react";
import { Modal, Form, Input, message, Upload } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { PlusOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import { ADD_FILE } from "lib/api";
import { UploadHeaders } from "../../../interface";
import { DisposeForm, handleUpload, limitUpload } from "utils";
import { UrlRegex } from "utils/regexp";
import { handlePreview } from "utils/handlePreview";

const { TextArea } = Input;

export const BaseRetainInfoAdd = ({
  orgID,
  productID,
  ADD_RETAIN,
  visible,
  setVisible,
  reload,
}: {
  orgID: string | number | null | undefined;
  productID?: string | undefined;
  ADD_RETAIN: (orgID: string | number) => string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const submit = (value: any) => {
    if (orgID)
      client(ADD_RETAIN(orgID), {
        data: {
          ...value,
          attachment: fileList.map((i) => i.uid),
          product_id: productID,
        },
        method: "POST",
      })
        .then(() => {
          message.success("添加成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("添加失败，请检查或联系管理员！");
        });
    else message.error("暂时无法添加，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setFileList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="新增必要留存信息"
      maskClosable={false}
      destroyOnClose
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={form.submit}
      okText="提交"
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={submit}
      >
        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true, message: "必须输入标题" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="简要描述"
          rules={[{ required: true, message: "必须输入简要描述信息" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <TextArea maxLength={255} allowClear showCount />
        </Form.Item>
        <Form.Item
          name="wiki_addr"
          label="相关wiki"
          rules={[
            {
              pattern: UrlRegex(),
              message: "必须输入合法的URL格式",
            },
          ]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear placeholder="输入wiki地址" />
        </Form.Item>
        <Form.Item name="remark" label="备注" normalize={(v) => DisposeForm(v)}>
          <TextArea allowClear />
        </Form.Item>
        <Form.Item
          name="attachment"
          label="附件"
          normalize={(v) => DisposeForm(v)}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={3}
            multiple={true}
            action={ADD_FILE}
            listType="picture-card"
            fileList={fileList}
            beforeUpload={limitUpload}
            onChange={(info) => handleUpload(info, setFileList)}
            onPreview={(f) =>
              handlePreview(f, {
                setPreviewImage,
                setPreviewOpen,
                setPreviewTitle,
              })
            }
          >
            {fileList.length >= 3 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传(限3个)</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </Form>
      <Modal
        centered
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        width={"61.8%"}
      >
        <div style={{ width: "100%" }}>
          <img alt="previwe" style={{ width: "100%" }} src={previewImage} />
        </div>
      </Modal>
    </Modal>
  );
};
