import { useEffect, useState } from "react";
import { Cascader, Spin } from "antd";
import { useHttp } from "utils/http";
import { useDebounceFn } from "ahooks";
import { useAsync } from "utils/hooks/useAsync";
import { RightOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib";

import {
  GET_USED_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_LIST,
  GET_PRODUCT_VERSION_BY_ID,
} from "lib/api";

import { CT_LUNG_VERSION_TYPE } from "utils";
import { ProductInfoType } from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import { ProductType } from "authenticated-app/pc/setting/customer-setting/interface";

interface Options {
  value: string;
  label: string;
  key?: string | number;
  children?: Options[];
  isLeaf?: boolean;
  disabled?: boolean;
}

export const CascaderProduct = ({
  filterHospital,
  hospitalID,
  showDefaultValue,
  form,
  onlyUsed,
}: {
  filterHospital: boolean;
  hospitalID?: number | undefined;
  showDefaultValue: boolean;
  form: FormInstance;
  onlyUsed: boolean;
}) => {
  const client = useHttp();
  const [options, setOptions] = useState<Options[]>([]);
  const [selectedProductValues, setSelectedProductValues] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    run: getAllProductList,
    isIdle: getAllProductListIsIdle,
    isLoading: gettingAllProductList,
    data: allProductList,
  } = useAsync<ProductType[]>();
  const getAllProductListHandle = () => {
    getAllProductList(client(GET_PRODUCT_LIST));
  };

  const {
    run: getHosProductDetailList,
    isIdle: getHosProductDetailListIsIdle,
    isLoading: gettingHosProductDetailList,
    data: hosProductDetailList,
  } = useAsync<ProductInfoType[]>();
  const getHosProductDetailListHandle = () => {
    if (hospitalID) {
      if (onlyUsed) {
        getHosProductDetailList(client(GET_USED_PRODUCT_DETAIL(hospitalID)));
      } else {
        getHosProductDetailList(client(GET_PRODUCT_DETAIL(hospitalID)));
      }
    }
  };
  const { run: getProductDetailDebounce } = useDebounceFn(
    getHosProductDetailListHandle,
    {
      wait: 200,
    },
  );

  const displayRender = (labels: any, selectOptions: any) =>
    labels.map((label: any, i: any) => {
      const option = selectOptions[i];
      if (i === labels.length - 1) {
        if (labels.length === 3) {
          return (
            <span key={i} style={{ color: "#108ee9" }}>
              [{label}]
            </span>
          );
        }
        if (labels.length === 1) {
          if (option.children.length) {
            return (
              <span key={i}>
                {labels[0]} {option.children[0].value}
              </span>
            );
          }
        }
      }
      return <span key={i}> {label} </span>;
    });

  function deduplicateData(data: Array<Array<string>>): Array<Array<string>> {
    const deduplicated: Record<string, Array<string>> = {};
    data.forEach((item) => {
      const key = item[0];
      if (
        !deduplicated[key] ||
        data.indexOf(item) > data.indexOf(deduplicated[key])
      ) {
        deduplicated[key] = item;
      }
    });
    return Object.values(deduplicated);
  }

  // 同一产品线只能选择一个
  const onlyOneProduct = async (
    inputValues: any[],
    allOptions: any[],
    hasDefaultProducts?: boolean,
    useDefaultValue?: boolean,
  ) => {
    // inputValues : 当前要请求处理的产品线[]
    // allOptions: 当前options [], 所有产品线的数据.
    // hasDefaultProducts: 是否处理默认产品线
    // useDefault:  是否使用传入的默认值, 使用将不会触发去重.
    let values: any[] = [];
    if (!!!inputValues.length) {
      setSelectedProductValues([]);
    }
    if (inputValues.length < selectedProductValues.length) {
      values = inputValues;
    } else {
      values = [...selectedProductValues, ...inputValues];
    }
    if (hasDefaultProducts) {
      values.forEach(async (product_info) => {
        const product_name = product_info[0];
        if (product_name) {
          const current_selected_options = allOptions.filter(
            (item: any) => item.value === product_name,
          );
          const children_length = allOptions.slice(-1)[0]?.children?.length;
          if (children_length <= 1) {
            await newLoadData(
              current_selected_options,
              allOptions,
              true,
              inputValues,
              showDefaultValue,
            );
          }
        }
      });
    } else {
      if (values.length) {
        const current_selected_value = values.slice(-1)[0];
        const product_name = current_selected_value[0];
        if (product_name) {
          const current_selected_options = allOptions.filter(
            (item: any) => item.value === product_name,
          );
          const current_options_children = current_selected_options[0].children;
          if (current_selected_value.length === 1) {
            switch (current_options_children.length) {
              case 0:
                await newLoadData(
                  current_selected_options,
                  allOptions,
                  true,
                  values,
                  showDefaultValue,
                );
                break;
              default:
                current_selected_value.push(current_options_children[0].value);
            }
          }
          if (
            product_name === "CT_LUNG" &&
            current_selected_value.length !== 3
          ) {
            const last_version_option = current_selected_options
              ? current_selected_options[0].children.slice(-1)[0]
              : undefined;
            if (last_version_option) {
              values.slice(-1)[0].push(last_version_option.children[0].value);
            }
          }
          let output: any[] = [];
          if (useDefaultValue !== false) {
            output = deduplicateData(values);
          }
          form.setFieldValue("product_id_list", output);
          setSelectedProductValues(output);
        }
      }
    }
  };

  const newLoadData = async (
    selectedOptions: Options[],
    changeOptions: Options[],
    extend?: boolean,
    values?: any[],
    useDefaultValue?: boolean,
  ) => {
    const targetOption = selectedOptions.slice(-1)[0];
    const { value } = targetOption;
    let product_all_version_value_list: any[] = [];
    targetOption.children = [];
    if (selectedOptions.length === 2) {
      const preOption = selectedOptions[selectedOptions.length - 2];
      const product_name = preOption.value.toUpperCase();
      if (product_name === "CT_LUNG") {
        targetOption.children = CT_LUNG_VERSION_TYPE.map((v, index) => ({
          value: v,
          label: v,
        }));
      }
    } else if (value) {
      await client(GET_PRODUCT_VERSION_BY_ID(value)).then((result: any) => {
        if (result.length) {
          result.map((item: any) => {
            let child_version_option: any[] = [];
            const product_name = value ? value.toUpperCase() : "";
            if (product_name === "CT_LUNG") {
              child_version_option = CT_LUNG_VERSION_TYPE.map((v, index) => ({
                value: v,
                label: v,
              }));
              CT_LUNG_VERSION_TYPE.map((v, index) => {
                product_all_version_value_list.push([
                  `${value}`,
                  `${item.name}`,
                  v,
                ]);
              });
              product_all_version_value_list =
                product_all_version_value_list.concat();
            } else {
              product_all_version_value_list.push([`${value}`, `${item.name}`]);
            }
            targetOption.children?.push({
              value: `${item.name}`, // value: `${item.id}`,
              label: `${item.name}`,
              children: child_version_option,
              isLeaf: child_version_option.length ? false : true,
            });
          });
        }
      });
    }
    setOptions([...changeOptions]);
    if (extend) {
      product_all_version_value_list =
        product_all_version_value_list.concat(values);
      let click_values;
      let hasDefaultProducts = undefined;
      if (useDefaultValue) {
        const sortedData = values
          ? [...values].sort(([keyA], [keyB]) =>
              keyA === value ? 1 : keyB === value ? -1 : 0,
            )
          : [];
        click_values = sortedData ? sortedData : product_all_version_value_list;
      } else {
        click_values = product_all_version_value_list
          .filter((v) => v.length !== 1)
          .reverse();
      }
      onlyOneProduct(
        click_values,
        changeOptions,
        hasDefaultProducts,
        showDefaultValue,
      );
    }
  };

  useEffect(() => {
    if (!filterHospital) {
      getAllProductListHandle();
    }
  }, []);

  useEffect(() => {
    if (hospitalID) {
      setOptions([]);
      setLoading(true);
      setSelectedProductValues([]);
      getProductDetailDebounce();
    }
  }, [hospitalID]);

  useEffect(() => {
    if (allProductList) {
      const option_values = allProductList.map((item: any) => ({
        value: item.product_id || item.title,
        label:
          item.product_chinese_name || item.chinese_name || item.title || "",
        children: [],
      }));
      setOptions(option_values);
    }
  }, [allProductList]);

  useEffect(() => {
    if (hosProductDetailList) {
      const option_values = hosProductDetailList
        .filter((item: any) => item.id)
        .map((item: any) => {
          if (item.version_type) {
            return {
              value: item.product_id || item.title,
              label:
                item.product_chinese_name ||
                item.chinese_name ||
                item.title ||
                "",
              children: [
                {
                  value: item.version_type,
                  label: item.version_type,
                },
              ],
            };
          } else {
            return {
              value: item.product_id || item.title,
              label:
                item.product_chinese_name ||
                item.chinese_name ||
                item.title ||
                "",
              children: [],
            };
          }
        });
      const arr = hosProductDetailList
        .map((item: any) => {
          const product_info: any[] = [];
          const product_name = item.product_id || item.title;
          product_info.push(product_name);
          if (item.product_version_name) {
            product_info.push(item.product_version_name);
          }
          if (item.version_type && product_name === "CT_LUNG") {
            product_info.push(item.version_type);
          }
          return product_info;
        })
        .filter((item: any) => item[0] !== "NotOnline");
      setOptions(option_values);
      onlyOneProduct(arr, option_values, true);
    }
  }, [hosProductDetailList]);

  return (
    <>
      {filterHospital ? (
        <>
          <Cascader
            value={selectedProductValues}
            placeholder={!!!hospitalID ? "请先选择客户" : "选择产品线"}
            disabled={!!!hospitalID}
            style={{ width: "100%" }}
            options={options}
            displayRender={displayRender}
            loadData={(selectOptions) => {
              newLoadData(selectOptions, options);
            }}
            dropdownRender={(originNode) => (
              <Spin
                spinning={
                  getHosProductDetailListIsIdle || gettingHosProductDetailList
                }
              >
                {originNode}
              </Spin>
            )}
            allowClear={true}
            multiple
            expandIcon={
              <span>
                点击展开
                <RightOutlined />
              </span>
            }
            onChange={(inputValues, selectOptions) => {
              if (inputValues.length === 1) {
                const onCheckedOptionsChildren = selectOptions[0][0].children;
                if (onCheckedOptionsChildren?.length === 1) {
                  inputValues[0].push(onCheckedOptionsChildren[0].value);
                }
              }
              onlyOneProduct(inputValues, options);
            }}
          />
        </>
      ) : (
        <>
          <Cascader
            value={selectedProductValues}
            placeholder={"选择产品线"}
            style={{ width: "100%" }}
            options={options}
            displayRender={displayRender}
            loadData={(selectOptions) => {
              newLoadData(selectOptions, options);
            }}
            dropdownRender={(originNode) => (
              <Spin
                spinning={
                  getAllProductListIsIdle || gettingAllProductList || loading
                }
              >
                {originNode}
              </Spin>
            )}
            allowClear={true}
            multiple
            expandIcon={
              <span>
                点击展开
                <RightOutlined />
              </span>
            }
            onChange={(inputValues, selectedOptions) => {
              onlyOneProduct(inputValues, options);
            }}
          />
        </>
      )}
    </>
  );
};
