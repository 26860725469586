import { useState } from "react";
import {
  Button,
  Dialog,
  Ellipsis,
  List,
  SwipeAction,
  Toast,
} from "antd-mobile";
import {
  RightOutline,
  EditSOutline,
  DeleteOutline,
  ExclamationCircleFill,
  AddSquareOutline,
} from "antd-mobile-icons";

import { useHttp } from "utils/http";
import { RetainInfosType } from "authenticated-app/pc/customer/customer-detail/tab-pane/infoTabPane/interface";
import { RetainAPI } from "authenticated-app/pc/customer/customer-detail/interface";
import { useAsync } from "utils/hooks/useAsync";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import {
  ListRightIconSize,
  SwipeActionIconSize,
} from "authenticated-app/mobile/util";
import { MobileRetainInfoDetail } from "./detail";
import { DelConfirmContent } from "authenticated-app/mobile/components/DelConfirmContent";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { MobileRetainInfoAdd } from "./add";
import { MobileRetainInfoEdit } from "./edit";

export const MobileRetainInfo = ({
  orgID,
  productID,
  retain,
  RetainAPI,
  reload,
}: {
  orgID: string | number | null | undefined;
  productID?: string | undefined;
  retain: RetainInfosType[] | undefined | null;
  RetainAPI: RetainAPI;
  reload: () => void;
}) => {
  // 必要信息state
  const [addRetainVisible, setAddRetainVisible] = useState(false);

  const [editRetainInfoVisible, setEditRetainInfoVisible] = useState(false);
  const [editRetainInfo, setEditRetainInfo] = useState<RetainInfosType>();

  const [detailRetainVisible, setDetailRetainVisible] =
    useState<boolean>(false);
  const [detailRetainInfo, setDetailRetainInfo] = useState<RetainInfosType>();

  const client = useHttp();

  const { run: del, isLoading: deleting } = useAsync();
  const delHandle = (id: number) => {
    if (id)
      del(client(RetainAPI.DEL_RETAIN(id), { method: "DELETE" }))
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="删除成功" />,
          });
          reload();
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="删除失败" />,
          });
        });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法删除" />,
      });
  };

  return (
    <ContentCard>
      <LoadingMask visible={deleting} />
      <List
        mode="card"
        header={
          <ListHeader
            text="必要信息留存"
            rightIcon={<AddSquareOutline fontSize={ListRightIconSize} />}
            loading={addRetainVisible}
            onClick={() => {
              setAddRetainVisible(true);
            }}
          />
        }
      >
        {retain?.map((i) => (
          <SwipeAction
            key={i.id}
            closeOnAction={false}
            rightActions={[
              {
                key: "edit",
                text: <EditSOutline fontSize={SwipeActionIconSize} />,
                color: "primary",
                onClick: () => {
                  setEditRetainInfo(i);
                  setEditRetainInfoVisible(true);
                },
              },
              {
                key: "delete",
                text: <DeleteOutline fontSize={SwipeActionIconSize} />,
                color: "danger",
                onClick: () => {
                  Dialog.confirm({
                    header: (
                      <ExclamationCircleFill
                        style={{
                          fontSize: 48,
                          color: "var(--adm-color-danger)",
                        }}
                      />
                    ),
                    content: <DelConfirmContent content={i.title} />,
                    confirmText: (
                      <span style={{ color: "var(--adm-color-danger)" }}>
                        确定
                      </span>
                    ),
                    onConfirm: () => delHandle(i.id),
                  });
                },
              },
            ]}
          >
            <List.Item
              extra={i.update_date || ""}
              arrow={
                <Button
                  fill="none"
                  style={{ height: "100%", padding: 0 }}
                  loading={
                    detailRetainVisible &&
                    i.id.toString() === detailRetainInfo?.id.toString()
                  }
                >
                  <RightOutline style={{ color: "var(--adm-color-light)" }} />
                </Button>
              }
              onClick={() => {
                setDetailRetainInfo(i);
                setDetailRetainVisible(true);
              }}
            >
              <Ellipsis direction="end" content={i.title} />
            </List.Item>
          </SwipeAction>
        ))}
      </List>
      <MobileRetainInfoDetail
        retainInfo={detailRetainInfo}
        visible={detailRetainVisible}
        setVisible={setDetailRetainVisible}
      />
      <MobileRetainInfoAdd
        orgID={orgID}
        productID={productID}
        ADD_RETAIN={RetainAPI.ADD_RETAIN}
        visible={addRetainVisible}
        setVisible={setAddRetainVisible}
        reload={reload}
      />
      <MobileRetainInfoEdit
        editingItem={editRetainInfo}
        UPDATE_RETAIN={RetainAPI.UPDATE_RETAIN}
        visible={editRetainInfoVisible}
        setVisible={setEditRetainInfoVisible}
        reload={reload}
      />
    </ContentCard>
  );
};
