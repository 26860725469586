import { useEffect, RefObject, useState, useMemo } from "react";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";

import {
  Form,
  Button,
  TextArea,
  DatePicker,
  DatePickerRef,
  Space,
  Radio,
  Input,
  CheckList,
  Popup,
  SearchBar,
  Grid,
  ErrorBlock,
  Card,
  List,
  Divider,
} from "antd-mobile";

import { Upload, UploadFile } from "antd";
import { AddOutline, InformationCircleOutline } from "antd-mobile-icons";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import {
  NEXT_PROCESS_TYPE,
  DELIVERY_DETAIL_TYPE,
  DELIVERY_RELATION_ORDER_LIST,
} from "lib/interface/launchChangeList";
import {
  FormatDayjs,
  FormatDate,
  DisposeForm,
  initEditUploadFileList,
  handleUpload,
} from "utils";
import { useDeliveryDetailComplete } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailComplete";
import { GET_HOSPITAL_ORDER } from "lib/api/deliverylist";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { ADD_FILE } from "lib/api";
import { MobileLimitUpload } from "authenticated-app/mobile/util";
import { RadioValue } from "antd-mobile/es/components/radio";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";

export const DeliveryDetailCompleteModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailComplete();
  const [form] = Form.useForm();
  const client = useHttp();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isOnSite, setIsOnSite] = useState<number | RadioValue>(1);
  const [relationOrderVisible, setRelationOrderVisible] =
    useState<boolean>(false);
  const [relationOrderSearchText, setRelationOrderSearchText] = useState("");
  const [relationOrderSelected, setRelationOrderSelected] = useState<any[]>([]);

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      issues_id_list: relationOrderSelected,
      first_entry_date: FormatDate(value.first_entry_date),
      actual_delivery_date: FormatDate(value.actual_delivery_date),
      attachment_id_list: fileList?.map((i) => i.uid.toString()),
    });
    close();
  };

  const {
    run: getHospitalOrder,
    data: hospitalOrderList,
    isIdle: HospitalOrderListIsIdle,
    isLoading: gettingHospitalOrderList,
  } = useAsync<DELIVERY_RELATION_ORDER_LIST[]>();
  const getHospOrderFunc = (params?: { [key in any]: any }) => {
    if (detailInfo?.hospital_id) {
      getHospitalOrder(client(GET_HOSPITAL_ORDER(detailInfo?.hospital_id)));
    }
  };

  useEffect(() => {
    if (hospitalOrderList) {
      const issues_id_list = detailInfo?.relation_order_list.map(
        (i: any) => i.id,
      );
      if (issues_id_list) {
        setRelationOrderSelected(issues_id_list);
        form.setFieldsValue({
          issues_id_list: issues_id_list,
        });
      }
    }
  }, [hospitalOrderList]);

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      let initList = initEditUploadFileList(detailInfo?.attachment_list);
      setFileList(initList || []);
      getHospOrderFunc();
      form.setFieldsValue({
        ...(detailInfo?.first_entry_date
          ? {
              first_entry_date: FormatDayjs(
                detailInfo?.first_entry_date,
              ).toDate(),
            }
          : { first_entry_date: null }),
        ...(detailInfo?.actual_delivery_date
          ? {
              actual_delivery_date: FormatDayjs(
                detailInfo?.actual_delivery_date,
              ).toDate(),
            }
          : { actual_delivery_date: null }),
        actual_entry_number: detailInfo?.actual_entry_number,
        is_send_back: detailInfo?.is_send_back || 2,
        is_deal_with_on_site: detailInfo?.is_deal_with_on_site || 1,
        is_over_checklist: detailInfo?.is_over_checklist,
        is_update_info: detailInfo?.is_update_info,
        actual_person_days: detailInfo?.actual_person_days,
        supplementary_info: detailInfo?.supplementary_info,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  const filteredItems = useMemo(() => {
    if (relationOrderSearchText) {
      return hospitalOrderList?.filter(
        (item) =>
          item.order_id.includes(relationOrderSearchText) ||
          item.title.includes(relationOrderSearchText),
      );
    } else {
      return hospitalOrderList;
    }
  }, [hospitalOrderList, relationOrderSearchText]);

  const removeElement = (val: any) => {
    const removeData = relationOrderSelected.filter(
      (element) => element !== val,
    );
    return removeData;
    // return relationOrderSelected.filter((element) => element !== val);
  };

  const over_checklist_info = () => {
    if (detailInfo?.delivery_type_name !== "产品撤机") {
      return (
        <Form.Item
          name="is_over_checklist"
          label="已完成退场检查"
          rules={[{ required: true, message: "必须选择是否已完成退场检查" }]}
        >
          <Radio.Group>
            <Radio
              value={1}
              style={{
                marginLeft: "4px",
                "--icon-size": "18px",
                "--font-size": "16px",
                "--gap": "6px",
              }}
            >
              是
            </Radio>
            <Radio
              value={0}
              style={{
                marginLeft: "8px",
                "--icon-size": "18px",
                "--font-size": "16px",
                "--gap": "6px",
              }}
            >
              否
            </Radio>
          </Radio.Group>
        </Form.Item>
      );
    }
  };

  const sendback_info = () => {
    switch (detailInfo?.delivery_type_name) {
      case "产品停用":
        return <></>;
      default:
        return (
          <>
            <Form.Item
              name="is_send_back"
              label="已退回多余物料"
              rules={[
                { required: true, message: "必须选择是否已退回多余物料" },
              ]}
            >
              <Radio.Group>
                <Radio
                  value={1}
                  style={{
                    marginLeft: "4px",
                    "--icon-size": "18px",
                    "--font-size": "16px",
                    "--gap": "6px",
                  }}
                >
                  是
                </Radio>
                <Radio
                  value={0}
                  style={{
                    marginLeft: "4px",
                    "--icon-size": "18px",
                    "--font-size": "16px",
                    "--gap": "6px",
                  }}
                >
                  否
                </Radio>
                <Radio
                  value={2}
                  style={{
                    marginLeft: "4px",
                    "--icon-size": "18px",
                    "--font-size": "16px",
                    "--gap": "6px",
                  }}
                >
                  无多余物料
                </Radio>
              </Radio.Group>
            </Form.Item>
          </>
        );
    }
  };

  const onsite_info = () => {
    switch (detailInfo?.delivery_type_name) {
      case "产品升级":
      case "产品停用":
      case "产品撤机":
      case "硬件部署":
      case "组件升级":
        return (
          <>
            <Form.Item
              name="is_deal_with_on_site"
              label="现场处理"
              rules={[{ required: true, message: "必须选择是否现场处理" }]}
            >
              <Radio.Group
                onChange={(v) => {
                  setIsOnSite(v);
                }}
              >
                <Radio
                  value={1}
                  style={{
                    marginLeft: "4px",
                    "--icon-size": "18px",
                    "--font-size": "16px",
                    "--gap": "6px",
                  }}
                >
                  是
                </Radio>
                <Radio
                  value={0}
                  style={{
                    marginLeft: "4px",
                    "--icon-size": "18px",
                    "--font-size": "16px",
                    "--gap": "6px",
                  }}
                >
                  否
                </Radio>
              </Radio.Group>
            </Form.Item>
          </>
        );
    }
  };

  const tips_info = () => {
    switch (detailInfo?.delivery_type_name) {
      case "产品升级":
      case "产品停用":
      case "产品撤机":
      case "硬件部署":
      case "组件升级":
        return (
          <>
            <List>
              <List.Item
                style={{
                  color: "var(--adm-color-success);",
                  fontSize: "13px",
                }}
              >
                1.现场硬件核对，确保与实际申请相符。
              </List.Item>
              <List.Item
                style={{
                  color: "var(--adm-color-success);",
                  fontSize: "13px",
                }}
              >
                2.产品线及版本核对，确保实际上线与申请相符。
              </List.Item>
              <List.Item
                style={{
                  color: "var(--adm-color-success);",
                  fontSize: "13px",
                }}
              >
                3.通过工程端二维码或手动录入等方式，更新客户相关信息。
              </List.Item>
            </List>
          </>
        );
      default:
        return (
          <>
            <List>
              <List.Item
                style={{
                  color: "var(--adm-color-success);",
                  fontSize: "13px",
                }}
              >
                1.现场硬件核对，确保与实际申请相符。
              </List.Item>
              <List.Item
                style={{
                  color: "var(--adm-color-success);",
                  fontSize: "13px",
                }}
              >
                2.产品线及版本核对，确保实际上线与申请相符。
              </List.Item>
              <List.Item
                style={{
                  color: "var(--adm-color-success);",
                  fontSize: "13px",
                }}
              >
                3.完成ERP下推调入流程。
              </List.Item>
              <List.Item
                style={{
                  color: "var(--adm-color-success);",
                  fontSize: "13px",
                }}
              >
                4.通过工程端二维码或手动录入等方式，更新客户相关信息。
              </List.Item>
            </List>
          </>
        );
    }
  };

  return (
    <EditPopup visible={isOpen} submitting={false} setVisible={close}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={false}
          >
            提交
          </Button>
        }
      >
        <Form.Header>完成交付</Form.Header>
        {over_checklist_info()}
        <Form.Item
          name="is_update_info"
          label="已更新客户信息"
          rules={[{ required: true, message: "必须选择是否已更新客户信息" }]}
        >
          <Radio.Group>
            <Radio
              value={1}
              style={{
                marginLeft: "4px",
                "--icon-size": "18px",
                "--font-size": "16px",
                "--gap": "6px",
              }}
            >
              是
            </Radio>
            <Radio
              value={0}
              style={{
                marginLeft: "4px",
                "--icon-size": "18px",
                "--font-size": "16px",
                "--gap": "6px",
              }}
            >
              否
            </Radio>
          </Radio.Group>
        </Form.Item>
        {sendback_info()}
        {onsite_info()}
        <Form.Item
          name="first_entry_date"
          label="第一次入场日期"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择第一次入场日期" }]}
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
          layout="horizontal"
        >
          <DatePicker title="第一次入场日期" precision="day" tillNow>
            {(value) =>
              value ? (
                <FormItemDiv>{FormatDate(value)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>第一次入场日期</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>

        <Form.Item
          name="actual_entry_number"
          label="实际入场次数"
          rules={[
            {
              required: isOnSite ? true : false,
              message: "必须填写实际入场次数",
            },
          ]}
        >
          <Input type="number" clearable placeholder="填写实际入场次数" />
        </Form.Item>
        <Form.Item
          name="actual_person_days"
          label="实际投入人天"
          rules={[
            {
              required: true,
              message: "必须填写实际交付人天,单位为数值,可输入小数.",
            },
          ]}
        >
          <Input type="number" clearable placeholder="填写实际投入人天" />
        </Form.Item>
        <Form.Item
          name="actual_delivery_date"
          label="实际交付日期"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择实际交付日期" }]}
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
          layout="horizontal"
        >
          <DatePicker title="实际交付日期" precision="day" tillNow>
            {(value) =>
              value ? (
                <FormItemDiv>{FormatDate(value)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>实际交付日期</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>

        <Form.Item name="supplementary_info" label="补充信息" layout="vertical">
          <TextArea autoSize={{ minRows: 3 }} placeholder="填写补充信息" />
        </Form.Item>
        <Form.Item name="issues_id_list" label="关联工单">
          已选择 {relationOrderSelected.length} 个关联工单
          <Button
            size="small"
            color="primary"
            fill="outline"
            onClick={() => {
              setRelationOrderVisible(true);
            }}
            loading={HospitalOrderListIsIdle || gettingHospitalOrderList}
          >
            点击管理
          </Button>
        </Form.Item>
        <Form.Item
          name="attachment"
          className="adm-form-item-label-attachment"
          label="附件 (数量限制10个)"
          normalize={(v) => DisposeForm(v)}
          layout={"vertical"}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={10}
            multiple={true}
            action={ADD_FILE}
            listType="picture"
            fileList={fileList}
            beforeUpload={MobileLimitUpload}
            onChange={(info) => handleUpload(info, setFileList)}
          >
            {fileList.length >= 10 ? null : (
              <Button size="middle" color="primary" fill="outline">
                <Space>
                  <AddOutline />
                  <span>点击上传</span>
                </Space>
              </Button>
            )}
          </Upload>
        </Form.Item>
        <Divider>提交须知</Divider>
        <Card
          // headerStyle={{
          //   color: 'black',
          // }}
          style={{
            paddingTop: "11px",
            border: "1px dashed #e5e5e5",
            justifyContent: "flex-end",
          }}
          title={
            <div style={{ fontWeight: "bold" }}>
              <InformationCircleOutline
                style={{ marginRight: "4px", color: "#1677ff" }}
              />
              完成交付关闭工单前，请确保已完成以下内容：
            </div>
          }
        >
          {tips_info()}
        </Card>
      </Form>
      <Popup
        visible={relationOrderVisible}
        onMaskClick={() => {
          setRelationOrderVisible(false);
        }}
        destroyOnClose
        bodyStyle={{ height: "75vh", overflowY: "scroll" }}
        showCloseButton={true}
        onClose={() => {
          setRelationOrderVisible(false);
        }}
      >
        {filteredItems?.length ? (
          <>
            <div
              style={{
                padding: "12px",
                borderBottom: "solid 1px var(--adm-color-border)",
              }}
            >
              <Grid columns={1}>
                <Grid.Item>
                  <h3>当前已选择 {relationOrderSelected.length} 个关联工单</h3>
                </Grid.Item>
                <Grid.Item>
                  <span
                    style={{ fontSize: "10px", color: "gray", width: "100%" }}
                  >
                    [快捷按钮]点击对应按钮可删除
                  </span>
                </Grid.Item>
                <Grid.Item>
                  <Space wrap style={{ marginTop: "10px" }}>
                    <Button
                      color="primary"
                      fill="outline"
                      size="mini"
                      onClick={() => {
                        setRelationOrderSelected([]);
                      }}
                    >
                      全部清空
                    </Button>
                  </Space>
                </Grid.Item>
                <Grid.Item>
                  <h3>选择关联工单</h3>
                </Grid.Item>
                <Grid.Item>
                  <span
                    style={{ fontSize: "10px", color: "gray", width: "100%" }}
                  >
                    筛选或滑动搜索, 点击选中要关联的工单.
                  </span>
                </Grid.Item>
              </Grid>
              <SearchBar
                placeholder="输入文字过滤选项"
                value={relationOrderSearchText}
                onChange={(v) => {
                  setRelationOrderSearchText(v);
                }}
              />
            </div>
            <div
              style={{
                overflowY: "scroll",
              }}
            >
              <CheckList
                style={{
                  borderTop: 0,
                  borderBottom: 0,
                }}
                value={relationOrderSelected}
                defaultValue={relationOrderSelected}
                onChange={(val) => {
                  setRelationOrderSelected(val);
                }}
                multiple
              >
                {" "}
                {filteredItems?.map((item) => (
                  <CheckList.Item key={item.id} value={item.id}>
                    {item.order_id} {item.title}
                  </CheckList.Item>
                ))}
              </CheckList>
            </div>
          </>
        ) : (
          <>
            <ErrorBlock
              status="empty"
              title="暂无关联工单数据"
              style={{ padding: "64px 0" }}
              description={<h2>没有相关工单可选择</h2>}
            />
          </>
        )}
      </Popup>
    </EditPopup>
  );
};
