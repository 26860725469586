import { Dispatch, SetStateAction, useEffect } from "react";
import { Select, message, Modal, Form } from "antd";

import { useHttp } from "utils/http";
import {
  GET_DOCKING_TYPE,
  GET_DOCKING_WAY,
  GET_HOS_PRODUCT_LIST,
  ADD_DOCKING_INFO,
} from "lib/api";

import { DockingType, DockingWay } from "../interface";
import { HospProductsListType } from "../../hardwareTabPane/interface";
import { useAsync } from "utils/hooks/useAsync";
import { DisposeForm } from "utils";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

const { Option } = Select;

export const DockingBaseInfoAdd = ({
  hospID,
  visible,
  setVisible,
  reload,
}: {
  hospID: number | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const [, setDocking] = useUrlQueryParam([]);

  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getDockingTypeList,
    isLoading: gettingDockingTypeList,
    data: dockingTypeList,
  } = useAsync<DockingType[]>();
  const getDockingTypeListFunc = () => {
    getDockingTypeList(client(GET_DOCKING_TYPE));
  };

  const {
    run: getDockingWayList,
    isLoading: gettingDockingWayList,
    data: dockingWayList,
  } = useAsync<DockingWay[]>();
  const getDockingWayListFunc = () => {
    getDockingWayList(client(GET_DOCKING_WAY));
  };

  const {
    run: getProductTypeList,
    isLoading: gettingProductTypeList,
    data: productTypeList,
  } = useAsync<HospProductsListType[]>();
  const getProductTypeListFunc = (hospital_id: number) => {
    getProductTypeList(client(GET_HOS_PRODUCT_LIST(hospital_id)));
  };

  const submit = (value: any) => {
    if (hospID)
      client(ADD_DOCKING_INFO, {
        data: {
          ...value,
          hospital_id: parseInt(hospID.toString()),
        },
        method: "POST",
      })
        .then((res) => {
          message.success("添加成功！");
          setVisible(false);
          setDocking({ docking: res.id });
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("添加失败，请检查或联系管理员！");
        });
    else {
      message.error("添加失败，请稍后重试或联系管理员！");
    }
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (hospID) {
        getProductTypeListFunc(hospID);
      }
      getDockingWayListFunc();
      getDockingTypeListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="新增基本对接信息"
      destroyOnClose
      open={visible}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      onOk={form.submit}
      okText="提交"
    >
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        onFinish={submit}
      >
        <Form.Item
          name="products"
          label="对接产品线"
          rules={[{ required: true, message: "必须选择对接产品线" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingProductTypeList}
            mode="multiple"
            placeholder="选择产品线"
          >
            {productTypeList?.map((i, index) => (
              <Option key={index} value={i.product_id}>
                {i.product_id}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="docking_type_id"
          label="对接类型"
          rules={[{ required: true, message: "必须选择对接类型" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select loading={gettingDockingTypeList} placeholder="选择对接类型">
            {dockingTypeList?.map((item, index) => (
              <Option key={index} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="cur_docking_id"
          label="现片对接方式"
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingDockingWayList}
            allowClear
            placeholder="选择现片对接方式"
          >
            {dockingWayList?.map((item, index) => (
              <Option key={index} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="history_docking_id"
          label="前片对接方式"
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingDockingWayList}
            allowClear
            placeholder="选择前片对接方式"
          >
            {dockingWayList?.map((item, index) => (
              <Option key={index} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
