import { useEffect } from "react";
import { usePermission } from "context/permission";
import { Tabs, Result, Skeleton, Button } from "antd";
import type { TabsProps } from "antd";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { BreakInfoStats } from "./break-info-stat";
import { BreakProcessStats } from "./break-process-stat";
import {
  BreakFeedbackStats,
  IssuesStats,
} from "./break-customer-feedback-stat";
const defaultTab = "overview";

export const BreakInfo = () => {
  const [{ tab }, setTab] = useUrlQueryParam(["tab"]);
  const { permission } = usePermission();

  useEffect(() => {
    if (!!!tab) {
      setTab({ tab: defaultTab });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const items: TabsProps["items"] = [
    {
      key: "overview",
      label: `工单总览`,
      children: (
        <>
          {permission?.issuesProfile === 1 ? ( // 1
            <BreakInfoStats />
          ) : (
            <>
              {permission?.issuesProfile === 0 ? (
                <Result
                  status="403"
                  title="403"
                  subTitle="抱歉，你无权限访问此页面！"
                  extra={
                    <Button
                      type="primary"
                      onClick={() =>
                        window.location.replace(window.location.origin)
                      }
                    >
                      返回主页
                    </Button>
                  }
                />
              ) : (
                <Skeleton active />
              )}
            </>
          )}
        </>
      ),
    },
    {
      key: "process",
      label: `处理统计`,
      children: (
        <>
          {permission?.issuesProcessProfile === 1 ? (
            <BreakProcessStats />
          ) : (
            <>
              {permission?.issuesProcessProfile === 0 ? (
                <Result
                  status="403"
                  title="403"
                  subTitle="抱歉，你无权限访问此页面！"
                  extra={
                    <Button
                      type="primary"
                      onClick={() =>
                        window.location.replace(window.location.origin)
                      }
                    >
                      返回主页
                    </Button>
                  }
                />
              ) : (
                <Skeleton active />
              )}
            </>
          )}
        </>
      ),
    },
    {
      key: "feedback",
      label: `客户反馈`,
      children: (
        <>
          {permission?.customerBreakProfile === 1 ? (
            <BreakFeedbackStats />
          ) : (
            <>
              {permission?.customerBreakProfile === 0 ? (
                <Result
                  status="403"
                  title="403"
                  subTitle="抱歉，你无权限访问此页面！"
                  extra={
                    <Button
                      type="primary"
                      onClick={() =>
                        window.location.replace(window.location.origin)
                      }
                    >
                      返回主页
                    </Button>
                  }
                />
              ) : (
                <>
                  <Skeleton active />
                </>
              )}
            </>
          )}
        </>
      ),
    },
    {
      key: "issues",
      label: `故障统计`,
      children: (
        <>
          {permission?.customerBreakProfile === 1 ? (
            <IssuesStats />
          ) : (
            <>
              {permission?.customerBreakProfile === 0 ? (
                <Result
                  status="403"
                  title="403"
                  subTitle="抱歉，你无权限访问此页面！"
                  extra={
                    <Button
                      type="primary"
                      onClick={() =>
                        window.location.replace(window.location.origin)
                      }
                    >
                      返回主页
                    </Button>
                  }
                />
              ) : (
                <>
                  <Skeleton active />
                </>
              )}
            </>
          )}
        </>
      ),
    },
  ];
  return (
    <Tabs
      activeKey={tab}
      size="small"
      style={{ marginLeft: "6px" }}
      items={items}
      onChange={(v) => setTab({ tab: v })}
    />
  );
};
