import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Upload } from "antd";
import type { UploadProps } from "antd";
import { Button, NoticeBar, Popup, Result, Space, Toast } from "antd-mobile";
import { RcFile } from "antd/lib/upload/interface";
import { UploadOutline } from "antd-mobile-icons";

import { ADD_RATE_FILE, ADD_RATE_QR } from "lib/api";
import { usePostFile } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";

const fileSize = 1024 * 1024 * 10; // 10M

export const MobileFileRateImport = ({
  hospID,
  hospName,
  visible,
  setVisible,
  reload,
}: {
  hospID: string;
  hospName: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = usePostFile();

  const [isSubSuccess, setIsSubSuccess] = useState<boolean>(false);
  const [isHasError, setIsHasError] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<string[]>([]);

  const { run: postFile, isLoading: postingFile } = useAsync();

  const props: UploadProps = {
    fileList: [],
    maxCount: 1,
    accept: "image/*,.xlsx,.XLSX,.xls,.XLS",
    customRequest(options) {
      const file = options.file as RcFile;
      const isLt2M = file.size <= fileSize;
      let url: string;
      const fmData = new FormData();

      if (isLt2M) {
        if (file.type.startsWith("image/")) {
          url = ADD_RATE_QR;
          fmData.append("qr_code", file);
          fmData.append("hospital_id", hospID || "");
        } else if (
          file.name.toLocaleLowerCase().endsWith("xlsx") ||
          file.name.toLocaleLowerCase().endsWith("xls")
        ) {
          url = ADD_RATE_FILE;
          fmData.append("file", file);
        } else {
          Toast.show({
            icon: "fail",
            content: (
              <ToastContent content={file.name + " 不是Excel或者图片类型"} />
            ),
          });
          return;
        }
      } else {
        Toast.show({
          icon: "fail",
          content: (
            <ToastContent content={file.name + " 文件大小超出10M，无法上传"} />
          ),
        });
        return;
      }

      postFile(
        client(url, {
          body: fmData,
        }),
      )
        .then(async (res) => {
          let msg = await res.json();
          if (msg?.error_list?.length > 0) {
            setIsHasError(true);
            setErrorList(msg?.error_list);
          } else {
            setIsSubSuccess(true);
          }
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="二维码解析失败" />,
          });
        });
    },
  };

  const initStatus = () => {
    setErrorList([]);
    setIsHasError(false);
    setIsSubSuccess(false);
  };

  useEffect(() => {
    if (visible) {
      initStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Popup
      visible={visible}
      destroyOnClose
      bodyStyle={{
        height: isHasError ? "80vh" : "38.2vh",
        overflowY: "scroll",
      }}
      onMaskClick={() => setVisible(false)}
      showCloseButton
      onClose={() => setVisible(false)}
    >
      {isSubSuccess || isHasError ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Result
            status={isHasError ? "error" : "success"}
            title={isHasError ? "提交出错" : "提交成功"}
            description={
              isHasError ? "表格校验出错，详情如下" : "完成导入点击率"
            }
          />
          {isHasError ? (
            <div
              style={{
                margin: 20,
                padding: 5,
                height: "30vh",
                overflow: "scroll",
                backgroundColor: "rgba(224, 224, 224, 1)",
              }}
            >
              {errorList.map((v, i) => (
                <p key={`import-${i}`}>{v}</p>
              ))}
            </div>
          ) : null}
          <div style={{ position: "absolute", bottom: 20, width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  initStatus();
                }}
                style={{ width: "100%", margin: "0 20px 0 10px" }}
              >
                再次上传
              </Button>
              <Button
                color="primary"
                fill="solid"
                onClick={() => {
                  reload();
                  setVisible(false);
                }}
                style={{ width: "100%", margin: "0 20px 0 10px" }}
              >
                返回详情
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <NoticeBar
            content={`二维码上传请对照客户主体，当前：${hospName}`}
            color="alert"
          />
          <NoticeBar
            content={`表格需要使用对应的模板，请前往PC端下载`}
            color="info"
          />
          <NoticeBar
            content={`文件上传支持Excel表格 (xls、xlsx) 和图片 (jpg、jpeg、png) 格式`}
            color="default"
          />
          <div style={{ position: "absolute", bottom: 72, width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Upload {...props}>
                <Button
                  color="primary"
                  loading={postingFile}
                  disabled={postingFile}
                >
                  <Space>
                    <UploadOutline />
                    <span>点击上传</span>
                  </Space>
                </Button>
              </Upload>
            </div>
          </div>
        </>
      )}
    </Popup>
  );
};
