import { Fragment, useEffect, useReducer, useState } from "react";
import {
  Button,
  Descriptions,
  Modal,
  Space,
  Form,
  Input,
  Select,
  message,
  Tooltip,
  Cascader,
} from "antd";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useDebounceFn, useMount } from "ahooks";

import { useHttp } from "utils/http";
import {
  GET_USER_TYPE,
  GET_DEPARTMENT_LIST,
  GET_DEPARTMENT_USER,
  UPDATE_USER,
} from "lib/api";
import {
  DetailCtrlProps,
  DepartmentType,
  DepartmentUserType,
  UpdateUserType,
  UserDepartmentRecord,
  UserType,
} from "../interface";

import { DesItemLabel2, LineDiv } from "../../utils";
import {
  createDeparmentTree,
  DisposeNullStr,
  findDepartmentArray,
} from "utils";
import { useAsync } from "utils/hooks/useAsync";

const { Option } = Select;

const InsiderInfo = ({ detail, reload }: DetailCtrlProps) => {
  const [departmentTree, setDepartmentTree] = useState<DepartmentType[]>();
  const [userEditable, setUserEditable] = useState(false);
  const [userDepartmentRecord, setUserDepartmentRecord] = useState<
    UserDepartmentRecord[]
  >([]);
  // 内部人员信息ref
  const [dingToken, setDingToken] = useState<string>("");
  // 内部人员reducer
  const [, notifyRender] = useReducer((x) => x + 1, 0);

  const client = useHttp();

  const { run: getInsiderType, data: insiderType } = useAsync<UserType[]>();
  const getInsiderTypeHandle = () => {
    getInsiderType(client(GET_USER_TYPE));
  };
  const { run: getInsiderTypeDebounce } = useDebounceFn(getInsiderTypeHandle, {
    wait: 200,
  });

  const { run: getDepartmentList, data: departmentList } =
    useAsync<DepartmentType[]>();
  const getDepartmentListHandle = () => {
    getDepartmentList(client(GET_DEPARTMENT_LIST));
  };
  const { run: getDepartmentListDebounce } = useDebounceFn(
    getDepartmentListHandle,
    {
      wait: 200,
    },
  );

  const updateUserInfo = () => {
    let data: UpdateUserType[] = [];
    userDepartmentRecord.forEach((item) => {
      data.push({
        user_type_id: item.user_type_id,
        user_id: item.user_id,
        ding_group_token: DisposeNullStr(dingToken),
      });
    });
    if (detail?.id)
      client(UPDATE_USER(detail?.id), {
        data,
        method: "POST",
      })
        .then(() => {
          message.success("更新成功！");
          setUserEditable(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error(`更新失败！请检查或者联系管理员`);
        });
  };

  useEffect(() => {
    setDepartmentTree(createDeparmentTree(departmentList || [])[0]?.children);
  }, [departmentList]);

  useEffect(() => {
    let record: UserDepartmentRecord[] = [];
    let insiders = detail?.insiders;
    insiderType?.forEach(async (item) => {
      let typer = insiders?.find((i) => i.type_name === item.name);
      let department_user: DepartmentUserType[] = typer?.department_id
        ? await client(GET_DEPARTMENT_USER(typer.department_id))
        : null;
      record.push({
        user_id: typer?.user_id || null,
        department_id: typer?.department_id || null,
        user_type_id: item.id,
        department_user: department_user,
      });
    });
    setUserDepartmentRecord(record);
    setDingToken(insiders ? insiders[0]?.ding_group_token || "" : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail?.insiders, insiderType]);

  useMount(() => {
    getInsiderTypeDebounce();
    getDepartmentListDebounce();
  });

  return (
    <Fragment>
      <Descriptions
        title="内部人员信息"
        column={4}
        extra={
          <Button
            loading={userEditable}
            onClick={() => setUserEditable(true)}
            icon={<EditOutlined />}
          >
            编辑
          </Button>
        }
      >
        {insiderType?.map((item, index) => (
          <Descriptions.Item
            key={`insider${index}`}
            label={<DesItemLabel2 label={item?.name || "未知"} />}
          >
            <LineDiv>
              {
                detail?.insiders?.find((i) => i.type_name === item.name)
                  ?.user_name
              }
            </LineDiv>
          </Descriptions.Item>
        ))}
      </Descriptions>
      <Modal
        title="编辑内部人员信息"
        open={userEditable}
        destroyOnClose
        maskClosable={false}
        onCancel={() => setUserEditable(false)}
        footer={null}
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          onFinish={updateUserInfo}
          initialValues={{
            DingToken: dingToken,
          }}
        >
          {insiderType?.map((item, index) => {
            let defaultInsider = detail?.insiders?.find(
              (i) => i.type_name === item.name,
            );
            let defaultId = defaultInsider?.user_id;
            let defaultDepartmentID = defaultInsider?.department_id;
            let defaultDepartmentValue = findDepartmentArray(
              departmentTree || null,
              (data: DepartmentType) =>
                data.userDepartmentId === defaultDepartmentID,
            );

            let _index = userDepartmentRecord?.findIndex(
              (i) => i.user_type_id === item.id,
            );
            let valueID = userDepartmentRecord[_index]?.user_id;

            return (
              <Form.Item
                key={`UserDepartmentCascader${index}`}
                name={item.name}
                label={item.name}
              >
                <Space style={{ width: "100%" }} direction={"vertical"}>
                  <Cascader
                    allowClear
                    changeOnSelect
                    fieldNames={{
                      label: "departmentName",
                      value: "userDepartmentId",
                      children: "children",
                    }}
                    placeholder="部门"
                    options={departmentTree}
                    defaultValue={defaultDepartmentValue}
                    onChange={async (v) => {
                      let tmpRecord: UserDepartmentRecord[] =
                        userDepartmentRecord;
                      let index = tmpRecord?.findIndex(
                        (i) => i.user_type_id === item.id,
                      );
                      if (v) {
                        tmpRecord[index].department_user = await client(
                          GET_DEPARTMENT_USER(v[v.length - 1] as number),
                        );
                        tmpRecord[index].user_id = null;
                      }
                      setUserDepartmentRecord(tmpRecord);
                      notifyRender();
                    }}
                  />
                  <Select
                    allowClear
                    defaultValue={defaultId}
                    value={valueID}
                    onChange={(user) => {
                      let tmpRecord: UserDepartmentRecord[] =
                        userDepartmentRecord;
                      let index = tmpRecord?.findIndex(
                        (i) => i.user_type_id === item.id,
                      );
                      tmpRecord[index].user_id = user;
                      setUserDepartmentRecord(tmpRecord);
                      notifyRender();
                    }}
                  >
                    {userDepartmentRecord
                      .find((i) => i.user_type_id === item.id)
                      ?.department_user?.map((v, d) => {
                        return (
                          <Option key={`defaultUserList${d}`} value={v.id}>
                            {v.userName}
                          </Option>
                        );
                      })}
                  </Select>
                </Space>
              </Form.Item>
            );
          })}
          <Form.Item
            name={"DingToken"}
            label={
              <>
                钉群Token&nbsp;
                <Tooltip title={"如何获取钉钉群机器人token"}>
                  <QuestionCircleOutlined
                    onClick={() =>
                      window.open(
                        "https://open.dingtalk.com/document/org/application-types",
                      )
                    }
                    style={{ color: "var(--adm-color-primary)" }}
                  />
                </Tooltip>
              </>
            }
          >
            <Input
              onChange={(v) => setDingToken(v.target.value)}
              disabled={
                userDepartmentRecord
                  ?.map((i) => i.user_id)
                  .find((t) => t !== null)
                  ? false
                  : true
              }
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 14, span: 10 }}>
            <Space>
              <Button onClick={() => setUserEditable(false)}>取消</Button>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default InsiderInfo;
