import { useEffect, RefObject } from "react";
import { Form, TextArea, Button, DatePicker, DatePickerRef } from "antd-mobile";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";

import { useBreakDetailSuspend } from "authenticated-app/hooks/breaklist/trans/useBreakDetailSuspend";
import { FormatTime, FormatDate } from "utils";
import { BREAK_DETAIL_TYPE } from "lib/interface/breaklist";
import { ConvertDateToPickerValueList } from "authenticated-app/mobile/util";
import "authenticated-app/pc/components/EditQuill/quill.snow.css";

const now = new Date();

export const BreakDetailSuspendModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: number[];
  detailInfo: BREAK_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useBreakDetailSuspend();

  const [form] = Form.useForm();

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_time: FormatTime(value.next_time),
      next_process: next,
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        suspension_reason: detailInfo?.suspension_reason,
        next_time: ConvertDateToPickerValueList(detailInfo?.next_time),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <EditPopup visible={isOpen} submitting={false} setVisible={close}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={false}
          >
            提交
          </Button>
        }
      >
        <Form.Header>挂起工单</Form.Header>
        <Form.Item
          name="suspension_reason"
          label="挂起原因"
          rules={[{ required: true, message: "必须填写挂起原因" }]}
          layout="vertical"
        >
          <TextArea placeholder="填写挂起原因" autoSize />
        </Form.Item>
        <Form.Item
          name="next_time"
          label="下次跟进日期"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择下次跟进日期" }]}
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
        >
          <DatePicker title="下次跟进日期" precision="day" tillNow min={now}>
            {(value) =>
              value ? (
                <FormItemDiv>{FormatDate(value)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择跟进日期</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
