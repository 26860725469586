import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-components";
import { Button, Col, Divider, Row, Select, Space, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDebounceFn, useMount } from "ahooks";

import { useHttp } from "utils/http";
import { HosNodesType } from "authenticated-app/pc/customer/customer-list/interface";
import { useAsync } from "utils/hooks/useAsync";
import { GET_HOSP_NODES } from "lib/api";
import { selectFilter } from "utils";
import { BaseValueType } from "../interface";
import { GET_CLIENT_STATISTIC, GET_DOCKING_STATISTIC } from "../api";
import { MyPieChart, MyPieChartType } from "../chart/pie-chart";
import { MyBarChart, MyBarChartType } from "../chart/bar-chart";
import { DockingStatisticType } from "../interface";
import { GetClusterNum } from "./calculate-value/cluster-num";
import { GetDockingTypeNum } from "./calculate-value/docking-type-num";
import { GetProductTypeNum } from "./calculate-value/product-type-num";
import { GetClientNum } from "./calculate-value/client-num";
import { useWindowSize } from "utils/hooks/useWindowSize";

const { Option } = Select;

export const DockingClientInfo = () => {
  const client = useHttp();
  const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [provinceList, setProvinceList] = useState<HosNodesType[]>();
  const [searchArea, setSearchArea] = useState<number>();
  const [searchProvince, setSearchProvince] = useState<number>();

  const [clientProvinceList, setClientProvinceList] =
    useState<HosNodesType[]>();
  const [clientSearchArea, setClientSearchArea] = useState<number>();
  const [clientSearchProvince, setClientSearchProvince] = useState<number>();

  const [clusterNum, setClusterNum] = useState<MyPieChartType>();
  const [dockingTypeNum, setDockingTypeNum] = useState<MyPieChartType>();
  const [productTypeNum, setProductTypeNum] = useState<MyPieChartType>();

  const [clientNum, setClientNum] = useState<MyBarChartType>();

  const { height } = useWindowSize();

  const {
    run: getHosNodes,
    isLoading: gettingNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };
  const { run: getHosNodesDebounce } = useDebounceFn(getHosNodesHandle, {
    wait: 200,
  });

  const {
    run: getDockingStatistic,
    isLoading: gettingDockingStatistic,
    data: dockingStatistic,
  } = useAsync<DockingStatisticType>();
  const getDockingStatisticHandle = () => {
    getDockingStatistic(
      client(GET_DOCKING_STATISTIC, {
        data: {
          area_id: searchArea,
          province_id: searchProvince,
        },
      }),
    );
  };
  const { run: getDockingStatisticDebounce } = useDebounceFn(
    getDockingStatisticHandle,
    { wait: 200 },
  );

  const {
    run: getClientStatistic,
    isLoading: gettingClientStatistic,
    data: clientStatistic,
  } = useAsync<BaseValueType[]>();
  const getClientStatisticHandle = () => {
    getClientStatistic(
      client(GET_CLIENT_STATISTIC, {
        data: {
          area_id: clientSearchArea,
          province_id: clientSearchProvince,
        },
      }),
    );
  };
  const { run: getClientStatisticDebounce } = useDebounceFn(
    getClientStatisticHandle,
    { wait: 200 },
  );

  useEffect(() => {
    let provinceListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.parentNodeId === searchArea && item.parentNodeId !== null) {
        provinceListTMP?.push(item);
      }
    });
    setProvinceList(provinceListTMP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchArea]);

  useEffect(() => {
    let provinceListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (
        item.parentNodeId === clientSearchArea &&
        item.parentNodeId !== null
      ) {
        provinceListTMP?.push(item);
      }
    });
    setClientProvinceList(provinceListTMP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSearchArea]);

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.nodeLevel === 1 && item.nodeName !== "海外") {
        areaListTMP?.push(item);
      }
    });
    setAreaList(areaListTMP);
  }, [hosNodes]);

  useEffect(() => {
    if (dockingStatistic) {
      setClusterNum(GetClusterNum(dockingStatistic));
      setDockingTypeNum(GetDockingTypeNum(dockingStatistic));
      setProductTypeNum(GetProductTypeNum(dockingStatistic));
    }
  }, [dockingStatistic]);

  useEffect(() => {
    if (clientStatistic) {
      setClientNum(GetClientNum(clientStatistic));
    }
  }, [clientStatistic]);

  useMount(() => {
    getHosNodesDebounce();
    getDockingStatisticDebounce();
    getClientStatisticDebounce();
  });

  const DockingLoading = gettingNodes || gettingDockingStatistic;
  const ClientLoading = gettingNodes || gettingClientStatistic;

  return (
    <div style={{ height: "100%", minWidth: 988 }}>
      <Spin spinning={DockingLoading}>
        <PageHeader
          style={{ padding: 0 }}
          title="对接、部署与产品统计"
          extra={
            <Space>
              <Select
                allowClear
                style={{ width: 120 }}
                placeholder="区域"
                disabled={DockingLoading}
                loading={DockingLoading}
                value={searchArea}
                onChange={(nodeId) => {
                  setSearchProvince(undefined);
                  setSearchArea(nodeId);
                }}
              >
                {areaList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Select
                disabled={DockingLoading}
                loading={DockingLoading}
                value={searchProvince}
                allowClear
                placeholder="省份"
                style={{ width: 140 }}
                showSearch
                filterOption={selectFilter}
                onChange={(nodeId) => {
                  setSearchProvince(nodeId);
                }}
              >
                {provinceList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                loading={DockingLoading}
                disabled={DockingLoading}
                onClick={getDockingStatisticHandle}
              >
                查询
              </Button>
            </Space>
          }
        />
        <Row
          style={{ height: (height - 256) / 2, width: "100%", minHeight: 300 }}
        >
          <Col span={8}>
            <MyPieChart Data={clusterNum} />
          </Col>
          <Col span={8}>
            <MyPieChart Data={dockingTypeNum} />
          </Col>
          <Col span={8}>
            <MyPieChart Data={productTypeNum} />
          </Col>
        </Row>
      </Spin>
      <Divider style={{ marginBottom: 16, marginTop: 0 }} />
      <Spin spinning={ClientLoading}>
        <PageHeader
          style={{ padding: 0 }}
          title="客户端数量统计"
          extra={
            <Space>
              <Select
                allowClear
                style={{ width: 120 }}
                placeholder="区域"
                disabled={DockingLoading}
                loading={DockingLoading}
                value={clientSearchArea}
                onChange={(nodeId) => {
                  setClientSearchProvince(undefined);
                  setClientSearchArea(nodeId);
                }}
              >
                {areaList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Select
                disabled={DockingLoading}
                loading={DockingLoading}
                value={clientSearchProvince}
                allowClear
                placeholder="省份"
                style={{ width: 140 }}
                showSearch
                filterOption={selectFilter}
                onChange={(nodeId) => {
                  setClientSearchProvince(nodeId);
                }}
              >
                {clientProvinceList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                loading={ClientLoading}
                disabled={ClientLoading}
                onClick={getClientStatisticHandle}
              >
                查询
              </Button>
            </Space>
          }
        />
        <Row
          style={{
            height: (height - 256) / 2 > 403 ? (height - 256) / 2 : 403,
            width: "100%",
            minHeight: 403,
          }}
        >
          <MyBarChart Data={clientNum} />
        </Row>
      </Spin>
    </div>
  );
};
