import qs from "qs";
import * as auth from "context/auth/provider";
import { message } from "antd";
import { ErrorMsg } from "interface";
import { useCallback } from "react";

interface Config extends RequestInit {
  token?: string | null;
  data?: object;
}
export const http = async (
  endpoint: string,
  { data, token, headers, ...customConfig }: Config = {},
) => {
  const config = {
    method: "GET", // 默认GET，后面会被customeConfig覆盖
    headers: {
      Authorization: token ? `jwt ${token}` : "",
      "Content-Type": data ? "application/json" : "",
      ...headers,
    },
    ...customConfig,
  };
  if (endpoint.startsWith("/monitorAPI/v1")) {
    config.headers = {
      Authorization: `Basic dXNlcjAwMTpjY2MyNmRhN2I5YWJhNTMzY2JiMjYzYTM2YzA3ZGNjNQ==`,
      "Content-Type": data ? "application/json" : "",
      ...headers,
    };
  }
  if (config.method.toUpperCase() === "GET") {
    if (data) {
      endpoint += `?${qs.stringify(data)}`;
    }
  } else {
    config.body = JSON.stringify(data || {});
  }

  return window
    .fetch(`${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        await auth.logout();
        return Promise.reject({ errorMsg: "请重新登录" });
      }
      if (response.status === 504) {
        return Promise.reject({ errorMsg: "请求超时，请稍后再试！" });
      }
      if (response.ok) {
        try {
          const data = await response.json();
          return data;
        } catch (error) {
          const data = {};
          return data;
        }
      } else {
        return Promise.reject(response);
      }
    })
    .catch(async (e) => {
      let msg: ErrorMsg;
      try {
        msg = await e.json();
      } catch {
        msg = e;
      }
      console.error(msg);
      message.error(
        msg?.errorMsg || msg?.detail || "未知错误，请联系管理员或刷新重试！",
      );
      // throw Promise.reject(e);
      throw e;
    });
};

export const useHttp = () => {
  const token = auth.getToken();
  return useCallback(
    (...[endpoint, config]: Parameters<typeof http>) =>
      http(endpoint, { ...config, token }),
    [token],
  );
};

export const postFile = async (
  endpoint: string,
  { token, ...customConfig }: Config = {},
) => {
  const config = {
    method: "POST",
    headers: {
      Authorization: token ? `jwt ${token}` : "",
    },
    ...customConfig,
  };

  return window
    .fetch(`${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        await auth.logout();
        return Promise.reject({ errorMsg: "请重新登录" });
      }
      if (response.ok) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    })
    .catch(async (e) => {
      let msg: ErrorMsg;
      try {
        msg = await e.json();
      } catch {
        msg = e;
      }
      console.error(msg);
      message.error(
        msg?.errorMsg || msg?.detail || "未知错误，请联系管理员或刷新重试！",
      );
      throw Promise.reject(e);
    });
};

export const usePostFile = () => {
  const token = auth.getToken();
  return useCallback(
    (...[endpoint, config]: Parameters<typeof postFile>) =>
      postFile(endpoint, { ...config, token }),
    [token],
  );
};
