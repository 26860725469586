import { ErrorBlock } from "antd-mobile";
import {
  InformationCircleOutline,
  CalculatorOutline,
  HistogramOutline,
  AppOutline,
  ShrinkOutline,
  MoreOutline,
  TransportQRcodeOutline,
} from "antd-mobile-icons";

import { MobileBaseInfo } from "./base-info";
import { MobileProductInfo } from "./product-info";
import { MobileHardwareInfo } from "./hardware-info";
import { MobileRateInfo } from "./rate-info";
import { MobileDockingInfo } from "./docking-info";
import { MobileRemoteInfo } from "./remote-info";
import { MobileClientInfo } from "./client-info";

export const baseKey = "base";
export const productKey = "product";
export const hardwareKey = "hardware";
export const dockingKey = "docking";
export const ratelKey = "rate";
export const remoteKey = "remote";
export const clientKey = "client";

export const tabs = [
  {
    key: baseKey,
    title: "详情",
    icon: <InformationCircleOutline />,
  },
  {
    key: productKey,
    title: "产品",
    icon: <AppOutline />,
  },
  {
    key: hardwareKey,
    title: "硬件",
    icon: <CalculatorOutline />,
  },
  {
    key: dockingKey,
    title: "对接",
    icon: <ShrinkOutline />,
  },
  {
    key: remoteKey,
    title: "远程",
    icon: <TransportQRcodeOutline />,
  },
  {
    key: ratelKey,
    title: "点击率",
    icon: <HistogramOutline />,
  },
];

export const DetailBodyComponent = (
  tabKey: string,
  hospName: string,
  hospCode: string,
) => {
  switch (tabKey) {
    case baseKey:
      return <MobileBaseInfo />;
    case productKey:
      return <MobileProductInfo />;
    case hardwareKey:
      return <MobileHardwareInfo />;
    case dockingKey:
      return <MobileDockingInfo hospName={hospName} hospCode={hospCode} />;
    case ratelKey:
      return <MobileRateInfo hospName={hospName} />;
    case remoteKey:
      return <MobileRemoteInfo hospName={hospName} hospCode={hospCode} />;
    case clientKey:
      return <MobileClientInfo />;
    default:
      return <ErrorBlock status="empty" />;
  }
};
