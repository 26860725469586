import { Popup, CheckList, Grid, SearchBar } from "antd-mobile";
import { Dispatch, SetStateAction, useState, useEffect, useRef } from "react";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { useDebounceFn } from "ahooks";
import { SearchBarRef } from "antd-mobile/es/components/search-bar";
import { CheckCircleFill } from "antd-mobile-icons";

export const SelectPopup = ({
  label,
  searchLabel,
  visible,
  api,
  setVisible,
  reload = () => {},
  height = 90,
  seleceted,
  setSelected,
}: {
  label: string;
  searchLabel?: string;
  visible: boolean;
  api: string;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload?: () => void;
  height?: number;
  seleceted: any[];
  setSelected: Dispatch<SetStateAction<any[]>>;
}) => {
  const client = useHttp();
  const searchRef = useRef<SearchBarRef>(null);
  const [searchText, setSearchText] = useState<string>();
  const [currentValue, setCurrentValue] = useState<any[]>([]);
  const [currentItems, setCurrentItems] = useState<any[]>([]);
  const [responseData, setResponseData] = useState<any[]>([]);
  const [emptyFalg, setEmptyFlag] = useState<boolean>();
  const { run, data, isLoading, isIdle } = useAsync<any>();
  const runHandle = (value: string) => {
    run(client(`${api}${value}`));
  };
  const { run: runDebounce } = useDebounceFn(runHandle, {
    wait: 200,
  });

  const closer = () => {
    setSelected(currentItems);
    setVisible(false);
  };

  const handleAdd = (val: any) => {
    const addItemId = val[0];
    const addItem = responseData?.filter((item: any) => item.id === addItemId);
    setResponseData(responseData?.filter((item: any) => item.id !== addItemId));
    setCurrentItems([...currentItems, ...addItem]);
    setCurrentValue([...currentValue, addItemId]);
    // setResponseData([]);
    // searchRef.current?.clear();
  };

  useEffect(() => {
    setResponseData(
      data?.filter((item: any) => !currentValue.includes(item.id)),
    );
  }, [data]);

  useEffect(() => {
    if (visible) {
      setCurrentValue(seleceted.map((item) => item.id));
      setCurrentItems(seleceted);
      setResponseData([]);
      setSearchText("");
      searchRef.current?.clear();
    }
  }, [visible]);

  return (
    <>
      <Popup
        visible={visible}
        onMaskClick={closer}
        destroyOnClose
        bodyStyle={{ height: `${height}vh`, overflowY: "scroll" }}
        showCloseButton={true}
        onClose={closer}
      >
        <div
          style={{
            padding: "12px",
            borderBottom: "solid 1px var(--adm-color-border)",
          }}
        >
          <Grid columns={1}>
            <Grid.Item>
              <h3>
                当前已选择 {currentValue.length} 个{label}
              </h3>
            </Grid.Item>
            <Grid.Item>
              <div
                style={{
                  overflowY: "scroll",
                }}
              >
                <CheckList
                  style={{
                    borderTop: 0,
                    borderBottom: 0,
                  }}
                  value={currentValue}
                  defaultValue={currentValue}
                  onChange={(val) => {
                    setCurrentItems(
                      currentItems?.filter((item) => val.includes(item.id)),
                    );
                    setCurrentValue(val);
                  }}
                  extra={(active) => (active ? <CheckCircleFill /> : null)}
                >
                  {currentItems?.map((item) => (
                    <CheckList.Item key={item.id} value={item.id}>
                      {item.order_id} {item.title}
                    </CheckList.Item>
                  ))}
                </CheckList>
              </div>
            </Grid.Item>
            <Grid.Item>
              <h4>搜索添加{label}</h4>
            </Grid.Item>
          </Grid>
          <SearchBar
            ref={searchRef}
            clearable
            onClear={() => {
              setResponseData([]);
            }}
            placeholder={`输入${searchLabel}进行搜索添加`}
            value={searchText}
            onChange={(v) => {
              setSearchText(v);
            }}
            onSearch={() => {
              if (searchText) {
                runDebounce(searchText);
              }
            }}
          />
        </div>
        <div
          style={{
            overflowY: "scroll",
          }}
        >
          <CheckList
            style={{
              borderTop: 0,
              borderBottom: 0,
            }}
            onChange={handleAdd}
          >
            {responseData?.map((item: any) => (
              <CheckList.Item key={item.id} value={item.id}>
                {item.order_id} {item.title}
              </CheckList.Item>
            ))}
          </CheckList>
        </div>
      </Popup>
    </>
  );
};
