import { useEffect, useRef, useState } from "react";
import { Button, Input, Divider } from "antd";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import { useHttp } from "utils/http";
import { HOSP_LIST_API } from "lib/api";
import Highlighter from "react-highlight-words";
import {
  ProTable,
  ProColumns,
  ProFormInstance,
  ActionType,
} from "@ant-design/pro-components";
import { HospListType } from "interface";
import { DetailPage } from "./detalPage";
import { trimStr } from "utils";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import "./index.css";

export const CustomerDetail = () => {
  const [hospID, setHospID] = useState<string | null>(null);
  const [{ hospitalId }, setHospitalId] = useUrlQueryParam(["hospitalId"]);
  const [clickHospInfo, setClickHospInfo] = useState<HospListType>();
  const [disChange, setDisChange] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const actionRef = useRef<ActionType>();
  const [loading, setLoading] = useState(false);
  const { height } = useWindowSize();
  const client = useHttp();
  const formRef = useRef<ProFormInstance>();
  const [page, setPage] = useState<number>(1);
  const [isMounted, setIsMounted] = useState(false);
  const [defaultFlag, setDefaultFlag] = useState(false);
  const [highlight, setHighlight] = useState(false);
  const columns: ProColumns<HospListType>[] = [
    {
      search: false,
      dataIndex: "name",
      width: "30%",
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            gap: "8px",
          }}
        >
          <Input
            placeholder="输入搜索客户名称"
            allowClear
            value={searchText}
            onChange={(e) => {
              if (e.target.value === "") {
                setSearchText("");
                setSearchedColumn("");
                setDefaultFlag(true);
              } else {
                setSearchText(e.target.value);
                setSearchedColumn("name");
              }
            }}
            onPressEnter={() => {
              setPage(1);
              setHospID(null);
              setHospitalId({ hospitalId: "" });
              actionRef.current?.reload();
            }}
            style={{ flex: 2 }}
            disabled={disChange}
          />
          <Button
            size="small"
            icon={<UndoOutlined />}
            onClick={() => {
              setHighlight(true);
              actionRef.current?.reload();
            }}
            disabled={disChange}
          />
          <Button
            type="primary"
            size="small"
            icon={<SearchOutlined />}
            onClick={() => {
              setPage(1);
              setHospID(null);
              setHospitalId({ hospitalId: "" });
              setSearchedColumn("name");
              actionRef.current?.reload();
            }}
            disabled={disChange}
          />
        </div>
      ),

      render: (text) =>
        searchedColumn === "name" ? (
          <div
            style={{ height: "40px", lineHeight: "40px", overflow: "hidden" }}
          >
            <Highlighter
              highlightStyle={{
                backgroundColor: "#ffc069",
                padding: 0,
              }}
              searchWords={[trimStr(searchText) || ""]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          </div>
        ) : (
          <div
            style={{ height: "40px", lineHeight: "40px", overflow: "hidden" }}
          >
            {text}
          </div>
        ),
    },
  ];

  useEffect(() => {
    if (hospitalId && hospID !== hospitalId) {
      setHospID(hospitalId);
    }
  }, [hospitalId]);

  const fetchHospitalPage = async (targetId: number) => {
    try {
      const result = await client(HOSP_LIST_API, {
        data: { hospital_id: targetId.toString() },
      });
      return result;
    } catch (error) {
      console.error("获取页码失败：", error);
      return 1;
    }
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div
      style={{
        height: "100%",
        minWidth: "928px",
        display: "flex",
      }}
    >
      {isMounted && (
        <>
          <ProTable<HospListType>
            loading={loading}
            columns={columns}
            rowKey="id"
            style={{ height: "100%", width: "248px", minWidth: "248px" }}
            size="large"
            actionRef={actionRef}
            formRef={formRef}
            toolBarRender={false}
            search={false}
            pagination={{
              simple: true,
              showQuickJumper: true,
              showSizeChanger: false,
              pageSize:
                Math.trunc((height - 207) / 72) > 9
                  ? Math.trunc((height - 207) / 72)
                  : 9,
              showTotal: () => null,
              current: page,
              onChange: (v) => {
                if (v !== page) {
                  setPage(v);
                  setHospID(null);
                  setHospitalId({ hospitalId: "" });
                  setHighlight(false);
                }
              },
            }}
            request={async (params) => {
              let data;
              let total;
              let current;
              try {
                setLoading(true);
                const { ...rest } = params;
                const currentHospId = hospID ? parseInt(hospID) : null;
                if (currentHospId && !!!defaultFlag) {
                  const result = await fetchHospitalPage(Number(currentHospId));
                  setClickHospInfo(result[0]);
                  setHospID(result[0].id.toString());
                  setHospitalId({ hospitalId: result[0].id.toString() });
                  setSearchText(result[0].name || "");
                  data = result || [];
                  total = result.length || 0;
                  current = 1;
                } else {
                  const result = await client(HOSP_LIST_API, {
                    data: {
                      pageSize: params.pageSize,
                      current: page,
                      ...rest,
                      ...(params.hospital_status?.length
                        ? {
                            hospital_status: params.hospital_status.join("|"),
                          }
                        : {}),
                      ...(searchText ? { name: searchText } : {}),
                    },
                  });
                  if (!!!highlight) {
                    setClickHospInfo(result.results[0]);
                    setHospID(result.results[0].id.toString());
                    setHospitalId({
                      hospitalId: result.results[0].id.toString(),
                    });
                  }
                  setDefaultFlag(true);

                  data = result.results || [];
                  total = result.count || 0;
                  current = result.current;
                }
                return {
                  data: data,
                  total: total,
                  success: true,
                  current: current,
                };
              } catch (e) {
                return { success: false, data: [], total: 0, current: 1 };
              } finally {
                setLoading(false);
              }
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  setClickHospInfo(record);
                  setHospitalId({ hospitalId: record?.id.toString() });
                },
              };
            }}
            rowClassName={(record) => {
              if (hospID && record.id === parseInt(hospID)) {
                return "selectedRow";
              }
              return "";
            }}
            onReset={() => {
              formRef.current?.setFieldsValue({
                area_id: undefined,
                province_id: undefined,
                city_id: undefined,
              });
            }}
          />
          <Divider style={{ height: "100%" }} type="vertical" />
          <div
            style={{
              height: "100%",
              flex: 1,
              overflow: "auto",
            }}
          >
            <DetailPage
              hospID={hospID ? parseInt(hospID) : null}
              hospInfo={clickHospInfo}
              setDisChange={setDisChange}
            />
          </div>
        </>
      )}
    </div>
  );
};
