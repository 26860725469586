import { SetStateAction, Dispatch, useEffect } from "react";
import { Modal, Form, Input, Select, message } from "antd";

import { useHttp } from "utils/http";
import { ADD_DOCKING_VERSION_INFO, GET_PRODUCT_VERSION_BY_ID } from "lib/api";
import { DisposeForm } from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { ProductVersionType } from "../../productTabPane/interface";
import { IpRegex } from "utils/regexp";

const { TextArea } = Input;
const { Option } = Select;

export const DockingVersionInfoAdd = ({
  title,
  hospID,
  visible,
  setVisible,
  reload,
}: {
  title: string;
  hospID: number | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getDsVersionList,
    isLoading: gettingDsVersionList,
    data: ubVersionList,
  } = useAsync<ProductVersionType[]>();
  const getDsVersionListFunc = () => {
    getDsVersionList(client(GET_PRODUCT_VERSION_BY_ID("DS")));
  };

  const submit = (value: any) => {
    if (hospID)
      client(ADD_DOCKING_VERSION_INFO, {
        data: {
          ...value,
          hospital_id: hospID,
        },
        method: "POST",
      })
        .then(() => {
          message.success("添加成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("添加失败，请检查或联系管理员！");
        });
    else {
      message.error("添加失败，请稍后重试或联系管理员！");
    }
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      getDsVersionListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={`新增${title}`}
      destroyOnClose
      open={visible}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      onOk={form.submit}
      okText="提交"
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={submit}
      >
        <Form.Item
          name="ip"
          label="IP"
          rules={[
            { required: true, message: "必须输入IP" },
            {
              pattern: IpRegex(),
              message: "必须输入合法的IP格式",
            },
          ]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="version_id"
          label="DS版本"
          rules={[{ required: true, message: "必须选择DS版本" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select loading={gettingDsVersionList} placeholder="选择DS版本">
            {ubVersionList?.map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
          normalize={(v) => DisposeForm(v)}
          rules={[{ required: true, message: "必须输入备注" }]}
        >
          <TextArea allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};
