import { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  FloatingBubble,
  List,
  Picker,
  Popover,
  PullToRefresh,
  Skeleton,
  Toast,
} from "antd-mobile";
import {
  RightOutline,
  EditSOutline,
  FileOutline,
  AddOutline,
  CloseOutline,
} from "antd-mobile-icons";
import { PickerColumn, PickerValue } from "antd-mobile/es/components/picker";
import { Action } from "antd-mobile/es/components/popover";
import dayjs from "dayjs";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { GET_HOS_PRODUCT_LIST, GET_RATE } from "lib/api";
import { formatDate, isR, RID, RIDCHINESENAME, THID } from "utils";
import {
  DateStep,
  RateType,
} from "authenticated-app/pc/customer/rate-detail/interface";
import { HospProductsListType } from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";
import { MobileCalculate } from "./calculate";
import { MobileRateImportManually } from "./rate-import/manually-import";
import { MobileFileRateImport } from "./rate-import/file-import";
import { RateLineChart } from "authenticated-app/pc/customer/rate-detail/hos-detail/rateLineChart";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { useDebounceFn } from "ahooks";

const now = new Date();

enum DatePickerType {
  start,
  end,
}

const changeStep = (step: DateStep) => {
  switch (step) {
    case DateStep.day:
      return "日";
    case DateStep.week:
      return "周";
    case DateStep.month:
      return "月";
    case DateStep.year:
      return "年";
    default:
      return "日";
  }
};

export const MobileRateInfo = ({ hospName }: { hospName: string }) => {
  const [{ detailId }] = useUrlQueryParam(["detailId"]);

  const client = useHttp();

  const [product, setProduct] = useState<string | null>(null);
  const [queryStep, setQueryStep] = useState<DateStep>(DateStep.day);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(
    dayjs().subtract(30, "days"),
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());

  const [productPickerColumns, setProductPickerColumns] = useState<
    PickerColumn[]
  >([]);
  const [productPickerValue, setProductPickerValue] = useState<PickerValue[]>(
    [],
  );
  const [productPickerVisible, setProductPickerVisible] =
    useState<boolean>(false);

  const [stepPickerVisible, setStepPickerVisible] = useState<boolean>(false);

  const [datePickerVisible, setDatePickerVisible] = useState<boolean>();
  const [datePickerType, setDatePickerType] = useState<DatePickerType>();

  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [manualVisible, setManualVisible] = useState<boolean>(false);

  const [fileImportVisible, setFileImportVisible] = useState<boolean>(false);

  const actions: Action[] = [
    // { key: "scan", icon: <ScanningOutline />, text: "扫码导入" },
    // { key: "picture", icon: <PicturesOutline />, text: "二维码导入", disabled: true },
    {
      key: "file",
      icon: <FileOutline />,
      text: "文件导入",
      onClick: () => {
        setPopoverVisible(false);
        setFileImportVisible(true);
      },
    },
    {
      key: "manual",
      icon: <EditSOutline />,
      text: "手动导入",
      onClick: () => {
        setPopoverVisible(false);
        setManualVisible(true);
      },
    },
  ];

  const {
    run: getHospRate,
    isLoading: gettingHospRate,
    data: hospRate,
  } = useAsync<RateType>();
  const getHospRateHandle = ({
    product_line,
    unit,
    start,
    end,
  }: {
    product_line?: string;
    unit?: DateStep;
    start?: dayjs.Dayjs;
    end?: dayjs.Dayjs;
  }) => {
    if (detailId) {
      getHospRate(
        client(GET_RATE, {
          data: {
            hospital_id: detailId,
            product_line: product_line || product,
            unit: unit || queryStep,
            start: formatDate(start) || formatDate(startDate),
            end: formatDate(end) || formatDate(endDate),
          },
        }),
      );
    } else {
      Toast.show({
        icon: "fail",
        content: <ToastContent content="获取失败" />,
      });
    }
  };
  const { run: getHospRateHandleDebounce } = useDebounceFn(getHospRateHandle, {
    wait: 200,
  });

  const {
    run: getHosProductList,
    isLoading: gettingHosProductList,
    data: productList,
    setData: setProductList,
  } = useAsync<HospProductsListType[]>();
  const getHosProductListFunc = (hospital_id: number | string) => {
    getHosProductList(client(GET_HOS_PRODUCT_LIST(hospital_id))).then(
      (res: HospProductsListType[]) => {
        // 肺结节、骨折、肺炎三者任一一个出现均只展示肺结节
        if (res?.find((p) => isR(p.product_id))) {
          let tmplist = res.filter((i) => !isR(i.product_id));
          tmplist.push({
            product_id: RID,
            product_chinese_name: RIDCHINESENAME,
          });
          setProduct(() => {
            let id = RID;
            setProductPickerValue([id]);
            getHospRateHandleDebounce({ product_line: id });
            return id;
          });
          setProductList(tmplist);
        } else {
          if (res.length > 0) {
            setProduct(() => {
              let id = res[0].product_id;
              setProductPickerValue([id]);
              getHospRateHandleDebounce({ product_line: id });
              return id;
            });
          }
        }
      },
    );
  };

  const { run: getHosProductListFuncDebounce } = useDebounceFn(
    getHosProductListFunc,
    { wait: 200 },
  );
  useEffect(() => {
    if (detailId) {
      getHosProductListFuncDebounce(detailId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailId]);

  useEffect(() => {
    if (productList && productList?.length > 0) {
      let tmpColumns: PickerColumn = [];
      productList.forEach((i) => {
        tmpColumns.push({
          label: `${i.product_id} ${i.product_chinese_name}`,
          value: `${i.product_id}`,
        });
      });
      setProductPickerColumns([tmpColumns]);
    }
  }, [productList]);

  const Loading = gettingHospRate || gettingHosProductList;

  return (
    <div style={{ width: "100%" }}>
      <LoadingMask visible={Loading} />
      <PullToRefresh
        onRefresh={async () => {
          if (product) {
            getHospRateHandleDebounce({ product_line: product });
          } else if (detailId) {
            getHosProductListFunc(detailId);
          }
        }}
        refreshingText={null}
      >
        <div className="customer-content">
          <ContentCard>
            <List mode="card" header={<ListHeader text="查询参数" />}>
              <List.Item
                extra={
                  productList?.find((i) => i.product_id === product)
                    ?.product_id || "未知"
                }
                arrow={
                  <Button
                    fill="none"
                    style={{ height: "100%", padding: 0 }}
                    loading={productPickerVisible}
                  >
                    <RightOutline style={{ color: "var(--adm-color-light)" }} />
                  </Button>
                }
                onClick={() => {
                  setProductPickerVisible(true);
                }}
              >
                产品线
              </List.Item>
              <List.Item
                extra={changeStep(queryStep) || "未知"}
                arrow={
                  <Button
                    fill="none"
                    style={{ height: "100%", padding: 0 }}
                    loading={stepPickerVisible}
                  >
                    <RightOutline style={{ color: "var(--adm-color-light)" }} />
                  </Button>
                }
                onClick={() => {
                  setStepPickerVisible(true);
                }}
              >
                查询单位
              </List.Item>
              <List.Item
                extra={formatDate(startDate) || "未知"}
                arrow={
                  <Button
                    fill="none"
                    style={{ height: "100%", padding: 0 }}
                    loading={
                      datePickerVisible &&
                      datePickerType === DatePickerType.start
                    }
                  >
                    <RightOutline style={{ color: "var(--adm-color-light)" }} />
                  </Button>
                }
                onClick={() => {
                  setDatePickerType(DatePickerType.start);
                  setDatePickerVisible(true);
                }}
              >
                查询开始日期
              </List.Item>
              <List.Item
                extra={formatDate(endDate) || "未知"}
                arrow={
                  <Button
                    fill="none"
                    style={{ height: "100%", padding: 0 }}
                    loading={
                      datePickerVisible && datePickerType === DatePickerType.end
                    }
                  >
                    <RightOutline style={{ color: "var(--adm-color-light)" }} />
                  </Button>
                }
                onClick={() => {
                  setDatePickerType(DatePickerType.end);
                  setDatePickerVisible(true);
                }}
              >
                查询截止日期
              </List.Item>
            </List>
          </ContentCard>
          <MobileCalculate
            data={hospRate?.calculate}
            isTh={product === THID}
            isR={isR(product)}
          />
          {gettingHospRate ? (
            <>
              <Skeleton.Title />
              <Skeleton.Paragraph lineCount={5} />
            </>
          ) : (
            <RateLineChart
              data={hospRate?.results}
              unit={hospRate?.unit || DateStep.day}
              isTh={product === THID}
              ismobile={true}
              isDownload={false}
              chartHeight={300}
              product={product}
            />
          )}
        </div>
      </PullToRefresh>
      <Picker
        closeOnMaskClick={false}
        title={"选择产品线"}
        columns={productPickerColumns}
        visible={productPickerVisible}
        onClose={() => {
          setProductPickerVisible(false);
        }}
        value={productPickerValue}
        onConfirm={(v) => {
          setProductPickerValue(v);
          setProduct(v[0] as string);
          getHospRateHandleDebounce({
            product_line: (v[0] as string) || undefined,
          });
        }}
      />
      <Picker
        closeOnMaskClick={false}
        title={"选择查询单位"}
        columns={[
          [
            { label: "日", value: DateStep.day },
            { label: "周", value: DateStep.week },
            { label: "月", value: DateStep.month },
            { label: "年", value: DateStep.year },
          ],
        ]}
        visible={stepPickerVisible}
        onClose={() => {
          setStepPickerVisible(false);
        }}
        value={[queryStep] as PickerValue[]}
        onConfirm={(v) => {
          setQueryStep(v[0] as DateStep);
          getHospRateHandleDebounce({ unit: v[0] as DateStep });
        }}
      />
      <DatePicker
        closeOnMaskClick={false}
        title={
          datePickerType === DatePickerType.start
            ? "选择查询开始日期"
            : datePickerType === DatePickerType.end
            ? "选择查询结束日期"
            : "选择日期"
        }
        visible={datePickerVisible}
        value={
          datePickerType === DatePickerType.start
            ? startDate.toDate()
            : datePickerType === DatePickerType.end
            ? endDate.toDate()
            : null
        }
        onClose={() => {
          setDatePickerVisible(false);
        }}
        max={now}
        onConfirm={(v) => {
          switch (datePickerType) {
            case DatePickerType.start:
              if (dayjs(v) > endDate) {
                Toast.show({
                  icon: "fail",
                  content: <ToastContent content="开始日期不能晚于截止日期" />,
                });
              } else {
                setStartDate(dayjs(v));
                getHospRateHandleDebounce({ start: dayjs(v) });
              }
              break;
            case DatePickerType.end:
              if (dayjs(v) < startDate) {
                Toast.show({
                  icon: "fail",
                  content: <ToastContent content="截止日期不能早于开始日期" />,
                });
              } else {
                setEndDate(dayjs(v));
                getHospRateHandleDebounce({ end: dayjs(v) });
              }
              break;
            default:
              Toast.show({
                icon: "fail",
                content: <ToastContent content="变更失败" />,
              });
              break;
          }
        }}
        tillNow
      />
      <FloatingBubble
        axis="y"
        magnetic="x"
        style={{
          "--initial-position-bottom": "62px",
          "--initial-position-right": "12px",
          "--edge-distance": "12px 12px 62px 12px",
          "--z-index": "999",
        }}
        onClick={() => {
          setPopoverVisible(!popoverVisible);
        }}
      >
        <Popover.Menu
          mode="dark"
          actions={actions}
          trigger="click"
          visible={popoverVisible}
        >
          <Button
            fill="none"
            style={{ padding: 0, color: "var(--adm-color-white)" }}
            shape="rounded"
          >
            {popoverVisible ? (
              <CloseOutline fontSize={28} />
            ) : (
              <AddOutline fontSize={32} />
            )}
          </Button>
        </Popover.Menu>
      </FloatingBubble>
      <MobileRateImportManually
        hospID={detailId}
        hospName={hospName}
        productList={productList}
        defaultProduct={product}
        visible={manualVisible}
        setVisible={setManualVisible}
        reload={() => getHospRateHandleDebounce({})}
      />
      <MobileFileRateImport
        hospID={detailId}
        hospName={hospName}
        visible={fileImportVisible}
        setVisible={setFileImportVisible}
        reload={() => getHospRateHandleDebounce({})}
      />
    </div>
  );
};
