import { Dispatch, SetStateAction } from "react";
import { Popup, List, Badge, Tag } from "antd-mobile";

import { RemoteAssetType } from "authenticated-app/pc/customer/customer-detail/tab-pane/remoteTabPane/interface";

export const MobileRemoteAssetDetail = ({
  assetInfo,
  visible,
  setVisible,
}: {
  assetInfo: RemoteAssetType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <>
      <Popup
        visible={visible}
        destroyOnClose
        bodyStyle={{ height: "70vh", overflowY: "scroll" }}
        onMaskClick={() => setVisible(false)}
        showCloseButton
        onClose={() => setVisible(false)}
      >
        <List
          mode="card"
          header={<p style={{ fontWeight: "bold" }}>远程资产详情</p>}
        >
          <List.Item extra={assetInfo?.ip || "未知"}>远程IP</List.Item>
          <List.Item extra={assetInfo?.asset_name || "未知"}>
            资产名称
          </List.Item>
          <List.Item
            extra={
              <>
                <Tag
                  fill="solid"
                  color={
                    assetInfo?.connectivity === "ok"
                      ? "success"
                      : assetInfo?.connectivity === "failed"
                      ? "danger"
                      : "default"
                  }
                >
                  {assetInfo?.connectivity === "ok"
                    ? "可连接"
                    : assetInfo?.connectivity === "failed"
                    ? "连接失败"
                    : assetInfo?.connectivity === "unknown"
                    ? "未知"
                    : assetInfo?.connectivity}
                </Tag>
              </>
            }
          >
            可连接性
          </List.Item>
          <List.Item extra={assetInfo?.apn || "未知"}>APN</List.Item>
          <List.Item extra={assetInfo?.last_user || "未知"}>
            最后连接人{" "}
          </List.Item>
          <List.Item extra={assetInfo?.last_connect || "未知"}>
            最后连接时间
          </List.Item>
          <List.Item
            extra={
              <>
                <Tag
                  color={assetInfo?.status === "已激活" ? "success" : "danger"}
                >
                  {assetInfo?.status}
                </Tag>
              </>
            }
          >
            卡状态
          </List.Item>
        </List>
      </Popup>
    </>
  );
};

export default MobileRemoteAssetDetail;
