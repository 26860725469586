import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  message,
  Select,
  Divider,
  Switch,
  Skeleton,
  Tooltip,
  Cascader,
} from "antd";
import { AlertOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import { createNodesTree, DisposeForm, selectFilter } from "utils";
import {
  IHospitalGroup,
  InstanceData,
  IPackageData,
} from "authenticated-app/pc/setting/customer-setting/interface";
import {
  CityType,
  HosNodesTree,
} from "authenticated-app/pc/customer/customer-list/interface";
import { HospListType } from "interface";
import { GET_HOSP_CITY } from "lib/api";
import { PackageType } from "../../interface";

const { Option } = Select;

export const SelectedPermissionAdd = ({
  loading,
  packageData,
  packageList,
  areaList,
  hospList,
  groutList,
  visible,
  setVisible,
  update,
}: {
  loading: boolean;
  packageData: IPackageData[];
  packageList: PackageType[];
  areaList: HosNodesTree[];
  hospList: HospListType[];
  groutList: IHospitalGroup[];
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  update: Dispatch<SetStateAction<IPackageData[]>>;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [isAllInstance, setIsAllInstance] = useState<boolean>(false);

  const [nodesTree, setNodesTree] = useState<HosNodesTree[]>();

  const submit = (value: any) => {
    // 1-area, 2-province, 3-city, 4-hos, 5-hosGroup
    try {
      let instanceDataVOS: InstanceData[] = [];
      value?.hospitals?.forEach((i: number) => {
        instanceDataVOS.push({
          nodeId: i,
          nodeType: 4,
        });
      });

      value?.areas?.forEach((i: string[]) => {
        let aeraInfo = i.slice(-1)[0].split("_");
        instanceDataVOS.push({
          nodeId: parseInt(aeraInfo[1]),
          nodeType: parseInt(aeraInfo[0]),
          provinceId:
            parseInt(aeraInfo[0]) === 3
              ? parseInt(i.slice(-2)[0].split("_")[1])
              : null,
        });
      });

      value?.hosGroups?.forEach((i: number) => {
        instanceDataVOS.push({
          nodeId: i,
          nodeType: 5,
        });
      });

      let packageInfo = value?.package?.split("_");
      let data = {
        package: value?.package,
        packageId: parseInt(packageInfo[0]),
        packageName: packageInfo[1] as string,
        isAllInstance: isAllInstance ? 1 : 0,
        instanceDataVOS: isAllInstance ? [] : instanceDataVOS,
      };

      let tmpResourceList = packageData;
      tmpResourceList.push({ ...data, key: packageData.length });
      update(tmpResourceList);
      setVisible(false);
      message.success("提交成功");
    } catch (error) {
      console.error(error);
      message.error("提交出错，请联系管理员！");
    }
  };

  const loadData = (selectedNode: any[]) => {
    const targetNode = selectedNode.find((i) => i.nodeLevel === 2);
    if (!targetNode?.children?.length && targetNode) {
      client(GET_HOSP_CITY(targetNode.nodeId)).then((res: CityType[]) => {
        targetNode.children = res.map((c) => ({
          nodeId: c.id,
          nodeLevel: 3,
          parentNodeId: c.provinceId,
          nodeName: c.cityName,
          label: c.cityName,
          value: `3_${c.id}`,
          children: [],
          isLeaf: true,
        }));
        setNodesTree([...(nodesTree || [])]);
      });
    }
  };

  const init = () => {
    setIsAllInstance(false);
    form.resetFields();
  };

  useEffect(() => {
    if (areaList.length) {
      setNodesTree(createNodesTree(areaList)[0]?.children);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaList.length]);

  useEffect(() => {
    if (visible) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="新增关联资源"
      destroyOnClose
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={"38.2%"}
    >
      <Skeleton loading={loading}>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          onFinish={submit}
        >
          <Form.Item
            name="package"
            label={"权限"}
            rules={[{ required: true, message: `必须选择权限` }]}
            normalize={(v) => DisposeForm(v)}
          >
            <Select
              showSearch
              filterOption={selectFilter}
              placeholder="选择权限"
            >
              {packageList?.map((item) => (
                <Option
                  key={item.packageId}
                  value={`${item.packageId}_${item.packageName}`}
                >
                  {item.packageName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Divider />
          <Form.Item
            label={
              <>
                节点全选&nbsp;
                <Tooltip title={"以下几项没有层级关系，都可以多选，也可以不选"}>
                  <AlertOutlined
                    style={{ color: "var(--adm-color-primary)" }}
                  />
                </Tooltip>
              </>
            }
          >
            <Switch
              checkedChildren="是"
              unCheckedChildren="否"
              checked={isAllInstance}
              onClick={(v) => setIsAllInstance(v)}
            />
          </Form.Item>
          {isAllInstance ? null : (
            <>
              <Form.Item
                name="areas"
                label="区域城市"
                normalize={(v) => DisposeForm(v)}
              >
                <Cascader
                  loading={nodesTree === undefined}
                  multiple
                  allowClear
                  loadData={loadData}
                  placeholder="选择区域(多选)"
                  options={nodesTree}
                />
              </Form.Item>
              <Form.Item
                name="hospitals"
                label="医院"
                normalize={(v) => DisposeForm(v)}
              >
                <Select
                  mode="multiple"
                  allowClear
                  disabled={isAllInstance}
                  placeholder="选择医院(多选)"
                  showSearch
                  filterOption={selectFilter}
                >
                  {hospList?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="hosGroups"
                label="虚拟分组"
                normalize={(v) => DisposeForm(v)}
              >
                <Select
                  mode="multiple"
                  allowClear
                  disabled={isAllInstance}
                  placeholder="选择虚拟分组(多选)"
                  showSearch
                  filterOption={selectFilter}
                >
                  {groutList?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.groupName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          <Divider />
          <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
            <Space>
              <Button htmlType="button" onClick={() => setVisible(false)}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Skeleton>
    </Modal>
  );
};
