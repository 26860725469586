import { SetStateAction, Dispatch, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  Input,
  message,
  Select,
  Divider,
  DatePicker,
  Cascader,
  Skeleton,
} from "antd";
import dayjs from "dayjs";

import { useHttp } from "utils/http";
import {
  DisposeForm,
  selectFilter,
  createDeparmentTree,
  cascaderFilter,
  findDepartmentArray,
} from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { DepartmentType } from "authenticated-app/pc/customer/customer-detail/tab-pane/infoTabPane/interface";
import { GET_DEPARTMENT_LIST } from "lib/api";
import SelectedPermission from "./selected-permission";
import { GET_PERMISSION_ASSOSIATION_BY_ID, GET_USER_LIST } from "../api";
import {
  PermissionAssosiationType,
  IPackageData,
  IUser,
} from "authenticated-app/pc/setting/customer-setting/interface";

const { Option } = Select;

export const PermissionAssociationSettingEdit = ({
  CTRL_UPDATE,
  edittinItem,
  visible,
  setVisible,
  reload,
}: {
  CTRL_UPDATE: (org_id: number) => string;
  edittinItem: PermissionAssosiationType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [editPackageDataVOS, setEditPackageDataVOS] = useState<IPackageData[]>(
    [],
  );
  const [departmentTree, setDepartmentTree] = useState<DepartmentType[]>();

  const {
    run: getPermissionDetailInfo,
    isIdle: idleGetPermissionDetailInfo,
    isLoading: gettingPermissionDetailInfo,
    data: permissionDetailInfo,
  } = useAsync<PermissionAssosiationType>();
  const getPermissionDetailInfoHandle = (id: number) => {
    getPermissionDetailInfo(client(GET_PERMISSION_ASSOSIATION_BY_ID(id)));
  };

  const {
    run: getDepartmentList,
    isIdle: idleGetDepartmentList,
    isLoading: gettingDepartmentList,
    data: departmentList,
  } = useAsync<DepartmentType[]>();
  const getDepartmentListHandle = () => {
    getDepartmentList(client(GET_DEPARTMENT_LIST));
  };

  const {
    run: getUserList,
    isIdle: idleGetUserList,
    isLoading: gettingUserList,
    data: userList,
  } = useAsync<IUser[]>();
  const getUserListHandle = () => {
    getUserList(client(GET_USER_LIST));
  };

  const init = () => {
    getDepartmentListHandle();
    getUserListHandle();
  };

  const submit = (value: any) => {
    if (edittinItem?.id) {
      client(CTRL_UPDATE(1), {
        data: {
          ...value,
          id: edittinItem?.id,
          authorityDepartmentIds: value?.authorityDepartmentIds?.map(
            (i: number[]) => i.slice(-1)[0],
          ),
          packageDataVOS: editPackageDataVOS,
          permissionStartDate: value?.permissionStartDate.toISOString(),
          permissionEndDate: value?.permissionEndDate.toISOString(),
        },
        method: "PUT",
      })
        .then(() => {
          message.success("添加成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error("submit obj is:");
          console.error(value);
          console.error(e);
          message.error("添加失败，请检查或联系管理员！");
        });
    } else {
      console.error("无法获取ID");
      console.error(edittinItem);
    }
  };

  useEffect(() => {
    if (permissionDetailInfo) {
      let tmpEditPackageDataVOS = permissionDetailInfo?.packageDataVOS?.map(
        (value, index) => ({
          ...value,
          key: index,
        }),
      );
      setEditPackageDataVOS(tmpEditPackageDataVOS || []);
      form.setFieldsValue({
        ...permissionDetailInfo,
        authorityDepartmentIds:
          permissionDetailInfo?.authorityDepartmentIds?.map((i) =>
            findDepartmentArray(
              departmentTree || null,
              (data: DepartmentType) => data.userDepartmentId === i,
            ),
          ),
        authorityUserIds: permissionDetailInfo?.authorityUserIds?.length
          ? permissionDetailInfo?.authorityUserIds
          : undefined,
        permissionStartDate: dayjs(permissionDetailInfo?.permissionStartDate),
        permissionEndDate: dayjs(permissionDetailInfo?.permissionEndDate),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionDetailInfo]);

  useEffect(() => {
    if (departmentTree) {
      if (edittinItem?.id) {
        getPermissionDetailInfoHandle(edittinItem?.id);
      } else {
        console.error("无法获取ID");
        console.error(edittinItem);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentTree]);

  useEffect(() => {
    if (departmentList) {
      setDepartmentTree(createDeparmentTree(departmentList)[0]?.children);
    }
  }, [departmentList]);

  useEffect(() => {
    if (visible) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={`编辑权限关联规则`}
      destroyOnClose
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width="61.8%"
    >
      <Skeleton
        loading={
          gettingPermissionDetailInfo ||
          idleGetPermissionDetailInfo ||
          gettingDepartmentList ||
          idleGetDepartmentList ||
          gettingUserList ||
          idleGetUserList
        }
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={submit}
        >
          <Form.Item
            name="title"
            label={"名称"}
            rules={[{ required: true, message: `必须输入权限名称` }]}
            normalize={(v) => DisposeForm(v)}
          >
            <Input placeholder="输入关联权限名称" maxLength={50} />
          </Form.Item>
          <Divider />
          <Form.Item
            name="authorityDepartmentIds"
            label={"部门"}
            normalize={(v) => DisposeForm(v)}
          >
            <Cascader
              loading={gettingDepartmentList}
              multiple
              allowClear
              showSearch={{
                filter: cascaderFilter,
              }}
              fieldNames={{
                label: "departmentName",
                value: "userDepartmentId",
                children: "children",
              }}
              placeholder="选择部门(多选)"
              options={departmentTree as any}
            />
          </Form.Item>
          <Form.Item
            name="authorityUserIds"
            label={"用户"}
            normalize={(v) => DisposeForm(v)}
          >
            <Select
              mode="multiple"
              loading={gettingUserList}
              showSearch
              allowClear
              placeholder="选择用户(多选)"
              filterOption={selectFilter}
            >
              {userList?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.userName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Divider />
          <Form.Item
            name="packageDataVOS"
            label={"已选权限列表"}
            normalize={(v) => DisposeForm(v)}
          >
            <SelectedPermission
              packageData={editPackageDataVOS}
              update={setEditPackageDataVOS}
            />
          </Form.Item>
          <Divider />
          <Form.Item
            name="permissionStartDate"
            label={"生效日期"}
            normalize={(v) => DisposeForm(v)}
            rules={[{ required: true, message: `必须输入生效日期` }]}
          >
            <DatePicker placeholder="结束时间" picker={"date"} />
          </Form.Item>
          <Form.Item
            name="permissionEndDate"
            label={"失效日期"}
            normalize={(v) => DisposeForm(v)}
            rules={[{ required: true, message: `必须输入失效日期` }]}
          >
            <DatePicker placeholder="结束时间" picker={"date"} />
          </Form.Item>
          <Divider />
          <Form.Item wrapperCol={{ offset: 17, span: 6 }}>
            <Space>
              <Button htmlType="button" onClick={() => setVisible(false)}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Skeleton>
    </Modal>
  );
};
