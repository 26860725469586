export enum priorityStatus {
  highest = "Higest",
  high = "High",
  medium = "Medium",
  low = "Low",
  lowest = "Lowest",
}

export const priorityColor = new Map([
  [priorityStatus.highest, "#CE0000"],
  [priorityStatus.high, "#EA4444"],
  ["high", "#EA4444"], // 测试用
  [priorityStatus.medium, "#EA7D24"],
  [priorityStatus.low, "#2A8735"],
  [priorityStatus.lowest, "#55A557"],
]);

export const priorityDes = new Map([
  [priorityStatus.highest, "Highest"],
  [priorityStatus.high, "High"],
  [priorityStatus.medium, "Medium"],
  [priorityStatus.low, "Low"],
  [priorityStatus.lowest, "Lowest"],
]);
