import { IdType } from ".";
import { TicketPrefix } from "./prefix";

export const GET_ORDER_TYPE = `${TicketPrefix}/issues/order_type`;

export const GET_ISSUES_MODULE = `${TicketPrefix}/issues/issue_module`;
export const GET_ISSUES_REASON = `${TicketPrefix}/issues/issue_reason`;
export const GET_ISSUES_REASON_NEW = (id: IdType) =>
  `${TicketPrefix}/issues/issue_reason?issue_type_id=${id}`;

export const GET_ISSUES_TYPE = `${TicketPrefix}/issues/issue_type`;

export const GET_ISSUES_FEEDBACK_CHANNEL = `${TicketPrefix}/issues/feedback_channel`;

export const GET_BREAKLIST = `${TicketPrefix}/issues/issues`;
export const ADD_BREAKLIST = `${TicketPrefix}/issues/issues`;

export const GET_BREAK_DETAIL = (id: IdType) =>
  `${TicketPrefix}/issues/issues/${id}`;
export const UPDATE_BREAK_DETAIL = (id: IdType) =>
  `${TicketPrefix}/issues/issues/${id}`;
export const DELETE_BREAK_DETAIL = (id: IdType) =>
  `${TicketPrefix}/issues/issues/${id}`;
export const UPDATE_BREAK_FLLOW_DETAIL = (id: IdType) =>
  `${TicketPrefix}/issues/follow/issues/${id}`;

export const TRANS_BREAK = (id: IdType) =>
  `${TicketPrefix}/issues/issues_trans/${id}`;

export const GET_DEPARTMENT_USERS = (ids: number[]) =>
  `${TicketPrefix}/issues/get_deparement_users?department_ids=${ids
    .map((i) => i)
    .join("|")}`;
export const GET_DEPARTMENT_ALL_USERS = `${TicketPrefix}/issues/get_deparement_users`;

export const GET_BREAKLIST_FILE = `${TicketPrefix}/issues/issues`;

export const GET_EVENT_TYPE = `${TicketPrefix}/issues/event_type`;

export const GET_VISIT_TYPE = `${TicketPrefix}/issues/visit_type`;

export const GET_VISIT_LIST = `${TicketPrefix}/issues/visit_info`;
export const ADD_VISIT_LIST = `${TicketPrefix}/issues/visit_info`;

export const GET_BUSINESS_TYPE = `${TicketPrefix}/issues/business_opportunity_type`;
export const GET_BUSINESS_LIST = `${TicketPrefix}/issues/visit_info`;

export const GET_VISIT_DETAIL = (id: IdType) =>
  `${TicketPrefix}/issues/visit_info/${id}`;

export const UPDATE_VISIT_DETAIL = (id: IdType) =>
  `${TicketPrefix}/issues/visit_info/${id}`;

export const GET_VISITLIST_FILE = `${TicketPrefix}/issues/visit_info`;
