import { VolumeStatisticsType } from "../../interface";
import { MyBarChartType } from "../../chart/bar-chart";

const GetType = (data: VolumeStatisticsType[]) => {
  let tmpType: Map<string, { data: number }> = new Map();
  for (let r of data) {
    if (r.status.length) {
      r.status.forEach((i) => {
        if (!tmpType.has(i.name)) {
          tmpType.set(i.name, { data: 0 });
        }
      });
      break;
    }
  }
  return tmpType;
};

export const GetStatus = (data: VolumeStatisticsType[]) => {
  let tmpType = GetType(data);

  let tmpData: MyBarChartType = {
    xData: [],
    series: [],
  };

  data.forEach((item) => {
    item.status.forEach((i) => {
      tmpType.get(i.name)!.data += i.value;
    });
  });

  let tmpSeriesData: number[] = [];
  tmpType.forEach((v, k) => {
    tmpData.xData?.push(k);
    tmpSeriesData.push(v.data);
  });
  tmpData.series?.push({ data: tmpSeriesData, type: "bar", barWidth: 10 });

  return tmpData;
};
