import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useDeliveryDetailPause = () => {
  const [{ openDeliveryDetailPause }, setopenDeliveryDetailPause] =
    useUrlQueryParam(["openDeliveryDetailPause"]);

  const open = () =>
    setopenDeliveryDetailPause({ openDeliveryDetailPause: true });
  const close = () =>
    setopenDeliveryDetailPause({
      openDeliveryDetailPause: undefined,
    });

  return [openDeliveryDetailPause === "true", open, close] as const;
};
