import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useDeliveryDetailComplete = () => {
  const [{ openDeliveryDetailComplete }, setopenDeliveryDetailComplete] =
    useUrlQueryParam(["openDeliveryDetailComplete"]);

  const open = () =>
    setopenDeliveryDetailComplete({ openDeliveryDetailComplete: true });
  const close = () =>
    setopenDeliveryDetailComplete({
      openDeliveryDetailComplete: undefined,
    });

  return [openDeliveryDetailComplete === "true", open, close] as const;
};
