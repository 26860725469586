import { Dispatch, SetStateAction } from "react";
import { Upload } from "antd";
import type { UploadProps } from "antd";
import { Button, Dialog, Toast } from "antd-mobile";
import { RcFile } from "antd/lib/upload/interface";

import { SERVICE_RECORD, SERVICE_RECORD_CONFIRM } from "lib/api";
import { usePostFile } from "utils/http";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";

const fileSize = 1024 * 1024 * 20; // 20M

export const MobileServerInfoScan = ({
  postFile,
  confirmScan,
  setIsActionsVisible,
}: {
  postFile: (promise: Promise<unknown>) => Promise<any>;
  confirmScan: (promise: Promise<unknown>) => Promise<any>;
  setIsActionsVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  const client = usePostFile();

  const props: UploadProps = {
    fileList: [],
    maxCount: 1,
    accept: "image/*",
    customRequest(options) {
      const file = options.file as RcFile;
      const isLt2M = file.size <= fileSize;
      let url: string;
      const fmData = new FormData();

      if (isLt2M) {
        if (file.type.startsWith("image/")) {
          url = SERVICE_RECORD;
          fmData.append("qr_code", file);
        } else {
          Toast.show({
            icon: "fail",
            content: <ToastContent content={file.name + " 不是图片类型"} />,
          });
          return;
        }
      } else {
        Toast.show({
          icon: "fail",
          content: (
            <ToastContent content={file.name + " 图片大小超出20M，无法上传"} />
          ),
        });
        return;
      }
      setIsActionsVisible(false);
      postFile(
        client(url, {
          body: fmData,
        }),
      )
        .then(async (res) => {
          let msg = await res.json();
          Dialog.confirm({
            content: `是否提交如下医院的采集数据: ${msg.hospital_list?.map(
              (i: string) => i,
            )}`,
            onConfirm: () => {
              const fmData = new FormData();
              fmData.append("qr_code", file);
              confirmScan(
                client(SERVICE_RECORD_CONFIRM, {
                  body: fmData,
                }),
              )
                .then(() => {
                  Toast.show({
                    icon: "success",
                    content: <ToastContent content={"提交成功"} />,
                  });
                })
                .catch(() => {
                  Toast.show({
                    icon: "fail",
                    content: <ToastContent content={"提交失败"} />,
                  });
                });
            },
          });
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="二维码解析失败" />,
          });
        });
    },
  };

  return (
    <Upload {...props}>
      <Button fill="none" style={{ padding: "3px 0 0 0", display: "flex" }}>
        数据采集
      </Button>
    </Upload>
  );
};
