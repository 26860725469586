import { message } from "antd";
import { ErrorMsg } from "interface";

const defaultMsg = "未知错误，请联系管理员或刷新重试！";

const AlertErrorMsg = async (e: any) => {
  console.error(e);
  try {
    let msg: ErrorMsg = await e.json();
    message.error(msg?.errorMsg || defaultMsg);
  } catch {
    if (typeof e === "string") {
      message.error(e);
    } else {
      message.error(defaultMsg);
    }
  }
};

export default AlertErrorMsg;
