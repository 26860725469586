import { useEffect, useState, Dispatch, SetStateAction, useMemo } from "react";

import {
  Button,
  Form,
  Space,
  TextArea,
  Toast,
  Grid,
  CheckList,
  Popup,
  ErrorBlock,
  SearchBar,
} from "antd-mobile";

import { Upload, UploadFile } from "antd";
import { AddOutline } from "antd-mobile-icons";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { DisposeForm, initEditUploadFileList, handleUpload } from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { ADD_FILE } from "lib/api";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { MobileLimitUpload } from "authenticated-app/mobile/util";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import {
  DELIVERY_DETAIL_TYPE,
  DELIVERY_RELATION_ORDER_LIST,
} from "lib/interface/launchChangeList";
import {
  GET_HOSPITAL_ORDER,
  UPDATE_DELIVERY_DETAIL,
} from "lib/api/deliverylist";

export const MobileEditDeliveryPopup = ({
  editingItem,
  visible,
  setVisible,
  reload,
}: {
  editingItem: DELIVERY_DETAIL_TYPE | undefined | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const client = useHttp();
  const { run: update, isLoading: submitting } = useAsync();
  const [selectedIssuesTypeId, setSelectedIssuesTypeId] = useState<number>();
  const [relationOrderVisible, setRelationOrderVisible] =
    useState<boolean>(false);
  const [relationOrderSearchText, setRelationOrderSearchText] = useState("");
  const [relationOrderSelected, setRelationOrderSelected] = useState<any[]>([]);

  const onSubmit = (value: any) => {
    if (editingItem?.id) {
      client(UPDATE_DELIVERY_DETAIL(editingItem?.id), {
        method: "PUT",
        data: {
          ...value,
          issues_id_list: relationOrderSelected,
          attachment_id_list: fileList?.map((i) => i.uid.toString()),
        },
      })
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content={"更新成功"} />,
          });
          // close();
          setVisible(false);
          reload();
        })
        .catch(() => {
          Toast.show({
            icon: "fail",
            content: <ToastContent content={"更新失败"} />,
          });
        });
    }
  };

  const [loading, setLoading] = useState(true);

  const {
    run: getHospitalOrder,
    data: hospitalOrderList,
    isIdle: HospitalOrderListIsIdle,
    isLoading: gettingHospitalOrderList,
  } = useAsync<DELIVERY_RELATION_ORDER_LIST[]>();
  const getHospOrderFunc = (params?: { [key in any]: any }) => {
    if (editingItem?.hospital_id) {
      getHospitalOrder(client(GET_HOSPITAL_ORDER(editingItem?.hospital_id)));
    }
  };

  const filteredItems = useMemo(() => {
    if (relationOrderSearchText) {
      return hospitalOrderList?.filter(
        (item) =>
          item.order_id.includes(relationOrderSearchText) ||
          item.title.includes(relationOrderSearchText),
      );
    } else {
      return hospitalOrderList;
    }
  }, [hospitalOrderList, relationOrderSearchText]);

  useEffect(() => {
    if (hospitalOrderList) {
      const issues_id_list = editingItem?.relation_order_list.map(
        (i: any) => i.id,
      );
      if (issues_id_list) {
        setRelationOrderSelected(issues_id_list);
        form.setFieldsValue({
          issues_id_list: issues_id_list,
        });
      }
    }
  }, [hospitalOrderList]);

  useEffect(() => {
    if (visible && editingItem) {
      form.resetFields();
      let initList = initEditUploadFileList(editingItem?.attachment_list);
      setFileList(initList || []);
      getHospOrderFunc();
      form.setFieldsValue({
        supplementary_info: editingItem?.supplementary_info,
      });

      setLoading(false);
    }
  }, [visible, editingItem]);

  return (
    <EditPopup
      visible={visible}
      submitting={submitting}
      setVisible={setVisible}
      reload={reload}
    >
      <LoadingMask visible={loading || submitting} />
      <Form
        form={form}
        onFinish={onSubmit}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={submitting}
          >
            提交
          </Button>
        }
      >
        <Form.Header>编辑工单</Form.Header>

        <Form.Item name="supplementary_info" label="补充信息" layout="vertical">
          <TextArea autoSize={{ minRows: 3 }} placeholder="填写补充信息" />
        </Form.Item>

        <Form.Item name="issues_id_list" label="关联工单">
          已选择 {relationOrderSelected.length} 个关联工单
          <Button
            size="small"
            color="primary"
            fill="outline"
            onClick={() => {
              setRelationOrderVisible(true);
            }}
            loading={HospitalOrderListIsIdle || gettingHospitalOrderList}
          >
            点击管理
          </Button>
        </Form.Item>
        <Form.Item
          name="attachment"
          className="adm-form-item-label-attachment"
          label="附件 (数量限制10个)"
          normalize={(v) => DisposeForm(v)}
          layout={"vertical"}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={10}
            multiple={true}
            action={ADD_FILE}
            listType="picture"
            fileList={fileList}
            beforeUpload={MobileLimitUpload}
            onChange={(info) => handleUpload(info, setFileList)}
          >
            {fileList.length >= 10 ? null : (
              <Button size="middle" color="primary" fill="outline">
                <Space>
                  <AddOutline />
                  <span>点击上传</span>
                </Space>
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
      <Popup
        visible={relationOrderVisible}
        onMaskClick={() => {
          setRelationOrderVisible(false);
        }}
        destroyOnClose
        bodyStyle={{ height: "75vh", overflowY: "scroll" }}
        showCloseButton={true}
        onClose={() => {
          setRelationOrderVisible(false);
        }}
      >
        {filteredItems?.length ? (
          <>
            <div
              style={{
                padding: "12px",
                borderBottom: "solid 1px var(--adm-color-border)",
              }}
            >
              <Grid columns={1}>
                <Grid.Item>
                  <h3>当前已选择 {relationOrderSelected.length} 个关联工单</h3>
                </Grid.Item>
                <Grid.Item>
                  <span
                    style={{ fontSize: "10px", color: "gray", width: "100%" }}
                  >
                    [快捷按钮]点击对应按钮可删除
                  </span>
                </Grid.Item>
                <Grid.Item>
                  <Space wrap style={{ marginTop: "10px" }}>
                    <Button
                      color="primary"
                      fill="outline"
                      size="mini"
                      onClick={() => {
                        setRelationOrderSelected([]);
                      }}
                    >
                      全部清空
                    </Button>
                  </Space>
                </Grid.Item>
                <Grid.Item>
                  <h3>选择关联工单</h3>
                </Grid.Item>
                <Grid.Item>
                  <span
                    style={{ fontSize: "10px", color: "gray", width: "100%" }}
                  >
                    筛选或滑动搜索, 点击选中要关联的工单.
                  </span>
                </Grid.Item>
              </Grid>
              <SearchBar
                placeholder="输入文字过滤选项"
                value={relationOrderSearchText}
                onChange={(v) => {
                  setRelationOrderSearchText(v);
                }}
              />
            </div>
            <div
              style={{
                overflowY: "scroll",
              }}
            >
              <CheckList
                style={{
                  borderTop: 0,
                  borderBottom: 0,
                }}
                value={relationOrderSelected}
                defaultValue={relationOrderSelected}
                onChange={(val) => {
                  setRelationOrderSelected(val);
                }}
                multiple
              >
                {" "}
                {filteredItems?.map((item) => (
                  <CheckList.Item key={item.id} value={item.id}>
                    {item.order_id} {item.title}
                  </CheckList.Item>
                ))}
              </CheckList>
            </div>
          </>
        ) : (
          <>
            <ErrorBlock
              status="empty"
              title="暂无关联工单数据"
              style={{ padding: "64px 0" }}
              description={<h2>没有相关工单可选择</h2>}
            />
          </>
        )}
      </Popup>
    </EditPopup>
  );
};
