import { MenuProps } from "antd";
import { Link } from "react-router-dom";
import {
  SolutionOutlined,
  ContainerOutlined,
  PieChartOutlined,
  SettingOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import {
  breakKey,
  breaktRoute,
  customerDetailKey,
  customerDetailRoute,
  customerKey,
  customerListKey,
  customerListRoute,
  customerSettingRoute,
  customerStatKey,
  customerStatRoute,
  keyPathMap,
  launchChangeKey,
  launchChangeRoute,
  maintenanceStatKey,
  maintenanceStatRoute,
  permissionSettingRoute,
  productStatKey,
  productStatRoute,
  rateKey,
  rateoute,
  // receiptKey,
  // receiptRoute,
  settingCustomerKey,
  settingKey,
  settingPermissionKey,
  settingTicketKey,
  statKey,
  ticketKey,
  ticketSettingRoute,
  operateKey,
  visitRoute,
  visitKey,
  breakInfoStatRoute,
  breakInfoStatKey,
  breakProcessStatKey,
} from "./routeSetting";
import { getLinkTo } from "utils";

type MenuItem = Required<MenuProps>["items"][number];

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
};

export const MenuItems: MenuItem[] = [
  getItem(keyPathMap[customerKey], customerKey, <SolutionOutlined />, [
    getItem(
      <Link to={getLinkTo(customerListRoute)}>
        {keyPathMap[customerListKey]}
      </Link>,
      customerListKey,
    ),
    getItem(
      <Link to={getLinkTo(customerDetailRoute)}>
        {keyPathMap[customerDetailKey]}
      </Link>,
      customerDetailKey,
    ),
    // getItem(
    //   <Link to={getLinkTo(rateoute)}>{keyPathMap[rateKey]}</Link>,
    //   rateKey,
    // ),
  ]),
  getItem(keyPathMap[ticketKey], ticketKey, <ContainerOutlined />, [
    getItem(
      <Link to={getLinkTo(breaktRoute)}>{keyPathMap[breakKey]}</Link>,
      breakKey,
    ),
    getItem(
      <Link to={getLinkTo(launchChangeRoute)}>
        {keyPathMap[launchChangeKey]}
      </Link>,
      launchChangeKey,
    ),
    // getItem(
    //   <Link to={getLinkTo(receiptRoute)}>{keyPathMap[receiptKey]}</Link>,
    //   receiptKey,
    // ),
  ]),

  getItem(keyPathMap[operateKey], operateKey, <LineChartOutlined />, [
    getItem(
      <Link to={getLinkTo(visitRoute)}>{keyPathMap[visitKey]}</Link>,
      visitKey,
    ),
    getItem(
      <Link to={getLinkTo(rateoute)}>{keyPathMap[rateKey]}</Link>,
      rateKey,
    ),
  ]),

  getItem(keyPathMap[statKey], statKey, <PieChartOutlined />, [
    getItem(
      <Link to={getLinkTo(breakInfoStatRoute)}>
        {keyPathMap[breakInfoStatKey]}
      </Link>,
      breakInfoStatKey,
    ),
    // getItem(
    //   <Link to={getLinkTo(breakProcessStateRoute)}>
    //     {keyPathMap[breakProcessStatKey]}
    //   </Link>,
    //   breakProcessStatKey,
    // ),
    getItem(
      <Link to={getLinkTo(customerStatRoute)}>
        {keyPathMap[customerStatKey]}
      </Link>,
      customerStatKey,
    ),
    getItem(
      <Link to={getLinkTo(productStatRoute)}>
        {keyPathMap[productStatKey]}
      </Link>,
      productStatKey,
    ),
    getItem(
      <Link to={getLinkTo(maintenanceStatRoute)}>
        {keyPathMap[maintenanceStatKey]}
      </Link>,
      maintenanceStatKey,
    ),
  ]),
  getItem(keyPathMap[settingKey], settingKey, <SettingOutlined />, [
    getItem(
      <Link to={getLinkTo(permissionSettingRoute)}>
        {keyPathMap[settingPermissionKey]}
      </Link>,
      settingPermissionKey,
    ),
    getItem(
      <Link to={getLinkTo(customerSettingRoute)}>
        {keyPathMap[settingCustomerKey]}
      </Link>,
      settingCustomerKey,
    ),
    getItem(
      <Link to={getLinkTo(ticketSettingRoute)}>
        {keyPathMap[settingTicketKey]}
      </Link>,
      settingTicketKey,
    ),
  ]),
];
