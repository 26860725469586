import { IdType } from "lib/api";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useCustomerDetail = () => {
  const [{ openCustomerDetail }, setopenCustomerDetail] = useUrlQueryParam([
    "openCustomerDetail",
    "detailId",
  ]);

  const open = (id: IdType) =>
    setopenCustomerDetail({ openCustomerDetail: true, detailId: id });
  const close = () =>
    setopenCustomerDetail({
      openCustomerDetail: undefined,
      detailId: undefined,
    });

  return [openCustomerDetail === "true", open, close] as const;
};
