import { IdType } from "lib/api";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useChecklistDetail = () => {
  const [{ openChecklistDetail }, setopenChecklistDetail] = useUrlQueryParam([
    "openChecklistDetail",
    "detailId",
  ]);

  const open = (id: IdType) =>
    setopenChecklistDetail({ openChecklistDetail: true, detailId: id });
  const close = () =>
    setopenChecklistDetail({
      openChecklistDetail: undefined,
      detailId: undefined,
    });

  return [openChecklistDetail === "true", open, close] as const;
};
