import { Fragment } from "react";
import {
  Descriptions,
  Row,
  Col,
  Statistic,
  Button,
  Space,
  Empty,
  Card,
} from "antd";
import { ProTable, ProColumns } from "@ant-design/pro-components";
import { ProductUsingDetailType, ProductUsingRecordType } from "../interface";
import { RedoOutlined } from "@ant-design/icons";
const UsingRate = ({
  usingInfo,
  reload,
}: {
  usingInfo: ProductUsingRecordType | undefined | null;
  reload: () => void;
}) => {
  const columns: ProColumns<ProductUsingDetailType>[] = [
    {
      title: "日期",
      dataIndex: "date",
      valueType: "text",
      width: 120,
    },
    {
      title: "预测量",
      dataIndex: "predict_num",
      valueType: "digit",
      width: 120,
    },
    {
      title: "点击量",
      dataIndex: "usv_num",
      valueType: "digit",
      width: 120,
    },
    {
      title: "点击率",
      dataIndex: "click_rate",
      valueType: "percent",
      width: 120,
    },
  ];

  const RetainInfoDescItem = (item: ProductUsingDetailType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`usingRate${key}`}>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("日期")}
          className={className()}
        >
          {item?.date || ""}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("预测量")}
          className={className()}
        >
          {item?.predict_num || 0}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("点击量")}
          className={className()}
        >
          {item?.usv_num || 0}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("点击率")}
          className={className()}
        >
          {item?.click_rate || "0%"}
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <ProTable<ProductUsingDetailType>
        size="small"
        columns={columns}
        rowKey="date"
        dataSource={usingInfo?.details}
        pagination={false}
        loading={usingInfo === undefined}
        dateFormatter="string"
        search={false}
        toolBarRender={false}
        tableExtraRender={(_, data) => (
          <div>
            <Row>
              <Col span={18}>
                <h3>使用信息</h3>
              </Col>
              <Col span={6} style={{ textAlign: "right" }}>
                <Button
                  key="add"
                  type="default"
                  icon={<RedoOutlined />}
                  onClick={reload}
                >
                  刷新
                </Button>
              </Col>
            </Row>
            <Row gutter={16} style={{ textAlign: "center" }}>
              <Col span={8}>
                <Statistic
                  title="总预测量"
                  value={usingInfo?.total_predict_num}
                />
              </Col>
              <Col span={8}>
                <Statistic title="总点击量" value={usingInfo?.total_usv_num} />
              </Col>
              <Col span={8}>
                <Statistic title="平均点击率" value={usingInfo?.click_rate} />
              </Col>
            </Row>
          </div>
        )}
        bordered={true}
        locale={{
          emptyText: <Empty description="No Data"></Empty>,
        }}
      />
      {/* <Descriptions
        column={1}
        title={"使用信息"}
        extra={
          <Button type="primary" onClick={reload}>
            刷新
          </Button>
        }
      />
      <Row gutter={16} style={{ textAlign: "center" }}>
        <Col span={8}>
          <Statistic title="总预测量" value={usingInfo?.total_predict_num} />
        </Col>
        <Col span={8}>
          <Statistic title="总点击量" value={usingInfo?.total_usv_num} />
        </Col>
        <Col span={8}>
          <Statistic title="平均点击率" value={usingInfo?.click_rate} />
        </Col>
      </Row>
      <Descriptions
        column={4}
        bordered={usingInfo?.details?.length ? true : false}
        layout="vertical"
      >
        {usingInfo?.details?.map((item, index) =>
          RetainInfoDescItem(item, index),
        )}
      </Descriptions> */}
    </Fragment>
  );
};

export default UsingRate;
