import { useEffect, useRef, useState } from "react";
import { PageHeader } from "@ant-design/pro-components";
import { Comment } from "@ant-design/compatible";
import { Space, Button, Avatar, message } from "antd";
import ReactQuill, { Quill } from "react-quill";
import {
  FullscreenOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useFullscreen } from "ahooks";

import { FormatTime } from "utils";
import { editQuillToolbarOption } from "./option";

import "./index.css";
import "./quill.snow.css";
import "./quill.bubble.css";
import ImageResize from "quill-image-resize-module-zzone";
Quill.register("modules/imageResize", ImageResize);

export const EditQuill = ({
  onUpdate,
  name,
  title,
  user,
  avatar,
  info,
  time,
  isCanChange,
  relatedField,
  relatedFiename,
}: {
  onUpdate: (value: any) => void;
  name: string;
  title: string;
  user?: string;
  avatar?: string;
  info: string;
  time?: string;
  isCanChange?: boolean;
  relatedField?: { id: number; name: string }[];
  relatedFiename?: string;
}) => {
  const [IsEditting, setIsEditting] = useState(false);
  const [value, setValue] = useState("");

  const fullRef = useRef<HTMLDivElement>(null);

  const [, { enterFullscreen }] = useFullscreen(fullRef);

  useEffect(() => {
    setValue(info);
  }, [info]);

  const handleUpdate = () => {
    if (relatedField) {
      const relatedFieldValue = relatedField.map((item) => item.id);
      if (relatedFieldValue.length > 0 && value === "") {
        message.error(`关联字段有值，不可为空！`);
        return;
      }
    }
    if (relatedFiename) {
      if (relatedFiename != "" && value === "") {
        message.error(`关联字段有值，不可为空！`);
        return;
      }
    }
    onUpdate({ [name]: value });
    setIsEditting(false);
  };

  return (
    <>
      <PageHeader
        className="page-header"
        subTitle={<span className="page-header-span">{title}</span>}
        extra={
          <Space>
            {IsEditting ? (
              <>
                <Button
                  icon={
                    <CheckOutlined
                      style={{ color: "var(--adm-color-primary)" }}
                    />
                  }
                  type="text"
                  onClick={handleUpdate}
                />
                <Button
                  icon={<CloseOutlined />}
                  type="text"
                  onClick={() => {
                    setIsEditting(false);
                  }}
                />
              </>
            ) : isCanChange ? (
              <Button
                icon={
                  <EditOutlined style={{ color: "var(--adm-color-primary)" }} />
                }
                type="text"
                onClick={() => setIsEditting(true)}
              />
            ) : null}
            <Button
              icon={<FullscreenOutlined />}
              type="text"
              onClick={enterFullscreen}
            />
          </Space>
        }
      />
      <div
        ref={fullRef}
        className={IsEditting ? "editting-info" : "info-comment"}
      >
        {IsEditting ? (
          <ReactQuill
            className="editting-quill"
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
              imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
              },
            }}
            value={value}
            onChange={setValue}
          />
        ) : (
          <Comment
            className={user ? "user-comment " : "no-user-comment"}
            author={user}
            avatar={avatar ? <Avatar src={avatar} alt={user} /> : undefined}
            content={
              <ReactQuill
                readOnly
                theme={"bubble"}
                value={value}
                modules={{ imageResize: { displaySize: true, readOnly: true } }}
              />
            }
            datetime={<span>{FormatTime(time)}</span>}
          />
        )}
      </div>
    </>
  );
};
