import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Descriptions,
  Space,
  Input,
  Select,
  message,
  Form,
  Upload,
  UploadFile,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import {
  GET_SYS_VERSION,
  GET_HOS_PRODUCT_LIST,
  GET_EQPT_TYPE,
  GET_EQPT_OEM,
  UPDATE_HARDWARE,
  GET_EQPT_MODEL,
  ADD_FILE,
} from "lib/api";
import {
  HardwareDesItemLabelMax,
  HardwareDesItemLabelMin,
  LineDiv,
} from "../../utils";

import {
  EqptModel,
  EqptOem,
  EqptType,
  HardwareServerType,
  HospProductsListType,
  SystemList,
} from "../interface";
import {
  DiskCapacityList,
  DiskNumberList,
  DisposeForm,
  handleUploadImage,
  initFileList,
  limitUploadImage,
  MemoryCapacityList,
  MemoryNumberList,
  SsdCapacityList,
} from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { IpRegex } from "utils/regexp";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";

const { Option } = Select;

export const ServerBaseInfoEdit = ({
  hospID,
  editingItem,
  editable,
  setEditable,
  setDisChange,
  reload,
}: {
  hospID: number | null;
  editingItem: HardwareServerType;
  editable: boolean;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setDisChange: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const {
    run: getHosProductList,
    isLoading: gettingHosProductList,
    data: hospProductList,
  } = useAsync<HospProductsListType[]>();
  const getHosProductListFunc = (hospital_id: number) => {
    getHosProductList(client(GET_HOS_PRODUCT_LIST(hospital_id)));
  };

  const {
    run: getEqptTypeList,
    // isLoading: gettingEqptTypeList,
    data: eqptTypeList,
  } = useAsync<EqptType[]>();
  const getEqptTypeListFunc = () => {
    getEqptTypeList(client(GET_EQPT_TYPE));
  };

  const {
    run: getEqptOemList,
    isLoading: gettingEqptOemList,
    data: eqptOemList,
  } = useAsync<EqptOem[]>();
  const getEqptOemListFunc = (eqpt_type_id: number) => {
    getEqptOemList(client(GET_EQPT_OEM(eqpt_type_id)));
  };

  const {
    run: getEqptModeList,
    isLoading: gettingEqptModeList,
    data: eqptModeList,
  } = useAsync<EqptModel[]>();
  const getEqptModeListFunc = (eqpt_oem_id: number) => {
    getEqptModeList(client(GET_EQPT_MODEL(eqpt_oem_id)));
  };

  const {
    run: getSysVersionList,
    isLoading: gettingSysVersionList,
    data: sysVersionList,
  } = useAsync<SystemList[]>();
  const getSysVersionListFunc = () => {
    getSysVersionList(client(GET_SYS_VERSION));
  };

  const [eqptTypeID, setEqptTypeID] = useState<number>();
  const [eqptOemID, setEqptOemID] = useState<number>();

  const submit = (value: any) => {
    if (editingItem.id) {
      client(UPDATE_HARDWARE(editingItem.id), {
        data: {
          ...value,
          asset_photos: fileList.map((i) => i.uid),
        },
        method: "PUT",
      })
        .then(() => {
          message.success("更新成功！");
          setEditable(false);
          setDisChange(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("更新失败！请检查或者联系管理员！");
        });
    } else {
      message.error("无法提交更新，请稍后重试或联系管理员！");
    }
  };

  const clear = () => {
    form.setFieldsValue({
      eqpt_model_id: null,
    });
  };

  useEffect(() => {
    if (editable) {
      if (hospID) {
        getHosProductListFunc(hospID);
      }
      getSysVersionListFunc();
      getEqptTypeListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);

  useEffect(() => {
    if (editingItem) {
      setFileList(initFileList(editingItem?.asset_photos) || []);
      if (editingItem.eqpt_oem_id) {
        getEqptModeListFunc(editingItem.eqpt_oem_id);
      }
      form.setFieldsValue({
        ...editingItem,
        products: editingItem.products.map((i) => i.product_id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  useEffect(() => {
    if (eqptTypeList?.length) {
      let serverID = eqptTypeList.find((t) => t.name === "服务器")?.id;
      if (serverID) {
        setEqptTypeID(serverID);
      }
    }
  }, [eqptTypeList]);

  useEffect(() => {
    if (eqptTypeID) {
      getEqptOemListFunc(eqptTypeID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqptTypeID]);

  useEffect(() => {
    if (eqptOemID) {
      getEqptModeListFunc(eqptOemID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqptOemID]);

  return (
    <Form className="server-base-info-form" form={form} onFinish={submit}>
      <Descriptions column={2}>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"设备品牌"} />}
        >
          <LineDiv>
            <Form.Item
              name="eqpt_oem_id"
              rules={[{ required: true, message: "必须选择设备品牌" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingEqptOemList}
                style={{ width: "256px" }}
                placeholder="选择设备品牌"
                onChange={(v) => {
                  clear();
                  setEqptOemID(v);
                }}
              >
                {eqptOemList?.map((i, index) => (
                  <Option key={index} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMax label={"运行产品"} />}
        >
          {/* <LineDiv> */}
          <Form.Item name="products" normalize={(v) => DisposeForm(v)}>
            <Select
              allowClear
              loading={gettingHosProductList}
              mode="multiple"
              maxTagCount={3}
              style={{ width: "256px" }}
              placeholder="选择产品线"
            >
              {hospProductList?.map((i, index) => (
                <Option key={index} value={i.product_id}>
                  {i.product_id}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* </LineDiv> */}
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"设备型号"} />}
        >
          <LineDiv>
            <Form.Item
              name="eqpt_model_id"
              rules={[{ required: true, message: "必须选择设备型号" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingEqptModeList}
                style={{ width: "256px" }}
                placeholder="选择设备型号"
              >
                {eqptModeList?.map((i, index) => (
                  <Option key={index} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMax label={"系统版本"} />}
        >
          <LineDiv>
            <Form.Item
              name="system_version_id"
              rules={[{ required: true, message: "必须选择系统版本" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingSysVersionList}
                style={{ width: "256px" }}
                placeholder="选择系统版本"
              >
                {sysVersionList?.map((i, index) => (
                  <Option key={index} value={i.id}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"是否主机"} />}
        >
          <LineDiv>
            <Form.Item
              name="is_master"
              rules={[{ required: true, message: "必须说明是否为(非)主机" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select style={{ width: "256px" }} placeholder="是否主机">
                {[
                  { value: 1, name: "是" },
                  { value: 0, name: "否" },
                ].map((i) => (
                  <Option key={i.value} value={i.value}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMax label={"内存大小(GB)"} />}
        >
          <LineDiv>
            <Form.Item
              name="memory_capacity"
              rules={[{ required: true, message: "必须选择内存大小" }]}
              style={{ display: "inline-block", marginRight: 5 }}
              normalize={(v) => DisposeForm(v)}
            >
              <Select style={{ width: "120px" }} placeholder="内存大小">
                {MemoryCapacityList.map((i, index) => (
                  <Option key={index} value={i}>
                    {i}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            *
            <Form.Item
              name="memory_num"
              rules={[{ required: true, message: "必须选择内存数量" }]}
              style={{ display: "inline-block", marginLeft: 5 }}
              normalize={(v) => DisposeForm(v)}
            >
              <Select style={{ width: "120px" }} placeholder="内存数量">
                {MemoryNumberList.map((i, index) => (
                  <Option key={index} value={i}>
                    {i}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"服务器IP"} />}
        >
          <LineDiv>
            <Form.Item
              name="server_ip"
              rules={[
                { required: true, message: "必须输入服务器IP" },
                {
                  pattern: IpRegex(),
                  message: "必须输入合法的IP格式",
                },
              ]}
              normalize={(v) => DisposeForm(v)}
            >
              <Input style={{ width: "256px" }} />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMax label={"SSD大小(GB)"} />}
        >
          <LineDiv>
            <Form.Item
              name="ssd_capacity"
              rules={[{ required: true, message: "必须选择SSD大小" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select style={{ width: "256px" }} placeholder="选择SSD大小">
                {SsdCapacityList.map((i, index) => (
                  <Option key={index} value={i}>
                    {i}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"维保代码"} />}
        >
          <LineDiv>
            <Form.Item
              name="maintenance_code"
              normalize={(v) => DisposeForm(v)}
            >
              <Input allowClear style={{ width: "256px" }} />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMax label={"机械硬盘大小(TB)"} />}
        >
          <LineDiv>
            <Form.Item
              name="disk_capacity"
              style={{ display: "inline-block", marginRight: 5 }}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                allowClear
                style={{ width: "120px" }}
                placeholder="硬盘大小"
              >
                {DiskCapacityList.map((i, index) => (
                  <Option key={index} value={i}>
                    {i}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            *
            <Form.Item
              name="disk_num"
              style={{ display: "inline-block", marginLeft: 5 }}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                allowClear
                style={{ width: "120px" }}
                placeholder="硬盘数量"
              >
                {DiskNumberList.map((i, index) => (
                  <Option key={index} value={i}>
                    {i}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"资产编码"} />}
        >
          <LineDiv>
            <Form.Item
              name="assets_code"
              rules={[{ required: true, message: "必须输入资产编码" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Input style={{ width: "256px" }} />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>

        <Descriptions.Item
          label={<HardwareDesItemLabelMax label={"放置位置"} />}
        >
          <LineDiv>
            <Form.Item
              name="location"
              rules={[{ required: true, message: "必须说明放置位置" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Input style={{ width: "256px" }} />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item
          label={<HardwareDesItemLabelMin label={"资产照片"} />}
        >
          <Form.Item name="asset_photos">
            <Upload
              headers={UploadHeaders}
              maxCount={3}
              multiple={true}
              action={ADD_FILE}
              listType="picture-card"
              fileList={fileList}
              beforeUpload={limitUploadImage}
              onChange={(info) => handleUploadImage(info, setFileList)}
              accept="image/*"
            >
              {fileList?.length >= 3 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>上传(限3个)</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
      <Form.Item
        wrapperCol={{ offset: 20 }}
        style={{ marginTop: "2rem", marginBottom: "-1rem" }}
      >
        <Space>
          <Button
            htmlType="button"
            onClick={() => {
              setDisChange(false);
              setEditable(false);
            }}
          >
            取消
          </Button>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
