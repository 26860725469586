import { useState, useRef } from "react";
import { Button, Space, message, Popconfirm, Card, Table, Input } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  RedoOutlined,
  UndoOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { InputRef } from "antd";
import { ColumnType, FilterConfirmProps } from "antd/es/table/interface";
import { ColumnsType } from "antd/es/table";
import Highlighter from "react-highlight-words";
import { useDebounceFn, useMount } from "ahooks";

import { useHttp } from "utils/http";
import { ProductType, SettingAPI } from "../interface";
import { ProductSettingAdd } from "./add";
import { ProductSettingEdit } from "./edit";
import { useAsync } from "utils/hooks/useAsync";
import { trimStr } from "utils";

type DataIndex = keyof ProductType;

const ProductSetting = ({ ctrlSetting }: { ctrlSetting: SettingAPI }) => {
  // 必要信息state
  const [addSettingVisible, setAddSettingVisible] = useState(false);
  const [editSettingVisible, setEditSettingVisible] = useState(false);
  const [editSettingInfo, setEditSettingInfo] = useState<ProductType>();

  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const client = useHttp();

  const {
    run: getSettingList,
    isIdle,
    isLoading: gettingSettingList,
    data: setting,
  } = useAsync<ProductType[]>();
  const getSettingListHandle = () => {
    getSettingList(client(ctrlSetting.GET_SETTING));
  };
  const { run: getSettingListDebounce } = useDebounceFn(getSettingListHandle, {
    wait: 200,
  });

  const reload = getSettingListHandle;
  const loading = gettingSettingList || isIdle;

  useMount(getSettingListDebounce);

  const delSetting = (id: number) => {
    if (id)
      client(ctrlSetting.DEL_SETTING(id), { method: "DELETE" })
        .then(() => {
          message.success("删除成功！");
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("删除失败，请联系管理员！");
        });
    else message.error("无法进行删除，请稍后重试或联系管理员！");
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    title: string,
  ): ColumnType<ProductType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`搜索${title}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space align={"end"}>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            icon={<UndoOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            重置
          </Button>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 100 }}
          >
            搜索
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        style={{ color: filtered ? "var(--adm-color-primary)" : undefined }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((trimStr(value as string) || "").toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[trimStr(searchText) || ""]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<ProductType> = [
    {
      title: "编号",
      dataIndex: "id",
      render: (_, __, index) => index + 1,
    },
    {
      title: "中文名称",
      dataIndex: "chinese_name",
      ...getColumnSearchProps("chinese_name", "中文名称"),
    },
    {
      title: "产品线简称（title：需唯一）",
      dataIndex: "title",
      ...getColumnSearchProps("title", "产品线简称"),
    },
    {
      title: "英文名称",
      dataIndex: "single_name",
      ...getColumnSearchProps("single_name", "英文名称"),
    },
    {
      title: "产品线全称",
      dataIndex: "product_name",
      ...getColumnSearchProps("product_name", "产品线全称"),
    },
    {
      title: "操作",
      align: "center",
      render: (_, record, index) => (
        <Space key={`oprate_${record.id}`}>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            loading={editSettingVisible && record.id === editSettingInfo?.id}
            onClick={() => {
              setEditSettingInfo(record);
              setEditSettingVisible(true);
            }}
          />
          <Popconfirm
            title={`确认删除编号【${index + 1}】的设置信息？`}
            onConfirm={() => delSetting(record.id)}
            okText="确认"
            cancelText="取消"
            icon={<ExclamationCircleOutlined />}
            style={{ color: "red" }}
          >
            <Button
              danger
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Card
        title="产品线"
        extra={
          <Space>
            <Button
              loading={addSettingVisible}
              icon={<PlusOutlined />}
              onClick={() => setAddSettingVisible(true)}
            >
              新增
            </Button>
            <Button
              loading={loading}
              icon={<RedoOutlined />}
              type={"primary"}
              onClick={reload}
            >
              刷新
            </Button>
          </Space>
        }
      >
        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={setting?.map((i) => ({ ...i, key: i.id })) || []}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: false,
          }}
        />
      </Card>
      <ProductSettingAdd
        ADD_SETTING={ctrlSetting.ADD_SETTING}
        visible={addSettingVisible}
        setVisible={setAddSettingVisible}
        reload={reload}
      />
      <ProductSettingEdit
        editSettingInfo={editSettingInfo}
        UPDATE_SETTING={ctrlSetting.UPDATE_SETTING}
        visible={editSettingVisible}
        setVisible={setEditSettingVisible}
        reload={reload}
      />
    </>
  );
};

export default ProductSetting;
