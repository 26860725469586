import { useEffect, useRef, useState } from "react";
import { PageHeader } from "@ant-design/pro-components";
import { Space, Button, Input, Form, InputRef } from "antd";
import { useFullscreen } from "ahooks";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";

import "./index.css";
import "./quill.snow.css";
import "./quill.bubble.css";

const { TextArea } = Input;

export const UpdateInputTextArea = ({
  onUpdate,
  name,
  title,
  info,
  isCanChange,
}: {
  onUpdate: (value: any) => void;
  name: string;
  title: string;
  user?: string;
  avatar?: string;
  info: string;
  time?: string;
  isCanChange?: boolean;
}) => {
  const [form] = Form.useForm();
  const [initValue, setInitValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const fullRef = useRef<HTMLDivElement>(null);

  const [, { enterFullscreen }] = useFullscreen(fullRef);

  const onFinish = (value: any) => {
    if (value !== initValue) {
      if (!!!value) {
        value = "";
      }
      onUpdate({ [name]: value });
      setInitValue(value);
    }
    inputRef.current?.blur();
  };

  const [IsEditting, setIsEditting] = useState(false);

  const resetContent = () => {
    setInitValue(info);
    form.setFieldsValue({
      [name]: info,
    });
  };

  useEffect(() => {
    resetContent();
  }, [info]);

  return (
    <div className={"update-input-form-txexarea"}>
      <PageHeader
        className="page-header"
        subTitle={<span className="page-header-span">{title}</span>}
        extra={
          <Space>
            {IsEditting ? (
              <>
                <Button
                  icon={
                    <CheckOutlined
                      style={{ color: "var(--adm-color-primary)" }}
                    />
                  }
                  type="text"
                  onClick={() => {
                    onFinish(form.getFieldValue(name));
                    setIsEditting(false);
                  }}
                />
                <Button
                  icon={<CloseOutlined />}
                  type="text"
                  onClick={() => {
                    resetContent();
                    setIsEditting(false);
                  }}
                />
              </>
            ) : isCanChange ? (
              <Button
                icon={
                  <EditOutlined style={{ color: "var(--adm-color-primary)" }} />
                }
                type="text"
                onClick={() => setIsEditting(true)}
              />
            ) : null}
            <Button
              icon={<FullscreenOutlined />}
              type="text"
              onClick={enterFullscreen}
            />
          </Space>
        }
      />
      <div className={"editting-info-textarea"} ref={fullRef}>
        {IsEditting ? (
          <Form form={form} autoComplete="off">
            <Form.Item
              name={name}
              // rules={[
              //   { required: true, message: `必须输入${name}` },
              // ]}
              style={{ width: "100%" }}
            >
              <TextArea
                style={{
                  paddingBlock: "4px",
                  fontSize: "13px",
                }}
                autoSize={{ minRows: 3 }}
              />
            </Form.Item>
          </Form>
        ) : (
          <Form form={form} onFinish={onFinish} autoComplete="off">
            <Form.Item name={name} style={{ width: "100%" }}>
              <TextArea
                readOnly
                className="textarea-disbaled"
                style={{
                  paddingBlock: "4px",
                  fontSize: "13px",
                }}
                autoSize
              />
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};
