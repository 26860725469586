import { useState, Fragment } from "react";
import { Button, Descriptions, Space, message, Popconfirm } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ProTable, ProColumns } from "@ant-design/pro-table";

import { useHttp } from "utils/http";
import { DEL_HOSP_USER } from "lib/api";

import { DetailCtrlProps, HosUserType } from "../interface";

import { HosUserInfoAdd } from "./add";
import { HosUserInfoEdit } from "./edit";

const HosUserInfo = ({ detail, reload }: DetailCtrlProps) => {
  const [addHosUserVisible, setAddHosUserVisible] = useState(false);
  const [editHosUserVisible, setEditHosUserVisible] = useState(false);
  const [editHosUserInfo, setEditHosUserInfo] = useState<HosUserType>();

  const client = useHttp();

  const delHosUser = (id: number) => {
    client(DEL_HOSP_USER(id), { method: "DELETE" })
      .then(() => {
        message.success("删除成功！");
        reload();
      })
      .catch((e) => {
        console.error(e);
        message.error("删除失败，请联系管理员！");
      });
  };
  const columns: ProColumns<HosUserType>[] = [
    {
      title: "姓名",
      dataIndex: "hos_user_name",
    },
    {
      title: "联系方式",
      dataIndex: "contact_name",
    },
    {
      title: "联系号码",
      dataIndex: "mobile",
    },
    {
      title: "人员类型",
      dataIndex: "hos_user_type_name",
    },
    {
      title: "备注",
      dataIndex: "remark",
    },
    {
      title: "操作",
      valueType: "option",
      width: 120,
      render: (text, item, _, action) => [
        <Space>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            loading={editHosUserVisible && item.id === editHosUserInfo?.id}
            onClick={() => {
              setEditHosUserInfo(item);
              setEditHosUserVisible(true);
            }}
          />
          <Popconfirm
            title={`确认删除外部人员【${item.hos_user_name}】的信息？`}
            onConfirm={() => delHosUser(item.id)}
            okText="确认"
            cancelText="取消"
            icon={<ExclamationCircleOutlined />}
            style={{ color: "red" }}
          >
            <Button
              danger
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>,
      ],
    },
  ];
  const HosUserDescItem = (item: HosUserType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");

    return (
      <Fragment key={`hosUserInfo${key}`}>
        <Descriptions.Item label={title("姓名")} className={className()}>
          {item?.hos_user_name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("联系方式")} className={className()}>
          {item?.contact_name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("联系号码")} className={className()}>
          {item?.mobile || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("人员类型")} className={className()}>
          {item?.hos_user_type_name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("备注")} className={className()}>
          {item?.remark || ""}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("操作")}
          className={className()}
        >
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              loading={editHosUserVisible && item.id === editHosUserInfo?.id}
              onClick={() => {
                setEditHosUserInfo(item);
                setEditHosUserVisible(true);
              }}
            />
            <Popconfirm
              title={`确认删除外部人员【${item.hos_user_name}】的信息？`}
              onConfirm={() => delHosUser(item.id)}
              okText="确认"
              cancelText="取消"
              icon={<ExclamationCircleOutlined />}
              style={{ color: "red" }}
            >
              <Button
                danger
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <ProTable<HosUserType>
        size="small"
        columns={columns}
        rowKey="id"
        dataSource={detail?.hos_users || []}
        pagination={false}
        search={false}
        dateFormatter="string"
        options={{
          reload: reload,
        }}
        toolbar={{
          title: <span style={{ fontWeight: "bold" }}>必要信息留存</span>,
          actions: [
            <Button
              key="add"
              type="default"
              icon={<PlusOutlined />}
              onClick={() => setAddHosUserVisible(true)}
            >
              新增
            </Button>,
          ],
          settings: [],
        }}
        bordered={detail?.hos_users?.length ? true : false}
      />
      {/* <Descriptions
        column={6}
        bordered={detail?.hos_users?.length ? true : false}
        title="外部人员信息"
        layout="vertical"
        extra={
          <Button
            icon={<PlusOutlined />}
            loading={addHosUserVisible}
            onClick={() => setAddHosUserVisible(true)}
          >
            新增
          </Button>
        }
      >
        {detail?.hos_users?.map((item, index) => HosUserDescItem(item, index))}
      </Descriptions> */}
      <HosUserInfoAdd
        hospID={detail?.id}
        visible={addHosUserVisible}
        setVisible={setAddHosUserVisible}
        reload={reload}
      />
      <HosUserInfoEdit
        editingItem={editHosUserInfo}
        visible={editHosUserVisible}
        setVisible={setEditHosUserVisible}
        reload={reload}
      />
    </Fragment>
  );
};

export default HosUserInfo;
