import { MyPieChartType } from "../../chart/pie-chart";
import { BaseValueType } from "../../interface";

export const GetMaintenancePie = (data: BaseValueType[]) => {
  let tmpData: MyPieChartType = {
    data: [],
  };

  data.forEach((item) => {
    tmpData.data.push({
      name: item.name,
      value: item.value,
    });
  });

  return tmpData;
};
