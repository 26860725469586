import { Dispatch, SetStateAction } from "react";
import { Popup, List, Badge, Tag } from "antd-mobile";

import { DockingListType } from "authenticated-app/pc/customer/customer-detail/tab-pane/dockingTabPane/interface";

export const MobileDockingListDetail = ({
  dockingInfo,
  visible,
  setVisible,
}: {
  dockingInfo: DockingListType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <>
      <Popup
        visible={visible}
        destroyOnClose
        bodyStyle={{ height: "70vh", overflowY: "scroll" }}
        onMaskClick={() => setVisible(false)}
        showCloseButton
        onClose={() => setVisible(false)}
      >
        <List
          mode="card"
          header={<p style={{ fontWeight: "bold" }}>对接详情</p>}
        >
          <List.Item extra={dockingInfo?.docking_type_name || "未知"}>
            对接类型
          </List.Item>
          <List.Item extra={dockingInfo?.cur_docking_name || "未知"}>
            现片对接方式
          </List.Item>
          <List.Item extra={dockingInfo?.history_docking_name || "未知"}>
            前片对接方式
          </List.Item>
          <List.Item
            extra={
              Array.isArray(dockingInfo?.products) &&
              dockingInfo.products.length > 0
                ? dockingInfo.products
                    .map((product) => product.product_id)
                    .join(", ")
                : "未知"
            }
          >
            对接产品线
          </List.Item>
        </List>
      </Popup>
    </>
  );
};

export default MobileDockingListDetail;
