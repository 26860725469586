import { IdType } from ".";
import { TicketPrefix, ApiPrefix } from "./prefix";

export const GET_DELIVERY_USER_LIST = `${ApiPrefix}/api/departments/420375923/users/?has_children=1`;

export const GET_DELIVERY_ORDER_TYPE = `${TicketPrefix}/online_change/delivery_type`;
export const GET_DELIVERY_ORDER_STATUS = `${TicketPrefix}/online_change/issue_process`;

export const GET_DELIVERY_ORDER = `${TicketPrefix}/online_change/online_change`;
export const ADD_DELIVERY_ORDER = `${TicketPrefix}/online_change/online_change`;

export const GET_DELIVERY_DETAIL = (id: IdType) =>
  `${TicketPrefix}/online_change/online_change/${id}`;

export const TRANS_DELIVERY = (id: IdType) =>
  `${TicketPrefix}/online_change/online_change/${id}`;

export const GET_DELIVERY_PAUSE_REASON_TYPE = `${TicketPrefix}/online_change/pause_reason_type`;

export const GET_DELIVERY_RESCHEDULING_REASON_TYPE = `${TicketPrefix}/online_change/rescheduling_reason_type`;

export const GET_DELIVERY_STOP_REASON_TYPE = `${TicketPrefix}/issues/order_type`;

export const UPDATE_DELIVERY_DETAIL = (id: IdType) =>
  `${TicketPrefix}/online_change/online_change/${id}`;

export const GET_HOSPITAL_ORDER = (id: IdType) =>
  `${TicketPrefix}/issues/issues?hospital_id=${id}&not_page=true`;

export const GET_ORDER_SEARCH = `${TicketPrefix}/issues/issues?not_page=true&order_id=`;

export const GET_ORDER_LIST = `${TicketPrefix}/issues/issues?not_page=true`;
export const GET_ISSUES_MODULE = `${TicketPrefix}/issues/issue_module`;
export const GET_ISSUES_REASON = `${TicketPrefix}/issues/issue_reason`;
export const GET_ISSUES_REASON_NEW = (id: IdType) =>
  `${TicketPrefix}/issues/issue_reason?issue_type_id=${id}`;

export const GET_LAUNCH_CHANGE_LIST_FILE = `${TicketPrefix}/online_change/online_change`;
