import { Dispatch, SetStateAction, useEffect } from "react";
import {
  Button,
  Descriptions,
  Space,
  Select,
  message,
  InputNumber,
  Form,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import {
  GET_CLIENT_VERSION,
  GET_IDENTIFY_WAY,
  UPDATE_CLIENT_DETAIL,
} from "lib/api";
import { ClientDesItemLabel, LineDiv } from "../../utils";
import {
  ClinetDetailType,
  IdentifyType,
  ClinetVersionType,
} from "../interface";
import { DisposeForm, selectFilter } from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { useWindowSize } from "utils/hooks/useWindowSize";

const { Option } = Select;

export const ClientBaseInfoEdit = ({
  editingItem,
  setEditable,
  setDisChange,
  reload,
}: {
  editingItem: ClinetDetailType | undefined;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setDisChange: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const { width } = useWindowSize();

  const {
    run: getClientTypeList,
    isLoading: gettingClientTypeList,
    data: clientTypeList,
  } = useAsync<ClinetVersionType[]>();
  const getClientTypeListFunc = () => {
    getClientTypeList(client(GET_CLIENT_VERSION));
  };

  const {
    run: getIdentifyTypeList,
    isLoading: gettingIdentifyTypeList,
    data: identifTypeList,
  } = useAsync<IdentifyType[]>();
  const getIdentifyTypeListFunc = () => {
    getIdentifyTypeList(client(GET_IDENTIFY_WAY));
  };

  const submit = (value: any) => {
    if (editingItem?.hospital_id)
      client(UPDATE_CLIENT_DETAIL(editingItem?.hospital_id), {
        data: {
          ...value,
        },
        method: "PUT",
      })
        .then(() => {
          message.success("更新成功！");
          setEditable(false);
          setDisChange(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("更新失败！请检查或者联系管理员");
        });
    else message.error("无法完成更新，请刷新重试或联系管理员！");
  };

  useEffect(() => {
    if (editingItem) {
      getClientTypeListFunc();
      getIdentifyTypeListFunc();
      form.setFieldsValue({
        ...editingItem,
        client_version_list: editingItem.client_version_list.map((i) => i.id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  return (
    <Form form={form} onFinish={submit}>
      <Descriptions
        title="小插件基本信息"
        column={width <= 1440 ? 2 : 4}
        extra={
          <Space>
            <Button
              onClick={() => {
                setEditable(false);
                setDisChange(false);
              }}
              icon={<CloseOutlined />}
            >
              取消
            </Button>
            <Button type="primary" htmlType="submit" icon={<CheckOutlined />}>
              提交
            </Button>
          </Space>
        }
      >
        <Descriptions.Item label={<ClientDesItemLabel label={"小插件版本"} />}>
          <LineDiv>
            <Form.Item
              name="client_version_list"
              rules={[{ required: true, message: "必须选择版本" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingClientTypeList}
                mode="multiple"
                showSearch
                filterOption={selectFilter}
                maxTagCount={2}
                style={{ width: "180px" }}
              >
                {clientTypeList?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ClientDesItemLabel label={"主识别方式"} />}>
          <LineDiv>
            <Form.Item
              name="identify_way_id"
              rules={[{ required: true, message: "必须选择主识别方式" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingIdentifyTypeList}
                style={{ width: "180px" }}
              >
                {identifTypeList?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ClientDesItemLabel label={"配置下载"} />}>
          <LineDiv>
            <Form.Item
              name="is_config_download"
              rules={[{ required: true, message: "必须说明是否可下载" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                style={{ width: "180px" }}
                placeholder="说明配置是否可下载"
              >
                {[
                  { name: "是", value: 1 },
                  { name: "否", value: 0 },
                ].map((i, index) => (
                  <Option key={index} value={i.value}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ClientDesItemLabel label={"安装数量"} />}>
          <LineDiv>
            <Form.Item
              name="install_num"
              rules={[{ required: true, message: "必须输入安装数量" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <InputNumber
                precision={0}
                style={{ width: "180px" }}
                min={0}
                max={9999}
              />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
      </Descriptions>
    </Form>
  );
};
