import React from "react";
import { ConfigProvider } from "antd";
import locale from "antd/es/locale/zh_CN";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "react-app-polyfill/stable";

import App from "./App";
import { AppProvider } from "context";

import "./index.css";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <ConfigProvider locale={locale}>
      <AppProvider>
        <App />
      </AppProvider>
    </ConfigProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
