import { useState } from "react";
import { Button, Ellipsis, List, SwipeAction } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";

import { useHttp } from "utils/http";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";

import {
  RemoteRetainType,
  RemoteAssetType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/remoteTabPane/interface";
import MobileRemoteAssetDetail from "./detail";

export const MobileRemoteAssetInfo = ({
  orgID,
  productID,
  assets,
  reload,
}: {
  orgID: string | number | null | undefined;
  productID?: string | undefined;
  assets: RemoteAssetType[] | undefined | null;
  reload: () => void;
}) => {
  const [DetailAssetVisible, setDetailAssetVisible] = useState<boolean>(false);
  const [DetailAssetInfo, setDetailAssetInfo] = useState<RemoteAssetType>();

  const client = useHttp();

  return (
    <ContentCard>
      <List mode="card" header={<ListHeader text="远程资产信息" />}>
        {assets?.map((i) => (
          <SwipeAction key={i.id} closeOnAction={false}>
            <List.Item
              extra={<Ellipsis direction="end" content={i.asset_name} />}
              arrow={
                <Button
                  fill="none"
                  style={{ height: "100%", padding: 0 }}
                  loading={
                    DetailAssetVisible &&
                    i.id.toString() === DetailAssetInfo?.id.toString()
                  }
                >
                  <RightOutline style={{ color: "var(--adm-color-light)" }} />
                </Button>
              }
              onClick={() => {
                setDetailAssetInfo(i);
                setDetailAssetVisible(true);
              }}
            >
              {i.ip || "未知IP"}
            </List.Item>
          </SwipeAction>
        ))}
      </List>
      <MobileRemoteAssetDetail
        assetInfo={DetailAssetInfo}
        visible={DetailAssetVisible}
        setVisible={setDetailAssetVisible}
      />
    </ContentCard>
  );
};

export default MobileRemoteAssetInfo;
