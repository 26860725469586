export enum DateStep {
  day = "day",
  week = "week",
  month = "month",
  year = "year",
}

export interface RateResults {
  date: string[];
  predict_num: number;
  // 通用参数
  visit_num?: number;
  usv_num?: number;
  click_rate?: string;
  // 胸外参数
  rc2_num?: number;
  access_num?: number;
  download_num?: number;
}

export interface RateCalculate {
  total_predict_num: number;
  // 通用总数
  total_usv_num?: number;
  total_visit_num?: number;
  // 胸外总数
  total_rc2_num?: number;
  total_access_num?: number;
  total_download_num?: number;

  average_predict_num: number;
  // 通用平均数
  average_usv_num?: number;
  average_visit_num?: number;
  average_click_rate?: string;
  // 胸外平均数
  average_rc2_num?: number;
  average_access_num?: number;
  average_download_num?: number;
}

export interface RateType {
  unit: DateStep;
  results: RateResults[];
  calculate: RateCalculate;
}

export interface PredictValue {
  ip: string;
  ident: string;
  name: string;
  value: number;
}

export interface PredictType {
  timestamp: string;
  values: PredictValue[];
}

export interface PREDICT_TIME_TYPE {
  chinese_name: string;
  count: number;
  max_count: number;
  avg_count: number;
  max_transfer_delay_seconds: number;
  avg_transfer_delay_seconds: number;
  max_receive_seconds: number;
  avg_receive_seconds: number;
  max_ds_seconds: number;
  avg_ds_seconds: number;
  max_wait_seconds: number;
  avg_wait_seconds: number;
  max_predict_seconds: number;
  avg_predict_seconds: number;
  max_end_to_end_seconds: number;
  avg_end_to_end_seconds: number;
  statics_date: string;
}

export interface PUSH_INFO_TYPE {
  id: number;
  hospital_id: number;
  push_series_num: number;
  push_series_success: number;
  push_film_num: number;
  push_film_success: number;
  statics_date: string;
}
