import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Select, message, Modal, Form, DatePicker, Input } from "antd";
import { CT_LUNG_VERSION_TYPE } from "utils";
import { useHttp } from "utils/http";
import {
  GET_PRODUCT_LIST,
  GET_DEPLOY_TYPE,
  GET_PRODUCT_DEPARTMENT,
  GET_SERVICE_TYPE,
  GET_USING_STATUS,
  GET_SERVICE_LEVEL,
  GET_PRODUCT_CLIENT,
  GET_PRODUCT_VERSION_BY_ID,
  ADD_PRODUCT_DETAIL,
} from "lib/api";

import {
  ProductClientType,
  ProductDeployType,
  ProductServiceLevelType,
  ProductServiceType,
  ProductType,
  ProductUsingDepartmentType,
  ProductUsingType,
  ProductVersionType,
} from "../interface";
import {
  DisabledDate,
  DisposeForm,
  formatDate,
  RID,
  selectFilter,
} from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

const { Option } = Select;

export const ProductBaseInfoAdd = ({
  id,
  visible,
  setVisible,
  reload,
}: {
  id: number | string | undefined | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const [, setProduct] = useUrlQueryParam([]);

  const client = useHttp();
  const [form] = Form.useForm();

  const [serviceType, setServiceType] = useState<number>();

  const licenseStatus = [
    { value: 1, label: "已授权" },
    { value: 2, label: "未授权" },
    { value: 3, label: "无需授权" },
  ];

  const {
    run: getProductList,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductType[]>();
  const getProductListFunc = () => {
    getProductList(client(GET_PRODUCT_LIST));
  };

  const {
    run: getProductVersionList,
    isLoading: gettingProductVersionList,
    data: productVersionList,
  } = useAsync<ProductVersionType[]>();
  const getProductVersionListFunc = (product_id: string) => {
    getProductVersionList(client(GET_PRODUCT_VERSION_BY_ID(product_id)));
  };

  const {
    run: getUbVersionList,
    isLoading: gettingUbVersionList,
    data: ubVersionList,
  } = useAsync<ProductVersionType[]>();
  const getUbVersionListFunc = () => {
    getUbVersionList(client(GET_PRODUCT_VERSION_BY_ID("UB")));
  };

  const {
    run: getProductClientList,
    isLoading: gettingProductClientList,
    data: productClientList,
  } = useAsync<ProductClientType[]>();
  const getProductClientListFunc = (product_id: string) => {
    getProductClientList(client(GET_PRODUCT_CLIENT(product_id)));
  };

  const {
    run: getDeployTypeList,
    isLoading: gettingDeployTypeList,
    data: deployTypeList,
  } = useAsync<ProductDeployType[]>();
  const getDeployTypeListFunc = () => {
    getDeployTypeList(client(GET_DEPLOY_TYPE));
  };

  const {
    run: getServiceTypeList,
    isLoading: gettingServiceTypeList,
    data: serviceTypeList,
  } = useAsync<ProductServiceType[]>();
  const getServiceTypeListFunc = () => {
    getServiceTypeList(client(GET_SERVICE_TYPE));
  };

  const {
    run: getUsingDepartmentTypeList,
    isLoading: gettingUsingDepartmentTypeList,
    data: usingDepartmentTypeList,
  } = useAsync<ProductUsingDepartmentType[]>();
  const getUsingDepartmentTypeListFunc = () => {
    getUsingDepartmentTypeList(client(GET_PRODUCT_DEPARTMENT));
  };

  const {
    run: getUsingStatusTypeList,
    isLoading: gettingUsingStatusTypeList,
    data: usingStatusTypeList,
  } = useAsync<ProductUsingType[]>();
  const getUsingStatusTypeListFunc = () => {
    getUsingStatusTypeList(client(GET_USING_STATUS));
  };

  const {
    run: getServiceLevelTypeList,
    isLoading: gettingServiceLevelTypeList,
    data: serviceLevelTypeList,
  } = useAsync<ProductServiceLevelType[]>();
  const getServiceLevelTypeListFunc = () => {
    getServiceLevelTypeList(client(GET_SERVICE_LEVEL));
  };

  const [addProduct, setAddProduct] = useState<string | null>(null);

  const initState = () => {
    setAddProduct(null);
    setServiceType(undefined);
  };

  const submit = (value: any) => {
    if (id) {
      client(ADD_PRODUCT_DETAIL, {
        data: {
          ...value,
          hospital_id: parseInt(id.toString()),
          online_date: formatDate(value?.online_date) || undefined,
          start_date: formatDate(value?.start_date) || undefined,
          end_date: formatDate(value?.end_date) || undefined,
        },
        method: "POST",
      })
        .then((res) => {
          message.success("添加成功！");
          setVisible(false);
          setProduct({ product: res.id });
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("添加失败！请检查或者联系管理员！");
        });
    } else {
      message.error("无法请求添加！请刷新重试或者联系管理员！");
    }
  };

  const clear = () => {
    form.setFieldsValue({
      product_version_id: null,
      client_version: null,
    });
  };

  useEffect(() => {
    if (addProduct) {
      getProductVersionListFunc(addProduct);
      getProductClientListFunc(addProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProduct]);

  useEffect(() => {
    if (visible) {
      initState();
      form.resetFields();
      getProductListFunc();
      getUbVersionListFunc();
      getDeployTypeListFunc();
      getServiceTypeListFunc();
      getUsingDepartmentTypeListFunc();
      getUsingStatusTypeListFunc();
      getServiceLevelTypeListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="新增产品线信息"
      destroyOnClose
      forceRender
      open={visible}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      onOk={form.submit}
      bodyStyle={{
        minHeight: "12rem",
        maxHeight: "80vh",
        overflow: "auto",
      }}
    >
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="product_id"
          label="产品线"
          rules={[{ required: true, message: "必须选择产品线" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            showSearch
            loading={gettingProductList}
            placeholder="选择产品线"
            onChange={(v) => {
              clear();
              setAddProduct(v);
            }}
          >
            {productList?.map((i, index) => {
              if (i.title !== "UB" && i.title !== "DS") {
                return (
                  <Option key={index} value={i?.title}>
                    {i?.product_name}
                  </Option>
                );
              }
              return null;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="product_status"
          label="授权状态"
          rules={[{ required: true, message: "必须选择授权状态" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select showSearch placeholder="选择产品授权状态">
            {licenseStatus?.map((i, index) => (
              <Option key={index} value={i?.value}>
                {i?.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {addProduct?.toUpperCase() === RID ? (
          <Form.Item
            name="version_type"
            label="版本类型"
            normalize={(v) => DisposeForm(v)}
          >
            <Select allowClear placeholder="选择版本类型">
              {CT_LUNG_VERSION_TYPE?.map((i, index) => (
                <Option key={index} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Form.Item
          name="product_version_id"
          label="安装分支"
          // rules={[{ required: true, message: "必须选择安装分支" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            showSearch
            loading={gettingProductVersionList}
            placeholder="选择安装分支"
            filterOption={selectFilter}
          >
            {productVersionList?.map((i, index) => (
              <Option key={index} value={i?.id}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="ub_version_id"
          label="UB版本"
          rules={[{ required: true, message: "必须选择UB版本" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            showSearch
            loading={gettingUbVersionList}
            placeholder="选择UB版本"
            filterOption={selectFilter}
          >
            {ubVersionList?.map((i, index) => (
              <Option key={index} value={i?.id}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="threshold"
          label="模型阈值"
          normalize={(v) => DisposeForm(v)}
        >
          <Input style={{ width: "151px" }} />
        </Form.Item>
        <Form.Item
          name="number"
          label="单日体量"
          normalize={(v) => DisposeForm(v)}
        >
          <Input placeholder="说明体量" />
        </Form.Item>
        <Form.Item
          name="deploy_type_id"
          label="部署类型"
          rules={[{ required: true, message: "必须选择部署类型" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select loading={gettingDeployTypeList} placeholder="选择部署类型">
            {deployTypeList?.map((i, index) => (
              <Option key={index} value={i?.id}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="is_customization"
          label="产品定制化"
          rules={[{ required: true, message: "必须选择定制化情况" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select placeholder="选择定制化情况">
            {[
              { name: "是", value: 1 },
              { name: "否", value: 0 },
            ].map((i, index) => (
              <Option key={index} value={i.value}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="client_version"
          label="客户端版本"
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            showSearch
            loading={gettingProductClientList}
            placeholder="选择客户端版本"
            filterOption={selectFilter}
          >
            {productClientList?.map((i, index) => (
              <Option key={index} value={i?.id}>
                {i?.product_client_version_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="online_date"
          label="上线时间"
          // rules={[{ required: true, message: "必须选择上线时间" }]}
        >
          <DatePicker
            placeholder="选择上线时间"
            disabledDate={DisabledDate}
            picker={"date"}
          />
        </Form.Item>
        <Form.Item
          name="service_type_id"
          label="服务类型"
          // rules={[{ required: true, message: "必须选择服务类型" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingServiceTypeList}
            placeholder="选择服务类型"
            onChange={(v) => setServiceType(v)}
          >
            {serviceTypeList?.map((i, index) => (
              <Option key={index} value={i?.id}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="using_status_id"
          label="使用状态"
          rules={[{ required: true, message: "必须选择使用状态" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select loading={gettingUsingStatusTypeList} placeholder="使用状态">
            {usingStatusTypeList?.map((i, index) => (
              <Option key={index} value={i?.id}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="departments"
          label="使用科室"
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingUsingDepartmentTypeList}
            mode="multiple"
            placeholder="选择使用科室"
          >
            {usingDepartmentTypeList?.map((i, index) => (
              <Option key={index} value={i?.id}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="service_level_id"
          label="服务等级"
          rules={[
            {
              required: serviceType === 2 ? true : false,
              message: "必须选择服务等级",
            },
          ]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingServiceLevelTypeList}
            placeholder="服务等级"
            allowClear={serviceType === 2 ? false : true}
          >
            {serviceLevelTypeList?.map((i, index) => (
              <Option key={index} value={i?.id}>
                {i?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Divider />
        <Form.Item name="start_date" label="维保开始时间">
          <DatePicker placeholder="选择开始时间" picker={"date"} />
        </Form.Item>
        <Form.Item name="end_date" label="维保结束时间">
          <DatePicker placeholder="选择结束时间" picker={"date"} />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};
