import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useBreakDetailSolve = () => {
  const [{ openBreakDetailSolve }, setOpenBreakDetailSolve] = useUrlQueryParam([
    "openBreakDetailSolve",
  ]);

  const open = () => setOpenBreakDetailSolve({ openBreakDetailSolve: true });
  const close = () =>
    setOpenBreakDetailSolve({
      openBreakDetailSolve: undefined,
    });

  return [openBreakDetailSolve === "true", open, close] as const;
};
