import { useState } from "react";
import {
  ActionSheet,
  Collapse,
  Dialog,
  List,
  Popover,
  Space,
  Tag,
  Toast,
} from "antd-mobile";
import {
  UnorderedListOutline,
  ExclamationCircleFill,
  DeleteOutline,
  EditSOutline,
  UserCircleOutline,
} from "antd-mobile-icons";
import type { Action } from "antd-mobile/es/components/action-sheet";

import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import {
  ListRightIconSize,
  isImageFormat,
} from "authenticated-app/mobile/util";
import { isNumber } from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { DEL_HARDWARE } from "lib/api";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { DelConfirmContent } from "authenticated-app/mobile/components/DelConfirmContent";
import { HardwareServerType } from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";
import { AttachmentDiv } from "authenticated-app/mobile/components/AttachmentDiv";
import { MobileServerEdit } from "./edit";

export const MobileServerInfo = ({
  hospID,
  detail,
  reload,
}: {
  hospID: number | string;
  detail: HardwareServerType;
  reload: () => void;
}) => {
  const [editVisible, setEditVisible] = useState<boolean>(false);

  const [actionVisible, setActionVisible] = useState<boolean>(false);

  const client = useHttp();

  const { run: del, isLoading: deleting } = useAsync();
  const delHandle = (id?: number) => {
    if (id)
      del(client(DEL_HARDWARE(id), { method: "DELETE" }))
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="删除成功" />,
          });
          reload();
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="删除失败" />,
          });
        });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法删除" />,
      });
  };

  const actions: Action[] = [
    {
      text: (
        <Space>
          <EditSOutline />
          编辑服务器
        </Space>
      ),
      key: "edit",
      onClick: () => {
        setActionVisible(false);
        setEditVisible(true);
      },
    },
    {
      text: (
        <Space>
          <DeleteOutline />
          删除服务器
        </Space>
      ),
      key: "delete",
      description: "删除后不可恢复",
      danger: true,
      onClick: () => {
        setActionVisible(false);
        Dialog.confirm({
          header: (
            <ExclamationCircleFill
              style={{
                fontSize: 48,
                color: "var(--adm-color-danger)",
              }}
            />
          ),
          content: (
            <DelConfirmContent content={detail?.server_ip || "服务器"} />
          ),
          confirmText: (
            <span style={{ color: "var(--adm-color-danger)" }}>确定</span>
          ),
          onConfirm: () => delHandle(detail?.id),
        });
      },
    },
  ];

  return (
    <ContentCard>
      <LoadingMask visible={deleting} />
      <List
        mode="card"
        header={
          <ListHeader
            text={
              <Space>
                <span>服务器基本信息</span>
                <Popover
                  content={
                    <Space direction="vertical">
                      <span>更新人: {detail?.update_user || "未知"}</span>
                      <span>更新时间: {detail?.update_time || "未知"}</span>
                    </Space>
                  }
                  trigger="click"
                  mode="dark"
                >
                  <UserCircleOutline />
                </Popover>
                {detail.is_mult ? (
                  <Tag
                    round
                    color="#108ee9"
                    style={{
                      transform: "translateY(-2px)",
                    }}
                  >
                    多机
                  </Tag>
                ) : null}
              </Space>
            }
            rightIcon={<UnorderedListOutline fontSize={ListRightIconSize} />}
            loading={actionVisible || editVisible}
            onClick={() => {
              setActionVisible(true);
            }}
          />
        }
      >
        <List.Item extra={detail?.eqpt_oem_name || "未知"}>设备品牌</List.Item>
        <List.Item extra={detail?.eqpt_model_name || "未知"}>
          设备型号
        </List.Item>
        <List.Item extra={detail?.server_ip || "未知"}>服务器IP</List.Item>
        <List.Item
          extra={
            detail?.is_master === 1
              ? "是"
              : detail?.is_master === 0
              ? "否"
              : "未知"
          }
        >
          是否主机
        </List.Item>
        <List.Item
          extra={detail?.products.map((i) => i.product_id).join("、") || "未知"}
        >
          运行产品
        </List.Item>
        <List.Item extra={detail?.system_version_name || "未知"}>
          系统版本
        </List.Item>
        <List.Item
          extra={
            isNumber(detail?.memory_capacity) && isNumber(detail?.memory_num)
              ? `${
                  detail?.memory_capacity! * detail?.memory_num!
                } (${detail?.memory_capacity}*${detail?.memory_num})`
              : "未知"
          }
        >
          内存大小 (GB)
        </List.Item>
        <List.Item extra={detail?.ssd_capacity || "未知"}>
          SSD大小 (GB)
        </List.Item>
        <List.Item
          extra={
            isNumber(detail?.disk_capacity) && isNumber(detail?.disk_num)
              ? `${
                  detail?.disk_capacity! * detail?.disk_num!
                } (${detail?.disk_capacity}*${detail?.disk_num})`
              : "未知"
          }
        >
          机械盘大小 (TB)
        </List.Item>
        <List.Item extra={detail?.assets_code || "未知"}>资产编码</List.Item>
        <List.Item extra={detail?.maintenance_code || "未知"}>
          维保代码
        </List.Item>
        <Collapse>
          <Collapse.Panel key={"server-location"} title="放置位置">
            {detail?.location || "未知"}
          </Collapse.Panel>
        </Collapse>
        <Collapse>
          <Collapse.Panel key={"server-asset_photos"} title="资产照片">
            {detail?.asset_photos.length ? (
              <Space direction="vertical">
                {detail?.asset_photos?.map((item, index) => (
                  <AttachmentDiv
                    key={index}
                    isImage={isImageFormat(item.attachment_name)}
                    attachment={item}
                    width={"80vw"}
                  />
                ))}
              </Space>
            ) : (
              "无"
            )}
          </Collapse.Panel>
        </Collapse>
      </List>
      <ActionSheet
        visible={actionVisible}
        actions={actions}
        onClose={() => setActionVisible(false)}
      />
      <MobileServerEdit
        hospID={hospID}
        editingItem={detail}
        visible={editVisible}
        setVisible={setEditVisible}
        reload={reload}
      />
    </ContentCard>
  );
};
