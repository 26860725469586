import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Modal, Result, Spin, UploadProps } from "antd";
import { message, Upload, Typography } from "antd";
import { RcFile } from "antd/lib/upload/interface";

import { usePostFile } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { SERVICE_RECORD, SERVICE_RECORD_CONFIRM } from "lib/api";

const { Dragger } = Upload;
const { Paragraph } = Typography;

const fileSize = 1024 * 1024 * 20; // 10M

export const ServerInfoScan = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  const client = usePostFile();
  const [isSubSuccess, setIsSubSuccess] = useState<boolean>(false);
  const [isHasError, setIsHasError] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<string[]>([]);

  const { run: postFile, isLoading: postingFile } = useAsync();
  const { run: confirmScan, isLoading: confirmingScan } = useAsync();

  const props: UploadProps = {
    fileList: [],
    maxCount: 1,
    accept: "image/*",
    customRequest(options) {
      const file = options.file as RcFile;
      const isLt2M = file.size <= fileSize;
      let url: string;
      const fmData = new FormData();

      if (isLt2M) {
        if (file.type.startsWith("image/")) {
          url = SERVICE_RECORD;
          fmData.append("qr_code", file);
        } else {
          message.error(file.name + "不是图片类型", 2);
          return;
        }
      } else {
        message.error(file.name + "文件大小超出20M，无法上传！", 2);
        return;
      }

      postFile(
        client(url, {
          body: fmData,
        }),
      )
        .then(async (res) => {
          let msg = await res.json();
          Modal.confirm({
            content: `是否提交如下医院的采集数据: ${msg.hospital_list?.map(
              (i: string) => i,
            )}`,
            okButtonProps: {
              loading: confirmingScan,
            },
            onOk: () => {
              const fmData = new FormData();
              fmData.append("qr_code", file);
              confirmScan(
                client(SERVICE_RECORD_CONFIRM, {
                  body: fmData,
                }),
              )
                .then(async (res) => {
                  let msg = await res.json();
                  if (msg?.error_list?.length > 0) {
                    setIsHasError(true);
                    setErrorList(msg?.error_list);
                  } else {
                    setIsSubSuccess(true);
                  }
                })
                .catch(() => {
                  message.error("提交失败");
                });
            },
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
  };

  const initStatus = () => {
    setErrorList([]);
    setIsHasError(false);
    setIsSubSuccess(false);
  };

  useEffect(() => {
    if (visible) {
      initStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={"数据采集"}
      open={visible}
      destroyOnClose
      maskClosable={false}
      onCancel={() => setVisible(false)}
      footer={null}
      width={"640px"}
      closable={!isSubSuccess && !isHasError}
    >
      {isSubSuccess || isHasError ? (
        <Result
          status={isHasError ? "error" : "success"}
          title={isHasError ? "提交出错" : "提交成功"}
          subTitle={isHasError ? "表格校验出错，详情如下" : "完成导入"}
          extra={[
            <Button
              key="subAgain"
              type="primary"
              onClick={() => {
                initStatus();
              }}
            >
              再次上传
            </Button>,
            <Button
              key="backCharts"
              onClick={() => {
                setVisible(false);
              }}
            >
              关闭窗口
            </Button>,
          ]}
        >
          {isHasError ? (
            <Paragraph
              style={{
                maxHeight: 256,
                overflowY: "scroll",
              }}
            >
              {errorList.map((v, i) => (
                <p key={`import-${i}`}>{v}</p>
              ))}
            </Paragraph>
          ) : null}
        </Result>
      ) : (
        <Spin spinning={postingFile || confirmingScan}>
          <br />
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">点击或拖动文件到此区域进行上传</p>
            <p className="ant-upload-hint">
              数据采集支持png, jpg, jepg等图片格式
            </p>
          </Dragger>
        </Spin>
      )}
    </Modal>
  );
};
