import { Fragment } from "react";
import { PullToRefresh, Toast } from "antd-mobile";
import { useDebounceFn } from "ahooks";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import {
  ADD_RETAIN,
  DEL_RETAIN,
  GET_HOSP_DETAIL,
  UPDATE_RETAIN,
} from "lib/api";
import { useMount } from "utils";
import { DetailType } from "authenticated-app/pc/customer/customer-detail/tab-pane/infoTabPane/interface";
import { MobileHospBaseInfo } from "./hosp-base-info";
import { MobileHospUserInfoList } from "./hosp-user-info";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { MobileRetainInfo } from "../general/retain-info";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { MobileInsiderInfo } from "./insider-info";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const MobileBaseInfo = () => {
  const [{ detailId }] = useUrlQueryParam(["detailId"]);

  const client = useHttp();

  const {
    run: getHospDetail,
    isLoading,
    data,
    isIdle,
  } = useAsync<DetailType>();
  const getHospDetailHandle = () => {
    if (detailId) {
      getHospDetail(client(GET_HOSP_DETAIL(detailId)));
    } else {
      Toast.show({
        icon: "fail",
        content: <ToastContent content="获取失败" />,
      });
    }
  };

  const { run } = useDebounceFn(getHospDetailHandle, { wait: 200 });

  useMount(run);

  const Loading = isLoading || isIdle;

  return (
    <div style={{ width: "100%" }}>
      <LoadingMask visible={Loading} />
      <PullToRefresh
        onRefresh={async () => getHospDetailHandle()}
        refreshingText={null}
      >
        <div className="customer-content">
          <Fragment>
            <MobileHospBaseInfo detail={data} reload={getHospDetailHandle} />
            <MobileHospUserInfoList
              hosUserInfo={data?.hos_users}
              reload={getHospDetailHandle}
            />
            <MobileInsiderInfo insiders={data?.insiders} />
            <MobileRetainInfo
              orgID={detailId}
              retain={data?.retain_infos}
              RetainAPI={{
                ADD_RETAIN: ADD_RETAIN,
                UPDATE_RETAIN: UPDATE_RETAIN,
                DEL_RETAIN: DEL_RETAIN,
              }}
              reload={getHospDetailHandle}
            />
          </Fragment>
        </div>
      </PullToRefresh>
    </div>
  );
};
