import { MutableRefObject, useEffect, useRef, useState } from "react";
import { ActionType, PageHeader } from "@ant-design/pro-components";
import {
  Divider,
  Form,
  Modal,
  Spin,
  Descriptions,
  Space,
  Button,
  Tooltip,
  message,
} from "antd";
import {
  PlayCircleOutlined,
  SwapOutlined,
  VerticalAlignTopOutlined,
  CheckOutlined,
  RollbackOutlined,
  LockOutlined,
  UnlockOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { formatDate, FormatDate } from "utils";
import { useDebounceFn } from "ahooks";
import { UpdateBoolSelect } from "authenticated-app/pc/components/UpdateBoolSelect";
import { UpdateDateSelect } from "authenticated-app/pc/components/UpdateDateSelect";
import { UpdateMultiSelect } from "authenticated-app/pc/components/UpdateMultipleSelect";
import { UpdateSelect } from "authenticated-app/pc/components/UpdateSelect";
import { UpdateInputNoTip } from "authenticated-app/pc/components/UpdateInputNoTip";
import { useAuth } from "context/auth";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { useVisitListDetail } from "authenticated-app/hooks/visitlist/useVisitListDetail";
import { VISIT_DETAIL_LIST } from "lib/interface/visitlist";
import { CompetitiveProductInfoType } from "../interface";
// import {
//   GET_VISIT_DETAIL,
//   GET_EVENT_TYPE,
//   GET_VISIT_TYPE,
//   UPDATE_VISIT_DETAIL,
//   GET_BUSINESS_TYPE,
// } from "lib/api/issues";
import {
  GET_COMPETITIVE_PRODUCT_DETAIL,
  DELETE_COMPETITIVE_PRODUCT,
  UPDATE_COMPETITIVE_PRODUCT,
} from "lib/api";
import { EditQuill } from "authenticated-app/pc/components/EditQuill";
import { EditUpload } from "authenticated-app/pc/components/EditUpload";
import { useCompetitiveDetail } from "authenticated-app/hooks/Competitive/useCompetitiveDetail";
import { UserOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { UpdateInput } from "authenticated-app/pc/components/UpdateInput";
const { confirm } = Modal;

export const CompetitiveDetailModal = ({
  actionRef,
}: {
  actionRef: MutableRefObject<ActionType | undefined>;
}) => {
  const { user } = useAuth();

  const [isChanged, setIsChanged] = useState(false);

  const [isOpen, , close] = useCompetitiveDetail();

  const [{ detailId }] = useUrlQueryParam(["detailId"]);

  const divRef = useRef<HTMLDivElement>(null);
  // const scroll = useScroll(divRef);

  const [form] = Form.useForm();
  const client = useHttp();
  const { Item } = Descriptions;
  const {
    run: getDetailInfo,
    data: detailInfo,
    isIdle,
    isLoading,
    setData,
  } = useAsync<CompetitiveProductInfoType>();

  const getDetailInfoHandle = () => {
    getDetailInfo(client(GET_COMPETITIVE_PRODUCT_DETAIL(detailId)));
  };
  const { run: getDetailInfoDebounce } = useDebounceFn(getDetailInfoHandle, {
    wait: 200,
  });

  const { run: update } = useAsync();
  const onUpdate = (value: any) => {
    if (detailInfo?.id) {
      update(
        client(UPDATE_COMPETITIVE_PRODUCT(detailInfo?.id), {
          method: "PUT",
          data: {
            attachment_id_list: detailInfo.attachment_list?.map(
              (i) => i.attachment_id,
            ),
            ...value,
          },
        }),
      )
        .then((res) => {
          const content = `更新成功!`;
          message.success(content, 5);
          setIsChanged(true);
          setData(res);
        })
        .catch(() => getDetailInfoDebounce());
    }
  };

  const { run: competitiveDelete } = useAsync();
  const onDelete = (id: any) => {
    if (detailInfo?.id) {
      competitiveDelete(
        client(DELETE_COMPETITIVE_PRODUCT(id), {
          method: "DELETE",
        }),
      )
        .then((res) => {
          const content = `删除成功!`;
          message.success(content, 5);
          actionRef.current?.reload();
        })
        .catch((error) => {
          const content = `删除失败!`;
          message.error(content, 5);
          actionRef.current?.reload();
        });
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "确定要删除这条竞品信息吗?",
      icon: <ExclamationCircleFilled />,
      okText: "确认删除",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        onDelete(detailId);
        close();
        actionRef.current?.reload();
      },
    });
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      setIsChanged(false);
      if (detailId) {
        getDetailInfoDebounce();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailId]);

  const Loading = isIdle || isLoading;
  const isFinished = !!!isIdle;
  const isHasPermission = user?.userName === detailInfo?.create_user;
  const isCanChange = isFinished && isHasPermission;

  return (
    <Modal
      centered
      maskClosable={false}
      destroyOnClose
      open={isOpen}
      afterClose={() => {
        setData(null);
        if (isChanged) {
          actionRef.current?.reload();
        }
      }}
      onCancel={close}
      footer={null}
      width={"93vw"}
      style={{
        minWidth: "928px",
        maxWidth: "93vw",
        height: "92vh",
      }}
    >
      <Spin spinning={Loading} style={{ height: "100%" }}>
        <PageHeader
          className="page-header"
          title={
            <Space>
              <span style={{ fontWeight: "normal", marginRight: "8px" }}>
                竞品信息
              </span>
              <span>{detailInfo?.hospital_name}</span>
              <span style={{ fontWeight: "normal", margin: "8px" }}>
                {detailInfo?.name}
              </span>
            </Space>
          }
          extra={
            <Space style={{ marginRight: 60 }}>
              <Tooltip title="创建人">
                <Button type="primary" shape="round" icon={<UserOutlined />}>
                  {detailInfo?.create_user}
                </Button>
              </Tooltip>
              {detailInfo?.update_time !== detailInfo?.create_time ? (
                <>
                  <Tooltip title="更新时间">
                    <Button
                      type="primary"
                      shape="round"
                      icon={<FieldTimeOutlined />}
                    >
                      {detailInfo?.update_time}
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="创建时间">
                    <Button
                      type="primary"
                      shape="round"
                      icon={<FieldTimeOutlined />}
                    >
                      {detailInfo?.create_time}
                    </Button>
                  </Tooltip>
                </>
              )}
              {isCanChange ? (
                <>
                  <Button
                    key={"delete_break"}
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      //   setBreakDeleteHandleOpening(true);
                      showDeleteConfirm();
                    }}
                    // loading={breakDeleteHandleOpening}
                    type="primary"
                    danger
                  >
                    删除
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Space>
          }
        />

        <Divider style={{ margin: "12px 0" }} />
        <div ref={divRef} className="break-detail-div">
          <div style={{ flex: 3, height: "100%" }}>
            <Descriptions
              className="break-base-desc-title"
              size="small"
              column={3}
            >
              <Item label={"竞品名称"}>{detailInfo?.name}</Item>
              <Item>
                {/* {detailInfo?.product_info} */}
                <UpdateInput
                  value={detailInfo?.product_info}
                  onUpdate={onUpdate}
                  label={"产品线及版本"}
                  name={"product_info"}
                  isCanChange={isCanChange}
                  isLink
                />
              </Item>
            </Descriptions>
            <Descriptions
              className="break-base-desc-title"
              size="small"
              column={3}
            >
              <Item label={"上线时间"}>
                {FormatDate(detailInfo?.online_date)}
              </Item>
              <Item>
                <UpdateInput
                  value={detailInfo?.access_address}
                  onUpdate={onUpdate}
                  label={"访问链接"}
                  name={"access_address"}
                  needUrlRegex
                  isCanChange={isCanChange}
                  isLink
                />
              </Item>
              <Item>
                <UpdateInput
                  value={detailInfo?.user_passwd}
                  onUpdate={onUpdate}
                  label={"用户名密码"}
                  name={"user_passwd"}
                  isCanChange={isCanChange}
                  isLink
                />
              </Item>
            </Descriptions>

            <Divider style={{ margin: "12px 0" }} />
            <EditQuill
              onUpdate={onUpdate}
              name={"function_info"}
              title={"竞品功能"}
              info={detailInfo?.function_info || ""}
              isCanChange={isCanChange}
            />
            <EditQuill
              onUpdate={onUpdate}
              name={"lightspot"}
              title={"竞品亮点"}
              info={detailInfo?.lightspot || ""}
              isCanChange={isCanChange}
            />
            <EditUpload
              onUpdate={onUpdate}
              name={"attachment_id_list"}
              title={"附件"}
              defaultValue={detailInfo?.attachment_list}
              isCanChange={isCanChange}
              maxCount={20}
            />
            <br />
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
