import { AbnormalColumns } from "authenticated-app/pc/ticket/checklist/detail/abnormalColumns";
import { SurveyColumns } from "authenticated-app/pc/ticket/checklist/detail/surveyColumns";
import { WorkColumns } from "authenticated-app/pc/ticket/checklist/detail/workColumns";
import { BoolValue, CheckValue } from "../base";

export enum CHECKLIST_ENUM {
  exit_list = "exit_list", // 退场清单
  online_list = "online_list", // 上线清单
  survey_list = "survey_list", //  调研清单
  except_list = "except_list", //  版本异常清单
}

export const CHECKLIST_ENUM_OPTION = [
  { value: CHECKLIST_ENUM.exit_list, label: "退场清单" },
  { value: CHECKLIST_ENUM.online_list, label: "上线清单" },
  { value: CHECKLIST_ENUM.survey_list, label: "调研清单" },
  { value: CHECKLIST_ENUM.except_list, label: "版本异常清单" },
];

export const CHECKLIST_ENUM_MAP = new Map([
  [CHECKLIST_ENUM.exit_list, "退场清单"],
  [CHECKLIST_ENUM.online_list, "上线清单"],
  [CHECKLIST_ENUM.survey_list, "调研清单"],
  [CHECKLIST_ENUM.except_list, "版本异常清单"],
]);

export const CHECKLIST_COLUMNS_MAP = new Map([
  [CHECKLIST_ENUM.exit_list, WorkColumns],
  [CHECKLIST_ENUM.online_list, WorkColumns],
  [CHECKLIST_ENUM.survey_list, SurveyColumns],
  [CHECKLIST_ENUM.except_list, AbnormalColumns],
]);

export interface CHECKLIST_LINKS_TYPE {
  next: null | string;
  previous: null | string;
}

export interface SHARED_USER_TYPE {
  user_id: number;
  user_name: string;
}

export interface CHECKLIST_RESULT_TYPE {
  id: number;
  hospital_id: number;
  assignee_id: number;
  source_order: string;
  check_type: CHECKLIST_ENUM;
  is_submit: BoolValue;
  submit_time: Date | null;
  create_time: Date | null;
  assignee_name: string;
  hospital_name: string;
  hospital_code: string;
  area_name: string;
  province_name: string;
  city_name: string;
  share_users: SHARED_USER_TYPE[];
}

export interface CHECKLIST_TYPE {
  links: CHECKLIST_LINKS_TYPE;
  number: number;
  num_pages: number;
  count: number;
  results: CHECKLIST_RESULT_TYPE[];
}
export interface CHECKLIST_VALUE_TYPE {
  id: number;
  is_need: BoolValue;
  is_start: BoolValue;
  value: CheckValue;
  product_id: string;
  product_version: string;
  problem_content: string;
  product_name: string;
  repair_mode: string;
  description: string;
  check_item?: string;
  content?: string;
}

export interface CHECKLIST_DETAIL_TYPE {
  id: number;
  hospital_id: number;
  assignee_id: number;
  source_order: string;
  check_type: string;
  is_submit: BoolValue;
  submit_time: Date | null;
  create_time: Date | null;
  assignee_name: string;
  hospital_name: string;
  hospital_code: string;
  area_name: string;
  province_name: string;
  city_name: string;
  checklist_values: CHECKLIST_VALUE_TYPE[];
}

export interface SCAN_CALLBACKL_HOSPLIST {
  hospital_list: string[];
}
