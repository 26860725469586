import { useState } from "react";
import {
  ActionSheet,
  Dialog,
  List,
  Popover,
  Space,
  Toast,
  Tag,
} from "antd-mobile";
import {
  UnorderedListOutline,
  ExclamationCircleFill,
  DeleteOutline,
  EditSOutline,
  UserCircleOutline,
} from "antd-mobile-icons";
import type { Action } from "antd-mobile/es/components/action-sheet";

import { ProductInfoType } from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { ListRightIconSize } from "authenticated-app/mobile/util";
import { RID } from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { DEL_PRODUCT_DETAIL } from "lib/api";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { DelConfirmContent } from "authenticated-app/mobile/components/DelConfirmContent";
import { MobileProductBaseInfoEdit } from "./edit";

export const MobileProductBaseInfo = ({
  detail,
  reload,
}: {
  detail: ProductInfoType | undefined;
  reload: () => void;
}) => {
  const [editVisible, setEditVisible] = useState<boolean>(false);

  const [actionVisible, setActionVisible] = useState<boolean>(false);

  const client = useHttp();

  const { run: del, isLoading: deleting } = useAsync();
  const delHandle = (id?: number) => {
    if (id)
      del(client(DEL_PRODUCT_DETAIL(id), { method: "DELETE" }))
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="删除成功" />,
          });
          reload();
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="删除失败" />,
          });
        });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法删除" />,
      });
  };

  const actions: Action[] = [
    {
      text: (
        <Space>
          <EditSOutline />
          编辑产品线
        </Space>
      ),
      key: "edit",
      onClick: () => {
        setActionVisible(false);
        setEditVisible(true);
      },
    },
    {
      text: (
        <Space>
          <DeleteOutline />
          删除产品线
        </Space>
      ),
      key: "delete",
      description: "删除后不可恢复",
      danger: true,
      onClick: () => {
        setActionVisible(false);
        Dialog.confirm({
          header: (
            <ExclamationCircleFill
              style={{
                fontSize: 48,
                color: "var(--adm-color-danger)",
              }}
            />
          ),
          content: (
            <DelConfirmContent content={detail?.product_name || "产品线"} />
          ),
          confirmText: (
            <span style={{ color: "var(--adm-color-danger)" }}>确定</span>
          ),
          onConfirm: () => delHandle(detail?.id),
        });
      },
    },
  ];

  const getProductStatus = (status: number | undefined, icon?: boolean) => {
    if (status === 1) {
      return icon ? (
        <Tag color="green" style={{ margin: 0 }}>
          已授权{" "}
        </Tag>
      ) : (
        "已授权"
      );
    } else if (status === 2) {
      return icon ? (
        <Tag color="orange" style={{ margin: 0 }}>
          未授权{" "}
        </Tag>
      ) : (
        "未授权"
      );
    } else if (status === 3) {
      return icon ? (
        <Tag color="lime" style={{ margin: 0 }}>
          无需授权{" "}
        </Tag>
      ) : (
        "无需授权"
      );
    } else {
      return "未知状态";
    }
  };

  return (
    <ContentCard>
      <LoadingMask visible={deleting} />
      <List
        mode="card"
        header={
          <ListHeader
            text={
              <Space>
                <span>产品基本信息</span>
                <Popover
                  content={
                    <Space direction="vertical">
                      <span>更新人: {detail?.update_user || "未知"}</span>
                      <span>更新时间: {detail?.update_time || "未知"}</span>
                    </Space>
                  }
                  trigger="click"
                  mode="dark"
                >
                  <UserCircleOutline />
                </Popover>
              </Space>
            }
            rightIcon={<UnorderedListOutline fontSize={ListRightIconSize} />}
            loading={actionVisible || editVisible}
            onClick={() => {
              setActionVisible(true);
            }}
          />
        }
      >
        <List.Item extra={detail?.product_name || "未知"}>产品线</List.Item>
        <List.Item extra={getProductStatus(detail?.product_status)}>
          授权状态
        </List.Item>
        <List.Item
          extra={
            detail?.is_customization === 1
              ? "是"
              : detail?.is_customization === 0
              ? "否"
              : "未知"
          }
        >
          产品定制化
        </List.Item>
        <List.Item extra={detail?.deploy_type_name || "未知"}>
          部署类型
        </List.Item>
        <List.Item extra={detail?.using_status_name || "未知"}>
          使用状态
        </List.Item>
        {detail?.product_id === RID ? (
          <List.Item extra={detail?.version_type || "未知"}>版本类型</List.Item>
        ) : null}
        <List.Item extra={detail?.product_version_name || "未知"}>
          安装分支
        </List.Item>
        <List.Item extra={detail?.ub_version_name || "未知"}>UB版本</List.Item>
        <List.Item extra={detail?.client_version_name || "未知"}>
          客户端版本
        </List.Item>
        <List.Item extra={detail?.online_date || "未知"}>上线时间</List.Item>
        <List.Item extra={detail?.service_type_name || "未知"}>
          服务类型
        </List.Item>
        <List.Item extra={detail?.service_level_name || "未知"}>
          服务等级
        </List.Item>
        <List.Item extra={detail?.number || "未知"}>单日体量</List.Item>
        <List.Item extra={detail?.threshold || "未知"}>模型阈值</List.Item>
        <List.Item
          extra={detail?.departments?.map((i) => i?.name).join("、") || "未知"}
        >
          使用科室
        </List.Item>
        <List.Item
          extra={
            detail?.start_date
              ? `${detail?.start_date}至${detail?.end_date}`
              : "未知"
          }
        >
          维保时间
        </List.Item>
      </List>
      <ActionSheet
        visible={actionVisible}
        actions={actions}
        onClose={() => setActionVisible(false)}
      />
      <MobileProductBaseInfoEdit
        editingItem={detail}
        visible={editVisible}
        setVisible={setEditVisible}
        reload={reload}
      />
    </ContentCard>
  );
};
