import { useEffect, useState, useRef } from "react";
import { Button, DatePicker, Card, Select, Space } from "antd";

import { ProTable, ProColumns, ActionType } from "@ant-design/pro-components";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { formatDate } from "utils";
import { HospListType } from "interface";
import { PREDICT_TIME_TYPE } from "../interface";
import { GET_PREDICT_TASK_TYPE, GET_PREDICT_TIME_INFO } from "lib/api";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
export const HosPredictTime = ({
  hospInfo,
  stateKey,
}: {
  hospInfo: HospListType | undefined;
  stateKey?: string;
}) => {
  const client = useHttp();
  const actionRef = useRef<ActionType>();
  const { height } = useWindowSize();
  const [page, setPage] = useState<number>(1);
  const DEFAULT_DAYS = 7;
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(
    dayjs().subtract(DEFAULT_DAYS, "days"),
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());
  const [selectedTaskType, setSelectedTaskType] = useState<string>("所有任务");

  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [taskTypeList, setTaskTypeList] = useState<any[]>([]);
  const {
    run: getPredictTaskType,
    data: predictTaskTypeList,
    isIdle: getPredictTaskTypeIsIdle,
    isLoading: gettingPredictTaskTypeList,
  } = useAsync<any[]>();
  const getPredictTaskTypeFunc = (params?: { [key in any]: any }) => {
    if (hospInfo?.id) {
      getPredictTaskType(client(GET_PREDICT_TASK_TYPE(hospInfo?.id)));
    }
  };
  const loading = getPredictTaskTypeIsIdle || gettingPredictTaskTypeList;

  const columns: ProColumns<PREDICT_TIME_TYPE>[] = [
    { title: "日期", dataIndex: "statics_date" },
    { title: "任务类型", dataIndex: "chinese_name" },
    {
      title: "序列数量",
      dataIndex: "count",
    },
    {
      title: "数量最大|平均",
      render: (text, record) => {
        return record.max_count + "|" + record.avg_count;
      },
    },
    {
      title: "传输延迟最大|平均",
      render: (text, record) => {
        return (
          record.max_transfer_delay_seconds +
          "|" +
          record.avg_transfer_delay_seconds
        );
      },
    },

    {
      title: "图像接收最大|平均",
      render: (text, record) => {
        return record.max_receive_seconds + "|" + record.avg_receive_seconds;
      },
    },
    {
      title: "图像处理最大|平均",
      render: (text, record) => {
        return record.max_ds_seconds + "|" + record.avg_ds_seconds;
      },
    },
    {
      title: "等待耗时最大|平均",
      render: (text, record) => {
        return record.max_wait_seconds + "|" + record.avg_wait_seconds;
      },
    },
    {
      title: "预测耗时最大|平均",
      render: (text, record) => {
        return record.max_predict_seconds + "|" + record.avg_predict_seconds;
      },
    },

    {
      title: "端到端耗时最大|平均",
      render: (text, record) => {
        return (
          record.max_end_to_end_seconds + "|" + record.avg_end_to_end_seconds
        );
      },
    },
  ];

  const getParams = (params: any) => {
    let type_name = {};
    if (selectedTaskType !== "所有任务") {
      type_name = { type_name: selectedTaskType };
    }
    let start_date = formatDate(startDate);
    let end_date = formatDate(endDate);
    let queryParams = {
      start_date,
      end_date,
    };

    return {
      ...params,
      ...queryParams,
      ...type_name,
    };
  };

  const search = (params: any) => {
    if (hospInfo?.id) {
      return client(GET_PREDICT_TIME_INFO(hospInfo.id), {
        data: { ...params },
      });
    }
  };

  useEffect(() => {
    setStartDate(dayjs().subtract(DEFAULT_DAYS, "days"));
    setEndDate(dayjs());
  }, [hospInfo]);

  useEffect(() => {
    if (hospInfo && stateKey === "predict_time") {
      getPredictTaskTypeFunc();
    }
  }, [hospInfo, stateKey]);

  useEffect(() => {
    if (stateKey === "predict_time" && hospInfo) {
      actionRef.current?.reload();
    }
  }, [stateKey]);

  useEffect(() => {
    if (predictTaskTypeList) {
      const newTaskTypeList = [
        { label: "所有任务", value: "所有任务" },
        ...predictTaskTypeList.map((item: any) => ({
          label: item,
          value: item,
        })),
      ];
      setTaskTypeList(newTaskTypeList);
    }
  }, [predictTaskTypeList]);

  return (
    <Card>
      <ProTable<PREDICT_TIME_TYPE>
        columns={columns}
        rowKey="id"
        dateFormatter="string"
        actionRef={actionRef}
        tableExtraRender={(props) => [
          <Space>
            <RangePicker
              disabled={loadingData}
              allowClear={false}
              value={[startDate, endDate]}
              style={{ width: 240 }}
              onChange={(v) => {
                if (v) {
                  setStartDate(v[0] || dayjs());
                  setEndDate(v[1] || dayjs());
                }
              }}
            />
            <Select
              disabled={loadingData}
              loading={loading}
              value={selectedTaskType}
              defaultValue={"所有任务"}
              onChange={(value) => {
                setSelectedTaskType(value);
              }}
              options={taskTypeList || []}
              placeholder={"请选择任务类型"}
              style={{ width: 200, marginLeft: 10 }}
            />
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              loading={loadingData}
              onClick={() => {
                setPage(1);
                actionRef.current?.reload();
              }}
            >
              查询
            </Button>
          </Space>,
        ]}
        toolBarRender={false}
        search={false}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: false,
          pageSize:
            Math.trunc((height - 382) / 47) > 5
              ? Math.trunc((height - 382) / 47)
              : 5,
          current: page,
          onChange: (v) => {
            setPage(v);
          },
        }}
        request={async (params) => {
          try {
            let queryParams = getParams(params);
            setLoadingData(true);
            const res = await search(queryParams);
            return {
              data: res.results,
              total: res.count,
              success: true,
            };
          } catch (e) {
            return { success: false };
          } finally {
            setLoadingData(false);
          }
        }}
      />
    </Card>
  );
};

export default HosPredictTime;
