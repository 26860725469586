import { MyBarChartType } from "../../chart/bar-chart";
import { BaseValueType } from "../../interface";

const GetType = (data: BaseValueType[]) => {
  let tmpType: Map<string, { data: number }> = new Map();
  for (let r of data) {
    tmpType.set(r.name, { data: 0 });
  }
  return tmpType;
};

export const GetClientNum = (data: BaseValueType[]) => {
  let tmpType = GetType(data);

  let tmpData: MyBarChartType = {
    xData: [],
    series: [],
  };

  data.forEach((item) => {
    tmpType.get(item.name)!.data += item.value;
  });

  let tmpSeriesData: number[] = [];
  tmpType.forEach((v, k) => {
    tmpData.xData?.push(k);
    tmpSeriesData.push(v.data);
  });
  tmpData.series?.push({ data: tmpSeriesData, type: "bar" });

  return tmpData;
};
