import { useState, Fragment } from "react";
import { Button, Descriptions, Space, Spin } from "antd";
import { EditOutlined, RedoOutlined } from "@ant-design/icons";
import { useDebounceFn } from "ahooks";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { GET_FRONT_RESOURCES, GET_PACKAGE_LIST } from "../api";
import { useMount } from "utils";
import { FrontPermissionSettingEdit } from "./edit";
import { PackageType, ResourceRelationType } from "../interface";
import { GET_EMPLOYEE_TYPE } from "authenticated-app/pc/setting/customer-setting/api";
import { UserType } from "authenticated-app/pc/setting/customer-setting/interface";

const FrontPermissionSetting = () => {
  // 必要信息state
  const [editSettingVisible, setEditSettingVisible] = useState(false);
  const client = useHttp();

  const {
    run: getSettingList,
    isIdle,
    isLoading: gettingSettingList,
    data: setting,
  } = useAsync<ResourceRelationType[]>();
  const getSettingListHandle = () => {
    getSettingList(client(GET_FRONT_RESOURCES));
  };
  const { run: getSettingListDebounce } = useDebounceFn(getSettingListHandle, {
    wait: 200,
  });

  const reload = getSettingListHandle;

  const {
    run: getUserList,
    isLoading: gettingUserList,
    data: userList,
  } = useAsync<UserType[]>();
  const getUserListFunc = () => {
    getUserList(client(GET_EMPLOYEE_TYPE));
  };
  const { run: getUserListDebounce } = useDebounceFn(getUserListFunc, {
    wait: 200,
  });

  const {
    run: getPackageList,
    isLoading: gettingPackageList,
    data: packageList,
  } = useAsync<PackageType[]>();
  const getPackageListFunc = () => {
    getPackageList(client(GET_PACKAGE_LIST));
  };
  const { run: getPackageListDebounce } = useDebounceFn(getPackageListFunc, {
    wait: 200,
  });

  const loading =
    isIdle || gettingSettingList || gettingUserList || gettingPackageList;

  useMount(() => {
    getSettingListDebounce();
    getUserListDebounce();
    getPackageListDebounce();
  });

  const SettingDescItem = (item: ResourceRelationType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`frontPermission${key}`}>
        <Descriptions.Item label={title("编号")} className={className()}>
          {key + 1}
        </Descriptions.Item>
        <Descriptions.Item label={title("权限体")} className={className()}>
          {userList?.find((i) => i.id === item?.user_type_id)?.name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("权限详情")} className={className()}>
          {item.resourceIds
            ?.map(
              (i) => packageList?.find((t) => i === t.packageId)?.packageName,
            )
            .join("、") || ""}
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Spin spinning={loading}>
      <Descriptions
        column={3}
        bordered={setting?.length ? true : false}
        title="一线权限资源"
        layout="vertical"
        extra={
          <Space>
            <Button
              loading={editSettingVisible}
              icon={<EditOutlined />}
              onClick={() => setEditSettingVisible(true)}
            >
              编辑
            </Button>
            <Button
              loading={loading}
              icon={<RedoOutlined />}
              type={"primary"}
              onClick={reload}
            >
              刷新
            </Button>
          </Space>
        }
      >
        {setting
          ?.sort((a, b) => a.user_type_id - b.user_type_id)
          ?.map((item, index) => SettingDescItem(item, index))}
      </Descriptions>
      <FrontPermissionSettingEdit
        setting={setting}
        visible={editSettingVisible}
        setVisible={setEditSettingVisible}
        reload={reload}
      />
    </Spin>
  );
};

export default FrontPermissionSetting;
