import {
  SetStateAction,
  Dispatch,
  useEffect,
  useState,
  useReducer,
  Fragment,
} from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  NoticeBar,
  Picker,
  Popup,
  Result,
  Skeleton,
  Stepper,
  Toast,
} from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import { PickerColumn, PickerValue } from "antd-mobile/es/components/picker";
// import dayjs from "dayjs";
import dayjs from "dayjs";

import { useHttp } from "utils/http";
import {
  DisposeForm,
  formatDate,
  isNumber,
  productIdToName,
  THID,
} from "utils";
import { HospProductsListType } from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";
import { ADD_RATE_MANUALLY, GET_RATE } from "lib/api";
import { useAsync } from "utils/hooks/useAsync";
import {
  DateStep,
  RateType,
} from "authenticated-app/pc/customer/rate-detail/interface";
import { FormItemDiv } from "authenticated-app/mobile/components/FormItem";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { useDebounceFn } from "ahooks";

const now = new Date();

const datePrefix = "start";
const predictPrefix = "predict_num";
const usvPrefix = "usv_num";

const thPredictPrefix = "thPredict_num";
const rc2Prefix = "rc2_num";
const accessPrefix = "access_num";
const downloadPrefix = "download_num";

export const MobileRateImportManually = ({
  hospID,
  hospName,
  productList,
  defaultProduct,
  visible,
  setVisible,
  reload,
}: {
  hospID: string | number | undefined;
  hospName: string;
  productList: HospProductsListType[] | null;
  defaultProduct: string | null;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();

  const [form] = Form.useForm();
  const { resetFields, getFieldValue } = form;

  const [product, setProduct] = useState<string | null>(null);
  const [isTh, setIsTh] = useState<boolean>(false);

  const [finalTime, setFinalTime] = useState<dayjs.Dayjs>(
    dayjs().subtract(1, "days"),
  );
  const [datePickerVisible, setDatePickerVisible] = useState<boolean>();

  const [productPickerColumns, setProductPickerColumns] = useState<
    PickerColumn[]
  >([]);
  const [productPickerValue, setProductPickerValue] = useState<PickerValue[]>(
    [],
  );
  const [productPickerVisible, setProductPickerVisible] =
    useState<boolean>(false);

  const [isSubSuccess, setIsSubSuccess] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);

  const [, notifyRender] = useReducer((x) => x + 1, 0);

  const { run: postRate, isLoading: posttingRate } = useAsync();

  const {
    run: getHospRate,
    isLoading: gettingHospRate,
    data: hospRate,
  } = useAsync<RateType>();
  const getHospRateHandle = ({ product_line }: { product_line?: string }) => {
    if (hospID) {
      getHospRate(
        client(GET_RATE, {
          data: {
            hospital_id: hospID,
            product_line: product_line || product,
            unit: DateStep.day,
            start:
              formatDate(finalTime.clone().subtract(13, "days")) ||
              formatDate(dayjs().subtract(13, "days")),
            end:
              formatDate(finalTime) || formatDate(dayjs().subtract(1, "days")),
          },
        }),
      ).then(() => {
        setLoadingForm(true);
        setTimeout(() => {
          initForm();
          setLoadingForm(false);
        }, 500);
      });
    } else {
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法请求当前医院数据" />,
      });
    }
  };
  const { run: getHospRateHandleDebounce } = useDebounceFn(getHospRateHandle, {
    wait: 200,
  });

  let dataSource = [];
  for (let i = 0; i < 14; i++) {
    dataSource.push({ key: String(i) });
  }

  const submit = (value: any) => {
    let data = [];
    try {
      for (let i = 0; i < 14; i++) {
        if (isTh) {
          if (
            isNumber(value[`${thPredictPrefix}-${i}`]) &&
            isNumber(value[`${rc2Prefix}-${i}`]) &&
            isNumber(value[`${accessPrefix}-${i}`]) &&
            isNumber(value[`${downloadPrefix}-${i}`])
          ) {
            data.push({
              start: formatDate(value[`${datePrefix}-${i}`]),
              predict_num: value[`${thPredictPrefix}-${i}`],
              rc2_num: value[`${rc2Prefix}-${i}`],
              access_num: value[`${accessPrefix}-${i}`],
              download_num: value[`${downloadPrefix}-${i}`],
            });
          }
        } else {
          if (
            isNumber(value[`${predictPrefix}-${i}`]) &&
            isNumber(value[`${usvPrefix}-${i}`])
          ) {
            data.push({
              start: formatDate(value[`${datePrefix}-${i}`]),
              predict_num: value[`${predictPrefix}-${i}`],
              usv_num: value[`${usvPrefix}-${i}`],
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法提交，导入数据格式化失败" />,
      });
      throw new Error("导入数据格式化失败");
    }
    if (hospID) {
      postRate(
        client(ADD_RATE_MANUALLY, {
          data: {
            product_line: product,
            hospital_id: hospID,
            click_rate: data,
          },
          method: "POST",
        }),
      ).then(() => setIsSubSuccess(true));
    } else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法提交" />,
      });
  };

  const initForm = () => {
    resetFields();
    notifyRender();
  };

  useEffect(() => {
    if (product === THID) {
      setIsTh(true);
    } else {
      setIsTh(false);
    }

    if (product) {
      getHospRateHandleDebounce({ product_line: product });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (productList && productList?.length > 0) {
      let tmpColumns: PickerColumn = [];
      productList.forEach((i) => {
        tmpColumns.push({
          label: `${i.product_id}`,
          value: `${i.product_id}`,
        });
      });
      setProductPickerColumns([tmpColumns]);
    }
  }, [productList]);

  useEffect(() => {
    if (product) {
      getHospRateHandleDebounce({ product_line: product });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalTime]);

  useEffect(() => {
    if (visible) {
      resetFields();
      if (defaultProduct === THID) {
        setIsTh(true);
      } else {
        setIsTh(false);
      }
      setProduct(defaultProduct);
      setProductPickerValue([defaultProduct]);
      setIsSubSuccess(false);
      getHospRateHandleDebounce({ product_line: defaultProduct || undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const loading = gettingHospRate || loadingForm;

  return (
    <Popup
      visible={visible}
      destroyOnClose
      bodyStyle={{ height: "80vh", overflowY: "scroll" }}
      onMaskClick={() => setVisible(false)}
      showCloseButton
      onClose={() => setVisible(false)}
    >
      {isSubSuccess ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Result
            status="success"
            title="提交成功"
            description={`${hospName}: ${formatDate(
              finalTime.clone().subtract(13, "days"),
            )}至${formatDate(finalTime)}日期段《${
              productIdToName.get(product || "unknown") || "未知产品线"
            }产品》点击率`}
          />
          <div style={{ position: "absolute", bottom: 20, width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                color="primary"
                fill="outline"
                onClick={() => {
                  getHospRateHandleDebounce({});
                  setIsSubSuccess(false);
                }}
                style={{ width: "100%", margin: "0 20px 0 10px" }}
              >
                再次填写
              </Button>
              <Button
                color="primary"
                fill="solid"
                onClick={() => {
                  reload();
                  setVisible(false);
                }}
                style={{ width: "100%", margin: "0 20px 0 10px" }}
              >
                返回详情
              </Button>
            </div>
          </div>
        </div>
      ) : loading ? (
        <>
          <Skeleton.Title animated style={{ margin: "20px 20px 0 20px" }} />
          <Skeleton.Paragraph
            lineCount={5}
            animated
            style={{ margin: "20px 20px 0 20px" }}
          />
        </>
      ) : (
        <Form
          form={form}
          onFinish={submit}
          layout="horizontal"
          footer={
            <Button
              block
              type="submit"
              color="primary"
              size="large"
              loading={posttingRate}
              disabled={posttingRate}
            >
              提交
            </Button>
          }
        >
          <NoticeBar
            content="友情提示：请先确定导入的“最终日期”再填写数据，变更日期会重置数据。导入日期段为最终日期前14天。"
            color="alert"
          />
          <Form.Header>选择导入产品线</Form.Header>
          <Form.Item
            name="product"
            label={"导入产品线"}
            arrow={
              <Button
                fill="none"
                style={{ height: "100%", padding: 0 }}
                loading={productPickerVisible}
              >
                <RightOutline style={{ color: "var(--adm-color-light)" }} />
              </Button>
            }
            onClick={() => {
              setProductPickerVisible(true);
            }}
          >
            <FormItemDiv>
              {productList?.find((i) => i.product_id === product)?.product_id ||
                "未知"}
            </FormItemDiv>
          </Form.Item>
          <Form.Header>选择最终日期</Form.Header>
          <Form.Item
            name="start"
            label={"最终日期"}
            arrow={
              <Button
                fill="none"
                style={{ height: "100%", padding: 0 }}
                loading={datePickerVisible}
              >
                <RightOutline style={{ color: "var(--adm-color-light)" }} />
              </Button>
            }
            onClick={() => {
              setDatePickerVisible(true);
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
            >
              {formatDate(finalTime) || "未知"}
            </div>
          </Form.Item>
          {dataSource.map((item, index) => {
            if (isTh) {
              return (
                <Fragment key={item.key}>
                  <Form.Header>
                    {formatDate(finalTime.clone().subtract(index, "days"))}
                  </Form.Header>
                  <Form.Item
                    label="日期"
                    childElementPosition="right"
                    name={`${datePrefix}-${index}`}
                    normalize={(v) => DisposeForm(v)}
                    initialValue={formatDate(
                      finalTime.clone().subtract(index, "days"),
                    )}
                  >
                    <Input style={{ width: "10ch" }} readOnly />
                  </Form.Item>
                  <Form.Item
                    label="病例数"
                    childElementPosition="right"
                    name={`${thPredictPrefix}-${index}`}
                    normalize={(v) => DisposeForm(v)}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            value !== "" &&
                            value < getFieldValue(`${rc2Prefix}-${index}`)
                          ) {
                            return Promise.reject(
                              new Error("病例数应大于重建数"),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    initialValue={
                      hospRate?.results.find(
                        (i) =>
                          i.date[0] ===
                          formatDate(finalTime.clone().subtract(index, "days")),
                      )?.predict_num
                    }
                  >
                    <Stepper min={0} onChange={() => notifyRender()} />
                  </Form.Item>
                  <Form.Item
                    label="重建数"
                    childElementPosition="right"
                    name={`${rc2Prefix}-${index}`}
                    normalize={(v) => DisposeForm(v)}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            value !== "" &&
                            value > getFieldValue(`${thPredictPrefix}-${index}`)
                          ) {
                            return Promise.reject(
                              new Error("重建数不能大于病例数"),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    initialValue={
                      hospRate?.results.find(
                        (i) =>
                          i.date[0] ===
                          formatDate(finalTime.clone().subtract(index, "days")),
                      )?.rc2_num
                    }
                  >
                    <Stepper min={0} onChange={() => notifyRender()} />
                  </Form.Item>
                  <Form.Item
                    label="访问数"
                    childElementPosition="right"
                    name={`${accessPrefix}-${index}`}
                    normalize={(v) => DisposeForm(v)}
                    initialValue={
                      hospRate?.results.find(
                        (i) =>
                          i.date[0] ===
                          formatDate(finalTime.clone().subtract(index, "days")),
                      )?.access_num
                    }
                  >
                    <Stepper min={0} onChange={() => notifyRender()} />
                  </Form.Item>
                  <Form.Item
                    label="报告下载数"
                    childElementPosition="right"
                    name={`${downloadPrefix}-${index}`}
                    normalize={(v) => DisposeForm(v)}
                    initialValue={
                      hospRate?.results.find(
                        (i) =>
                          i.date[0] ===
                          formatDate(finalTime.clone().subtract(index, "days")),
                      )?.download_num
                    }
                  >
                    <Stepper min={0} onChange={() => notifyRender()} />
                  </Form.Item>
                </Fragment>
              );
            } else {
              return (
                <Fragment key={item.key}>
                  <Form.Header>
                    {formatDate(finalTime.clone().subtract(index, "days"))}
                  </Form.Header>
                  <Form.Item
                    label="日期"
                    childElementPosition="right"
                    name={`${datePrefix}-${index}`}
                    normalize={(v) => DisposeForm(v)}
                    initialValue={formatDate(
                      finalTime.clone().subtract(index, "days"),
                    )}
                  >
                    <Input style={{ width: "10ch" }} readOnly />
                  </Form.Item>
                  <Form.Item
                    label="预测量"
                    childElementPosition="right"
                    name={`${predictPrefix}-${index}`}
                    normalize={(v) => DisposeForm(v)}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            value !== "" &&
                            value < getFieldValue(`${usvPrefix}-${index}`)
                          ) {
                            return Promise.reject(
                              new Error("点击率不能大于100%"),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    initialValue={
                      hospRate?.results.find(
                        (i) =>
                          i.date[0] ===
                          formatDate(finalTime.clone().subtract(index, "days")),
                      )?.predict_num
                    }
                  >
                    <Stepper min={0} onChange={() => notifyRender()} />
                  </Form.Item>
                  <Form.Item
                    label="点击量(USV)"
                    childElementPosition="right"
                    name={`${usvPrefix}-${index}`}
                    normalize={(v) => DisposeForm(v)}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            value !== "" &&
                            value > getFieldValue(`${predictPrefix}-${index}`)
                          ) {
                            return Promise.reject(
                              new Error("点击率不能大于100%"),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    initialValue={
                      hospRate?.results.find(
                        (i) =>
                          i.date[0] ===
                          formatDate(finalTime.clone().subtract(index, "days")),
                      )?.usv_num
                    }
                  >
                    <Stepper min={0} onChange={() => notifyRender()} />
                  </Form.Item>
                  <Form.Item label="点击率" childElementPosition="right">
                    {
                      <span key={`rate-${index}}`}>
                        {(
                          getFieldValue(`${predictPrefix}-${index}`) ||
                          hospRate?.results.find(
                            (i) =>
                              i.date[0] ===
                              formatDate(
                                finalTime.clone().subtract(index, "days"),
                              ),
                          )?.predict_num
                            ? (
                                ((getFieldValue(`${usvPrefix}-${index}`) ||
                                  hospRate?.results.find(
                                    (i) =>
                                      i.date[0] ===
                                      formatDate(
                                        finalTime
                                          .clone()
                                          .subtract(index, "days"),
                                      ),
                                  )?.usv_num ||
                                  0) /
                                  (getFieldValue(`${predictPrefix}-${index}`) ||
                                    hospRate?.results.find(
                                      (i) =>
                                        i.date[0] ===
                                        formatDate(
                                          finalTime
                                            .clone()
                                            .subtract(index, "days"),
                                        ),
                                    )?.predict_num ||
                                    0)) *
                                100
                              ).toFixed(2)
                            : null
                        )
                          ? (getFieldValue(`${predictPrefix}-${index}`) ||
                            hospRate?.results.find(
                              (i) =>
                                i.date[0] ===
                                formatDate(
                                  finalTime.clone().subtract(index, "days"),
                                ),
                            )?.predict_num
                              ? (
                                  ((getFieldValue(`${usvPrefix}-${index}`) ||
                                    hospRate?.results.find(
                                      (i) =>
                                        i.date[0] ===
                                        formatDate(
                                          finalTime
                                            .clone()
                                            .subtract(index, "days"),
                                        ),
                                    )?.usv_num ||
                                    0) /
                                    (getFieldValue(
                                      `${predictPrefix}-${index}`,
                                    ) ||
                                      hospRate?.results.find(
                                        (i) =>
                                          i.date[0] ===
                                          formatDate(
                                            finalTime
                                              .clone()
                                              .subtract(index, "days"),
                                          ),
                                      )?.predict_num ||
                                      0)) *
                                  100
                                ).toFixed(2)
                              : null) + "%"
                          : hospRate?.results.find(
                              (i) =>
                                i.date[0] ===
                                formatDate(
                                  finalTime.clone().subtract(index, "days"),
                                ),
                            )?.click_rate}
                      </span>
                    }
                  </Form.Item>
                </Fragment>
              );
            }
          })}
        </Form>
      )}
      <Picker
        title={"选择产品线"}
        closeOnMaskClick={false}
        columns={productPickerColumns}
        visible={productPickerVisible}
        onClose={() => {
          setProductPickerVisible(false);
        }}
        value={productPickerValue}
        onConfirm={(v: PickerValue[]) => {
          setProductPickerValue(v);
          setProduct(v[0] as string);
          getHospRateHandleDebounce({
            product_line: (v[0] as string) || undefined,
          });
        }}
      />
      <DatePicker
        closeOnMaskClick={false}
        title={"选择最终日期"}
        visible={datePickerVisible}
        value={finalTime.toDate()}
        onClose={() => {
          setDatePickerVisible(false);
        }}
        max={now}
        onConfirm={(v) => {
          setFinalTime(dayjs(v));
        }}
        tillNow
      />
    </Popup>
  );
};
