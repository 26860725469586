import { PieCharts } from "./TopPieCharts";
import { Table } from "./MiddleTable";
import { IssuesStatsTable } from "./IssuesStatsTable";
import { BarCharts } from "./BottomBarChats";

export const BreakFeedbackStats = () => {
  return (
    <>
      <PieCharts />
      <BarCharts />
      <Table />
    </>
  );
};

export const IssuesStats = () => {
  return (
    <>
      <IssuesStatsTable />
    </>
  );
};
