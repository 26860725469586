import { useState } from "react";
import { Descriptions, Timeline, Collapse, Divider, Space, Button } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { FormatTime } from "utils";
import { DELIVERY_DETAIL_TYPE } from "lib/interface/launchChangeList";
import { EditOutlined } from "@ant-design/icons";
import { UpdateRelationOrderListSelect } from "../components/UpdateRelationOrderListSelect";
import { GET_HOSPITAL_ORDER } from "lib/api/deliverylist";

const { Item } = Descriptions;

export const BreakDetailRight = ({
  onUpdate,
  detailInfo,
  isCanChange,
}: {
  onUpdate: (value: any) => void;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
  isCanChange: boolean;
}) => {
  const [isOrderEditMode, setIsOrderEditMode] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [openOrderRelationCollapse, setOpenOrderRelationCollapse] = useState<
    string[]
  >([]);
  const childinfo = () => {
    switch (detailInfo?.delivery_type_name) {
      case "销售交付":
      case "试用交付":
      case "渠道交付":
        return (
          <>
            <Collapse
              className="break-detail-collapse"
              ghost
              items={[
                {
                  key: "1",
                  label: "物料退回",
                  children: (
                    <Descriptions size="small" column={1}>
                      <Item label={"是否需要退回硬件"}>
                        {detailInfo?.is_need_send_back === null
                          ? ""
                          : detailInfo?.is_need_send_back
                          ? "是"
                          : "否"}
                      </Item>
                      <Item label={"计划退回日期"}>
                        {detailInfo?.planned_return_date}
                      </Item>
                      <Item label={"实际退回日期"}>
                        {detailInfo?.actual_return_date}
                      </Item>
                      <Item label={"退回物流单号"}>
                        {detailInfo?.return_tracking_number}
                      </Item>
                    </Descriptions>
                  ),
                },
              ]}
            />
            <Divider className="no-margin-divider" />
          </>
        );
    }
  };

  return (
    <div style={{ flex: 1, height: "100%" }}>
      <Collapse
        className="break-detail-collapse"
        defaultActiveKey={["1"]}
        ghost
        items={[
          {
            key: "1",
            label: "工单状态",
            children: (
              <Descriptions size="small" column={1}>
                <Item label={"流程状态"}>{detailInfo?.process_name}</Item>
                <Item label={"交付负责人"}>
                  {detailInfo?.delivery_user_name}
                </Item>
                <Item label={"工单创建人"}>{detailInfo?.create_user}</Item>
                <Item label={"创建时间"}>
                  {FormatTime(detailInfo?.create_time)}
                </Item>
              </Descriptions>
            ),
          },
        ]}
      />
      <Divider className="no-margin-divider" />
      <Collapse
        className="break-detail-collapse"
        defaultActiveKey={["1"]}
        ghost
        items={[
          {
            key: "1",
            label: "客户信息",
            children: (
              <div>
                <Descriptions size="small" column={2}>
                  <Item label={"区域"}>
                    {detailInfo?.hospital_info?.area_name}
                  </Item>
                  <Item label={"省份"}>
                    {detailInfo?.hospital_info?.province_name}
                  </Item>
                  <Item label={"售后"}>
                    {detailInfo?.hospital_info?.execute_user_name}
                  </Item>
                  <Item label={"运营"}>
                    {detailInfo?.hospital_info?.operate_user_name}
                  </Item>
                  <Item label={"销售"}>
                    {detailInfo?.hospital_info?.sales_user_name}
                  </Item>
                  <Item label={"售前"}>
                    {detailInfo?.hospital_info?.pre_sales_user_name}
                  </Item>
                </Descriptions>
                <Descriptions size="small" column={1}>
                  <Item label={"已上线"}>
                    {detailInfo?.hospital_info?.product_info
                      ?.map(
                        (i) =>
                          `${i.product_chinese_name} ${i.product_version_name}`,
                      )
                      .join("、")}
                  </Item>
                </Descriptions>
              </div>
            ),
          },
        ]}
      />
      {childinfo()}
      <Divider className="no-margin-divider" />
      <Collapse
        className="break-detail-collapse"
        activeKey={openOrderRelationCollapse}
        onChange={(v: any) => {
          setOpenOrderRelationCollapse(v);
        }}
        ghost
        items={[
          {
            key: "1",
            label: "关联工单",
            extra: (
              <>
                {isCanChange && openOrderRelationCollapse.length !== 0 ? (
                  <Space wrap>
                    {isOrderEditMode ? (
                      <>
                        <Button
                          type="text"
                          icon={
                            <CheckOutlined
                              style={{ color: "var(--adm-color-primary)" }}
                            />
                          }
                          onClick={(event) => {
                            if (isCanChange) {
                              setIsSubmit(true);
                            }
                            event.stopPropagation();
                          }}
                        />
                        <Button
                          type="text"
                          icon={<CloseOutlined />}
                          onClick={(event) => {
                            setIsOrderEditMode(false);
                            event.stopPropagation();
                          }}
                        />
                      </>
                    ) : (
                      <Button
                        icon={
                          <EditOutlined
                            style={{ color: "var(--adm-color-primary)" }}
                          />
                        }
                        disabled={!!!isCanChange}
                        type="text"
                        onClick={(event) => {
                          if (isCanChange) {
                            setOpenOrderRelationCollapse(["1"]);
                            setIsOrderEditMode(true);
                          }
                          event.stopPropagation();
                        }}
                      />
                    )}
                  </Space>
                ) : (
                  <></>
                )}
              </>
            ),
            children: (
              <UpdateRelationOrderListSelect
                onUpdate={onUpdate}
                defaultValue={detailInfo?.relation_order_list}
                detailInfo={detailInfo}
                label={""}
                name={"issues_id_list"}
                options={{ value: "id", label: "title" }}
                api={
                  detailInfo?.hospital_id
                    ? GET_HOSPITAL_ORDER(detailInfo?.hospital_id)
                    : ""
                }
                isCanChange={isOrderEditMode}
                setEditMode={setIsOrderEditMode}
                setSubmit={setIsSubmit}
                submit={isSubmit}
              />
            ),
          },
        ]}
      />
      <Collapse
        className="break-detail-collapse"
        ghost
        items={[
          {
            key: "1",
            label: "变更记录",
            children: (
              <Timeline
                style={{
                  paddingTop: "12px",
                  maxHeight: "400px",
                  overflow: "auto",
                }}
                items={detailInfo?.operation_record_list?.map((i) => ({
                  children: (
                    <Space direction="vertical">
                      <span>{FormatTime(i.create_time)}</span>
                      <Space>
                        <span>{i.update_user}</span>
                        <span>{i.process_trans_name}</span>
                        <span>{i.to_user} </span>
                      </Space>
                    </Space>
                  ),
                }))}
              />
            ),
          },
        ]}
      />
      <Divider className="no-margin-divider" />
    </div>
  );
};
