import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
} from "react";
import { Divider, Spin } from "antd";
import { useDebounceFn } from "ahooks";

import { exeChildFunc } from "authenticated-app/pc/customer/customer-detail/interface";
import ServerBaseInfo from "./server-base-info";
import { useHttp } from "utils/http";
import { HardwaresType } from "./interface";
import {
  ADD_HARDWARE_RETAIN,
  DEL_HARDWARE_RETAIN,
  GET_HARDWARES_LIST,
  UPDATE_HARDWARE_RETAIN,
} from "lib/api";
import MatterInfo from "./matter-info";
import BaseRetainInfo from "../infoTabPane/base-retain-info";
import { useAsync } from "utils/hooks/useAsync";

import "../../index.css";

const HardwareTabPane = forwardRef<
  exeChildFunc,
  {
    hospID: number | null;
    setDisChange: Dispatch<SetStateAction<boolean>>;
    changeCount: any;
  }
>(({ hospID, setDisChange, changeCount }, ref) => {
  const client = useHttp();

  const { run, isLoading, data: detail } = useAsync<HardwaresType>();
  const getHardwareDetail = () => {
    if (hospID) {
      run(client(GET_HARDWARES_LIST(hospID)));
    }
  };
  const { run: getHardwareDetailDebounce } = useDebounceFn(getHardwareDetail, {
    wait: 200,
  });

  useImperativeHandle(ref, () => ({
    exeFunc() {
      getHardwareDetail();
    },
  }));

  useEffect(() => {
    getHardwareDetailDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospID]);

  return (
    <Spin spinning={isLoading}>
      <ServerBaseInfo
        hospID={hospID}
        detail={detail?.hardware_list}
        reload={getHardwareDetail}
        setDisChange={setDisChange}
        changeCount={changeCount}
      />
      <Divider />
      <MatterInfo
        matterInfo={detail?.other_infos}
        hospID={hospID}
        reload={getHardwareDetail}
        setDisChange={setDisChange}
        changeCount={changeCount}
      />
      <Divider />
      <BaseRetainInfo
        retain={detail?.retain_infos}
        orgID={hospID}
        RetainAPI={{
          ADD_RETAIN: ADD_HARDWARE_RETAIN,
          UPDATE_RETAIN: UPDATE_HARDWARE_RETAIN,
          DEL_RETAIN: DEL_HARDWARE_RETAIN,
        }}
        reload={getHardwareDetail}
      />
    </Spin>
  );
});

export default HardwareTabPane;
