import { List } from "antd-mobile";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { CHECKLIST_VALUE_TYPE } from "lib/interface/checklist";

export const SharedWorkList = ({
  detail,
  index,
  loading,
}: {
  detail: CHECKLIST_VALUE_TYPE;
  index: number;
  loading: boolean;
}) => {
  const { check_item, description, value } = detail || {};

  return (
    <>
      <List
        mode="card"
        header={<ListHeader text={`第${index}项`} loading={loading} />}
      >
        <List.Item extra={check_item || "无"}>检查项</List.Item>
        <List.Item extra={description || "无"}>参考指标</List.Item>
        <List.Item extra={value || "无"}>结果</List.Item>
      </List>
    </>
  );
};
