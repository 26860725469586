import { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TitleComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
} from "echarts/components";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { message } from "antd";

type EChartsOption = echarts.ComposeOption<
  | TitleComponentOption
  | ToolboxComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | LineSeriesOption
>;

export interface MyLineChartType {
  xData: string[];
  series: LineSeriesOption[];
}

export const MyLineChart = ({
  Data,
  Title,
}: {
  Data: MyLineChartType | undefined;
  Title?: string;
}) => {
  echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
  ]);

  const [option, setOption] = useState<EChartsOption>({});

  const getOption = (): EChartsOption => ({
    title: {
      text: Title,
    },
    tooltip: {
      trigger: "axis",
    },
    legend: {},
    grid: {
      left: "0%",
      right: "2%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: Data?.xData,
    },
    yAxis: {
      type: "value",
    },
    series: Data?.series,
  });

  const chartRef = useRef(null);
  let myChart: echarts.ECharts;

  const renderChart = (option: EChartsOption) => {
    try {
      const renderedInstance = echarts.getInstanceByDom(
        chartRef.current as unknown as HTMLElement,
      );
      if (renderedInstance) {
        myChart = renderedInstance;
      } else {
        myChart = echarts.init(chartRef.current as unknown as HTMLElement);
      }
      myChart.setOption(option);
    } catch (error) {
      message.error("初始化图表失败！");
      console.error(error);
      myChart && myChart.dispose();
    }
  };

  const resizeHandler = () => {
    myChart.resize();
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    renderChart(option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  useEffect(() => {
    setOption(getOption());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  return <div style={{ height: "100%", width: "100%" }} ref={chartRef}></div>;
};
