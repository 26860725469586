import { useState, useRef, useEffect } from "react";
import type { BaseSelectRef } from "rc-select";
import { Form, Cascader, Spin } from "antd";
import { useDebounceFn } from "ahooks";

import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { BREAK_INFLUENCE_PRODUCT_LIST_TYPE } from "lib/interface/breaklist";
import {
  ProductInfoType,
  ProductType,
  ProductVersionType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import {
  ADD_FILE,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_LIST,
  HOSP_LIST_API,
  GET_PRODUCT_VERSION_BY_ID,
  GET_PRODUCT_DETAIL_HAS_NOTONLINE,
} from "lib/api";

import "./index.css";

export const UpdateMultipleCascadeSelect = ({
  onUpdate,
  defaultValue,
  label,
  name,
  optionss,
  required,
  api,
  isCanChange,
  hosptialId,
}: {
  onUpdate: (value: any) => void;
  defaultValue?: BREAK_INFLUENCE_PRODUCT_LIST_TYPE[];
  label: string;
  name: string;
  optionss: { label: string; value: string };
  required?: boolean;
  api: string;
  isCanChange?: boolean;
  hosptialId: string | number;
}) => {
  const [form] = Form.useForm();

  const client = useHttp();

  const [isEditting, setIsEditting] = useState(false);
  const [isFoucs, setIsFoucs] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const ref = useRef<BaseSelectRef>(null);

  const onFinish = (value: any) => {
    if (isChanged) {
      onUpdate(value);
      setIsChanged(false);
    }
  };

  interface Options {
    value: string;
    label: string;
    disabled?: boolean;
    children?: Options[];
    isLeaf?: boolean;
  }

  // 请求产品接口
  const fetchFirstLevelData = async (hospID: number | string) => {
    try {
      let firstLevelData: any[] = [];
      let formattedData: any[] = [];
      if (hospID === "all") {
        firstLevelData = await client(GET_PRODUCT_LIST);
      } else {
        firstLevelData = await client(GET_PRODUCT_DETAIL_HAS_NOTONLINE(hospID));
      }
      formattedData = await fetchSecondLevelData(firstLevelData);
      // 构建默认选中项
      if (defaultValue) {
        setSelectedValues(
          defaultValue.map((item: any) => [
            item.title,
            // `${item.chinese_name} ${item.product_version_name}`,
            item.product_version_id || "",
          ]),
        );
      } else {
        setSelectedValues([]);
      }
      return formattedData;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  // 请求产品版本接口
  const fetchSecondLevelData = async (firstLevelData: any[]) => {
    const formattedData: Options[] = [];
    for (const item of firstLevelData) {
      const current_product_version_id = item.product_version_id;
      const product: Options = {
        value: item.product_id || item.title,
        label:
          item.product_chinese_name || item.chinese_name || item.title || "",
        children: [],
      };
      const secondLevelData: any[] = await client(
        GET_PRODUCT_VERSION_BY_ID(item.product_id || item.title),
      );
      let fetch_ids: string[] = [];
      if (secondLevelData.length) {
        for (const version of secondLevelData) {
          if (product.children) {
            version.id.toString() === `${current_product_version_id}`
              ? product.children.unshift({
                  value: version.id.toString(),
                  label: `${
                    item.product_chinese_name ||
                    item.chinese_name ||
                    item.title ||
                    ""
                  } ${version.name}`,
                })
              : product.children.push({
                  value: version.id.toString(),
                  label: `${
                    item.product_chinese_name ||
                    item.chinese_name ||
                    item.title ||
                    ""
                  } ${version.name}`,
                });
          }
          fetch_ids.push(version.id.toString());
        }
      } else {
        if (product.children) {
          product.children.push({
            value: "",
            label: "无版本",
          });
        }
        fetch_ids.push("");
      }
      // 处理医院有产品线和产品版本, 但是对应产品线列表内没有对应版本的情况(例如,当前是历史版本,系统中已经删除了这个版本, 要允许选择.)
      if (item.product_version_id) {
        const include_current_version = fetch_ids.filter(
          (val: string) => val === `${item.product_version_id}`,
        );
        if (include_current_version.length === 0) {
          product.children?.push({
            value: `${item.product_version_id}`,
            label: `${item.product_chinese_name} ${item.product_version_name}`,
          });
        }
      }
      formattedData.push(product);
    }
    return formattedData;
  };

  const [selectedValues, setSelectedValues] = useState<any[]>([]);

  const { run, data, isLoading, isIdle } = useAsync<any>();
  const runHandle = () => {
    run(fetchFirstLevelData(hosptialId));
  };
  const { run: runDebounce } = useDebounceFn(runHandle, {
    wait: 200,
  });

  useEffect(() => {
    if (hosptialId && isCanChange) {
      runDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hosptialId, isCanChange]);

  // 选中值变化, 更新表单数据.
  useEffect(() => {
    form.setFieldsValue({
      product_id_list: selectedValues,
    });
  }, [selectedValues]);

  const loading = isLoading || isIdle;

  return (
    <Form
      form={form}
      className="update-multiple-select-form"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label={label}
        name={name}
        rules={[{ required: required, message: `必须选择${label}` }]}
        style={{ width: "100%" }}
      >
        {isCanChange ? (
          <Spin spinning={loading}>
            <Cascader
              loading={loading}
              placeholder={`选择${label}`}
              showSearch
              value={selectedValues}
              style={{ width: "100%" }}
              options={data}
              onBlur={() => {
                setIsFoucs(false);
                setIsEditting(false);
                form.submit();
              }}
              onMouseEnter={() => setIsEditting(true)}
              onMouseLeave={() => {
                if (isFoucs) return;
                setIsEditting(false);
              }}
              onChange={(v, item) => {
                const resultMap = new Map();
                v.forEach(([key, value]) => {
                  if (key === "NotOnline") {
                    resultMap.set(key, [key, ""]);
                  } else {
                    if (value === undefined) {
                      // 如果没选择子版本,从当前产品线中选择最后一个(最新版本)填入.
                      let children_id = [""];
                      item.map((i: any) => {
                        children_id = i.map((x: any) => {
                          if (x.value === key) {
                            return x.children[x.children.length - 1].value;
                          }
                        });
                      });
                      resultMap.set(key, [
                        key,
                        children_id[0] ? children_id[0] : "",
                      ]);
                    } else {
                      resultMap.set(key, [key, value]);
                    }
                  }
                });
                const uniqueData = Array.from(resultMap.values());
                setSelectedValues(uniqueData);
                setIsChanged(true);
              }}
              multiple
            />
          </Spin>
        ) : (
          defaultValue
            ?.map(
              (i) =>
                `${i?.chinese_name} ${
                  i?.product_version_name ? i.product_version_name : ""
                } `,
            )
            .join("、")
        )}
      </Form.Item>
    </Form>
  );
};
