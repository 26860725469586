import { Fragment, useEffect, useRef, useState } from "react";
import { Badge, Button, Tooltip } from "antd";
import type { ProColumns } from "@ant-design/pro-components";
import { ActionType, ProTable, FormInstance } from "@ant-design/pro-components";
import { ShareAltOutlined, PlusOutlined } from "@ant-design/icons";

import { ChecklistStatusColor, FormatTime, useMount } from "utils";
import {
  CHECKLIST_ENUM,
  CHECKLIST_ENUM_MAP,
  CHECKLIST_RESULT_TYPE,
  SHARED_USER_TYPE,
} from "lib/interface/checklist";
import { useHttp } from "utils/http";
import { GET_CHECKLIST } from "lib/api/checklist";
import { useChecklistDetail } from "authenticated-app/hooks/checklist/useChecklistDetail";
import { useChecklistAdd } from "authenticated-app/hooks/checklist/useChecklistAdd";
import { useShareChecklist } from "authenticated-app/hooks/checklist/useShareChecklist";
import { AddModal } from "../add";
import { CheckDetailModal } from "../detail";
import { ShareModal } from "../share";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { HosNodesType } from "authenticated-app/pc/customer/customer-list/interface";
import { useAsync } from "utils/hooks/useAsync";
import { GET_HOSP_NODES } from "lib/api";
import { useDebounceFn } from "ahooks";

export const ChecklistTable = () => {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<FormInstance>();

  const client = useHttp();

  const [, openChecklistDetail] = useChecklistDetail();
  const [checklistAddOpening, openChecklistAdd] = useChecklistAdd();
  const [shareChecklistOpening, openShareChecklist] = useShareChecklist();

  const [area, setArea] = useState<number>();
  const [areaListEnum, setAreaListEnum] = useState<{
    [key in number]: { text: string };
  }>({});
  const [provinceListEnum, setProvinceListEnum] = useState<{
    [key in number]: { text: string };
  }>({});

  const {
    run: getHosNodes,
    isLoading: gettingHosNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };
  const { run: getHosNodesDebounce } = useDebounceFn(getHosNodesHandle, {
    wait: 200,
  });

  const [{ detailId }] = useUrlQueryParam(["detailId"]);

  const [sharedList, setSharedList] = useState<SHARED_USER_TYPE[]>([]);
  const { height } = useWindowSize();

  const ChecklistColumns: ProColumns<CHECKLIST_RESULT_TYPE>[] = [
    {
      title: "客户名称",
      dataIndex: "hospital_name",
    },
    {
      title: "客户编码",
      search: false,
      dataIndex: "hospital_code",
    },
    {
      title: "区域",
      dataIndex: "area_name",
      valueType: "select",
      valueEnum: areaListEnum,
      fieldProps: {
        loading: gettingHosNodes,
        onChange: (i: number) => {
          setArea(i);
          formRef.current?.setFieldsValue({
            province_name: undefined,
          });
        },
      },
    },
    {
      title: "省份",
      dataIndex: "province_name",
      valueType: "select",
      valueEnum: provinceListEnum,
      fieldProps: {
        loading: gettingHosNodes,
        showSearch: true,
      },
    },
    {
      title: "城市",
      search: false,
      dataIndex: "city_name",
    },
    {
      title: "单据来源",
      search: false,
      dataIndex: "source_order",
    },
    {
      title: "经办人",
      dataIndex: "assignee_name",
    },
    {
      title: "状态",
      dataIndex: "is_submit",
      valueType: "select",
      valueEnum: {
        0: "待提交",
        1: "已完成",
      },
      width: 72,
      render: (_, record, __) => {
        return (
          <Badge
            key={record.id}
            color={ChecklistStatusColor.get(record.is_submit || 0)}
            text={record.is_submit === 1 ? "已完成" : "待提交"}
          />
        );
      },
    },
    {
      title: "类型",
      dataIndex: "check_type",
      valueType: "select",
      valueEnum: {
        [CHECKLIST_ENUM.online_list]: {
          text: CHECKLIST_ENUM_MAP.get(CHECKLIST_ENUM.online_list),
        },
        [CHECKLIST_ENUM.exit_list]: {
          text: CHECKLIST_ENUM_MAP.get(CHECKLIST_ENUM.exit_list),
        },
        [CHECKLIST_ENUM.except_list]: {
          text: CHECKLIST_ENUM_MAP.get(CHECKLIST_ENUM.except_list),
        },
        [CHECKLIST_ENUM.survey_list]: {
          text: CHECKLIST_ENUM_MAP.get(CHECKLIST_ENUM.survey_list),
        },
      },
      render: (_, record, __) => {
        return CHECKLIST_ENUM_MAP.get(record.check_type) || "未知";
      },
    },
    {
      title: "创建时间",
      search: false,
      dataIndex: "create_time",
      render: (_, record, __) => {
        return FormatTime(record.create_time);
      },
    },
    {
      title: "提交时间",
      search: false,
      dataIndex: "submit_time",
      render: (_, record, __) => {
        return FormatTime(record.submit_time);
      },
    },
    {
      title: "操作",
      align: "center",
      search: false,
      width: 32,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation(); // 阻止事件冒泡-屏蔽table row点击事件
        }, // 点击行
      }),
      render: (_, record, __) => {
        const { id, share_users, is_submit } = record;
        return (
          <Tooltip key={id} title={is_submit === 0 ? "请先提交" : "分享"}>
            <Button
              disabled={is_submit === 0}
              loading={shareChecklistOpening && detailId === id.toString()}
              icon={<ShareAltOutlined />}
              type="link"
              onClick={() => {
                setSharedList(share_users);
                openShareChecklist(id);
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    let enums: { [key in number]: { text: string } } = {};
    hosNodes?.forEach((i) => {
      if (i.nodeLevel === 1) {
        enums[i.nodeId] = { text: i.nodeName || "未知" };
      }
    });
    setAreaListEnum(enums);
  }, [hosNodes]);

  useEffect(() => {
    let enums: { [key in number]: { text: string } } = {};
    hosNodes?.forEach((i) => {
      if (i.parentNodeId === Number(area) && i.parentNodeId !== null) {
        enums[i.nodeId] = { text: i.nodeName || "未知" };
      }
    });
    setProvinceListEnum(enums);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  useMount(getHosNodesDebounce);

  return (
    <Fragment>
      <ProTable<CHECKLIST_RESULT_TYPE>
        style={{
          minWidth: 988,
        }}
        rowKey="id"
        actionRef={actionRef}
        formRef={formRef}
        cardBordered
        columns={ChecklistColumns}
        request={async (params) => {
          let queryParams = {
            ...params,
            area_id: params?.area_name,
            area_name: undefined,
            province_id: params?.province_name,
            province_name: undefined,
          };
          try {
            const res = await client(GET_CHECKLIST, {
              data: { ...queryParams },
            });
            return {
              data: res.results,
              total: res.count,
              success: true,
            };
          } catch (e) {
            return { success: false };
          }
        }}
        search={{
          labelWidth: "auto",
          searchText: "搜索",
          span: 6,
        }}
        pagination={{
          // defaultPageSize: 10,
          // pageSizeOptions: [10, 15, 20, 30, 50],
          showQuickJumper: true,
          showSizeChanger: false,
          pageSize:
            Math.trunc((height - 382) / 57) > 5
              ? Math.trunc((height - 382) / 57)
              : 5,
        }}
        onRow={(record) => ({
          onClick: () => openChecklistDetail(record.id),
        })}
        toolBarRender={() => [
          <Button
            loading={checklistAddOpening}
            icon={<PlusOutlined />}
            type="primary"
            onClick={openChecklistAdd}
          >
            创建
          </Button>,
        ]}
      />
      <CheckDetailModal actionRef={actionRef} />
      <AddModal actionRef={actionRef} />
      <ShareModal actionRef={actionRef} share_users={sharedList} />
    </Fragment>
  );
};
