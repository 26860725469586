import { useState, Fragment } from "react";
import { Button, Descriptions, Space, Spin } from "antd";
import { EditOutlined, RedoOutlined } from "@ant-design/icons";
import { useDebounceFn } from "ahooks";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import {
  GET_ISSUES_DEPARTMENT_LIST,
  UPDATE_ISSUES_DEPARTMENT_USERS,
} from "../api";
import { useMount } from "utils";
import { IssueDepartmentType } from "../interface";
import { DepartmentSettingEdit } from "./edit";

const DepartmentSetting = () => {
  const [editVisible, setEditVisible] = useState(false);
  const [edittingInfo, setEditingInfo] = useState<IssueDepartmentType>();

  const client = useHttp();

  const {
    run: getDepartmentList,
    isIdle: getDepartmentListIsIdle,
    isLoading: gettingDepartmentList,
    data: departmentList,
  } = useAsync<IssueDepartmentType[]>();
  const getDepartmentListHandle = () => {
    getDepartmentList(client(GET_ISSUES_DEPARTMENT_LIST));
  };
  const { run: getDepartmentListDebounce } = useDebounceFn(
    getDepartmentListHandle,
    { wait: 200 },
  );

  const reload = getDepartmentListHandle;

  const loading = getDepartmentListIsIdle || gettingDepartmentList;

  useMount(() => {
    getDepartmentListDebounce();
  });

  const DescItem = (item: IssueDepartmentType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`frontPermission${key}`}>
        <Descriptions.Item
          label={title("编号")}
          className={className()}
          style={{ width: "5rem" }}
        >
          {key + 1}
        </Descriptions.Item>
        <Descriptions.Item
          label={title("角色")}
          className={className()}
          style={{ width: "5rem" }}
        >
          {item.name || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("用户")} className={className()}>
          {item.users?.map((i) => i.user_name).join("、") || ""}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("操作")}
          className={className()}
        >
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              loading={editVisible && item.id === edittingInfo?.id}
              onClick={() => {
                setEditingInfo(item);
                setEditVisible(true);
              }}
            />
          </Space>
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Spin spinning={loading}>
      <Descriptions
        column={4}
        bordered={departmentList?.length ? true : false}
        title="工单角色配置"
        layout="vertical"
        extra={
          <Space>
            <Button
              loading={loading}
              icon={<RedoOutlined />}
              type={"primary"}
              onClick={reload}
            >
              刷新
            </Button>
          </Space>
        }
      >
        {departmentList
          ?.sort((a, b) => a.id - b.id)
          ?.map((item, index) => DescItem(item, index))}
      </Descriptions>
      <DepartmentSettingEdit
        title={edittingInfo?.name || ""}
        edittingInfo={edittingInfo}
        submitAPI={UPDATE_ISSUES_DEPARTMENT_USERS}
        visible={editVisible}
        setVisible={setEditVisible}
        reload={reload}
      />
    </Spin>
  );
};

export default DepartmentSetting;
