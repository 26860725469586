import { useState, Fragment, Dispatch, SetStateAction } from "react";
import { Button, Descriptions, Space, message, Popconfirm } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { PermissionResourcesAdd } from "./add";
import { PermissionResourcesType } from "../../interface";
import { PermissionResourcesEdit } from "./edit";

export const resourceActions = [
  { id: "create", name: "新增" },
  { id: "delete", name: "删除" },
  { id: "edit", name: "修改" },
  { id: "get", name: "查看" },
];

const PermissionResources = ({
  resources,
  update,
}: {
  resources: PermissionResourcesType[];
  update: Dispatch<SetStateAction<PermissionResourcesType[]>>;
}) => {
  // 必要信息state
  const [addSettingVisible, setAddSettingVisible] = useState(false);
  const [editSettingVisible, setEditSettingVisible] = useState(false);

  const [editSettingInfo, setEditSettingInfo] =
    useState<PermissionResourcesType>();

  const delSetting = (key: number) => {
    try {
      let tmp = resources.filter((i) => i.key !== key);
      update(tmp);
      message.success("删除成功");
    } catch (error) {
      console.error(error);
      message.error("无法进行删除，请稍后重试或联系管理员！");
    }
  };

  const updateList = (data: PermissionResourcesType) => {
    try {
      resources.forEach((v, i) => {
        if (v.key === data.key) {
          resources[i] = data;
        }
      });
      update(resources);
    } catch (e) {
      console.error(e);
      console.error(data);
      message.error("更新出错，请联系管理员！");
    }
  };

  const SettingDescItem = (item: PermissionResourcesType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`gpuSetting${key}`}>
        <Descriptions.Item label={title("资源")} className={className()}>
          {item?.resourceName ? item?.resourceName : "*"}
        </Descriptions.Item>
        <Descriptions.Item label={title("分类")} className={className()}>
          {item?.domainNames ? item?.domainNames.join("、") : "*"}
        </Descriptions.Item>
        <Descriptions.Item label={title("规则")} className={className()}>
          {item?.actions.split(",").join("、") || ""}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("操作")}
          className={className()}
        >
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              loading={editSettingVisible && item?.key === editSettingInfo?.key}
              onClick={() => {
                setEditSettingInfo(item);
                setEditSettingVisible(true);
              }}
            />
            <Popconfirm
              title={`确认删除【${item.resourceName || "*"}】资源？`}
              onConfirm={() => delSetting(item.key)}
              okText="确认"
              cancelText="取消"
              icon={<ExclamationCircleOutlined />}
              style={{ color: "red" }}
            >
              <Button
                danger
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Descriptions
        column={4}
        bordered={resources?.length ? true : false}
        layout="vertical"
        extra={
          <Button
            loading={addSettingVisible}
            icon={<PlusOutlined />}
            onClick={() => setAddSettingVisible(true)}
          >
            新增
          </Button>
        }
      >
        {resources?.map((item, index) => SettingDescItem(item, index))}
      </Descriptions>
      <PermissionResourcesAdd
        resources={resources}
        visible={addSettingVisible}
        setVisible={setAddSettingVisible}
        update={update}
      />
      <PermissionResourcesEdit
        editingItem={editSettingInfo}
        visible={editSettingVisible}
        setVisible={setEditSettingVisible}
        update={updateList}
      />
    </Fragment>
  );
};

export default PermissionResources;
