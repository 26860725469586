import { IdType } from "lib/api";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useShareChecklist = () => {
  const [{ openShareChecklist }, setopenShareChecklist] = useUrlQueryParam([
    "openShareChecklist",
    "detailId",
  ]);

  const open = (id: IdType) =>
    setopenShareChecklist({ openShareChecklist: true, detailId: id });
  const close = () =>
    setopenShareChecklist({
      openShareChecklist: undefined,
      detailId: undefined,
    });

  return [openShareChecklist === "true", open, close] as const;
};
