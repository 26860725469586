import { useEffect, useState, useRef } from "react";
import { DropdownRef } from "antd-mobile/es/components/dropdown";
import { Dropdown, Form, Radio, Space } from "antd-mobile";
import type { FormInstance } from "rc-field-form/es";
import useUrlState from "@ahooksjs/use-url-state";
import { useMount } from "utils";
import { getSelectParams, getSelectTitle } from ".";
import { useAuth } from "context/auth";

export const BreaklistSelectDropdown = ({
  form,
  onSubmit,
}: {
  form: FormInstance<any>;
  onSubmit: (params: any, type?: string) => void;
}) => {
  const dropdownRef = useRef<DropdownRef>(null);
  const [{ select_type }, setSearch] = useUrlState({
    select_type: undefined,
  });
  const { user } = useAuth();
  const [filterTitle, setFilterTitle] = useState<string>(
    getSelectTitle(select_type),
  );

  useEffect(() => {
    if (select_type) {
      form.setFieldValue("select_type", select_type);
      setFilterTitle(getSelectTitle(select_type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select_type]);

  useEffect(() => {
    setSearch({ select_type: "unclosed" });
  }, []);

  return (
    <Dropdown closeOnClickAway ref={dropdownRef}>
      <Dropdown.Item key="select" title={filterTitle} highlight>
        <div>
          <Form
            mode="card"
            form={form}
            onValuesChange={(_, values) => {
              const { select_type } = values;
              setFilterTitle(getSelectTitle(select_type));
              onSubmit(getSelectParams(select_type, user), undefined);
              dropdownRef.current?.close();
            }}
          >
            <Form.Header>快速筛选</Form.Header>
            <Form.Item
              name={"select_type"}
              // initialValue={"unclosed"}
              rules={[{ required: true, message: "至少选择一个" }]}
            >
              <Radio.Group
                onChange={(v) => {
                  setSearch({ select_type: v });
                }}
              >
                <Space direction="vertical" block>
                  <Radio block value={"unclosed"}>
                    未关单
                  </Radio>
                  <Radio block value={"all"}>
                    全部工单
                  </Radio>
                  {/* <Radio block value={"charge"}>
                    负责中工单
                  </Radio> */}
                  <Radio block value={"dispose"}>
                    处理中工单
                  </Radio>
                  <Radio block value={"suspend"}>
                    挂起中工单
                  </Radio>
                  <Radio block value={"created"}>
                    已创建工单
                  </Radio>
                  {/* <Radio block value={"solved"}>
                    已解决工单
                  </Radio> */}
                  <Radio block value={"srd_processing"}>
                    升级SRD工单
                  </Radio>
                  <Radio block value={"dev_processing"}>
                    升级研发工单
                  </Radio>
                  {/* <Radio block value={"processed"}>
                    我处理的工单
                  </Radio> */}
                  <Radio block value={"follow"}>
                    关注工单
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Header>类型</Form.Header>
          </Form>
        </div>
      </Dropdown.Item>
    </Dropdown>
  );
};
