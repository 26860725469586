import { PageHeader } from "@ant-design/pro-components";
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  message,
  Row,
  Segmented,
  Select,
  Space,
} from "antd";
import { UploadOutlined, RedoOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useDebounceFn } from "ahooks";

import { HospListType } from "interface";
import {
  DisabledDate,
  formatDate,
  hosStatus,
  isR,
  RID,
  RIDCHINESENAME,
  selectFilter,
  statusColor,
  THID,
} from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { HospProductsListType } from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";
import { GET_HOS_PRODUCT_LIST, GET_RATE } from "lib/api";
import { Fragment, useEffect, useState } from "react";
import { DateStep, RateType } from "../interface";
import { Calculate } from "./calculate";
import { RateLineChart } from "./rateLineChart";
import { RateTable } from "./rateTable";
import { RateImportManually } from "./import-rate/rateImportManually";
import { FileImport } from "./import-rate/fileImport";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const HosRateDetail = ({
  hospInfo,
  stateKey,
}: {
  hospInfo: HospListType | undefined;
  stateKey?: string;
}) => {
  const [product, setProduct] = useState<string | null>(null);
  const [queryStep, setQueryStep] = useState<DateStep>(DateStep.day);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(
    dayjs().subtract(30, "days"),
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());
  const [manuallyVisible, setManuallyVisible] = useState<boolean>(false);
  const [fileVisible, setFileVisible] = useState<boolean>(false);
  const [hosRateData, setHosRateData] = useState<RateType | null>();
  const client = useHttp();

  const {
    run: getHospRate,
    isLoading: gettingHospRate,
    isIdle: getHospRateIsIdle,
    data: hospRate,
  } = useAsync<RateType>();
  const getHospRateHandle = ({
    product_line,
    unit,
    start,
    end,
  }: {
    product_line?: string;
    unit?: DateStep;
    start?: dayjs.Dayjs;
    end?: dayjs.Dayjs;
  }) => {
    if (hospInfo) {
      getHospRate(
        client(GET_RATE, {
          data: {
            hospital_id: hospInfo.id,
            product_line: product_line || product,
            unit: unit || queryStep,
            start: formatDate(start) || formatDate(startDate),
            end: formatDate(end) || formatDate(endDate),
          },
        }),
      );
    } else {
      message.error("无法请求当前医院数据，请稍后重试或者联系管理员！");
    }
  };
  const { run: getHospRateDebounce } = useDebounceFn(getHospRateHandle, {
    wait: 200,
  });

  const {
    run: getHosProductList,
    isLoading: gettingHosProductList,
    isIdle: getHosProductListIsIdle,
    data: productList,
    setData: setProductList,
  } = useAsync<HospProductsListType[]>();
  const getHosProductListFunc = (hospital_id: number) => {
    getHosProductList(client(GET_HOS_PRODUCT_LIST(hospital_id))).then(
      (res: HospProductsListType[]) => {
        // 肺结节、骨折、肺炎三者任一一个出现均只展示肺结节
        if (res?.find((p) => isR(p.product_id))) {
          let tmplist = res.filter((i) => !isR(i.product_id));
          tmplist.push({
            product_id: RID,
            product_chinese_name: RIDCHINESENAME,
          });
          setProduct(() => {
            let id = RID;
            getHospRateDebounce({ product_line: id });
            return id;
          });
          setProductList(tmplist);
        } else {
          if (res.length > 0) {
            setProduct(() => {
              let id = res[0].product_id;
              getHospRateDebounce({ product_line: id });
              return id;
            });
          } else {
            getHospRateDebounce({});
          }
        }
      },
    );
  };

  const { run: getHosProductListDebounce } = useDebounceFn(
    getHosProductListFunc,
    { wait: 200 },
  );
  const initDetail = () => {
    setHosRateData(null);
  };

  useEffect(() => {
    initDetail();
    if (hospInfo && stateKey == "rate") {
      getHosProductListDebounce(hospInfo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospInfo, stateKey]);

  useEffect(() => {
    setStartDate(dayjs().subtract(30, "days"));
    setEndDate(dayjs());
  }, [hospInfo]);

  useEffect(() => {
    setHosRateData(hospRate);
  }, [hospRate]);

  const loadingData =
    getHosProductListIsIdle ||
    gettingHosProductList ||
    getHospRateIsIdle ||
    gettingHospRate;

  return (
    <div
      style={{
        height: "100%",
        paddingRight: "8px",
        overflow: "auto",
        minWidth: "1032px",
      }}
    >
      <Card
        title={
          <Row gutter={[16, 16]}>
            <Col>
              <Select
                disabled={loadingData}
                loading={loadingData}
                placeholder="选择产品线"
                style={{ width: 220 }}
                showSearch
                filterOption={selectFilter}
                value={product}
                onChange={(v) => {
                  setProduct(v);
                  getHospRateHandle({ product_line: v });
                }}
              >
                {productList?.map((i, index) => (
                  <Option key={index} value={i.product_id}>
                    {i.product_id}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <RangePicker
                disabled={loadingData}
                allowClear={false}
                disabledDate={DisabledDate}
                value={[startDate, endDate]}
                style={{ width: 240 }}
                onChange={(v) => {
                  if (v) {
                    setStartDate(v[0] || dayjs());
                    setEndDate(v[1] || dayjs());
                  }
                }}
                onOpenChange={(open) => {
                  if (!open) {
                    getHospRateHandle({});
                  }
                }}
              />
            </Col>
            <Col>
              <Segmented
                disabled={loadingData}
                options={[
                  { label: "日", value: DateStep.day },
                  { label: "周", value: DateStep.week },
                  { label: "月", value: DateStep.month },
                  { label: "年", value: DateStep.year },
                ]}
                value={queryStep}
                onChange={(v) => {
                  setQueryStep(v as DateStep);
                  getHospRateHandle({ unit: v as DateStep });
                }}
              />
            </Col>
            <Col>
              <Button
                disabled={loadingData}
                loading={loadingData}
                icon={<RedoOutlined />}
                type="primary"
                onClick={() => getHospRateHandle({})}
              >
                刷新
              </Button>
            </Col>
          </Row>
        }
        extra={
          <Space>
            <Button
              disabled={loadingData}
              loading={fileVisible}
              icon={<UploadOutlined />}
              onClick={() => setFileVisible(true)}
            >
              文件导入
            </Button>
            <Button
              disabled={loadingData}
              loading={manuallyVisible}
              icon={<UploadOutlined />}
              onClick={() => setManuallyVisible(true)}
            >
              手动导入
            </Button>
          </Space>
        }
        loading={loadingData}
      >
        <Fragment>
          <Calculate data={hosRateData?.calculate} isTh={product === THID} />
          <Divider />
          <RateLineChart
            data={hosRateData?.results}
            unit={hosRateData?.unit || DateStep.day}
            isTh={product === THID}
            hospInfo={hospInfo}
            product={product}
          />
          <Divider />
          <RateTable
            data={hosRateData?.results}
            unit={hosRateData?.unit || DateStep.day}
            isTh={product === THID}
          />
          <RateImportManually
            hospInfo={hospInfo}
            productList={productList || []}
            defaultProduct={product}
            visible={manuallyVisible}
            setVisible={setManuallyVisible}
            reload={() => getHospRateHandle({})}
          />
          <FileImport
            hospInfo={hospInfo}
            visible={fileVisible}
            setVisible={setFileVisible}
            reload={() => getHospRateHandle({})}
          />
        </Fragment>
      </Card>
    </div>
  );
};
