import { IntroduceRow } from "./IntroduceRow";
import { BreakInfoChart } from "./BreakInfoChart";
export const BreakInfoStats = () => {
  return (
    <div style={{ height: "100%", minWidth: 988, margin: "20px" }}>
      <IntroduceRow />
      <BreakInfoChart />
    </div>
  );
};
