import { MyPieChartType } from "../../chart/pie-chart";
import { EqptModelStatisticType as PieType } from "../../interface";

const GetType = (data: PieType[]) => {
  let tmpType: Map<
    string,
    {
      name: string;
      data: number;
    }
  > = new Map();
  for (let r of data) {
    if (r.model.length) {
      r.model.forEach((i) => {
        if (!tmpType.has(i.name)) {
          tmpType.set(i.name, {
            name: i.name,
            data: 0,
          });
        }
      });
      break;
    }
  }
  return tmpType;
};

export const GetModelNumPie = (data: PieType[]) => {
  let tmpType = GetType(data);

  let tmpData: MyPieChartType = {
    data: [],
  };

  data.forEach((item) => {
    item.model.forEach((r) => {
      tmpType.get(r.name)!.data += r.value;
    });
  });

  tmpType.forEach((item) => {
    tmpData.data.push({
      name: item.name,
      value: item.data,
    });
  });

  return tmpData;
};
