import { useEffect } from "react";
import { Form, Modal, Input, DatePicker } from "antd";

import { useBreakDetailSuspend } from "authenticated-app/hooks/breaklist/trans/useBreakDetailSuspend";
import { FormatDayjs, FormatTime } from "utils";
import { BREAK_DETAIL_TYPE } from "lib/interface/breaklist";

import "authenticated-app/pc/components/EditQuill/quill.snow.css";

const { TextArea } = Input;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

export const BreakDetailSuspendModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: number[];
  detailInfo: BREAK_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useBreakDetailSuspend();

  const [form] = Form.useForm();

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_time: FormatTime(value.next_time),
      next_process: next,
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        suspension_reason: detailInfo?.suspension_reason,
        next_time: detailInfo?.next_time
          ? FormatDayjs(detailInfo?.next_time)
          : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="挂起工单"
      onCancel={close}
      onOk={form.submit}
      width={"550px"}
      style={{ minWidth: "550px" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        <Form.Item
          name="suspension_reason"
          label="挂起原因"
          rules={[{ required: true, message: "必须填写挂起原因" }]}
        >
          <TextArea placeholder="填写挂起原因" autoSize={{ minRows: 3 }} />
        </Form.Item>
        <Form.Item
          name="next_time"
          label="下次跟进日期"
          rules={[{ required: true, message: "必须选择下次跟进日期" }]}
        >
          <DatePicker />
        </Form.Item>
      </Form>
    </Modal>
  );
};
