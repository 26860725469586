import { List, Popover, Space } from "antd-mobile";
import { QuestionCircleOutline } from "antd-mobile-icons";

import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { RateCalculate } from "authenticated-app/pc/customer/rate-detail/interface";
import { isRRateTip } from "utils";

export const MobileCalculate = ({
  data,
  isTh,
  isR,
}: {
  data: RateCalculate | undefined;
  isTh: boolean;
  isR: boolean;
}) => {
  return (
    <ContentCard>
      <List
        mode="card"
        header={
          <ListHeader
            text={
              isR ? (
                <Space>
                  <span>查询结果</span>
                  <Popover content={isRRateTip} trigger="click" mode="dark">
                    <QuestionCircleOutline />
                  </Popover>
                </Space>
              ) : (
                "查询结果"
              )
            }
          />
        }
      >
        {isTh ? (
          <>
            <List.Item
              extra={
                data?.total_predict_num === 0
                  ? 0
                  : data?.total_predict_num || "未知"
              }
            >
              病例数
            </List.Item>
            <List.Item
              extra={
                data?.total_rc2_num === 0 ? 0 : data?.total_rc2_num || "未知"
              }
            >
              重建数
            </List.Item>
            <List.Item
              extra={
                data?.total_access_num === 0
                  ? 0
                  : data?.total_access_num || "未知"
              }
            >
              访问数
            </List.Item>
            <List.Item
              extra={
                data?.total_download_num === 0
                  ? 0
                  : data?.total_download_num || "未知"
              }
            >
              报告下载数
            </List.Item>
          </>
        ) : (
          <>
            <List.Item
              extra={
                data?.total_predict_num === 0
                  ? 0
                  : data?.total_predict_num || "未知"
              }
            >
              预测量
            </List.Item>
            <List.Item
              extra={
                data?.total_usv_num === 0 ? 0 : data?.total_usv_num || "未知"
              }
            >
              点击量
            </List.Item>
            <List.Item extra={data?.average_click_rate || "0%"}>
              点击率
            </List.Item>
          </>
        )}
      </List>
    </ContentCard>
  );
};
