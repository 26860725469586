import * as auth from "context/auth/provider";

export interface exeChildFunc {
  exeFunc(): void;
}

export interface RetainAPI {
  ADD_RETAIN: (hospID: string | number) => string;
  UPDATE_RETAIN: (org_id: number) => string;
  DEL_RETAIN: (org_id: number) => string;
}

const token = auth.getToken();
export const UploadHeaders = {
  Authorization: token ? `jwt ${token}` : "",
};

export interface Attachment {
  id: number;
  update_user: string;
  attachment_id: number;
  attachment_name: string;
  attachment_addr: string;
}
