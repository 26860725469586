import { useEffect } from "react";
import { Form, Modal, Select, Input } from "antd";
import { useDebounceFn } from "ahooks";
import { useDeliveryDetailSend } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailSend";
import { useAuth } from "context/auth";
import {
  DELIVERY_DETAIL_TYPE,
  NEXT_PROCESS_TYPE,
  DELIVERY_USER_TYPE,
} from "lib/interface/launchChangeList";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { HospSelectFilter, FormatDayjs } from "utils";
import { GET_DELIVERY_USER_LIST } from "lib/api/deliverylist";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const DeliveryDetailSendModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailSend();
  const [form] = Form.useForm();
  const { user } = useAuth();
  const client = useHttp();

  const {
    run: getDeliveryUserList,
    data: deliveryUserList,
    isIdle: getDeliveryUserListIdle,
    isLoading: gettingDeliveryUserList,
  } = useAsync<DELIVERY_USER_TYPE[]>();
  const getDeliveryUserListHandle = () => {
    getDeliveryUserList(client(GET_DELIVERY_USER_LIST));
  };
  const { run: getDeliveryUserListDebounce } = useDebounceFn(
    getDeliveryUserListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      const delivery_user_id = detailInfo?.delivery_user_id
        ? detailInfo?.delivery_user_id
        : user?.id;
      form.setFieldsValue({
        delivery_user_id: delivery_user_id,
        tracking_number: detailInfo?.tracking_number,
        bill_of_materials: detailInfo?.bill_of_materials,
      });
      getDeliveryUserListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="生产寄送"
      onCancel={close}
      onOk={form.submit}
      width={"700px"}
      style={{ minWidth: "550px" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        <Form.Item
          name="bill_of_materials"
          label="物料清单"
          help={
            "物料清单内容从流程的订单信息中获取,填写时建议以物料名称*数量组合, 多个物料以间隔即可.例如：P5820 * 2, A4000*3 "
          }
          rules={[{ required: true, message: "必须填写物料清单内容" }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name="tracking_number"
          label="物流单号"
          help={"从流程中获取物流单号, 如SF1234567890"}
        >
          <Input.TextArea placeholder="填写物流单号" />
        </Form.Item>

        <Form.Item
          name="delivery_user_id"
          label="交付负责人"
          rules={[{ required: true, message: "必须指定交付负责人" }]}
        >
          <Select
            showSearch
            loading={getDeliveryUserListIdle || gettingDeliveryUserList}
            placeholder="指定交付负责人"
            filterOption={HospSelectFilter}
            options={deliveryUserList?.map((i) => ({
              label: `${i.userName} (${i.jobTitle})`,
              value: i.id,
            }))}
          />
        </Form.Item>
        <Form.Item name="callout_number" hidden initialValue={""}></Form.Item>
      </Form>
    </Modal>
  );
};
