import { useEffect } from "react";
import { Tabs, Result, Button, Skeleton } from "antd";
import type { TabsProps } from "antd";
import { usePermission } from "context/permission";

import { DockingClientInfo } from "./docking-client";
import { MaintenanceQuantity } from "./maintenance-quantity";
import { RemoteModelInfo } from "./remote-model";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { AssetInfo } from "./asset-info";

const defaultTab = "overview";

export const MaintenancetStat = () => {
  const [{ tab }, setTab] = useUrlQueryParam(["tab"]);
  const { permission } = usePermission();

  useEffect(() => {
    if (!!!tab) {
      setTab({ tab: defaultTab });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const items: TabsProps["items"] = [
    {
      key: "overview",
      label: `维护数量概览`,
      children: <MaintenanceQuantity />,
    },
    {
      key: "docking-client",
      label: `产品对接部署与客户端统计`,
      children: <DockingClientInfo />,
    },
    {
      key: "remote-model",
      label: `远程方式与机型统计`,
      children: <RemoteModelInfo />,
    },
    {
      key: "remote-info",
      label: `远程与跳板机统计`,
      children: <AssetInfo />,
    },
  ];

  return permission?.maintainProfile === 1 ? (
    <Tabs
      activeKey={tab}
      size="small"
      items={items}
      onChange={(v) => setTab({ tab: v })}
    />
  ) : permission?.maintainProfile === 0 ? (
    <Result
      status="403"
      title="403"
      subTitle="抱歉，你无权限访问此页面！"
      extra={
        <Button
          type="primary"
          onClick={() => window.location.replace(window.location.origin)}
        >
          返回主页
        </Button>
      }
    />
  ) : (
    <Skeleton active />
  );
};
