import { Key } from "react";

import { CHECKLIST_VALUE_TYPE, CHECKLIST_ENUM } from "lib/interface/checklist";
import { SharedAbnormalList } from "./list/sharedAbnormalList";
import { SharedSurveyList } from "./list/sharedSurveyList";
import { SharedWorkList } from "./list/sharedWorkList";

const getType = ({
  key,
  detail,
  check_type,
  index,
  loading,
}: {
  key: Key;
  detail: CHECKLIST_VALUE_TYPE;
  index: number;
  check_type?: string;
  loading: boolean;
}) => {
  switch (check_type as CHECKLIST_ENUM) {
    case CHECKLIST_ENUM.online_list:
      return (
        <SharedWorkList
          key={key}
          detail={detail}
          index={index}
          loading={loading}
        />
      );
    case CHECKLIST_ENUM.exit_list:
      return (
        <SharedWorkList
          key={key}
          detail={detail}
          index={index}
          loading={loading}
        />
      );
    case CHECKLIST_ENUM.survey_list:
      return (
        <SharedSurveyList
          key={key}
          detail={detail}
          index={index}
          loading={loading}
        />
      );
    case CHECKLIST_ENUM.except_list:
      return (
        <SharedAbnormalList
          key={key}
          detail={detail as CHECKLIST_VALUE_TYPE}
          index={index}
          loading={loading}
        />
      );
    default:
      return;
  }
};

export const GetListType = ({
  key,
  detail,
  check_type,
  index,
  loading,
}: {
  key: Key;
  detail: CHECKLIST_VALUE_TYPE;
  index: number;
  check_type?: string;
  loading: boolean;
}) => {
  return getType({ key, detail, check_type, index, loading });
};
