import qs from "qs";
import * as auth from "context/auth/provider";
import { ErrorMsg } from "interface";
import { message } from "antd";

interface Config extends RequestInit {
  token?: string | null;
  data?: object;
}
export const download = async (
  endpoint: string,
  { data, token, headers, ...customConfig }: Config = {},
) => {
  const config = {
    method: "GET", // 默认GET，后面会被customeConfig覆盖
    headers: {
      Authorization: token ? `jwt ${token}` : "",
      "Content-Type": data ? "application/json" : "",
      ...headers,
    },
    ...customConfig,
  };

  if (config.method.toUpperCase() === "GET") {
    if (data) {
      endpoint += `?${qs.stringify(data)}`;
    }
  } else {
    config.body = JSON.stringify(data || {});
  }

  return window
    .fetch(`${endpoint}`, config)
    .then(async (response) => {
      if (response.status === 401) {
        await auth.logout();
        return Promise.reject({ errorMsg: "token已失效" });
      }
      if (response.ok) {
        try {
          return [
            response.blob(),
            response.headers.get("content-disposition"),
          ] as const;
        } catch {
          return Promise.reject({ errorMsg: "无法解析文件流" });
        }
      } else {
        return Promise.reject(response);
      }
    })
    .then(async ([blob, name]) => {
      let filename: string;
      filename = name?.split(";")[1]?.split("=")[1] || "导出信息.xlsx";

      var url = window.URL.createObjectURL(await blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = decodeURI(filename);
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch(async (e) => {
      let msg: ErrorMsg;
      try {
        msg = await e.json();
      } catch {
        msg = e;
      }
      console.error(msg);
      message.error(
        msg?.errorMsg || msg?.detail || "未知错误，请联系管理员或刷新重试！",
      );
      throw Promise.reject(e);
    });
};

export const useDownload = () => {
  const token = auth.getToken();
  return (...[endpoint, config]: Parameters<typeof download>) =>
    download(endpoint, { ...config, token });
};
