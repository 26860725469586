import { RefObject, useEffect, useMemo, useState, useRef } from "react";
import {
  Button,
  DatePicker,
  DatePickerRef,
  Form,
  Grid,
  Input,
  Picker,
  Popup,
  SearchBar,
  Selector,
  Skeleton,
  Space,
  Stepper,
  Switch,
  TextArea,
  Toast,
  Tag,
  CascadePicker,
  Divider,
} from "antd-mobile";
import {
  PickerRef,
  PickerColumnItem,
  PickerValue,
} from "antd-mobile/es/components/picker";
import { useDebounceFn } from "ahooks";
import { Upload, UploadFile } from "antd";
import {
  AddOutline,
  CloseCircleOutline,
  InformationCircleOutline,
  DeleteOutline,
  InformationCircleFill,
} from "antd-mobile-icons";
import ReactQuill from "react-quill";
import { mobileQuillToolbarOption } from "authenticated-app/mobile/components/MobileQuill/option";

import {
  DisposeForm,
  DisposeMobileText,
  DisposeQuill,
  FormatTime,
  handleUpload,
} from "utils";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { HospListType } from "interface";
import {
  ADD_FILE,
  GET_PRODUCT_LIST,
  HOSP_LIST_API,
  GET_PRODUCT_VERSION_BY_ID,
  GET_PRODUCT_DETAIL_HAS_NOTONLINE,
} from "lib/api";

import { useBreaklistAdd } from "authenticated-app/hooks/breaklist/useBreaklistAdd";
import {
  GET_ORDER_TYPE,
  GET_ISSUES_TYPE,
  // GET_ISSUES_REASON,
  GET_ISSUES_REASON_NEW,
  GET_ISSUES_FEEDBACK_CHANNEL,
  ADD_BREAKLIST,
} from "lib/api/issues";
import { BREAKLIST_OPTION_TYPE } from "lib/interface/breaklist";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { MobileLimitUpload } from "authenticated-app/mobile/util";

const now = new Date();

export const MobileAddBreaklistPopup = ({ reload }: { reload: () => void }) => {
  const [isOpen, , close] = useBreaklistAdd();
  const [form] = Form.useForm();

  const [isCustomer, setIsCustomer] = useState(true);
  const [isAdditional, setIsAdditional] = useState(false);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [serviceConfirmationfile, setServiceConfirmationfile] = useState<
    UploadFile[]
  >([]);
  const [departureConfirmationfile, setDepartureConfirmationfile] = useState<
    UploadFile[]
  >([]);

  const [selectedHospId, setSelectedHospId] = useState<number>();
  const [orderTypeHelpInfo, setOrderTypeHelpInfo] = useState<string>(
    "使用故障: 日常客户反馈的故障,例如产品功能异常,数据预测失败,数据缺失等.",
  );
  const [searchName, setSearchName] = useState<string>("");
  const [effectSwitch, setEffectSwitch] = useState<
    number | string | PickerValue
  >(1);

  const client = useHttp();

  const [productVisible, setProductVisible] = useState<boolean>(false);

  const [options, setOptions] = useState<Options[]>([]);
  const [searchEffectProduct, setSearchEffectProduct] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<Options[]>([]);

  const [productLoading, setProductLoading] = useState(true);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [selectedIssuesTypeId, setSelectedIssuesTypeId] = useState<number>();
  const [affectProductValues, setAffectProductValues] = useState<any[]>([]);

  const {
    run: getHosp,
    data: hospList,
    isIdle: getHospIsIdle,
    isLoading: gettingHospList,
  } = useAsync<HospListType[]>();
  const getHospFunc = (params?: { [key in any]: any }) => {
    getHosp(
      client(HOSP_LIST_API, {
        data: {
          ...params,
        },
      }),
    );
  };
  const { run: getHospDebounce } = useDebounceFn(getHospFunc, { wait: 200 });

  const { run: AddBreaklist, isLoading: AddingBreaklist } = useAsync();

  const {
    run: getOrderTypeList,
    data: orderTypeList,
    isIdle: getOrderTypeListIsIdle,
    isLoading: gettingOrderTypeList,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getOrderTypeListHandle = () => {
    getOrderTypeList(client(GET_ORDER_TYPE));
  };
  const { run: getOrderTypeListDebounce } = useDebounceFn(
    getOrderTypeListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getIssuesTypeList,
    data: issuesTypeList,
    isIdle: getIssuesTypeListIsIdle,
    isLoading: gettingIssuesTypeList,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesTypeListHandle = () => {
    getIssuesTypeList(client(GET_ISSUES_TYPE));
  };
  const { run: getIssuesTypeListDebounce } = useDebounceFn(
    getIssuesTypeListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getIssuesReasonList,
    data: issuesReasonList,
    isIdle: getIssuesReasonListIsIdle,
    isLoading: gettingIssuesReasonList,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getIssuesReasonListHandle = () => {
    if (selectedIssuesTypeId) {
      getIssuesReasonList(client(GET_ISSUES_REASON_NEW(selectedIssuesTypeId)));
    }
  };
  const { run: getIssuesReasonListDebounce } = useDebounceFn(
    getIssuesReasonListHandle,
    {
      wait: 200,
    },
  );

  const {
    run: getFeedbackChannel,
    data: feedbackChannel,
    isIdle: getFeedbackChannelIsIdle,
    isLoading: gettingFeedbackChannel,
  } = useAsync<BREAKLIST_OPTION_TYPE[]>();
  const getFeedbackChannelHandle = () => {
    getFeedbackChannel(client(GET_ISSUES_FEEDBACK_CHANNEL));
  };
  const { run: getFeedbackChannelDebounce } = useDebounceFn(
    getFeedbackChannelHandle,
    {
      wait: 200,
    },
  );

  const onSubmit = (values: any) => {
    AddBreaklist(
      client(ADD_BREAKLIST, {
        method: "POST",
        data: {
          order_type_id: values?.order_type_id
            ? parseInt(values?.order_type_id[0])
            : null,
          title: values?.title || null,
          customer_owner: values?.customer_owner?.[0] || null,
          customer_name: values?.customer_name || null,
          hospital_id: values?.hospital_id
            ? parseInt(values?.hospital_id[0])
            : null,
          // {
          //     "label": "肺结节 R12.9.7(e9d7d14)",
          //     "value": "CT_LUNG|164"
          // }
          product_id_list: selectedValues.map((item) => [
            item.value.split("|")[0],
            item.value.split("|")[1],
          ]),
          description: values?.description || null,
          feedback_channel_id: values?.feedback_channel_id
            ? parseInt(values?.feedback_channel_id[0])
            : null,
          feedback_user: values?.feedback_user || null,
          contact: values?.contact || null,
          feedback_time: values?.feedback_time
            ? FormatTime(values?.feedback_time)
            : undefined,
          influence_function: values?.influence_function?.[0] || null,
          influence_sphere: values?.influence_sphere?.[0] || null,
          is_additional_recording: values?.is_additional_recording ? 1 : 0,
          debug_info: values?.debug_info || null,
          solution_info: values?.solution_info || null,
          is_deal_with_on_site: values?.is_deal_with_on_site ? 1 : 0,
          issue_type_id: values?.issue_type_id
            ? parseInt(values?.issue_type_id[0])
            : null,
          issue_reason_id: values?.issue_reason_id
            ? parseInt(values?.issue_reason_id[0])
            : null,
          checklist_id: serviceConfirmationfile.length
            ? serviceConfirmationfile.map((i) => i.uid)[0]
            : undefined,
          service_order_id: departureConfirmationfile.length
            ? departureConfirmationfile.map((i) => i.uid)[0]
            : undefined,
          attachment_id_list: fileList.map((i) => i.uid),
        },
      }),
    )
      .then((v) => {
        const content = `工单${v.order_id || ""}提交成功!`;
        Toast.show({
          icon: "success",
          content: <ToastContent content={content} />,
        });
        close();
        reload();
      })
      .catch(() => {
        Toast.show({
          icon: "fail",
          content: <ToastContent content={"工单提交失败!"} />,
          duration: 3,
        });
      });
  };

  const filteredItems = useMemo(() => {
    if (searchName) {
      return hospList?.filter((item) => item.name?.includes(searchName));
    } else {
      return hospList;
    }
  }, [hospList, searchName]);

  const initFormData = (order_type_id: string | null) => {
    form.resetFields();
    form.setFieldsValue({ order_type_id: [order_type_id ? order_type_id : 1] });
    setSelectedIssuesTypeId(undefined);
    setEffectSwitch(order_type_id ? order_type_id[0] : 1);
    setIsCustomer(true);
    setIsAdditional(false);
    setSelectedHospId(undefined);
    setFileList([]);
    setServiceConfirmationfile([]);
    setDepartureConfirmationfile([]);
    resetProductSetting();
  };

  const resetProductSetting = () => {
    setOptions([]);
    form.setFieldsValue({ product_id_list: [] });
    setSelectedValues([]);
    setProductLoading(true);
  };

  const resetFormData = () => {
    const order_type_id = form.getFieldValue("order_type_id");
    initFormData(order_type_id);
    form.setFieldValue("order_type_id", order_type_id);
  };

  const setOrderTypeTips = (typeID: number | PickerValue) => {
    switch (typeID) {
      case 1:
        setOrderTypeHelpInfo(
          "使用故障: 日常客户反馈的故障,例如产品功能异常,数据预测失败,数据缺失等.",
        );
        break;
      case 2:
        setOrderTypeHelpInfo(
          "使用需求: 日常客户提出的需求,例如使用咨询,修改报告模板,模型反馈,新增电脑安装客户端，数据/PACS/胶片对接等",
        );
        break;
      case 3:
        setOrderTypeHelpInfo("日常服务: 资产盘点、使用巡检、信息调研、回访等");
        break;
    }
  };

  useEffect(() => {
    if (isAdditional) {
      if (!!!issuesTypeList) {
        getIssuesTypeListDebounce();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdditional]);

  useEffect(() => {
    if (isOpen) {
      initFormData(null);
      if (!!!hospList) {
        getHospDebounce();
      }
      if (!!!orderTypeList) {
        getOrderTypeListDebounce();
      }
      if (!!!feedbackChannel) {
        getFeedbackChannelDebounce();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!!!isCustomer) {
      // getAllProductListHandle();
      fetchFirstLevelData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomer]);

  useEffect(() => {
    if (selectedHospId) {
      // getProductListHandle();
      fetchFirstLevelData(selectedHospId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHospId]);

  useEffect(() => {
    resetFormData();
    setOrderTypeTips(effectSwitch);
    // initFormData();
  }, [effectSwitch]);

  useEffect(() => {
    form.setFieldsValue({ product_id_list: selectedValues });
  }, [selectedValues]);

  const loading = getHospIsIdle || gettingHospList;

  interface Options {
    value: string;
    label: string;
    children?: Options[];
    isLeaf?: boolean;
  }

  const fetchFirstLevelData = async (hospID: number | null) => {
    try {
      setProductLoading(true);
      let firstLevelData: any[] = [];
      if (hospID) {
        firstLevelData = await client(GET_PRODUCT_DETAIL_HAS_NOTONLINE(hospID));
      } else {
        firstLevelData = await client(GET_PRODUCT_LIST);
      }
      const formattedData = await fetchSecondLevelData(firstLevelData);
      let defaultSelectedValues: any[] = [];
      if (isCustomer) {
        if (firstLevelData) {
          defaultSelectedValues = firstLevelData.map((item: any) => ({
            label: `${item.product_chinese_name} ${
              item.product_version_name || "无版本"
            }`,
            value: `${item.product_id}|${
              item.product_version_id ? item.product_version_id.toString() : ""
            }`,
          }));
        }
      }
      setSelectedValues(defaultSelectedValues);
      setOptions(formattedData);
      setProductLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSecondLevelData = async (firstLevelData: any[]) => {
    const formattedData: any[] = [];
    for (const item of firstLevelData) {
      const current_product_version_id = item.product_version_id;
      const product: Options = {
        value: item.product_id || item.title,
        label:
          item.product_chinese_name || item.chinese_name || item.title || "",
        children: [],
      };
      const product_id = item.product_id || item.title;
      const secondLevelData: any[] = await client(
        GET_PRODUCT_VERSION_BY_ID(product_id),
      );
      if (secondLevelData.length) {
        for (const version of secondLevelData) {
          if (product.children) {
            version.id.toString() === `${current_product_version_id}`
              ? product.children.unshift({
                  value: version.id.toString(),
                  label: `${version.name}`,
                })
              : product.children.push({
                  value: version.id.toString(),
                  label: `${version.name}`,
                });
          }
        }
      } else {
        if (product.children) {
          product.children.push({
            value: "",
            label: "无版本",
          });
        }
      }
      formattedData.push(product);
    }
    return formattedData;
  };

  useEffect(() => {
    getIssuesReasonListDebounce();
  }, [selectedIssuesTypeId]);

  const filterOptions = (value: string) => {
    if (value == "") {
      setFilteredOptions(options);
      return;
    }
    const filtered = options
      .map((item) => ({
        ...item,
        children: item.children?.filter((child) =>
          child.label.toLowerCase().includes(value.toLowerCase()),
        ),
      }))
      .filter((item) => item.children && item.children.length > 0);
    setFilteredOptions(filtered);
  };

  useEffect(() => {
    if (!!!productVisible) {
      return;
    }
    filterOptions(searchEffectProduct);
  }, [searchEffectProduct, productVisible]);

  return (
    <Popup
      visible={isOpen}
      destroyOnClose
      bodyStyle={{
        height: "90vh",
        overflowY: "scroll",
      }}
      onMaskClick={close}
      showCloseButton
      onClose={close}
    >
      <LoadingMask visible={loading || AddingBreaklist} />
      <Form
        form={form}
        onFinish={onSubmit}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={AddingBreaklist}
          >
            提交
          </Button>
        }
      >
        <Form.Header>新建工单</Form.Header>
        <Form.Item
          name="order_type_id"
          label="工单类型"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择工单类型" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            title={
              <span style={{ color: "gray", fontSize: "10px" }}>
                {orderTypeHelpInfo}
              </span>
            }
            loading={gettingOrderTypeList || getOrderTypeListIsIdle}
            columns={
              orderTypeList
                ? [
                    orderTypeList?.map((i) => ({
                      label: i.name,
                      value: i.id,
                    })),
                  ]
                : []
            }
            onConfirm={(v) => {
              if (v[0]) {
                setEffectSwitch(v[0]);
              }
            }}
            onClose={() => {
              setOrderTypeTips(effectSwitch);
            }}
            onSelect={(v) => {
              setOrderTypeTips(v[0]);
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选工单类型</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true, message: "必须填写标题" }]}
          normalize={(v) => DisposeMobileText(v)}
          layout={"vertical"}
        >
          <TextArea autoSize placeholder="问题简述" />
        </Form.Item>
        <Form.Item
          name="customer_owner"
          label="客户所属"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择客户所属" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
          initialValue={["商业化事业部"]}
        >
          <Picker
            columns={[
              [
                { label: "商业化事业部", value: "商业化事业部" },
                { label: "其他", value: "其他" },
              ],
            ]}
            onConfirm={(v) => {
              if (v[0] === "商业化事业部") {
                setIsCustomer(true);
              } else {
                setIsCustomer(false);
              }
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选客户所属</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        {isCustomer ? (
          <Form.Item
            name="hospital_id"
            label="客户名称"
            trigger="onConfirm"
            validateTrigger="onConfirm"
            rules={[{ required: true, message: "必须填写客户名称" }]}
            normalize={(v) => DisposeForm(v)}
            onClick={(_, pickerRef: RefObject<PickerRef>) =>
              pickerRef.current?.open()
            }
          >
            <Picker
              loading={loading}
              columns={[
                filteredItems?.map((i) => ({
                  label: i.name,
                  value: i.id,
                })) || [],
              ]}
              onConfirm={(v) => {
                if (v[0]) {
                  setSelectedHospId(Number(v[0]));
                }
              }}
              title={
                <SearchBar
                  placeholder="搜索客户"
                  value={searchName}
                  onChange={(v) => {
                    setSearchName(v);
                  }}
                />
              }
            >
              {(items: (PickerColumnItem | null)[]) =>
                items[0] ? (
                  <FormItemDiv>{items[0]?.label}</FormItemDiv>
                ) : (
                  <FormItemPlaceholder>选择客户名称</FormItemPlaceholder>
                )
              }
            </Picker>
          </Form.Item>
        ) : (
          <Form.Item
            name="customer_name"
            label="客户名称"
            rules={[{ required: true, message: "必须填写客户名称" }]}
            normalize={(v) => DisposeMobileText(v)}
          >
            <Input
              style={{ "--text-align": "right" }}
              placeholder="输入客户名称"
            />
          </Form.Item>
        )}
        {!!!isCustomer ? ( // 其他
          <Form.Item
            name="product_id_list"
            label="影响产品"
            normalize={(v) => DisposeForm(v)}
            // rules={[{ required: true, message: "必须选择影响产品" }]}
            layout="vertical"
            // help={"点击模块可删除!"}
          >
            {productLoading ? (
              <Skeleton.Paragraph animated />
            ) : (
              <>
                {selectedValues.length ? (
                  <>
                    <Space wrap>
                      <Button
                        color="primary"
                        fill="outline"
                        onClick={() => {
                          setProductVisible(true);
                        }}
                        size="small"
                      >
                        <Space>
                          <AddOutline />
                          <span>添加产品线</span>
                        </Space>
                      </Button>
                      <Button
                        color="danger"
                        fill="outline"
                        onClick={() => {
                          setSelectedValues([]);
                        }}
                        size="small"
                      >
                        <Space>
                          <DeleteOutline />
                          <span>全部清空</span>
                        </Space>
                      </Button>
                    </Space>
                    <Divider />
                    <Selector
                      style={{
                        wordBreak: "break-all",
                      }}
                      columns={3}
                      multiple
                      options={
                        selectedValues
                          ? selectedValues.map((item) => ({
                              label: (
                                <>
                                  <Grid columns={1}>
                                    <Grid.Item>
                                      <span>{item.label}</span>
                                    </Grid.Item>
                                  </Grid>
                                  <Grid columns={1}>
                                    <Grid.Item style={{ textAlign: "center" }}>
                                      <Tag color="red" fill="outline">
                                        点击删除
                                      </Tag>
                                    </Grid.Item>
                                  </Grid>
                                </>
                              ),
                              value: `${item.value}`,
                            }))
                          : []
                      }
                      onChange={(val, extend) => {
                        const currentValue: any[] = selectedValues.filter(
                          (item) => item.value !== val[val.length - 1],
                        );
                        setSelectedValues(currentValue);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      color="primary"
                      fill="outline"
                      style={{ width: "100%" }}
                      onClick={() => {
                        setProductVisible(true);
                      }}
                      size="small"
                    >
                      <Space>
                        <AddOutline />
                        <span>添加产品线</span>
                      </Space>
                    </Button>
                  </>
                )}
              </>
            )}
          </Form.Item>
        ) : (
          <Form.Item
            name="product_id_list"
            label="影响产品"
            normalize={(v) => DisposeForm(v)}
            rules={[{ required: true, message: "必须选择影响产品" }]}
            layout="vertical"
            help={!!!selectedHospId ? "请先选择客户!" : "点击模块可删除!"}
          >
            {!!!selectedHospId ? null : productLoading ? (
              <Skeleton.Paragraph animated />
            ) : (
              <>
                {selectedValues.length ? (
                  <>
                    <Grid columns={1}>
                      <Grid.Item>
                        <span>当前已选择 {selectedValues.length}个产品线</span>
                      </Grid.Item>
                      <Grid.Item>
                        <Space wrap>
                          <Button
                            color="primary"
                            fill="outline"
                            onClick={() => {
                              setProductVisible(true);
                            }}
                            size="small"
                          >
                            <Space>
                              <AddOutline />
                              <span>修改产品线</span>
                            </Space>
                          </Button>
                          <Button
                            color="danger"
                            fill="outline"
                            onClick={() => {
                              setSelectedValues([]);
                            }}
                            size="small"
                          >
                            <Space>
                              <DeleteOutline />
                              <span>全部清空</span>
                            </Space>
                          </Button>
                        </Space>
                      </Grid.Item>
                    </Grid>
                    <Divider />
                    <Selector
                      style={{
                        wordBreak: "break-all",
                      }}
                      columns={3}
                      multiple
                      options={
                        selectedValues
                          ? selectedValues.map((item) => ({
                              label: (
                                <>
                                  <Grid columns={1}>
                                    <Grid.Item>
                                      <span>{item.label}</span>
                                    </Grid.Item>
                                  </Grid>
                                  <Grid columns={1}>
                                    <Grid.Item style={{ textAlign: "center" }}>
                                      <Tag color="red" fill="outline">
                                        点击删除
                                      </Tag>
                                    </Grid.Item>
                                  </Grid>
                                </>
                              ),
                              value: `${item.value}`,
                            }))
                          : []
                      }
                      onChange={(val, extend) => {
                        const currentValue: any[] = selectedValues.filter(
                          (item) => item.value !== val[val.length - 1],
                        );
                        setSelectedValues(currentValue);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      color="primary"
                      fill="outline"
                      style={{ width: "100%" }}
                      onClick={() => {
                        setProductVisible(true);
                      }}
                      size="small"
                    >
                      <Space>
                        <AddOutline />
                        <span>添加产品线</span>
                      </Space>
                    </Button>
                  </>
                )}
              </>
            )}
          </Form.Item>
        )}
        <CascadePicker
          title={
            <>
              <SearchBar
                placeholder="搜索产品分支版本"
                value={searchEffectProduct}
                onChange={(v) => {
                  setSearchEffectProduct(v);
                }}
              />
            </>
          }
          options={filteredOptions}
          visible={productVisible}
          loading={productLoading}
          onClose={() => {
            setProductVisible(false);
            setSearchEffectProduct("");
          }}
          onConfirm={(val, extend) => {
            const currentValue = {
              label: `${extend.items[0]?.label} ${
                extend.items[1]?.label || ""
              }`,
              value: `${val[0]}|${val[1] || ""}`,
            };
            const cur_list: any[] = selectedValues.filter(
              (item) => item.value.split("|")[0] !== `${val[0]}`,
            );
            cur_list.push(currentValue);
            setSelectedValues(cur_list);
          }}
        />
        <Form.Item
          name="description"
          label="反馈详情"
          rules={[{ required: true, message: "必须填写反馈详情" }]}
          normalize={(v) => DisposeQuill(v)}
          layout={"vertical"}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: mobileQuillToolbarOption,
            }}
          />
        </Form.Item>
        <Form.Item
          name="feedback_channel_id"
          label="反馈渠道"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择反馈渠道" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingFeedbackChannel || getFeedbackChannelIsIdle}
            columns={
              feedbackChannel
                ? [
                    feedbackChannel.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择反馈渠道</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="feedback_user"
          label="反馈人姓名"
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input
            style={{ "--text-align": "right" }}
            placeholder="填写反馈人姓名"
          />
        </Form.Item>
        <Form.Item
          name="contact"
          label="反馈人联系方式"
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input
            style={{ "--text-align": "right" }}
            placeholder="填写反馈人联系方式"
          />
        </Form.Item>
        <Form.Item
          name="feedback_time"
          label="反馈时间"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择反馈时间" }]}
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
        >
          <DatePicker precision="minute" tillNow max={now}>
            {(value) =>
              value ? (
                <FormItemDiv>{FormatTime(value)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择反馈时间</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>
        {/* 只有使用故障展示 */}
        {effectSwitch === 1 || effectSwitch === "1" ? (
          <>
            <Form.Item
              name="influence_function"
              label="影响功能"
              trigger="onConfirm"
              validateTrigger="onConfirm"
              rules={[{ required: true, message: "必须选择影响功能" }]}
              normalize={(v) => DisposeForm(v)}
              onClick={(_, pickerRef: RefObject<PickerRef>) =>
                pickerRef.current?.open()
              }
            >
              <Picker
                columns={[
                  [
                    { label: "核心功能", value: "核心功能" },
                    { label: "次要功能", value: "次要功能" },
                    { label: "附加功能", value: "附加功能" },
                  ],
                ]}
              >
                {(items: (PickerColumnItem | null)[]) =>
                  items[0] ? (
                    <FormItemDiv>{items[0]?.label}</FormItemDiv>
                  ) : (
                    <FormItemPlaceholder>选择影响功能</FormItemPlaceholder>
                  )
                }
              </Picker>
            </Form.Item>
            <Form.Item
              name="influence_sphere"
              label="影响范围"
              trigger="onConfirm"
              validateTrigger="onConfirm"
              rules={[{ required: true, message: "必须选择影响范围" }]}
              normalize={(v) => DisposeForm(v)}
              onClick={(_, pickerRef: RefObject<PickerRef>) =>
                pickerRef.current?.open()
              }
            >
              <Picker
                columns={[
                  [
                    { label: "完全不可用", value: "完全不可用" },
                    { label: "部分不可用", value: "部分不可用" },
                    { label: "不影响使用", value: "不影响使用" },
                  ],
                ]}
              >
                {(items: (PickerColumnItem | null)[]) =>
                  items[0] ? (
                    <FormItemDiv>{items[0]?.label}</FormItemDiv>
                  ) : (
                    <FormItemPlaceholder>选择影响范围</FormItemPlaceholder>
                  )
                }
              </Picker>
            </Form.Item>
          </>
        ) : (
          <></>
        )}
        <Form.Item
          name="is_additional_recording"
          label="是否为补录"
          childElementPosition="right"
          rules={[{ required: true, message: "必须选择是否为补录" }]}
          initialValue={false}
        >
          <Switch
            uncheckedText="否"
            checkedText="是"
            onChange={setIsAdditional}
          />
        </Form.Item>
        {isAdditional ? (
          <>
            <Form.Item
              name="debug_info"
              label="定位及补充记录"
              rules={[{ required: true, message: "必须填写定位及补充记录" }]}
              normalize={(v) => DisposeQuill(v)}
              layout={"vertical"}
            >
              {/* <MobileReactQuill /> */}
              <ReactQuill
                theme={"snow"}
                modules={{
                  toolbar: mobileQuillToolbarOption,
                }}
              />
            </Form.Item>
            <Form.Item
              name="solution_info"
              label="操作及解决"
              rules={[{ required: true, message: "必须填写操作及解决" }]}
              normalize={(v) => DisposeQuill(v)}
              layout={"vertical"}
            >
              {/* <MobileReactQuill /> */}
              <ReactQuill
                theme={"snow"}
                modules={{
                  toolbar: mobileQuillToolbarOption,
                }}
              />
            </Form.Item>
            <Form.Item
              name="is_deal_with_on_site"
              label="是否现场处理"
              childElementPosition="right"
              rules={[{ required: true, message: "必须选择是否现场处理" }]}
              initialValue={false}
            >
              <Switch uncheckedText="否" checkedText="是" />
            </Form.Item>
            <Form.Item
              name="response_time"
              label="响应时长 (min)"
              childElementPosition="right"
            >
              <Stepper min={0} />
            </Form.Item>
            <Form.Item
              name="issue_type_id"
              label="反馈类型"
              trigger="onConfirm"
              validateTrigger="onConfirm"
              rules={[{ required: true, message: "必须选择反馈类型" }]}
              normalize={(v) => DisposeForm(v)}
              onClick={(_, pickerRef: RefObject<PickerRef>) =>
                pickerRef.current?.open()
              }
            >
              <Picker
                loading={gettingIssuesTypeList || getIssuesTypeListIsIdle}
                columns={
                  issuesTypeList
                    ? [
                        issuesTypeList.map((i) => ({
                          label: i.name,
                          value: i.id.toString(),
                        })),
                      ]
                    : []
                }
                onConfirm={(v: any[]) => {
                  if (v) {
                    setSelectedIssuesTypeId(v[0]);
                    form.setFieldValue("issue_reason_id", undefined);
                  }
                }}
              >
                {(items: (PickerColumnItem | null)[]) =>
                  items[0] ? (
                    <FormItemDiv>{items[0]?.label}</FormItemDiv>
                  ) : (
                    <FormItemPlaceholder>选择反馈类型</FormItemPlaceholder>
                  )
                }
              </Picker>
            </Form.Item>

            <Form.Item
              name="issue_reason_id"
              label="反馈归因"
              trigger="onConfirm"
              validateTrigger="onConfirm"
              rules={[{ required: true, message: "必须选择反馈归因" }]}
              normalize={(v) => DisposeForm(v)}
              onClick={(_, pickerRef: RefObject<PickerRef>) =>
                pickerRef.current?.open()
              }
              disabled={!!!selectedIssuesTypeId}
              help={"请先选择反馈类型!"}
            >
              <Picker
                loading={gettingIssuesReasonList || getIssuesReasonListIsIdle}
                columns={
                  issuesReasonList
                    ? [
                        issuesReasonList.map((i) => ({
                          label: i.name,
                          value: i.id.toString(),
                        })),
                      ]
                    : []
                }
              >
                {(items: (PickerColumnItem | null)[]) =>
                  items[0] ? (
                    <FormItemDiv>{items[0]?.label}</FormItemDiv>
                  ) : (
                    <FormItemPlaceholder>选择反馈归因</FormItemPlaceholder>
                  )
                }
              </Picker>
            </Form.Item>
            <Form.Item
              name="service_order_id"
              className="adm-form-item-label-attachment"
              label="服务确认单"
              normalize={(v) => DisposeForm(v)}
              layout={"vertical"}
            >
              <Upload
                headers={UploadHeaders}
                maxCount={1}
                className="upload-list-inline"
                action={ADD_FILE}
                listType="picture"
                fileList={departureConfirmationfile}
                beforeUpload={MobileLimitUpload}
                onChange={(info) =>
                  handleUpload(info, setDepartureConfirmationfile)
                }
              >
                {departureConfirmationfile.length ? null : (
                  <Button color="primary" fill="outline">
                    <Space>
                      <AddOutline />
                      <span>点击上传</span>
                    </Space>
                  </Button>
                )}
              </Upload>
            </Form.Item>
          </>
        ) : null}
        <Form.Item
          name="attachment"
          className="adm-form-item-label-attachment"
          label="附件 (数量限制10个)"
          normalize={(v) => DisposeForm(v)}
          layout={"vertical"}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={10}
            className="upload-list-inline"
            action={ADD_FILE}
            listType="picture"
            fileList={fileList}
            beforeUpload={MobileLimitUpload}
            onChange={(info) => handleUpload(info, setFileList)}
          >
            {fileList.length >= 10 ? null : (
              <Button color="primary" fill="outline">
                <Space>
                  <AddOutline />
                  <span>点击上传</span>
                </Space>
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </Popup>
  );
};
