import { useEffect, RefObject } from "react";
import { Form, Button, TextArea, Radio, Picker, Toast } from "antd-mobile";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { useDebounceFn } from "ahooks";
import ReactQuill from "react-quill";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { GET_DEPARTMENT_USERS } from "lib/api/issues";
import {
  BREAK_DETAIL_TYPE,
  ORDER_DEPARTMENT_TYPE,
} from "lib/interface/breaklist";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { DisposeQuill, DisposeForm } from "utils";

import { useBreakDetailUpgrade } from "authenticated-app/hooks/breaklist/trans/useBreakDetailUpgrade";
import { editQuillToolbarOption } from "authenticated-app/pc/components/EditQuill/option";

import "authenticated-app/pc/components/EditQuill/quill.snow.css";

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

export const BreakDetailUpgradeModal = ({
  onTrans,
  next,
  department,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: number[];
  department: number[];
  detailInfo: BREAK_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useBreakDetailUpgrade();

  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getDepartmentList,
    data: departmentList,
    isIdle: getDepartmentListIdle,
    isLoading: gettingDepartmentList,
  } = useAsync<ORDER_DEPARTMENT_TYPE[]>();
  const getDepartmentListHandle = () => {
    getDepartmentList(client(GET_DEPARTMENT_USERS(department)));
  };
  const { run: getDepartmentListDebounce } = useDebounceFn(
    getDepartmentListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      up_user_id: value?.up_user_id
        ? parseInt(value?.up_user_id[0])
        : undefined,
      next_process: next,
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        up_user_id: undefined,
        description: detailInfo?.description,
        is_on_site: detailInfo?.is_on_site,
        remote_info: detailInfo?.remote_info,
      });
      getDepartmentListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <EditPopup visible={isOpen} submitting={false} setVisible={close}>
      <Form
        form={form}
        onFinish={onFinish}
        {...layout}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={false}
          >
            提交
          </Button>
        }
      >
        <Form.Header>升级工单</Form.Header>
        <Form.Item
          name="up_user_id"
          label="升级至"
          rules={[{ required: true, message: "必须指定升级人" }]}
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={getDepartmentListIdle || gettingDepartmentList}
            columns={
              departmentList
                ? [
                    departmentList.map((i) => ({
                      label: `${i.user_name} (${i.user_job})`,
                      value: i.user_id,
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>指定升级人</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="description"
          label="反馈详情"
          rules={[{ required: true, message: "必须填写反馈详情" }]}
          normalize={(v) => DisposeQuill(v)}
          layout="vertical"
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
            }}
          />
        </Form.Item>
        <Form.Item
          name="is_on_site"
          label="是否在现场"
          rules={[{ required: true, message: "必须选择是否在现场" }]}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="remote_info"
          label="远程信息"
          rules={[{ required: true, message: "必须填写远程信息" }]}
          layout="vertical"
        >
          <TextArea placeholder="填写远程信息" autoSize />
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
