import {
  BreakInfoValueType,
  BreakProcessValueType as BarType,
  BREAK_ISSUES_PROCESS_LIST_TYPE,
  BREAK_ISSUES_PROCESS_STATISTICS_TYPE,
  BaseValueType,
} from "../../../interface";
import { PredictType } from "authenticated-app/pc/customer/rate-detail/interface";
import { MyBarChartType } from "../../../chart/bar-chart";
import { BarSeriesOption } from "echarts/charts";
import { MyPieChartType } from "../../../chart/pie-chart";

const GetType = (data: BreakInfoValueType[]) => {
  let tmpType: Map<string, { data: number }> = new Map();
  for (let r of data) {
    tmpType.set(r.month, { data: 0 });
  }
  return tmpType;
};

export const GetBreakBar = (data: BreakInfoValueType[]) => {
  let tmpType = GetType(data);

  let tmpData: MyBarChartType = {
    xData: [],
    series: [],
  };

  data.forEach((item) => {
    tmpType.get(item.month)!.data += item.count;
  });

  let tmpSeriesData: number[] = [];
  tmpType.forEach((v, k) => {
    tmpData.xData?.push(k);
    tmpSeriesData.push(v.data);
  });
  tmpData.series?.push({ data: tmpSeriesData, type: "bar" });
  return tmpData;
};
const GetMultiType = (data: BarType[]) => {
  let tmpType: Map<
    string,
    {
      name: string;
      type: string;
      stack: string;
      data: number[];
    }
  > = new Map();
  for (let r of data) {
    if (r.status?.length) {
      r.status?.forEach((i) => {
        if (!tmpType.has(i.name)) {
          tmpType.set(i.name, {
            name: i.name,
            type: "bar",
            stack: "value",
            data: [],
          });
        }
      });
      break;
    } else if (r.processingDepartment?.length) {
      r.processingDepartment?.forEach((i) => {
        if (!tmpType.has(i.name)) {
          tmpType.set(i.name, {
            name: i.name,
            type: "bar",
            stack: "value",
            data: [],
          });
        }
      });
      break;
    }
  }
  return tmpType;
};

export const GetProcessNum = (data: BarType[]) => {
  let tmpType = GetMultiType(data);
  let tmpData: MyBarChartType = {
    xData: [],
    series: [],
  };

  data.forEach((item) => {
    if (item.status?.length) {
      if (item.month) tmpData.xData?.push(item.month);
      item.status?.forEach((i) => {
        tmpType.get(i.name)?.data.push(i.value);
      });
    }
  });

  tmpType.forEach((t) => {
    tmpData.series?.push(t as BarSeriesOption);
  });
  return tmpData;
};

export const GetBreakProcessTotalNum = (
  data: BREAK_ISSUES_PROCESS_STATISTICS_TYPE,
) => {
  let tmpData: MyBarChartType = {
    xData: [],
    series: [],
  };
  let tmpSeriesData: number[] = [];
  data.statistics.forEach((item) => {
    if (item) {
      tmpData.xData?.push(item.name);
      tmpSeriesData.push(item.count);
    }
  });
  tmpData.series?.push({ data: tmpSeriesData, type: "bar" });
  return tmpData;
};

export const GetBreakProcessDepartmentNum = (
  data: BREAK_ISSUES_PROCESS_STATISTICS_TYPE,
) => {
  let tmpType = GetMultiType(data.statistics);
  let tmpData: MyBarChartType = {
    xData: [],
    series: [],
  };
  data.statistics.forEach((item) => {
    if (item.processingDepartment.length) {
      tmpData.xData?.push(item.name);
      item.processingDepartment.forEach((i) => {
        tmpType.get(i.name)?.data.push(i.value);
      });
    }
  });
  tmpType.forEach((t) => {
    tmpData.series?.push(t as BarSeriesOption);
  });
  return tmpData;
};

export const GetBreakProcessDepartmentPieNum = (
  data: BREAK_ISSUES_PROCESS_STATISTICS_TYPE,
) => {
  let tmpData: MyPieChartType = {
    data: [],
  };
  data.percentage.forEach((item) => {
    tmpData.data.push({
      name: item.name,
      value: item.value,
    });
  });
  return tmpData;
};

export const GetBreakFeedbackPieNum = (data: BaseValueType[]) => {
  let tmpData: MyPieChartType = {
    data: [],
  };
  data.forEach((item) => {
    tmpData.data.push({
      name: item.name,
      value: item.value,
    });
  });
  return tmpData;
};

const GetBaseType = (data: BaseValueType[]) => {
  let tmpType: Map<string, { data: number }> = new Map();
  for (let r of data) {
    tmpType.set(r.name, { data: 0 });
  }
  return tmpType;
};

export const GetProductLineBarNum = (data: BaseValueType[]) => {
  let tmpType = GetBaseType(data);

  let tmpData: MyBarChartType = {
    xData: [],
    series: [],
  };

  data.forEach((item) => {
    tmpType.get(item.name)!.data += item.value;
  });

  let tmpSeriesData: number[] = [];
  tmpType.forEach((v, k) => {
    tmpData.xData?.push(k);
    tmpSeriesData.push(v.data);
  });
  tmpData.series?.push({ data: tmpSeriesData, type: "bar" });
  return tmpData;
};
