import { useState } from "react";
import {
  Ellipsis,
  List,
  NavBar,
  Popover,
  Popup,
  SafeArea,
  Space,
  TabBar,
  Tag,
} from "antd-mobile";
import { useMount } from "ahooks";
import { LinkOutline, UserOutline } from "antd-mobile-icons";

import { hosStatus, statusColor } from "utils";
import {
  DetailBodyComponent,
  baseKey,
  clientKey,
  remoteKey,
  tabs,
} from "./tabs";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import {
  detailNameStorageKey,
  detailStatusStorageKey,
  detailCodeStorageKey,
} from "..";
import { useCustomerDetail } from "authenticated-app/hooks/customer/useCustomerDetail";

export const titleHeight = "45px";

export const MobileCustomerDetailPopup = () => {
  const [isOpen, , close] = useCustomerDetail();

  const [{ customerTab }, setTab] = useUrlQueryParam(["customerTab"]);

  const [rightPopupVisible, setRightPopupVisible] = useState(false);

  const hospName =
    window.sessionStorage.getItem(detailNameStorageKey) || "客户服务系统";
  const hospStatus =
    window.sessionStorage.getItem(detailStatusStorageKey) || hosStatus.unknown;
  const hospCode =
    window.sessionStorage.getItem(detailCodeStorageKey) || hosStatus.unknown;

  const changeRoute = (tabKey: string, isClosePopup: boolean = true) => {
    setTab({ customerTab: tabKey });
    if (isClosePopup) {
      setRightPopupVisible(false);
    }
  };

  const selectedColor = (isSelected: boolean) =>
    isSelected ? "var(--adm-color-primary)" : undefined;

  useMount(() => {
    if (!!!customerTab) {
      setTab({ customerTab: baseKey });
    }
  });

  const [visible, setVisible] = useState(false);

  const showPopup = () => {
    setVisible(true);
  };

  const hidePopup = () => {
    setVisible(false);
  };
  return (
    <div>
      <Popup
        visible={isOpen}
        onClose={hidePopup}
        destroyOnClose
        bodyStyle={{ height: "100vh" }}
      >
        <div className="popup-content">
          <div className={"customer-detail-top"}>
            <NavBar
              style={{ lineHeight: titleHeight, "--height": titleHeight }}
              onBack={() => {
                close();
                // setTab({ customerTab: baseKey });
              }}
              // right={
              //   <div
              //     style={{ fontSize: 24 }}
              //     onClick={() => setRightPopupVisible(true)}
              //   >
              //     <MoreOutline
              //       color={selectedColor(otherKeyList.indexOf(tabname) > -1)}
              //     />
              //   </div>
              // }
            >
              <Popover
                content={hospStatus || "未知状态"}
                trigger="click"
                mode="dark"
              >
                <Space>
                  <Tag
                    round
                    color={
                      statusColor.get(hospStatus as hosStatus) || "#5B5B5B"
                    }
                    style={{
                      margin: 0,
                      padding: "2px",
                      transform: "translateY(-2px)",
                    }}
                  />
                  <Ellipsis
                    direction="end"
                    content={hospName}
                    style={{ fontSize: 18, lineHeight: titleHeight }}
                  />
                </Space>
              </Popover>
            </NavBar>
          </div>
          <div style={{ overflowY: "scroll" }}>
            <div className={"customer-detail-body"}>
              {DetailBodyComponent(customerTab, hospName, hospCode)}
            </div>
          </div>
          <div className="customer-tabbar">
            <TabBar
              defaultActiveKey={customerTab}
              onChange={(key) => changeRoute(key, false)}
              activeKey={customerTab}
              safeArea={true}
            >
              {tabs.map((item) => (
                <TabBar.Item
                  key={item.key}
                  icon={item.icon}
                  title={item.title}
                />
              ))}
            </TabBar>
          </div>
          <SafeArea position="bottom" />
          <Popup
            visible={rightPopupVisible}
            onMaskClick={() => {
              setRightPopupVisible(false);
            }}
            position="right"
            bodyStyle={{ width: "60vw" }}
          >
            <List header={"点击跳转"}>
              <List.Item
                key={remoteKey}
                prefix={<LinkOutline />}
                onClick={() => changeRoute(remoteKey)}
                style={{ color: selectedColor(customerTab === remoteKey) }}
              >
                远程
              </List.Item>
              <List.Item
                key={clientKey}
                prefix={<UserOutline />}
                onClick={() => changeRoute(clientKey)}
                style={{ color: selectedColor(customerTab === clientKey) }}
              >
                客户端
              </List.Item>
              {/* <List.Item
            key={statKey}
            prefix={<PieOutline />}
            onClick={() => changeRoute(statKey)}
            style={{ color: selectedColor(tab === statKey) }}
          >
            统计页
          </List.Item> */}
            </List>
          </Popup>
        </div>
      </Popup>
    </div>
  );
};
