import { Dispatch, SetStateAction, useEffect } from "react";
import { Modal, Form, message, Select } from "antd";

import { useHttp } from "utils/http";
import { DisposeForm, HospSelectFilter } from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { IssueDepartmentType } from "../interface";
import { useDebounceFn, useMount } from "ahooks";
import { GET_USER_LIST } from "../api";
import { IUser } from "authenticated-app/pc/setting/customer-setting/interface";

export const DepartmentSettingEdit = ({
  title,
  edittingInfo,
  submitAPI,
  visible,
  setVisible,
  reload,
}: {
  title: string;
  edittingInfo: IssueDepartmentType | undefined;
  submitAPI: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getUserList,
    isIdle: getUserListIsIdle,
    isLoading: gettingUserList,
    data: userList,
  } = useAsync<IUser[]>();
  const getUserListHandle = () => {
    getUserList(client(GET_USER_LIST));
  };
  const { run: getUserListDebounce } = useDebounceFn(getUserListHandle, {
    wait: 200,
  });

  const { run: update, isLoading: updating } = useAsync();

  const onSubmit = (values: any) => {
    let id = edittingInfo?.id;
    if (id) {
      update(
        client(submitAPI, {
          method: "POST",
          data: [
            {
              department_id: id,
              user_ids: values.user_ids.map((i: any) =>
                typeof i === "object" ? (i.value ? i.value : []) : i,
              ),
            },
          ],
        }),
      )
        .then(() => {
          message.success("修改成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    } else message.error("无法请求修改，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    form.setFieldsValue({
      user_ids: edittingInfo?.users.map((i) => ({
        label:
          i.user_status === "3" ? (
            <span style={{ color: "red" }}>{i.user_name}(已离职)</span>
          ) : (
            i.user_name
          ),
        value: i.id,
      })),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edittingInfo]);

  useMount(getUserListDebounce);

  const loading = gettingUserList || getUserListIsIdle;

  return (
    <Modal
      title={`修改${title}项`}
      destroyOnClose
      open={visible}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      onOk={form.submit}
      confirmLoading={updating}
      centered
    >
      <div style={{ maxHeight: "500px", overflowY: "auto" }}>
        <Form
          form={form}
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 22 }}
          onFinish={onSubmit}
        >
          <Form.Item label="角色" normalize={(v) => DisposeForm(v)}>
            {edittingInfo?.name}
          </Form.Item>
          <Form.Item
            name="user_ids"
            label="人员"
            normalize={(v) => DisposeForm(v)}
          >
            <Select
              size="middle"
              mode="multiple"
              loading={loading}
              showSearch
              filterOption={HospSelectFilter}
              options={userList?.map((i) => ({
                label: i.userName,
                value: i.id,
              }))}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
