import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-components";
import { Button, Col, Row, Select, Space, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDebounceFn, useMount } from "ahooks";

import { ProductServiceLevelType } from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import { HosNodesType } from "authenticated-app/pc/customer/customer-list/interface";
import { hosStatus } from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { GET_HOSP_NODES, GET_SERVICE_LEVEL } from "lib/api";
import { GET_PRODUCT_STAT } from "../api";
import { VolumeStatisticsType } from "../interface";
import { MyBarChart, MyBarChartType } from "../chart/bar-chart";
import { GetCityStatus } from "./calculate-value/city-status";
import { GetStatus } from "./calculate-value/status";
import { GetCityLevel } from "./calculate-value/city-level";
import { GetLevel } from "./calculate-value/level";
import { useWindowSize } from "utils/hooks/useWindowSize";

const { Option } = Select;

export const DataSummary = ({ type }: { type: "product" | "hospital" }) => {
  const client = useHttp();
  const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [searchArea, setSearchArea] = useState<number>();
  const [searchUserStatus, setSearchUserStatus] = useState<string>();
  const [searchLevel, setSearchLevel] = useState<string>();

  const [cityStatus, setCityStatus] = useState<MyBarChartType>();
  const [statusAll, setStatusAll] = useState<MyBarChartType>();
  const [cityLevel, setCityLevel] = useState<MyBarChartType>();
  const [levelAll, setLevelAll] = useState<MyBarChartType>();

  const { height } = useWindowSize();

  const {
    run: getHosNodes,
    isLoading: gettingNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };
  const { run: getHosNodesDebounce } = useDebounceFn(getHosNodesHandle, {
    wait: 200,
  });

  const {
    run: getStatistics,
    isLoading: gettingStatistics,
    data: statistics,
  } = useAsync<VolumeStatisticsType[]>();
  const getStatisticsHandle = () => {
    getStatistics(
      client(GET_PRODUCT_STAT, {
        data: {
          type,
          area_id: searchArea,
          status: searchUserStatus,
          level: searchLevel,
        },
      }),
    );
  };
  const { run: getStatisticsDebounce } = useDebounceFn(getStatisticsHandle, {
    wait: 200,
  });

  const {
    run: getServiceLevelTypeList,
    isLoading: gettingServiceLevelTypeList,
    data: serviceLevelTypeList,
  } = useAsync<ProductServiceLevelType[]>();
  const getServiceLevelTypeListFunc = () => {
    getServiceLevelTypeList(client(GET_SERVICE_LEVEL));
  };
  const { run: getServiceLevelTypeListDebounce } = useDebounceFn(
    getServiceLevelTypeListFunc,
    { wait: 200 },
  );

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.nodeLevel === 1 && item.nodeName !== "海外") {
        areaListTMP?.push(item);
      }
    });
    setAreaList(areaListTMP);
  }, [hosNodes]);

  useEffect(() => {
    if (statistics) {
      setCityStatus(GetCityStatus(statistics));
      setStatusAll(GetStatus(statistics));
      setCityLevel(GetCityLevel(statistics));
      setLevelAll(GetLevel(statistics));
    }
  }, [statistics]);

  useMount(() => {
    getHosNodesDebounce();
    getStatisticsDebounce();
    getServiceLevelTypeListDebounce();
  });

  return (
    <div style={{ height: "100%", minWidth: 988 }}>
      <Spin spinning={gettingStatistics}>
        <PageHeader
          style={{ padding: 0 }}
          extra={
            <Space>
              <Select
                allowClear
                style={{ width: 120 }}
                placeholder="区域"
                disabled={gettingNodes || gettingStatistics}
                loading={gettingNodes || gettingStatistics}
                value={searchArea}
                onChange={(nodeId) => setSearchArea(nodeId)}
              >
                {areaList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Select
                allowClear
                style={{ width: 120 }}
                placeholder="状态"
                value={searchUserStatus}
                loading={gettingStatistics}
                disabled={gettingStatistics}
                onChange={(status) => setSearchUserStatus(status)}
              >
                {[
                  hosStatus.pending,
                  hosStatus.using,
                  hosStatus.stop,
                  hosStatus.out,
                ].map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <Select
                allowClear
                loading={gettingServiceLevelTypeList || gettingStatistics}
                disabled={gettingServiceLevelTypeList || gettingStatistics}
                style={{ width: 120 }}
                placeholder="等级"
                value={searchLevel}
                onChange={(level) => setSearchLevel(level)}
              >
                {serviceLevelTypeList?.map((i, index) => (
                  <Option key={index} value={i?.name}>
                    {i?.name}
                  </Option>
                ))}
              </Select>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                loading={gettingStatistics}
                disabled={gettingStatistics}
                onClick={getStatisticsHandle}
              >
                查询
              </Button>
            </Space>
          }
        />
        <Row
          style={{ height: (height - 256) / 2, minHeight: 380, width: "100%" }}
        >
          <Col span={20}>
            <MyBarChart Data={cityStatus} />
          </Col>
          <Col span={4}>
            <MyBarChart Data={statusAll} />
          </Col>
        </Row>
        <Row
          style={{ height: (height - 256) / 2, minHeight: 380, width: "100%" }}
        >
          <Col span={20}>
            <MyBarChart Data={cityLevel} />
          </Col>
          <Col span={4}>
            <MyBarChart Data={levelAll} />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
