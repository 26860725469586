import { IdType } from "lib/api";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useCompetitiveDetail = () => {
  const [{ openCompetitiveDetail }, setOpenCompetitiveDetail] =
    useUrlQueryParam(["openCompetitiveDetail", "detailId"]);

  const open = (id: IdType) =>
    setOpenCompetitiveDetail({ openCompetitiveDetail: true, detailId: id });
  const close = () =>
    setOpenCompetitiveDetail({
      openCompetitiveDetail: undefined,
      detailId: undefined,
    });

  return [openCompetitiveDetail === "true", open, close] as const;
};
