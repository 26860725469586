import { useState, Fragment } from "react";
import {
  Button,
  Descriptions,
  Space,
  message,
  Popconfirm,
  Typography,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ProTable, ProColumns } from "@ant-design/pro-table";

import { useHttp } from "utils/http";

import { RemoteRetainType } from "../interface";
import { RetainAPI } from "../../../interface";
import { RemoteRetainInfoAdd } from "./add";
import { RemoteRetainInfoEdit } from "./edit";

const { Paragraph, Link } = Typography;

const RemoteRetainInfo = ({
  hospID,
  retain,
  RetainAPI,
  reload,
}: {
  hospID: number | null;
  productID?: string | undefined;
  retain: RemoteRetainType[] | undefined;
  RetainAPI: RetainAPI;
  reload: () => void;
}) => {
  // 必要信息state
  const [addRetainVisible, setAddRetainVisible] = useState(false);
  const [editRetainInfoVisible, setEditRetainInfoVisible] = useState(false);
  const [editRetainInfo, setEditRetainInfo] = useState<RemoteRetainType>();

  const client = useHttp();

  const delRetain = (id: number) => {
    if (id)
      client(RetainAPI.DEL_RETAIN(id), { method: "DELETE" })
        .then(() => {
          message.success("删除成功！");
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("删除失败，请联系管理员！");
        });
  };

  const columns: ProColumns<RemoteRetainType>[] = [
    {
      title: "标题",
      dataIndex: "title",
    },
    {
      title: "简要描述",
      dataIndex: "description",
    },
    {
      title: "备注",
      dataIndex: "remark",
    },
    {
      title: "对接人",
      dataIndex: "docking_user",
    },
    {
      title: "联系方式",
      dataIndex: "contact_info",
    },

    {
      title: "附件",
      dataIndex: "attachment",
      render: (text, record) => (
        <ul style={{ margin: 0 }}>
          {record?.attachment?.map((item, index) => (
            <li key={index}>
              <Link href={item.attachment_addr} download>
                {item.attachment_name}
              </Link>
            </li>
          ))}
        </ul>
      ),
      width: 250,
    },
    {
      title: "修改人",
      dataIndex: "update_user",
      width: 100,
    },
    {
      title: "修改时间",
      dataIndex: "update_date",
      width: 100,
    },
    {
      title: "操作",
      valueType: "option",
      width: 100,
      render: (text, record, _, action) => [
        <Space>
          <Button
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            loading={editRetainInfoVisible && record.id === editRetainInfo?.id}
            onClick={() => {
              setEditRetainInfo(record);
              setEditRetainInfoVisible(true);
            }}
          />
          <Popconfirm
            title={`确认删除留存信息【${record.title}】的信息？`}
            onConfirm={() => delRetain(record.id)}
            okText="确认"
            cancelText="取消"
            icon={<ExclamationCircleOutlined />}
            style={{ color: "red" }}
          >
            <Button
              danger
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>,
      ],
    },
  ];

  const RetainInfoDescItem = (item: RemoteRetainType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`remoteRetain${key}`}>
        <Descriptions.Item label={title("标题")} className={className()}>
          {item?.title || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("简要描述")} className={className()}>
          {item?.description || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("对接人")} className={className()}>
          {item?.docking_user || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("联系方式")} className={className()}>
          {item?.contact_info || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("备注")} className={className()}>
          {item?.remark || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("附件")} className={className()}>
          <Paragraph>
            <ul>
              {item?.attachment?.map((item, index) => (
                <li key={index}>
                  <Link href={item.attachment_addr} download>
                    {item.attachment_name}
                  </Link>
                </li>
              ))}
            </ul>
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item label={title("修改人")} className={className()}>
          {item?.update_user}
        </Descriptions.Item>
        <Descriptions.Item label={title("修改时间")} className={className()}>
          {item?.update_date}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("操作")}
          className={className()}
        >
          <Space>
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              loading={editRetainInfoVisible && item.id === editRetainInfo?.id}
              onClick={() => {
                setEditRetainInfo(item);
                setEditRetainInfoVisible(true);
              }}
            />
            <Popconfirm
              title={`确认删除留存信息【${item.title}】的信息？`}
              onConfirm={() => delRetain(item.id)}
              okText="确认"
              cancelText="取消"
              icon={<ExclamationCircleOutlined />}
              style={{ color: "red" }}
            >
              <Button
                danger
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <ProTable<RemoteRetainType>
        size="small"
        columns={columns}
        rowKey="id"
        dataSource={retain}
        pagination={false}
        search={false}
        dateFormatter="string"
        options={{
          reload: reload,
        }}
        toolbar={{
          title: <span style={{ fontWeight: "bold" }}>必要信息留存</span>,
          actions: [
            <Button
              key="add"
              type="default"
              icon={<PlusOutlined />}
              onClick={() => setAddRetainVisible(true)}
            >
              新增
            </Button>,
          ],
          settings: [],
        }}
        bordered={retain?.length ? true : false}
      />
      {/* <Descriptions
        column={9}
        bordered={retain?.length ? true : false}
        title="必要信息留存"
        layout="vertical"
        extra={
          <Button
            loading={addRetainVisible}
            icon={<PlusOutlined />}
            onClick={() => setAddRetainVisible(true)}
          >
            新增
          </Button>
        }
      >
        {retain?.map((item, index) => RetainInfoDescItem(item, index))}
      </Descriptions> */}
      <RemoteRetainInfoAdd
        hospID={hospID}
        ADD_RETAIN={RetainAPI.ADD_RETAIN}
        visible={addRetainVisible}
        setVisible={setAddRetainVisible}
        reload={reload}
      />
      <RemoteRetainInfoEdit
        editingItem={editRetainInfo}
        UPDATE_RETAIN={RetainAPI.UPDATE_RETAIN}
        visible={editRetainInfoVisible}
        setVisible={setEditRetainInfoVisible}
        reload={reload}
      />
    </Fragment>
  );
};

export default RemoteRetainInfo;
