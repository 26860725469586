import { useEffect } from "react";
import { Dropdown, Form, Radio, Space } from "antd-mobile";
import type { FormInstance } from "rc-field-form/es";
import useUrlState from "@ahooksjs/use-url-state";

import { useMount } from "utils";

import "./index.css";
import { getSelectParams } from ".";
import { useAuth } from "context/auth";

export const BreaklistSearchDropdown = ({
  form,
  onSubmit,
}: {
  form: FormInstance<any>;
  onSubmit: (params: any, type?: string) => void;
}) => {
  const [{ search_type, select_type }, setSearch] = useUrlState({
    search_type: undefined,
    select_type: undefined,
  });
  const { user } = useAuth();

  useEffect(() => {
    if (search_type) {
      form.setFieldsValue({ search_type });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search_type]);

  useMount(() => {
    if (!!!search_type) {
      setSearch((s) => ({ search_type: "customer_name" }));
    }
  });

  return (
    <Dropdown className="break-search-dropdown" closeOnClickAway>
      <Dropdown.Item key="search" title={""}>
        <div>
          <Form
            mode="card"
            form={form}
            onValuesChange={(_, values) => {
              const { search_type } = values;
              const params = { ...getSelectParams(select_type, user) };
              onSubmit({ ...params }, search_type);
            }}
          >
            <Form.Header>搜索类型</Form.Header>
            <Form.Item
              name={"search_type"}
              initialValue={"customer_name"}
              rules={[{ required: true, message: "至少选择一个" }]}
            >
              <Radio.Group onChange={(v) => setSearch({ search_type: v })}>
                <Space direction="vertical" block>
                  <Radio block value={"customer_name"}>
                    客户名称
                  </Radio>
                  <Radio block value={"dc"}>
                    DC号码
                  </Radio>
                  <Radio block value={"delivery_user_name"}>
                    交付负责人
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Header>类型</Form.Header>
          </Form>
        </div>
      </Dropdown.Item>
    </Dropdown>
  );
};
