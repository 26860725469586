import { useRef } from "react";
import { PageHeader } from "@ant-design/pro-components";
import { Spin, Avatar } from "antd";
import { useAuth } from "context/auth";
import { Comment } from "@ant-design/compatible";
import { Divider } from "antd";
import { MentionQuill } from "./MentionQuill/MentionQuill";
import { BREAK_DETAIL_TYPE, COMMENT_LIST } from "lib/interface/breaklist";
import "./index.css";
export const Comments = ({
  title,
  relatedFiename,
  detailInfo,
  reload,
}: {
  title: string;
  relatedFiename?: string;
  detailInfo?: BREAK_DETAIL_TYPE | null;
  reload: () => void;
}) => {
  const fullRef = useRef<HTMLDivElement>(null);
  const issues_id = detailInfo?.id;
  const { user } = useAuth();
  const ExampleComment: React.FC<{
    comments: COMMENT_LIST[];
  }> = ({ comments }) => {
    return comments.map((item) => (
      <Comment
        key={item.id}
        className={item.user_name ? "user-comment " : "no-user-comment"}
        author={item.user_name}
        datetime={item.update_time}
        avatar={
          item.avatar ? (
            <Avatar src={item.avatar} alt={item.user_name} />
          ) : undefined
        }
        content={
          <MentionQuill
            name={"comment"}
            info={item.comment}
            isCanChange={false}
            relatedFiename={relatedFiename}
            issues_id={issues_id}
            type={"update"}
            comment_id={item.id}
            user_id={item.user_id}
            reload={reload}
          />
        }
      >
        {item.children && item.children.length > 0 && (
          <div style={{ marginLeft: "15px" }}>
            <ExampleComment comments={item.children} />
          </div>
        )}
      </Comment>
    ));
  };

  return detailInfo ? (
    <>
      <PageHeader
        className="page-header"
        subTitle={<span className="page-header-span">{title}</span>}
        style={{ margin: "0 0 0 0", padding: "0 0 0 0" }}
      />
      <>
        <div ref={fullRef} className={"editting-info"}>
          <Comment
            avatar={
              user?.avatar ? (
                <Avatar src={user?.avatar} alt={user?.userName} />
              ) : undefined
            }
            author={user?.userName}
            content={
              <div>
                <MentionQuill
                  name={"comment"}
                  info={""}
                  isCanChange={true}
                  issues_id={issues_id}
                  type={"add"}
                  reload={reload}
                />
              </div>
            }
          />
          <h3 style={{ margin: "2px" }}>{detailInfo?.comment_length} 条评论</h3>
          {detailInfo?.comment_list ? (
            <ExampleComment comments={detailInfo.comment_list} />
          ) : (
            <></>
          )}
          <Divider />
        </div>
      </>
    </>
  ) : (
    <Spin spinning>加载中</Spin>
  );
};
