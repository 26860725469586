import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Form,
  Input,
  Picker,
  PickerRef,
  Space,
  TextArea,
  Toast,
} from "antd-mobile";
import { AddOutline } from "antd-mobile-icons";
import { PickerColumnItem } from "antd-mobile/es/components/picker";
import { Upload } from "antd";
import { UploadFile } from "antd/es/upload/interface";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { DisposeForm, DisposeMobileText, handleUploadImage } from "utils";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { IpRegex } from "utils/regexp";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import {
  ADD_FILE,
  ADD_HARDWARE_MATTER,
  GET_EQPT_MODEL,
  GET_EQPT_OEM,
  GET_EQPT_TYPE,
} from "lib/api";
import { MobileLimitUploadImage } from "authenticated-app/mobile/util";
import {
  EqptModel,
  EqptOem,
  EqptType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";

export const MobileMatterInfoAdd = ({
  hospID,
  visible,
  setVisible,
  reload,
}: {
  hospID: string | number | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [eqptTypeID, setEqptTypeID] = useState<number | null>(null);
  const [eqptOemID, setEqptOemID] = useState<number | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const {
    run: getEqptTypeList,
    isLoading: gettingEqptTypeList,
    data: eqptTypeList,
  } = useAsync<EqptType[]>();
  const getEqptTypeListFunc = () => {
    getEqptTypeList(client(GET_EQPT_TYPE));
  };

  const {
    run: getEqptOemList,
    isLoading: gettingEqptOemList,
    data: eqptOemList,
  } = useAsync<EqptOem[]>();
  const getEqptOemListFunc = (eqpt_type_id: number) => {
    getEqptOemList(client(GET_EQPT_OEM(eqpt_type_id)));
  };

  const {
    run: getEqptModeList,
    isLoading: gettingEqptModeList,
    data: eqptModeList,
    setData: setEqptModel,
  } = useAsync<EqptModel[]>();
  const getEqptModeListFunc = (eqpt_oem_id: number) => {
    getEqptModeList(client(GET_EQPT_MODEL(eqpt_oem_id)));
  };

  const { run: submit, isLoading: submitting } = useAsync();
  const submitHandle = (value: any) => {
    if (hospID)
      submit(
        client(ADD_HARDWARE_MATTER, {
          data: {
            // ...value,
            hospital_id: parseInt(hospID.toString()),
            eqpt_type_id: value?.eqpt_type_id
              ? parseInt(value?.eqpt_type_id[0])
              : null,
            eqpt_oem_id: value?.eqpt_oem_id
              ? parseInt(value?.eqpt_oem_id[0])
              : null,
            eqpt_model_id: value?.eqpt_model_id
              ? parseInt(value?.eqpt_model_id[0])
              : null,
            ip: value?.ip || null,
            maintenance_code: value?.maintenance_code || null,
            assets_code: value?.assets_code || null,
            description: value?.description || null,
            asset_photos: fileList.map((i) => i.uid),
          },
          method: "POST",
        }),
      )
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="添加成功" />,
          });
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="添加失败" />,
          });
        });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法提交" />,
      });
  };

  const clearModel = () => {
    setEqptModel([]);
    form.setFieldsValue({
      eqpt_model_id: [],
    });
  };

  const clearOem = () => {
    setEqptOemID(null);
    form.setFieldsValue({
      eqpt_oem_id: [],
    });
  };

  useEffect(() => {
    if (eqptTypeID) {
      getEqptOemListFunc(eqptTypeID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqptTypeID]);

  useEffect(() => {
    if (eqptOemID) {
      getEqptModeListFunc(eqptOemID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqptOemID]);

  useEffect(() => {
    if (visible) {
      setFileList([]);
      form.resetFields();
      getEqptTypeListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <EditPopup
      visible={visible}
      submitting={submitting}
      setVisible={setVisible}
    >
      <Form
        form={form}
        onFinish={submitHandle}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={submitting}
          >
            提交
          </Button>
        }
      >
        <Form.Header>新增其它物料信息</Form.Header>
        <Form.Item
          name="eqpt_type_id"
          label="设备类型"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择设备类型" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingEqptTypeList}
            columns={
              eqptTypeList
                ? [
                    eqptTypeList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
            onConfirm={(v) => {
              clearOem();
              clearModel();
              setEqptTypeID(v[0] ? parseInt(v[0] as string) : null);
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择设备类型</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="eqpt_oem_id"
          label="设备品牌"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择设备品牌" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingEqptOemList}
            columns={
              eqptOemList
                ? [
                    eqptOemList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
            onConfirm={(v) => {
              clearModel();
              setEqptOemID(v[0] ? parseInt(v[0] as string) : null);
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择设备品牌</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="eqpt_model_id"
          label="设备型号"
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingEqptModeList}
            columns={
              eqptModeList
                ? [
                    eqptModeList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>选择设备型号</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="ip"
          label="物料IP"
          rules={[
            {
              pattern: IpRegex(),
              message: "必须输入合法的IP格式",
            },
          ]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input style={{ "--text-align": "right" }} placeholder="输入物料IP" />
        </Form.Item>
        <Form.Item
          name="maintenance_code"
          label="维保代码"
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input
            style={{ "--text-align": "right" }}
            placeholder="输入维保代码"
          />
        </Form.Item>
        <Form.Item
          name="assets_code"
          label="资产编码"
          rules={[{ required: true, message: "必须输入资产编码" }]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input
            style={{ "--text-align": "right" }}
            placeholder="请输入资产编码"
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="应用描述"
          help="该设备的用途说明"
          normalize={(v) => DisposeMobileText(v)}
          layout={"vertical"}
        >
          <TextArea autoSize placeholder="填写应用描述" />
        </Form.Item>
        <Form.Item
          name="asset_photos"
          className="adm-form-item-label-attachment"
          label="资产照片 (数量限制3个)"
          normalize={(v) => DisposeForm(v)}
          layout={"vertical"}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={3}
            className="upload-list-inline"
            action={ADD_FILE}
            listType="picture"
            fileList={fileList}
            beforeUpload={MobileLimitUploadImage}
            onChange={(info) => handleUploadImage(info, setFileList)}
          >
            {fileList.length >= 3 ? null : (
              <Button color="primary" fill="outline">
                <Space>
                  <AddOutline />
                  <span>点击上传</span>
                </Space>
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
