import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-components";
import { Button, Col, Row, Select, Space, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDebounceFn, useMount } from "ahooks";

import { useHttp } from "utils/http";
import { HosNodesType } from "authenticated-app/pc/customer/customer-list/interface";
import { useAsync } from "utils/hooks/useAsync";
import { GET_HOSP_NODES } from "lib/api";
import { selectFilter } from "utils";
import { GET_EQPT_MODEL_STATISTIC, GET_REMOTE_STATISTIC } from "../api";
import { MyPieChart, MyPieChartType } from "../chart/pie-chart";
import { MyBarChart, MyBarChartType } from "../chart/bar-chart";
import { EqptModelStatisticType, RemoteStatisticType } from "../interface";
import { GetRemoteNumBar } from "./calculate-value/remote-num-bar";
import { GetModelNumBar } from "./calculate-value/model-num-bar";
import { GetRemoteNumPie } from "./calculate-value/remote-num-pie";
import { GetModelNumPie } from "./calculate-value/model-num-pie";
import { useWindowSize } from "utils/hooks/useWindowSize";

const { Option } = Select;

export const RemoteModelInfo = () => {
  const client = useHttp();
  const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [provinceList, setProvinceList] = useState<HosNodesType[]>();
  const [searchArea, setSearchArea] = useState<number>();
  const [searchProvince, setSearchProvince] = useState<number>();

  const [remotePie, setRemotePie] = useState<MyPieChartType>();
  const [remoteBar, setRemoteBar] = useState<MyBarChartType>();

  const [modelPie, setModelPie] = useState<MyPieChartType>();
  const [modelBar, setModelBar] = useState<MyBarChartType>();

  const { height } = useWindowSize();

  const {
    run: getHosNodes,
    isLoading: gettingNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };
  const { run: getHosNodesDebounce } = useDebounceFn(getHosNodesHandle, {
    wait: 200,
  });

  const {
    run: getRemoteNum,
    isLoading: gettingRemoteNum,
    data: remoteNum,
  } = useAsync<RemoteStatisticType[]>();
  const getRemoteNumHandle = () => {
    getRemoteNum(
      client(GET_REMOTE_STATISTIC, {
        data: {
          area_id: searchArea,
          province_id: searchProvince,
        },
      }),
    );
  };
  const { run: getRemoteNumDebounce } = useDebounceFn(getRemoteNumHandle, {
    wait: 200,
  });

  const {
    run: getEqptModelNum,
    isLoading: gettingEqptModelNum,
    data: eqptModelNum,
  } = useAsync<EqptModelStatisticType[]>();
  const getEqptModelNumHandle = () => {
    getEqptModelNum(
      client(GET_EQPT_MODEL_STATISTIC, {
        data: {
          area_id: searchArea,
          province_id: searchProvince,
        },
      }),
    );
  };
  const { run: getEqptModelNumDebounce } = useDebounceFn(
    getEqptModelNumHandle,
    { wait: 200 },
  );

  useEffect(() => {
    let provinceListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.parentNodeId === searchArea && item.parentNodeId !== null) {
        provinceListTMP?.push(item);
      }
    });
    setProvinceList(provinceListTMP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchArea]);

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.nodeLevel === 1 && item.nodeName !== "海外") {
        areaListTMP?.push(item);
      }
    });
    setAreaList(areaListTMP);
  }, [hosNodes]);

  useEffect(() => {
    if (remoteNum) {
      setRemotePie(GetRemoteNumPie(remoteNum));
      setRemoteBar(GetRemoteNumBar(remoteNum));
    }
  }, [remoteNum]);

  useEffect(() => {
    if (eqptModelNum) {
      setModelPie(GetModelNumPie(eqptModelNum));
      setModelBar(GetModelNumBar(eqptModelNum));
    }
  }, [eqptModelNum]);

  useMount(() => {
    getHosNodesDebounce();
    getRemoteNumDebounce();
    getEqptModelNumDebounce();
  });

  const Loading = gettingNodes || gettingRemoteNum || gettingEqptModelNum;

  return (
    <div style={{ height: "100%", minWidth: 988 }}>
      <Spin spinning={Loading}>
        <PageHeader
          style={{ padding: 0 }}
          extra={
            <Space>
              <Select
                allowClear
                style={{ width: 120 }}
                placeholder="区域"
                disabled={Loading}
                loading={Loading}
                value={searchArea}
                onChange={(nodeId) => {
                  setSearchProvince(undefined);
                  setSearchArea(nodeId);
                }}
              >
                {areaList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Select
                disabled={Loading}
                loading={Loading}
                value={searchProvince}
                allowClear
                placeholder="省份"
                style={{ width: 140 }}
                showSearch
                filterOption={selectFilter}
                onChange={(nodeId) => {
                  setSearchProvince(nodeId);
                }}
              >
                {provinceList?.map((item, index) => (
                  <Option key={index} value={item.nodeId}>
                    {item.nodeName}
                  </Option>
                ))}
              </Select>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                loading={Loading}
                disabled={Loading}
                onClick={() => {
                  getRemoteNumHandle();
                  getEqptModelNumHandle();
                }}
              >
                查询
              </Button>
            </Space>
          }
        />
        <Row
          style={{ height: (height - 256) / 2, minHeight: 380, width: "100%" }}
        >
          <Col span={10}>
            <MyPieChart Data={remotePie} showLegend={false} />
          </Col>
          <Col span={14}>
            <MyBarChart Data={remoteBar} />
          </Col>
        </Row>
        <Row
          style={{ height: (height - 256) / 2, minHeight: 380, width: "100%" }}
        >
          <Col span={10}>
            <MyPieChart Data={modelPie} showLegend={false} />
          </Col>
          <Col span={14}>
            <MyBarChart Data={modelBar} />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
