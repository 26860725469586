import { MutableRefObject, useEffect, useRef, useState } from "react";
import { ActionType, PageHeader } from "@ant-design/pro-components";
import {
  Badge,
  Button,
  Divider,
  Form,
  Modal,
  Space,
  Spin,
  message,
  Result,
  Descriptions,
  Typography,
  Tag,
  Input,
  InputRef,
} from "antd";
import { MonitorInfo } from "authenticated-app/pc/customer/customer-detail/tab-pane/remoteTabPane/monitor-info";
import { useDebounceFn, useScroll } from "ahooks";
import {
  PlayCircleOutlined,
  SwapOutlined,
  VerticalAlignTopOutlined,
  CheckOutlined,
  RollbackOutlined,
  LockOutlined,
  UnlockOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  EditOutlined,
} from "@ant-design/icons";
import { useAuth } from "context/auth";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { useBreaklistDetail } from "authenticated-app/hooks/breaklist/useBreaklistDetail";
import { BREAK_DETAIL_TYPE } from "lib/interface/breaklist";
import {
  GET_BREAK_DETAIL,
  TRANS_BREAK,
  UPDATE_BREAK_DETAIL,
  DELETE_BREAK_DETAIL,
  UPDATE_BREAK_FLLOW_DETAIL,
} from "lib/api/issues";
import { ORDER_ALERT_TYPE } from "lib/interface/breaklist";
import { UPDATE_ISSUES_LAEL, GET_MONITOR_ALERT_INFO } from "lib/api";
import { priorityColor, priorityDes, priorityStatus } from "utils/ticket";
import { BreakDetailBaseInfo } from "./baseInfo";
import { BreakDetailDetailInfo } from "./detailInfo";
import { BreakDetailRight } from "./right";
import { EditQuill } from "authenticated-app/pc/components/EditQuill";
import { BreakDetailTransferModal } from "./trans/transfer";
import { useBreakDetailTransfer } from "authenticated-app/hooks/breaklist/trans/useBreakDetailTransfer";
import { useBreakDetailUpgrade } from "authenticated-app/hooks/breaklist/trans/useBreakDetailUpgrade";
import { BreakDetailUpgradeModal } from "./trans/upgrade";
import { BreakDetailSuspendModal } from "./trans/suspend";
import { useBreakDetailSuspend } from "authenticated-app/hooks/breaklist/trans/useBreakDetailSuspend";
import { BreakDetailSolveModal } from "./trans/solve";
import { useBreakDetailSolve } from "authenticated-app/hooks/breaklist/trans/useBreakDetailSolve";
import { BreakDetailReverseModal } from "./trans/reverse";
import { useBreakDetailReverse } from "authenticated-app/hooks/breaklist/trans/useBreakDetailReverse";
import { EditUpload } from "authenticated-app/pc/components/EditUpload";
import { Comments } from "authenticated-app/pc/components/Comments";
import { ADD_LABEL, GET_LABEL_LIST } from "lib/api";
import { UpdateSelectLabel } from "authenticated-app/pc/components/UpdateSelectLabel";

import "./index.css";
const { Item } = Descriptions;
const { Link } = Typography;

const { confirm } = Modal;
export const BreakDetailModal = ({
  actionRef,
}: {
  actionRef: MutableRefObject<ActionType | undefined>;
}) => {
  const { user } = useAuth();

  const [isChanged, setIsChanged] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isOpen, , close] = useBreaklistDetail();
  const [{ detailId }] = useUrlQueryParam(["detailId"]);
  const [isEditting, setIsEditting] = useState(false);
  const inputRef = useRef<InputRef>(null);

  const [nextProcess, setNextProcess] = useState<number[]>([]);
  const [tarnsDepartment, setTransDepartment] = useState<number[]>([]);
  const [breakDetailHandleOpening, setBreakDetailHandleOpening] =
    useState(false);
  const [breakDetailUnlockOpening, setBreakDetailUnlockOpening] =
    useState(false);
  const [breakDetailTransferOpenig, openBreakDetailTransfer] =
    useBreakDetailTransfer();
  const [breakDetailUpgradeOpenig, openBreakDetailUpgrade] =
    useBreakDetailUpgrade();
  const [breakDetailReverseOpenig, openBreakDetailReverse] =
    useBreakDetailReverse();
  const [breakDetailSuspendOpenig, openBreakDetailSuspend] =
    useBreakDetailSuspend();
  const [breakDetailSolveOpenig, openBreakDetailSolve] = useBreakDetailSolve();
  const [breakDeleteHandleOpening, setBreakDeleteHandleOpening] =
    useState(false);
  const [alertCount, setAlertCount] = useState<number>();
  const divRef = useRef<HTMLDivElement>(null);
  const scroll = useScroll(divRef);
  const [isFoucs, setIsFoucs] = useState(false);
  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getDetailInfo,
    data: detailInfo,
    isIdle,
    isLoading,
    setData,
  } = useAsync<BREAK_DETAIL_TYPE>();
  const getDetailInfoHandle = async () => {
    try {
      await getDetailInfo(client(GET_BREAK_DETAIL(detailId)));
      setIsNotFound(false);
    } catch (error: any) {
      setData(null);
      if (error.status === 404) {
        setIsNotFound(true);
      }
    }
  };
  const { run: getDetailInfoDebounce } = useDebounceFn(getDetailInfoHandle, {
    wait: 200,
  });

  const { run: update, isLoading: updating } = useAsync();
  const onUpdate = (value: any) => {
    if (detailInfo?.id) {
      const { follow_user_id_list } = value;
      let updateAPI = UPDATE_BREAK_DETAIL;
      if (follow_user_id_list) {
        updateAPI = UPDATE_BREAK_FLLOW_DETAIL;
      }
      update(
        client(updateAPI(detailInfo?.id), {
          method: "PUT",
          data: {
            ...value,
          },
        }),
      )
        .then((res) => {
          const content = `${res.order_id}更新成功!`;
          message.success(content, 5);
          setIsChanged(true);
          setData(res);
        })
        .catch(() => getDetailInfoDebounce());
    }
  };

  const { run: breakDelete } = useAsync();
  const onDelete = (id: any) => {
    if (detailInfo?.id) {
      breakDelete(
        client(DELETE_BREAK_DETAIL(id), {
          method: "DELETE",
        }),
      )
        .then((res) => {
          const content = `${detailInfo?.order_id} 删除成功!`;
          message.success(content, 5);
        })
        .catch((error) => {
          const content = `${detailInfo?.order_id} 删除失败!`;
          message.error(content, 5);
        });
    }
  };

  const { run: trans } = useAsync();
  const onTrans = (value: any, onTransType?: string) => {
    if (detailInfo?.id) {
      trans(
        client(TRANS_BREAK(detailInfo?.id), {
          method: "PUT",
          data: {
            order_type_id: detailInfo.order_type_id,
            customer_owner: detailInfo.customer_owner,
            feedback_time: detailInfo.feedback_time,
            hospital_id: detailInfo.hospital_id,
            reasons_for_transfer: detailInfo.reasons_for_transfer,
            knowledge_link: detailInfo.knowledge_link,
            suspension_reason: detailInfo.suspension_reason,
            is_additional_recording: detailInfo.is_additional_recording,
            is_temporary: detailInfo.is_temporary,
            is_on_site: detailInfo.is_on_site,
            is_known: detailInfo.is_known,
            delivery_time: detailInfo.delivery_time,
            next_time: detailInfo.next_time,
            checklist_id: detailInfo.checklist_id,
            is_deal_with_on_site: detailInfo.is_deal_with_on_site,
            response_time: detailInfo.response_time,
            issue_module_id: detailInfo.issue_module_id,
            issue_reason_id: detailInfo.issue_reason_id,
            issue_type_id: detailInfo.issue_type_id,
            service_order_id: detailInfo.service_order_id,
            title: detailInfo.title,
            customer_name: detailInfo.customer_name || detailInfo.hospital_name,
            description: detailInfo.description,
            feedback_channel_id: detailInfo.feedback_channel_id,
            feedback_user: detailInfo.feedback_user,
            contact: detailInfo.contact,
            influence_function: detailInfo.influence_function,
            influence_sphere: detailInfo.influence_sphere,
            issue_user_id: detailInfo.issue_user_id,
            node_user_id: detailInfo.node_user_id,
            remote_info: detailInfo.remote_info,
            product_id_list: detailInfo.product_list?.map((i) => [
              i.title,
              i.product_version_id || "",
            ]),
            attachment_id_list: detailInfo.attachment_list?.map(
              (i) => i.attachment_id,
            ),
            debug_info:
              !!!detailInfo.debug_info_dict.update_user_id &&
              !!!detailInfo.debug_info_dict.debug_info
                ? undefined
                : detailInfo.debug_info_dict.debug_info,
            solution_info:
              !!!detailInfo.solution_info_dict.update_user_id &&
              !!!detailInfo.solution_info_dict.solution_info
                ? undefined
                : detailInfo.solution_info_dict.solution_info,
            ...value,
          },
        }),
      )
        .then((res) => {
          setIsChanged(true);
          setData(res);
          const content = `${res.order_id}转换为${res.process_name}!`;
          message.success(content, 5);
        })
        .catch(() => getDetailInfoDebounce())
        .finally(() => {
          if (onTransType === "handle") {
            setBreakDetailHandleOpening(false);
          } else if (onTransType === "unlock") {
            setBreakDetailUnlockOpening(false);
          }
        });
    }
  };

  const reload = () => {
    getDetailInfoDebounce();
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "确定要删除这个工单吗?",
      icon: <ExclamationCircleFilled />,
      okText: "确认删除",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        onDelete(detailId);
        setBreakDeleteHandleOpening(false);
        close();
        actionRef.current?.reload();
      },
      onCancel() {
        setBreakDeleteHandleOpening(false);
      },
    });
  };
  const {
    run: updateLabel,
    isLoading: updateLabelLoading,
    isIdle: updateLabelIsIdle,
  } = useAsync();
  const onUpdateLabel = (value: any) => {
    if (detailInfo?.id) {
      updateLabel(
        client(UPDATE_ISSUES_LAEL(detailInfo?.id), {
          method: "PUT",
          data: {
            ...value,
          },
        }),
      )
        .then((res) => {
          const content = `${res.order_id}更新成功!`;
          message.success(content, 5);
          setIsChanged(true);
          setData(res);
        })
        .catch(() => {
          getDetailInfoDebounce();
        });
    }
  };

  const {
    run: getAlertInfo,
    data: alertInfo,
    isLoading: getAlertLoading,
    isIdle: getAlertIsIdle,
  } = useAsync<ORDER_ALERT_TYPE>();
  const getAlertInfoHandle = () => {
    if (detailInfo?.hospital_info.hospital_code) {
      getAlertInfo(
        client(GET_MONITOR_ALERT_INFO, {
          data: {
            query: `crm_code=${detailInfo.hospital_info.hospital_code}`,
            hours: 24,
          },
        }),
      );
    }
  };
  const { run: getAlertDebounce } = useDebounceFn(getAlertInfoHandle, {
    wait: 200,
  });

  const getStatus = () => {
    switch (detailInfo?.exist_monitor) {
      case 0:
      case 1:
        return (
          <>
            {detailInfo.exist_monitor === 0 ? (
              <Tag color="blue">监控离线</Tag>
            ) : (
              <Tag color="blue">监控在线</Tag>
            )}
            {getAlertLoading ? (
              <Spin></Spin>
            ) : (
              <>
                {alertCount === 0 ? (
                  <Tag color="success">最近一天没有告警信息</Tag>
                ) : (
                  <>
                    <Tag color="error">最近一天发生{alertCount}次告警!</Tag>
                  </>
                )}
                <Button
                  type="link"
                  href="https://monitor.infervision.com/alert-his-events"
                  target="_blank"
                >
                  访问监控平台
                </Button>
                <Link
                  href={`http://inhand.infervision.com:3009/grafana/d/d1ad5d08-081b-46ec-9468-344e84a9164a/fcd6c01d-adb4-5d5b-ab6d-01b6b7571eb2?orgId=1&var-hospital_name=${detailInfo?.hospital_name}&var-crm_code=${detailInfo?.hospital_info.hospital_code}&from=now-24h&to=now`}
                  target="_blank"
                >
                  查看最近一天监控数据
                </Link>
              </>
            )}
          </>
        );
      case 2:
        return (
          <>
            <Tag color="blue">未接入监控</Tag>
          </>
        );
      default:
        return <></>;
    }
  };

  const onFinish = (value: any) => {
    if (isChanged) {
      onUpdate(value);
      setIsChanged(false);
    }
    inputRef.current?.blur();
  };

  useEffect(() => {
    setIsEditting(false);
    if (detailInfo?.hospital_info) {
      if (
        detailInfo.hospital_info.hospital_code &&
        detailInfo.exist_monitor !== 2
      ) {
        getAlertDebounce();
      }
    }
    if (detailInfo?.title) {
      form.setFieldsValue({
        ["title"]: detailInfo?.title,
      });
    }
  }, [detailInfo]);

  useEffect(() => {
    if (alertInfo) {
      setAlertCount(alertInfo.dat.total);
    }
  }, [alertInfo]);

  useEffect(() => {
    setIsEditting(false);
    setIsNotFound(false);
    if (isOpen) {
      form.resetFields();
      setIsChanged(false);
      if (detailId) {
        getDetailInfoDebounce();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailId]);

  const Loading = isIdle || isLoading;
  const isFinished = !!!isIdle && detailInfo?.process_id === 6;
  const isHasPermission =
    user?.id === detailInfo?.node_user_id ||
    user?.id === detailInfo?.issue_user_id;

  const isCanChange = !!!isFinished && isHasPermission;
  return (
    <Modal
      centered
      maskClosable={false}
      destroyOnClose
      open={isOpen}
      afterClose={() => {
        setData(null);
        if (isChanged) {
          actionRef.current?.reload();
        }
      }}
      onCancel={close}
      footer={null}
      width={"93vw"}
      style={{
        minWidth: "928px",
        height: "92vh",
      }}
    >
      {isNotFound ? (
        <Result
          status={404}
          subTitle="抱歉，你访问的工单不存在！"
          extra={
            <Button
              type="primary"
              onClick={() => {
                close();
              }}
            >
              返回工单列表
            </Button>
          }
        ></Result>
      ) : (
        <>
          <Spin spinning={Loading} style={{ height: "100%" }}>
            <PageHeader
              className="page-header"
              title={
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        minWidth: "80px",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "normal",
                          minWidth: "80px",
                        }}
                      >
                        <Badge
                          style={{ color: "#5B5B5B", margin: "0px" }}
                          color={priorityColor.get(
                            (detailInfo?.priority as priorityStatus) ||
                              priorityStatus.medium,
                          )}
                          text={priorityDes.get(
                            (detailInfo?.priority as priorityStatus) ||
                              priorityStatus.medium,
                          )}
                        />
                      </span>
                      {isCanChange ? (
                        <>
                          {isEditting ? null : (
                            <span
                              style={{
                                marginLeft: "16px",
                                fontSize: "18px",
                              }}
                              onClick={() => {
                                setIsEditting(true);
                              }}
                              onMouseEnter={() => setIsEditting(true)}
                              onMouseLeave={() => {
                                setIsEditting(false);
                              }}
                              onBlur={() => {
                                setIsEditting(false);
                              }}
                            >
                              {detailInfo?.title}
                            </span>
                          )}
                        </>
                      ) : (
                        <span
                          style={{
                            marginLeft: "16px",
                            fontSize: "18px",
                          }}
                        >
                          {detailInfo?.title}
                        </span>
                      )}
                    </span>
                    {isEditting && isCanChange ? (
                      <Form
                        form={form}
                        className="update-input-form"
                        onFinish={onFinish}
                        autoComplete="off"
                      >
                        <Form.Item
                          name={"title"}
                          rules={[
                            { required: true, message: "必须输入工单标题" },
                          ]}
                          style={{ width: "100%" }}
                        >
                          <Input
                            placeholder="必须输入工单标题"
                            ref={inputRef}
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                            value={detailInfo?.title}
                            suffix={isEditting ? <EditOutlined /> : undefined}
                            bordered={isEditting}
                            onChange={(e) => {
                              setIsChanged(true);
                            }}
                            onFocus={() => setIsFoucs(true)}
                            onBlur={() => {
                              form
                                .validateFields()
                                .then(() => {
                                  setIsFoucs(false);
                                  setIsEditting(false);
                                  form.submit();
                                })
                                .catch((errorInfo) => {
                                  console.log("标题表单验证失败:", errorInfo);
                                });
                            }}
                            onMouseEnter={() => setIsEditting(true)}
                            onMouseLeave={() => {
                              if (!isFoucs) {
                                setIsEditting(false);
                              }
                            }}
                          />
                        </Form.Item>
                      </Form>
                    ) : null}
                  </div>
                </>
              }
              extra={
                <Space style={{ marginRight: 24 }}>
                  {detailInfo?.process_name !== "已解决" ? (
                    <>
                      <Button
                        key={"delete_break"}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          setBreakDeleteHandleOpening(true);
                          showDeleteConfirm();
                        }}
                        loading={breakDeleteHandleOpening}
                        type="primary"
                        danger
                      >
                        删除
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                  {detailInfo?.process_trans.map((i, index) => {
                    switch (i.title) {
                      case "处理":
                        return (
                          <Button
                            key={index}
                            icon={<PlayCircleOutlined />}
                            onClick={() => {
                              setBreakDetailHandleOpening(true);
                              onTrans(
                                { next_process: i.next_process },
                                "handle",
                              );
                            }}
                            loading={breakDetailHandleOpening}
                          >
                            {i.title}
                          </Button>
                        );
                      case "转派":
                        return (
                          <Button
                            key={index}
                            icon={<SwapOutlined />}
                            loading={breakDetailTransferOpenig}
                            onClick={() => {
                              setNextProcess(i.next_process);
                              setTransDepartment(i.department_ids);
                              openBreakDetailTransfer();
                            }}
                          >
                            {i.title}
                          </Button>
                        );
                      case "转回":
                        return (
                          <Button
                            key={index}
                            icon={<RollbackOutlined />}
                            loading={breakDetailReverseOpenig}
                            onClick={() => {
                              setNextProcess(i.next_process);
                              setTransDepartment(i.department_ids);
                              openBreakDetailReverse();
                            }}
                          >
                            {i.title}
                          </Button>
                        );
                      case "升级":
                        return (
                          <Button
                            key={index}
                            icon={<VerticalAlignTopOutlined />}
                            loading={breakDetailUpgradeOpenig}
                            onClick={() => {
                              setNextProcess(i.next_process);
                              setTransDepartment(i.department_ids);
                              openBreakDetailUpgrade();
                            }}
                          >
                            {i.title}
                          </Button>
                        );
                      case "解决":
                        return (
                          <Button
                            key={index}
                            icon={<CheckOutlined />}
                            loading={breakDetailSolveOpenig}
                            onClick={() => {
                              setNextProcess(i.next_process);
                              setTransDepartment(i.department_ids);
                              openBreakDetailSolve();
                            }}
                          >
                            {i.title}
                          </Button>
                        );
                      case "挂起":
                        return (
                          <Button
                            key={index}
                            icon={<LockOutlined />}
                            loading={breakDetailSuspendOpenig}
                            onClick={() => {
                              setNextProcess(i.next_process);
                              setTransDepartment(i.department_ids);
                              openBreakDetailSuspend();
                            }}
                          >
                            {i.title}
                          </Button>
                        );
                      case "解挂":
                        return (
                          <Button
                            key={index}
                            icon={<UnlockOutlined />}
                            loading={breakDetailUnlockOpening}
                            onClick={() => {
                              setBreakDetailUnlockOpening(true);
                              onTrans(
                                { next_process: i.next_process },
                                "unlock",
                              );
                            }}
                          >
                            {i.title}
                          </Button>
                        );
                      default:
                        return null;
                    }
                  })}
                </Space>
              }
            />
            <Divider style={{ margin: "6px 0" }} />
            <div className="break-detail-div">
              <div style={{ flex: 3, height: "100%" }}>
                <BreakDetailBaseInfo
                  detailInfo={detailInfo}
                  isFinished={isFinished}
                  isHasPermission={isHasPermission}
                />
                <Descriptions
                  size="small"
                  className="break-base-desc-title"
                  column={1}
                >
                  <Item>
                    <UpdateSelectLabel
                      onUpdate={onUpdateLabel}
                      defaultValue={detailInfo?.label_list}
                      defaultIds={detailInfo?.label_list.map((item) => item.id)}
                      label={"标签"}
                      name={"label_id_list"}
                      options={{ value: "id", label: "name" }}
                      api={GET_LABEL_LIST}
                      addApi={ADD_LABEL}
                      isCanChange={true}
                      updating={updateLabelLoading}
                    />
                  </Item>
                </Descriptions>
                <BreakDetailDetailInfo
                  onUpdate={onUpdate}
                  detailInfo={detailInfo}
                  isFinished={isFinished}
                  isHasPermission={isHasPermission}
                />
                <EditQuill
                  onUpdate={onUpdate}
                  name={"description"}
                  title={"反馈详情"}
                  info={detailInfo?.description || ""}
                  isCanChange={isCanChange}
                />
                <EditUpload
                  onUpdate={onUpdate}
                  name={"attachment_id_list"}
                  title={"附件"}
                  defaultValue={detailInfo?.attachment_list}
                  isCanChange={isCanChange}
                />
                <Divider style={{ margin: 2 }} />
                {/* <Descriptions
                  size="small"
                  className="break-base-desc-title"
                  column={1}
                  title="监控信息"
                >
                  <Item>{getStatus()}</Item>
                </Descriptions> */}
                <MonitorInfo
                  hospCode={detailInfo?.hospital_info.hospital_code}
                  hospitalName={detailInfo?.hospital_name}
                  hospID={detailInfo?.hospital_id}
                  mode="overview"
                />
                <Divider style={{ margin: 2 }} />
                <EditQuill
                  onUpdate={onUpdate}
                  name={"debug_info"}
                  title={"定位及补充记录"}
                  user={detailInfo?.debug_info_dict.update_user_name || ""}
                  avatar={detailInfo?.debug_info_dict.avatar || ""}
                  info={detailInfo?.debug_info_dict.debug_info || ""}
                  time={detailInfo?.debug_info_dict.update_time || ""}
                  isCanChange={isCanChange}
                />
                <Divider style={{ margin: 2 }}></Divider>
                <EditQuill
                  onUpdate={onUpdate}
                  name={"solution_info"}
                  title={"操作及解决记录"}
                  user={detailInfo?.solution_info_dict.update_user_name || ""}
                  avatar={detailInfo?.solution_info_dict.avatar || ""}
                  info={detailInfo?.solution_info_dict.solution_info || ""}
                  time={detailInfo?.solution_info_dict.update_time || ""}
                  isCanChange={isCanChange}
                />
                <Divider style={{ margin: 2 }} />
                <div className="independent-container">
                  <Comments
                    detailInfo={detailInfo}
                    reload={reload}
                    title="评论"
                  />
                </div>
              </div>
              <Divider
                style={{ height: `calc(100% + ${scroll?.top}px)` }}
                type="vertical"
              />
              <BreakDetailRight
                onUpdate={onUpdate}
                detailInfo={detailInfo}
                isFinished={isFinished}
                isHasPermission={isHasPermission}
                isCanChange={isHasPermission}
              />
            </div>
          </Spin>
          <BreakDetailTransferModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
            department={tarnsDepartment}
          />
          <BreakDetailUpgradeModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
            department={tarnsDepartment}
          />
          <BreakDetailReverseModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
            department={tarnsDepartment}
          />
          <BreakDetailSuspendModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
          />
          <BreakDetailSolveModal
            detailInfo={detailInfo}
            onTrans={onTrans}
            next={nextProcess}
          />
        </>
      )}
    </Modal>
  );
};
