import { Descriptions } from "antd";
import { DELIVERY_DETAIL_TYPE } from "lib/interface/launchChangeList";
import { getBoolValue } from "utils";

const { Item } = Descriptions;

export const DeliveryDetailBaseInfo = ({
  onUpdate,
  detailInfo,
  isCanChange,
}: {
  onUpdate: (value: any) => void;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
  isCanChange: boolean;
}) => {
  const childinfo = () => {
    switch (detailInfo?.delivery_type_name) {
      case "销售交付":
      case "试用交付":
      case "渠道交付":
        return (
          <>
            <Descriptions
              className="break-base-desc-title"
              size="small"
              title="基本信息"
              column={3}
            >
              <Item label={"工单编号"}>{detailInfo?.order_id}</Item>
              <Item label={"对接方式"}>{detailInfo?.docking_type}</Item>
              <Item label={"是否涉及定制化"}>
                <span style={{ marginLeft: "8px", color: "black" }}>
                  {getBoolValue(detailInfo?.is_customization)}
                </span>
              </Item>
            </Descriptions>
            <Descriptions column={3} size="small">
              <Item label={"审批单号"}>{detailInfo?.approval_number}</Item>
              <Item label={"调出单号"}>{detailInfo?.store_out_number}</Item>
              <Item label={"物流单号"}>{detailInfo?.tracking_number}</Item>
            </Descriptions>
            <Descriptions column={1} size="small">
              <Item label={"物料清单"}>{detailInfo?.bill_of_materials}</Item>
              <Item label={"收件人及联系方式"}>{detailInfo?.contact}</Item>
              <Item label={"上线产品及版本"}>
                {detailInfo?.product_description?.split("|").join("、")}
              </Item>
            </Descriptions>
          </>
        );
      case "产品升级":
        return (
          <>
            <Descriptions
              className="break-base-desc-title"
              size="small"
              title="基本信息"
              column={3}
            >
              <Item label={"工单编号"}>{detailInfo?.order_id}</Item>
              <Item label={"产品线类型"}>{detailInfo?.product_type}</Item>
              <Item label={"审批单号"}>{detailInfo?.approval_number}</Item>
            </Descriptions>

            <Descriptions column={2} size="small">
              <Item label={"是否定制版本"}>
                <span style={{ marginLeft: "8px", color: "black" }}>
                  {getBoolValue(detailInfo?.is_customization_version)}
                </span>
              </Item>
              <Item label={"定制版本内容"}>
                {detailInfo?.customization_version_content}
              </Item>
            </Descriptions>
            <Descriptions column={1} size="small">
              <Item label={"升级产品线及版本"}>
                {detailInfo?.product_description?.split("|").join("、")}
              </Item>
            </Descriptions>
            <Descriptions column={2} size="small">
              <Item label={"是否存在历史定制化"}>
                <span style={{ marginLeft: "8px", color: "black" }}>
                  {getBoolValue(detailInfo?.is_history_customization_version)}
                </span>
              </Item>
              <Item label={"历史定制化内容"}>
                {detailInfo?.history_customization_version_content}
              </Item>
            </Descriptions>
          </>
        );
      case "产品停用":
        return (
          <>
            <Descriptions
              className="break-base-desc-title"
              size="small"
              title="基本信息"
              column={2}
            >
              <Item label={"工单编号"}>{detailInfo?.order_id}</Item>
              <Item label={"审批单号"}>{detailInfo?.approval_number}</Item>
            </Descriptions>
            <Descriptions column={1} size="small">
              <Item label={"停用产品线"}>
                {detailInfo?.product_description?.split("|").join("、")}
              </Item>
            </Descriptions>
          </>
        );
      case "产品撤机":
        return (
          <>
            <Descriptions
              className="break-base-desc-title"
              size="small"
              title="基本信息"
              column={2}
            >
              <Item label={"工单编号"}>{detailInfo?.order_id}</Item>
              <Item label={"审批单号"}>{detailInfo?.approval_number}</Item>
              <Item label={"撤机类型"}>{detailInfo?.abort_type}</Item>
              {detailInfo?.abort_type === "部分撤机" ? (
                <>
                  <Item label={"撤机产品线"}>
                    {detailInfo?.abort_product?.split("|").join("、")}
                  </Item>
                </>
              ) : (
                <></>
              )}
            </Descriptions>
          </>
        );
      case "硬件部署":
        return (
          <>
            <Descriptions
              className="break-base-desc-title"
              size="small"
              title="基本信息"
              column={2}
            >
              <Item label={"工单编号"}>{detailInfo?.order_id}</Item>
              <Item label={"是否存在历史定制化"}>
                <span style={{ marginLeft: "8px", color: "black" }}>
                  {getBoolValue(detailInfo?.is_history_customization_version)}
                </span>
              </Item>
            </Descriptions>
            <Descriptions column={1} size="small">
              <Item label={"历史定制化内容"}>
                {detailInfo?.history_customization_version_content}
              </Item>
            </Descriptions>
            <Descriptions column={1} size="small">
              <Item label={"涉及产品及版本"}>
                {detailInfo?.product_description?.split("|").join("、")}
              </Item>
            </Descriptions>
          </>
        );
      case "组件升级":
        return (
          <>
            <Descriptions
              className="break-base-desc-title"
              size="small"
              title="基本信息"
              column={2}
            >
              <Item label={"工单编号"}>{detailInfo?.order_id}</Item>
              <Item label={"是否存在历史定制化"}>
                <span style={{ marginLeft: "8px", color: "black" }}>
                  {getBoolValue(detailInfo?.is_history_customization_version)}
                </span>
              </Item>
            </Descriptions>
            <Descriptions column={1} size="small">
              <Item label={"历史定制化内容"}>
                {detailInfo?.history_customization_version_content}
              </Item>
            </Descriptions>
            <Descriptions column={2} size="small">
              <Item label={"组件名称"}>{detailInfo?.module_name}</Item>
              <Item label={"组件版本"}>{detailInfo?.module_version}</Item>
            </Descriptions>
          </>
        );
      default:
        return (
          <>
            <Descriptions
              className="break-base-desc-title"
              size="small"
              title="基本信息"
              column={2}
            >
              <Item label={"工单编号"}>{detailInfo?.order_id}</Item>
              <Item label={"审批单号"}>{detailInfo?.approval_number}</Item>
            </Descriptions>
          </>
        );
    }
  };
  return <>{childinfo()}</>;
};
