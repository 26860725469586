import { useState, Fragment, useReducer } from "react";
import { Button, Descriptions, Space, Spin } from "antd";
import { PlusOutlined, RedoOutlined } from "@ant-design/icons";
import { useDebounceFn } from "ahooks";

import { ResourceType } from "../interface";
import { ResourceSettingAdd } from "./addResource";
import { ResourceClassAdd } from "./addClass";
import ShowClass from "./showClass";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { GET_RESOURCE_LIST } from "../api";
import { useMount } from "utils";

export interface classReducer {
  id: number | null;
  count: number;
}
const initialState: classReducer = { id: null, count: 0 };

const reducer = (state: classReducer, action: { id: number | null }) => {
  return { id: action.id, count: state.count + 1 };
};

const ResourceSetting = () => {
  const client = useHttp();

  // 必要信息state
  const [addResourcesVisible, setAddResourcesVisible] = useState(false);
  const [addClassVisible, setAddClassVisible] = useState(false);
  const [addClassInfo, setAddClassInfo] = useState<ResourceType>();

  const [reducerState, notifyRender] = useReducer(reducer, initialState);

  const {
    run: getSettingList,
    isIdle,
    isLoading: gettingSettingList,
    data: resources,
  } = useAsync<ResourceType[]>();
  const getSettingListHandle = () => {
    getSettingList(client(GET_RESOURCE_LIST));
  };
  const { run: getSettingListDebounce } = useDebounceFn(getSettingListHandle, {
    wait: 200,
  });

  const reload = getSettingListHandle;
  const loading = isIdle || gettingSettingList;

  useMount(getSettingListDebounce);

  const ResourceDescItem = (item: ResourceType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`resourceSetting${key}`}>
        <Descriptions.Item label={title("编号")} className={className()}>
          {key + 1}
        </Descriptions.Item>
        <Descriptions.Item label={title("资源名称")} className={className()}>
          {item.resourceName || ""}
        </Descriptions.Item>
        <Descriptions.Item label={title("资源分类")} className={className()}>
          <ShowClass id={item.resourceId} state={reducerState} />
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("操作")}
          className={className()}
        >
          <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            loading={
              addClassVisible && item.resourceId === addClassInfo?.resourceId
            }
            onClick={() => {
              setAddClassInfo(item);
              setAddClassVisible(true);
            }}
          />
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Spin spinning={loading}>
      <Descriptions
        column={4}
        bordered={resources?.length ? true : false}
        title="资源"
        layout="vertical"
        extra={
          <Space>
            <Button
              loading={addResourcesVisible}
              icon={<PlusOutlined />}
              onClick={() => setAddResourcesVisible(true)}
            >
              新增
            </Button>
            <Button
              loading={loading}
              icon={<RedoOutlined />}
              type={"primary"}
              onClick={reload}
            >
              刷新
            </Button>
          </Space>
        }
      >
        {resources
          ?.sort((a, b) => a.resourceId - b.resourceId)
          ?.map((item, index) => ResourceDescItem(item, index))}
      </Descriptions>
      <ResourceSettingAdd
        resources={resources}
        visible={addResourcesVisible}
        setVisible={setAddResourcesVisible}
        reload={reload}
      />
      <ResourceClassAdd
        resource={addClassInfo}
        visible={addClassVisible}
        setVisible={setAddClassVisible}
        reload={() => {
          notifyRender({ id: addClassInfo?.resourceId || null });
          reload();
        }}
      />
    </Spin>
  );
};

export default ResourceSetting;
