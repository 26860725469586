import { useEffect, RefObject } from "react";
import {
  Form,
  Button,
  TextArea,
  DatePicker,
  DatePickerRef,
  Picker,
} from "antd-mobile";
import { useDebounceFn } from "ahooks";
import { useAuth } from "context/auth";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";
import {
  NEXT_PROCESS_TYPE,
  DELIVERY_DETAIL_TYPE,
  DELIVERY_USER_TYPE,
} from "lib/interface/launchChangeList";
import { FormatDayjs, FormatDate, DisposeForm, FormatTime } from "utils";
import { useDeliveryDetailTransfer } from "authenticated-app/hooks/deliverylist/trans/useDeliveryDetailTransfer";
import { GET_DELIVERY_USER_LIST } from "lib/api/deliverylist";
export const DeliveryDetailTransferModal = ({
  onTrans,
  next,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: NEXT_PROCESS_TYPE | undefined;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useDeliveryDetailTransfer();
  const [form] = Form.useForm();
  const { user } = useAuth();
  const client = useHttp();

  const {
    run: getDeliveryUserList,
    data: deliveryUserList,
    isIdle: getDeliveryUserListIdle,
    isLoading: gettingDeliveryUserList,
  } = useAsync<DELIVERY_USER_TYPE[]>();
  const getDeliveryUserListHandle = () => {
    getDeliveryUserList(client(GET_DELIVERY_USER_LIST));
  };
  const { run: getDeliveryUserListDebounce } = useDebounceFn(
    getDeliveryUserListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({
      ...value,
      next_process: next?.next_process,
      trans_id: next?.trans_id,
      delivery_user_id: value.delivery_user_id[0],
      ...(value.planned_delivery_date
        ? { planned_delivery_date: FormatDate(value.planned_delivery_date) }
        : {}),
      ...(value.planned_return_date
        ? { planned_return_date: FormatDate(value.planned_return_date) }
        : {}),
    });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      const delivery_user_id = detailInfo?.delivery_user_id
        ? detailInfo?.delivery_user_id
        : user?.id;
      form.setFieldsValue({
        delivery_user_id: [delivery_user_id],
        transfer_reason: detailInfo?.transfer_reason,
        planned_delivery_date: detailInfo?.planned_delivery_date
          ? FormatDayjs(detailInfo.planned_delivery_date).toDate()
          : null,
      });

      if (detailInfo?.process_name === "硬件退回") {
        form.setFieldsValue({
          planned_return_date: detailInfo?.planned_return_date
            ? FormatDayjs(detailInfo.planned_return_date).toDate()
            : null,
        });
      } else {
        form.setFieldsValue({
          planned_delivery_date: detailInfo?.planned_delivery_date
            ? FormatDayjs(detailInfo.planned_delivery_date).toDate()
            : null,
        });
      }
      getDeliveryUserListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <EditPopup visible={isOpen} submitting={false} setVisible={close}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={false}
          >
            提交
          </Button>
        }
      >
        <Form.Header>转派工单</Form.Header>
        <Form.Item
          name="delivery_user_id"
          label="转派至"
          rules={[{ required: true, message: "必须指定转派人" }]}
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={getDeliveryUserListIdle || gettingDeliveryUserList}
            columns={
              deliveryUserList
                ? [
                    deliveryUserList.map((i) => ({
                      label: `${i.userName} (${i.jobTitle})`,
                      value: i.id,
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>指定转派人</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="transfer_reason"
          label="转派原因"
          rules={[{ required: true, message: "必须填写转派原因" }]}
        >
          <TextArea placeholder="填写转派原因" autoSize={{ minRows: 3 }} />
        </Form.Item>
        <Form.Item
          name={
            detailInfo?.process_name === "硬件退回"
              ? "planned_return_date"
              : "planned_delivery_date"
          }
          label={
            detailInfo?.process_name === "硬件退回"
              ? "计划退回日期"
              : "计划交付日期"
          }
          rules={
            detailInfo?.process_name === "硬件退回"
              ? [{ required: true, message: "必须选择计划退回日期" }]
              : [{ required: true, message: "必须选择计划交付日期" }]
          }
          trigger="onConfirm"
          validateTrigger="onConfirm"
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open();
          }}
          layout="horizontal"
        >
          <DatePicker title="计划交付日期" precision="day" tillNow>
            {(value) =>
              value ? (
                <FormItemDiv>{FormatDate(value)}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>计划交付日期</FormItemPlaceholder>
              )
            }
          </DatePicker>
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
