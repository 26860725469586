import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
} from "react";
import { Divider, Spin } from "antd";
import { useDebounceFn } from "ahooks";

import { exeChildFunc } from "authenticated-app/pc/customer/customer-detail/interface";
import { useHttp } from "utils/http";
import {
  ADD_REMOTE_RETAIN,
  DEL_REMOTE_RETAIN,
  GET_REMOTE_DETAIL,
  UPDATE_REMOTE_RETAIN,
} from "lib/api";
import { RemoteDetailType } from "./interface";
import RemoteBaseInfo from "./remote-base-info";
import RemoteAssetList from "./remote-asset-list.tsx";
import RemoteRetainInfo from "./remote-retain-info";
import MonitorInfo from "./monitor-info";
import { useAsync } from "utils/hooks/useAsync";

import "../../index.css";

const RemoteTabPane = forwardRef<
  exeChildFunc,
  {
    hospID: number | null;
    hospCode: string | undefined;
    hospitalName: string | undefined;
    setDisChange: Dispatch<SetStateAction<boolean>>;
  }
>(({ hospID, hospCode, hospitalName, setDisChange }, ref) => {
  const client = useHttp();

  const { run, isLoading, data: detail } = useAsync<RemoteDetailType>();
  const getDetail = () => {
    if (hospID) {
      run(client(GET_REMOTE_DETAIL(hospID)));
    }
  };
  const { run: getDetailDebounce } = useDebounceFn(getDetail, {
    wait: 200,
  });

  useImperativeHandle(ref, () => ({
    exeFunc() {
      getDetail();
    },
  }));

  useEffect(() => {
    getDetailDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospID]);

  return (
    <Spin spinning={isLoading}>
      <RemoteBaseInfo
        detail={detail || undefined}
        reload={getDetail}
        setDisChange={setDisChange}
      />
      <Divider style={{ margin: "8px 0" }} />
      <MonitorInfo
        detail={detail || undefined}
        hospCode={hospCode}
        hospitalName={hospitalName}
        hospID={detail?.hospital_id}
        mode="detail"
      />
      <Divider style={{ margin: "8px 0" }} />
      <RemoteAssetList assetInfo={detail?.assets} />
      <Divider style={{ margin: "8px 0" }} />
      <RemoteRetainInfo
        hospID={hospID}
        retain={detail?.retain_infos}
        RetainAPI={{
          ADD_RETAIN: ADD_REMOTE_RETAIN,
          UPDATE_RETAIN: UPDATE_REMOTE_RETAIN,
          DEL_RETAIN: DEL_REMOTE_RETAIN,
        }}
        reload={getDetail}
      />
    </Spin>
  );
});

export default RemoteTabPane;
