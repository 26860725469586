import { MutableRefObject, useEffect, useState } from "react";
import { ActionType } from "@ant-design/pro-components";
import {
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
  Upload,
  UploadFile,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import {
  DateFormate,
  DisposeQuill,
  HospSelectFilter,
  handleUpload,
  limitUpload,
} from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import {
  GET_EVENT_TYPE,
  GET_VISIT_TYPE,
  ADD_VISIT_LIST,
  GET_BUSINESS_TYPE,
} from "lib/api/issues";
import { useDebounceFn } from "ahooks";
import {
  ADD_FILE,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_LIST,
  HOSP_LIST_API,
  GET_PRODUCT_DEPARTMENT,
} from "lib/api";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import { editQuillToolbarOption } from "authenticated-app/pc/components/EditQuill/option";
import {
  ProductInfoType,
  ProductType,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import { HospListType } from "interface";
import { handlePreview } from "utils/handlePreview";

import "./index.css";
import "authenticated-app/pc/components/EditQuill/quill.snow.css";

import { VisitType, EventType, BusinessType } from "../interface";
import { ProductUsingDepartmentType } from "authenticated-app/pc/customer/customer-detail/tab-pane/productTabPane/interface";
import dayjs from "dayjs";
import type { RangePickerProps } from "antd/es/date-picker";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-zzone";
// import { useBreaklistAdd } from "authenticated-app/hooks/breaklist/useBreaklistAdd";
import { useVisitListAdd } from "authenticated-app/hooks/visitlist/useVisitListAdd";
Quill.register("modules/imageResize", ImageResize);

const { Item } = Form;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
};

export const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  return current > dayjs().endOf("day");
};

export const AddModal = ({
  actionRef,
}: {
  actionRef: MutableRefObject<ActionType | undefined>;
}) => {
  const [isOpen, , close] = useVisitListAdd();
  const [form] = Form.useForm();

  const [isCustomer, setIsCustomer] = useState(true);
  const [isEvent, setIsEvent] = useState(true);
  const [isBusiness, setIsBusiness] = useState(true);
  // const [isAdditional, setIsAdditional] = useState(false);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  // const [serviceConfirmationfile, setServiceConfirmationfile] = useState<
  //   UploadFile[]
  // >([]);
  // const [departureConfirmationfile, setDepartureConfirmationfile] = useState<
  //   UploadFile[]
  // >([]);

  const [selectedHospId, setSelectedHospId] = useState<number>();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const client = useHttp();

  const {
    run: getHosp,
    data: hospList,
    isIdle: getHospIsIdle,
    isLoading: gettingHospList,
  } = useAsync<HospListType[]>();
  const getHospFunc = (params?: { [key in any]: any }) => {
    getHosp(
      client(HOSP_LIST_API, {
        data: {
          ...params,
        },
      }),
    );
  };
  const { run: getHospDebounce } = useDebounceFn(getHospFunc, { wait: 200 });

  const { run: AddVisitlist, isLoading: AddingVisitlist } = useAsync();

  const {
    run: getProductList,
    isIdle: getProductListIsIdle,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductInfoType[]>();
  const getProductListHandle = () => {
    if (selectedHospId) {
      getProductList(client(GET_PRODUCT_DETAIL(selectedHospId)));
    }
  };

  const {
    run: getAllProductList,
    isIdle: getAllProductListIsIdle,
    isLoading: gettingAllProductList,
    data: allProductList,
  } = useAsync<ProductType[]>();
  const getAllProductListHandle = () => {
    getAllProductList(client(GET_PRODUCT_LIST));
  };

  // 科室类型
  const {
    run: getDepartmentList,
    data: departTypeList,
    isIdle: getDepartTypeListIsIdle,
    isLoading: gettingDepartTypeList,
  } = useAsync<ProductUsingDepartmentType[]>();
  const getDepartmentListHandle = () => {
    getDepartmentList(client(GET_PRODUCT_DEPARTMENT));
  };
  const { run: getDepartTypeListDebounce } = useDebounceFn(
    getDepartmentListHandle,
    {
      wait: 200,
    },
  );

  // 拜访类型
  const {
    run: getVisitTypeList,
    data: visitTypeList,
    isIdle: getVisitTypeListIsIdle,
    isLoading: gettingVisitTypeList,
  } = useAsync<VisitType[]>();
  const getVisitTypeListHandle = () => {
    getVisitTypeList(client(GET_VISIT_TYPE));
  };
  const { run: getVisitTypeListDebounce } = useDebounceFn(
    getVisitTypeListHandle,
    {
      wait: 200,
    },
  );

  //   重大事件类别
  const {
    run: getEventTypeList,
    data: eventTypeList,
    isIdle: getEventTypeListIsIdle,
    isLoading: gettingEventTypeList,
  } = useAsync<EventType[]>();
  const getEventTypeListHandle = () => {
    getEventTypeList(client(GET_EVENT_TYPE));
  };
  const { run: getEventTypeListDebounce } = useDebounceFn(
    getEventTypeListHandle,
    {
      wait: 200,
    },
  );

  // 商机类别
  const {
    run: getBusinessTypeList,
    data: BusinessTypeList,
    isIdle: getBusinessTypeListIsIdle,
    isLoading: gettingBusinessTypeList,
  } = useAsync<BusinessType[]>();
  const getBusinessTypeListHandle = () => {
    getBusinessTypeList(client(GET_BUSINESS_TYPE));
  };
  const { run: getBusinessTypeListDebounce } = useDebounceFn(
    getBusinessTypeListHandle,
    {
      wait: 200,
    },
  );

  const onSubmit = (values: any) => {
    AddVisitlist(
      client(ADD_VISIT_LIST, {
        method: "POST",
        data: {
          ...values,
          visit_date: values.visit_date.format(DateFormate),
          attachment_id_list: fileList.map((i) => i.uid),
        },
      }),
    ).then(() => {
      close();
      actionRef.current?.reload();
    });
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      // setIsCustomer(true);
      // setSelectedHospId(undefined);
      setIsEvent(false);
      setIsBusiness(false);
      setFileList([]);
      if (!!!hospList) {
        getHospDebounce();
      }
      if (!!!visitTypeList) {
        getVisitTypeListDebounce();
      }
      if (!!!departTypeList) {
        getDepartTypeListDebounce();
      }
      if (!!!eventTypeList) {
        getEventTypeListDebounce();
      }
      if (!!!BusinessTypeList) {
        getBusinessTypeListDebounce();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!!!isCustomer) {
      getAllProductListHandle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomer]);

  useEffect(() => {
    if (selectedHospId) {
      getProductListHandle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHospId]);

  const loading = getHospIsIdle || gettingHospList;

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="新建拜访记录"
      centered
      className="breaklist-add-modal"
      onCancel={close}
      onOk={form.submit}
      confirmLoading={AddingVisitlist}
      width={"912px"}
      bodyStyle={{
        minHeight: "12rem",
        maxHeight: "80vh",
        overflow: "auto",
      }}
    >
      <Form form={form} {...formItemLayout} onFinish={onSubmit}>
        {isCustomer ? (
          <Item
            name="hospital_id"
            label="客户名称"
            rules={[{ required: true, message: "必须填写客户名称" }]}
          >
            <Select
              loading={loading}
              placeholder="选择客户名称"
              showSearch
              filterOption={HospSelectFilter}
              onChange={(id) => {
                setSelectedHospId(id);
              }}
              dropdownRender={(originNode) => (
                <Spin spinning={loading}>{originNode}</Spin>
              )}
              options={hospList?.map((i) => ({ label: i.name, value: i.id }))}
            />
          </Item>
        ) : (
          <Item
            name="customer_name"
            label="客户名称"
            rules={[{ required: true, message: "必须填写客户名称" }]}
          >
            <Input placeholder="填写客户名称" />
          </Item>
        )}
        <Item
          name="product_id_list"
          label="涉及产品线"
          rules={[{ required: true, message: "必须选择涉及产品线" }]}
        >
          {!!!isCustomer ? (
            <Select
              mode="multiple"
              allowClear
              showSearch
              filterOption={HospSelectFilter}
              loading={gettingAllProductList || getAllProductListIsIdle}
              placeholder="选择产品线"
              dropdownRender={(originNode) => (
                <Spin
                  spinning={gettingAllProductList || getAllProductListIsIdle}
                >
                  {originNode}
                </Spin>
              )}
            >
              {allProductList?.map((i, index) => (
                <Option key={index} value={i?.title}>
                  {`${i?.title} (${i?.chinese_name})`}
                </Option>
              ))}
            </Select>
          ) : (
            <Select
              disabled={!!!selectedHospId}
              mode="multiple"
              allowClear
              showSearch
              filterOption={HospSelectFilter}
              loading={gettingProductList}
              placeholder={!!!selectedHospId ? "请先选择客户" : "选择产品线"}
              dropdownRender={(originNode) => (
                <Spin spinning={gettingProductList || getProductListIsIdle}>
                  {originNode}
                </Spin>
              )}
            >
              {productList?.map((i, index) => (
                <Option key={index} value={i?.product_id}>
                  {`${i?.product_id} (${i?.product_chinese_name})`}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item
          name="visit_date"
          label="拜访日期"
          rules={[{ required: true, message: "必须填写拜访日期" }]}
        >
          <DatePicker style={{ width: "100%" }} disabledDate={disabledDate} />
        </Item>
        <Item
          name="department_type_id_list"
          label="拜访科室"
          rules={[{ required: true, message: "必须选择拜访科室" }]}
        >
          <Select
            mode="multiple"
            loading={gettingDepartTypeList || getDepartTypeListIsIdle}
            placeholder="选择拜访科室"
            dropdownRender={(originNode) => (
              <Spin spinning={gettingDepartTypeList || getDepartTypeListIsIdle}>
                {originNode}
              </Spin>
            )}
          >
            {departTypeList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="visit_type_id_list"
          label="拜访类别"
          rules={[{ required: true, message: "必须选择拜访类别" }]}
        >
          <Select
            mode="multiple"
            loading={gettingVisitTypeList || getVisitTypeListIsIdle}
            placeholder="选择拜访类别"
            dropdownRender={(originNode) => (
              <Spin spinning={gettingVisitTypeList || getVisitTypeListIsIdle}>
                {originNode}
              </Spin>
            )}
          >
            {visitTypeList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>

        <Item
          name="description"
          label="拜访记录"
          rules={[{ required: true, message: "必须填写拜访记录" }]}
          normalize={(v) => DisposeQuill(v)}
        >
          <ReactQuill
            theme={"snow"}
            modules={{
              toolbar: editQuillToolbarOption,
              imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
              },
            }}
          />
        </Item>
        <Item name="of_num" label="产品反馈OF记录">
          <Input placeholder="填写OF记录" />
        </Item>
        <Item name="event_type_id" label="重大事件类别">
          <Select
            // mode="multiple"
            loading={gettingEventTypeList || getEventTypeListIsIdle}
            placeholder="选择重大事件类别"
            dropdownRender={(originNode) => (
              <Spin spinning={gettingEventTypeList || getEventTypeListIsIdle}>
                {originNode}
              </Spin>
            )}
            onChange={(v) => {
              if (v !== null) {
                setIsEvent(true);
              }
            }}
          >
            {eventTypeList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        {isEvent ? (
          <Item
            name="event_desc"
            label="重大事件记录"
            normalize={(v) => DisposeQuill(v)}
            rules={[{ required: true, message: "必须填写重大事件记录" }]}
          >
            <ReactQuill
              theme={"snow"}
              modules={{
                toolbar: editQuillToolbarOption,
                imageResize: {
                  parchment: Quill.import("parchment"),
                  modules: ["Resize", "DisplaySize"],
                },
              }}
            />
          </Item>
        ) : (
          <Item
            name="event_desc"
            label="重大事件记录"
            normalize={(v) => DisposeQuill(v)}
          >
            <ReactQuill
              theme={"snow"}
              modules={{
                toolbar: editQuillToolbarOption,
                imageResize: {
                  parchment: Quill.import("parchment"),
                  modules: ["Resize", "DisplaySize"],
                },
              }}
            />
          </Item>
        )}

        <Item name="business_opportunity_type_id_list" label="商机类别">
          <Select
            mode="multiple"
            loading={gettingBusinessTypeList || getBusinessTypeListIsIdle}
            placeholder="选择商机类别"
            dropdownRender={(originNode) => (
              <Spin
                spinning={gettingBusinessTypeList || getBusinessTypeListIsIdle}
              >
                {originNode}
              </Spin>
            )}
            onChange={(v) => {
              if (v !== null) {
                setIsBusiness(true);
              }
            }}
          >
            {BusinessTypeList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        {isBusiness ? (
          <Item
            name="business_opportunity_description"
            label="商机内容记录"
            normalize={(v) => DisposeQuill(v)}
            rules={[{ required: true, message: "必须填写商机内容记录" }]}
          >
            <ReactQuill
              theme={"snow"}
              modules={{
                toolbar: editQuillToolbarOption,
                imageResize: {
                  parchment: Quill.import("parchment"),
                  modules: ["Resize", "DisplaySize"],
                },
              }}
            />
          </Item>
        ) : (
          <Item
            name="business_opportunity_description"
            label="商机内容记录"
            normalize={(v) => DisposeQuill(v)}
          >
            <ReactQuill
              theme={"snow"}
              modules={{
                toolbar: editQuillToolbarOption,
                imageResize: {
                  parchment: Quill.import("parchment"),
                  modules: ["Resize", "DisplaySize"],
                },
              }}
            />
          </Item>
        )}

        <Item name="material_link" label="运营素材链接">
          <Input placeholder="填写运营素材链接" />
        </Item>
        <Item name="is_special_focus" label="特别关注" initialValue={0}>
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Item>
        <Form.Item name="attachment_id_list" label="附件上传">
          <Upload
            headers={UploadHeaders}
            maxCount={10}
            multiple={true}
            action={ADD_FILE}
            listType="picture-card"
            fileList={fileList}
            beforeUpload={limitUpload}
            onChange={(info) => handleUpload(info, setFileList)}
            onPreview={(f) =>
              handlePreview(f, {
                setPreviewImage,
                setPreviewOpen,
                setPreviewTitle,
              })
            }
          >
            {fileList.length >= 10 ? null : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传(限10个)</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      </Form>
      <Modal
        centered
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
        width={"61.8%"}
      >
        <div style={{ width: "100%" }}>
          <img alt="previwe" style={{ width: "100%" }} src={previewImage} />
        </div>
      </Modal>
    </Modal>
  );
};
