import { useEffect, useState, useRef } from "react";
import {
  Button,
  message,
  Dropdown,
  Badge,
  Space,
  Modal,
  Row,
  Col,
  Statistic,
  Alert,
} from "antd";
import type { MenuProps } from "antd";
import { ProTable, ProFormInstance } from "@ant-design/pro-components";
import type { ProColumns, ActionType } from "@ant-design/pro-components";
import {
  RedoOutlined,
  KeyOutlined,
  DownOutlined,
  LineChartOutlined,
  FileTextOutlined,
  SafetyOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useHttp } from "utils/http";
import {
  hosStatus,
  selectFilter,
  statusColor,
  statusOptions,
  versionParamas,
} from "utils";
import { HospListType } from "interface";
import {
  GET_HOSP_CITY,
  GET_HOSP_NODES,
  GET_HOSP_PWD,
  IMPORT_MAINTENANCE,
  HOSP_LIST_API,
} from "lib/api";
import { CityType, HosNodesType, PamInfoType } from "./interface";
import { useAsync } from "utils/hooks/useAsync";
import { ListOutput } from "./output/list";
import { RateOutput } from "./output/rate";
import { BaseExcelImport } from "./import/base-excel-import";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { customerDetailRoute } from "../../routeSetting";
import { ServerInfoScan } from "./import/server-info-scan";

export const CustomerList = () => {
  const navigate = useNavigate();
  const { run: fetchList, isLoading } = useAsync();
  const [isPamVisible, setIsPamVisible] = useState(false);
  const [pamInfo, setPamInfo] = useState<PamInfoType>();
  const [isMaintenanceImportVisible, setIsMaintenanceImportVisible] =
    useState(false);
  const [serverInfoScanVisible, setServerInfoScanVisible] = useState(false);
  const [isOutputVisible, setIsOutputVisible] = useState(false);
  const [isRateOutputVisible, setIsRateOutputVisible] = useState(false);
  const { height } = useWindowSize();
  const client = useHttp();
  const formRef = useRef<ProFormInstance>();

  const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [provinceList, setProvinceList] = useState<HosNodesType[]>();
  const [cityList, setCityList] = useState<CityType[]>();
  const [nodes, setNodes] = useState<HosNodesType[]>();
  const actionRef = useRef<ActionType>();

  useEffect(() => {
    const loadNodes = async () => {
      const nodes = await client(GET_HOSP_NODES);
      setNodes(nodes);
      const areas = nodes.filter((node: HosNodesType) => node.nodeLevel === 1);
      setAreaList(areas);
    };
    loadNodes();
  }, []);

  const columns: ProColumns<HospListType>[] = [
    {
      title: "客户名称",
      dataIndex: "name",
      width: "30%",
    },
    {
      title: "客户编码",
      dataIndex: "code",
      width: "15%",
    },
    {
      title: "客户状态",
      dataIndex: "hospital_status",
      width: "10%",
      valueType: "select",
      fieldProps: {
        mode: "multiple",
        options: statusOptions,
        allowClear: true,
        maxTagCount: "responsive",
        defaultValue: undefined,
      },
      formItemProps: {
        rules: [],
      },
      render: (_, record) => (
        <Badge
          style={{ marginLeft: "10px", color: "#5B5B5B" }}
          color={statusColor.get(
            (record?.hospital_status as hosStatus) || hosStatus.unknown,
          )}
          text={record?.hospital_status || "未知"}
        />
      ),
    },
    {
      title: "区域",
      dataIndex: "area_id",
      width: "10%",
      valueType: "select",
      fieldProps: {
        options: areaList?.map((item) => ({
          label: item.nodeName,
          value: item.nodeId,
        })),
        onChange: (value: any) => {
          formRef.current?.setFieldsValue({
            province_id: undefined,
            city_id: undefined,
          });
          setProvinceList([]);
          setCityList([]);

          if (value) {
            const provinces = nodes?.filter(
              (node) => node.nodeLevel === 2 && node.parentNodeId === value,
            );
            setProvinceList(provinces);
          }
        },
      },
    },
    {
      title: "省份",
      dataIndex: "province_name",
      key: "province_id",
      width: "10%",
      valueType: "select",
      fieldProps: {
        options:
          provinceList?.map((item) => ({
            label: item.nodeName,
            value: item.nodeId,
          })) || [],
        onChange: async (value: any) => {
          formRef.current?.setFieldsValue({ city_id: undefined });
          if (value) {
            const cities = await client(GET_HOSP_CITY(value));
            setCityList(cities);
          } else {
            setCityList([]);
          }
        },
        showSearch: true,
        filterOption: selectFilter,
        disabled: !provinceList?.length,
        value: formRef.current?.getFieldValue("province_id") || undefined,
      },
      dependencies: ["area_id"],
    },
    {
      title: "城市",
      dataIndex: "city_name",
      width: "20%",
      search: false,
    },
    {
      title: "动态密码",
      dataIndex: "pam_pwd",
      width: "10%",
      search: false,
      align: "center",
      render: (_, record) => (
        <Button
          icon={<KeyOutlined />}
          loading={isPamVisible && pamInfo?.id === record.id}
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            showPam(record);
          }}
        >
          查询
        </Button>
      ),
    },
  ];

  const outputItems: MenuProps["items"] = [
    {
      key: "logout",
      label: "客户信息",
      icon: <FileTextOutlined />,
      onClick: () => setIsOutputVisible(true),
    },
    {
      key: "rate",
      label: "点击率",
      icon: <LineChartOutlined />,
      onClick: () => setIsRateOutputVisible(true),
    },
  ];

  const inputItems: MenuProps["items"] = [
    {
      key: "maintenance",
      label: "维保信息",
      icon: <SafetyOutlined />,
      onClick: () => setIsMaintenanceImportVisible(true),
    },
    {
      key: "serverInfo",
      label: "数据采集",
      icon: <QrcodeOutlined />,
      onClick: () => setServerInfoScanVisible(true),
    },
  ];

  const showPam = (record: HospListType) => {
    if (process.env.REACT_APP_VERSION === "dev") {
      setPamInfo({
        id: record?.id,
        name: record?.name,
        code: record?.code,
        hospitalPwd: "test_pwd",
      });
      setIsPamVisible(true);
    } else {
      client(GET_HOSP_PWD(record?.id))
        .then((response) => {
          setPamInfo({
            name: record?.name,
            code: record?.code,
            ...response[0],
          });
          setIsPamVisible(true);
        })
        .catch((e) => {
          message.error(`获取${record?.code}的动态密码请联系管理员！`);
        });
    }
  };

  return (
    <>
      <ProTable<HospListType>
        columns={columns}
        rowKey="id"
        loading={isLoading}
        actionRef={actionRef}
        search={{
          labelWidth: "auto",
          defaultCollapsed: false,
          span: {
            xs: 24,
            sm: 12,
            md: 8,
            lg: 6,
            xl: 6,
            xxl: 4,
          },
          layout: "horizontal",
        }}
        form={{
          initialValues: {},
        }}
        formRef={formRef}
        toolbar={{
          actions: [
            <Dropdown key="import" menu={{ items: inputItems }}>
              <Button
                loading={isMaintenanceImportVisible || serverInfoScanVisible}
              >
                <Space>
                  导入
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>,
            <Dropdown key="export" menu={{ items: outputItems }}>
              <Button loading={isOutputVisible || isRateOutputVisible}>
                <Space>
                  导出
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>,
            <Button
              key="refresh"
              loading={isLoading}
              icon={<RedoOutlined />}
              type="primary"
              onClick={() => {
                actionRef.current?.reload();
              }}
            >
              刷新
            </Button>,
          ],
        }}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize: Math.max(Math.trunc((height - 318) / 57), 5),
        }}
        request={async (params, sort, filter) => {
          const {
            pageSize = 10,
            current = 1,
            hospital_status,
            ...rest
          } = params;
          const result = await fetchList(
            client(HOSP_LIST_API, {
              data: {
                pageSize,
                current,
                ...rest,
                ...(hospital_status?.length
                  ? { hospital_status: hospital_status.join("|") }
                  : {}),
              },
            }),
          );

          return {
            data: result.results || [],
            total: result.count || 0,
            success: true,
          };
        }}
        onRow={(record) => ({
          onClick: () => {
            navigate({
              pathname: customerDetailRoute,
              search: versionParamas({
                hospitalId: record?.id.toString(),
              }),
            });
          },
        })}
        onReset={() => {
          setProvinceList([]);
          setCityList([]);
          formRef.current?.setFieldsValue({
            area_id: undefined,
            province_id: undefined,
            city_id: undefined,
          });
        }}
      />

      <BaseExcelImport
        visible={isMaintenanceImportVisible}
        setVisible={setIsMaintenanceImportVisible}
        API={IMPORT_MAINTENANCE}
        modelAPI={IMPORT_MAINTENANCE}
        title="维保信息导入"
      />

      <ServerInfoScan
        visible={serverInfoScanVisible}
        setVisible={setServerInfoScanVisible}
      />

      <ListOutput visible={isOutputVisible} setVisible={setIsOutputVisible} />

      <RateOutput
        visible={isRateOutputVisible}
        setVisible={setIsRateOutputVisible}
      />

      <Modal
        title={pamInfo?.name}
        open={isPamVisible}
        onOk={() => setIsPamVisible(false)}
        onCancel={() => setIsPamVisible(false)}
        cancelText={"取消"}
        okText={"确定"}
        centered
      >
        <Row>
          <Col span={12}>
            <Statistic title="医院编号" formatter={() => pamInfo?.code} />
          </Col>
          <Col span={12}>
            <Statistic
              title="动态密码"
              formatter={() => pamInfo?.hospitalPwd}
            />
          </Col>
        </Row>
        <Alert message="密码仅当天有效，请妥善保管，切勿泄露！" type="error" />
      </Modal>
    </>
  );
};
