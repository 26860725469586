import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Form,
  Input,
  Space,
  TextArea,
  Toast,
  Picker,
  Selector,
  Skeleton,
} from "antd-mobile";
import { PickerRef, PickerColumnItem } from "antd-mobile/es/components/picker";
import { AddOutline } from "antd-mobile-icons";
import { Upload } from "antd";
import { UploadFile } from "antd/es/upload/interface";

import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import {
  DiskCapacityList,
  DiskNumberList,
  DisposeForm,
  DisposeMobileText,
  MemoryCapacityList,
  MemoryNumberList,
  SsdCapacityList,
  handleUploadImage,
  initFileList,
} from "utils";
import { EditPopup } from "authenticated-app/mobile/components/EditPopup";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { IpRegex } from "utils/regexp";
import { UploadHeaders } from "authenticated-app/pc/customer/customer-detail/interface";
import {
  ADD_FILE,
  GET_EQPT_MODEL,
  GET_EQPT_OEM,
  GET_EQPT_TYPE,
  GET_HOS_PRODUCT_LIST,
  GET_SYS_VERSION,
  UPDATE_HARDWARE,
} from "lib/api";
import {
  ConvertToPickerValueList,
  MobileLimitUploadImage,
} from "authenticated-app/mobile/util";
import {
  EqptModel,
  EqptOem,
  EqptType,
  HardwareServerType,
  HospProductsListType,
  SystemList,
} from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";
import {
  FormItemDiv,
  FormItemPlaceholder,
} from "authenticated-app/mobile/components/FormItem";

export const MobileServerEdit = ({
  hospID,
  editingItem,
  visible,
  setVisible,
  reload,
}: {
  hospID: string | number | undefined;
  editingItem: HardwareServerType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [eqptTypeID, setEqptTypeID] = useState<number | null>(null);
  const [eqptOemID, setEqptOemID] = useState<number | null>(null);

  const {
    run: getHosProductList,
    isLoading: gettingHosProductList,
    data: hospProductList,
  } = useAsync<HospProductsListType[]>();
  const getHosProductListFunc = (hospital_id: number | string) => {
    getHosProductList(client(GET_HOS_PRODUCT_LIST(hospital_id)));
  };

  const { run: getEqptTypeList, data: eqptTypeList } = useAsync<EqptType[]>();
  const getEqptTypeListFunc = () => {
    getEqptTypeList(client(GET_EQPT_TYPE));
  };

  const {
    run: getEqptOemList,
    isLoading: gettingEqptOemList,
    data: eqptOemList,
  } = useAsync<EqptOem[]>();
  const getEqptOemListFunc = (eqpt_type_id: number) => {
    getEqptOemList(client(GET_EQPT_OEM(eqpt_type_id)));
  };

  const {
    run: getEqptModeList,
    isLoading: gettingEqptModeList,
    data: eqptModeList,
  } = useAsync<EqptModel[]>();
  const getEqptModeListFunc = (eqpt_oem_id: number) => {
    getEqptModeList(client(GET_EQPT_MODEL(eqpt_oem_id)));
  };

  const {
    run: getSysVersionList,
    isLoading: gettingSysVersionList,
    data: sysVersionList,
  } = useAsync<SystemList[]>();
  const getSysVersionListFunc = () => {
    getSysVersionList(client(GET_SYS_VERSION));
  };

  const { run: submit, isLoading: submitting } = useAsync();
  const submitHandle = (value: any) => {
    if (editingItem?.id)
      submit(
        client(UPDATE_HARDWARE(editingItem.id), {
          data: {
            // ...value,
            server_ip: value.server_ip || null,
            products: value.products || null,
            is_master: value?.is_master_server
              ? parseInt(value?.is_master_server[0])
              : null,
            eqpt_oem_id: value?.eqpt_oem ? parseInt(value?.eqpt_oem[0]) : null,
            eqpt_model_id: value?.eqpt_model
              ? parseInt(value?.eqpt_model[0])
              : null,
            maintenance_code: value?.maintenance_code || null,
            assets_code: value?.assets_code || null,
            system_version_id: value?.system_version
              ? parseInt(value?.system_version[0])
              : null,
            ssd_capacity: value?.ssd_capacity_value
              ? parseInt(value?.ssd_capacity_value[0])
              : null,
            memory_capacity: value?.memory_capacity_value
              ? parseInt(value?.memory_capacity_value[0])
              : null,
            memory_num: value?.memory_num_value
              ? parseInt(value?.memory_num_value[0])
              : null,
            disk_capacity: value?.disk_capacity_value
              ? parseInt(value?.disk_capacity_value[0])
              : null,
            disk_num: value?.disk_num_value
              ? parseInt(value?.disk_num_value[0])
              : null,
            location: value?.location || null,
            asset_photos: fileList.map((i) => i.uid),
          },
          method: "PUT",
        }),
      )
        .then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="更新成功" />,
          });
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          Toast.show({
            icon: "fail",
            content: <ToastContent content="更新失败" />,
          });
        });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法提交" />,
      });
  };

  useEffect(() => {
    if (eqptTypeList?.length) {
      let serverID = eqptTypeList.find((t) => t.name === "服务器")?.id;
      if (serverID) {
        setEqptTypeID(serverID);
      }
    }
  }, [eqptTypeList]);

  useEffect(() => {
    if (eqptTypeID) {
      getEqptOemListFunc(eqptTypeID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqptTypeID]);

  useEffect(() => {
    if (eqptOemID) {
      getEqptModeListFunc(eqptOemID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqptOemID]);

  const clear = () => {
    form.setFieldsValue({
      eqpt_model_id: null,
    });
  };

  useEffect(() => {
    if (visible) {
      if (hospID) {
        getHosProductListFunc(hospID);
      }
      getSysVersionListFunc();
      getEqptTypeListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (editingItem && visible) {
      setFileList(initFileList(editingItem?.asset_photos) || []);
      if (editingItem.eqpt_oem_id) {
        getEqptModeListFunc(editingItem.eqpt_oem_id);
      }
      form.setFieldsValue({
        // ...editingItem,
        server_ip: editingItem.server_ip || "",
        products: editingItem.products.map((i) => i.product_id),
        is_master_server: ConvertToPickerValueList(editingItem.is_master),
        eqpt_oem: ConvertToPickerValueList(editingItem.eqpt_oem_id),
        eqpt_model: ConvertToPickerValueList(editingItem.eqpt_model_id),
        maintenance_code: editingItem.maintenance_code || "",
        assets_code: editingItem.assets_code || "",
        system_version: ConvertToPickerValueList(editingItem.system_version_id),
        ssd_capacity_value: ConvertToPickerValueList(editingItem.ssd_capacity),
        memory_capacity_value: ConvertToPickerValueList(
          editingItem.memory_capacity,
        ),
        memory_num_value: ConvertToPickerValueList(editingItem.memory_num),
        disk_capacity_value: ConvertToPickerValueList(
          editingItem.disk_capacity,
        ),
        disk_num_value: ConvertToPickerValueList(editingItem.disk_num),
        location: editingItem.location || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem, visible]);

  return (
    <EditPopup
      visible={visible}
      submitting={submitting}
      setVisible={setVisible}
    >
      <Form
        form={form}
        onFinish={submitHandle}
        layout="horizontal"
        mode="card"
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            loading={submitting}
          >
            提交
          </Button>
        }
      >
        <Form.Header>新增服务器信息</Form.Header>
        <Form.Item
          name="server_ip"
          label="服务器IP"
          rules={[
            { required: true, message: "必须输入IP" },
            {
              pattern: IpRegex(),
              message: "必须输入合法的IP格式",
            },
          ]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input style={{ "--text-align": "right" }} placeholder="请输入IP" />
        </Form.Item>
        <Form.Item
          name="products"
          label="运行产品线"
          normalize={(v) => DisposeForm(v)}
          layout="vertical"
        >
          {gettingHosProductList ? (
            <Skeleton.Paragraph animated />
          ) : (
            <Selector
              multiple
              options={
                hospProductList
                  ? hospProductList.map((i) => ({
                      label: i.product_id,
                      value: i.product_id,
                    }))
                  : []
              }
            />
          )}
        </Form.Item>
        <Form.Item
          name="is_master_server"
          label="是否主机"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须说明是否为(非)主机" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            columns={[
              [
                { label: "是", value: "1" },
                { label: "否", value: "0" },
              ],
            ]}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择主从情况</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="eqpt_oem"
          label="设备品牌"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择设备品牌" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingEqptOemList}
            columns={
              eqptOemList
                ? [
                    eqptOemList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
            onConfirm={(v) => {
              clear();
              setEqptOemID(v[0] ? parseInt(v[0] as string) : null);
            }}
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择设备品牌</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="eqpt_model"
          label="设备型号"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择设备型号" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingEqptModeList}
            columns={
              eqptModeList
                ? [
                    eqptModeList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择设备型号</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="maintenance_code"
          label="维保代码"
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input
            style={{ "--text-align": "right" }}
            placeholder="输入维保代码"
          />
        </Form.Item>
        <Form.Item
          name="assets_code"
          label="资产编码"
          rules={[{ required: true, message: "必须输入资产编码" }]}
          normalize={(v) => DisposeMobileText(v)}
        >
          <Input
            style={{ "--text-align": "right" }}
            placeholder="请输入资产编码"
          />
        </Form.Item>
        <Form.Item
          name="system_version"
          label="系统版本"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择系统版本" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            loading={gettingSysVersionList}
            columns={
              sysVersionList
                ? [
                    sysVersionList.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择系统版本</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="ssd_capacity_value"
          label="SSD配置"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择SSD配置" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            columns={
              SsdCapacityList
                ? [
                    SsdCapacityList.map((i) => ({
                      label: i,
                      value: i.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择SSD大小(GB)</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Header>内存条配置</Form.Header>
        <Form.Item
          name="memory_capacity_value"
          label="内存条大小"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择内存条大小" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            columns={
              MemoryCapacityList
                ? [
                    MemoryCapacityList.map((i) => ({
                      label: i,
                      value: i.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>单根内存条大小(GB)</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="memory_num_value"
          label="内存条数量"
          trigger="onConfirm"
          validateTrigger="onConfirm"
          rules={[{ required: true, message: "必须选择内存条数量" }]}
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            columns={
              MemoryNumberList
                ? [
                    MemoryNumberList.map((i) => ({
                      label: i,
                      value: i.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择内存条数量</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Header>机械硬盘配置</Form.Header>
        <Form.Item
          name="disk_capacity_value"
          label="机械盘大小"
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            columns={
              DiskCapacityList
                ? [
                    DiskCapacityList.map((i) => ({
                      label: i,
                      value: i.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>单个机械盘大小(TB)</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Item
          name="disk_num_value"
          label="机械盘数量"
          trigger="onConfirm"
          normalize={(v) => DisposeForm(v)}
          onClick={(_, pickerRef: RefObject<PickerRef>) =>
            pickerRef.current?.open()
          }
        >
          <Picker
            columns={
              DiskNumberList
                ? [
                    DiskNumberList.map((i) => ({
                      label: i,
                      value: i.toString(),
                    })),
                  ]
                : []
            }
          >
            {(items: (PickerColumnItem | null)[]) =>
              items[0] ? (
                <FormItemDiv>{items[0]?.label}</FormItemDiv>
              ) : (
                <FormItemPlaceholder>请选择机械盘数量</FormItemPlaceholder>
              )
            }
          </Picker>
        </Form.Item>
        <Form.Header>补充信息</Form.Header>
        <Form.Item
          name="location"
          label="放置位置"
          help="服务器放置位置"
          rules={[{ required: true, message: "必须输入放置位置" }]}
          normalize={(v) => DisposeMobileText(v)}
          layout={"vertical"}
        >
          <TextArea autoSize placeholder="请输入放置位置" />
        </Form.Item>
        <Form.Item
          name="asset_photos"
          className="adm-form-item-label-attachment"
          label="资产照片 (数量限制3个)"
          normalize={(v) => DisposeForm(v)}
          layout={"vertical"}
        >
          <Upload
            headers={UploadHeaders}
            maxCount={3}
            className="upload-list-inline"
            action={ADD_FILE}
            listType="picture"
            fileList={fileList}
            beforeUpload={MobileLimitUploadImage}
            onChange={(info) => handleUploadImage(info, setFileList)}
          >
            {fileList.length >= 3 ? null : (
              <Button color="primary" fill="outline">
                <Space>
                  <AddOutline />
                  <span>点击上传</span>
                </Space>
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </EditPopup>
  );
};
