import { MutableRefObject, useEffect } from "react";
import { ActionType } from "@ant-design/pro-components";
import { Form, Modal, Select, Spin } from "antd";
import { CHECKLIST_ENUM_OPTION } from "lib/interface/checklist";
import { useDebounceFn } from "ahooks";

import { useChecklistAdd } from "authenticated-app/hooks/checklist/useChecklistAdd";
import { HospSelectFilter } from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { ADD_CHECKLIST } from "lib/api/checklist";
import { HospListType } from "interface";
import { HOSP_LIST_API } from "lib/api";

import "./index.css";

const { Item } = Form;

interface SubmitType {
  hospital_id: string;
  check_type: string;
}

export const AddModal = ({
  actionRef,
}: {
  actionRef: MutableRefObject<ActionType | undefined>;
}) => {
  const [isOpen, , close] = useChecklistAdd();
  const [form] = Form.useForm<SubmitType>();

  const client = useHttp();
  const { run: AddChecklist, isLoading: AddingChecklist } = useAsync();

  const {
    run: getHosp,
    data: hospList,
    isIdle: getHospIsIdle,
    isLoading: gettingHospList,
  } = useAsync<HospListType[]>();
  const getHospFunc = (params?: { [key in any]: any }) => {
    getHosp(
      client(HOSP_LIST_API, {
        data: {
          ...params,
        },
      }),
    );
  };
  const { run: getHospDebounce } = useDebounceFn(getHospFunc, { wait: 200 });

  const onSubmit = (values: SubmitType) => {
    AddChecklist(
      client(ADD_CHECKLIST, { method: "POST", data: { ...values } }),
    ).then(() => {
      close();
      actionRef.current?.reload();
    });
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      if (!!!hospList) {
        getHospDebounce();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, form]);

  const loading = getHospIsIdle || gettingHospList;

  return (
    <Modal
      centered
      maskClosable={false}
      open={isOpen}
      title="新建Checklist"
      className="checklist-add-modal"
      style={{
        minHeight: "12rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onCancel={close}
      onOk={form.submit}
      confirmLoading={AddingChecklist}
    >
      <Form className="checklist-add-form" form={form} onFinish={onSubmit}>
        <Item
          label="客户名称"
          name="hospital_id"
          rules={[{ required: true, message: "必须选择客户" }]}
        >
          <Select
            loading={loading}
            showSearch
            filterOption={HospSelectFilter}
            style={{ width: "22rem" }}
            placeholder="请选择客户"
            options={hospList?.map((i) => ({ label: i.name, value: i.id }))}
            dropdownRender={(originNode) => (
              <Spin spinning={loading}>{originNode}</Spin>
            )}
          />
        </Item>
        <Item
          label="检查类型"
          name="check_type"
          rules={[{ required: true, message: "必须选择检查类型" }]}
        >
          <Select
            style={{ width: "22rem" }}
            placeholder="请选择类型"
            options={CHECKLIST_ENUM_OPTION}
          />
        </Item>
      </Form>
    </Modal>
  );
};
