import { Fragment, useEffect, useRef } from "react";
import { Button } from "antd";
import { useHttp } from "utils/http";
import { PlusOutlined } from "@ant-design/icons";
import type { ProColumns } from "@ant-design/pro-components";
import { ActionType, ProTable, FormInstance } from "@ant-design/pro-components";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { GET_COMPETITIVE_PRODUCT } from "lib/api";
import { CompetitiveProductInfoType } from "./interface";
import { CompetitiveDetailModal } from "./detail";
import { AddCompetitiveModal } from "./add";
import { useCompetitiveAdd } from "authenticated-app/hooks/Competitive/useCompetitiveAdd";
import { useCompetitiveDetail } from "authenticated-app/hooks/Competitive/useCompetitiveDetail";

export const CompetitiveTables = ({ hospID }: { hospID: number | null }) => {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<FormInstance>();
  const { height } = useWindowSize();
  const client = useHttp();
  const [, openCompetitiveDetail] = useCompetitiveDetail();
  const [CompetitiveAddOpening, openCompetitivetAdd] = useCompetitiveAdd();

  const search = (hospID: number | string) => {
    return client(GET_COMPETITIVE_PRODUCT(hospID));
  };

  const Columns: ProColumns[] = [
    {
      title: "竞品名称",
      dataIndex: "name",
    },
    {
      title: "产品及版本",
      dataIndex: "product_info",
    },
    {
      title: "上线时间",
      dataIndex: "online_date",
    },

    {
      title: "访问链接",
      dataIndex: "access_address",
    },
    {
      title: "用户名密码",
      dataIndex: "user_passwd",
    },
    {
      title: "创建人",
      dataIndex: "create_user",
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
    },
    // {
    //   title: "操作",
    //   dataIndex: "operation",
    //   render: (_, record) => (
    //     <Button type="link" onClick={() => {}}>
    //       查看详情
    //     </Button>
    //   ),
    // },
  ];

  useEffect(() => {
    actionRef.current?.reload();
  }, [hospID]);

  return (
    <Fragment>
      <ProTable<CompetitiveProductInfoType>
        style={{
          minWidth: 988,
        }}
        rowKey="id"
        actionRef={actionRef}
        formRef={formRef}
        cardBordered
        columns={Columns}
        request={async (params) => {
          try {
            if (hospID) {
              const res = await search(hospID);
              return {
                data: res,
                total: res.length,
                success: true,
              };
            } else {
              return { success: false };
            }
          } catch (e) {
            return { success: false };
          }
        }}
        search={false}
        pagination={{
          showQuickJumper: true,
          showSizeChanger: false,
          pageSize:
            Math.trunc((height - 382) / 47) > 5
              ? Math.trunc((height - 382) / 47)
              : 5,
        }}
        onRow={(record) => ({
          onClick: () => openCompetitiveDetail(record.id),
        })}
        toolBarRender={() => [
          <Button
            loading={false}
            icon={<PlusOutlined />}
            type="primary"
            onClick={openCompetitivetAdd}
          >
            创建
          </Button>,
        ]}
      />
      <CompetitiveDetailModal actionRef={actionRef} />
      <AddCompetitiveModal actionRef={actionRef} hospID={hospID} />
    </Fragment>
  );
};
