import { Dispatch, SetStateAction, useEffect } from "react";
import { Button, Form, Input, message, Modal, Select, Space } from "antd";

import { DisposeForm, selectFilter } from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { GET_PRODUCT_BRANCH } from "../api";
import { ProductBranch, ProductClientVersion } from "../interface";

const { Option } = Select;

export const ProductClientSettingEdit = ({
  settingInfo,
  submitAPI,
  visible,
  setVisible,
  reload,
}: {
  settingInfo: ProductClientVersion | undefined;
  submitAPI: (org_id: number) => string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getBranchList,
    isLoading: gettingBranchList,
    data: branchList,
  } = useAsync<ProductBranch[]>();
  const getBranchListFunc = (product_id?: string) => {
    getBranchList(client(GET_PRODUCT_BRANCH(product_id || null)));
  };

  const submit = (value: any) => {
    let id = settingInfo?.id;
    if (id)
      client(submitAPI(id), {
        data: {
          ...value,
          product_id: settingInfo?.product_id,
        },
        method: "PUT",
      })
        .then(() => {
          message.success("修改成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    else message.error("无法请求修改，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    form.setFieldsValue({
      ...settingInfo,
      product_version_list: settingInfo?.product_version_list?.map((i) => i.id),
    });
    getBranchListFunc(settingInfo?.product_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingInfo]);

  return (
    <Modal
      title="修改产品线客户端"
      destroyOnClose
      open={visible}
      maskClosable={false}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          // name="product_id"
          label="产品线"
          rules={[{ required: true, message: "必须选择产品线" }]}
          normalize={(v) => DisposeForm(v)}
        >
          {`${settingInfo?.product_name} (${settingInfo?.product_id})`}
        </Form.Item>
        <Form.Item
          name="product_version_list"
          label="产品分支"
          rules={[{ required: true, message: "必须选择产品分支" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingBranchList}
            placeholder="选择产品分支"
            mode="multiple"
            showSearch
            filterOption={selectFilter}
          >
            {branchList?.map((i, index) => (
              <Option key={index} value={i.id}>
                {i.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="product_client_version_name"
          label="客户端版本"
          rules={[{ required: true, message: "必须输入客户端版本" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input maxLength={50} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
