import { ReactNode, createContext, useContext, useEffect } from "react";

import { useAuth } from "context/auth";
import { Permission } from "interface";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { GET_PERMISSION } from "lib/api";

const PermissionContext = createContext<
  | {
      permission: Permission | null;
    }
  | undefined
>(undefined);

PermissionContext.displayName = "PermissionContext";

export const PermissionProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();

  const client = useHttp();
  const { run: getPermision, data: permission } = useAsync<Permission>();

  useEffect(() => {
    if (user) {
      getPermision(client(GET_PERMISSION));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <PermissionContext.Provider
      value={{
        permission,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermission = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error("usePermission必须在PermissionProvider中使用");
  }
  return context;
};
