import { Fragment, useEffect, useState } from "react";
import {
  Form,
  InfiniteScroll,
  List,
  PullToRefresh,
  SearchBar,
  Tag,
  Grid,
} from "antd-mobile";
import { useDebounceFn } from "ahooks";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { FormatDate, useMount } from "utils";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { BreaklistSearchDropdown } from "./dropdown/search";
import { getSelectParams } from "./dropdown";
import { useAuth } from "context/auth";
import { MobileDeliverylistDetailPopup } from "./detail";
import { useDeliverylistDetail } from "authenticated-app/hooks/deliverylist/useDeliverylistDetail";
import {
  DELIVERY_TYPE,
  DELIVERY_LIST_TYPE,
} from "lib/interface/launchChangeList";
import { launchChangeNameStoreageKey } from "authenticated-app/mobile";

import { GET_DELIVERY_ORDER } from "lib/api/deliverylist";

const pageSize = 15;

export const MobileLaunchChangeList = () => {
  const { user } = useAuth();

  const [{ search_type, select_type }] = useUrlQueryParam([
    "search_type",
    "select_type",
  ]);

  const [, openDeliverylistDetail] = useDeliverylistDetail();

  const client = useHttp();

  const [searchName, setSearchName] = useState<string>(
    window.sessionStorage.getItem(launchChangeNameStoreageKey) || "",
  );

  // 分页
  const [data, setData] = useState<DELIVERY_TYPE[]>([]);
  const [next, setNext] = useState<number>();
  const [hasMore, setHasMore] = useState(false);

  const [searchForm] = Form.useForm();

  const {
    run: getDeliveryOrderList,
    isLoading: gettingDeliveryOrderList,
    isIdle: getDeliveryOrderListIsIdle,
  } = useAsync<DELIVERY_LIST_TYPE>();
  const getDeliveryOrderListHandle = (
    params?: object,
    search?: string,
    search_type?: string,
  ) => {
    let queryParams: object = {};
    if (params) {
      queryParams = { ...params };
    } else {
      let selectParams = getSelectParams(select_type, user);
      queryParams = { ...selectParams };
    }
    if (search) {
      queryParams = {
        ...queryParams,
        [search_type === "dc" ? "order_id" : search_type ? search_type : ""]:
          search || undefined,
      };
    }
    getDeliveryOrderList(
      client(GET_DELIVERY_ORDER, {
        data: { pageSize, current: 1, ...queryParams },
      }),
    ).then((res) => {
      setData(res?.results || []);
      setNext((res?.number || 0) + 1);
      setHasMore(!!res?.links?.next);
    });
  };
  const { run: getDeliveryOrderListBounce } = useDebounceFn(
    getDeliveryOrderListHandle,
    {
      wait: 200,
    },
  );

  const onSubmit = (params: any, search_type?: string) => {
    let subValue = { ...params };
    getDeliveryOrderListHandle({ ...subValue }, searchName, search_type);
  };

  const loadMore = async () => {
    let selectParams = getSelectParams(select_type, user);

    let append = await client(GET_DELIVERY_ORDER, {
      data: {
        pageSize,
        current: next || 1,
        ...selectParams,
        [search_type === "dc" ? "order_id" : search_type]:
          searchName || undefined,
      },
    });
    setData((val) => [...val, ...(append?.results || [])]);
    setNext((append?.number || 0) + 1);
    setHasMore(!!append?.links?.next);
  };

  const getSearchPlaceholder = (search_type: string) => {
    switch (search_type) {
      case "dc":
        return "搜索DC号码";
      case "customer_name":
        return "搜索客户名称";
      case "delivery_user_name":
        return "搜索交付负责人姓名";
    }
  };

  useMount(() =>
    getDeliveryOrderListBounce(undefined, searchName, search_type),
  );

  const Loading = gettingDeliveryOrderList || getDeliveryOrderListIsIdle;

  return (
    <div>
      <LoadingMask visible={Loading} />
      <div className={"mobile-header"}>
        <BreaklistSearchDropdown form={searchForm} onSubmit={onSubmit} />
        <SearchBar
          defaultValue={searchName}
          placeholder={getSearchPlaceholder(search_type)}
          onSearch={(v) => {
            getDeliveryOrderListHandle(undefined, v, search_type);
            setSearchName(v);
            window.sessionStorage.setItem(launchChangeNameStoreageKey, v || "");
          }}
          onClear={() => {
            getDeliveryOrderListHandle(undefined, undefined, search_type);
            setSearchName("");
            window.sessionStorage.removeItem(launchChangeNameStoreageKey);
          }}
          style={{ width: "100%" }}
        />
        {/* <BreaklistSelectDropdown form={selectForm} onSubmit={onSubmit} /> */}
      </div>
      <div className={"mobile-body"}>
        <PullToRefresh
          onRefresh={async () => {
            getDeliveryOrderListHandle(undefined, searchName, search_type);
          }}
          refreshingText={null}
        >
          <Fragment>
            <List>
              {data?.map((i) => (
                <List.Item
                  key={i.id}
                  description={
                    <>
                      <Grid columns={1}>
                        <Grid.Item>
                          <span>{i?.delivery_user_name}</span>
                        </Grid.Item>
                      </Grid>
                      <Grid columns={1}>
                        <Grid.Item>
                          <span> {i?.delivery_type_name}</span>
                          <span style={{ marginLeft: "8px" }}>
                            {FormatDate(i?.create_time)}
                          </span>
                        </Grid.Item>
                      </Grid>
                    </>
                  }
                  onClick={() => {
                    openDeliverylistDetail(i.id);
                  }}
                  arrow={
                    <div
                      style={{
                        fontSize: "var(--adm-font-size-main)",
                        textAlign: "end",
                        color: "var(--adm-color-weak)",
                      }}
                    >
                      <div>
                        {i?.is_urgent_delivery ? (
                          <Tag color="#ff6430" fill="outline">
                            紧急
                          </Tag>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>{i?.process_name}</div>
                    </div>
                  }
                >
                  <span style={{ wordBreak: "break-all" }}>
                    [{i?.order_id}]&nbsp;
                    {i?.hospital_name
                      ? i?.hospital_name
                      : i?.customer_name
                      ? i?.customer_name
                      : "未知客户"}
                  </span>
                </List.Item>
              ))}
            </List>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
          </Fragment>
        </PullToRefresh>
        <MobileDeliverylistDetailPopup
          reload={() =>
            getDeliveryOrderListHandle(undefined, searchName, search_type)
          }
        />
      </div>
    </div>
  );
};
