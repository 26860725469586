import { Dispatch, SetStateAction, useEffect } from "react";
import { Button, Descriptions, Space, Select, message, Form } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import { GET_REMOTE_TYPE, UPDATE_REMOTE_STATUS } from "lib/api";
import { RemoteDesItemLabel, LineDiv } from "../../utils";

import { RemoteDetailType, RemoteTypes } from "../interface";
import { DisposeForm } from "utils";
import { useAsync } from "utils/hooks/useAsync";

const { Option } = Select;

export const RemoteBaseInfoEdit = ({
  editingItem,
  setEditable,
  setDisChange,
  reload,
}: {
  editingItem: RemoteDetailType | undefined;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setDisChange: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getRemoteTypeList,
    isLoading: gettingRemoteTypeList,
    data: remoteTypeList,
  } = useAsync<RemoteTypes[]>();
  const getRemoteTypeListFunc = () => {
    getRemoteTypeList(client(GET_REMOTE_TYPE));
  };

  const submit = (value: any) => {
    if (editingItem?.hospital_id)
      client(UPDATE_REMOTE_STATUS(editingItem?.hospital_id), {
        data: {
          ...value,
        },
        method: "PUT",
      })
        .then(() => {
          message.success("更新成功！");
          setEditable(false);
          setDisChange(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("更新失败！请检查或者联系管理员");
        });
    else message.error("无法提交更新，请刷新重试或联系管理员！");
  };

  useEffect(() => {
    if (editingItem) {
      getRemoteTypeListFunc();
      form.setFieldsValue({
        ...editingItem,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  return (
    <Form form={form} onFinish={submit}>
      <Descriptions
        title="远程基本信息"
        column={2}
        extra={
          <Space>
            <Button
              onClick={() => {
                setEditable(false);
                setDisChange(false);
              }}
              icon={<CloseOutlined />}
            >
              取消
            </Button>
            <Button type="primary" htmlType="submit" icon={<CheckOutlined />}>
              提交
            </Button>
          </Space>
        }
      >
        <Descriptions.Item label={<RemoteDesItemLabel label={"远程方式"} />}>
          <LineDiv>
            <Form.Item
              name="remote_type_id"
              // rules={[{ required: true, message: "必须选择远程方式" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingRemoteTypeList}
                style={{ width: 180 }}
                allowClear={true}
              >
                {remoteTypeList?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        {/* <Descriptions.Item label={<RemoteDesItemLabel label={"连接状态"} />}>
          <LineDiv>
            <Form.Item
              name="status"
              rules={[{ required: true, message: "必须选择连接状态" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select style={{ width: 180 }}>
                {["正常", "暂停", "未连接", "未知"]?.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item> */}
        <Descriptions.Item label={<RemoteDesItemLabel label={"MQTT密码"} />}>
          <LineDiv>{editingItem?.mqtt_passwd || "未知"}</LineDiv>
        </Descriptions.Item>
      </Descriptions>
    </Form>
  );
};
