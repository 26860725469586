import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useDeliverylistAdd = () => {
  const [{ openDeliverylistAdd }, setopenDeliverylistAdd] = useUrlQueryParam([
    "openDeliverylistAdd",
  ]);

  const open = () => setopenDeliverylistAdd({ openDeliverylistAdd: true });
  const close = () =>
    setopenDeliverylistAdd({ openDeliverylistAdd: undefined });

  return [openDeliverylistAdd === "true", open, close] as const;
};
