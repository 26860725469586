import { useEffect } from "react";
import { Form, Modal, Select, Input } from "antd";
import { useDebounceFn } from "ahooks";

import { useBreakDetailTransfer } from "authenticated-app/hooks/breaklist/trans/useBreakDetailTransfer";
import { GET_DEPARTMENT_USERS } from "lib/api/issues";
import {
  BREAK_DETAIL_TYPE,
  ORDER_DEPARTMENT_TYPE,
} from "lib/interface/breaklist";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import { HospSelectFilter } from "utils";

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const BreakDetailTransferModal = ({
  onTrans,
  next,
  department,
  detailInfo,
}: {
  onTrans: (value: any) => void;
  next: number[];
  department: number[];
  detailInfo: BREAK_DETAIL_TYPE | null;
}) => {
  const [isOpen, , close] = useBreakDetailTransfer();

  const [form] = Form.useForm();
  const client = useHttp();

  const {
    run: getDepartmentList,
    data: departmentList,
    isIdle: getDepartmentListIdle,
    isLoading: gettingDepartmentList,
  } = useAsync<ORDER_DEPARTMENT_TYPE[]>();
  const getDepartmentListHandle = () => {
    getDepartmentList(client(GET_DEPARTMENT_USERS(department)));
  };
  const { run: getDepartmentListDebounce } = useDebounceFn(
    getDepartmentListHandle,
    {
      wait: 200,
    },
  );

  const onFinish = (value: any) => {
    onTrans({ ...value, next_process: next });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        up_user_id: undefined,
        reasons_for_transfer: detailInfo?.reasons_for_transfer,
        remote_info: detailInfo?.remote_info,
      });
      getDepartmentListDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, detailInfo]);

  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title="转派工单"
      onCancel={close}
      onOk={form.submit}
      width={"550px"}
      style={{ minWidth: "550px" }}
    >
      <Form form={form} onFinish={onFinish} {...layout}>
        <Form.Item
          name="up_user_id"
          label="转派至"
          rules={[{ required: true, message: "必须指定转派人" }]}
        >
          <Select
            showSearch
            loading={getDepartmentListIdle || gettingDepartmentList}
            placeholder="指定转派人"
            filterOption={HospSelectFilter}
            options={departmentList?.map((i) => ({
              label: `${i.user_name} (${i.user_job})`,
              value: i.user_id,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="reasons_for_transfer"
          label="转派原因"
          rules={[{ required: true, message: "必须填写转派原因" }]}
        >
          <TextArea placeholder="填写转派原因" autoSize={{ minRows: 3 }} />
        </Form.Item>
        <Form.Item
          name="remote_info"
          label="远程信息"
          rules={[{ required: true, message: "必须填写远程信息" }]}
        >
          <TextArea placeholder="填写远程信息" autoSize={{ minRows: 3 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
