import { MyPieChartType } from "../../chart/pie-chart";
import { StatisticsHospType } from "../../interface";

export const GetHospTypePie = (data: StatisticsHospType[]) => {
  let tmpData: MyPieChartType = {
    data: [],
  };

  let tmpPublic = {
    name: "公立医院",
    value: 0,
  };
  data.forEach((item) => {
    if (item.is_public === 1) {
      tmpPublic.value += item.count;
    } else {
      tmpData.data.push({
        name: item.type,
        value: item.count,
      });
    }
  });
  tmpData.data.push(tmpPublic);

  return tmpData;
};
