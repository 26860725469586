import { MutableRefObject, useEffect, useState } from "react";
import { ActionType } from "@ant-design/pro-components";
import { Form, Modal, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useDebounceFn } from "ahooks";

import { HospSelectFilter, useMount } from "utils";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { SHARE_CHECKLIST } from "lib/api/checklist";
import { useShareChecklist } from "authenticated-app/hooks/checklist/useShareChecklist";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { SHARED_USER_TYPE } from "lib/interface/checklist";
import { IUser } from "authenticated-app/pc/setting/customer-setting/interface";
import { GET_USER_LIST } from "authenticated-app/pc/setting/permission-setting/api";

import "./index.css";

const { Item } = Form;

export const ShareModal = ({
  share_users,
  actionRef,
}: {
  share_users?: SHARED_USER_TYPE[];
  actionRef: MutableRefObject<ActionType | undefined>;
}) => {
  const [isOpen, , close] = useShareChecklist();
  const [{ detailId }] = useUrlQueryParam(["detailId"]);
  const [form] = Form.useForm();

  const [userListOption, setUserListOption] = useState<DefaultOptionType[]>([]);

  const client = useHttp();
  const { run: ShareChecklist, isLoading: SharingChecklist } = useAsync();
  const {
    run: getUserList,
    isLoading: gettingUserList,
    isIdle,
  } = useAsync<IUser[]>();
  const getUserListHandle = () => {
    getUserList(client(GET_USER_LIST)).then((res: IUser[]) => {
      let options: DefaultOptionType[] = [];
      res?.forEach((i) => {
        options.push({ label: i.userName, value: i.id });
      });
      setUserListOption(options);
    });
  };
  const { run: getUserListDebounce } = useDebounceFn(getUserListHandle, {
    wait: 200,
  });

  const onSubmit = (values: any) => {
    if (detailId) {
      ShareChecklist(
        client(SHARE_CHECKLIST, {
          method: "POST",
          data: { check_list_id: detailId, ...values },
        }),
      ).then(() => {
        close();
        actionRef.current?.reload();
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      form.setFieldsValue({
        user_id_list: share_users?.map((i) => i.user_id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useMount(getUserListDebounce);

  const Loading = gettingUserList || isIdle;

  return (
    <Modal
      centered
      maskClosable={false}
      open={isOpen}
      title="分享Checklist"
      className="checklist-share-modal"
      style={{
        minHeight: "12rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onCancel={close}
      onOk={form.submit}
      confirmLoading={SharingChecklist}
    >
      <Form className="checklist-share-form" form={form} onFinish={onSubmit}>
        <Item label="选择分享人" name="user_id_list">
          <Select
            mode="multiple"
            loading={Loading}
            showSearch
            filterOption={HospSelectFilter}
            style={{ width: "22rem" }}
            placeholder="请选分享人"
            options={userListOption}
          />
        </Item>
      </Form>
    </Modal>
  );
};
