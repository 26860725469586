import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Descriptions,
  Space,
  Select,
  message,
  DatePicker,
  Form,
  Input,
} from "antd";
import dayjs from "dayjs";

import { useHttp } from "utils/http";
import {
  UPDATE_PRODUCT_DETAIL,
  GET_PRODUCT_LIST,
  GET_DEPLOY_TYPE,
  GET_PRODUCT_DEPARTMENT,
  GET_SERVICE_TYPE,
  GET_USING_STATUS,
  GET_SERVICE_LEVEL,
  UPDATE_CUSTOMIZATION,
  GET_PRODUCT_VERSION_BY_ID,
  GET_PRODUCT_CLIENT,
} from "lib/api";
import { ProductDesItemLabel, LineDiv } from "../../utils";

import {
  ProductClientType,
  ProductDeployType,
  ProductInfoType,
  ProductIsCustomization,
  ProductServiceLevelType,
  ProductServiceType,
  ProductType,
  ProductUsingDepartmentType,
  ProductUsingType,
  ProductVersionType,
} from "../interface";
import {
  CT_LUNG_VERSION_TYPE,
  DateFormate,
  DisabledDate,
  DisposeForm,
  formatDate,
  RID,
  selectFilter,
} from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { Label } from "@icon-park/react";

const { Option } = Select;

export const ProductBaseInfoEdit = ({
  editingItem,
  editable,
  setEditable,
  setDisChange,
  reload,
}: {
  editingItem: ProductInfoType;
  editable: boolean;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setDisChange: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [isModify, setIsModify] = useState(false);
  const [serviceType, setServiceType] = useState<number>();

  const licenseStatus = [
    { value: 1, label: "已授权" },
    { value: 2, label: "未授权" },
    { value: 3, label: "无需授权" },
  ];
  const {
    run: updateCustomizationStatus,
    isLoading: updattingCustomizationStatus,
  } = useAsync<ProductIsCustomization>();
  const updateCustomizationStatusFunc = (checked: boolean) => {
    updateCustomizationStatus(
      client(UPDATE_CUSTOMIZATION(editingItem.id), {
        data: {
          is_customization: checked ? 1 : 0,
        },
        method: "PUT",
      }),
    ).then((res) => {
      form.setFieldsValue({
        ...res,
      });
    });
  };

  const {
    run: getProductList,
    isLoading: gettingProductList,
    data: productList,
  } = useAsync<ProductType[]>();
  const getProductListFunc = () => {
    getProductList(client(GET_PRODUCT_LIST));
  };

  const {
    run: getProductVersionList,
    isLoading: gettingProductVersionList,
    data: productVersionList,
  } = useAsync<ProductVersionType[]>();
  const getProductVersionListFunc = (product_id: string) => {
    getProductVersionList(client(GET_PRODUCT_VERSION_BY_ID(product_id)));
  };

  const {
    run: getUbVersionList,
    isLoading: gettingUbVersionList,
    data: ubVersionList,
  } = useAsync<ProductVersionType[]>();
  const getUbVersionListFunc = () => {
    getUbVersionList(client(GET_PRODUCT_VERSION_BY_ID("UB")));
  };

  const {
    run: getProductClientList,
    isLoading: gettingProductClientList,
    data: productClientList,
  } = useAsync<ProductClientType[]>();
  const getProductClientListFunc = (product_id: string) => {
    getProductClientList(client(GET_PRODUCT_CLIENT(product_id)));
  };

  const {
    run: getDeployTypeList,
    isLoading: gettingDeployTypeList,
    data: deployTypeList,
  } = useAsync<ProductDeployType[]>();
  const getDeployTypeListFunc = () => {
    getDeployTypeList(client(GET_DEPLOY_TYPE));
  };

  const {
    run: getServiceTypeList,
    isLoading: gettingServiceTypeList,
    data: serviceTypeList,
  } = useAsync<ProductServiceType[]>();
  const getServiceTypeListFunc = () => {
    getServiceTypeList(client(GET_SERVICE_TYPE));
  };

  const {
    run: getUsingDepartmentTypeList,
    isLoading: gettingUsingDepartmentTypeList,
    data: usingDepartmentTypeList,
  } = useAsync<ProductUsingDepartmentType[]>();
  const getUsingDepartmentTypeListFunc = () => {
    getUsingDepartmentTypeList(client(GET_PRODUCT_DEPARTMENT));
  };

  const {
    run: getUsingStatusTypeList,
    isLoading: gettingUsingStatusTypeList,
    data: usingStatusTypeList,
  } = useAsync<ProductUsingType[]>();
  const getUsingStatusTypeListFunc = () => {
    getUsingStatusTypeList(client(GET_USING_STATUS));
  };

  const {
    run: getServiceLevelTypeList,
    isLoading: gettingServiceLevelTypeList,
    data: serviceLevelTypeList,
  } = useAsync<ProductServiceLevelType[]>();
  const getServiceLevelTypeListFunc = () => {
    getServiceLevelTypeList(client(GET_SERVICE_LEVEL));
  };

  const [editProduct, setEditProduct] = useState<string>();

  const submit = (value: any) => {
    if (editingItem?.id) {
      client(UPDATE_PRODUCT_DETAIL(editingItem.id), {
        data: {
          ...value,
          is_customization: undefined,
          online_date: formatDate(value?.online_date),
          start_date: formatDate(value?.start_date),
          end_date: formatDate(value?.end_date),
        },
        method: "PUT",
      })
        .then(() => {
          message.success("更新成功！");
          setEditable(false);
          setDisChange(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("更新失败！请检查或者联系管理员！");
        });
    } else {
      message.error("无法提交更新，请稍后重试或联系管理员！");
    }
  };

  const clear = () => {
    form.setFieldsValue({
      product_version_id: null,
      client_version_id: null,
    });
  };

  useEffect(() => {
    if (editProduct) {
      getProductVersionListFunc(editProduct);
      getProductClientListFunc(editProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editProduct]);

  useEffect(() => {
    if (editable) {
      setIsModify(false);
      getProductListFunc();
      getUbVersionListFunc();
      getDeployTypeListFunc();
      getServiceTypeListFunc();
      getUsingDepartmentTypeListFunc();
      getUsingStatusTypeListFunc();
      getServiceLevelTypeListFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable]);

  useEffect(() => {
    if (editingItem) {
      setEditProduct(editingItem.product_id);
      setServiceType(editingItem.service_type_id);
      form.setFieldsValue({
        ...editingItem,
        departments: editingItem.departments?.map((i) => i?.id),
        online_date: editingItem.online_date
          ? dayjs(editingItem.online_date, DateFormate)
          : undefined,
        start_date: editingItem.start_date
          ? dayjs(editingItem.start_date, DateFormate)
          : undefined,
        end_date: editingItem.end_date
          ? dayjs(editingItem.end_date, DateFormate)
          : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  return (
    <Form form={form} onFinish={submit}>
      <Descriptions column={2}>
        <Descriptions.Item label={<ProductDesItemLabel label={"产品线"} />}>
          <LineDiv>
            <Form.Item
              name="product_id"
              rules={[{ required: true, message: "必须选择产品线" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingProductList}
                style={{ width: "256px" }}
                placeholder="选择产品线"
                onChange={(v) => {
                  clear();
                  setEditProduct(v);
                }}
              >
                {productList?.map((i, index) => (
                  <Option key={index} value={i?.title}>
                    {i?.product_name || i.chinese_name || i.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"授权状态"} />}>
          <LineDiv>
            <Form.Item
              name="product_status"
              rules={[{ required: true, message: "必须选择授权状态" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select style={{ width: "256px" }} placeholder="选择授权状态">
                {licenseStatus?.map((i, index) => (
                  <Option key={index} value={i?.value}>
                    {i?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        {editProduct === RID ? (
          <Descriptions.Item label={<ProductDesItemLabel label={"版本类型"} />}>
            <LineDiv>
              <Form.Item name="version_type" normalize={(v) => DisposeForm(v)}>
                <Select
                  allowClear
                  style={{ width: "256px" }}
                  placeholder="选择版本类型"
                >
                  {CT_LUNG_VERSION_TYPE.map((i, index) => (
                    <Option key={index} value={i}>
                      {i}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </LineDiv>
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label={<ProductDesItemLabel label={"模型阈值"} />}>
          <LineDiv>
            <Form.Item name={"threshold"} normalize={(v) => DisposeForm(v)}>
              <Input style={{ width: "256px" }} />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"产品定制化"} />}>
          <LineDiv>
            <Form.Item
              name={"is_customization"}
              rules={[{ required: true, message: "必须选择定制化情况" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                disabled={updattingCustomizationStatus}
                loading={updattingCustomizationStatus}
                placeholder="选择定制化情况"
                style={{ width: "256px" }}
                onChange={(v) => {
                  setIsModify(!isModify);
                  updateCustomizationStatusFunc(v);
                }}
              >
                {[
                  { name: "是", value: 1 },
                  { name: "否", value: 0 },
                ].map((i, index) => (
                  <Option key={index} value={i.value}>
                    {i.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"安装分支"} />}>
          <LineDiv>
            <Form.Item
              name={"product_version_id"}
              // rules={[{ required: true, message: "必须选择安装分支" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                showSearch
                filterOption={selectFilter}
                loading={gettingProductVersionList}
                style={{ width: "256px" }}
                placeholder="选择分支版本"
              >
                {productVersionList?.map((i, index) => (
                  <Option key={index} value={i?.id}>
                    {i?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"UB版本"} />}>
          <LineDiv>
            <Form.Item
              name={"ub_version_id"}
              rules={[{ required: true, message: "必须选择UB版本" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                showSearch
                filterOption={selectFilter}
                loading={gettingUbVersionList}
                style={{ width: "256px" }}
                placeholder="选择UB版本"
              >
                {ubVersionList?.map((i, index) => (
                  <Option key={index} value={i?.id}>
                    {i?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"客户端版本"} />}>
          <LineDiv>
            <Form.Item
              name={"client_version_id"}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                showSearch
                filterOption={selectFilter}
                loading={gettingProductClientList}
                style={{ width: "256px" }}
                placeholder="选择客户端版本"
              >
                {productClientList?.map((i, index) => (
                  <Option key={index} value={i?.product_client_version_id}>
                    {i?.product_client_version_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"部署类型"} />}>
          <LineDiv>
            <Form.Item
              name={"deploy_type_id"}
              rules={[{ required: true, message: "必须选择部署类型" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingDeployTypeList}
                style={{ width: "256px" }}
                placeholder="部署类型"
              >
                {deployTypeList?.map((i, index) => (
                  <Option key={index} value={i?.id}>
                    {i?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"上线时间"} />}>
          <LineDiv>
            <Form.Item
              name={"online_date"}
              required
              rules={[{ required: true, message: "必须选择上线时间" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <DatePicker
                style={{ width: "256px" }}
                placeholder="选择上线时间"
                picker={"date"}
                disabledDate={DisabledDate}
              />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"服务类型"} />}>
          <LineDiv>
            <Form.Item
              name={"service_type_id"}
              // rules={[{ required: true, message: "必须选择服务类型" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingServiceTypeList}
                style={{ width: "256px" }}
                placeholder="服务类型"
                onChange={(v) => setServiceType(v)}
              >
                {serviceTypeList?.map((i, index) => (
                  <Option key={index} value={i?.id}>
                    {i?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"单日体量"} />}>
          <LineDiv>
            <Form.Item name={"number"} normalize={(v) => DisposeForm(v)}>
              <Input placeholder="说明体量" style={{ width: "256px" }} />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"使用状态"} />}>
          <LineDiv>
            <Form.Item
              name={"using_status_id"}
              rules={[{ required: true, message: "必须选择使用状态" }]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingUsingStatusTypeList}
                style={{ width: "256px" }}
                placeholder="使用状态"
              >
                {usingStatusTypeList?.map((i, index) => (
                  <Option key={index} value={i?.id}>
                    {i?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"使用科室"} />}>
          <LineDiv>
            <Form.Item name={"departments"} normalize={(v) => DisposeForm(v)}>
              <Select
                loading={gettingUsingDepartmentTypeList}
                mode="multiple"
                maxTagCount={3}
                style={{ width: "256px" }}
                placeholder="选择使用科室"
              >
                {usingDepartmentTypeList?.map((i, index) => (
                  <Option key={index} value={i?.id}>
                    {i?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"服务等级"} />}>
          <LineDiv>
            <Form.Item
              name={"service_level_id"}
              rules={[
                {
                  required: serviceType === 2 ? true : false,
                  message: "必须选择服务等级",
                },
              ]}
              normalize={(v) => DisposeForm(v)}
            >
              <Select
                loading={gettingServiceLevelTypeList}
                style={{ width: "256px" }}
                placeholder="使用状态"
                allowClear={serviceType === 2 ? false : true}
              >
                {serviceLevelTypeList?.map((i, index) => (
                  <Option key={index} value={i?.id}>
                    {i?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
        <Descriptions.Item label={<ProductDesItemLabel label={"维保时间"} />}>
          <LineDiv>
            <Form.Item
              name={"start_date"}
              style={{ display: "inline-block", marginRight: 4 }}
              normalize={(v) => DisposeForm(v)}
            >
              <DatePicker
                style={{ width: "120px" }}
                placeholder="开始时间"
                picker={"date"}
                disabled
              />
            </Form.Item>
            --
            <Form.Item
              name={"end_date"}
              style={{ display: "inline-block", marginLeft: 4 }}
              normalize={(v) => DisposeForm(v)}
            >
              <DatePicker
                style={{ width: "120px" }}
                placeholder="结束时间"
                picker={"date"}
                disabled
              />
            </Form.Item>
          </LineDiv>
        </Descriptions.Item>
      </Descriptions>
      <Form.Item
        wrapperCol={{ offset: 20 }}
        style={{ marginTop: "2rem", marginBottom: "-1rem" }}
      >
        <Space>
          <Button
            htmlType="button"
            onClick={() => {
              setDisChange(false);
              setEditable(false);
              if (isModify) {
                reload();
              }
            }}
          >
            取消
          </Button>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
