import { BarSeriesOption } from "echarts/charts";
import { MyBarChartType } from "../../chart/bar-chart";
import { EqptModelStatisticType as BarType } from "../../interface";

const GetType = (data: BarType[]) => {
  let tmpType: Map<
    string,
    {
      name: string;
      type: string;
      stack: string;
      data: number[];
    }
  > = new Map();
  for (let r of data) {
    if (r.model.length) {
      r.model.forEach((i) => {
        if (!tmpType.has(i.name)) {
          tmpType.set(i.name, {
            name: i.name,
            type: "bar",
            stack: "value",
            data: [],
          });
        }
      });
      break;
    }
  }
  return tmpType;
};

export const GetModelNumBar = (data: BarType[]) => {
  let tmpType = GetType(data);

  let tmpData: MyBarChartType = {
    xData: [],
    series: [],
  };

  data.forEach((item) => {
    if (item.model.length) {
      tmpData.xData?.push(item.name);
      item.model.forEach((i) => {
        tmpType.get(i.name)?.data.push(i.value);
      });
    }
  });

  tmpType.forEach((t) => {
    tmpData.series?.push(t as BarSeriesOption);
  });

  return tmpData;
};
