import { useState, useEffect } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { DateStep, RateResults } from "../interface";

export const RateTable = ({
  data,
  isTh,
  unit,
}: {
  data: RateResults[] | undefined;
  isTh: boolean;
  unit: DateStep;
}) => {
  const [columns, setColumns] = useState<ColumnsType<RateResults>>([]);

  const timeRender = (value: string[]) => {
    switch (unit) {
      case DateStep.day:
        return value[0];
      case DateStep.week:
        return `${value[0]}~${value[1]}`;
      case DateStep.month:
        return `${value[0].split("-")[0]}-${value[0].split("-")[1]}`;
      case DateStep.year:
        return value[0].split("-")[0];
    }
  };

  useEffect(() => {
    if (isTh) {
      setColumns([
        {
          title: "时间",
          dataIndex: "date",
          align: "center",
          render: timeRender,
        },
        {
          title: "病例数",
          dataIndex: "predict_num",
          align: "center",
        },
        {
          title: "重建数",
          dataIndex: "rc2_num",
          align: "center",
        },
        {
          title: "访问数",
          dataIndex: "access_num",
          align: "center",
        },
        {
          title: "报告下载数",
          dataIndex: "download_num",
          align: "center",
        },
      ]);
    } else {
      setColumns([
        {
          title: "时间",
          dataIndex: "date",
          align: "center",
          render: timeRender,
        },
        {
          title: "预测量",
          dataIndex: "predict_num",
          align: "center",
        },
        {
          title: "点击量",
          dataIndex: "usv_num",
          align: "center",
        },
        {
          title: "点击率",
          dataIndex: "click_rate",
          align: "center",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTh]);

  return (
    <Table
      size="small"
      bordered
      columns={columns}
      dataSource={
        data?.map((item, index) => ({ ...item, key: index })).reverse() || []
      }
      pagination={{
        showQuickJumper: true,
        showSizeChanger: false,
      }}
    />
  );
};
