import { useEffect, useRef, useState } from "react";
import { Button, Row, Col, Space } from "antd";
import {
  ProDescriptions,
  ProTable,
  ProColumns,
  ProForm,
  ProFormDateRangePicker,
  ProFormText,
} from "@ant-design/pro-components";
import { DeviceReceiveInfoList } from "../interface";
import { GET_DEVICE_DATA_RECEIVE_INFO } from "lib/api";
import { FormInstance, ActionType } from "@ant-design/pro-components";
import { useHttp } from "utils/http";
import { GetDate, FormatDate } from "utils";

const columns: ProColumns<DeviceReceiveInfoList>[] = [
  {
    title: "日期",
    dataIndex: "statics_date",
    valueType: "date",
    width: 120,
  },
  {
    title: "当日设备总数",
    width: 100,
    dataIndex: "device_total",
  },
  {
    title: "当日接收总数",
    width: 120,
    dataIndex: "total",
  },
  {
    title: "接收详情",
    dataIndex: "data",
    render: (_, record) => (
      <div>
        {record.data.map((item, index) => (
          <ProDescriptions key={`device-detail${index}`} column={3}>
            <ProDescriptions.Item label="设备名">
              {item.station_name}
            </ProDescriptions.Item>
            <ProDescriptions.Item label="AE Title">
              {item.ae_title}
            </ProDescriptions.Item>
            <ProDescriptions.Item label="数量">
              {item.count}
            </ProDescriptions.Item>
          </ProDescriptions>
        ))}
      </div>
    ),
  },
];

const DeviceReceiveInfo = ({ hospID }: { hospID: number | null }) => {
  const client = useHttp();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const [searchParams, setSearchParams] = useState<any>();

  const getParams = (params: any) => {
    const dateRange = formRef.current?.getFieldValue("dateRange");
    const station_name = formRef.current?.getFieldValue("device_name");
    const ae_title = formRef.current?.getFieldValue("ae_title");
    const queryParams = {
      start_date: dateRange[0] ? FormatDate(dateRange[0]) : undefined,
      end_date: dateRange[1] ? FormatDate(dateRange[1]) : undefined,
      station_name,
      ae_title,
    };
    return {
      ...params,
      ...queryParams,
    };
  };

  const search = (params: any) => {
    if (hospID) {
      return client(GET_DEVICE_DATA_RECEIVE_INFO(hospID), {
        data: { ...params, ...searchParams },
      });
    }
  };

  return (
    <>
      <ProTable<DeviceReceiveInfoList>
        className="ant-table-padding-inline"
        tableExtraRender={(_, data) => (
          <div>
            <Row>
              <Col>
                <h3 style={{ margin: "0px 4px 4px 0px" }}> 设备接收统计</h3>
              </Col>
            </Row>
            <Row>
              <ProForm<any>
                formRef={formRef}
                layout={"inline"}
                submitter={{
                  render: (props, doms) => {
                    return (
                      <Space>
                        <Button type="primary" htmlType="submit">
                          查询
                        </Button>
                      </Space>
                    );
                  },
                }}
                onFinish={async () => {
                  actionRef.current?.reload();
                }}
              >
                <ProFormDateRangePicker
                  label="日期"
                  name="dateRange"
                  initialValue={GetDate("last_3_days")}
                />
                <ProFormText
                  name="device_name"
                  label="设备"
                  placeholder="输入设备名称查询"
                />
                <ProFormText
                  name="ae_title"
                  label="AE"
                  placeholder="输入AE Title查询"
                />
              </ProForm>
            </Row>
          </div>
        )}
        actionRef={actionRef}
        columns={columns}
        search={false}
        request={async (params) => {
          try {
            let queryParams = getParams(params);
            const res = await search(queryParams);
            return {
              data: res.results,
              total: res.count,
              success: true,
            };
          } catch (e) {
            return { success: false };
          }
        }}
        rowKey="id"
        pagination={{
          pageSize: 3,
        }}
        dateFormatter="string"
        options={false}
      />
    </>
  );
};

export default DeviceReceiveInfo;
