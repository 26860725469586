import { Mask, SpinLoading } from "antd-mobile";
import { CSSProperties } from "react";

export const LoadingMaskStyle: CSSProperties &
  Partial<Record<"--color" | "--size", string>> = {
  "--size": "48px",
  position: "absolute",
  top: "50%",
  left: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "-24px",
  marginLeft: "-24px",
};

export const LoadingMask = ({ visible }: { visible: boolean }) => {
  return (
    <Mask color="white" visible={visible}>
      <SpinLoading style={LoadingMaskStyle} />
    </Mask>
  );
};
