import { Dispatch, forwardRef, SetStateAction } from "react";

import { exeChildFunc } from "authenticated-app/pc/customer/customer-detail/interface";

import { OnlineChangeListTable } from "./onlineChangeTable";
const OnlineChangeTabPane = forwardRef<
  exeChildFunc,
  {
    hospID: number | null;
    setDisChange: Dispatch<SetStateAction<boolean>>;
  }
>(({ hospID, setDisChange }, ref) => {
  return <OnlineChangeListTable hospID={hospID} />;
});

export default OnlineChangeTabPane;
