import { MyBarChartType } from "authenticated-app/pc/stat/chart/bar-chart";
import { PredictType, PredictValue } from "../interface";
import { BarSeriesOption } from "echarts/charts";

export interface MyBarTimeChartType {
  series: BarSeriesOption[] | undefined;
}

export const GetMultiType = (data: any[]) => {
  let tmpType: Map<
    string,
    {
      name: string;
      type: string;
      stack: string;
      data: any[];
    }
  > = new Map();
  for (let r of data) {
    if (r.values?.length) {
      r.values?.forEach((i: any) => {
        if (!tmpType.has(i.name)) {
          tmpType.set(i.name, {
            name: i.name,
            type: "bar",
            stack: "value",
            data: [],
          });
        }
      });
      break;
    }
  }
  return tmpType;
};

export const GetPredictNum = (data: PredictType[]) => {
  let tmpType = GetMultiType(data);
  let tmpData: BarSeriesOption[] = [];
  if (tmpType) {
    data.forEach((item) => {
      let tmpTime = item.timestamp;
      if (item.values) {
        item.values.forEach((i: PredictValue) => {
          tmpType.get(i.name)?.data.push([tmpTime, i.value]);
        });
      }
    });
  }
  // return tmpType;
  // let tmpData = [];
  // if (tmpType) {
  //   data.forEach((item) => {
  //     if (item.values?.length) {
  //       if (item.timestamp) tmpData.push(item.timestamp);
  //       item.values?.forEach((i: PredictValue) => {
  //         tmpType.get(i.name)?.data.push(i.value);
  //       });
  //     }
  //   });
  tmpType.forEach((t) => {
    tmpData.push(t as BarSeriesOption);
  });
  // // return tmpType;
  return tmpData;
};
