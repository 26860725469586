import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useVisitListAdd = () => {
  const [{ openVisitListAdd }, setopenVisitListAdd] = useUrlQueryParam([
    "openVisitListAdd",
  ]);

  const open = () => setopenVisitListAdd({ openVisitListAdd: true });
  const close = () => setopenVisitListAdd({ openVisitListAdd: undefined });

  return [openVisitListAdd === "true", open, close] as const;
};
