import { Descriptions } from "antd";
import { DELIVERY_DETAIL_TYPE } from "lib/interface/launchChangeList";
import { UpdateInputTextArea } from "authenticated-app/pc/components/UpdateInputTextArea";
import { getBoolValue } from "utils";
const { Item } = Descriptions;
export const DeliveryOrderDetailInfo = ({
  onUpdate,
  detailInfo,
  isCanChange,
}: {
  onUpdate: (value: any) => void;
  detailInfo: DELIVERY_DETAIL_TYPE | null;
  isCanChange: boolean;
}) => {
  const sendback_info = () => {
    if (detailInfo?.delivery_type_name !== "产品停用") {
      return (
        <Item label={"多余物料是否退回"}>
          {getBoolValue(detailInfo?.is_send_back, "无多余物料")}
        </Item>
      );
    }
  };
  const childinfo = () => {
    switch (detailInfo?.delivery_type_name) {
      case "产品升级":
      case "硬件部署": // 硬件部署
        return (
          <>
            <Item label={"是否需要退回硬件"}>
              {getBoolValue(detailInfo?.is_need_send_back)}
            </Item>
            <Item label={"是否现场处理"}>
              {getBoolValue(detailInfo?.is_deal_with_on_site)}
            </Item>
          </>
        );
      case "组件升级":
      case "产品停用":
      case "产品撤机": // 产品撤机
        return (
          <>
            <Item label={"是否现场处理"}>
              {getBoolValue(detailInfo?.is_deal_with_on_site)}
            </Item>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Descriptions size="small" className="break-detail-desc-title">
        <Item>
          <UpdateInputTextArea
            info={detailInfo?.description || ""}
            onUpdate={onUpdate}
            title={"详细描述"}
            name={"description"}
            isCanChange={false}
          />
        </Item>
      </Descriptions>
      <Descriptions
        className="break-detail-desc-title"
        title="交付信息"
        size="small"
        column={3}
      >
        <Item label={"计划交付日期"}>{detailInfo?.planned_delivery_date}</Item>
        <Item label={"第一次入场日期"}>{detailInfo?.first_entry_date}</Item>
        <Item label={"实际交付日期"}>{detailInfo?.actual_delivery_date}</Item>
        <Item label={"下次跟进日期"}>{detailInfo?.next_entry_date}</Item>
        <Item label={"实际入场次数"}>{detailInfo?.actual_entry_number}</Item>
        <Item label={"实际投入天数"}>{detailInfo?.actual_person_days}</Item>
        <Item label={"是否完成退场检查"}>
          {getBoolValue(detailInfo?.is_over_checklist)}
        </Item>
        <Item label={"是否更新客户信息"}>
          {getBoolValue(detailInfo?.is_update_info)}
        </Item>
        {sendback_info()}
        {childinfo()}
      </Descriptions>
      <UpdateInputTextArea
        info={detailInfo?.supplementary_info || ""}
        onUpdate={onUpdate}
        title={"补充信息"}
        name={"supplementary_info"}
        isCanChange={isCanChange}
      />
    </>
  );
};
