import {
  Modal,
  Input,
  Divider,
  Button,
  Space,
  message,
  Tooltip,
  Tag,
} from "antd";
import { useBreaklistSearch } from "authenticated-app/hooks/breaklist/useBreaklistSearch";
import { ProList, ActionType } from "@ant-design/pro-components";
import { useState, useRef, useEffect } from "react";
import { useHttp } from "utils/http";
import { QUERY_BREAK } from "lib/api";
import { QUERY_BREAK_TYPE } from "lib/interface/breaklist";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { FieldTimeOutlined } from "@ant-design/icons";
import "./index.css";

const { Search } = Input;

export const BreakSearchModal = () => {
  const [isOpen, , close] = useBreaklistSearch();
  const { height } = useWindowSize();
  const client = useHttp();
  const actionRef = useRef<ActionType>();
  const [searchParams, setSearchParams] = useState<any>();
  const [queryValue, setQueryValue] = useState<string | undefined>();
  const [page, setPage] = useState<number>(1);

  const search = (params: any) => {
    return client(QUERY_BREAK, {
      data: { ...params, ...searchParams },
    });
  };
  const getParams = (params: any) => {
    return {
      ...params,
      ...searchParams,
    };
  };

  const handleSearch = (value: string) => {
    if (!value.trim()) {
      message.warning("请输入检索关键词！");
    } else {
      setSearchParams({ query: queryValue });
      actionRef.current?.reload();
    }
  };

  useEffect(() => {
    actionRef.current?.reload();
  }, [searchParams]);

  useEffect(() => {
    setSearchParams(undefined);
    setQueryValue(undefined);
    actionRef.current?.reload();
    setPage(1);
  }, [isOpen]);

  return (
    <Modal
      centered
      maskClosable={false}
      destroyOnClose
      open={isOpen}
      afterClose={() => {}}
      onCancel={close}
      footer={null}
      width={"85vw"}
      style={{
        minWidth: "928px",
        height: "90vh",
      }}
      closable={false}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Search
          style={{ width: "90%" }}
          size="middle"
          value={queryValue}
          placeholder="请输入检索关键词"
          onChange={(e) => setQueryValue(e.target.value)}
          onSearch={handleSearch}
          enterButton
        />
        <Space>
          <Button
            onClick={() => {
              setQueryValue(undefined);
              setSearchParams(undefined);
            }}
          >
            重置
          </Button>
          <Button size="middle" onClick={close}>
            关闭
          </Button>
        </Space>
      </div>
      <Divider style={{ margin: "6px 0" }} />
      <div className="break-detail-div">
        <div style={{ flex: 3, height: "100%", marginInline: 0 }}>
          <ProList<QUERY_BREAK_TYPE>
            rowKey="id"
            actionRef={actionRef}
            showActions="hover"
            itemLayout="vertical"
            pagination={{
              showQuickJumper: true,
              showSizeChanger: false,
              pageSize:
                Math.trunc((height - 382) / 85) > 5
                  ? Math.trunc((height - 382) / 85)
                  : 5,
              current: page,
              onChange: (v) => {
                setPage(v);
              },
            }}
            request={async (params) => {
              try {
                if (searchParams) {
                  let queryParams = getParams(params);
                  const res = await search(queryParams);
                  return {
                    data: res.results,
                    total: res.count,
                    success: true,
                  };
                } else {
                  return {
                    data: [],
                    total: 0,
                    success: true,
                  };
                }
              } catch (e) {
                return { success: false };
              }
            }}
            search={false}
            metas={{
              title: {
                search: false,
                render: (title, record) => {
                  const baseUrl = window.location.origin;
                  const path = "/ticket/break";
                  const queryParams = {
                    openBreaklistDetail: "true",
                    detailId: record.id.toString(),
                  };
                  const searchParams = new URLSearchParams(queryParams);
                  const fullUrl = `${baseUrl}${path}?${searchParams.toString()}`;
                  return (
                    <a href={fullUrl} target="_blank" rel="noopener noreferrer">
                      <Space>
                        <span>{title}</span>
                        <Tooltip title="创建时间">
                          <Tag icon={<FieldTimeOutlined />} color="processing">
                            {record?.create_time}
                          </Tag>
                        </Tooltip>
                      </Space>
                    </a>
                  );
                },
              },
              description: {
                search: false,
                render: (desc, record) => {
                  const highlightDesc = record.highlight?.description[0] || "";
                  const highlightSolution =
                    record.highlight?.solution_info[0] || "";
                  const highlighDebug = record.highlight?.debug_info[0] || "";
                  return (
                    <>
                      <span style={{ fontWeight: "bold" }}>影响产品：</span>
                      <span
                        style={{ color: "black" }}
                        className="pro-list-content-no-margin"
                      >
                        {record.product_list
                          .map((item) =>
                            item.product_version_name
                              ? `${item.chinese_name} ${item.product_version_name}`
                              : item.chinese_name,
                          )
                          .join(", ")}
                      </span>
                      {highlightDesc ? (
                        <div style={{}}>
                          <span
                            className="pro-list-content-no-margin"
                            dangerouslySetInnerHTML={{
                              __html: `<em class="emHead">反馈详情：</em> ${highlightDesc}`,
                            }}
                          ></span>
                        </div>
                      ) : null}
                      {highlighDebug ? (
                        <div>
                          <span
                            className="pro-list-content-no-margin"
                            dangerouslySetInnerHTML={{
                              __html: `<em class="emHead">定位信息：</em>${highlighDebug}`,
                            }}
                          ></span>
                        </div>
                      ) : null}
                      {highlightSolution ? (
                        <div>
                          <span
                            className="pro-list-content-no-margin"
                            dangerouslySetInnerHTML={{
                              __html: `<em class="emHead">解决方案：</em>${highlightSolution}`,
                            }}
                          ></span>
                        </div>
                      ) : null}
                    </>
                  );
                },
                dataIndex: "description",
              },
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
