// Checklist退场清单设置
export const GET_EXIT_TYPE = "/ticketAPI/checklist/exit_list_item";
export const ADD_EXIT_TYPE = "/ticketAPI/checklist/exit_list_item";
export const UPDATE_EXIT_TYPE = (org_id: number) =>
  `/ticketAPI/checklist/exit_list_item/${org_id}`;
export const DEL_EXIT_TYPE = (org_id: number) =>
  `/ticketAPI/checklist/exit_list_item/${org_id}`;

// Checklist上线清单设置
export const GET_ONLINE_TYPE = "/ticketAPI/checklist/online_list_item";
export const ADD_ONLINE_TYPE = "/ticketAPI/checklist/online_list_item";
export const UPDATE_ONLINE_TYPE = (org_id: number) =>
  `/ticketAPI/checklist/online_list_item/${org_id}`;
export const DEL_ONLINE_TYPE = (org_id: number) =>
  `/ticketAPI/checklist/online_list_item/${org_id}`;

// Checklist调研设置
export const GET_SURVEY_TYPE = "/ticketAPI/checklist/survey_list_item";
export const ADD_SURVEY_TYPE = "/ticketAPI/checklist/survey_list_item";
export const UPDATE_SURVEY_TYPE = (org_id: number) =>
  `/ticketAPI/checklist/survey_list_item/${org_id}`;
export const DEL_SURVEY_TYPE = (org_id: number) =>
  `/ticketAPI/checklist/survey_list_item/${org_id}`;

// Checklist异常清单设置
export const GET_EXCEPT_TYPE = "/ticketAPI/checklist/except_list_item";
export const ADD_EXCEPT_TYPE = "/ticketAPI/checklist/except_list_item";
export const UPDATE_EXCEPT_TYPE = (org_id: number) =>
  `/ticketAPI/checklist/except_list_item/${org_id}`;
export const DEL_EXCEPT_TYPE = (org_id: number) =>
  `/ticketAPI/checklist/except_list_item/${org_id}`;
