import { Dispatch, forwardRef, SetStateAction } from "react";
import { Divider, Spin } from "antd";
import { exeChildFunc } from "authenticated-app/pc/customer/customer-detail/interface";
import { useHttp } from "utils/http";
import { useAsync } from "utils/hooks/useAsync";
import { CompetitiveTables } from "./competitiveTable";

const CompetitiveTabPane = forwardRef<
  exeChildFunc,
  {
    hospID: number | null;
    setDisChange: Dispatch<SetStateAction<boolean>>;
    changeCount: any;
  }
>(({ hospID, setDisChange, changeCount }, ref) => {
  const client = useHttp();

  return <CompetitiveTables hospID={hospID} />;
});

export default CompetitiveTabPane;
