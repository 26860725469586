import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
} from "react";
import { Divider, Spin } from "antd";
import { useDebounceFn } from "ahooks";
import { ProCard } from "@ant-design/pro-components";
import { exeChildFunc } from "authenticated-app/pc/customer/customer-detail/interface";
import { useHttp } from "utils/http";
import {
  ADD_DOCKING_RETAIN,
  DEL_DOCKING_RETAIN,
  GET_DOCKING_INFO,
  UPDATE_DOCKING_RETAIN,
} from "lib/api";
import { DockingInfo } from "./interface";
import DockingBaseInfo from "./docking-base-info";
import EqptRecordInfo from "./eqpt-record-info";
import BaseRetainInfo from "../infoTabPane/base-retain-info";
import DockingVersionInfo from "./docking-version-info";
import DeviceReceiveInfo from "./device-receive-info";
import DataReceiveInfo from "./data-receive-info";
import { useAsync } from "utils/hooks/useAsync";

import "../../index.css";

const DockingTabPane = forwardRef<
  exeChildFunc,
  {
    hospID: number | null;
    setDisChange: Dispatch<SetStateAction<boolean>>;
    changeCount: any;
  }
>(({ hospID, setDisChange, changeCount }, ref) => {
  const client = useHttp();

  const { run, isLoading, data: detail } = useAsync<DockingInfo>();
  const getDockingDetail = () => {
    if (hospID) {
      run(client(GET_DOCKING_INFO(hospID)));
    }
  };
  const { run: getDockingDetailDebounce } = useDebounceFn(getDockingDetail, {
    wait: 200,
  });

  useImperativeHandle(ref, () => ({
    exeFunc() {
      getDockingDetail();
    },
  }));

  useEffect(() => {
    getDockingDetailDebounce();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospID]);

  return (
    <Spin spinning={isLoading}>
      <DockingBaseInfo
        hospID={hospID}
        dockingInfo={detail?.docking_list}
        reload={getDockingDetail}
        setDisChange={setDisChange}
        changeCount={changeCount}
      />
      <Divider style={{ margin: "8px" }} />
      <DeviceReceiveInfo hospID={hospID} />
      <Divider style={{ margin: "8px" }} />
      <DataReceiveInfo hospID={hospID} />
      <Divider style={{ margin: "8px" }} />
      <DockingVersionInfo
        hospID={hospID}
        info={detail?.ds_info}
        reload={getDockingDetail}
      />
      <Divider style={{ margin: "8px" }} />
      <EqptRecordInfo
        hospID={hospID}
        eqptRecordInfo={detail?.eqpt_infos}
        reload={getDockingDetail}
      />
      <Divider style={{ margin: "8px" }} />
      <BaseRetainInfo
        retain={detail?.retain_infos}
        orgID={hospID}
        RetainAPI={{
          ADD_RETAIN: ADD_DOCKING_RETAIN,
          UPDATE_RETAIN: UPDATE_DOCKING_RETAIN,
          DEL_RETAIN: DEL_DOCKING_RETAIN,
        }}
        reload={getDockingDetail}
      />
    </Spin>
  );
});

export default DockingTabPane;
