import { SetStateAction, Dispatch, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  Input,
  message,
  Select,
  Divider,
  DatePicker,
  Cascader,
} from "antd";
import dayjs from "dayjs";

import { useHttp } from "utils/http";
import {
  DisposeForm,
  selectFilter,
  createDeparmentTree,
  cascaderFilter,
} from "utils";
import { useAsync } from "utils/hooks/useAsync";
import { DepartmentType } from "authenticated-app/pc/customer/customer-detail/tab-pane/infoTabPane/interface";
import { GET_DEPARTMENT_LIST } from "lib/api";
import SelectedPermission from "./selected-permission";
import { GET_USER_LIST } from "../api";
import {
  IPackageData,
  IUser,
} from "authenticated-app/pc/setting/customer-setting/interface";

const { Option } = Select;
const defaultStartDate = dayjs();
const defaultEndDate = dayjs(4102416000000);

export const PermissionAssociationSettingAdd = ({
  CTRL_ADD,
  visible,
  setVisible,
  reload,
}: {
  CTRL_ADD: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [addPackageDataVOS, setAddPackageDataVOS] = useState<IPackageData[]>(
    [],
  );
  const [departmentTree, setDepartmentTree] = useState<DepartmentType[]>();

  const {
    run: getDepartmentList,
    isLoading: gettingDepartmentList,
    data: departmentList,
  } = useAsync<DepartmentType[]>();
  const getDepartmentListHandle = () => {
    getDepartmentList(client(GET_DEPARTMENT_LIST));
  };

  const {
    run: getUserList,
    isLoading: gettingUserList,
    data: userList,
  } = useAsync<IUser[]>();
  const getUserListHandle = () => {
    getUserList(client(GET_USER_LIST));
  };

  const init = () => {
    getDepartmentListHandle();
    getUserListHandle();
    setAddPackageDataVOS([]);
    form.resetFields();
    form.setFieldsValue({
      permissionStartDate: defaultStartDate,
      permissionEndDate: defaultEndDate,
    });
  };

  const submit = (value: any) => {
    client(CTRL_ADD, {
      data: {
        ...value,
        authorityDepartmentIds: value?.authorityDepartmentIds?.map(
          (i: number[]) => i.slice(-1)[0],
        ),
        packageDataVOS: addPackageDataVOS,
        permissionStartDate: value?.permissionStartDate.toISOString(),
        permissionEndDate: value?.permissionEndDate.toISOString(),
      },
      method: "POST",
    })
      .then(() => {
        message.success("添加成功！");
        setVisible(false);
        reload();
      })
      .catch((e) => {
        console.error("submit obj is:");
        console.error(value);
        console.error(e);
        message.error("添加失败，请检查或联系管理员！");
      });
  };

  useEffect(() => {
    if (departmentList) {
      setDepartmentTree(createDeparmentTree(departmentList)[0]?.children);
    }
  }, [departmentList]);

  useEffect(() => {
    if (visible) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={`新增权限关联规则`}
      maskClosable={false}
      destroyOnClose
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width="61.8%"
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        onFinish={submit}
      >
        <Form.Item
          name="title"
          label={"名称"}
          rules={[{ required: true, message: `必须输入权限名称` }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input placeholder="输入关联权限名称" maxLength={50} />
        </Form.Item>
        <Divider />
        <Form.Item
          name="authorityDepartmentIds"
          label={"部门"}
          normalize={(v) => DisposeForm(v)}
        >
          <Cascader
            loading={gettingDepartmentList}
            multiple
            allowClear
            showSearch={{
              filter: cascaderFilter,
            }}
            fieldNames={{
              label: "departmentName",
              value: "userDepartmentId",
              children: "children",
            }}
            placeholder="选择部门(多选)"
            options={departmentTree as any}
          />
        </Form.Item>
        <Form.Item
          name="authorityUserIds"
          label={"用户"}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            mode="multiple"
            loading={gettingUserList}
            showSearch
            allowClear
            placeholder="选择用户(多选)"
            filterOption={selectFilter}
          >
            {userList?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.userName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Divider />
        <Form.Item
          name="packageDataVOS"
          label={"已选权限列表"}
          normalize={(v) => DisposeForm(v)}
        >
          <SelectedPermission
            packageData={addPackageDataVOS}
            update={setAddPackageDataVOS}
          />
        </Form.Item>
        <Divider />
        <Form.Item
          name="permissionStartDate"
          label={"生效日期"}
          normalize={(v) => DisposeForm(v)}
          rules={[{ required: true, message: `必须输入生效日期` }]}
        >
          <DatePicker placeholder="结束时间" picker={"date"} />
        </Form.Item>
        <Form.Item
          name="permissionEndDate"
          label={"失效日期"}
          normalize={(v) => DisposeForm(v)}
          rules={[{ required: true, message: `必须输入失效日期` }]}
        >
          <DatePicker placeholder="结束时间" picker={"date"} />
        </Form.Item>
        <Divider />
        <Form.Item wrapperCol={{ offset: 17, span: 6 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
