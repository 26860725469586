import { useEffect, SetStateAction, Dispatch } from "react";
import { Button, Modal, Space, Form, Input, Select, message } from "antd";

import { useHttp } from "utils/http";
import {
  GET_HOSP_USER_TYPE,
  GET_HOSP_CONTACT,
  UPDATE_HOSP_USER,
} from "lib/api";

import { ContactType, HosUserType } from "../interface";

import { DisposeForm } from "utils";
import { useAsync } from "utils/hooks/useAsync";

const { Option } = Select;
const { TextArea } = Input;

export const HosUserInfoEdit = ({
  editingItem,
  visible,
  setVisible,
  reload,
}: {
  editingItem: HosUserType | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const {
    run: getContact,
    isLoading: gettingContact,
    data: contact,
  } = useAsync<ContactType[]>();
  const getContactFunc = () => {
    getContact(client(GET_HOSP_CONTACT));
  };

  const {
    run: getHosUserType,
    isLoading: gettingHosUserType,
    data: hosUserType,
  } = useAsync<HosUserType[]>();
  const getHosUserTypeFunc = () => {
    getHosUserType(client(GET_HOSP_USER_TYPE));
  };

  const submit = (value: any) => {
    let id = editingItem?.id;
    if (id)
      client(UPDATE_HOSP_USER(id), {
        data: {
          ...value,
        },
        method: "PUT",
      })
        .then(() => {
          message.success("修改成功！");
          setVisible(false);
          reload();
        })
        .catch((e) => {
          console.error(e);
          message.error("修改失败，请检查或联系管理员！");
        });
    else message.error("无法提交修改，请稍后重试或联系管理员！");
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...editingItem,
      });
      getContactFunc();
      getHosUserTypeFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="编辑外部人员信息"
      destroyOnClose
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="hos_user_name"
          label="姓名"
          rules={[{ required: true, message: "姓名不能为空" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="contact_id"
          label="联系方式"
          rules={[{ required: true, message: "联系方式不能为空" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingContact}
            placeholder="选择一个联系方式"
            allowClear
          >
            {contact?.map((item, index) => (
              <Option key={index} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="mobile"
          label="联系号码"
          normalize={(v) => DisposeForm(v)}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="hos_user_type_id"
          label="人员类型"
          rules={[{ required: true, message: "必须选择人员类型" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingHosUserType}
            placeholder="选择一个人员类型"
            allowClear
          >
            {hosUserType?.map((item, index) => (
              <Option key={index} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="remark" label="备注" normalize={(v) => DisposeForm(v)}>
          <TextArea allowClear />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
