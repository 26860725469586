import { Descriptions } from "antd";

import { UpdateBoolSelect } from "authenticated-app/pc/components/UpdateBoolSelect";
import { UpdateDateSelect } from "authenticated-app/pc/components/UpdateDateSelect";
import { UpdateInput } from "authenticated-app/pc/components/UpdateInput";
import { BREAK_DETAIL_TYPE } from "lib/interface/breaklist";
import { GET_ISSUES_REASON, GET_ISSUES_TYPE } from "lib/api/issues";
import { UpdateSelect } from "authenticated-app/pc/components/UpdateSelect";
import { UpdateMultipleCascadeSelect } from "authenticated-app/pc/components/UpdateMultipleCascadeSelect";

import { GET_PRODUCT_DETAIL_HAS_NOTONLINE } from "lib/api";
import { useEffect, useState } from "react";
import "index.css";
const { Item } = Descriptions;

export const BreakDetailDetailInfo = ({
  onUpdate,
  detailInfo,
  isFinished,
  isHasPermission,
}: {
  onUpdate: (value: any) => void;
  detailInfo: BREAK_DETAIL_TYPE | null;
  isFinished: boolean;
  isHasPermission: boolean;
}) => {
  const isCanChange = !isFinished && isHasPermission;
  const [selectedIssuesTypeId, setSelectedIssuesTypeId] = useState<number>();

  useEffect(() => {
    if (detailInfo?.issue_type_id !== selectedIssuesTypeId) {
      setSelectedIssuesTypeId(detailInfo?.issue_type_id);
    }
  }, [detailInfo]);
  return (
    <>
      <Descriptions
        className="break-detail-desc-title"
        size="small"
        title="详细信息"
        column={4}
      >
        <Item>
          <UpdateBoolSelect
            onUpdate={onUpdate}
            value={detailInfo?.is_on_site}
            label={"是否在现场"}
            name={"is_on_site"}
            isCanChange={isCanChange}
          />
        </Item>
        <Item>
          <UpdateBoolSelect
            onUpdate={onUpdate}
            value={detailInfo?.is_deal_with_on_site}
            label={"是否现场处理"}
            name={"is_deal_with_on_site"}
            isCanChange={isCanChange}
          />
        </Item>
        <Item>
          <UpdateBoolSelect
            onUpdate={onUpdate}
            value={detailInfo?.is_temporary}
            label={"是否临时解决"}
            name={"is_temporary"}
            isCanChange={isCanChange}
          />
        </Item>
        <Item>
          <UpdateBoolSelect
            onUpdate={onUpdate}
            value={detailInfo?.is_known}
            label={"是否已知问题"}
            name={"is_known"}
            isCanChange={isCanChange}
          />
        </Item>
      </Descriptions>
      <Descriptions className="break-detail-desc-title" size="small" column={2}>
        <>
          <Item>
            <UpdateSelect
              onUpdate={onUpdate}
              defaultValue={detailInfo?.issue_type_name}
              defaultId={detailInfo?.issue_type_id}
              label={"反馈类型"}
              name={"issue_type_id"}
              options={{ value: "id", label: "name" }}
              api={GET_ISSUES_TYPE}
              isCanChange={isCanChange}
            />
          </Item>
          <Item>
            <UpdateSelect
              onUpdate={onUpdate}
              defaultValue={detailInfo?.issue_reason_name}
              defaultId={detailInfo?.issue_reason_id}
              label={"反馈归因"}
              name={"issue_reason_id"}
              options={{ value: "id", label: "name" }}
              api={GET_ISSUES_REASON}
              isCanChange={isCanChange}
              enableDependency={true}
              preDependency={{
                type: "issue_type_id",
                label: "反馈类型",
                value: selectedIssuesTypeId,
              }}
            />
          </Item>
        </>
      </Descriptions>
      <Descriptions className="break-detail-desc-title" size="small" column={1}>
        <Item>
          <UpdateMultipleCascadeSelect
            onUpdate={onUpdate}
            defaultValue={detailInfo?.product_list}
            label={"影响产品"}
            name={"product_id_list"}
            optionss={{ value: "product_id", label: "product_chinese_name" }}
            api={
              detailInfo?.hospital_id
                ? GET_PRODUCT_DETAIL_HAS_NOTONLINE(detailInfo?.hospital_id)
                : ""
            }
            hosptialId={
              detailInfo?.hospital_id ? detailInfo?.hospital_id : "all"
            }
            isCanChange={isCanChange}
          />
        </Item>
      </Descriptions>
      <Descriptions size="small" column={2}>
        <Item>
          <UpdateDateSelect
            showTime
            value={detailInfo?.delivery_time}
            onUpdate={onUpdate}
            label={"预计交付日期"}
            name={"delivery_time"}
            isCanChange={isCanChange}
          />
        </Item>
        <Item>
          <UpdateInput
            value={detailInfo?.knowledge_link}
            onUpdate={onUpdate}
            label={"知识库链接"}
            name={"knowledge_link"}
            needUrlRegex
            isCanChange={isCanChange}
            isLink
          />
        </Item>
      </Descriptions>
    </>
  );
};
