import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

export const useDeliveryDetailSend = () => {
  const [{ openDeliveryDetailSend }, setopenDeliveryDetailSend] =
    useUrlQueryParam(["openDeliveryDetailSend"]);

  const open = () =>
    setopenDeliveryDetailSend({ openDeliveryDetailSend: true });
  const close = () =>
    setopenDeliveryDetailSend({
      openDeliveryDetailSend: undefined,
    });

  return [openDeliveryDetailSend === "true", open, close] as const;
};
