import { useEffect, useState, useRef } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import {
  PageHeader,
  ProColumns,
  FormInstance,
  ActionType,
  ProTable,
} from "@ant-design/pro-components";
import {
  Row,
  Col,
  Space,
  Radio,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  Divider,
} from "antd";
import { MyBarChart, MyBarChartType } from "../../chart/bar-chart";
import { MyPieChart, MyPieChartType } from "../../chart/pie-chart";
import { GetDate, RangeValue } from "utils";
import {
  HosNodesType,
  BREAK_ISSUES_PROCESS_LIST_TYPE,
  BREAK_ISSUES_PROCESS_STATISTICS_TYPE,
} from "../../interface";
import { useAsync } from "utils/hooks/useAsync";
import { useHttp } from "utils/http";
import {
  GET_HOSP_NODES,
  GET_ISSUES_PROCESS_LIST,
  GET_ISSUES_PROCESS_STATISTICS,
} from "lib/api";
import { useDebounceFn, useMount } from "ahooks";
import { DateTimeFormate, FormatDate, trimStr } from "utils";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { useDownload } from "utils/download";
import {
  GetBreakProcessTotalNum,
  GetBreakProcessDepartmentNum,
  GetBreakProcessDepartmentPieNum,
} from "../break-info-stat/utils/break-bar";
import { useBreaklistDetail } from "authenticated-app/hooks/breaklist/useBreaklistDetail";
import { BreakDetailModal } from "authenticated-app/pc/ticket/breakList/detail";
import dayjs from "dayjs";
import "./index.css";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const BreakProcessStats = () => {
  const client = useHttp();
  const downloadClient = useDownload();

  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const { height } = useWindowSize();
  const [, openBreaklistDetail] = useBreaklistDetail();
  const [tabName, setTabName] = useState<number>(1);
  const [areaList, setAreaList] = useState<HosNodesType[]>();
  const [searchAreaID, setSearchAreaID] = useState<number | null>();
  const [searchProcessID, setSearchProcessID] = useState<any[]>([]);
  const [searchBreakResolvState, setSearchBreakResolvState] = useState<
    string | null
  >();
  const [queryDateRange, setQueryDateRange] = useState<RangeValue>();
  const [lightButton, setLightButton] = useState<string>();
  const [totalNum, setTotalNumBar] = useState<MyBarChartType>();
  const [processNum, setProcessNum] = useState<MyBarChartType>();
  const [processPieNum, setProcessPieNum] = useState<MyPieChartType>();
  const [page, setPage] = useState<number>(1);

  const beforeThisWeekStr = GetDate("before_this_week")?.toString();
  const beforeTwoWeeksStr = GetDate("before_two_weeks")?.toString();
  const lastSixMonthStr = GetDate("last_six_month")?.toString();
  const currentYearsStr = GetDate("year")?.toString();
  const currentMonthStr = GetDate("month")?.toString();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const breakProcessName = [
    {
      text: "未分配",
      value: 1,
    },
    {
      text: "现场处理中",
      value: 2,
    },
    {
      text: "客服处理中",
      value: 3,
    },
    {
      text: "SRD处理中",
      value: 4,
    },
    {
      text: "研发处理中",
      value: 5,
    },
    {
      text: "已解决",
      value: 6,
    },
    {
      text: "挂起中",
      value: 7,
    },
  ];

  const breakResolveState = [
    {
      text: "已解决",
      value: "已解决",
    },
    {
      text: "未解决",
      value: "未解决",
    },
  ];

  const maxTagPlaceholder = (value: any) => {
    return (
      <div
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        <Tooltip
          title={value.map((item: any) => item.label).join(", ")}
          color={"blue"}
          key={"blue_Tooltip_1"}
          open={tooltipVisible}
          onOpenChange={(visible) => setTooltipVisible(visible)}
        >
          <div style={{ color: "blue" }}>+{value.length}</div>
        </Tooltip>
      </div>
    );
  };

  const {
    run: getHosNodes,
    isLoading: gettingNodes,
    data: hosNodes,
  } = useAsync<HosNodesType[]>();
  const getHosNodesHandle = () => {
    getHosNodes(client(GET_HOSP_NODES));
  };
  const { run: getHosNodesDebounce } = useDebounceFn(getHosNodesHandle, {
    wait: 200,
  });

  const initQueryParams = () => {
    setPage(1);
    setQueryDateRange(GetDate("last_six_month"));
    setTabName(1);
    setSearchAreaID(null);
    setSearchProcessID([]); // test
    setSearchBreakResolvState(null);
  };

  const search = (params: any) => {
    if (queryDateRange) {
      const start_date = queryDateRange[0]?.format("YYYY-MM-DD");
      const end_date = queryDateRange[1]?.format("YYYY-MM-DD");
      const queryParams = {
        order_type_id: tabName,
        start_date: start_date,
        end_date: end_date,
        ...(searchAreaID ? { area_id: searchAreaID } : {}),
        ...(searchProcessID
          ? { process_id_list: searchProcessID.map((i) => i).join("|") }
          : {}),
        ...(searchBreakResolvState ? { status: searchBreakResolvState } : {}),
      };
      return client(GET_ISSUES_PROCESS_LIST, {
        data: { ...queryParams, ...params },
      });
    }
  };

  const { run: getFile, isLoading: gettingFile } = useAsync();
  const getFileHandle = () => {
    if (queryDateRange) {
      const start_date = queryDateRange[0]?.format("YYYY-MM-DD");
      const end_date = queryDateRange[1]?.format("YYYY-MM-DD");
      const params = {
        order_type_id: tabName,
        start_date: start_date,
        end_date: end_date,
        area_id: searchAreaID,
        ...(searchProcessID
          ? { process_id_list: searchProcessID.map((i) => i).join("|") }
          : {}),
        status: searchBreakResolvState,
        is_download: 1,
      };
      getFile(
        downloadClient(GET_ISSUES_PROCESS_LIST, {
          data: params,
        }),
      );
    }
  };

  const {
    run: getBreakIssuesProcessStatistics,
    isLoading: gettingBreakIssuesProcessStatistics,
    data: BreakIssuesProcessStatistics,
  } = useAsync<BREAK_ISSUES_PROCESS_STATISTICS_TYPE>();
  const getgetBreakIssuesProcessStatisticsHanle = () => {
    if (queryDateRange) {
      const start_date = queryDateRange[0]?.format("YYYY-MM-DD");
      const end_date = queryDateRange[1]?.format("YYYY-MM-DD");
      const params = {
        order_type_id: tabName,
        start_date: start_date,
        end_date: end_date,
        area_id: searchAreaID,
        process_id: searchProcessID,
        status: searchBreakResolvState,
      };
      getBreakIssuesProcessStatistics(
        client(GET_ISSUES_PROCESS_STATISTICS, {
          data: params,
        }),
      );
    }
  };
  const { run: getBreakIssuesProcessStatisticsDebounce } = useDebounceFn(
    getgetBreakIssuesProcessStatisticsHanle,
    {
      wait: 200,
    },
  );

  useEffect(() => {
    let areaListTMP: HosNodesType[] = [];
    hosNodes?.forEach((item) => {
      if (item.nodeLevel === 1 && item.nodeName !== "海外") {
        areaListTMP?.push(item);
      }
    });
    setAreaList(areaListTMP);
  }, [hosNodes]);

  useEffect(() => {
    if (queryDateRange) {
      const queryDateRangeStr = queryDateRange?.toString();
      if (queryDateRangeStr === beforeThisWeekStr) {
        setLightButton("before_this_week");
      } else if (queryDateRangeStr === beforeTwoWeeksStr) {
        setLightButton("before_two_weeks");
      } else if (queryDateRangeStr === currentYearsStr) {
        setLightButton("year");
      } else if (queryDateRangeStr === currentMonthStr) {
        setLightButton("month");
      } else if (queryDateRangeStr === lastSixMonthStr) {
        setLightButton("last_six_month");
      } else {
        setLightButton("");
      }
    }
  }, [queryDateRange]);

  useEffect(() => {
    initQueryParams();
    getBreakIssuesProcessStatisticsDebounce();
  }, []);

  useEffect(() => {
    if (BreakIssuesProcessStatistics) {
      setTotalNumBar(GetBreakProcessTotalNum(BreakIssuesProcessStatistics));
      setProcessNum(GetBreakProcessDepartmentNum(BreakIssuesProcessStatistics));
      setProcessPieNum(
        GetBreakProcessDepartmentPieNum(BreakIssuesProcessStatistics),
      );
    }
  }, [BreakIssuesProcessStatistics]);
  useEffect(() => {
    setPage(1);
    getBreakIssuesProcessStatisticsDebounce();
    actionRef.current?.reload();
  }, [
    queryDateRange,
    searchAreaID,
    searchProcessID,
    searchBreakResolvState,
    tabName,
  ]);

  useMount(() => {
    getHosNodesDebounce();
  });

  const BreaklistColumns: ProColumns<BREAK_ISSUES_PROCESS_LIST_TYPE>[] = [
    {
      title: "工单ID",
      dataIndex: "order_id",
    },
    {
      title: "工单状态",
      dataIndex: "process_name", // number|number
    },
    {
      title: "客户名称",
      dataIndex: "customer_name",
      render: (_, record, __) => {
        return record.customer_name || record.hospital_name;
      },
    },
    {
      title: "区域",
      dataIndex: "area_name",
    },
    {
      title: "节点负责人",
      dataIndex: "node_user_name",
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      search: false,
      render: (_, record, __) => {
        return dayjs(record.create_time).format(DateTimeFormate);
      },
    },
    {
      title: "处理时长",
      dataIndex: "total_time",
      search: false,
      tooltip: (
        <span
          dangerouslySetInnerHTML={{
            __html: `处理时长指标说明:<br />
          1. 工单未关单时, 处理时长 = 当前时间 - 创建时间<br />
          2. 工单已解决时, 处理时长 = 关单时间 - 创建时间`,
          }}
        />
      ),
    },
    // {
    //   title: "线上处理时长",
    //   dataIndex: "online_time",
    //   search: false,
    // },
    // {
    //   title: "现场处理时长",
    //   dataIndex: "on_site_time",
    //   search: false,
    // },
    // {
    //   title: "SRD处理时长",
    //   dataIndex: "srd_time",
    //   search: false,
    // },
    // {
    //   title: "研发处理时长",
    //   dataIndex: "dev_time",
    //   search: false,
    // },
  ];

  const Loading = gettingNodes;

  return (
    <>
      <Row
        style={{
          minWidth: 988,
        }}
      >
        <Col span={24}>
          <PageHeader>
            <Row>
              <Col span={5}>
                <Space>
                  <Radio.Group
                    onChange={(e) => {
                      setTabName(e.target.value);
                    }}
                    defaultValue={tabName}
                    buttonStyle="solid"
                    size="middle"
                  >
                    <Radio.Button
                      style={{ paddingLeft: "8px", paddingRight: "8px" }}
                      value={1}
                      key={`raido_button_1`}
                    >
                      使用故障
                    </Radio.Button>
                    <Radio.Button
                      style={{ paddingLeft: "8px", paddingRight: "8px" }}
                      value={2}
                      key={`raido_button_2`}
                    >
                      使用需求
                    </Radio.Button>
                    <Radio.Button
                      style={{ paddingLeft: "8px", paddingRight: "8px" }}
                      value={3}
                      key={`raido_button_3`}
                    >
                      日常服务
                    </Radio.Button>
                  </Radio.Group>
                </Space>
              </Col>
              <Col span={19} style={{ textAlign: "right" }}>
                <Space wrap>
                  <Tooltip title="工单创建时间< 本周周一">
                    <Button
                      size="small"
                      type={
                        lightButton === "before_this_week" ? "link" : "text"
                      }
                      style={{ padding: "1px" }}
                      onClick={() => {
                        setQueryDateRange(GetDate("before_this_week"));
                      }}
                    >
                      本周前
                    </Button>
                  </Tooltip>
                  <Tooltip title="工单创建时间< 上周周一">
                    <Button
                      size="small"
                      style={{ padding: "1px" }}
                      type={
                        lightButton === "before_two_weeks" ? "link" : "text"
                      }
                      onClick={() => {
                        setQueryDateRange(GetDate("before_two_weeks"));
                      }}
                    >
                      两周前
                    </Button>
                  </Tooltip>
                  <Tooltip title="工单创建时间 = 本月1日到本月末.">
                    <Button
                      size="small"
                      style={{ padding: "1px" }}
                      type={lightButton === "month" ? "link" : "text"}
                      onClick={() => {
                        setQueryDateRange(GetDate("month"));
                      }}
                    >
                      本月
                    </Button>
                  </Tooltip>
                  <Tooltip title="工单创建时间 = 本年1月1日到本年12月31日.">
                    <Button
                      size="small"
                      style={{ padding: "1px" }}
                      type={lightButton === "year" ? "link" : "text"}
                      onClick={() => {
                        setQueryDateRange(GetDate("year"));
                      }}
                    >
                      本年
                    </Button>
                  </Tooltip>
                  <RangePicker
                    style={{ width: "240px" }}
                    allowClear={false}
                    onChange={(val) => {
                      setQueryDateRange(val);
                    }}
                    value={queryDateRange}
                    picker="date"
                  />
                  <Select
                    allowClear
                    placeholder="区域"
                    disabled={Loading}
                    loading={Loading}
                    value={searchAreaID}
                    onChange={(nodeId) => {
                      setSearchAreaID(nodeId);
                    }}
                  >
                    {areaList?.map((item, index) => (
                      <Option key={index} value={item.nodeId}>
                        {item.nodeName}
                      </Option>
                    ))}
                  </Select>
                  <Tooltip title="工单状态与解决状态互斥,不能同时选择.">
                    <Select
                      allowClear
                      style={{ minWidth: "110px" }}
                      mode="multiple"
                      maxTagCount={1}
                      maxTagPlaceholder={maxTagPlaceholder}
                      placeholder={
                        <span style={{ textAlign: "left" }}>工单状态</span>
                      }
                      disabled={Loading}
                      loading={Loading}
                      value={searchProcessID}
                      defaultValue={breakProcessName.filter((item) =>
                        searchProcessID.includes(item.value),
                      )}
                      onChange={(processID) => {
                        setSearchProcessID(processID);
                        setSearchBreakResolvState(null);
                      }}
                    >
                      {breakProcessName?.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.text}
                        </Option>
                      ))}
                    </Select>
                  </Tooltip>
                  <Tooltip title="解决状态与工单状态互斥,不能同时选择.">
                    <Select
                      allowClear
                      placeholder="解决状态"
                      disabled={Loading}
                      loading={Loading}
                      value={searchBreakResolvState}
                      onChange={(value) => {
                        setSearchBreakResolvState(value);
                        setSearchProcessID([]);
                      }}
                      style={{ width: "85px" }}
                    >
                      {breakResolveState?.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.text}
                        </Option>
                      ))}
                    </Select>
                  </Tooltip>
                  <Button
                    type="primary"
                    shape="round"
                    icon={<DownloadOutlined />}
                    size={"middle"}
                    loading={gettingFile}
                    onClick={() => {
                      getFileHandle();
                    }}
                  ></Button>
                </Space>
              </Col>
            </Row>
          </PageHeader>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ProTable<BREAK_ISSUES_PROCESS_LIST_TYPE>
            style={{
              minWidth: 988,
            }}
            rowKey="id"
            actionRef={actionRef}
            formRef={formRef}
            cardBordered
            columns={BreaklistColumns}
            request={async (params) => {
              try {
                const res = await search(params);
                return {
                  data: res.results,
                  total: res.count,
                  success: true,
                };
              } catch (e) {
                return { success: false };
              }
            }}
            // loading={}
            search={false}
            pagination={{
              showQuickJumper: true,
              showSizeChanger: false,
              pageSize:
                Math.trunc((height - 700) / 47) > 5
                  ? Math.trunc((height - 700) / 47)
                  : 5,
              current: page,
              onChange: (v) => {
                setPage(v);
              },
            }}
            onRow={(record) => ({
              onClick: () => openBreaklistDetail(record.id),
            })}
          />
        </Col>
      </Row>
      <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
      <Row style={{ minHeight: 320, width: "100%" }}>
        <Col span={8}>
          <Spin spinning={gettingBreakIssuesProcessStatistics}>
            <Row style={{ minHeight: 320, width: "100%" }}>
              <Col span={24}>
                <MyBarChart Data={totalNum} />
              </Col>
            </Row>
          </Spin>
        </Col>
        <Col span={1}>
          <Divider type="vertical" style={{ height: "100%", color: "black" }} />
        </Col>
        <Col span={8}>
          <Spin spinning={gettingBreakIssuesProcessStatistics}>
            <Row style={{ minHeight: 320, width: "100%" }}>
              <Col span={24}>
                <MyBarChart Data={processNum} />
              </Col>
            </Row>
          </Spin>
        </Col>
        <Col span={1}>
          <Divider type="vertical" style={{ height: "100%", color: "black" }} />
        </Col>
        <Col span={6}>
          <Spin spinning={gettingBreakIssuesProcessStatistics}>
            <Row style={{ minHeight: 320, width: "100%" }}>
              <Col span={24}>
                <MyPieChart Data={processPieNum} showLegend={false} />
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
      <BreakDetailModal actionRef={actionRef} />
    </>
  );
};
