import { Fragment } from "react";
import { Descriptions, Row, Col, Statistic, Button } from "antd";

import { ProductUsingDetailType, ProductUsingRecordType } from "../interface";

const ThUsingRate = ({
  usingInfo,
  reload,
}: {
  usingInfo: ProductUsingRecordType | undefined | null;
  reload: () => void;
}) => {
  const RetainInfoDescItem = (item: ProductUsingDetailType, key: number) => {
    let isFirstLine = key === 0;
    const title = (label: string) => (isFirstLine ? label : null);
    const className = () => (isFirstLine ? undefined : "desTable");
    return (
      <Fragment key={`thUsingRate${key}`}>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("日期")}
          className={className()}
        >
          {item?.date || ""}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("病例数")}
          className={className()}
        >
          {item?.predict_num || 0}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("重建数")}
          className={className()}
        >
          {item?.rc2_num || 0}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("访问数")}
          className={className()}
        >
          {item?.access_num || 0}
        </Descriptions.Item>
        <Descriptions.Item
          style={{ textAlign: "center" }}
          label={title("报告下载数")}
          className={className()}
        >
          {item?.download_num || 0}
        </Descriptions.Item>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Descriptions
        column={1}
        title={"使用信息"}
        extra={
          <Button type="primary" onClick={reload}>
            刷新
          </Button>
        }
      />
      <Row gutter={16} style={{ textAlign: "center" }}>
        <Col span={6}>
          <Statistic title="总病例数" value={usingInfo?.total_predict_num} />
        </Col>
        <Col span={6}>
          <Statistic title="总重建数" value={usingInfo?.total_rc2_num} />
        </Col>
        <Col span={6}>
          <Statistic title="总访问数" value={usingInfo?.total_access_num} />
        </Col>
        <Col span={6}>
          <Statistic
            title="总报告下载数"
            value={usingInfo?.total_download_num}
          />
        </Col>
      </Row>
      <Descriptions
        column={5}
        bordered={usingInfo?.details?.length ? true : false}
        layout="vertical"
      >
        {usingInfo?.details?.map((item, index) =>
          RetainInfoDescItem(item, index),
        )}
      </Descriptions>
    </Fragment>
  );
};

export default ThUsingRate;
