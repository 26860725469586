import {
  Descriptions,
  Timeline,
  Collapse,
  Divider,
  Space,
  Typography,
  List,
  Avatar,
  Tooltip,
  Badge,
  Button,
  Modal,
  Row,
  Col,
  Statistic,
  Alert,
  message,
} from "antd";
import {
  LockOutlined,
  QuestionOutlined,
  ToolOutlined,
  LaptopOutlined,
  RocketOutlined,
  BugOutlined,
  CheckOutlined,
  EditOutlined,
  KeyOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { DebounceSelect } from "./debounceSelect";
import { useHttp } from "utils/http";
import { BREAK_DETAIL_TYPE } from "lib/interface/breaklist";
import { FormatTime, FormatDate, getClickableLink } from "utils";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { UpdateSelect } from "authenticated-app/pc/components/UpdateSelect";
import { GET_ISSUES_FEEDBACK_CHANNEL } from "lib/api/issues";
import { GET_USER_LIST } from "lib/api";
import { UpdateDateSelect } from "authenticated-app/pc/components/UpdateDateSelect";
import { UpdateInputNoTip } from "authenticated-app/pc/components/UpdateInputNoTip";
import { UpdateMultiSelect } from "authenticated-app/pc/components/UpdateMultipleSelect";
import { GET_ORDER_SEARCH } from "lib/api/deliverylist";
import { GET_HOSP_PWD } from "lib/api";
import { PamInfoType } from "authenticated-app/pc/customer/customer-list/interface";
import { useEffect, useState } from "react";
const { Item } = Descriptions;
const { Link } = Typography;

const processMap = new Map([
  ["未分配", <QuestionOutlined />],
  ["现场处理中", <ToolOutlined />],
  ["客服处理中", <LaptopOutlined />],
  ["SRD处理中", <RocketOutlined />],
  ["研发处理中", <BugOutlined />],
  ["已解决", <CheckOutlined />],
  ["挂起中", <LockOutlined />],
]);

export const BreakDetailRight = ({
  onUpdate,
  detailInfo,
  isFinished,
  isHasPermission,
  isCanChange,
}: {
  onUpdate: (value: any) => void;
  detailInfo: BREAK_DETAIL_TYPE | null;
  isFinished: boolean;
  isHasPermission: boolean;
  isCanChange: boolean;
}) => {
  const [, setId] = useUrlQueryParam(["detailId"]);
  const client = useHttp();
  const [isOrderEditMode, setIsOrderEditMode] = useState(false);
  const [openOrderRelationCollapse, setOpenOrderRelationCollapse] = useState<
    string[]
  >([]);
  const [openOrderSimilarCollapse, setOpenOrderSimilarCollapse] = useState<
    string[]
  >([]);
  const [pamInfo, setPamInfo] = useState<PamInfoType>();
  const [isPamVisible, setIsPamVisible] = useState(false);
  const [pamIsLoading, setPamIsLoading] = useState(false);
  const [value, setValue] = useState<[]>([]);
  const [relationOrderCount, setRelationOrderCount] = useState<number>(0);

  const getPam = () => {
    if (detailInfo?.hospital_id) {
      if (process.env.REACT_APP_VERSION === "dev") {
        setPamInfo({
          id: detailInfo?.hospital_id,
          name: detailInfo?.hospital_name,
          code: detailInfo?.hospital_id.toString(),
          hospitalPwd: "test_pwd",
        });
        setIsPamVisible(true);
      } else {
        setPamIsLoading(true);
        client(GET_HOSP_PWD(detailInfo?.hospital_id))
          .then((res) => {
            setPamInfo({
              name: detailInfo?.hospital_name,
              code: res[0].id,
              ...res[0],
            });
            setIsPamVisible(true);
          })
          .catch((err) => {
            console.error(err);
            message.error(
              `获取${detailInfo?.hospital_name}的动态密码出错，请联系管理员！`,
            );
          })
          .finally(() => {
            setPamIsLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (detailInfo) {
      let count =
        detailInfo.relation_order_list.length +
        detailInfo.similar_order_list.length;
      setRelationOrderCount(count);
    }
  }, [detailInfo]);

  return (
    <div style={{ flex: 1, height: "100%" }}>
      <Collapse
        className="break-detail-collapse"
        style={{ marginBottom: "2px" }}
        ghost
        activeKey={openOrderRelationCollapse}
        onChange={(v: any) => {
          setOpenOrderRelationCollapse(v);
          if (!v.length) {
            setIsOrderEditMode(false);
          }
        }}
        items={[
          {
            key: "1",
            label: (
              <>
                <Space wrap>
                  <span>未解决工单</span>
                </Space>
              </>
            ),
            extra: (
              <Space wrap>
                <Badge
                  count={detailInfo?.relation_order_list.length}
                  showZero
                  color={
                    detailInfo?.relation_order_list.length ? "#faad14" : "green"
                  }
                  size="default"
                />
              </Space>
            ),
            children: (
              <>
                <>
                  {detailInfo?.relation_order_list ? (
                    <>
                      <List
                        style={{
                          maxHeight: "400px",
                          overflow: "auto",
                        }}
                        itemLayout="horizontal"
                        dataSource={
                          detailInfo ? detailInfo.relation_order_list : []
                        }
                        renderItem={(item) => (
                          <List.Item style={{ padding: 6 }}>
                            <List.Item.Meta
                              avatar={
                                <Tooltip title={item.process_name}>
                                  <Avatar
                                    icon={processMap.get(item.process_name)}
                                  />
                                </Tooltip>
                              }
                              title={
                                <Space>
                                  <Link
                                    style={{ fontWeight: "normal" }}
                                    onClick={() => setId({ detailId: item.id })}
                                  >
                                    {item.order_id}
                                  </Link>
                                  <span
                                    style={{
                                      color: " rgba(0, 0, 0, 0.45)",
                                      fontSize: "12px",
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    <Space>
                                      {item.order_type_name}
                                      {FormatTime(item.create_time)}
                                    </Space>
                                  </span>
                                </Space>
                              }
                              description={
                                <span
                                  style={{
                                    color: " rgba(0, 0, 0, 0.88)",
                                    fontSize: "13px",
                                    fontWeight: "normal",
                                    // whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "normal", // 允许文本换行
                                    wordWrap: "break-word", // 当单词过长时换行
                                  }}
                                >
                                  {item.title}
                                </span>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </>
                  ) : null}
                </>
              </>
            ),
          },
        ]}
      />
      <Collapse
        className="break-detail-collapse"
        style={{ marginBottom: "2px" }}
        ghost
        activeKey={openOrderSimilarCollapse}
        onChange={(v: any) => {
          setOpenOrderSimilarCollapse(v);
          if (!v.length) {
            setIsOrderEditMode(false);
          }
        }}
        items={[
          {
            key: "1",
            label: (
              <>
                <Space wrap>
                  <span>相似工单</span>
                </Space>
              </>
            ),
            extra: (
              <Space wrap>
                {isOrderEditMode ? (
                  <Button
                    type="text"
                    style={{
                      color: "var(--adm-color-primary)",
                      padding: "4px",
                    }}
                    onClick={(event) => {
                      setIsOrderEditMode(false);
                      setValue([]);
                      event.stopPropagation();
                    }}
                  >
                    退出更新
                  </Button>
                ) : (
                  <>
                    <Button
                      icon={
                        <EditOutlined
                          style={{ color: "var(--adm-color-primary)" }}
                        />
                      }
                      type="text"
                      onClick={(event) => {
                        setOpenOrderSimilarCollapse(["1"]);
                        setIsOrderEditMode(true);
                        event.stopPropagation();
                      }}
                    />
                    <Badge
                      count={detailInfo?.similar_order_list.length}
                      showZero
                      color={
                        detailInfo?.similar_order_list.length
                          ? "#faad14"
                          : "green"
                      }
                      size="default"
                    />
                  </>
                )}
              </Space>
            ),
            children: (
              <>
                {isOrderEditMode ? (
                  <>
                    <div style={{ width: "100%" }}>
                      <DebounceSelect
                        value={value}
                        placeholder="输入工单号查询选择添加"
                        api={GET_ORDER_SEARCH}
                        onChange={(newValue: any) => {
                          setValue(newValue);
                        }}
                        style={{
                          width: "100%",
                          maxWidth: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        updateList={detailInfo?.similar_order_list.map(
                          (item) => item.id,
                        )}
                        onUpdate={onUpdate}
                      />
                      {detailInfo?.similar_order_list.length ? (
                        <List
                          style={{
                            maxHeight: "400px",
                            overflow: "auto",
                            marginTop: "6px",
                          }}
                          itemLayout="horizontal"
                          dataSource={detailInfo?.similar_order_list}
                          renderItem={(item) => (
                            <List.Item style={{ padding: 6 }}>
                              <List.Item.Meta
                                title={
                                  <Space>
                                    <Link
                                      style={{ fontWeight: "normal" }}
                                      onClick={() => {
                                        if (!isOrderEditMode) {
                                          setId({ detailId: item.id });
                                        }
                                      }}
                                    >
                                      {item.order_id}
                                    </Link>
                                    <span
                                      style={{
                                        color: "rgba(0, 0, 0, 0.45)",
                                        fontSize: "12px",
                                        fontWeight: "lighter",
                                      }}
                                    >
                                      <Space>
                                        {item.order_type_name}
                                        {FormatTime(item.create_time)}
                                        <Button
                                          size="small"
                                          type="text"
                                          icon={<DeleteOutlined />}
                                          onClick={() => {
                                            onUpdate({
                                              similar_id_order_list:
                                                detailInfo?.similar_order_list
                                                  .filter(
                                                    (listItem: any) =>
                                                      listItem.id !== item.id,
                                                  )
                                                  .map((i) => i.id),
                                            });
                                          }}
                                        />
                                      </Space>
                                    </span>
                                  </Space>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      ) : null}
                      <Divider style={{ margin: "6px" }}></Divider>
                    </div>
                  </>
                ) : (
                  <>
                    <Divider
                      orientationMargin={0}
                      orientation="left"
                      plain
                      style={{ margin: "2px", flex: 1 }}
                    ></Divider>
                    {detailInfo?.similar_order_list.length ? (
                      <List
                        style={{
                          maxHeight: "400px",
                          overflow: "auto",
                        }}
                        itemLayout="horizontal"
                        dataSource={detailInfo?.similar_order_list}
                        renderItem={(item) => (
                          <List.Item style={{ padding: 6 }}>
                            <List.Item.Meta
                              avatar={
                                <Tooltip title={item.process_name}>
                                  <Avatar
                                    icon={processMap.get(item.process_name)}
                                  />
                                </Tooltip>
                              }
                              title={
                                <Space>
                                  <Link
                                    style={{ fontWeight: "normal" }}
                                    onClick={() => setId({ detailId: item.id })}
                                  >
                                    {item.order_id}
                                  </Link>
                                  <span
                                    style={{
                                      color: " rgba(0, 0, 0, 0.45)",
                                      fontSize: "12px",
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    <Space>
                                      {item.order_type_name}
                                      {FormatTime(item.create_time)}
                                    </Space>
                                  </span>
                                </Space>
                              }
                              description={
                                <span
                                  style={{
                                    color: " rgba(0, 0, 0, 0.88)",
                                    fontSize: "13px",
                                    fontWeight: "normal",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "normal", // 允许文本换行
                                    wordWrap: "break-word", // 当单词过长时换行
                                  }}
                                >
                                  {item.title}
                                </span>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    ) : null}
                  </>
                )}
              </>
            ),
          },
        ]}
      />
      <Collapse
        className="break-detail-collapse"
        defaultActiveKey={["1"]}
        ghost
        items={[
          {
            key: "1",
            label: "工单状态",
            children: (
              <>
                <Descriptions size="small" column={2}>
                  <Item label={"流程状态"}>{detailInfo?.process_name}</Item>
                  <Item label={"接单时长"}>
                    <Tooltip title="创建到转换为开始处理的时长">
                      {detailInfo?.receiving_time}
                    </Tooltip>
                  </Item>
                  <Item label={"解决时长"}>
                    <Tooltip title="创建工单到已解决的时长">
                      {detailInfo?.processing_time}
                    </Tooltip>
                  </Item>
                  <Item label={"响应时长"}>
                    {detailInfo?.response_time} 分钟
                  </Item>
                  <Item label={"节点负责人"}>{detailInfo?.node_user_name}</Item>
                  <Item label={"工单负责人"}>
                    {detailInfo?.issue_user_name}
                  </Item>
                  <Item label={"工单创建人"}>{detailInfo?.create_user}</Item>
                </Descriptions>
                <Descriptions size="small" column={1}>
                  <Item label={"创建时间"}>
                    {FormatTime(detailInfo?.create_time)}
                  </Item>
                </Descriptions>
              </>
            ),
          },
        ]}
      />
      <Collapse
        className="break-detail-collapse"
        defaultActiveKey={["1"]}
        ghost
        items={[
          {
            key: "1",
            label: "工单关注人",
            extra: (
              <>
                <Badge
                  count={detailInfo?.follow_user_list.length}
                  size="default"
                  color="blue"
                ></Badge>
              </>
            ),
            children: (
              <div style={{ height: "40px", overflow: "auto" }}>
                <Item>
                  <UpdateMultiSelect
                    maxTag={2}
                    onUpdate={onUpdate}
                    defaultValue={detailInfo?.follow_user_list}
                    defaultIds={detailInfo?.follow_user_list.map(
                      (item) => item.id,
                    )}
                    label={""}
                    name={"follow_user_id_list"}
                    options={{ value: "id", label: "userName" }}
                    api={GET_USER_LIST}
                    isCanChange={true}
                  />
                </Item>
              </div>
            ),
          },
        ]}
      ></Collapse>
      <Divider className="no-margin-divider" />
      <Collapse
        className="break-detail-collapse"
        defaultActiveKey={["1"]}
        ghost
        items={[
          {
            key: "1",
            label: "客户信息",
            children: (
              <>
                <Descriptions size="small" column={2}>
                  <Item label={"区域"}>
                    {detailInfo?.hospital_info?.area_name}
                  </Item>
                  <Item label={"省份"}>
                    {detailInfo?.hospital_info.province_name}
                  </Item>
                  <Item label={"售后"}>
                    {detailInfo?.hospital_info.execute_user_name}
                  </Item>
                  <Item label={"运营"}>
                    {detailInfo?.hospital_info.operate_user_name}
                  </Item>
                  <Item label={"销售"}>
                    {detailInfo?.hospital_info?.sales_user_name}
                  </Item>
                  <Item label={"售前"}>
                    {detailInfo?.hospital_info?.pre_sales_user_name}
                  </Item>
                </Descriptions>
                <Descriptions size="small" column={1}>
                  <Item label={"远程信息"}>{detailInfo?.remote_info}</Item>
                  <Item label={"动态密码"}>
                    <Space>
                      <Button
                        icon={<KeyOutlined />}
                        loading={pamIsLoading}
                        disabled={isPamVisible}
                        size="small"
                        onClick={getPam}
                      >
                        点击查询
                      </Button>
                    </Space>
                  </Item>
                </Descriptions>
                <Descriptions size="small" column={2}>
                  <Item label={"已上线"}>
                    {detailInfo?.hospital_info.product_info
                      ?.map(
                        (i) =>
                          `${i.product_chinese_name} ${i.product_version_name}`,
                      )
                      .join("、")}
                  </Item>
                </Descriptions>
              </>
            ),
          },
        ]}
      />
      <Divider className="no-margin-divider" />
      <Collapse
        className="break-detail-collapse"
        defaultActiveKey={["1"]}
        ghost
        items={[
          {
            key: "1",
            label: "反馈信息",
            children: (
              <>
                <Descriptions size="small" column={1}>
                  <Item>
                    <UpdateSelect
                      onUpdate={onUpdate}
                      defaultValue={detailInfo?.feedback_channel_name}
                      defaultId={detailInfo?.feedback_channel_id}
                      label={"渠道"}
                      name={"feedback_channel_name"}
                      options={{ value: "id", label: "name" }}
                      api={GET_ISSUES_FEEDBACK_CHANNEL}
                      isCanChange={isCanChange}
                    />
                  </Item>
                  <Item>
                    <UpdateInputNoTip
                      value={detailInfo?.feedback_user}
                      onUpdate={onUpdate}
                      label={"反馈人"}
                      name={"feedback_user"}
                      isCanChange={isCanChange}
                    />
                  </Item>
                </Descriptions>
                <Descriptions size="small" column={1}>
                  <Item>
                    <UpdateInputNoTip
                      value={detailInfo?.contact}
                      onUpdate={onUpdate}
                      label={"联系方式"}
                      name={"contact"}
                      isCanChange={isCanChange}
                    />
                  </Item>
                </Descriptions>
                <Item>
                  <UpdateDateSelect
                    showTime
                    value={detailInfo?.feedback_time}
                    onUpdate={onUpdate}
                    label={"反馈时间"}
                    name={"feedback_time"}
                    isCanChange={isCanChange}
                  />
                </Item>
              </>
            ),
          },
        ]}
      />
      <Divider className="no-margin-divider" />
      <Collapse
        className="break-detail-collapse"
        ghost
        items={[
          {
            key: "1",
            label: "确认单",
            children: (
              <Descriptions size="small" column={1}>
                <Item label={"服务确认单"}>
                  <Link
                    href={
                      detailInfo?.service_order_addr
                        ? getClickableLink(detailInfo?.service_order_addr)
                        : undefined
                    }
                    download
                    target="_blank"
                    ellipsis
                    style={{
                      lineHeight: "32px",
                      width: "4rem",
                    }}
                  >
                    <Tooltip title={detailInfo?.service_order_name}>
                      {detailInfo?.service_order_name ? "点击查看" : ""}
                    </Tooltip>
                  </Link>
                </Item>
              </Descriptions>
            ),
          },
        ]}
      />
      <Divider className="no-margin-divider" />
      <Collapse
        className="break-detail-collapse"
        ghost
        items={[
          {
            key: "1",
            label: "转派挂起记录",
            children: (
              <Descriptions size="small" column={1}>
                <Item label={"转派原因"}>
                  {detailInfo?.reasons_for_transfer}
                </Item>
                <Item label={"挂起原因"}>{detailInfo?.suspension_reason}</Item>
                <Item label={"下次跟进日期"}>
                  {FormatDate(detailInfo?.next_time)}
                </Item>
              </Descriptions>
            ),
          },
        ]}
      />
      <Divider className="no-margin-divider" />
      <Collapse
        className="break-detail-collapse"
        ghost
        items={[
          {
            key: "1",
            label: "变更记录",
            children: (
              <Timeline
                style={{
                  paddingTop: "12px",
                  maxHeight: "400px",
                  overflow: "auto",
                }}
                items={detailInfo?.operation_record_list?.map((i) => ({
                  children: (
                    <Space direction="vertical">
                      <span>{FormatTime(i.create_time)}</span>
                      <Space>
                        <span>{i.update_user}</span>
                        <span>{i.process_trans_name}</span>
                        <span>{i.to_user}</span>
                      </Space>
                    </Space>
                  ),
                }))}
              />
            ),
          },
        ]}
      />
      <Divider className="no-margin-divider" />
      <Modal
        title={pamInfo?.name}
        open={isPamVisible}
        onOk={() => setIsPamVisible(false)}
        onCancel={() => setIsPamVisible(false)}
        cancelText={"取消"}
        okText={"确定"}
        centered
      >
        <Row>
          <Col span={12}>
            <Statistic title="医院编号" formatter={() => pamInfo?.code} />
          </Col>
          <Col span={12}>
            <Statistic
              title="动态密码"
              formatter={() => pamInfo?.hospitalPwd}
            />
          </Col>
        </Row>
        <Alert message="密码仅当天有效，请妥善保管，切勿泄露！" type="error" />
      </Modal>
    </div>
  );
};
