import { useEffect, useRef, useState } from "react";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TitleComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
} from "echarts/components";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { message } from "antd";
import dayjs from "dayjs";

import { DateStep, RateResults } from "../interface";
import { HospListType } from "interface";
import { isR, isRRateTip, productIdToName } from "utils";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";

type EChartsOption = echarts.ComposeOption<
  | TitleComponentOption
  | ToolboxComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | LineSeriesOption
>;

export const RateLineChart = ({
  data,
  unit,
  isTh,
  hospInfo,
  ismobile = false,
  isDownload = true,
  chartHeight = 500,
  product,
}: {
  data: RateResults[] | undefined;
  unit: DateStep;
  isTh: boolean;
  hospInfo?: HospListType;
  ismobile?: boolean;
  isDownload?: boolean;
  chartHeight?: number;
  product: string | null;
}) => {
  echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
  ]);

  const [option, setOption] = useState<EChartsOption>({});

  const getOption = (
    legendData: string[],
    xData: string[],
    ySeris: LineSeriesOption[],
  ): EChartsOption => ({
    title: {
      text: ismobile
        ? undefined
        : `${productIdToName.get(product || "unknown") || "未知产品线"}`,
      subtext: ismobile ? undefined : isR(product) ? isRRateTip : undefined,
    },
    tooltip: {
      trigger: "axis",
      valueFormatter: (value: any) => {
        if (!isNaN(value)) {
          if ((value + "").indexOf(".") !== -1) {
            return value + " %";
          }
        }
        return value;
      },
    },
    legend: {
      data: legendData,
      selected: {
        病例数: true,
        重建数: true,
        访问数: true,
        报告下载数: true,
        预测量: false,
        点击量: false,
        点击率: true,
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    toolbox: {
      feature: isDownload
        ? {
            saveAsImage: {
              title: "保存截图",
              name:
                `${hospInfo?.name}-${hospInfo?.code}-点击率折线图-${dayjs().format(
                  "YYYY-MM-DD-HHMM",
                )}` || "点击率折线图",
            },
          }
        : undefined,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xData,
    },
    yAxis: {
      type: "value",
    },
    series: ySeris,
  });

  const chartRef = useRef(null);
  let myChart: echarts.ECharts;

  const renderChart = (option: EChartsOption) => {
    try {
      const renderedInstance = echarts.getInstanceByDom(
        chartRef.current as unknown as HTMLElement,
      );
      if (renderedInstance) {
        myChart = renderedInstance;
      } else {
        myChart = echarts.init(chartRef.current as unknown as HTMLElement);
      }
      myChart.setOption(option);
    } catch (error) {
      message.error("初始化图表失败！");
      console.error(error);
      myChart && myChart.dispose();
    }
  };

  const resizeHandler = () => {
    myChart.resize();
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    renderChart(option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  useEffect(() => {
    if (data) {
      let tmpLegendData: string[];
      let tmpXData: string[] = [];
      let tmpYSeris: LineSeriesOption[] = [];
      if (isTh) {
        tmpLegendData = ["病例数", "重建数", "访问数", "报告下载数"];
        tmpYSeris = [
          {
            name: "病例数",
            type: "line",
            data: [],
          },
          {
            name: "重建数",
            type: "line",
            data: [],
          },
          {
            name: "访问数",
            type: "line",
            data: [],
          },
          {
            name: "报告下载数",
            type: "line",
            data: [],
          },
        ];
      } else {
        tmpLegendData = ["预测量", "点击量", "点击率"];
        tmpYSeris = [
          {
            name: "预测量",
            type: "line",
            data: [],
          },
          {
            name: "点击量",
            type: "line",
            data: [],
          },
          {
            name: "点击率",
            type: "line",
            data: [],
          },
        ];
      }
      data.forEach((item) => {
        if (isTh) {
          tmpYSeris
            .find((i) => i.name === "病例数")
            ?.data?.push(item.predict_num === 0 ? 0 : item.predict_num || "");
          tmpYSeris
            .find((i) => i.name === "重建数")
            ?.data?.push(item.rc2_num === 0 ? 0 : item.rc2_num || "");
          tmpYSeris
            .find((i) => i.name === "访问数")
            ?.data?.push(item.access_num === 0 ? 0 : item.access_num || "");
          tmpYSeris
            .find((i) => i.name === "报告下载数")
            ?.data?.push(item.download_num === 0 ? 0 : item.download_num || "");
        } else {
          tmpYSeris
            .find((i) => i.name === "预测量")
            ?.data?.push(item.predict_num === 0 ? 0 : item.predict_num || "");
          tmpYSeris
            .find((i) => i.name === "点击量")
            ?.data?.push(item.usv_num === 0 ? 0 : item.usv_num || "");
          tmpYSeris
            .find((i) => i.name === "点击率")
            ?.data?.push(
              parseFloat(item.click_rate?.split("%")[0] || "0").toFixed(2),
            );
        }
        switch (unit) {
          case DateStep.day:
            tmpXData.push(item.date[0]);
            break;
          case DateStep.week:
            tmpXData.push(`${item.date[0]}~${item.date[1]}`);
            break;
          case DateStep.month:
            tmpXData.push(
              `${item.date[0].split("-")[0]}-${item.date[0].split("-")[1]}`,
            );
            break;
          case DateStep.year:
            tmpXData.push(item.date[0].split("-")[0]);
            break;
        }
      });
      setOption(getOption(tmpLegendData, tmpXData, tmpYSeris));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ContentCard>
      <div
        style={{ width: "100%", height: chartHeight, minHeight: 300 }}
        ref={chartRef}
      />
    </ContentCard>
  );
};
