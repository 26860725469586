import { useState } from "react";
import {
  Button,
  Dialog,
  Ellipsis,
  List,
  SwipeAction,
  Toast,
} from "antd-mobile";
import {
  RightOutline,
  EditSOutline,
  DeleteOutline,
  ExclamationCircleFill,
  AddSquareOutline,
} from "antd-mobile-icons";

import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { LoadingMask } from "authenticated-app/mobile/components/LoadingMask";
import { useHttp } from "utils/http";
import { ToastContent } from "authenticated-app/mobile/components/ToastContent";
import { useAsync } from "utils/hooks/useAsync";
import {
  ListRightIconSize,
  SwipeActionIconSize,
} from "authenticated-app/mobile/util";
import { DelConfirmContent } from "authenticated-app/mobile/components/DelConfirmContent";
import { NetworkType } from "authenticated-app/pc/customer/customer-detail/tab-pane/hardwareTabPane/interface";
import { DEL_NETWORK } from "lib/api";
import { MobileNetworkInfoDetail } from "./detail";
import { MobileNetworkInfoAdd } from "./add";
import { MobileNetworkInfoEdit } from "./edit";

export const MobileNetworkInfo = ({
  networkInfo,
  hardware_id,
  reload,
}: {
  networkInfo: NetworkType[] | undefined | null;
  hardware_id: number | null;
  reload: () => void;
}) => {
  const [detailInfo, setDetailInfo] = useState<NetworkType>();
  const [detailVisible, setDetailVisible] = useState<boolean>(false);

  const [addVisible, setAddVisible] = useState<boolean>(false);

  const [editingItem, seteditingItem] = useState<NetworkType>();
  const [editVisible, setEditVisible] = useState<boolean>(false);

  const client = useHttp();

  const { run: del, isLoading: deleting } = useAsync();
  const delHandle = (id: number) => {
    if (id)
      del(
        client(DEL_NETWORK(id), { method: "DELETE" }).then(() => {
          Toast.show({
            icon: "success",
            content: <ToastContent content="删除成功" />,
          });
          reload();
        }),
      ).catch((e) => {
        console.error(e);
        Toast.show({
          icon: "fail",
          content: <ToastContent content="删除失败" />,
        });
      });
    else
      Toast.show({
        icon: "fail",
        content: <ToastContent content="无法删除" />,
      });
  };
  return (
    <ContentCard>
      <LoadingMask visible={deleting} />
      <List
        mode="card"
        header={
          <ListHeader
            text="网络信息"
            rightIcon={<AddSquareOutline fontSize={ListRightIconSize} />}
            loading={addVisible}
            onClick={() => {
              setAddVisible(true);
            }}
          />
        }
      >
        {networkInfo?.map((r) => (
          <SwipeAction
            key={r.id}
            closeOnAction={false}
            rightActions={[
              {
                key: "edit",
                text: <EditSOutline fontSize={SwipeActionIconSize} />,
                color: "primary",
                onClick: () => {
                  seteditingItem(r);
                  setEditVisible(true);
                },
              },
              {
                key: "delete",
                text: <DeleteOutline fontSize={SwipeActionIconSize} />,
                color: "danger",
                onClick: () => {
                  Dialog.confirm({
                    header: (
                      <ExclamationCircleFill
                        style={{
                          fontSize: 48,
                          color: "var(--adm-color-danger)",
                        }}
                      />
                    ),
                    content: <DelConfirmContent content={`${r.ip}的记录`} />,
                    confirmText: (
                      <span style={{ color: "var(--adm-color-danger)" }}>
                        确定
                      </span>
                    ),
                    onConfirm: () => delHandle(r.id),
                  });
                },
              },
            ]}
          >
            <List.Item
              arrow={
                <Button
                  fill="none"
                  style={{ height: "100%", padding: 0 }}
                  loading={
                    detailVisible &&
                    r.id.toString() === detailInfo?.id.toString()
                  }
                >
                  <RightOutline style={{ color: "var(--adm-color-light)" }} />
                </Button>
              }
              onClick={() => {
                setDetailInfo(r);
                setDetailVisible(true);
              }}
            >
              {r.ip ? <Ellipsis direction="end" content={r.ip} /> : "未知IP"}
            </List.Item>
          </SwipeAction>
        ))}
      </List>
      <MobileNetworkInfoDetail
        detailInfo={detailInfo}
        visible={detailVisible}
        setVisible={setDetailVisible}
      />
      <MobileNetworkInfoAdd
        orgID={hardware_id}
        visible={addVisible}
        setVisible={setAddVisible}
        reload={reload}
      />
      <MobileNetworkInfoEdit
        editingItem={editingItem}
        visible={editVisible}
        setVisible={setEditVisible}
        reload={reload}
      />
    </ContentCard>
  );
};
