import { Fragment, useEffect, useState } from "react";
import {
  Descriptions,
  Space,
  message,
  Tabs,
  Modal,
  Divider,
  Tag,
  Popover,
  Tooltip,
  Typography,
} from "antd";
import {
  EditOutlined,
  CloseCircleFilled,
  ExclamationCircleFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";

import { useHttp } from "utils/http";
import { DEL_HARDWARE } from "lib/api";
import {
  HardwareDesItemLabelMax,
  HardwareDesItemLabelMin,
  LineDiv,
  SingleImageList,
} from "../../utils";
import { HardwareCtrlProps } from "../interface";
import NetworkInfo from "../network-info";
import GPUInfo from "../gpu-info";
import { ServerBaseInfoAdd } from "./add";
import { ServerBaseInfoEdit } from "./edit";
import { isNumber } from "utils";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
//ProductVersionType
import { ProductVersionType } from "../interface";

const { Paragraph, Link } = Typography;

const ServerBaseInfo = ({
  hospID,
  detail,
  reload,
  setDisChange,
  changeCount,
}: HardwareCtrlProps) => {
  const [{ tab, server }, setServer] = useUrlQueryParam(["tab", "server"]);

  const [edittingID, setEdittingID] = useState<string | null>(null);
  const [hardwareIsEditable, setHardwareIsEditable] = useState(false);
  const [addHardwareInfoVisible, setAddHardwareInfoVisible] = useState(false);

  const client = useHttp();

  // const getProductStatus = (info: ProductVersionType, icon?: boolean) => {
  //   let status = info.product_status;
  //   if (status === 1) {
  //     return (
  //       <Tooltip title="已授权">
  //         <Tag color="green" style={{ margin: 0 }}>
  //           {info.product_id} {info.product_version_name}{" "}
  //         </Tag>
  //       </Tooltip>
  //     );
  //   } else if (status === 2) {
  //     return (
  //       <Tooltip title="未授权">
  //         {" "}
  //         <Tag color="orange" style={{ margin: 0 }}>
  //           {info.product_id} {info.product_version_name}
  //         </Tag>
  //       </Tooltip>
  //     );
  //   } else if (status === 3) {
  //     return (
  //       <Tooltip title="无需授权">
  //         <Tag color="lime" style={{ margin: 0 }}>
  //           {info.product_id} {info.product_version_name}
  //         </Tag>
  //       </Tooltip>
  //     );
  //   } else {
  //     return "未知状态";
  //   }
  // };

  const getProductStatus = (status: number, icon?: boolean) => {
    if (status === 1) {
      return icon ? "#009b51" : "已授权";
    } else if (status === 2) {
      return icon ? "#ffae11" : "未授权";
    } else if (status === 3) {
      return icon ? "#009b51" : "无需授权";
    } else {
      return "未知状态";
    }
  };

  const onEdit = (
    targetKey:
      | string
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>,
    action: "add" | "remove",
  ) => {
    if (action === "add") {
      setAddHardwareInfoVisible(true);
    } else {
      setDisChange(true);
      setEdittingID(targetKey as string);
      if (!hardwareIsEditable) {
        setHardwareIsEditable(true);
      }
    }
  };

  const delHardware = (id: number, ip: string) => {
    Modal.confirm({
      title: "删除确认",
      content: `确认删除【${ip}】的信息？`,
      okText: "删除",
      cancelText: "取消",
      okButtonProps: { type: "primary", danger: true },
      closable: true,
      icon: <ExclamationCircleFilled style={{ color: "#f5222d" }} />,
      onOk: () =>
        client(DEL_HARDWARE(id), { method: "DELETE" })
          .then(() => {
            message.success("删除成功！");
            setHardwareIsEditable(false);
            setDisChange(false);
            Modal.destroyAll();
            reload();
          })
          .catch((e) => {
            console.error(e);
            message.error("删除失败，请联系管理员！");
          }),
    });
  };

  useEffect(() => {
    if (tab === "hardware" && edittingID) {
      let index = detail?.findIndex((d) => d.id.toString() === edittingID);
      if (index !== undefined && index >= 0 && detail) {
        setServer({ server: edittingID });
      } else {
        if (detail && detail[0]) {
          let settingID = detail[0].id.toString();
          setEdittingID(settingID);
          setServer({ server: settingID });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCount, edittingID]);

  useEffect(() => {
    if (server) {
      let index = detail?.findIndex((d) => d.id.toString() === server);
      if (index !== undefined && index >= 0 && detail) {
        setEdittingID(server);
      } else {
        if (detail && detail[0]) {
          setEdittingID(detail[0].id.toString());
        }
      }
    } else if (detail && detail[0]) {
      setEdittingID(detail[0].id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  return (
    <Fragment>
      <Descriptions
        column={1}
        title={
          <Space style={{ lineHeight: "100%" }}>
            <span>服务器基本信息</span>
            {detail?.length && detail?.length > 1 ? (
              detail?.find((i) => i.id.toString() === edittingID)?.is_mult ===
              1 ? (
                <Tag color="#108ee9">多机</Tag>
              ) : (
                <Tag>单机</Tag>
              )
            ) : null}
          </Space>
        }
      >
        <Descriptions.Item>
          <Tabs
            type="editable-card"
            onEdit={onEdit}
            activeKey={edittingID || undefined}
            hideAdd={hardwareIsEditable}
            onTabClick={(v) => {
              setEdittingID(v);
              setServer({ server: v });
            }}
            items={detail?.map((i) => ({
              disabled: edittingID !== i.id.toString() && hardwareIsEditable,
              key: i.id.toString(),
              label: (
                <>
                  {i.is_master === 1 ? <Tag color="blue">主</Tag> : null}
                  <Space>
                    {i?.server_ip}
                    <Popover
                      title={"更新记录"}
                      content={
                        <>
                          <p> 更新人: {i.update_user || "未知"}</p>
                          <p> 更新时间: {i.update_time || "未知"}</p>
                        </>
                      }
                      trigger="hover"
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  </Space>
                </>
              ),
              closable: edittingID === i.id.toString(),
              closeIcon:
                hardwareIsEditable && i.id.toString() === edittingID ? (
                  <CloseCircleFilled
                    style={{ color: "#f5222d" }}
                    onClick={() => delHardware(i?.id, i?.id.toString())}
                  />
                ) : (
                  <EditOutlined />
                ),
              children:
                hardwareIsEditable && i.id.toString() === edittingID ? (
                  <ServerBaseInfoEdit
                    hospID={hospID}
                    editingItem={i}
                    editable={hardwareIsEditable}
                    setEditable={setHardwareIsEditable}
                    setDisChange={setDisChange}
                    reload={reload}
                  />
                ) : (
                  <>
                    <Descriptions column={2}>
                      <Descriptions.Item
                        label={<HardwareDesItemLabelMin label={"设备品牌"} />}
                      >
                        <LineDiv>{i.eqpt_oem_name}</LineDiv>
                      </Descriptions.Item>

                      <Descriptions.Item
                        label={<HardwareDesItemLabelMax label={"运行产品"} />}
                      >
                        <Popover
                          title="运行产品"
                          placement="topLeft"
                          content={
                            <>
                              {i.products.map((i) => (
                                <p style={{ margin: "2px" }}>{i.product_id}</p>
                              ))}
                            </>
                          }
                          trigger="hover"
                        >
                          <div
                            style={{
                              width: "100%",
                              lineHeight: "36px",
                            }}
                          >
                            <Paragraph
                              ellipsis={{ rows: 2 }}
                              style={{
                                lineHeight: "36px",
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              {i.products.map((i) => i.product_id).join("、")}
                            </Paragraph>
                          </div>
                        </Popover>
                      </Descriptions.Item>
                      {/* <Descriptions.Item label={"运行产品"}>
                        <LineDiv>
                          {i.products.map((i) => i.product_id).join("、")}
                        </LineDiv>
                      </Descriptions.Item> */}
                      <Descriptions.Item
                        label={<HardwareDesItemLabelMin label={"设备型号"} />}
                      >
                        <LineDiv>{i.eqpt_model_name}</LineDiv>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<HardwareDesItemLabelMax label={"系统版本"} />}
                      >
                        <LineDiv>{i.system_version_name}</LineDiv>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<HardwareDesItemLabelMin label={"是否主机"} />}
                      >
                        <LineDiv>
                          {i.is_master === 1
                            ? "是"
                            : i.is_master === 0
                            ? "否"
                            : "未知"}
                        </LineDiv>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <HardwareDesItemLabelMax label={"内存大小(GB)"} />
                        }
                      >
                        <LineDiv>
                          {isNumber(i.memory_capacity) && isNumber(i.memory_num)
                            ? `${i.memory_capacity * i.memory_num} (${
                                i.memory_capacity
                              }*${i.memory_num})`
                            : null}
                        </LineDiv>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<HardwareDesItemLabelMin label={"服务器IP"} />}
                      >
                        <LineDiv>{i.server_ip}</LineDiv>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <HardwareDesItemLabelMax label={"SSD大小(GB)"} />
                        }
                      >
                        <LineDiv>{i.ssd_capacity}</LineDiv>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<HardwareDesItemLabelMin label={"维保代码"} />}
                      >
                        <LineDiv>{i.maintenance_code}</LineDiv>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <HardwareDesItemLabelMax label={"机械硬盘大小(TB)"} />
                        }
                      >
                        <LineDiv>
                          {isNumber(i.disk_capacity) && isNumber(i.disk_num)
                            ? `${i.disk_capacity * i.disk_num} (${
                                i.disk_capacity
                              }*${i.disk_num})`
                            : null}
                        </LineDiv>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<HardwareDesItemLabelMin label={"资产编码"} />}
                      >
                        <LineDiv>{i.assets_code}</LineDiv>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<HardwareDesItemLabelMax label={"放置位置"} />}
                      >
                        <LineDiv>{i.location}</LineDiv>
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<HardwareDesItemLabelMin label={"资产照片"} />}
                      >
                        <SingleImageList imgList={i?.asset_photos} />
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions column={1}>
                      <Descriptions.Item
                        label={
                          <b
                            style={{
                              color: "#4F4F4F",
                              width: "64px",
                              textAlign: "right",
                            }}
                          >
                            安装分支
                          </b>
                        }
                      >
                        <Space wrap>
                          {i.product_version_list.map(
                            (i: ProductVersionType) => (
                              <Tag
                                color={getProductStatus(i.product_status, true)}
                                style={{ margin: "0px" }}
                              >
                                <Tooltip
                                  title={getProductStatus(i.product_status)}
                                >
                                  {i.product_id} {i.product_version_name}
                                </Tooltip>
                              </Tag>
                            ),
                          )}
                        </Space>
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                ),
            }))}
          />
        </Descriptions.Item>
      </Descriptions>
      {detail?.find((i) => i.id.toString() === edittingID)?.server_ip ? (
        <>
          <Divider style={{ margin: "8px" }} />
          <NetworkInfo
            hardware_id={edittingID}
            networkInfo={
              detail?.find((i) => i.id.toString() === edittingID)?.network
            }
            reload={reload}
          />
          <Divider style={{ margin: "8px" }} />
          <GPUInfo
            hospID={hospID}
            hardware_id={edittingID}
            gpuInfo={detail?.find((i) => i.id.toString() === edittingID)?.gpus}
            reload={reload}
          />
        </>
      ) : null}
      <ServerBaseInfoAdd
        hospID={hospID}
        visible={addHardwareInfoVisible}
        setVisible={setAddHardwareInfoVisible}
        reload={reload}
      />
    </Fragment>
  );
};

export default ServerBaseInfo;
