import { useState } from "react";
import { Button, Ellipsis, List, Popup, SwipeAction, Tag } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import { useHttp } from "utils/http";
import { ContentCard } from "authenticated-app/mobile/components/ContentCard";
import { ListHeader } from "authenticated-app/mobile/components/ListHeader";
import { DockingListType } from "authenticated-app/pc/customer/customer-detail/tab-pane/dockingTabPane/interface";
import MobileDockingListDetail from "./detail";

export const MobileDockingTypeInfo = ({
  docking,
  reload,
}: {
  orgID: string | number | null | undefined;
  productID?: string | undefined;
  docking: DockingListType[] | undefined | null;
  reload: () => void;
}) => {
  const [DetailDockingVisible, setDetailDockingVisible] =
    useState<boolean>(false);
  const [DetailDockingInfo, setDetailDockingInfo] = useState<DockingListType>();

  const client = useHttp();

  return (
    <ContentCard>
      <List mode="card" header={<ListHeader text="对接基本信息" />}>
        {docking?.map((i) => (
          <SwipeAction key={i.id} closeOnAction={false}>
            <List.Item
              extra={<Ellipsis direction="end" content={i.docking_type_name} />}
              arrow={
                <Button
                  fill="none"
                  style={{ height: "100%", padding: 5 }}
                  loading={
                    DetailDockingVisible &&
                    i.id.toString() === DetailDockingInfo?.id.toString()
                  }
                >
                  <RightOutline style={{ color: "var(--adm-color-light)" }} />
                </Button>
              }
              onClick={() => {
                setDetailDockingInfo(i);
                setDetailDockingVisible(true);
              }}
            ></List.Item>
          </SwipeAction>
        ))}
      </List>
      <MobileDockingListDetail
        dockingInfo={DetailDockingInfo}
        visible={DetailDockingVisible}
        setVisible={setDetailDockingVisible}
      />
    </ContentCard>
  );
};

export default MobileDockingTypeInfo;
