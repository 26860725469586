import React, { Fragment, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { WaterMark } from "antd-mobile";
import { isBrowser } from "react-device-detect";
import { env } from "dingtalk-jsapi";

import { useAuth } from "context/auth";
import { Login } from "login";
import { Home } from "authenticated-app/pc";
import { MobileHome } from "authenticated-app/mobile";
import { LoadingPage } from "components";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";
import { devVersion } from "utils";
// import { PcSharedPage } from "authenticated-app/pc/ticket/shared";
import { MobileSharedPage } from "authenticated-app/mobile/ticket/shared";

import "./App.css";

const isSharedPage = (path: string) =>
  path === "/shared/checklist" || path === "/shared/checklist/";

const App: React.FC = () => {
  const { user, isIdle, isLogining } = useAuth();
  const hasStatus = user?.hasOwnProperty("userStatus");
  const hasPermission = hasStatus && user?.userStatus === 1;
  const Loading = isIdle || isLogining;

  const isShared = isSharedPage(window.location.pathname);

  const [{ version }, setVersion] = useUrlQueryParam(["version"]);

  useEffect(() => {
    setVersion({ version: devVersion });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  return (
    <Fragment>
      {isShared ? (
        <Routes>
          <Route
            path={"/shared/checklist"}
            // element={isBrowser ? <PcSharedPage /> : <MobileSharedPage />}
            element={<MobileSharedPage />}
          />
        </Routes>
      ) : (
        <Fragment>
          {env.platform === "notInDingTalk" ? (
            hasPermission ? (
              isBrowser ? (
                <Home />
              ) : (
                <MobileHome />
              )
            ) : !!!hasStatus && !!!Loading ? (
              <Login />
            ) : (
              <LoadingPage />
            )
          ) : env.platform === "pc" ? (
            hasPermission ? (
              <Home />
            ) : (
              <LoadingPage />
            )
          ) : hasPermission ? (
            <MobileHome />
          ) : (
            <LoadingPage />
          )}
        </Fragment>
      )}
      {user && (
        <WaterMark
          content={`${user?.userName} ${user?.mobile}`}
          fontColor={"rgba(211,211,211,0.2)"}
          width={168}
          height={62}
          rotate={-16}
        />
      )}
    </Fragment>
  );
};

export default App;
