import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { Descriptions, message, Tabs, Modal } from "antd";
import {
  EditOutlined,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";

import { useHttp } from "utils/http";
import { DEL_DOCKING_INFO } from "lib/api";
import { DockingDesItemLabel, LineDiv } from "../../utils";
import { DockingListType } from "../interface";
import { DockingBaseInfoAdd } from "./add";
import { DockingBaseInfoEdit } from "./edit";
import { useUrlQueryParam } from "utils/hooks/useUrlQueryParam";

const DockingBaseInfo = ({
  dockingInfo,
  hospID,
  reload,
  setDisChange,
  changeCount,
}: {
  dockingInfo: DockingListType[] | undefined | null;
  hospID: number | null;
  reload: () => void;
  setDisChange: Dispatch<SetStateAction<boolean>>;
  changeCount: any;
}) => {
  const [{ tab, docking }, setDocking] = useUrlQueryParam(["tab", "docking"]);

  const [edittingID, setEdittingID] = useState<string>();
  const [dockingIsEditable, setDockingIsEditable] = useState(false);
  const [addDockingVisible, setAddDockingVisible] = useState(false);

  const client = useHttp();

  const onEdit = (
    targetKey:
      | string
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>,
    action: "add" | "remove",
  ) => {
    if (action === "add") {
      setAddDockingVisible(true);
    } else {
      setDisChange(true);
      setEdittingID(targetKey as string);
      if (!dockingIsEditable) {
        setDockingIsEditable(true);
      }
    }
  };

  const delDockingInfo = (id: number, name: string) => {
    Modal.confirm({
      title: "删除确认",
      content: `确认删除【${name}】的信息？`,
      okText: "删除",
      cancelText: "取消",
      okButtonProps: { type: "primary", danger: true },
      closable: true,
      icon: <ExclamationCircleFilled style={{ color: "#f5222d" }} />,
      onOk: () =>
        client(DEL_DOCKING_INFO(id), { method: "DELETE" })
          .then(() => {
            message.success("删除成功！");
            setDockingIsEditable(false);
            setDisChange(false);
            Modal.destroyAll();
            reload();
          })
          .catch((e) => {
            console.error(e);
            message.error("删除失败，请联系管理员！");
          }),
    });
  };

  useEffect(() => {
    if (tab === "docking" && edittingID) {
      let index = dockingInfo?.findIndex((d) => d.id.toString() === edittingID);
      if (index !== undefined && index >= 0 && dockingInfo) {
        setDocking({ docking: edittingID });
      } else {
        if (dockingInfo && dockingInfo[0]) {
          let settingID = dockingInfo[0].id.toString();
          setEdittingID(settingID);
          setDocking({ docking: settingID });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCount, edittingID]);

  useEffect(() => {
    if (docking) {
      let index = dockingInfo?.findIndex((d) => d.id.toString() === docking);
      if (index !== undefined && index >= 0 && dockingInfo) {
        setEdittingID(docking);
      } else {
        if (dockingInfo && dockingInfo[0]) {
          setEdittingID(dockingInfo[0].id.toString());
        }
      }
    } else if (dockingInfo && dockingInfo[0]) {
      setEdittingID(dockingInfo[0].id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dockingInfo]);

  return (
    <Fragment>
      <Descriptions column={1} title={"基本对接信息"}>
        <Descriptions.Item>
          <Tabs
            type="editable-card"
            onEdit={onEdit}
            activeKey={edittingID}
            hideAdd={dockingIsEditable}
            onTabClick={(v) => {
              setEdittingID(v);
              setDocking({ docking: v });
            }}
            items={dockingInfo?.map((item, index) => ({
              disabled: edittingID !== item.id.toString() && dockingIsEditable,
              key: item.id.toString(),
              label: `对接-${index + 1}`,
              closable: edittingID === item.id.toString(),
              closeIcon:
                dockingIsEditable && item.id.toString() === edittingID ? (
                  <CloseCircleFilled
                    style={{ color: "#f5222d" }}
                    onClick={() => delDockingInfo(item.id, `对接-${index + 1}`)}
                  />
                ) : (
                  <EditOutlined />
                ),
              children:
                edittingID === item.id.toString() && dockingIsEditable ? (
                  <DockingBaseInfoEdit
                    hospID={hospID}
                    editingItem={item}
                    editable={dockingIsEditable}
                    setEditable={setDockingIsEditable}
                    setDisChange={setDisChange}
                    reload={reload}
                  />
                ) : (
                  <Descriptions column={2}>
                    <Descriptions.Item
                      label={<DockingDesItemLabel label={"对接产品线"} />}
                    >
                      <LineDiv>
                        {item.products.map((i) => i.product_id).join("、")}
                      </LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<DockingDesItemLabel label={"对接类型"} />}
                    >
                      <LineDiv>{item.docking_type_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<DockingDesItemLabel label={"现片对接方式"} />}
                    >
                      <LineDiv>{item.cur_docking_name}</LineDiv>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<DockingDesItemLabel label={"前片对接方式"} />}
                    >
                      <LineDiv>{item.history_docking_name}</LineDiv>
                    </Descriptions.Item>
                  </Descriptions>
                ),
            }))}
          />
        </Descriptions.Item>
      </Descriptions>
      <DockingBaseInfoAdd
        hospID={hospID}
        visible={addDockingVisible}
        setVisible={setAddDockingVisible}
        reload={reload}
      />
    </Fragment>
  );
};

export default DockingBaseInfo;
