import { useMemo, useRef, useState } from "react";
import { Select, Spin, Button, Space } from "antd";
import debounce from "lodash/debounce";
import { useHttp } from "utils/http";

export const DebounceSelect = ({
  api,
  debounceTimeout = 800,
  updateList,
  onUpdateValue,
  onUpdate,
  ...props
}: {
  api: string;
  debounceTimeout?: number;
  updateList: number[] | undefined;
  onUpdateValue?: (newValue: any[]) => void;
  onUpdate: (value: any) => void;
  [key: string]: any;
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<[]>([]);
  const fetchRef = useRef(0);
  const client = useHttp();

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);

      if (value) {
        setFetching(true);
        client(`${api}${value}`).then((response: any) => {
          if (response) {
            setOptions(
              response.map((item: any) => ({
                label: `${item.order_id}|${item.title}`,
                value: item.id,
              })),
            );
            setFetching(false);
          }
        });
      }
    };
    return debounce(loadOptions, debounceTimeout);
  }, [api, debounceTimeout]);

  return (
    <div style={{ display: "flex" }}>
      <Select
        showSearch
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="default" /> : null}
        // options={options}
        {...props}
        allowClear
      >
        {options.map((option: any) => (
          <Select.Option
            key={option.value}
            value={option.value}
            label={option.label}
            style={{ whiteSpace: "pre-wrap" }}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
      <Button
        size="middle"
        onClick={() => {
          if (updateList && props.value.value) {
            onUpdate({
              similar_id_order_list: [...updateList, props.value.value],
            });
            props.onChange([]);
          }
        }}
      >
        添加
      </Button>
    </div>
  );
};
