import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  Input,
  message,
  Select,
  Divider,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useHttp } from "utils/http";
import { GPUOem } from "../interface";
import { useAsync } from "utils/hooks/useAsync";
import { GET_GPU_OEM } from "../api";
import { DisposeForm } from "utils";

const { Option } = Select;

export const GPUSettingAdd = ({
  submitAPI,
  visible,
  setVisible,
  reload,
}: {
  submitAPI: string;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  reload: () => void;
}) => {
  const client = useHttp();
  const [form] = Form.useForm();

  const [addOemItem, setAddOemItem] = useState<string[]>([]);
  const [newOem, setNewOem] = useState<string | null>(null);

  const {
    run: getGPUOem,
    isLoading: gettingGPUOem,
    // data: gpuOemList,
  } = useAsync<GPUOem[]>();
  const getGPUOemFunc = () => {
    getGPUOem(client(GET_GPU_OEM)).then((res: GPUOem[]) => {
      if (res) {
        let arr: string[] = res?.map((i) => i.name);
        setAddOemItem(arr);
      }
    });
  };

  const newOemHandle = (e: React.MouseEvent) => {
    e.preventDefault();
    if (newOem) {
      setAddOemItem([...addOemItem, newOem]);
      setNewOem(null);
    }
  };

  const submit = (value: any) => {
    client(submitAPI, {
      data: {
        ...value,
      },
      method: "POST",
    })
      .then(() => {
        message.success("添加成功！");
        setVisible(false);
        reload();
      })
      .catch((e) => {
        console.error(e);
        message.error("添加失败，请检查或联系管理员！");
      });
  };

  useEffect(() => {
    if (visible) {
      getGPUOemFunc();
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="新增显卡型号"
      destroyOnClose
      maskClosable={false}
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          name="gpu_oem_name"
          label="品牌"
          rules={[{ required: true, message: "必须输入品牌" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Select
            loading={gettingGPUOem}
            placeholder="选择显卡品牌"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    allowClear
                    maxLength={50}
                    onChange={(e) => setNewOem(e.target.value)}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={newOemHandle}
                  >
                    添加品牌
                  </Button>
                </Space>
              </>
            )}
          >
            {addOemItem?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="gpu_model_name"
          label="型号"
          rules={[{ required: true, message: "必须输入型号" }]}
          normalize={(v) => DisposeForm(v)}
        >
          <Input maxLength={50} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 15, span: 9 }}>
          <Space>
            <Button htmlType="button" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};
