import { Input, Form } from "antd";
import type { ColumnsType } from "antd/es/table";

import { CHECKLIST_VALUE_TYPE } from "lib/interface/checklist";

const { Item } = Form;
const { TextArea } = Input;

export const SurveyColumns: ColumnsType<CHECKLIST_VALUE_TYPE> = [
  {
    title: "编号",
    dataIndex: "id",
    width: 64,
    align: "center",
    render: (_, __, index) => {
      return index + 1;
    },
  },
  {
    title: "调研内容",
    dataIndex: "content",
    width: 96,
  },
  {
    title: "参考指标",
    dataIndex: "description",
  },
  {
    title: "调研结果",
    dataIndex: "value",
    width: 256,
    render: (_, record, __) => {
      return (
        <Item
          key={record.id}
          name={record.id}
          rules={[{ required: record.is_need === 1, message: "必须填写此项" }]}
          initialValue={record.value}
        >
          <TextArea autoSize={{ minRows: 3 }} placeholder="填写调研结果" />
        </Item>
      );
    },
  },
];
