import { Comment } from "@ant-design/compatible";
import { Card, Avatar, Divider } from "antd-mobile";
import { BREAK_DETAIL_TYPE, COMMENT_LIST } from "lib/interface/breaklist";
import { MentionQuill } from "./MentionQuill/MentionQuill";
export const MobileComments = ({
  name,
  title,
  user,
  avatar,
  detailInfo,
}: {
  name: string;
  title: string;
  user?: string;
  avatar?: string;
  detailInfo?: BREAK_DETAIL_TYPE | null;
}) => {
  const issues_id = detailInfo?.id;
  const ExampleComment: React.FC<{
    comments: COMMENT_LIST[];
  }> = ({ comments }) => {
    return comments.map((item) => (
      <Comment
        key={item.id}
        className={item.user_name ? "user-comment" : "no-user-comment"}
        author={item.user_name}
        avatar={
          item.avatar ? (
            <Avatar src={item.avatar} alt={item.user_name} />
          ) : undefined
        }
        datetime={item.update_time}
        content={
          <MentionQuill
            name={"comment"}
            info={item.comment}
            isCanChange={false}
            issues_id={issues_id}
            type={"update"}
            comment_id={item.id}
            user_id={item.user_id}
            reload={() => {}}
          />
        }
      >
        {item.children && item.children.length > 0 && (
          <div style={{ marginLeft: "4px" }}>
            <ExampleComment comments={item.children} />
          </div>
        )}
      </Comment>
    ));
  };

  return (
    <>
      <Card style={{ maxHeight: "500px", overflowY: "auto" }}>
        {detailInfo?.comment_list ? (
          <ExampleComment comments={detailInfo.comment_list} />
        ) : (
          <></>
        )}
        <Divider />
      </Card>
    </>
  );
};
